import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import {authTypes} from "../store/auth";
import {setTokenAction} from "@tshio/redux-api-auth-middleware";


const initializeStoreData = (store) => {
  const access = Cookies.get("access");
  const refresh = Cookies.get("refresh");

  if (access && refresh) {
    const payload = {
      access_token: access,
      refresh_token: refresh
    };
    const refreshDecode = jwtDecode(refresh);
    const expired = 1000 * refreshDecode.exp - new Date().getTime() < 5000;
    if (!expired) {
      store.dispatch({type: authTypes.AUTHENTICATED, payload});
      store.dispatch(setTokenAction({
        access_token: access,
        refresh_token: refresh,
        expires_in: refreshDecode.exp
      }));
      // store.dispatch(checkLogin());
    }
  }
};

export default initializeStoreData;
