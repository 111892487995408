import React from "react";
import PropTypes from "prop-types";
import {ItemText} from "../../Common/Bars/styled";

const Details = ({styles, baseClassName}) => {
  return (
    <div className={`${baseClassName}-detail`} style={styles}>
      <ItemText color={"white"}>No History</ItemText>
    </div>
  );
};

Details.propTypes = {
  baseClassName: PropTypes.string
};

export {Details};

