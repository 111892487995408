import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {NumberInputControl} from "../../../Common/Base/Inputs/NumberInput";
import {AutoSave} from "../../../Common/Forms/Base";

const AddInventoryQuantityForm = ({onSubmit, fromOrder, initialValues, max}) => {
  let options = {};
  if (max) {
    options = {
      max,
    };
  }

  return (
    <Form
      onSubmit={onSubmit}
      // validate={(values) => values.quantity <= validate}
      // initialValues={values}
      render={({
        handleSubmit,
        pristine,
        invalid,
        form,
        submitting,
        values,
      }) => {
        const orderMax = initialValues && initialValues.available ? initialValues.available : 0;
        const available = initialValues && initialValues.available ? initialValues.available : 0;
        return (
          <form className={"add-pallet-quantity-form"} onSubmit={handleSubmit}>
            <AutoSave
              save={onSubmit}
              debounced={[]}
            />

            {fromOrder ?
              <NumberInputControl
                backgroundColor="white"
                label={`Remaining: ${available - values.quantity}`}
                placeholder={"Quantity..."}
                name={"quantity"}
                defaultValue={0}
                isDisabled={false}
                clampValueOnBlur={false}
                max={orderMax}
              />
              :
              <NumberInputControl
                backgroundColor="white"
                placeholder={"Quantity..."}
                name={"quantity"}
                defaultValue={0}
                isDisabled={false}
                clampValueOnBlur={false}
                {...options}
              />
            }
          </form>
        );
      }}
    />
  );
};

AddInventoryQuantityForm.propTypes = {
  onSubmit: (values) => null,
  initialValues: PropTypes.any,
  validate: PropTypes.func,
  max: PropTypes.number,
  fromOrder: PropTypes.bool,
};

AddInventoryQuantityForm.defaultProps = {
  fromOrder: false,
};

export default AddInventoryQuantityForm;
