import React, {Fragment} from "react";
import {Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import {FieldArray} from "react-final-form-arrays";
import PropTypes from "prop-types";
import {Box, Label} from "Base";
import {AutoSave, EditableColorFormDropdown, EditableTemplateDropdown, FormField, FormLinkField} from "../Base";
import {SubmitButton} from "../styled";
import {postLoadFormat, preSaveFormat} from "./utils";

import {LabelText} from "../Base/styled";

const AssetForm = (props) => {
  const {
    onSubmit,
    submitting,
    validate,
    values,
    editAccess,
    assetTypes,
    selectedAssetType,
    selectedAssetTypeTemplate,
    loadingTemplate,
    newAssetType,
    submitCreateAssetTemplate,
    submitEditAssetTemplate,
    submitDeleteAssetTemplate,
    handleAssetTypeSelect,
    handleAssetTypeTemplateSelect,
    handleShowCreateAssetType,
    handleShowEditAssetType,
    handleAssetTypeNameChange,
    handleAssetTypeColorChange,
    handleCreateAssetType,
    handleUpdateAssetType,
    handleDeleteAssetType,
    handleManufacturerClick
  } = props;

  let templates = assetTypes.find(obj => obj.id === selectedAssetType.id) || [];
  if (templates !== []) {
    templates = templates.templates || [];
  }

  let hasTemplateData = selectedAssetTypeTemplate !== null && selectedAssetTypeTemplate.id;

  const disableAutoSave = !loadingTemplate || submitting;
  const initialValues = postLoadFormat(values);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"item-details-form"} onSubmit={handleSubmit}>
          {disableAutoSave &&
          <AutoSave
            debounce={2000}
            save={onSubmit}
            debounced={["name", "manufacturer_name", "template_data"]}
            postLoadFormat={postLoadFormat}
            preSaveFormat={preSaveFormat}
            formInvalid={invalid}
          />}

          <FormField
            label="Asset Name"
            type="text"
            name="name"
            isDisabled={!editAccess}
          />

          {editAccess ?
            <FormField
              label="Asset Manufacturer Name"
              type="text"
              name="manufacturer_name"
              isDisabled={!editAccess}
              handleClick={handleManufacturerClick}
            />
            :
            <FormLinkField
              label="Asset Manufacturer Name"
              type="text"
              name="manufacturer_name"
              isDisabled={!editAccess}
              handleClick={handleManufacturerClick}
              url="/#/dashboard/assets"
            />
          }

          <EditableColorFormDropdown
            dropdownName="Asset Type"
            label={"ASSET TYPE"}
            canChangeSelection={true}
            canCreateSelections={editAccess}
            dropdownOptions={assetTypes}
            selectedOption={selectedAssetType}
            newField={newAssetType}
            handleFieldSelect={handleAssetTypeSelect}
            handleShowCreateFieldSuper={handleShowCreateAssetType}
            handleShowEditFieldSuper={handleShowEditAssetType}
            handleCreateField={handleCreateAssetType}
            handleEditField={handleUpdateAssetType}
            handleDeleteField={handleDeleteAssetType}
            handleColorChange={handleAssetTypeColorChange}
            handleNewFieldNameChange={handleAssetTypeNameChange}/>

          <Fragment>
            {!(selectedAssetType.id === undefined || selectedAssetType.id === "") &&
            <Fragment>
              <Label>
                <LabelText>
                  ASSET TEMPLATE:
                </LabelText>
              </Label>

              <EditableTemplateDropdown
                dropdownName="Asset Template"
                canChangeData={true}
                canCreateTemplates={editAccess}
                dropdownOptions={templates}
                selectedOption={selectedAssetTypeTemplate}
                handleFieldSelect={handleAssetTypeTemplateSelect}
                currentTemplate={selectedAssetTypeTemplate}
                submitCreateTemplate={submitCreateAssetTemplate}
                submitEditTemplate={submitEditAssetTemplate}
                submitDeleteTemplate={submitDeleteAssetTemplate}
              />
              {hasTemplateData && !loadingTemplate &&
              <Fragment>
                <Label>
                  <LabelText>
                    ASSET TEMPLATE DATA:
                  </LabelText>
                </Label>
                <FieldArray name={"template_data"}>
                  {({fields}) => (
                    fields.map((name, index) => {
                      let field_label = fields.value[index].field.name;
                      if (fields.value[index].field.units) {
                        field_label += ` (${fields.value[index].field.units})`;
                      }
                      return (
                        <Box width={0.5} key={index} mt={"10px"}>
                          <FormField
                            id={"templateData" + index}
                            label={field_label}
                            name={`${name}.value`}
                            placeholder="Value"
                          />
                        </Box>
                      );
                    })
                  )}
                </FieldArray>
              </Fragment>
              }
            </Fragment>
            }
          </Fragment>

          <SubmitButton
            className="item-details-form-button"
            loading={submitting}>
            Save
          </SubmitButton>
        </form>
      )}
    />
  );
};

AssetForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  editAccess: PropTypes.bool,
  assetTypes: PropTypes.array,
  selectedAssetType: PropTypes.any,
  selectedAssetTypeTemplate: PropTypes.object,
  originalAssetTypeTemplate: PropTypes.object,
  templateData: PropTypes.any,
  newAssetType: PropTypes.any,
  submitCreateAssetTemplate: PropTypes.func,
  submitEditAssetTemplate: PropTypes.func,
  submitDeleteAssetTemplate: PropTypes.func,
  handleAssetTypeSelect: PropTypes.func,
  handleAssetTypeTemplateSelect: PropTypes.func,
  handleShowCreateAssetType: PropTypes.func,
  handleShowEditAssetType: PropTypes.func,
  handleAssetTypeNameChange: PropTypes.func,
  handleAssetTypeColorChange: PropTypes.func,
  handleCreateAssetType: PropTypes.func,
  handleUpdateAssetType: PropTypes.func,
  handleDeleteAssetType: PropTypes.func,
  updateAssetTemplateDataValue: PropTypes.func,
  handleColorPickerClick: PropTypes.func,
  handleManufacturerClick: PropTypes.func,
};

AssetForm.defaultProps = {
  values: {},
  editAccess: true
};

export default AssetForm;
