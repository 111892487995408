import {Flex} from "../Base";
import styled from "@emotion/styled";

const HeaderWrapper = styled(Flex)`
    border-bottom: 1px solid ${props => props.theme.colors.colorGreyLight3};
    
    //margin-bottom: 90px;
`;

HeaderWrapper.defaultProps = {
  bg: "colorGreyLight1",
  flexDirection: "row",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "space-between",
  p: 2
};

const MenuItem = styled(Flex)``;

MenuItem.defaultProps = {
  flexDirection: "column",
  justifyContent: "center"
};


const HeaderMenu = styled(Flex)``;

HeaderMenu.defaultProps = {
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: 2,
  color: "colorGreyDark2"
};

export {HeaderWrapper, MenuItem, HeaderMenu};

