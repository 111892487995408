import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {Heading} from "../../Base";
import {FormDropdown, FormField} from "../Base";
import {countryList, statesOptions, DISPLAY_IMG} from "./constants";
import {AttachedFiles} from "../../index";
import {
  AZURE_BLOB_CONTAINER_NAME,
  AZURE_SERVPROVBUS_PREFIX,
  deleteBlobFromAzureContainer,
  getAzureBlobURL,
  listBlobsInAzureContainer,
} from "../../../../utils/azure";
import {SubmitButton} from "../styled";
import {ImageWithDefault} from "../../Details/Layout";

const BusinessInfo = (props) => {
  const [businessImages, setImages] = useState([]);

  const {
    handleSubmit,
    validate,
    business_name,
    business_street,
    business_city,
    business_country,
    business_zip,
    business_state,
    display_image,
    userUUID
  } = props;

  const initialValues = {
    business_name,
    business_street,
    business_city,
    business_country,
    business_zip,
    business_state,
  };

  const getBusinessImages = () => {
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_SERVPROVBUS_PREFIX}/${userUUID}/`).then(files => {
      setImages(files);
    });
  };

  useEffect(() => {
    getBusinessImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDisplayImage = async imageName => {
    if (imageName) {
      imageName = getAzureBlobURL(imageName);
    }
    handleSubmit({[DISPLAY_IMG]: imageName});
  };

  const handleRemoveDocument = async documentName => {
    const azureUrl = await getAzureBlobURL(documentName);
    // if the logo (display image) is equal to the document requested to delete, remove the logo from the DB
    if (display_image === azureUrl) {
      handleSubmit({[DISPLAY_IMG]: ""});
    }
    deleteBlobFromAzureContainer(AZURE_BLOB_CONTAINER_NAME, documentName)
      .then(res => {
        // if the delete request is successful, keep all of the images/docs that are in state except for the removed one
        const newBusinessImages = businessImages.filter(image => image.name !== documentName);
        setImages(newBusinessImages);
      })
      .catch(e => console.log(e));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        pristine,
        invalid,
        submitting,
        values
      }) => (
        <form className={"item-details-form serv-prov-info"} onSubmit={handleSubmit}>
          <FormField
            label="Business Name"
            placeholder="Name"
            type="text"
            name="business_name"
          />
          <FormField
            label="Address"
            placeholder="Street"
            type="text"
            name="business_street"
          />
          <FormField
            placeholder="City"
            type="text"
            name="business_city"
          />
          <FormField
            placeholder="Zip Code"
            type="text"
            name="business_zip"
          />

          <FormDropdown
            options={countryList}
            name={"business_country"}
            label={"COUNTRY"}
            format={(value, name) => countryList.find(x => x.value === value)}
            parse={(value, name) => value.value}
          />
          {values.business_country === "United States" &&
          <FormDropdown
            options={statesOptions}
            name={"business_state"}
            label={"STATE"}
            format={(value, name) => statesOptions.find(x => x.value === value)}
            parse={(value, name) => value.value}
          />
          }
          <label><Heading size="xl">Logo:</Heading></label>
          <ImageWithDefault src={display_image} />
          <AttachedFiles
            files={businessImages}
            displayImage={display_image}
            azureDocumentPrefix={AZURE_SERVPROVBUS_PREFIX}
            UUID={userUUID}
            getDocuments={getBusinessImages}
            handleChangeDisplayImage={handleChangeDisplayImage}
            handleRemoveDocument={handleRemoveDocument}
            refreshObject={() => null}/>
          <br/>
          <SubmitButton className="item-details-form-button" loading={submitting}>Submit</SubmitButton>
        </form>
      )
      }
    />
  );
};

BusinessInfo.propTypes = {
  handleSubmit: PropTypes.func,
  validate: PropTypes.func
};

BusinessInfo.defaultProps = {
  validate: (values) => null
};

export default BusinessInfo;
