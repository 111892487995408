import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import {Button} from "Base";
import PropTypes from "prop-types";


const CreatePMOrderButton = props => {
  const [createdPMOrder, setCreatedPMOrder] = useState(null);
  const {
    createPMOrder,
    location,
    orgID,
  } = props;

  const handleCreatePMOrder = () => {
    createPMOrder({
      location: location.id,
      org: orgID,
    }).then(res => {
      setCreatedPMOrder(res.payload.id);
    }).catch(err => {
      console.log("Error creating PMOrder", err);
    });
  };

  if (createdPMOrder) return <Redirect to={`/dashboard/orders/${createdPMOrder}?new`}/>;

  return <Button id={"createPMOrderButton"} onClick={handleCreatePMOrder}>Create PM Order</Button>;
};


CreatePMOrderButton.propTypes = {
  /**
   * Callback to create PMOrder
   */
  createPMOrder: PropTypes.func,
  /**
   * LocationInstance data
   */
  location: PropTypes.object,
  /**
   * Organization primary key of user
   */
  orgID: PropTypes.number,
};

export default CreatePMOrderButton;
