import actionTyper from "redux-actiontyper";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_NONE_FOUND,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CHANGE_VIEW_CALENDAR,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE
} = actionTyper("@@vendor_orders/");

export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_NONE_FOUND,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CHANGE_VIEW_CALENDAR,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE
};
