import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import {Text} from "../../../Common/Base";
import SelectingInventoryListItem from "./SelectingInventoryListItem";
import {MATERIAL, PRODUCT, STORE, UNPACK} from "../../utils/constants";
import {capitalize} from "lodash-es";

/*
This component will render a Product or Material inventory list
Used to select quantities to perform actions on
 */
const SelectingInventoryList = (props) => {
  const {
    data,
    title,
    fromOrder,
    onEdit,
    onRemove,
    maxField,
    action,
    type,
    locationPagination,
    getLocations,
    location,
    createLocation,
  } = props;
  const count = data.length;

  let header = <Text color={"black"}>{` ${count} ${capitalize(type)}'s Selected`}</Text>;

  if (title) {
    header = title;
  }
  return (
    <List
      className={"select-product-list"}
      dataSource={data}
      header={header}
      renderItem={item =>
        <SelectingInventoryListItem
          key={item.id}
          item={item}
          onEdit={onEdit}
          onRemove={onRemove}
          maxField={maxField}
          fromOrder={fromOrder}
          onSelect={(values) => console.log("selected", values)}
          action={action}
          type={type}
          locationPagination={locationPagination}
          getLocations={getLocations}
          location={location}
          createLocation={createLocation}
        />
      }
    />
  );
};

SelectingInventoryList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  fromOrder: PropTypes.bool,
  maxField: PropTypes.string,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  action: PropTypes.oneOf([UNPACK, STORE]),
  type: PropTypes.oneOf([PRODUCT, MATERIAL]),
  locationPagination: PropTypes.object,
  getLocations: PropTypes.func,
  location: PropTypes.object,
};

SelectingInventoryList.defaultProps = {
  fromOrder: false,
  maxField: "available_quantity",
  action: null,
  type: PRODUCT,
};
export default SelectingInventoryList;
