import React, {Fragment} from "react";
import {ItemText} from "../../../Common/Bars/styled";
import {generateBreadcrumb} from "../../../../utils/location";

const Details = props => {
  const {baseClassName, location} = props;

  return (
    <div className={`${baseClassName}-detail`}>
      <Fragment>
        <ItemText style={{"textDecoration": "underline"}}>Location</ItemText>
        <ItemText>{generateBreadcrumb(location)}</ItemText>
      </Fragment>
    </div>
  );
};

export {Details};
