
const hasErrors = state => Object.keys(state.errors).length !== 0;

const refreshNotes = (state, id) => {
  if (state.notes && state.notes[id]) {
    return state.notes[id];
  }
  return [];
};

const refreshCsvMessages = (state) => {
  if (state.csvMessages) {
    return state.csvMessages;
  }
  return null;
};

export default {
  hasErrors,
  refreshNotes,
  refreshCsvMessages
};
