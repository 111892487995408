import React from "react";
import PropTypes from "prop-types";
import {Button} from "antd";

// A Button that displays different text based on toggle state
const ToggleButton = ({toggled, text, children, ...rest}) => {
  const renderText = () => toggled ? text : children;

  return (
    <Button {...rest}>
      {renderText()}
    </Button>
  );
};

ToggleButton.propTypes = {
  toggled: PropTypes.bool,
  text: PropTypes.string
};

ToggleButton.defaultProps = {
  text: "Cancel"
};

export default ToggleButton;
