import actionTyper from "redux-actiontyper";

const {
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  GET_ALL_SUCCESS,
  GET_ALL_REQUEST,
  GET_ALL_FAILURE,

  CREATE_SCHEDULED_SHIFT_REQUEST,
  CREATE_SCHEDULED_SHIFT_SUCCESS,
  CREATE_SCHEDULED_SHIFT_FAILURE,

  UPDATE_SCHEDULED_SHIFT_REQUEST,
  UPDATE_SCHEDULED_SHIFT_SUCCESS,
  UPDATE_SCHEDULED_SHIFT_FAILURE,

  DELETE_SCHEDULED_SHIFT_REQUEST,
  DELETE_SCHEDULED_SHIFT_SUCCESS,
  DELETE_SCHEDULED_SHIFT_FAILURE,

  GET_CALENDAR_REQUEST,
  GET_CALENDAR_SUCCESS,
  GET_CALENDAR_FAILURE,

  GET_SCHEDULE_CALENDAR_REQUEST,
  GET_SCHEDULE_CALENDAR_SUCCESS,
  GET_SCHEDULE_CALENDAR_FAILURE,

  CREATE_SHIFT_REQUEST,
  CREATE_SHIFT_SUCCESS,
  CREATE_SHIFT_FAILURE,

  UPDATE_SHIFT_REQUEST,
  UPDATE_SHIFT_SUCCESS,
  UPDATE_SHIFT_FAILURE,

  DELETE_SHIFT_REQUEST,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAILURE,

  ASSIGN_PERSONNEL_REQUEST,
  ASSIGN_PERSONNEL_SUCCESS,
  ASSIGN_PERSONNEL_FAILURE,

  GET_RECENT_SHIFT_ACTIVITY_REQUEST,
  GET_RECENT_SHIFT_ACTIVITY_SUCCESS,
  GET_RECENT_SHIFT_ACTIVITY_FAILURE,

  GET_ACTIVE_SHIFT_ACTIVITY_REQUEST,
  GET_ACTIVE_SHIFT_ACTIVITY_SUCCESS,
  GET_ACTIVE_SHIFT_ACTIVITY_FAILURE,

  TOGGLE_SHIFT_ACTIVITY_REQUEST,
  TOGGLE_SHIFT_ACTIVITY_SUCCESS,
  TOGGLE_SHIFT_ACTIVITY_FAILURE,
} = actionTyper("@@scheduled_shifts/");

export default {
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ALL_SUCCESS,
  GET_ALL_REQUEST,
  GET_ALL_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  CREATE_SCHEDULED_SHIFT_REQUEST,
  CREATE_SCHEDULED_SHIFT_SUCCESS,
  CREATE_SCHEDULED_SHIFT_FAILURE,

  UPDATE_SCHEDULED_SHIFT_REQUEST,
  UPDATE_SCHEDULED_SHIFT_SUCCESS,
  UPDATE_SCHEDULED_SHIFT_FAILURE,

  DELETE_SCHEDULED_SHIFT_REQUEST,
  DELETE_SCHEDULED_SHIFT_SUCCESS,
  DELETE_SCHEDULED_SHIFT_FAILURE,

  GET_CALENDAR_REQUEST,
  GET_CALENDAR_SUCCESS,
  GET_CALENDAR_FAILURE,

  GET_SCHEDULE_CALENDAR_REQUEST,
  GET_SCHEDULE_CALENDAR_SUCCESS,
  GET_SCHEDULE_CALENDAR_FAILURE,

  CREATE_SHIFT_REQUEST,
  CREATE_SHIFT_SUCCESS,
  CREATE_SHIFT_FAILURE,

  UPDATE_SHIFT_REQUEST,
  UPDATE_SHIFT_SUCCESS,
  UPDATE_SHIFT_FAILURE,

  DELETE_SHIFT_REQUEST,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAILURE,

  ASSIGN_PERSONNEL_REQUEST,
  ASSIGN_PERSONNEL_SUCCESS,
  ASSIGN_PERSONNEL_FAILURE,

  GET_RECENT_SHIFT_ACTIVITY_REQUEST,
  GET_RECENT_SHIFT_ACTIVITY_SUCCESS,
  GET_RECENT_SHIFT_ACTIVITY_FAILURE,

  GET_ACTIVE_SHIFT_ACTIVITY_REQUEST,
  GET_ACTIVE_SHIFT_ACTIVITY_SUCCESS,
  GET_ACTIVE_SHIFT_ACTIVITY_FAILURE,

  TOGGLE_SHIFT_ACTIVITY_REQUEST,
  TOGGLE_SHIFT_ACTIVITY_SUCCESS,
  TOGGLE_SHIFT_ACTIVITY_FAILURE
};
