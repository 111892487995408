function refreshNameList(state) {
  if (state && state.items && state.items.length) {
    return state.items.map(item => ({
      id: item.id,
      reference: item.reference,
      status: item.status_display || "",
      value: item.id,
      label: `${item?.reference ?? item.id}`
    }));
  }
  return [];
}

export default {
  refreshNameList
};
