import React from "react";
import PropTypes from "prop-types";
import {Flex} from "Base";
import {FormDrawer} from "../../../../Common/Drawers";
import EntryForm from "./EntryForm";

const EntryFormDrawer = props => {
  const {onSubmit, productionLine, values, formConfig, text, buttonConfig} = props;

  return (
    <Flex p={2} alignItems={"center"}>
      <FormDrawer
        onSubmit={onSubmit}
        formID={"entry-form"}
        FormComponent={EntryForm}
        text={text}
        formConfig={{
          ...formConfig,
          productionLine
        }}
        values={values}
        buttonConfig={buttonConfig}
      />
    </Flex>
  );
};

EntryFormDrawer.propTypes = {
  onSubmit: PropTypes.func,
  productionLine: PropTypes.object,
  values: PropTypes.object,
  text: PropTypes.object,
  buttonConfig: PropTypes.object
};

EntryFormDrawer.defaultProps = {
  values: {},
  text: {
    title: "New Production Entry",
    tooltip: "Add a production shift entry to the schedule.",
    openButton: "New Entry",
  },
  buttonConfig: {
    ariaLabel: "Open Create Form",
    type: "primary",
    className: "open-create-entry-btn"
  },
};

export default EntryFormDrawer;
