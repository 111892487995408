import React, {Fragment} from "react";
import {useToast} from "@chakra-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";

/* This snackbar is responsible for displaying toast (notification) messages to the user. */
export const SnackBar = props => {
  const {title, description, status, duration, isClosable, position} = props;
  const toast = useToast();

  return (
    <Fragment>
      {toast({
        title: title,
        description: description,
        status: status,
        duration: duration,
        isClosable: isClosable,
        position: position
      })}
    </Fragment>
  );
};

SnackBar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.oneOf(["success", "warning", "error"]),
  duration: PropTypes.number,
  isClosable: PropTypes.bool,
  position: PropTypes.string,
};

SnackBar.defaultProps = {
  status: "success",
  duration: 3000,
  isClosable: true,
  position: "bottom-left"
};

class SnackbarDispatch extends React.Component {
  state = {
    snack: null
  };

  componentDidMount() {
    if (this.props.snack) {
      this.showSnack(this.props.snack);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const snack = this.props.snack;
    if (snack) {
      this.showSnack(snack);
    } else {
      this.setState({snack: null});
    }
  }

  showSnack = (snack) => {
    this.setState({snack});
    this.snackTimer = setTimeout(() => {
      this.setState({snack: null});
    }, 1000);
  };

  render() {
    const {snack} = this.state;
    if (!snack) {
      return null;
    }
    return (
      <SnackBar {...snack.data} />
    );
  }
}

export default connect(state => ({snack: state.snackbar.queue.shift() || null}))(SnackbarDispatch);
