import actions from "./actions";
import {name as endpoint} from "./index";
import {baseOperations} from "../base";
import {orgOperations} from "../orgs";
import types from "./types";
import {dateUtils, snackUtils} from "../../utils";
import base from "../base/actions";

const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getAllItems = () => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});

/* This Function handles sending an Item update request to the API.
Then dispatches either the updateSuccess or updateFailure action.
Also dispatches snackbar messages on start and finish. */
const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Asset", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "asset", res.payload.name, dispatch, () => {
  });
};

/* This Function handles sending an Item update request to the API
for a specific field. Then dispatches either the updateSuccess or
updateFailure action. Also dispatches snackbar messages on start
and finish, and updates asset data upon success. */
const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateItem(id, fieldData));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Asset", res.payload.name, dispatch, () => {
    dispatch(getAllItems());
  });
};

const uploadCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch, getAllItems);
};

/* This Function handles adding a new item to the current parent item.
The first example of this is adding a note to an asset. */
const addNote = (id, data) => async dispatch => {
  const res = await dispatch(actions.submitNote(id, data));
  baseOperations.handleSubmitNoteResponse(res, dispatch);
  return res.payload;
};

const bulkDelete = (data) => async dispatch => {
  const res = await dispatch(actions.bulkDelete(data));
  console.log("BULK OPERATIONS: ", res);
  dispatch(getAllItems());
};

const createAssetTemplate = (asset_id, org_id, asset_type_id, template) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.createAssetTemplate(asset_id, org_id, asset_type_id, template));
  baseOperations.handleCreateResponse(res, timestamp, "asset type", template.name, dispatch, () => {
    dispatch(getItem(asset_id));
    dispatch(orgOperations.getAssetTypes(org_id));
  });
  return res;
};

const updateAssetTemplate = (asset_id, org_id, asset_type_id, template_id, template) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateAssetTemplate(asset_id, org_id, asset_type_id, template_id, template));
  baseOperations.handleUpdateResponse(res, timestamp, "asset type", template.name, dispatch, () => {
    dispatch(getItem(asset_id));
    dispatch(orgOperations.getAssetTypes(org_id));
  });
  return res.payload;
};

const deleteAssetTemplate = (asset_id, org_id, asset_type_id, template_id) => async dispatch => {
  const res = dispatch(actions.deleteAssetTemplate(asset_id, org_id, asset_type_id, template_id));
  console.log(res);
  dispatch(getItem(asset_id));
  dispatch(orgOperations.getAssetTypes(org_id));
};


export default {
  getItem,
  getAllItems,
  updateItem,
  updateItemField,
  addNote,
  uploadCsv,
  createAssetTemplate,
  updateAssetTemplate,
  deleteAssetTemplate,
  bulkDelete
};
