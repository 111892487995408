import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";

import {Redirect} from "react-router-dom";
import {deleteAxios, postAxios} from "utils/authWithAxios";
import AddItemModal from "Common/Modals/AddItemModal";
import AdminControlsDetails from "Common/AdminControlsDetails/AdminControlsDetails";
import Modal from "Common/Modals/Modal";
import {Button, Heading} from "Common/Base";

import {csvColumns, makeSampleData} from "./constants";
import {DescriptionText} from "Common/Modals/styled";
import {ASSET_DASHBOARD_ROUTE, PM_ORDERS_DASHBOARD_ROUTE, STOCKROOM_DASHBOARD_ROUTE, VENDORS_DASHBOARD_ROUTE} from "../../../routes/constants";
import {authSelectors} from "../../../store";

/* This presents the details for a particular asset. The imported item ID is acquired via the URL. */
class AdminControls extends Component {
  constructor(props) {
    super(props);
    // This.assetID = props.match.params.assetID;
    this.state = {
      addedStockroomAssetID: "",
      addedVendorOrderID: "",
      addedPMOrderID: "",
      addItemModalSubmitting: false,
      addItemModalError: false,
      showAddStockroomAssetModal: false,
      showAddVendorOrderModal: false,
      showAddPMOrderModal: false,
      adminControlsSubmitting: false,
      adminControlsMessage: "",
      deleteAssetModalShowing: false,
      errorLoadingMessage: "",
      deletedAsset: false,
    };
  }


  /* This function will handle deletion of an asset. */
  handleDeleteAsset = () => {
    const {asset} = this.props;
    if (asset.id) {
      this.setState({
        adminControlsSubmitting: true,
        adminControlsMessage: "",
      });
      deleteAxios({
        url: `${global.base_url}v1/assets/${asset.id}/`
      })
        .then(res => {
          console.log(res);
          this.setState({
            adminControlsSubmitting: false,
            adminControlsMessage: "Asset deletion successful.",
            deletedAsset: true,
          });
        })
        .catch(error => {
          console.log("Error fetching and parsing data", error);
          this.setState({
            adminControlsSubmitting: false,
            adminControlsMessage: "Error occurred during asset deletion.",
            deleteAssetModalShowing: false,
          });
        });
    }
  };


  /* This function will open the add asset modal upon clicking the plus sign
    located within the asset item bar. */
  handleToggleAddStockroomAssetModal = () => {
    this.setState(prevState => ({
      showAddStockroomAssetModal: !prevState.showAddStockroomAssetModal,
      showAddVendorOrderModal: false,
      showAddPMOrderModal: false,
      addItemModalError: false,
    }));
  };

  handleUploadStockroomAssetCsv = async (data) => {
    const {uploadCsv, accountInfo, fetchDetails} = this.props;
    await uploadCsv(data, accountInfo.personnel.orgs[0].id);
    fetchDetails();
  };

  /* Creates a new stockroom asset with current AssetID in state */
  handleSubmitAddStockroomAsset = () => {
    const {asset, orgID} = this.props;

    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false,
    });
    postAxios({
      url: `${global.base_url}v1/stockroom_assets/`,
      data: {
        org: orgID,
        asset: asset.id,
        name: ""
      }})
      .then(res => {
        console.log(res);
        this.setState({
          addedStockroomAssetID: res.data.id,
          addItemModalSubmitting: false,
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          addItemModalSubmitting: false,
          addItemModalError: true,
        });
      });
  };

  /* Creates a new vendor order with current AssetID in state */
  handleSubmitAddVendorOrder = () => {
    const {asset, orgID, getAllVendors} = this.props;

    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false,
    });
    postAxios({
      url: `${global.base_url}v1/vendor_orders/`,
      data: {
        org: orgID,
        asset: asset.id,
      },
    })
      .then(res => {
        console.log(res);
        this.setState({
          addedVendorOrderID: res.data.id,
          addItemModalSubmitting: false,
        });
        getAllVendors();
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          addItemModalSubmitting: false,
          addItemModalError: true,
        });
      });
  };

  /* Creates a new PM order with current AssetID in state */
  handleSubmitAddPMOrder = () => {
    const {asset, orgID, getAllPMOrders} = this.props;
    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false,
    });
    postAxios({
      url: `${global.base_url}v1/pm_orders/`,
      data: {
        org: orgID,
        asset: asset.id,
      },
    })
      .then(res => {
        console.log(res);
        this.setState({
          addedPMOrderID: res.data.id,
          addItemModalSubmitting: false,
        });
        getAllPMOrders();
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          addItemModalSubmitting: false,
          addItemModalError: true,
        });
      });
  };


  toggleDeleteAssetModal = () => {
    this.setState({deleteAssetModalShowing: !this.state.deleteAssetModalShowing});
  };


  /* This function will open the add vendor order modal upon clicking the plus sign
 located within the asset item bar. */
  handleToggleAddVendorOrderModal = () => {
    this.setState(prevState => ({
      showAddVendorOrderModal: !prevState.showAddVendorOrderModal,
      showAddStockroomAssetModal: false,
      showAddPMOrderModal: false,
      addItemModalError: false,
    }));
  };

  /* This function will open the add PM order modal upon clicking 'Add PM Order' button. */
  handleToggleAddPMOrderModal = () => {
    this.setState(prevState => ({
      showAddPMOrderModal: !prevState.showAddPMOrderModal,
      showAddStockroomAssetModal: false,
      showAddVendorOrderModal: false,
      addItemModalError: false,
    }));
  };

  render() {
    const {
      addItemModalSubmitting,
      addItemModalError,
      showAddStockroomAssetModal,
      showAddVendorOrderModal,
      showAddPMOrderModal,
      addedStockroomAssetID,
      addedPMOrderID,
      addedVendorOrderID,
      adminControlsSubmitting,
      adminControlsMessage,
      deleteAssetModalShowing,
      deletedAsset,
    } = this.state;

    const {
      accountInfo,
      asset,
      csvMessages,
      clearCsvMessages,
    } = this.props;
    const name = asset?.name ?? "Asset";
    /* Upon adding an asset redirect to edit asset page for that particular asset. */
    if (addedStockroomAssetID) return <Redirect to={`${STOCKROOM_DASHBOARD_ROUTE}/${addedStockroomAssetID}`}/>;
    else if (addedVendorOrderID) return <Redirect to={`${VENDORS_DASHBOARD_ROUTE}/${addedVendorOrderID}`}/>;
    else if (addedPMOrderID) return <Redirect to={`${PM_ORDERS_DASHBOARD_ROUTE}/${addedPMOrderID}`}/>;
    else if (deletedAsset) return <Redirect to={ASSET_DASHBOARD_ROUTE}/>;
    const sampleData = makeSampleData(asset);

    return (
      <Fragment>
        <Heading color={"colorPrimary"}>CONTROLS</Heading>

        <Button
          block
          type={"primary"}
          className="item-details-pane-left-control add-stock-asset"
          onClick={this.handleToggleAddStockroomAssetModal}>
          Add Stockroom Asset
        </Button>
        {authSelectors.moduleEnabled(accountInfo, "vendor_orders") &&
        <Button
          block
          type={"primary"}
          className="item-details-pane-left-control add-vendor-order"
          onClick={this.handleToggleAddVendorOrderModal}>
          Add Vendor Order
        </Button>
        }
        {authSelectors.moduleEnabled(accountInfo, "pm_orders") &&
        <Button
          block
          type={"primary"}
          className="item-details-pane-left-control add-pm-order"
          onClick={this.handleToggleAddPMOrderModal}>
          Add PM Order
        </Button>
        }

        <br/>
        <AdminControlsDetails
          deleteItem={this.toggleDeleteAssetModal}
          controlsSubmitting={adminControlsSubmitting}
          controlsMessage={adminControlsMessage}
        />
        <AddItemModal
          visible={showAddStockroomAssetModal}
          itemName="Stockroom Asset"
          closeAddItemModal={() => this.setState({showAddStockroomAssetModal: false})}
          submitAddItem={this.handleSubmitAddStockroomAsset}
          addItemSubmitting={addItemModalSubmitting}
          addItemError={addItemModalError}
          handleUploadCsv={this.handleUploadStockroomAssetCsv}
          showCsv
          columns={csvColumns}
          sampleData={sampleData}
          csvMessages={csvMessages}
          clearCsvMessages={clearCsvMessages}
        />
        <AddItemModal
          visible={showAddVendorOrderModal}
          itemName="Vendor Order"
          closeAddItemModal={this.handleToggleAddVendorOrderModal}
          submitAddItem={this.handleSubmitAddVendorOrder}
          addItemSubmitting={addItemModalSubmitting}
          addItemError={addItemModalError}
        />

        <AddItemModal
          visible={showAddPMOrderModal}
          itemName="PM Order"
          closeAddItemModal={this.handleToggleAddPMOrderModal}
          submitAddItem={this.handleSubmitAddPMOrder}
          addItemSubmitting={addItemModalSubmitting}
          addItemError={addItemModalError}
        />
        <Modal
          visible={deleteAssetModalShowing}
          title={`Delete ${name || "Asset"}?`}
          onCancel={this.toggleDeleteAssetModal}
          onOk={this.handleDeleteAsset}
          isDeleteModal
        >
          <DescriptionText>Deleting this asset will remove it from all PM and Vendor Orders.</DescriptionText>
        </Modal>
      </Fragment>
    );
  }
}


AdminControls.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  asset: PropTypes.object,
  uploadCsv: PropTypes.func,
  csvMessages: PropTypes.any,
  clearCsvMessages: PropTypes.func,
  fetchDetails: PropTypes.func,
  getRecentLogs: PropTypes.func,
};

export default AdminControls;

