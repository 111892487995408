import personnelReducer from "./reducers";

export const name = "v1/personnel/";

export {default as personnelTypes} from "./types";
export {default as personnelSelectors} from "./selectors";
export {default as personnelOperations} from "./operations";
export {default as personnelActions} from "./actions";

export default personnelReducer;
