import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {DayPickerSingleDateController as BaseDatePicker, isInclusivelyAfterDay} from "react-dates";
import {DatePickerWrapper, datePopoverContainerStyles} from "../styles/styles";
import {Box, Input as BaseInput, InputGroup} from "@chakra-ui/core";
import Popover from "react-tiny-popover";
import moment from "moment";
import "react-dates/initialize";

const InputWrapper = styled(InputGroup)`
  input::-webkit-calendar-picker-indicator{
    display: none;
}

  input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
}
`;


const SingleDatePicker = (props) => {
  const {InputComponent, inputConfig, value, onChange, calendarOptions, defaultCalendarOptions, popoverOptions, wrapperProps, ...rest} = props;
  const [isFocused, setIsFocused] = useState(false);


  const getCurrentMomentValue = (date) => {
    let result = null;

    if (date) {
      const currentValue = moment(date);
      if (currentValue.isValid()) {
        result = currentValue;
      }
    }
    return result;
  };

  const handleDateChange = (date) => {
    // let parseDate = moment(date).format("YYYY-MM-DD");
    if (onChange) {
      onChange(date);
    }
  };

  const handleInputDateChange = (e) => {
    e.preventDefault();
    // console.log("input change", e.target.value);
    const newValue = e.target.value;
    // console.log(moment(newValue).year())
    // console.log(moment().year())
    if (moment(newValue).year() >= moment().year()) {
      onChange && onChange(e.target.value);
    }
  };

  const currentValue = getCurrentMomentValue(value);

  // const currentDateString = value ? currentValue.format("MM/DD/YYYY") : "Select Date";
  const inputValue = value ? moment(value).format("MM/DD/YYYY") : null;


  return (
    <Box className={"single-date-picker"} {...wrapperProps}>
      <Popover
        isOpen={isFocused}
        onClickOutside={() => setIsFocused(false)}
        {...popoverOptions}
        containerStyle={datePopoverContainerStyles}
        closeOnBlur={true}
        content={(

          <DatePickerWrapper className={"datepicker-wrapper"}>
            <BaseDatePicker
              hideKeyboardShortcutsPanel
              focused={isFocused}
              onFocusChange={({focused}) => setIsFocused(focused)} // PropTypes.func.isRequired
              onDateChange={handleDateChange}
              date={currentValue}
              {...defaultCalendarOptions}
              {...calendarOptions}
            />
          </DatePickerWrapper>
        )}
      >
        <InputWrapper>
          <InputComponent
            type="date"
            mb={1}
            onClick={() => setIsFocused(!isFocused)} defaultValue={inputValue}
            onChange={handleInputDateChange}
            {...rest}
          />

        </InputWrapper>

      </Popover>
    </Box>
  );
};

SingleDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  calendarOptions: PropTypes.object,
  defaultCalendarOptions: PropTypes.object,
  popoverOptions: PropTypes.object,
  InputComponent: PropTypes.elementType,
  inputConfig: PropTypes.object,
  wrapperProps: PropTypes.object
};

SingleDatePicker.defaultProps = {
  defaultCalendarOptions: {
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  },
  popoverOptions: {
    position: "bottom",
    align: "start"
  },
  InputComponent: BaseInput,
  inputConfig: {},
  wrapperProps: {}
};

export default SingleDatePicker;
