import React from "react";
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";

import {AutoSave} from "../../../Forms/Base";

const SearchInput = props => {
  const {
    onSubmit,
    validate,
    // values
    filter
  } = props;

  const submitSearch = (values) => {
    console.log("search value", values);
    onSubmit(values.search);
  };


  const values = {
    search: filter
  };
  return (
    <Form
      onSubmit={submitSearch}
      validate={validate}
      initialValues={values}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <div className={""}>
          <AutoSave
            debounce={1000}
            save={handleSubmit}
            isSubmitting={submitting}
            debounced={["search"]}
          />
          <Field
            component={"input"}
            name={"search"}
            className={"toolbar-row-search"}
            placeholder={"Search..."}
          />

        </div>
      )}
    />
  );
};

SearchInput.propTypes = {
  onSubmit: (values) => null,
  validate: PropTypes.func,
  values: PropTypes.object,
};

export default SearchInput;
