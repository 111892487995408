import React from "react";
import PropTypes from "prop-types";
import {Textarea, Input} from "./Inputs";
import {adapt} from "./utils";

/* This is a reusable form input element with passed in props that define
its label, input type, and more. */
const FormInput = (props) => {
  const {
    // Label,
    placeholder,
    type,
    name,
    value,
    onChange,
    readOnlyMode,
    handleClick,
    ...rest
  } = props;
  return (
    type === "textarea" ?
      <Textarea
        type={type}
        placeholder={placeholder}
        className={"item-details-form-input" + (!readOnlyMode ? "-editable" : "")}
        readOnly={readOnlyMode}
        name={name}
        value={value}
        onChange={onChange}
        {...rest}
      /> :
      <Input
        type={type}
        placeholder={placeholder}
        className={"item-details-form-input" + (!readOnlyMode ? "-editable" : "")}
        readOnly={readOnlyMode}
        name={name}
        value={value}
        onChange={onChange}
        onClick={handleClick}
        {...rest}
      />
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  handleClick: PropTypes.func,
  display: PropTypes.string
};

FormInput.defaultProps = {
  display: "block",
  onChange: (value) => console.log(value),
  handleClick: () => null,
};

export default adapt(FormInput);
