import React, {Component} from "react";
import {Button, Flex, Heading, Input} from "../Base";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModalErrorHeading} from "./styled";
import Modal from "./index";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash-es";


class TemplateModal extends Component {
  constructor(props) {
    super(props);

    let curTemplate = {fields: []};

    if (this.props.currentTemplate) {
      curTemplate = cloneDeep(this.props.currentTemplate);
      if (curTemplate.fields === undefined) {
        curTemplate.fields = [];
      }
    }

    this.state = {
      modalShowing: false,
      currentTemplate: curTemplate
    };
    if (this.state.currentTemplate.fields.length === 0) {
      this.addNewTemplateItem();
    }
  }

  handleSubmit = e => {
    const {submit, toggleModal} = this.props;
    e.preventDefault();
    submit(this.state.currentTemplate);
    toggleModal();
  };

  addNewTemplateItem = e => {
    let {currentTemplate} = this.state;
    if (e) {
      e.preventDefault();
    }
    currentTemplate.fields.push({name: "New Field"});
    this.setState({currentTemplate: currentTemplate});
  };

  deleteTemplateItem = (e, i) => {
    let {currentTemplate} = this.state;
    e.preventDefault();
    currentTemplate.fields.splice(i, 1);
    this.setState({currentTemplate: currentTemplate});
  };

  render() {
    const {
      title,
      toggleModal,
      itemError,
      visible
    } = this.props;
    let {currentTemplate} = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={toggleModal}
        onOk={this.handleSubmit}
        disableConfirmButton={!currentTemplate.name}
        title={title}
        modalConfig={{width: 700}}>
        <Flex alignItems="center">
          <Heading as="h3" textAlign="left" flex="0 0 50%">Template Name: </Heading>
          <Input
            id="templateName"
            flex="1 1 auto"
            defaultValue={currentTemplate.name}
            required
            onChange={e => {
              currentTemplate.name = e.target.value;
              this.setState({currentTemplate: currentTemplate});
            }}
          />
        </Flex>
        <Heading as="h3" textAlign="left">Template Fields: </Heading>
        <table style={{"width": "100%"}}>
          <thead style={{"fontSize": "14px"}}>
            <tr style={{"textAlign": "center"}}>
              <th colSpan={3}>Name</th>
              <th colSpan={2}>Type</th>
              <th colSpan={2}>Unit</th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {currentTemplate && currentTemplate.fields.length !== 0 &&
          currentTemplate.fields.map((item, i) => {
            return <tr key={i}>
              <td colSpan={3}>
                <Input
                  id="templateFieldName"
                  margin={"4px auto"}
                  pl={"5px"}
                  width={null}
                  value={`${item.name}`}
                  onChange={e => {
                    currentTemplate.fields[i].name = e.target.value;
                    this.setState({currentTemplate: currentTemplate});
                  }}
                />
              </td>
              <td colSpan={2}>{item.type || "input"}</td>
              <td colSpan={2}>
                <Input
                  id="templateFieldUnit"
                  margin={"4px auto"}
                  pl={"5px"}
                  width={null}
                  value={`${item.units || ""}`}
                  onChange={e => {
                    currentTemplate.fields[i].units = e.target.value;
                    this.setState({currentTemplate: currentTemplate});
                  }}
                />
              </td>
              <td>
                <FontAwesomeIcon
                  style={{cursor: "pointer"}}
                  icon="trash"
                  size="lg"
                  onClick={e => this.deleteTemplateItem(e, i)}
                />
              </td>
            </tr>;
          })}
            <tr>
              <td colSpan={8}>
                <Button
                  type={"primary"}
                  className="modal-card-button"
                  width="unset !important"
                  margin="5px auto"
                  fontWeight="bold"
                  onClick={this.addNewTemplateItem}>Add New Field</Button>
              </td>
            </tr>
          </tbody>
        </table>
        {itemError && <ModalErrorHeading>An error occurred. Please try again.</ModalErrorHeading>}
      </Modal>
    );
  }
}

TemplateModal.propTypes = {
  title: PropTypes.string,
  toggleModal: PropTypes.func,
  itemError: PropTypes.bool,
  submit: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  currentTemplate: PropTypes.object
};

TemplateModal.defaultProps = {};

export default TemplateModal;
