import React from "react";
import PropTypes from "prop-types";
import {Flex, Heading, Text} from "../../Common/Base";
import {ruleStringToText} from "../../../utils/rrules";

const CalendarInfo = props => {
  const {personnel} = props;
  return (
    <Flex p={2} flexDirection={"column"} maxW={"300px"}>
      <Heading>Available Personnel</Heading>
      {personnel.map(personnel => (
        <Flex key={personnel.id} flexDirection={"column"}>
          <Text>{personnel.name}</Text>
          {personnel.schedules.map(schedule => (
            <Flex key={schedule.id} flexDirection={"column"}>
              <Text fontSize={"md"}>Schedule: {schedule.name}</Text>
              {schedule.shifts.map(shift => (
                <Text key={shift.id} fontSize={"sm"} color={"meta"}>{ruleStringToText(shift.repeat)}</Text>
              ))}
            </Flex>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

CalendarInfo.propTypes = {
  personnel: PropTypes.array,
};

export default CalendarInfo;
