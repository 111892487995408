import React from "react";
import PropTypes from "prop-types";
import {numericalFieldHandler} from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import {Input, InputWrapper, Label, Row} from "../../../styles/styled";

const RepeatDaily = ({
  id,
  daily: {
    interval
  },
  handleChange,
  translations
}) => (
  <Row className="form-group">
    <Label width={1 / 8} textAlign={"left"}>
      {translateLabel(translations, "repeat.daily.every")}
    </Label>
    <InputWrapper width={1 / 2}>
      <Input
        id={`${id}-interval`}
        name="repeat.daily.interval"
        aria-label="Repeat daily interval"
        className="form-control"
        value={interval}
        onChange={numericalFieldHandler(handleChange)}
      />
    </InputWrapper>
    <Label width={1 / 8}>
      {translateLabel(translations, "repeat.daily.days")}
    </Label>

  </Row>
);
RepeatDaily.propTypes = {
  id: PropTypes.string.isRequired,
  daily: PropTypes.shape({
    interval: PropTypes.number.isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default RepeatDaily;
