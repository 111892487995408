import React, {useState} from "react";
import PropTypes from "prop-types";
import {Field} from "react-final-form-html5-validation";
import {Label, LabelText} from "./styled";
import {LocationModal} from "../../index";
import {Span} from "../../Base/styled";
// import {InlineButton, Button} from "../../Base/Button";
import {Button} from "Base";
import FormInput from "./FormInput";

const QuantityFormField = props => {
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const {
    label,
    name,
    isDisabled,

    locationData,
    locationList,
    isAdmin,
    handleSelectLocation
  } = props;

  return (
    <Label htmlFor={name}>
      <LabelText>{label}:</LabelText>
      <br/>
      {/* <Flex flexDirection={'row'}>*/}
      <Field
        component={FormInput}
        className={"item-details-form-input" + (!isDisabled ? "-editable" : "")}
        placeholder={label}
        readOnlyMode={isDisabled}
        min={0}
        display={"inline-block !important"}
        {...props}
      />
      <Button
        type={"primary"}
        className="item-details-form-button assign-location inline-button"
        onClick={e => {
          e.preventDefault();
          setLocationModalOpen(!locationModalOpen);
        }}>
        Pick Location
      </Button>
      {/* </Flex>*/}
      {locationData.location && locationData.location.id &&
      <Span className="assigned-location">
        <Span mr={"10px"}><b>Rung</b>: {locationData.location.level}</Span>
        <Span><b>Name</b>: {locationData.location.name}</Span>
      </Span>
      }

      <LocationModal
        visible={locationModalOpen}
        headerText={locationList.length !== 0 ? "Assign a Location" : "No locations available"}
        locationList={locationList}
        closeModal={() => setLocationModalOpen(!locationModalOpen)}
        assignedLocation={locationData}
        submit={handleSelectLocation}
        isAdmin={isAdmin}/>
    </Label>
  );
};


QuantityFormField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
  assignLocation: PropTypes.func,
  locationData: PropTypes.object.isRequired,
  locationList: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
  handleSelectLocation: PropTypes.func.isRequired,
};

QuantityFormField.defaultProps = {
  isDisabled: true
};

export default QuantityFormField;
