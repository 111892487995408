import React from "react";
import PropTypes from "prop-types";
import {DashboardTable} from "../../Common/Dashboard";
import {columns} from "./constants";

const ProductionLineTable = props => {
  const {currentPage} = props;
  return (
    <DashboardTable
      data={currentPage}
      columns={columns}
      style={{width: "100%"}}
    />
  );
};

ProductionLineTable.propTypes = {
  currentPage: PropTypes.array.isRequired
};

export default ProductionLineTable;
