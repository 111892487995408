import {getItems} from "../../base/paginator/selectors";
import selectors from "../common/selectors";

const refreshNameList = (state) => {
  const items = getItems(state);
  if (items) {
    return items;
  }
  return [];
};

const refreshFacilityList = selectors.refreshFacilityList;

export default {
  refreshNameList,
  refreshFacilityList
};
