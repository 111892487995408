import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

/* Mapping props to a function instead of the actual state helps ensure the data stays up to date. */
function refreshAccountInfo(state) {
  return state?.accountInfo ?? null;
}

/* Mapping props to a function instead of the actual state helps ensure the data stays up to date. */
function refreshAccountSettings(state) {
  return state?.settings ?? null;
}

// Const isAuthenticated = state => state.isAuthenticated;
const isPasswordChanged = state => state.isPasswordChanged;
const loginErrorMessage = state => state.errors;
const attemptingLogin = state => state.attemptingLogin;

export function isAccessTokenExpired(state) {
  const token = Cookies.get("access");

  try {
    if (token !== undefined) {
      const access = jwtDecode(token);
      return 1000 * access.exp - new Date().getTime() < 5000;
    } else if (state.access && state.access.exp) {
      return 1000 * state.access.exp - new Date().getTime() < 5000;
    }
    return true;
  } catch (error) {
    console.log(error);
    return true;
  }
}

function isRefreshTokenExpired(state) {
  // Const token = Cookies.get('refresh');

  if (state.refresh && state.refresh.exp) {
    return 1000 * state.refresh.exp - new Date().getTime() < 5000;
  }
  return true;
}

function isAuthenticated(state) {
  return !isRefreshTokenExpired(state);
}

export function accessToken(state) {
  return state?.access?.token;
}

export function refreshToken(state) {
  return state?.refresh?.token;
}

export const withCookieAuth = (headers = {}) => {
  const token = Cookies.get("access");

  return {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
};

export function withAuth(headers = {}) {
  return state => {
    if (state.auth) {
      return ({
        ...headers,
        // Authorization: `Bearer ${accessToken(state.auth)}`
      });
    } else {
      return ({
        ...headers,
        // Authorization: `Bearer ${accessToken(state)}`
      });
    }
  };
}

/**
 * Gets the UUID of the personnel
 * This is for the request_id in websocket connections
 * @param {object} state The object to get the UUID id from
 * @param {object} state.accountInfo The object containing the account info
 * @returns {number|null} The ID if it exists, otherwise null
 */
function refreshPersonnelUUID(state) {
  return state?.accountInfo?.personnel?.UUID ?? "none";
}

/**
 * Gets the default org id for the object
 * @param {object} state The object to get the org id from
 * @param {object} state.accountInfo The object containing the account info
 * @returns {number|null} The ID if it exists, otherwise null
 */
function defaultOrgID(state) {
  return state?.accountInfo?.personnel?.default_org?.id ?? null;
}

/**
 * Gets if the module is enabled
 * @param {object} accountInfo The object containing the account info
 * @param {string} moduleName The name of the module to check
 * @returns {boolean} If the module is enabled
 */
function moduleEnabled(accountInfo, moduleName) {
  let realModuleName = moduleName.split("/")[0];
  if (moduleName === "orders") realModuleName = "pm_orders";
  else if (moduleName === "vendors") realModuleName = "vendor_orders";
  return accountInfo?.personnel?.default_org?.[`${realModuleName}_enabled`] ?? true;
}

/**
 * Gets the checks if the account is an admin
 * @param {object} state The object to check the admin status of
 * @param {object} state.accountInfo The object containing the account info
 * @returns {boolean} True if the account is admin
 */
const isAdmin = (state) => {
  return state?.accountInfo?.user_type <= 1 ?? false;
};

export default {
  refreshAccountInfo,
  refreshAccountSettings,
  isAuthenticated,
  isPasswordChanged,
  loginErrorMessage,
  attemptingLogin,
  isAccessTokenExpired,
  isRefreshTokenExpired,
  accessToken,
  refreshToken,
  refreshPersonnelUUID,
  defaultOrgID,
  moduleEnabled,
  isAdmin,
};
