import {dismissSnack, showSnack} from "react-redux-snackbar";
import actions from "./actions";
import {baseOperations} from "../base";
import {ruleStringToText} from "../../utils/rrules";
import {dateUtils, snackUtils} from "../../utils";


const getAllItems = () => async dispatch => {
  dispatch(actions.getAllItems());
};

const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();

  snackUtils.createSnack("Updating Scheduled Shift", 3000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res,
    timestamp + 1,
    "Scheduled Shift",
    res.payload.name,
    dispatch,
    () => {
    });
};

const createScheduledShift = data => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Creating Schedule", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.createScheduledShift(data));
  baseOperations.handleCreateResponse(res, timestamp + 1, "Schedule", res.payload.name, dispatch, () => {
    dispatch(getAllItems());
  });
};

const updateScheduledShift = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  try {
    const res = await dispatch(actions.updateScheduledShift(id, data));

    console.log(res);
    baseOperations.handleUpdateResponse(res, timestamp + 1, "Schedule", res.payload.name, dispatch, () => {
      dispatch(getAllItems());
    });
    // Dispatch(getItem(org_id));
  } catch (error) {
    console.log("error updating schedule", error);
  }
};

const deleteScheduledShift = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  try {
    const res = await dispatch(actions.updateScheduledShift(id, data));

    console.log(res);
    dispatch(dismissSnack(timestamp));
    dispatch(
      showSnack(timestamp, {
        label: "Deleted Scheduled Shift",
        timeout: 2000,
        button: {label: "X"}
      })
    );

    // Dispatch(getItem(org_id));
  } catch (error) {
    dispatch(dismissSnack(timestamp));
    dispatch(
      showSnack(timestamp, {
        label: "Failed to delete a new Scheduled Shift",
        timeout: 2000,
        button: {label: "X"}
      })
    );
  }
};

const fetchShiftCalendar = (data) => async dispatch => {
  try {
    const res = await dispatch(actions.getCalendar(data));
    // Console.log(res);
    // dispatch(getItem(org_id));
    return res;
  } catch (error) {
    console.log("error fetching calendar", error);
  }
};

const fetchScheduleShiftCalendar = (id, data) => async dispatch => {
  try {
    const res = await dispatch(actions.getScheduleShiftCalendar(id, data));
    console.log(res);
    // Dispatch(getItem(org_id));
  } catch (error) {
    console.log("error fetching calendar", error);
  }
};

const createShift = (schedule_id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  try {
    const res = await dispatch(actions.createShift(schedule_id, data));

    console.log(res);

    baseOperations.handleCreateResponse(res, timestamp + 1, "Shift", ` ${ruleStringToText(res.payload.repeat)}`, dispatch, () => {
      dispatch(getAllItems());
    });

    return res;
    // Dispatch(getItem(org_id));
  } catch (error) {
    dispatch(dismissSnack(timestamp));
    dispatch(
      showSnack(timestamp, {
        label: "Failed to create a new Shift",
        timeout: 2000,
        button: {label: "X"}
      })
    );
  }
};

const updateShift = (schedule_id, id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  try {
    const res = await dispatch(actions.updateShift(schedule_id, id, data));

    let name = id;
    if (res.payload && res.payload.repeat) {
      name = `${ruleStringToText(res.payload.repeat)}`;
    }
    baseOperations.handleUpdateResponse(res, timestamp + 1, "Shift", name, dispatch, () => {
      dispatch(getAllItems());
    });

    return res;
    // Dispatch(getItem(org_id));
  } catch (error) {
    console.log("error updating shift", error);
  }
};

const deleteShift = (schedule_id, id) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  try {
    const res = await dispatch(actions.deleteShift(schedule_id, id));

    console.log(res);
    dispatch(dismissSnack(timestamp));
    dispatch(
      showSnack(timestamp, {
        label: "Successfully deleted Shift",
        timeout: 2000,
        button: {label: "X"}
      })
    );

    // Dispatch(getItem(org_id));
  } catch (error) {
    dispatch(dismissSnack(timestamp));
    dispatch(
      showSnack(timestamp, {
        label: "Failed to delete Shift",
        timeout: 2000,
        button: {label: "X"}
      })
    );
  }
};


/**
 * Assigns a personnel to a shift
 * @param {string} id id of schedule
 * @param {object} data {personnel: personnel_id}
 * @returns {Promise} the network request
 */
const assignPersonnel = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  try {
    const res = await dispatch(actions.assignPersonnel(id, data));

    baseOperations.handleUpdateResponse(res, timestamp + 1, "Schedule Personnel", res.payload.name, dispatch, () => {
      dispatch(getAllItems());
    });
    return res;
    // Dispatch(getItem(org_id));
  } catch (error) {
    console.log("error updating schedule", error);
  }
};

const toggleShiftActivity = (data) => async dispatch => {
  const res = await dispatch(actions.toggleShiftActivity(data));
  if (!res.error) {
    dispatch(actions.getActiveShiftActivity());

    // TODO: add additional handling for pagination
    if (res.payload && res.payload.end_time !== null) {
      dispatch(actions.getRecentShiftActivity(1));
    }
  }
  return res;
};


export default {
  updateItem,
  getAllItems,
  createScheduledShift,
  updateScheduledShift,
  deleteScheduledShift,
  fetchShiftCalendar,
  fetchScheduleShiftCalendar,
  updateShift,
  createShift,
  deleteShift,
  assignPersonnel,
  toggleShiftActivity
};
