import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {Redirect, withRouter} from "react-router-dom";
import {AddItemModal, ButtonBar, HelpButton, Calendar, TutorialModal} from "../index";
import ToolBar from "./ToolBar/ToolBar";
import {Box, ErrorHeading, Heading, Loading, Checkbox, Button} from "../Base";
import {PaginationControl} from "../Paginator";
import {EDIT_PARAM} from "../../../routes/constants";
import BannerOne from "../../../static/img/BannerOne.png";
import {LIST, TABLE, TILE, CALENDAR} from "store/base/crud/utils";
import voca from "voca";
import {paginationProps} from "../../../types/propTypes";
import {formatCalendarRange} from "../../../utils/dates";
import qs from "query-string";
import {scrollToTop} from "../../../utils/containerUtils";

class DashboardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedID: "",
      showAddItemModal: false,
      addItemModalSubmitting: false,
      addItemModalError: false,
      showTutorialModal: true,
      showImportInventoryModal: false,
      showTutFromHelp: false,
      errorLoadingMessage: "",
      selectedRowsIds: [],
      showDelete: false,
      deleteAllIsChecked: false,
      filter: {params: {}},
      sortBy: "created_at",
      search: null,
      sortDirection: "-",
      clickedCalendarID: null,
      // for props.location.search to quickly check for changes
      urlSearch: "",
      // parsed urlSearch
      urlParams: {}
    };
  }

  /* Upon mounting, fetch a list of products and store the array in redux. They
    will be rendered using the map method. */
  componentDidMount = () => {
    this.handleMount();
    scrollToTop();
  };

  handleMount = async () => {
    const {fetchData} = this.props;
    const res = await this.handleRefreshPage();
    if (!res.error && res.payload.count > 0) {
      this.setState({showTutorialModal: false});
    }
    fetchData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.urlSearch !== nextProps.location.search) {
      const urlParams = qs.parse(nextProps.location.search);
      return {urlParams, urlSearch: nextProps.location.search};
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.urlSearch !== this.state.urlSearch) {
      this.handleRefreshPage();
    }
    if (prevProps.submitCreate === false && this.props.submitCreate === true) {
      this.handleSubmitAddItem();
    }
  }

  handleUploadCsv = async (data) => {
    const {uploadCsv, accountInfo} = this.props;
    await uploadCsv(data, accountInfo.personnel.orgs[0].id);
    this.handleRefreshPage();
  };

  handleRefreshPage = (params = {}) => {
    const {search, sortBy, sortDirection} = this.state;
    const {getPage, pagination, location} = this.props;
    const {itemsCurrentPage} = pagination;
    const urlParams = qs.parse(location.search);
    let finalParams = {
      ordering: `${sortDirection}${sortBy}`,
      ...urlParams,
      ...params
    };
    if (search) {
      params.search = search;
    }

    if (itemsCurrentPage) {
      return getPage(itemsCurrentPage, finalParams);
    } else {
      return getPage(1, finalParams);
    }// sortProduct("created_at");
  };

  handleReverseList = () => {
    const {sortDirection, sortBy} = this.state;
    const newDirection = sortDirection === "-" ? "" : "-";
    this.setState({sortDirection: newDirection});
    this.handleRefreshPage({ordering: `${newDirection}${sortBy}`});
  }

  /* This function will open the add product modal upon clicking the plus sign
    located within the product item bar. */
  handleOpenAddItemModal = () => {
    this.setState({
      showAddItemModal: true,
    });
  };

  /* This function will close the modal upon clicking the X on the top right. */
  handleCloseAddItemModal = () => {
    this.setState({
      showAddItemModal: false,
    });
  };

  handleTutFromButton = () => {
    this.setState({
      showTutFromHelp: !this.state.showTutFromHelp,
    });
  };

  handleCloseTutorialModal = () => {
    this.setState({
      showTutorialModal: false,
      showTutFromHelp: false,
    });
  };

  /* This handles search field input and dispatches filterProduct to redux. */
  handleSearch = (filter) => {
    // e.preventDefault();
    const {getPage} = this.props;
    this.setState({search: filter});
    getPage(1, {search: filter});
  };

  /* This handles selection field input and dispatches filterProduct to redux. */
  updateSortBy = e => {
    const {sortDirection} = this.state;
    // // const {sortProduct} = this.props;
    // sortProduct(e.value);
    this.setState({sortBy: e.value});
    this.handleRefreshPage({ordering: `${sortDirection}${e.value}`});
  };

  /* Handles filtering from the ToolBar  */
  handleFilterBy = filter => {
    // const {filterField,}
    // const {location, filterListBy, history} = this.props;
    // handleFilterDropdownChange(e, "filterby", "orders", filterListBy, location, history);
    console.log("update filter", filter);

    this.setState({filter: filter});
    this.handleRefreshPage(filter.params);
  }


  /* This function will perform the API request required to add a new product.
     The new product might have one attribute defined with key and value pair,
     the user will be forwarded to the Edit Product page for the new product
     where they can then enter details. */
  handleSubmitAddItem = async (key = "", value = "") => {
    const {accountInfo, createItem, initialValues} = this.props;

    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false,
    });

    const dataToSubmit = {
      org: accountInfo.personnel ? accountInfo.personnel.orgs[0].id : null,
      ...initialValues,
    };
    // Add data if provided
    if (key !== "" && value !== "") {
      dataToSubmit[key] = value;
    }

    const res = await createItem(dataToSubmit);
    console.log("res", res);
    if (res.error) {
      this.setState({
        addItemModalSubmitting: false,
        addItemModalError: true,
      });
    } else {
      this.setState({
        addedID: res.payload.id,
        addItemModalSubmitting: false,
      });
    }
  };

  /**
   * Sets Dashboard selected rows to the Table selected rows
   * @param {Array} selectedRowsIds - an array passed from the table component
   */
  onTableSelectChange = (selectedRowsIds) => {
    this.setState({selectedRowsIds: selectedRowsIds});
  };

  /**
   * Takes user input from a table field and PATCHes the item
   * @param {Array} data - An array of objects passed from the table component
   */
  handleTableFieldChange = async data => {
    let updateData;
    const {updateDetails} = this.props;
    const newData = data[0];
    const index = newData.dataIndex;
    const id = newData.key;
    if (index === "manufacturer_name") {
      updateData = {"manufacturer": newData["manufacturer_name"]};
    } else {
      updateData = {
        [index]: newData[index]
      };
    }
    await updateDetails(id, updateData);
  }

  /**
   * Take user selections from state and and uses redux RSAA call to delete the user selections
   */
  onDeleteSelected = () => {
    const {selectedRowsIds} = this.state;
    const {bulkDelete} = this.props;
    bulkDelete(selectedRowsIds);
    this.setState({
      deleteAllIsChecked: false,
      selectedRowsIds: [],
      showDelete: false
    });
  };

  /**
   * Add or remove ID's to state and handle whether or not the all checked checkbox
   * should be checked.
   * @param {number} id - item ID associated with the clicked checkbox
   */
  onCheckChange = (id) => {
    const {selectedRowsIds} = this.state;
    const {itemsPaged} = this.props.pagination;
    const idLen = selectedRowsIds.length;
    const allIds = itemsPaged.map(item => item.id);
    if (!selectedRowsIds.includes(id)) {
      if (idLen + 1 === allIds.length) {
        this.setState({deleteAllIsChecked: true});
      }
      this.setState({selectedRowsIds: [id, ...selectedRowsIds]});
    } else {
      this.setState({
        selectedRowsIds: selectedRowsIds.filter(x => x !== id),
        deleteAllIsChecked: false
      });
    }
  };

  /**
   * Add all items to this.state.selectedRowsIds when select all is checked, and remove them when
   * unchecked
   */
  handleDeleteAllIsChecked = () => {
    if (!this.state.deleteAllIsChecked) {
      this.setState({
        selectedRowsIds: this.props.pagination.itemsPaged.map(x => x.id),
        deleteAllIsChecked: true
      });
    } else {
      this.setState({
        selectedRowsIds: [],
        deleteAllIsChecked: false
      });
    }
  };

  handleShowDelete = () => {
    this.setState({
      showDelete: !this.state.showDelete,
      selectedRowsIds: [],
      deleteAllIsChecked: false
    });
  };

  handleDateChange = (event) => {
    const {getPage, onDateChange} = this.props;
    const dateRange = formatCalendarRange(event);
    return onDateChange ? onDateChange(1, dateRange) : getPage(1, dateRange);
  };

  handleCalendarEvent = (event) => {
    this.setState({clickedCalendarID: event.id});
  };

  render() {
    const {
      addItemModalSubmitting,
      addItemModalError,
      showAddItemModal,
      addedID,
      showTutFromHelp,
      showTutorialModal,
      errorLoadingMessage,
      sortDirection,
      filter,
      clickedCalendarID,
      showDelete,
      deleteAllIsChecked,
      selectedRowsIds
    } = this.state;

    const {
      changeView,
      accountInfo,
      getPage,
      pagination,
      type,
      buttonBarActions,
      ListItem,
      Table,
      tutorialText,
      csvOptions,
      sortByOptions,
      filterOptions,
      newItemUrl,
      showCsv,
      showCalendar,
      title,
      calendarOptions,
      calendarUrl,
      extraCreationModalButtons,
      readOnly,
    } = this.props;

    const {
      loading,
      pageSize,
      pageView,
      itemsPaged,
      itemsCurrentPage,
      itemsCount,
      isEmpty,
    } = pagination;

    const {
      csvMessages,
      clearMessages,
      csvColumns,
      csvData,
      csvHelpText
    } = csvOptions;
    // Privileges for editing asset
    const productEditAccess = accountInfo.user_type <= 2;

    /* Upon adding an item redirect to edit item page for that particular
       item. */
    if (addedID) {
      return <Redirect to={`${newItemUrl}/${addedID}?${EDIT_PARAM}`}/>;
    }

    const listView = pageView === LIST;

    const typeTitle = title ? title : voca.titleCase(type);

    const isReversed = sortDirection === "-";
    if (clickedCalendarID) {
      const redirectUrl = calendarUrl ?? newItemUrl;
      return <Redirect to={`${redirectUrl}${clickedCalendarID}`}/>;
    }
    return (
      <Fragment>
        {/* {!loading && (*/}
        <ToolBar
          handleShowDelete={this.handleShowDelete}
          showDelete={showDelete}
          handleChange={this.handleSearch}
          updateSortBy={this.updateSortBy}
          reverseList={this.handleReverseList}
          changeViewList={() => changeView(LIST)}
          changeViewTile={() => changeView(TILE)}
          changeViewTable={() => changeView(TABLE)}
          changeViewCalendar={() => changeView(CALENDAR)}
          listView={pageView === LIST}
          tileView={pageView === TILE}
          tableView={pageView === TABLE}
          calendarView={pageView === CALENDAR}
          showCalendar={showCalendar}
          selectionOptions={sortByOptions}
          filterOptions={filterOptions}
          filterListBy={this.handleFilterBy}
          filteringBy={filter}
          handleAddItemButtonClick={this.handleOpenAddItemModal}
          objectType={typeTitle}
          withPagination={false}
          isReversed={isReversed}
          readOnly={readOnly}
        />
        {showDelete && !this.props.calendarView &&
        <Box style={{marginBottom: "10px"}} display="flex" justifyContent={pageView !== TABLE ? "space-between" : "flex-end"}>
          {pageView !== TABLE &&
          <Checkbox id="deleteSelectAll" checked={deleteAllIsChecked} onClick={this.handleDeleteAllIsChecked}>Select all</Checkbox>}
          <div>
            <Button
              id="confirmDeleteButton"
              onClick={this.onDeleteSelected}
              danger={selectedRowsIds.length !== 0}
              disabled={selectedRowsIds.length === 0}
            >Confirm Delete {selectedRowsIds.length + "/" + itemsPaged.length}</Button>
          </div>
        </Box>
        }
        {/* )}*/}

        <Box p={1} pl={[2, 1]}>
          <PaginationControl
            pageSize={pageSize}
            current={itemsCurrentPage}
            total={itemsCount}
            onChange={getPage}
          />
        </Box>
        <div className="row">
          <div className={listView ? "items-flex-row" : "items-flex-column"}>
            {loading &&
            <Loading title={`Loading ${typeTitle}s...`}/>
            }
            {!loading && productEditAccess && !readOnly &&
            <div>
              <div data-tip="Open a help popup" data-delay-show="1000">
                <HelpButton handleClick={this.handleTutFromButton}/>
              </div>
              <div data-tip={`Add a ${type}.`} data-delay-show="1000">
                <ButtonBar
                  handleClick={this.handleOpenAddItemModal}
                  actions={buttonBarActions}
                />
              </div>
            </div>
            }

            {[LIST, TILE].includes(pageView) && itemsPaged.length > 0
            && itemsPaged.map(item => (
              <ListItem
                key={item.id}
                data={item}
                listView={listView}
                onCheckChange={this.onCheckChange}
                showDelete={this.state.showDelete}
                selectedRowsIds={this.state.selectedRowsIds}
                url={newItemUrl}
              />
            ))
            }
          </div>

        </div>
        {pageView === TABLE && itemsPaged
        &&
        <Table
          handleFieldChange={this.handleTableFieldChange}
          currentPage={itemsPaged}
          allItems
          selectedRowsIds={this.state.selectedRowsIds}
          onTableSelectChange={this.onTableSelectChange}
        />
        }

        {showCalendar && pageView === CALENDAR &&
        <Calendar
          items={itemsPaged}
          onEventClick={this.handleCalendarEvent}
          onRangeChange={this.handleDateChange}
          {...calendarOptions}
        />
        }

        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}
        {isEmpty && !loading && <Heading as={"h1"} textAlign={"center"}>No {typeTitle} Found</Heading>}

        <TutorialModal
          visible={(itemsPaged.length === 0 && showTutorialModal && !loading) || (showTutFromHelp)}
          closeModal={this.handleCloseTutorialModal}
          banner={BannerOne}
          type={typeTitle}
          descriptions={tutorialText}
          handleOpenAddItemModal={[this.handleOpenAddItemModal, "Create One!"]}/>

        {!readOnly &&
          <AddItemModal
            visible={showAddItemModal}
            itemName={typeTitle}
            closeAddItemModal={this.handleCloseAddItemModal}
            submitAddItem={this.handleSubmitAddItem}
            addItemSubmitting={addItemModalSubmitting}
            addItemError={addItemModalError}
            handleUploadCsv={this.handleUploadCsv}
            columns={csvColumns}
            showCsv={showCsv}
            csvMessages={csvMessages}
            clearCsvMessages={clearMessages}
            sampleData={csvData}
            csvHelpText={csvHelpText}
            extraButtons={extraCreationModalButtons}
          />
        }
      </Fragment>
    );
  }
}

DashboardList.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  /**
   * ListItem or Bar component for List/Tile view that takes props:
   * data: object,
   * listView: boolean
   * onCheckChange: function
   * showDelete: boolean
   selectedRowsIds: array of integers
   */
  ListItem: PropTypes.elementType.isRequired,
  /**
   * For Table view, accepts the below props:
   handleFieldChange: function,
   currentPage: number,
   selectedRowsIds: array
   onTableSelectChange: function
   */
  Table: PropTypes.elementType.isRequired,
  /**
   * Redux pagination for Dashboard type
   */
  pagination: paginationProps.isRequired,
  /**
   * function: (page =1, params = {}) => RSAA response
   */
  getPage: PropTypes.func.isRequired,
  /**
   * function: ([selectedIds]) => RSAA call to delete array of selected id's
   */
  bulkDelete: PropTypes.func,
  /**
   * redux function to change from Tile/List/Table views
   */
  changeView: PropTypes.func.isRequired,
  /**
   * For fetching extra data outside of what getPage fetches
   */
  fetchData: PropTypes.func,
  uploadCsv: PropTypes.func,
  createItem: PropTypes.func.isRequired,
  /**
   * For rendering in buttons and title's
   */
  type: PropTypes.string.isRequired,
  /**
   * If title case of type does not work, override with this
   */
  title: PropTypes.string,
  /**
   * array of strings for tutorial
   */
  tutorialText: PropTypes.array,
  /**
   * Extra actions specific to type
   */
  buttonBarActions: PropTypes.array,
  csvOptions: PropTypes.shape({
    csvMessages: PropTypes.any,
    clearMessages: PropTypes.func,
    csvColumns: PropTypes.array,
    csvData: PropTypes.any,
    csvHelpText: PropTypes.arrayOf(PropTypes.string)
  }),
  /**
   * Initial values for new items created
   */
  initialValues: PropTypes.object,
  /**
   * Base url to redirect created items
   */
  newItemUrl: PropTypes.string,
  showCsv: PropTypes.bool,
  showCalendar: PropTypes.bool,
  onDateChange: PropTypes.func,
  /**
   * Props for Common/Calendar component
   */
  calendarOptions: PropTypes.shape({
    startAccessor: PropTypes.string,
    endAccessor: PropTypes.string
  }),
  /**
   * overrides newItemUrl for clicking on calendar items
   */
  calendarUrl: PropTypes.string,
  /**
   * label/value/field filter options array
   */
  filterOptions: PropTypes.array,
  /**
   * label/value sort by options array
   */
  sortByOptions: PropTypes.array,
  /**
   * Extra buttons to show on the creation modal
   */
  extraCreationModalButtons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    config: PropTypes.object,
  })),
  /**
   * Pass true to call this.handleSubmitAddItem after setting initialValues
   */
  submitCreate: PropTypes.bool,
  /**
   * Pass true to disable add functionality
   */
  readOnly: PropTypes.bool,
};

DashboardList.defaultProps = {
  withPagination: true,
  buttonBarActions: [],
  fetchData: () => null,
  initialValues: {
    name: "",
    manufacturer: "",
    sku: "",
  },
  csvOptions: {
    csvMessages: [],
    clearMessages: () => null,
    csvColumns: [],
    csvData: [],
    csvHelpText: []
  },
  showCsv: true,
  showCalendar: false,
  onDateChange: null,
  filterOptions: [],
  sortByOptions: [],
  title: null,
  calendarOptions: {
    startAccessor: "start",
    endAccessor: "end",
  },
  calendarUrl: null,
  extraCreationModalButtons: [],
  submitCreate: false,
  readOnly: false,
};

export default withRouter(DashboardList);
