import React, {Component} from "react";
import {Box, Flex} from "../Base";

/* Keystroke types used by this tag component's functions. */
const ENTER_KEY = 13;
const BACKSPACE_KEY = 8;
const COMMA_KEY = 188;

/* This handles functionality related to a tag array and various front end inputs. */
class TagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      value: "",
      editValue: "",
      editIndex: -1,
    };
  }

  /* Upon mounting, set the list of tags. */
  componentDidMount() {
    const {tagList} = this.props;
    this.setState({tags: tagList});
  }

  /* Update state for edit value. */
  handleEditChange = e => {
    this.setState({editValue: e.target.value});
  };

  /* Perform edit tag upon typing enter. */
  handleEditKeyUp = e => {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) this.editTag();
  };

  /* The primary function for editing a tag, or removing one. */
  editTag = () => {
    const {tags, editValue, editIndex} = this.state;
    const {handleUpdate} = this.props;
    let editedTag = editValue.trim();
    let newTags = [];

    editedTag = editedTag.replace(/,/g, "");

    // If tag has all elements removed, remove it from the tags array.
    // Otherwise, update array with potentially modified tag.
    if (editedTag === "") {
      let i = 0;
      while (i < tags.length) {
        if (i !== editIndex) {
          newTags.push(tags[i]);
        }
        i += 1;
      }
    } else {
      newTags = tags;
      newTags[editIndex] = editedTag;
    }

    // Apply the changes.
    handleUpdate(newTags);
    this.setState({
      tags: newTags,
      editValue: "",
      editIndex: -1
    });
  };

  /* This will toggle between edit and non-edit state. */
  toggleEdit = e => {
    const {editIndex, tags} = this.state;

    this.setState({
      editIndex: e.target.value === editIndex ? -1 : parseInt(e.target.value),
      editValue: e.target.value === editIndex ? "" : tags[e.target.value]
    });
  };

  /* Cancel edit mode. */
  exitEdit = () => {
    this.setState({
      editIndex: -1,
      editValue: ""
    });
  };

  /* Handle tag edit. */
  addTag = () => {
    const {tags, value} = this.state;
    const {handleUpdate} = this.props;
    let tag = value.trim();

    tag = tag.replace(/,/g, "");

    if (!tag) return;

    handleUpdate([...tags, tag]);
    this.setState({
      tags: [...tags, tag],
      value: ""
    });
  }

  /* Upon pressing a key, evaluate the key type. */
  handleKeyDown = (e) => {
    const key = e.keyCode;
    const {value} = this.state;

    if (key === BACKSPACE_KEY && !value) this.editPrevTag();
  }

  /* Upon pressing a key, evaluate the key type. */
  handleKeyUp = (e) => {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) this.addTag();
  }

  /* Perform a change. */
  handleChange = (e) => {
    this.setState({value: e.target.value});
  }

  /* Edit the previous tag. */
  editPrevTag = () => {
    const {tags} = this.state;
    const tag = tags.pop();

    this.setState({tags, value: tag});
  }

  render() {
    const {
      tags,
      value,
      editIndex,
      editValue
    } = this.state;

    return (
      <Flex flexDirection="column" className="tags">
        <Box className="tags-list">
          <ul>
            {tags.map((tag, i) => (
              <li
                className={editIndex === i ? "tag-edit" : "tag"}
                key={i}
                value={i}
              >
                {editIndex === i ? (
                  <div>
                    <input
                      className="tag-edit-input"
                      type="text"
                      value={editValue}
                      onChange={this.handleEditChange}
                      onKeyUp={this.handleEditKeyUp}
                      onBlur={this.exitEdit}
                      autoFocus
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    value={i}
                    onClick={this.toggleEdit}
                  >
                    {tag}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </Box>
        <Box>
          <input
            type="text"
            placeholder="Add tag..."
            value={value}
            onChange={this.handleChange}
            className="tags-input"
            onKeyUp={this.handleKeyUp}
            onKeyDown={this.handleKeyDown}
          />
        </Box>
      </Flex>
    );
  }
}

export default TagInput;
