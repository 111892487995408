import {createCrud} from "../base/crud";

const notificationEndpoint = "v1/notifications/";

const notificationsModule = createCrud({
  endpoint: notificationEndpoint,
  name: "notifications",
  withPagination: true
});

const {
  actions,
  operations,
  types
} = notificationsModule;

export const receiveNotification = (data) => ({
  type: types.GET_ITEM_SUCCESS,
  payload: data
});

export {actions, operations, types};

export default notificationsModule;
