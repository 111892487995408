import PropTypes from "prop-types";
import React from "react";
import {postLoadFormat} from "./utils";
import {formatMaterialOptions} from "../../../store/materials/selectors";
import {Form} from "react-final-form";
import EditableMaterialDropdown from "../../Common/Forms/Base/EditableMaterialDropdown";
import {Flex} from "../../Common/Base";
import MaterialBar from "./MaterialBar";
import {SubmitButton} from "../../Common/Forms/styled";


const OrderMaterialForm = props => {
  const {
    onSubmit,
    validate,
    initialValues,
    editAccess,
    accountUserType,
    accountPersonnelId,
    materialList,
    createOrderMaterial,
    updateOrderMaterial,
    deleteOrderMaterial,
    addMaterialToOrder,
    deleteMaterialFromOrder,
  } = props;

  const postLoadValues = postLoadFormat(initialValues);
  const materialOptions = formatMaterialOptions(materialList);

  const submit = (values) => {
    console.log("material submit values", values);
    onSubmit(values);
  };
  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={postLoadValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form id="pm-material-form" className={"item-details-form"} onSubmit={handleSubmit}>

          <EditableMaterialDropdown
            label="Order Materials"
            name="materials"
            dropdownName="Order Material"
            options={materialOptions}
            org={initialValues?.org?.id ?? null}
            canChangeSelection={true}
            canCreateSelections={accountUserType <= 1}
            includeNone={false}
            handleCreateField={createOrderMaterial}
            handleUpdateField={updateOrderMaterial}
            handleDeleteField={deleteOrderMaterial}
            handleAddFieldToObject={addMaterialToOrder}
          />
          <Flex flexWrap="wrap" mb="15px">
            {initialValues.materials &&
            initialValues.materials.map((material, index) => (
              <MaterialBar
                key={index}
                materialDetails={material}
                accountUserType={accountUserType}
                accountPersonnelId={accountPersonnelId}
                deleteMaterialFromOrder={deleteMaterialFromOrder}
              />
            ))}
          </Flex>
          {editAccess && (
            <SubmitButton className="item-details-form-button" loading={submitting}>
              Save
            </SubmitButton>
          )}
        </form>
      )}
    />
  );
};

OrderMaterialForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  values: PropTypes.object.isRequired,
  assetList: PropTypes.array,
  accountUserType: PropTypes.number.isRequired,
  accountPersonnelId: PropTypes.number.isRequired,
  stockAssetList: PropTypes.array,
  editAccess: PropTypes.bool,
  selectedMaterial: PropTypes.object,
  repeating: PropTypes.bool,
  createOrderMaterial: PropTypes.func.isRequired,
  updateOrderMaterial: PropTypes.func.isRequired,
  deleteOrderMaterial: PropTypes.func.isRequired,
  addMaterialToOrder: PropTypes.func.isRequired,
  orderInspectionNoteVisible: PropTypes.bool,
  availablePersonnel: PropTypes.array,
  /**
   * If the Asset module is enabled for this org
   */
  assetsEnabled: PropTypes.bool
};

OrderMaterialForm.defaultProps = {
  values: {},
  editAccess: false,
  orderInspectionNoteVisible: true,
};

export default OrderMaterialForm;
