import {cloneDeep} from "lodash-es";
import React from "react";
import moment from "moment-timezone";

const dateFormat = "YYYY-MM-DD";
export const basePostLoadFormat = (values) => {
  let result = values;

  return result;
};

export const parseDate = (value) => {
  return value ? moment(value).format("YYYY-MM-DD") : null;
};

export const parseText = (value) => {
  return value === undefined ? "" : value;
};

export const deleteUnwantedProps = values => {
  delete values.created_at;
  delete values.updated_at;
  delete values.permissions;
  delete values.org;
  delete values.UUID;
  delete values.barcode;
  delete values.created_by;

  return values;
};

/**
 * Should be called in all form's preSaveFormat functions.
 * Creates a deep copy of the input values, so that the form's props aren't affected
 * @param {object} values The input form values
 * @returns {object} The values formatted how all forms should have them formatted
 */
export const basePreSaveFormat = (values) => {
  // Do a deep copy so that the form's props aren't affected
  let result = cloneDeep(values);

  return deleteUnwantedProps(result);
};


export const basePreSaveFormatArray = (values) => {
  let results = cloneDeep(values);
  return results.map(result => {
    result = deleteUnwantedProps(result);
    return result;
  });
};

export const adapt = Component => ({input, meta: {valid}, ...rest}) =>
  <Component {...input} {...rest} valid={valid}/>;

export const antAdapt = Component => ({input, meta: {valid}, readOnlyMode, isSelect, handleClick, withValidation, ...rest}) =>
  <Component {...input} {...rest} valid={valid.toString()}/>;

export const antSelectAdapt = Component => ({input: {value, ...inputRest}, meta: {valid}, ...rest}) => {
  console.log("input value", value);
  return <Component value={value} valid={valid.toString()} {...inputRest} {...rest}/>;
};

export const antDateAdapter = Component => ({input: {onChange, value, ...inputRest}, label, ...rest}) => (
  <Component onChange={onChange} value={value ? moment(value, dateFormat) : null} label={label} {...rest} {...inputRest}/>
);

export const dateAdapter = Component => ({input: {onChange, value, ...inputRest}, label, ...rest}) => (
  <Component onChange={onChange} value={value} label={label} {...rest} {...inputRest}/>
);


export const dateRangeAdapter = Component => ({input: {onChange, value, ...inputRest}, label, ...rest}) => (
  <Component onChange={onChange} value={value} label={label} {...rest} {...inputRest}/>
);
