import React from "react";
import PropTypes from "prop-types";
import {Bottom, SeeMoreButton, SideList, Wrapper} from "../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../Common/Bars/BarComponents/constants";
import {parseLocation} from "../../Warehouse/Pallets/Bar/utils";

const ProductionLineBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const baseClassName = listView ? listStyle : tileStyle;
  const locationInfo = parseLocation(data);
  const leftList = [
    {title: "NAME", value: data.name || "None"},
    {title: "STATUS", value: data.status_display || "None"},
    {title: locationInfo.label, value: locationInfo.value}
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="production/lines"
      itemData={data}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
    </Wrapper>
  );
};

ProductionLineBar.propTypes = {
  data: PropTypes.object,
};

export default ProductionLineBar;
