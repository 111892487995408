import React from "react";
import {formatStartEndTime} from "./utils";

export const entryColumns = [
  {
    title: "Time",
    key: "time",
    render: (text, record) => (
      <span>{formatStartEndTime(record)}</span>
    ),
    // fixed: "left",
    width: 110
  },
  {
    title: "Job",
    editable: false,
    dataIndex: ["sales_order", "reference"],
    key: "job",
    width: 75
  },
  {
    title: "Product",
    editable: false,
    dataIndex: ["product", "sku"],
    key: "product",
    width: 75
  },
  {
    title: "People Planned",
    dataIndex: "people_planned",
    key: "people_planned",
    responsive: ["md"],
    editable: true,
    width: 75,
  },
  {
    title: "Actual",
    dataIndex: "people_actual",
    key: "people_actual",
    editable: true,
    width: 50
  },
  {
    title: "Capactiy",
    dataIndex: "capacity",
    key: "capacity",
    responsive: ["md"],
    width: 80,
    editable: true
  },

  {
    title: "Produced",
    dataIndex: "produced",
    key: "produced",
    ellipsis: true,
    editable: true,
    width: 80,
  },
];
