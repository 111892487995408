import qs from "query-string";

/**
 * Checks for edit flag in router location search
 * @param {{search: string}} location - location from react router
 * @returns {boolean} - True if edit=true in url search
 */
export const checkIsNew = (location) => {
  const searchParams = qs.parse(location?.search ?? "");
  return Boolean(searchParams?.edit ?? "false" === "true");
};
