import types from "./types";
import {palletEndpoint as endpoint, palletDetailsExpand} from "./constants";
import {withAuth} from "../../auth/selectors";
import base from "../../base/actions";
import {palletBarcodes} from "./reducers";
import {inventoryEndpoint} from "../products/constants";


/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getAllItems = (filter) => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${filter}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});

const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/?${palletDetailsExpand}`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

const createItem = data => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  body: JSON.stringify(data),
  types: [types.CREATE_ITEM_REQUEST, types.CREATE_ITEM_SUCCESS, types.CREATE_ITEM_FAILURE]
});

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const deleteItem = id => base.deleteRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [
    types.DELETE_ITEM_REQUEST,
    {
      type: types.DELETE_ITEM_SUCCESS,
      meta: {id}
    },
    types.DELETE_ITEM_FAILURE]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});

const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

const uploadCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}csv/${org}/`,
  headers: withAuth({}),
  body: data,
  types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE]
});

/* SimplePagination actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  payload: by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

const filterListBy = query => ({
  type: types.FILTER_LIST_BY,
  payload: query
});

const reverseList = () => ({
  type: types.REVERSE_LIST
});

/* View related actions. */
const changeViewList = () => ({
  type: types.CHANGE_VIEW_LIST
});

const changeViewTile = () => ({
  type: types.CHANGE_VIEW_TILE
});

const changeViewTable = () => ({
  type: types.CHANGE_VIEW_TABLE
});

const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});


const getInventory = (filter = "") => base.getRsaa({
  endpoint: `${global.base_url}${inventoryEndpoint}${filter}`,
  types: [types.GET_INVENTORY_REQUEST, types.GET_INVENTORY_SUCCESS, types.GET_INVENTORY_FAILURE]
});

const addInventory = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/products/`,
  body: JSON.stringify(data),
  types: [types.ADD_INVENTORY_REQUEST, types.ADD_INVENTORY_SUCCESS, types.ADD_INVENTORY_FAILURE]
});

const receiveInventory = (palletId, id, data) => base.putRsaa({
  endpoint: `${global.base_url}v1/warehouse/pallets/${palletId}/products/${id}/receive/`,
  body: JSON.stringify(data),
  types: [types.RECEIVE_INVENTORY_REQUEST, types.RECEIVE_INVENTORY_SUCCESS, types.RECEIVE_INVENTORY_FAILURE]
});

const addMaterial = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/materials/`,
  body: JSON.stringify(data),
  types: [types.ADD_INVENTORY_REQUEST, types.ADD_INVENTORY_SUCCESS, types.ADD_INVENTORY_FAILURE]
});


const receiveMaterial = (palletId, id, data) => base.putRsaa({
  endpoint: `${global.base_url}v1/warehouse/pallets/${palletId}/materials/${id}/receive/`,
  body: JSON.stringify(data),
  types: [types.RECEIVE_INVENTORY_REQUEST, types.RECEIVE_INVENTORY_SUCCESS, types.RECEIVE_INVENTORY_FAILURE]
});

const pickupPallet = (id) => base.putRsaa({
  endpoint: `${global.base_url}v1/warehouse/pallets/${id}/pick_up/`,
  body: {},
  types: [types.PICK_UP_PALLET_REQUEST, types.PICK_UP_PALLET_SUCCESS, types.PICK_UP_PALLET_FAILURE]
});

export default {
  ...palletBarcodes.actions,
  getAllItems,
  getItem,
  createItem,
  updateItem,
  deleteItem,
  getItemNotes,
  submitNote,

  uploadCsv,

  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  filterListBy,
  reverseList,
  changeViewList,
  changeViewTile,
  changeViewTable,

  clearMessages,

  getInventory,
  addInventory,
  receiveInventory,
  receiveMaterial,
  addMaterial,

  pickupPallet
};
