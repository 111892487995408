import React, {Component} from "react";
import {connect} from "react-redux";

import {Redirect} from "react-router-dom";
import {authOperations, authSelectors} from "../../store/auth";

import CompanyLogo from "../../static/img/conmitto_logo_color.png";
import PasswordForm from "../../components/Common/Forms/PasswordForm";

class PasswordContainer extends Component {
  submitNewPassword = values => {
    const {oldPassword, newPassword} = values;
    this.props.confirmInvitation(oldPassword, newPassword);
  };

  render() {
    const {passwordChanged, authErrorMessage} = this.props;

    if (passwordChanged) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="login">
        <div className="login-card">
          <img
            src={CompanyLogo}
            className="login-card-logo"
            alt="Conmitto logo"
          />
          <h2 className="login-card-header-text">CHANGE YOUR PASSWORD</h2>
          {authErrorMessage && <div className="login-card-msg-error"> {authErrorMessage}</div>}
          <div className="login-card-form">
            <PasswordForm onSubmit={this.submitNewPassword}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authErrorMessage: authSelectors.loginErrorMessage(state.account),
  passwordChanged: authSelectors.isPasswordChanged(state.account)
});

const mapDispatchToProps = dispatch => ({
  confirmInvitation: async (oldP, newP) => dispatch(await authOperations.confirmInvitation(oldP, newP))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordContainer);
