/**
 * Creates a route to be used with RSAA requests.
 * Prepends global.base_url to the string, and appends a trailing
 * /, if it doesn't exist
 * @param {string} route The main route
 * @returns {string} The route to use as the URL for the RSAA request
 */
const constructRoute = route => {
  if (route.slice(-1) !== "/") {
    route += "/";
  }
  return `${global.base_url}${route}`;
};

export {
  constructRoute
};
