import React from "react";
import {basePreSaveFormat} from "../../../Common/Forms/Base/utils";
import {palletStatus} from "../../utils";
import {PURCHASE_ORDER} from "../../utils/constants";

export const load = async (values) => {
};

export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values, order, orderType) => {
  const {reference, location, id, org} = order;
  return {
    ...values,
    reference,
    location: location && location.id ? location.id : null,
    [orderType]: id,
    org: org.id,
    status: orderType === PURCHASE_ORDER ? palletStatus.INCOMING : palletStatus.OUTGOING
  };
};

export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);
  // if (result.product) {
  //   delete result.product;
  // }
  return result;
};

const quantityHelper = (product, location) => {
  let productQuantity = product.quantities.find(quantity => {
    return Boolean(location && quantity.location && (quantity.location.id === location.location.id));
  });
  return productQuantity;
};

export const buildProductOptions = (theList, location) => {
  let result = [];
  let localList = theList.filter(product => quantityHelper(product, location));
  let length = localList.length;
  for (let i = 0; i < length; i++) {
    result.push({value: i + 1, label: localList[i].name, productId: localList[i].id, quantity: quantityHelper(localList[i], location).quantity});
  }
  return result;
};


export const buildInventoryOptions = (inventory) => {
  let result = [];
  let length = inventory.length;
  for (let i = 0; i < length; i++) {
    result.push(
      {
        value: inventory[i].id,
        label: inventory[i].name,
        productId: inventory[i].product.id,
        quantity: inventory[i].avaliable_quantity
      }
    );
  }
};
