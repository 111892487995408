import React, {Component} from "react";

import {authOperations, authSelectors, orgOperations, orgSelectors} from "../../store";
import {connect} from "react-redux";

import Settings from "./Settings";
import Loading from "../../components/Common/Base/Loading";

/* This page will allow a user to view and edit their settings. */
class SettingsContainer extends Component {
  render() {
    const {account} = this.props;

    // accountInfo must be loaded for settings
    if (!account || Object.keys(account).length === 0) {
      return <Loading title={"Settings"}/>;
    }
    return (
      <Settings {...this.props}/>
    );
  }
}

const mapStateToProps = (state) => ({
  account: authSelectors.refreshAccountInfo(state.account),
  accountSettings: authSelectors.refreshAccountSettings(state.account),
  org: orgSelectors.item(state.orgs),
});

const mapDispatchToProps = dispatch => ({
  changePassword: async (oldP, newP) => dispatch(await authOperations.changePassword(oldP, newP)),
  updateServiceProviderInfo: async (org_id, data) => dispatch(await orgOperations.updateServiceProviderInfo(org_id, data)),
  getOrg: async id => dispatch(await orgOperations.getItem(id)),
  updateNotificationSettings: (data) => dispatch(authOperations.updateSettings(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsContainer);
