import React from "react";
import PropTypes from "prop-types";
import {Bottom, SeeMoreButton, SideList, Wrapper} from "../../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../../Common/Bars/BarComponents/constants";
import {Details} from "./Details";
import {RightSideList} from "./RightSideList";
import {parseLocation} from "./utils";

/* A Pallet bar is a div containing information regarding an asset. A list of
Pallet bars are visible within the assets section. */

const PalletBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const locationInfo = parseLocation(data);
  const baseClassName = listView ? listStyle : tileStyle;
  const leftList = [
    {title: "PALLET SKU", value: data.sku || "None"},
    {title: "STATUS", value: data.status_display || "None"},
    {title: locationInfo.label, value: locationInfo.value},
    {title: "EXPECTED DATE", value: data.expected_date || "None"}
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="warehouse/pallets"
      itemData={data}
      detailsPane={<Details baseClassName={baseClassName} data={data} />}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
      <RightSideList data={data} baseClassName={baseClassName} />
    </Wrapper>
  );
};

PalletBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array
};

export {PalletBar};
