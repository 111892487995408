const CODE_128_BARCODE = 0;

const BARCODE_MAP = {
  [CODE_128_BARCODE]: "code_128"
};

/**
 * The zxing format numbers to their corresponding strings
 */
const ZXING_FORMAT_MAP = {
  0: "aztec",
  1: "codabar",
  2: "code_39",
  3: "code_93",
  4: "code_128",
  5: "data_matrix",
  6: "ean_8",
  7: "ean_13",
  8: "itf",
  9: "maxicode",
  10: "pdf_417",
  11: "qr_code",
  12: "rss_14",
  13: "rss_expanded",
  14: "upc_a",
  15: "upc_e",
  16: "upc_ean_extension",
};

/**
 * Searches BARCODE_MAP for the string format type, and returns the backend integer that goes with it
 * @param {string} format The format to look for
 * @returns {string} The backend integer to save
 */
function barcodeFormatStringToInt(format) {
  for (const [key, val] of Object.entries(BARCODE_MAP)) {
    if (val === format) {
      return key;
    }
  }
}

export {CODE_128_BARCODE, ZXING_FORMAT_MAP, BARCODE_MAP, barcodeFormatStringToInt};
