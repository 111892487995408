import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import PositiveNumberField from "../Base/PositiveNumberField";
import {SubmitButton} from "../styled";

const BusinessSize = props => {
  const {
    handleSubmit,
    validate,
    employee_number,
    annual_current_sales,
    annual_last_sales
  } = props;

  const initialValues = {
    employee_number,
    annual_current_sales,
    annual_last_sales
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        pristine,
        invalid,
        submitting,
        values
      }) => (
        <form className={"item-details-form serv-prov-info"} onSubmit={handleSubmit}>
          <PositiveNumberField
            label="Number of Employees"
            placeholder="enter #"
            name="employee_number"
            required
          />
          <PositiveNumberField
            label="Current Annual Sales"
            placeholder="enter #"
            name="annual_current_sales"
          />
          <PositiveNumberField
            label="Last Years Annual Sales"
            placeholder="enter #"
            name="annual_last_sales"
          />
          <br/>
          <SubmitButton className="item-details-form-button" loading={submitting}>Submit</SubmitButton>
        </form>
      )
      }
    />

  );
};

BusinessSize.propTypes = {
  handleSubmit: PropTypes.func,
  validate: PropTypes.func
};

BusinessSize.defaultProps = {
  validate: (values) => null
};

export default BusinessSize;
