import actions from "./actions";
import {baseOperations} from "../base";
import {dateUtils, snackUtils} from "../../utils";

const getAllItems = () => async dispatch => {
  const res = await dispatch(actions.getAllItems());
  console.log(res);
  return res;
};

const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Stockroom Asset", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Stockroom Asset", res.payload.name, dispatch, () => {
  });
  return res.payload;
};

const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating stockroom asset.", 3000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, fieldData));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Stockroom Asset", res.payload.name, dispatch, () => {
  });
};

const uploadCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload...", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch, getAllItems);
};

const addNote = (id, data) => async dispatch => {
  const res = await dispatch(actions.submitNote(id, data));
  baseOperations.handleSubmitNoteResponse(res, dispatch);
  return res.payload;
};

export default {
  getAllItems,
  updateItem,
  updateItemField,
  uploadCsv,
  addNote
};
