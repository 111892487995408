import React, {Component} from "react";
import Select from "react-select";
import moment from "moment";
import {ChakraButton, Flex, FlexCenter, Text} from "../../components/Common/Base";
import {getAxios} from "../../utils/authWithAxios";
import {SnackBar} from "../../components/Common";
import {Input} from "@chakra-ui/core";

const TIMESCALES_DROPDOWNS = ["week", "month", "quarter", "year"];
const MONTHS = [
  {"value": 1, "label": "Jan"},
  {"value": 2, "label": "Feb"},
  {"value": 3, "label": "Mar"},
  {"value": 4, "label": "Apr"},
  {"value": 5, "label": "May"},
  {"value": 6, "label": "Jun"},
  {"value": 7, "label": "Jul"},
  {"value": 8, "label": "Aug"},
  {"value": 9, "label": "Sep"},
  {"value": 10, "label": "Oct"},
  {"value": 11, "label": "Nov"},
  {"value": 12, "label": "Dec"}
];
const QUARTERS = [{value: 1, label: "Q1"}, {value: 2, label: "Q2"}, {value: 3, label: "Q3"}, {value: 4, label: "Q4"}];

class TimescaleContainer extends Component {
  state = {
    downloadingSummary: false,
    snackMsg: "",
    years: []
  };

  componentDidMount() {
    let years = this.calculateYearOptions(2018);
    this.setState({years});
  }

  // Calculate starting day of the weeks for an entire year
  calculateWeekOptions = (year) => {
    // set the locale to english so the first day of the week is Sunday
    moment.locale("en");
    // start from creating a date on the 1st January then set day to 0, so find the next Sunday
    let firstDayOfTheYear = moment(`${year}-01-01`, "YYYY-MM-DD");
    let firstSundayOfTheYear = firstDayOfTheYear.add(7 - firstDayOfTheYear.weekday(), "d");
    let sundaysOfYear = [];
    while (parseInt(year) === firstSundayOfTheYear.year()) {
      sundaysOfYear.push({value: firstSundayOfTheYear.format("YYYY-MM-DD"), label: firstSundayOfTheYear.format("MM-DD")});
      firstSundayOfTheYear = firstSundayOfTheYear.add(7, "d");
    }
    return sundaysOfYear;
  };

  // years are calculated based on current year until 2018
  // TODO make it more scalable, maybe checking on the backend for the years
  calculateYearOptions = (minYear = 2018) => {
    let years = [];
    for (let i = minYear; i <= moment().year(); i++) {
      years.push({value: i.toString(), label: i.toString()});
    }
    return years;
  };


  getOptionsBasedTimescale = (timescale) => {
    const {summaryYear} = this.props;
    switch (timescale) {
    case TIMESCALES_DROPDOWNS[0]:
      return this.calculateWeekOptions(summaryYear);
    case TIMESCALES_DROPDOWNS[1]:
      return MONTHS;
    case TIMESCALES_DROPDOWNS[2]:
      return QUARTERS;
    case TIMESCALES_DROPDOWNS[3]:
      return this.state.years;
    default:
      break;
    }
  };

  currentValueDropdown = (timescale) => {
    const {summaryYear, summaryTimescale, summaryTimescaleValue} = this.props;
    if (timescale !== "year" && summaryTimescale === timescale) {
      return this.getOptionsBasedTimescale(timescale).find(option => option.value === summaryTimescaleValue);
    } else {
      return this.getOptionsBasedTimescale(timescale).find(option => option.value === summaryYear);
    }
  };

  downloadBooklet = (summaryTimescale, summaryTimescaleValue, summaryYear) => {
    this.setState({downloadingSummary: true, snackMsg: ""});
    getAxios({
      url: `${global.base_url}v1/org/summary/?download&timescale=${summaryTimescale}&value=${summaryTimescaleValue}&year=${summaryYear}`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "org_summary.xlsx");
      document.body.appendChild(link);
      link.click();
      this.setState({downloadingSummary: false});
    }).catch((response) => {
      // display an error in this case
      this.setState({downloadingSummary: false, snackMsg: "Something went wrong."});
    });
  }

  onChangeSingleDate = (event) => {
    if (event.target.value !== "") {
      const momentDate = moment(event.target.value, "YYYY-MM-DD");
      this.props.fetchOrgSummary("day", momentDate.format("YYYY-MM-DD"), null);
    }
  }

  renderSingleDayPicker = (summaryTimescale, summaryTimescaleValue) =>
    <Flex
      key="day"
      mx={[1, 1, 2, 2]}
      mb={[2, 2, 2, 0]}
      bg={(summaryTimescale === "day") ? "colorGreyLight2" : ""}
      borderRadius={"md"}
      p={"1"}
    >
      <Text mx={[2]} lineHeight={"2"}>
        DAY
      </Text>
      <Input
        type="date"
        onChange={date => this.onChangeSingleDate(date)}
        value={(summaryTimescale === "day" ? summaryTimescaleValue : "")}
      />
    </Flex>

  renderSingleButton = (value, index, summaryTimescale, summaryYear) =>
    <Flex
      key={value}
      mx={[1, 1, 2, 2]}
      mb={[2, 2, 2, 0]}
      bg={(summaryTimescale === value || value === "year") ? "colorGreyLight2" : ""}
      borderRadius={"md"}
      p={"1"}
    >
      <Text mx={[2]} lineHeight={"2"}>
        {value.toUpperCase()}
      </Text>
      <Flex w={100}>
        <Select
          onChange={(e) => this.props.fetchOrgSummary(value, e.value, summaryYear)}
          className="toolbar-row-select filter-select-timescale"
          options={this.getOptionsBasedTimescale(value)}
          defaultValue={{value: "", label: ""}}
          value={this.currentValueDropdown(value)}
        />
      </Flex>
    </Flex>
    ;

  render() {
    const {summaryYear, summaryTimescale, summaryTimescaleValue} = this.props;
    const {snackMsg} = this.state;
    return (
      <FlexCenter flexWrap="wrap" mt={"30px"}>
        <Flex spacing={[1, 4]} mb={[2, 0, 0, 0]} flexWrap="wrap">
          {this.renderSingleDayPicker(summaryTimescale, summaryTimescaleValue)}
          {TIMESCALES_DROPDOWNS.map((value, index) => this.renderSingleButton(value, index, summaryTimescale, summaryYear))}
        </Flex>
        <ChakraButton
          isLoading={this.state.downloadingSummary}
          loadingText="Downloading"
          variantColor="blue"
          size={["md"]}
          mb={[2, 0, 0, 0]}
          variant={"solid"}
          onClick={() => { this.downloadBooklet(summaryTimescale, summaryTimescaleValue, summaryYear); }}>
          Download
        </ChakraButton>
        {snackMsg !== "" && <SnackBar title={snackMsg} status={"error"}/>}
      </FlexCenter>
    );
  }
}

export default TimescaleContainer;
