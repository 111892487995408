import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import {withAsyncPaginate} from "react-select-async-paginate";

const AsyncPaginate = withAsyncPaginate(CreatableSelect);

const WarehouseLocationDropdown = ({value, onChange, onCreate, loadOptions, name, facility}) => {
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);

  const handleChange = (newValue) => {
    console.log("location onChange", newValue);
    onChange(newValue);
  };

  const handleCreate = useCallback(async (values) => {
    setIsAddingInProgress(true);

    const data = {
      code: values,
      location: facility.id
    };
    console.log("handleCreate data", data);
    const res = await onCreate(data);
    setIsAddingInProgress(false);
    if (!res.error) {
      const {payload} = res;
      const newOption = {
        label: payload.code,
        value: payload.id
      };
      onChange(newOption);
    }

    console.log("create res", res);
  }, [onCreate, facility.id, onChange]);

  const handleLoad = async (search, loadedOptions, {page}) => {
    const res = await loadOptions(page, {search: search});

    if (!res.error) {
      const {payload, meta} = res;
      const {page} = meta;

      const hasMore = Boolean(payload.next);
      return {
        options: payload.results.map(item => ({label: item.code, value: item.id})),
        hasMore,
        additional: {
          page: hasMore ? page + 1 : page,
        },
      };
    }
    return loadOptions(search, loadedOptions, {page});
  };
  return (
    <AsyncPaginate
      isDisabled={isAddingInProgress}
      value={value}
      loadOptions={handleLoad}
      onChange={handleChange}
      onCreateOption={handleCreate}
      name={name}
      className={"warehouse-location-select"}

      additional={{
        page: 1,
      }}
    />
  );
};

WarehouseLocationDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  loadOptions: PropTypes.func,
  name: PropTypes.string,
  facility: PropTypes.object
};

export default WarehouseLocationDropdown;
