import React, {Component, Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Flex} from "Base";
import {Input} from "./Inputs";
import Select from "react-select";
import {Modal} from "../../index";
import {ChromePicker} from "react-color";
import chroma from "chroma-js";
import {DescriptionText} from "../../Modals/styled";
import {Label, LabelText} from "./styled";

// Import {OnChange} from 'react-final-form-listeners'

class EditableColorFormDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorPickerShowing: false,
      createFieldShowing: false,
      editFieldShowing: false,
      deleteFieldModalShowing: false,
    };
  }

  handleColorPickerClick = () => {
    this.setState({colorPickerShowing: !this.state.colorPickerShowing});
  };

  handleShowCreateField = () => {
    const {handleShowCreateFieldSuper} = this.props;
    this.setState({
      createFieldShowing: !this.state.createFieldShowing,
      editFieldShowing: false,
    });
    handleShowCreateFieldSuper();
  };
  handleShowEditField = () => {
    const {handleShowEditFieldSuper} = this.props;
    this.setState({
      createFieldShowing: false,
      editFieldShowing: !this.state.editFieldShowing,
    });
    handleShowEditFieldSuper();
  };

  hideCreateField = () => {
    this.setState({
      createFieldShowing: false,
    });
  };

  hideEditField = () => {
    this.setState({
      editFieldShowing: false,
    });
  };

  handleCreateFieldSubmit = () => {
    const {handleCreateField, newField} = this.props;
    handleCreateField(newField.name, newField.color);
    this.hideCreateField();
  };

  handleEditFieldSubmit = () => {
    const {handleEditField, newField} = this.props;
    handleEditField(newField.id, newField.name, newField.color);
    this.hideEditField();
  };

  toggleDeleteFieldModal = () => {
    this.setState({deleteFieldModalShowing: !this.state.deleteFieldModalShowing});
  };

  deleteFieldAndCloseModal = () => {
    const {handleDeleteField} = this.props;
    handleDeleteField();
    this.toggleDeleteFieldModal();
    this.hideCreateField();
    this.hideEditField();
  };

  render() {
    const {
      colorPickerShowing,
      createFieldShowing,
      editFieldShowing,
      deleteFieldModalShowing,
    } = this.state;
    const {
      defaultOptions,
      dropdownName,
      canChangeSelection,
      canCreateSelections,
      dropdownOptions,
      selectedOption,
      newField,
      handleFieldSelect,
      handleColorChange,
      handleNewFieldNameChange,
      label,
    } = this.props;

    let selectOptions = [{value: "", label: "None", color: "#2c3655"}];
    dropdownOptions.forEach(type => {
      selectOptions.push({
        value: type.label,
        label: type.label,
        id: type.id,
        color: type.color,
      });
    });

    let selectedIsDefaultOption = selectedOption.name && !(defaultOptions && defaultOptions.includes(selectedOption.name));

    const dot = (color = "#ccc") => ({
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: color,
        borderRadius: 10,
        border: "1px solid black",
        content: "\" \"",
        display: "block",
        marginRight: 8,
        height: 15,
        width: 15,
      },
    });
    const colorStyles = {
      option: (styles, {data, isFocused, isSelected}) => {
        const color = chroma(data.color);
        const isTooBright = chroma.contrast(color, "white") > 1;
        return {
          ...styles,
          ...dot(data.color),
          backgroundColor: isSelected ?
            (isTooBright ? color.alpha(0.4).css() : "rgba(0,0,0,0.1)")
            : isFocused ? (isTooBright ? color.alpha(0.1).css() : "rgba(0,0,0,0.1)") : null,
          color: "#000",
          cursor: "default",
        };
      },
      input: styles => ({...styles, ...dot()}),
      placeholder: styles => ({...styles, ...dot()}),
      singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
    };

    return (
      <Fragment>
        <Label>
          <LabelText>{label}:</LabelText>
        </Label>

        <Box className="item-details-form-flex" mb={"30px"}>
          <Select
            id="editableColorDropdown"
            className="item-details-form-dropdown"
            defaultValue={selectOptions.find(option => option.label === "None")}
            value={selectOptions.find(option => option.value === selectedOption.name)}
            styles={colorStyles}
            isDisabled={!canChangeSelection}
            onChange={data => {
              handleFieldSelect(data);
              this.hideEditField();
            }}
            options={selectOptions}>
          </Select>
          {canCreateSelections && (
            <FontAwesomeIcon
              id="dropdownPlus"
              icon="plus"
              size="lg"
              onClick={this.handleShowCreateField}
              data-tip={`Create New ${dropdownName}.`}
            />
          )}
          {canCreateSelections && selectedOption.name && (
            <FontAwesomeIcon
              id="dropdownEdit"
              icon="edit"
              size="lg"
              onClick={this.handleShowEditField}
              data-tip={`Edit ${dropdownName}.`}
            />
          )}
          {canCreateSelections && selectedIsDefaultOption && (
            <FontAwesomeIcon
              id="dropdownDelete"
              icon="trash"
              size="lg"
              onClick={this.toggleDeleteFieldModal}
              data-tip={`Delete ${dropdownName}.`}
            />
          )}
        </Box>
        {(createFieldShowing || editFieldShowing) && (
          <Flex mb={"30px"}>
            <Input
              value={newField.id}
              readOnly={true}
              display={"none"}
              name="newFieldID"
            />
            <Input
              placeholder={`New ${dropdownName} Name.`}
              value={newField.name}
              alignSelf="center"
              mb={0}
              type="text"
              name="fieldNameNew"
              label={`${dropdownName} Name.`}
              readOnly={!selectedIsDefaultOption && editFieldShowing}
              onChange={handleNewFieldNameChange}
            />
            <Box onClick={this.handleColorPickerClick}
              id="chromePicker"
              margin={"0 15px"}
              padding={"5px"}
              background={"#fff"}
              borderRadius={1}
              display={"inline-block"}
              cursor={"pointer"}>
              <Box
                width={36}
                height={"100%"}
                border={"1px solid black"}
                borderRadius={2}
                background={newField.color}/>
            </Box>
            {colorPickerShowing ? <Box>
              <Box position={"absolute"} width={1}>
                <div style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }} onClick={this.handleColorPickerClick}/>
                <Box position={"relative"} left={-300}>
                  <ChromePicker
                    disableAlpha={true}
                    color={newField.color}
                    onChangeComplete={handleColorChange}/>
                </Box>
              </Box></Box> : null}
            <Button type="button" mt={0}
              id="editOrCreateButton"
              disabled={newField.name === ""}
              onClick={() => {
                editFieldShowing ? this.handleEditFieldSubmit() : this.handleCreateFieldSubmit();
              }}>
              {editFieldShowing ? "Edit" : "Create"}
            </Button>
            <br/>
          </Flex>
        )}
        <Modal
          visible={deleteFieldModalShowing}
          title={`Delete ${selectedOption.name || "Option"}?`}
          onCancel={this.toggleDeleteFieldModal}
          onOk={this.deleteFieldAndCloseModal}
          isDeleteModal
        >
          <DescriptionText>Deleting this {dropdownName} will remove it from any object.</DescriptionText>
        </Modal>
      </Fragment>
    );
  }
}

export default EditableColorFormDropdown;
