import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormField} from "../Base";
import {Row, SecondaryButton} from "../styled";
import {ScheduleField} from "../../Fields/Schedules";
import {NumberInputControl} from "../../Base/Inputs/NumberInput";
import {Box, Flex, Label, SingleDatePicker} from "../../Base";
import {LocationSection} from "../../index";
import {dateAdapter} from "../Base/utils";
import moment from "moment-timezone";
/*
Sample Values
    {
      "id": 6,
      "start": "2019-08-31T19:01:54.067161Z",
      "end": null,
      "repeat": "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=SA",
      "repeat_until": null,
      "start_time": "10:00:00",
      "end_time": "16:00:00",
      "location": "Office",
      "details": ""
    }
 */

const AdaptedDatePicker = dateAdapter(SingleDatePicker);


const getLocationData = (values, locationList) => {
  if (values) {
    if (values.location && values.location.id) {
      return values.location;
    } else {
      return locationList.find(location => location.id === values.location);
    }
  }
  return {};
};
const ShiftForm = props => {
  console.log("shift props", props);
  // Const [ruleValue, setRuleValue] = useState('')
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    locationList
  } = props;

  const handleDelete = (e) => {
    e.preventDefault();
  };

  // Const handleBack = (e) => {
  //   e.preventDefault()
  // }

  const initialValues = {
    repeat: "RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;WKST=SU",
    ...values
  };


  const handleSubmitLocationSection = (data) => {
    console.log("values", data);
    const {location} = data;
    const result = {
      id: values.id,
      location
    };
    console.log("result", result);
    onSubmit(result);
  };

  const locationData = getLocationData(values, locationList);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        pristine,
        invalid,
        form,
        submitting,
        values
      }) => (
        <form className={"shift-form item-details-form"} onSubmit={handleSubmit}>


          <Row justifyContent={"center"}>
            <SecondaryButton className="delete-shift" onClick={handleDelete}>
              Delete Shift
            </SecondaryButton>
            <SecondaryButton className="submit-shift" type="primary" htmlType="submit">
              {values && values.id ? "Edit" : "Save"} Shift
            </SecondaryButton>
            {/* <SecondaryButton className="delete-shift" onClick={handleBack}>*/}
            {/*  Add Personnel*/}
            {/* </SecondaryButton>*/}
          </Row>
          <Row>
            <Box width={1 / 2}>
              <FormField
                label="Start Time"
                type="time"
                name="start_time"
                readOnlyMode={!editAccess}
              />
            </Box>
            <Box width={1 / 2}>
              <FormField
                label="End Time"
                type="time"
                name="end_time"
                readOnlyMode={!editAccess}
              />
            </Box>


          </Row>
          <Row>
            <NumberInputControl
              label={"Hours before shift to recieve Email"}
              name={"pre_shift_notify_hours"}
              defaultValue={2}
            />
          </Row>

          <ScheduleField
            label={"Shift Frequency"}
            name={"repeat"}
          />

          <Row>
            <Flex flexDirection={"column"} width={"50%"} pr={3}>
              <Label><strong>Start Date:</strong></Label>

              <FormField
                placeholder="Start Date"
                name="start"
                readOnlyMode={!editAccess}
                component={AdaptedDatePicker}
                // format={(value, name) => value ? moment(value).format("MM/DD/YYYY") : null}
                parse={(value, name) => value ? moment(value).format("YYYY-MM-DD") : null}
                popoverOptions={{
                  position: "top",
                  align: "center",
                  disableReposition: true
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} width={"50%"} pr={3}>
              <Label><strong>End Date:</strong></Label>
              <FormField
                placeholder={"End Date"}
                // Label="Shift End Date"
                // type="datetime-local"
                name="end"
                readOnlyMode={!editAccess}
                component={AdaptedDatePicker}
                // format={(value, name) => value ? moment(value).format("MM/DD/YYYY") : null}
                parse={(value, name) => value ? moment(value).format("YYYY-MM-DD") : null}
                popoverOptions={{
                  position: "top",
                  align: "center",
                  disableReposition: true,
                  padding: 10
                  // contentLocation: {
                  //   top: 10,
                  //   left: 0
                  // }
                }}

              />
            </Flex>
          </Row>

          <LocationSection
            submit={handleSubmitLocationSection}
            locationList={locationList}
            locationData={locationData}
          />

          {/* <FormField*/}
          {/*  label="Location"*/}
          {/*  type="text"*/}
          {/*  name="location"*/}
          {/*  readOnlyMode={!editAccess}*/}
          {/* />*/}

          <FormField
            label="Details"
            type="textarea"
            name="details"
            readOnlyMode={!editAccess}
          />


          {/* <ScheduleRule onChange={setRuleValue} value={ruleValue}/>*/}

        </form>
      )}
    />
  );
};

ShiftForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  editAccess: PropTypes.bool,
  locationList: PropTypes.array
};

ShiftForm.defaultProps = {
  editAccess: true,
  validate: (values) => console.log("validate", values)
};

export default ShiftForm;
