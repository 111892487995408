import React, {useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Flex, IconButton, List} from "../Base";
import Modal from "./";

import {DateText, HistoryListWrapper, HistoryText} from "./styled";

const PriceHistoryModal = (props) => {
  const [initStatus, updateInitStatus] = useState(false);
  const {
    priceHistory,
    refresh,
    closePriceHistoryModal,
    toggleDeleteModal,
    editAccess,
    visible,
  } = props;


  const handleInit = () => {
    updateInitStatus(true);
    refresh();
  };

  if (initStatus === false) {
    handleInit();
  }
  const renderItem = (entry, i) => {
    const date = `${moment(entry.date).format("MMM Do YYYY")}: `;
    const long_date = moment(entry.date).format("MM/DD/YYYY, h:mma");
    const prefix = entry.price_currency === "USD" ? "$" : "";
    const suffix = entry.price_currency === "USD" ? "" : entry.price_currency;
    const price = `${prefix}${entry.price} ${suffix}`;
    const text = date + price;
    return (
      <HistoryListWrapper key={entry.id}>
        <DateText flexGrow={0.5} className={"history-entry-date"} title={long_date}>
          {date}
        </DateText>
        <HistoryText flexGrow={1} className={"history-entry-price"}>
          {price}
        </HistoryText>
        {editAccess &&
        <IconButton
          variant="link"
          aria-label="Delete entry"
          icon="delete"
          disabled={i === 0}
          onClick={() => toggleDeleteModal(entry.id, text)}/>
        }
      </HistoryListWrapper>
    );
  };
  return (
    <Modal
      visible={visible}
      onCancel={closePriceHistoryModal}
      showCancelButton={false}
      title={"Price History"}>
      <Flex flexDirection={"column"}>
        <List dataSource={priceHistory} renderItem={renderItem} itemLayout={"vertical"}/>
      </Flex>
    </Modal>
  );
};

PriceHistoryModal.propTypes = {
  priceHistory: PropTypes.array,
  refresh: PropTypes.func,
  closePriceHistoryModal: PropTypes.func,
  toggleDeleteModal: PropTypes.func,
  editAccess: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

PriceHistoryModal.defaultProps = {
  priceHistory: [],
  editAccess: false,
};

export default PriceHistoryModal;
