import createPaginatorReducer from "./reducers";
import createPaginationActionTypes from "./types";
import createPaginationActions from "./actions";
// import paginationSelectors from "./selectors";


const createPaginator = ({name = "", endpoint = ""}) => {
  const types = createPaginationActionTypes(name);

  const actions = createPaginationActions(types, endpoint);

  const reducer = () => createPaginatorReducer(types);

  return {
    types,
    actions,
    reducer
  };
};

export default createPaginator;
