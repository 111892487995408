import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormField} from "../Base";

import {SubmitButton} from "../styled";

const ServiceInfo = props => {
  const {
    handleSubmit,
    validate,
    main_service,
    secondary_service,
    service_one,
    service_two,
    service_three,
    material
  } = props;

  const initialValues = {
    main_service,
    secondary_service,
    service_one,
    service_two,
    service_three,
    material
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        pristine,
        invalid,
        submitting,
        values
      }) => (
        <form className={"item-details-form serv-prov-info"} onSubmit={handleSubmit}>
          <FormField
            label="Main Service Provided *"
            placeholder="main service"
            type="textarea"
            name="main_service"
            required
          />
          <FormField
            label="Secondary Service Provided"
            placeholder="secondary service"
            type="textarea"
            name="secondary_service"
          />
          <FormField
            label="Service Provided"
            placeholder="service..."
            type="textarea"
            name="service_one"
          />
          <FormField
            label="Service Provided"
            placeholder="service..."
            type="textarea"
            name="service_two"
          />
          <FormField
            label="Service Provided"
            placeholder="service..."
            type="textarea"
            name="service_three"
          />
          <FormField
            label="Materials"
            placeholder="service..."
            type="textarea"
            name="material"
          />
          <br/>
          <SubmitButton className="item-details-form-button" loading={submitting}>Submit</SubmitButton>
        </form>
      )
      }
    />
  );
};

ServiceInfo.propTypes = {
  handleSubmit: PropTypes.func,
  validate: PropTypes.func
};

ServiceInfo.defaultProps = {
  validate: (values) => null
};

export default ServiceInfo;
