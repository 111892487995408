import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  AutoSave, FormField, FormLinkField, FormMoneyField, MaterialQuantityField,
  PositiveNumberField, QuantityFormField,
} from "../../../Common/Forms/Base";
import {postLoadFormat, preSaveFormat} from "./utils";
import {getDeepestLocation, generateBreadcrumb} from "../../../../utils/location";
import {clearMutator} from "../../../Common/Forms/Base/mutators";
import {SubmitButton} from "../../../Common/Forms/styled";
import {MATERIAL_DASHBOARD_ROUTE} from "../../../../routes/constants";
import InventoryShippingDimensions from "../../../Common/Forms/InventoryShippingDimensions";
import {SHIPPING_DIMENSIONS_FIELDS, INVENTORY_SHIPPING_FIELDS} from "../../../../constants";

const MaterialForm = (props) => {
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    handleManufacturerClick,

    locationData,
    setLocationData,
    locationList,
    isAdmin,
  } = props;

  let initialValues = postLoadFormat(values);
  const {quantities} = values;
  const totalQuantity = quantities && quantities.length ? quantities.reduce((acc, item) => acc + item.quantity - item.in_use, 0) : 0;

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        clearMutator,
      }}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"item-details-form"} onSubmit={async e => {
          await handleSubmit(e);
          form.mutators.clearMutator("quantity");
        }}>
          <AutoSave
            debounce={2000}
            save={onSubmit}
            isSubmitting={submitting}
            debounced={["name", "price", "price_currency", "manufacturer", "sku"]}
            ignored={["quantity", "quantities", ...SHIPPING_DIMENSIONS_FIELDS, ...INVENTORY_SHIPPING_FIELDS]}
            // arrayFields={{quantities: "quantity"}}
            preSaveFormat={preSaveFormat}
            postLoadFormat={postLoadFormat}
            formInvalid={invalid}
          />

          <FormField
            label={"Material Name"}
            type={"text"}
            name={"name"}
            isDisabled={!editAccess}
          />

          {editAccess ?
            <FormField
              label="Material Manufacturer"
              type="text"
              name="manufacturer"
              isDisabled={!editAccess}
            />
            :
            <FormLinkField
              label="Material Manufacturer"
              type="text"
              name="manufacturer"
              isDisabled={!editAccess}
              handleClick={handleManufacturerClick}
              url={MATERIAL_DASHBOARD_ROUTE}
            />
          }

          <PositiveNumberField
            label={"SKU"}
            type={"text"}
            name={"sku"}
            isDisabled={!editAccess}
          />

          <FormMoneyField
            label={"PRICE"}
            name={"price"}
            currencyName={"price_currency"}
            isDisabled={!editAccess}
          />

          <InventoryShippingDimensions editAccess={editAccess}/>

          <div id="total-available-quantity" style={{fontSize: "16px", fontWeight: "bold"}}>
            TOTAL AVAILABLE QUANTITY: {totalQuantity}
          </div>

          <FieldArray name="quantities">
            {({fields}) => (
              fields.map((name, i) => {
                let quantity_data = fields.value[i];
                const [rung] = getDeepestLocation(quantity_data.location);
                const label = rung ? generateBreadcrumb(quantity_data.location) : "Quantity without location";
                const {quantity, in_use} = quantity_data;
                const available = quantity - in_use >= 0 ? quantity - in_use : 0;
                return (
                  <MaterialQuantityField
                    key={i}
                    label={label}
                    available={available}
                    editAccess={editAccess}
                    name={name}
                    initialValues={initialValues}
                    submit={onSubmit}
                    {...quantity_data}/>
                );
              })
            )}
          </FieldArray>

          <QuantityFormField
            label="QUANTITY"
            type="number"
            name="quantity"
            isDisabled={!editAccess}
            mr={"20px"}

            locationData={locationData}
            locationList={locationList}
            handleSelectLocation={setLocationData}
            isAdmin={isAdmin}
          />

          {editAccess &&
          <SubmitButton className="item-details-form-button" loading={submitting}>
            Save
          </SubmitButton>
          }
        </form>
      )}
    />
  );
};


MaterialForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  handleManufacturerClick: PropTypes.func,
  locationList: PropTypes.array,
  isAdmin: PropTypes.bool,
  locationData: PropTypes.any,
  setLocationData: PropTypes.func,
};

MaterialForm.defaultProps = {
  values: {},
  editAccess: false,
};

export default MaterialForm;
