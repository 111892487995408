import React, {Component} from "react";
import {Button, DetailsCloseIcon, ErrorHeading, Flex, Heading, Link, Loading} from "../../Common/Base";
import ReactTooltip from "react-tooltip";
import {Redirect} from "react-router-dom";
import {isEmpty} from "lodash-es";
import {deleteAxios} from "../../../utils/authWithAxios";
import {preSaveFormat} from "../../Common/Forms/VendorForm/utils";
import {VendorForm} from "../../Common/Forms";
import {AdminControlsDetails, AttachedFiles, Container, Modal, NoteSection} from "../../Common";
import {OrderProgressContainer} from "../../../containers";
import {baseDetailsState, checkForResponseError, handleErrorResponse, scrollToTop} from "../../../utils/containerUtils";
import {
  AZURE_BLOB_CONTAINER_NAME,
  AZURE_VENDOR_PREFIX,
  handleChangeDisplayImage,
  handleChangeWarrantyDocument,
  listBlobsInAzureContainer,
  removeDocument,
  uploadBlobsToAzureWithFileNameCheck,
} from "../../../utils/azure";
import {DetailsHeader, DetailsPaneLeft, DetailsPaneRight, ImageWithDefault} from "../../Common/Details/Layout";
import {DescriptionText} from "../../Common/Modals/styled";
import authSelectors from "../../../store/auth/selectors";

/* This presents the details for a particular service. The imported service ID is acquired via the URL. */
class VendorDetails extends Component {
  constructor(props) {
    super(props);
    this.vendorID = props.match.params.vendorID;
    this.state = {
      assetName: "",
      stockAssetName: "",
      assetData: null,
      inspectionNotesVisible: true,
      inspectionNote: "",
      savingVisible: false,
      deleteVendorOrder: false,
      deleteVendorOrderModalShowing: false,
      selectedVendorType: "",
      newVendorType: {
        name: "",
        color: "#2c3655",
        id: ""
      },
      ...baseDetailsState("vendor")
    };
  }

  /* Upon mounting, fetch data for the specific vendor by vendorID and store the data in state. */
  componentDidMount = () => {
    const {getOrg, getMaterials, getServicesList, vendorDetails, getVendorTypes} = this.props;
    if (isEmpty(vendorDetails)) {
      getServicesList();
    }
    this.fetchVendorDetails().then(() => {
      if (this.state.vendorOrgID) {
        getOrg(this.state.vendorOrgID);
        getVendorTypes(this.state.vendorOrgID);
      }
      getMaterials();
    });
    scrollToTop();
  }

  /* This function will handle the fetching of details for a service. */
  fetchVendorDetails = (fetchDocuments = true) => {
    const {fetchDetails} = this.props;
    return fetchDetails(this.vendorID)
      .then(res => {
        console.log(res);
        checkForResponseError(res);
        let data = res.payload;
        this.setState({
          vendorData: data,
          vendorID: data.id,
          vendorUUID: data.UUID || data.uuid,
          vendorOrgID: data.org.id,
          assetName: data.asset ? data.asset.name : "",
          stockAssetName: data.stockroom_asset ? data.stockroom_asset.name : "",
          assetData: data.asset,
          stockAssetData: data.stockroom_asset,
          inspectionNote: data.inspection_note,
          selectedVendorType: data.type || {},
          displayImage: data.display_image,
          warrantyDocument: data.warranty_document,
          editAccess: data?.permissions?.write ?? false
        });
        if (fetchDocuments) {
          this.getVendorDocuments();
        }
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        handleErrorResponse(this, error, "Vendor Order");
      })
      .then(() => {
        this.setState({
          showLoader: false
        });
      });
  };

  submit = async values => {
    console.log("submit", values);

    const {updateDetails} = this.props;
    const {submitting, vendorData} = this.state;

    if (!submitting) {
      this.setState({submitting: true});
      const res = await updateDetails(this.vendorID, preSaveFormat(values));
      vendorData.materials = res.materials;
      // this.setState({vendorData: vendorData});
      setTimeout(() => this.setState({submitting: false}), 1000);
      this.fetchVendorDetails(false);
      return res;
    }
  };

  /* This function will handle deletion of a vendor order. */
  handleDeleteVendorOrder = () => {
    this.setState({
      adminControlsSubmitting: true,
      adminControlsMessage: ""
    });
    deleteAxios({
      url: `${global.base_url}v1/vendor_orders/${this.vendorID}/`
    })
      .then(res => {
        console.log(res);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Vendor order deletion successful.",
          deleteVendorOrder: true
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Error occurred during vendor order deletion.",
          deleteVendorOrderModalShowing: false
        });
      });
  };

  getVendorDocuments = () => {
    const {vendorUUID} = this.state;
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_VENDOR_PREFIX}/${vendorUUID}/`).then(files => {
      this.setState({
        vendorFiles: files
      });
    });
  };

  handleChangeDisplayImageField = () => {
    const {vendorUUID} = this.state;
    uploadBlobsToAzureWithFileNameCheck(
      vendorUUID,
      AZURE_VENDOR_PREFIX,
      [this.refs.fileUploader.files[0]]
    ).then(async res => {
      // Res contains filenames from uploaded files, use res[0] because only one file was uploaded
      await this.handleChangeDisplayImageNoUpload(res[0]);
      this.fetchVendorDetails();
    });
  };

  handleChangeDisplayImageNoUpload = async imageName => {
    await handleChangeDisplayImage(this, imageName, this.vendorID, this.props.updateVendorField);
  };

  handleChangeWarrantyDocumentField = async documentName => {
    await handleChangeWarrantyDocument(this, documentName, this.vendorID, this.props.updateVendorField);
  };

  handleRemoveDocument = documentName => {
    removeDocument(this, documentName, this.vendorID, this.props.updateVendorField, this.getVendorDocuments);
  };

  handleVendorTypeSelect = data => {
    const obj = {
      name: data["value"],
      color: data["color"],
      id: data["id"]
    };
    this.setState({
      selectedVendorType: obj
    });
    this.submit({type: obj.id || null});
  };
  handleShowCreateVendorType = () => {
    this.setState({
      newVendorType: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };
  handleShowEditVendorType = () => {
    this.setState({newVendorType: this.state.selectedVendorType});
  };
  handleVendorTypeColorChange = color => {
    this.setState({
      newVendorType: {
        name: this.state.newVendorType.name,
        color: color.hex,
        id: this.state.newVendorType.id
      }
    });
  };
  handleVendorTypeNameChange = event => {
    this.setState({
      newVendorType: {
        name: event.target.value,
        color: this.state.newVendorType.color,
        id: this.state.newVendorType.id
      }
    });
  };
  handleCreateVendorType = async (name, color) => {
    const res = await this.props.createVendorType(this.state.vendorOrgID, name, color);
    // if the request is successful, automatically select the type as the newly created type
    if (res && res.payload) {
      const {id, color, name} = res.payload;
      this.handleVendorTypeSelect({id, color, value: name});
    }
  };
  handleUpdateVendorType = (vendor_type_id, name, color) => {
    this.props.updateVendorType(this.state.vendorOrgID, vendor_type_id, name, color);
    this.setState({selectedVendorType: this.state.newVendorType});
  };
  handleDeleteVendorType = () => {
    const {name, color, id} = this.state.selectedVendorType;
    this.props.deleteVendorType(this.state.vendorOrgID, id, name, color);
    this.setState({
      selectedVendorType: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };

  toggleDeleteVendorOrderModal = () => {
    this.setState({deleteVendorOrderModalShowing: !this.state.deleteVendorOrderModalShowing});
  };

  toggleInspectionNotes = () => {
    this.setState({inspectionNotesVisible: !this.state.inspectionNotesVisible});
  };

  render() {
    const {
      vendorData,
      vendorID,
      vendorUUID,
      assetName,
      stockAssetName,
      assetData,
      stockAssetData,
      showLoader,
      adminControlsSubmitting,
      adminControlsMessage,
      errorLoadingMessage,
      deleteVendorOrder,
      deleteVendorOrderModalShowing,
      selectedVendorType,
      newVendorType,
      vendorFiles,
      displayImage,
      warrantyDocument,
      inspectionNotesVisible,
      editAccess
    } = this.state;
    const {
      accountInfo,
      vendorTypeList,
      getRecentLogs,
      notes,
      fetchNotes,
      addNote,
      personnelList,
      // materialList,
      createOrderMaterial,
      updateOrderMaterial,
      deleteOrderMaterial,
      vendorDetails,
      materialPagination
    } = this.props;

    const materialList = materialPagination.itemsPaged;
    if (deleteVendorOrder) return <Redirect to={"/dashboard/vendors"}/>;
    const {is_on_site} = vendorDetails;
    const orderLocation = is_on_site !== null ? (is_on_site ? "On-site" : "Off-site") : "";
    const personnelEnabled = authSelectors.moduleEnabled(accountInfo, "personnel");

    return (
      <div>
        <Container>
          {vendorID && (
            <Flex className="item-details" flexDirection={["column", "row"]}>
              <DetailsCloseIcon href={"/#/dashboard/vendors"} dataTip={"Close and return to vendor orders."}/>
              <DetailsPaneLeft className="item-details-pane-left">
                <ImageWithDefault src={displayImage} />
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  ref="fileUploader"
                  onChange={this.handleChangeDisplayImageField}
                  style={{display: "none"}}
                />
                <Button
                  className="item-details-form-button"
                  onClick={() => this.refs.fileUploader.click()}>
                  Change Display Image
                </Button>
                <div className="item-details-pane-left-text">
                  <ReactTooltip/>
                  <Heading mt="10px" color={"colorPrimary"} display="inline-block">VENDOR ORDER HISTORY</Heading>
                  <div
                    className="tooltip-circle"
                    data-tip="This displays the history of changes performed on the vendor order."
                  />
                  <br/>
                  <br/>
                  {(accountInfo.user_type === 1) ?
                    <AdminControlsDetails
                      deleteItem={this.toggleDeleteVendorOrderModal}
                      controlsSubmitting={adminControlsSubmitting}
                      controlsMessage={adminControlsMessage}
                    /> : null}
                </div>
              </DetailsPaneLeft>

              <DetailsPaneRight className="item-details-pane-right">
                <div className="item-details-pane-right-text">
                  <DetailsHeader>{orderLocation + "  "}VENDOR ORDER DETAILS</DetailsHeader>
                  <OrderProgressContainer submit={this.submit} {...vendorDetails} {...accountInfo}/>
                  <h3>
                    <b>Asset Name: </b>
                    {assetName ? (
                      <Link color="#1e6cb6" to={`/dashboard/assets/${assetData.id}`}>
                        {assetName}
                      </Link>
                    ) : (
                      "Asset has been deleted"
                    )}
                  </h3>
                  <h3>
                    <b>Stockroom Asset Name: </b>
                    {stockAssetName ? (
                      <Link color="#1e6cb6" to={`/dashboard/stockroom_asset/${stockAssetData.id}`}>
                        {stockAssetName}
                      </Link>
                    ) : (
                      "None"
                    )}
                  </h3>
                  <br/>
                  <div className="item-details-form">
                    <VendorForm
                      onSubmit={this.submit}
                      editAccess={editAccess}
                      values={vendorData}
                      vendorTypes={vendorTypeList}
                      selectedVendorType={selectedVendorType}
                      newVendorType={newVendorType}
                      handleVendorTypeSelect={this.handleVendorTypeSelect}
                      handleColorPickerClick={this.handleColorPickerClick}
                      handleShowCreateVendorType={this.handleShowCreateVendorType}
                      handleShowEditVendorType={this.handleShowEditVendorType}
                      handleVendorTypeNameChange={this.handleVendorTypeNameChange}
                      handleVendorTypeColorChange={this.handleVendorTypeColorChange}
                      handleCreateVendorType={this.handleCreateVendorType}
                      handleUpdateVendorType={this.handleUpdateVendorType}
                      handleDeleteVendorType={this.handleDeleteVendorType}
                      personnelList={personnelList}
                      materialList={materialList}
                      createOrderMaterial={createOrderMaterial}
                      updateOrderMaterial={updateOrderMaterial}
                      deleteOrderMaterial={deleteOrderMaterial}
                      addMaterialToOrder={material => this.submit({materials: material})}
                      deleteMaterialFromOrder={material => this.submit({materials: material})}
                      accountUserType={accountInfo.user_type}
                      accountPersonnelId={accountInfo?.personnel?.id ?? null}
                      vendorDetails={vendorDetails}
                      inspectionNotesVisible={inspectionNotesVisible}
                      personnelEnabled={personnelEnabled}
                    />
                  </div>
                  <br/>
                  <AttachedFiles
                    files={vendorFiles}
                    displayImage={displayImage}
                    warrantyDocument={warrantyDocument}
                    azureDocumentPrefix={AZURE_VENDOR_PREFIX}
                    UUID={vendorUUID}
                    getDocuments={this.getVendorDocuments}
                    handleChangeDisplayImage={this.handleChangeDisplayImageNoUpload}
                    handleChangeWarrantyDocument={this.handleChangeWarrantyDocumentField}
                    handleRemoveDocument={this.handleRemoveDocument}
                    refreshObject={this.fetchVendorDetails}
                  />

                  <NoteSection
                    UUID={vendorUUID}
                    toggleInspectionNotes={this.toggleInspectionNotes}
                    accountInfo={accountInfo}
                    documentID={vendorID}
                    handleSubmit={addNote}
                    refreshObject={() => fetchNotes(vendorID)}
                    azureDocumentPrefix={AZURE_VENDOR_PREFIX}
                    getDocuments={this.getVendorDocuments}
                    notes={notes}
                    files={vendorFiles}
                    getRecentLogs={getRecentLogs}
                    title="VENDOR ORDER NOTES"
                    endpoint="vendor_orders"
                  />
                </div>
              </DetailsPaneRight>
            </Flex>
          )}
        </Container>
        {showLoader && (
          <Loading title={"Loading Vendor Order..."}/>
        )}
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}
        <Modal
          visible={deleteVendorOrderModalShowing}
          title={"Delete Vendor Order?"}
          onCancel={this.toggleDeleteVendorOrderModal}
          onOk={this.handleDeleteVendorOrder}
          isDeleteModal
        >
          <DescriptionText>Are you sure you want to delete this vendor order?</DescriptionText>
        </Modal>
      </div>
    );
  }
}

export default VendorDetails;
