import React from "react";
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";
import {Box, Descriptions, Flex} from "Base";
import {CreateSelectField} from "Common/Fields";

import {AdaptedInput} from "Base/Inputs/AdaptedInputs";
import {FormDropdown} from "Common/Forms/Base";
import {SupplierForm} from "../Agents";
import {formatSupplierList} from "./utils";
import {parseText} from "Common/Forms/Base/utils";
import {statusOptions} from "../../PurchaseOrders/Details/constants";
import {FilePond} from "react-filepond";

const FileAdapter = ({input: {value, onChange}, meta: {submitError, error}}) => (
  <FilePond
    onaddfile={(error, fileItem) => {
      onChange(fileItem);
    }}
    acceptedFileTypes={["image/*", "application/pdf"]}
  />
);

const inputSize = "medium";
const PurchaseOrderForm = ({
  onSubmit,
  updateBol,
  initialValues,
  createSupplier,
  suppliers,
  personnel,
}) => {
  const {reference, expected_date, instructions, supplier, purchaser, status} = initialValues;

  const defaultValues = {reference, expected_date, instructions, supplier, purchaser, status};

  const submitForm = (values) => {
    let data = values;

    console.log("order values", values);
    // TODO: make backend do this
    if (values.purchaser && values.purchaser.id) {
      data.purchaser = values.purchaser.id;
    }
    if (!initialValues.expected_date && values.expected_date) {
      data = {
        ...values,
        status: 2,
      };
    }
    if (initialValues.expected_date && !values.expected_date) {
      data = {
        ...values,
        status: 1,
      };
    }

    if (data.supplier && data.supplier.id) {
      data.supplier = data.supplier.id;
    }
    let formData = new FormData();
    let hasFiles = false;
    if (data.order) {
      hasFiles = true;
      formData.append("order", data.order.file);
      delete data.order;
    }
    if (data?.invoice) {
      hasFiles = true;
      formData.append("invoice", data.invoice.file);
      delete data.invoice;
    }
    if (hasFiles) {
      updateBol(formData);
    }
    onSubmit(data);
  };

  const supplierOptions = formatSupplierList(suppliers);

  return (
    <Form
      onSubmit={submitForm}
      initialValues={defaultValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form id={"purchase-order-form"} onSubmit={handleSubmit}>
          <Descriptions
            size="small"
            column={{sm: 2, xs: 1}}
            className={"page-details"}>
            <Descriptions.Item label={"Total"} span={2}>
              {initialValues.total_cost}
            </Descriptions.Item>

            <Descriptions.Item label={"Reference #"} span={1}>
              <Field
                type="text"
                name="reference"
                component={AdaptedInput}
                size={inputSize}
                parse={parseText}
                format={parseText}

              />
            </Descriptions.Item>
          </Descriptions>

          <Flex
            flexWrap={"wrap"}
            flexDirection={["column", "column", "row"]}
            alignItems={["", "", "center"]}>
            <Box pr={"10px"}>Order: </Box>
            <Box flex={1}>
              <Field
                name="order"
                component={FileAdapter}
                size={inputSize}
              />
            </Box>
          </Flex>
          <Flex
            flexWrap={"wrap"}
            flexDirection={["column", "column", "row"]}
            alignItems={["", "", "center"]}>
            <Box pr={"10px"}>Invoice: </Box>
            <Box flex={1}>
              <Field
                name="invoice"
                component={FileAdapter}
                size={inputSize}
              />
            </Box>
          </Flex>
          <Flex flexWrap={"wrap"} flexDirection={["column", "column", "row"]}>
            <FormDropdown
              label={"Supplier"}
              component={CreateSelectField}
              selectClassName={"details-dropdown"}
              name="supplier"
              form={SupplierForm}
              text={{
                title: "New Supplier",
                tooltip: "Create a new supplier",
              }}
              options={supplierOptions}
              onSubmit={createSupplier}
              wrapperConfig={{pr: 2, pb: 2}}
            />
            <FormDropdown
              label={"Purchaser"}
              name="purchaser"
              options={personnel}
              selectClassName={"purchase-order-peronnel"}
            />
            <FormDropdown
              label={"Status"}
              name="status"
              options={statusOptions}
              selectClassName={"details-dropdown"}
            />
          </Flex>


        </form>
      )}
    />
  );
};

PurchaseOrderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  suppliers: PropTypes.array,
  createSupplier: PropTypes.func.isRequired,
};

PurchaseOrderForm.defaultProps = {
  suppliers: [],
};

export default PurchaseOrderForm;
