import React, {Fragment} from "react";
import {ItemText} from "../../../Common/Bars/styled";

const PurchaseOrderBarDetails = props => {
  const {baseClassName} = props;

  return (
    <div className={`${baseClassName}-detail`}>
      <Fragment>
        <ItemText style={{"textDecoration": "underline"}}>LOCATION</ItemText>
        <ItemText></ItemText>
      </Fragment>
    </div>
  );
};

export {PurchaseOrderBarDetails};
