import React from "react";
import PropTypes from "prop-types";
import {Label, LabelText} from "./styled";
import {FormDropdown, PositiveNumberField} from "./index";
import {currencyList} from "./constants";

const FormMoneyField = props => {
  const {
    name,
    label,
    currencyName,
    isDisabled,
    numberProps
  } = props;
  return (
    <Label className={"item-details-form-money"} htmlFor={name}>
      <LabelText>{label}:</LabelText>
      <PositiveNumberField
        name={name}
        step={0.01}
        placeholder={"$0.00"}
        readOnlyMode={isDisabled}
        {...numberProps}
      />
      <FormDropdown
        options={currencyList}
        name={currencyName}
        isDisabled={isDisabled}
        format={(value, name) => (currencyList.find(el => el.value === value))}
        parse={(value, name) => value.value}
      />
    </Label>
  );
};


FormMoneyField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  editAccess: PropTypes.bool,
  currencyName: PropTypes.string,
  numberProps: PropTypes.object
};

FormMoneyField.defaultProps = {
  editAccess: true,
  options: [],
};


export {
  FormMoneyField
};
