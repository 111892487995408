import React, {Component} from "react";
import PropTypes from "prop-types";
import WebSocketInstance from "./WebSocket";
import {notification} from "antd";
import {Text, DirectLink} from "Base";
import {connect} from "react-redux";
import {authOperations, authSelectors} from "../store";
import {contentTypeReducer} from "../utils/contentTypes";

function mapStateToProps(state) {
  return {
    accessExpired: authSelectors.isAccessTokenExpired(state.auth),
    refreshToken: authSelectors.refreshToken(state.auth)
  };
}

const mapDispatchToProps = dispatch => ({
  handleTokenRefresh: (token) => dispatch(authOperations.refreshAccessToken(token))
});

// Component for connecting to and receiving data from the Notification websocket
class NotificationListener extends Component {
  state = {
    data: {},
  };

  componentDidMount() {
    this.handleWebsocket();
  }

  handleWebsocket = async () => {
    await this.handleTokenRefresh();
    WebSocketInstance.connect("/notifications/");
    this.waitForSocketConnection(() => {
      WebSocketInstance.addCallbacks(this.handleUpdate.bind(this), this.addMessage.bind(this));
    });
  };

  handleTokenRefresh = async () => {
    const {accessExpired, refreshToken, handleTokenRefresh} = this.props;
    if (accessExpired) {
      await handleTokenRefresh(refreshToken);
    }
  }

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(
      function() {
        // Check if websocket state is OPEN
        if (WebSocketInstance.state() === 1) {
          console.info("Connection is made");
          callback();
          return;
        } else {
          console.info("wait for connection...");
          component.waitForSocketConnection(callback);
        }
      }, 100); // wait 100 milisecond for the connection...
  }

  handleUpdate(data) {
    const {onReceive} = this.props;
    if (data && data.id) {
      onReceive && onReceive(data);
      this.openNotification(data);
      this.setState({data});
    }
  }

  openNotification = (data) => {
    try {
      const targetType = contentTypeReducer(data.target_content_type);
      const actionObjectType = contentTypeReducer(data.action_object_type);
      const args = {
        message: `${targetType.label} Update`,
        description:
          <Text>
            {data.actor?.personnel?.name ?? data.actor.username} {" "}
            {data.verb} {" "}
            {data?.action_object?.name ?? actionObjectType.label}{" to "}
            <DirectLink
              color={"purple"}
              href={`/#${targetType.url}/${data.target_object_id}`}>
              {data?.target?.name ?? targetType.label}
            </DirectLink>
          </Text>,
        duration: 0,
      };
      notification.open(args);
    } catch (e) {
      console.log("notification error", e);
    }
  };

  addMessage(message) {
    this.setState({messages: [...this.state.messages, message]});
  }

  render() {
    return (
      <div className={"notification-listener"}/>
    );
  }
}

NotificationListener.propTypes = {
  /**
   * Function to handle receiving websocket data
   * import {notificationActions} from 'store'
   * receive: (data) => dispatch(receiveNotification(data))
   */
  onReceive: PropTypes.func
};

NotificationListener.defaultProps = {
  onReceive: (data) => console.log("received", data)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationListener);
