export const selectionOptions = [
  {value: "asset.name", label: "Associated Asset Name"},
  {value: "asset.description", label: "Associated Asset Description"},
  {value: "title", label: "Title"},
  {value: "status", label: "Status"},
  {value: "personnel.name", label: "Assigned Personnel"},
  {value: "created_at", label: "Created At"},
  {value: "updated_at", label: "Updated At"},
];

/**
 * Filters options for the PM Orders
 */
export const filterOptions = [
  {value: "", label: "All"},
  {value: "created_today", label: "Created Today"},
  {value: "needs_approval", label: "Needs Approval"},
  {value: "occurring_today", label: "Occurring Today"},
];
