import React, {Component} from "react";
import {connect} from "react-redux";
import {
  authSelectors,
  baseSelectors,
  orgOperations,
  orgSelectors,
  productActions,
  productOperations,
  paginationSelectors, materialOperations, materialPageActions,
} from "store";

import {
  templatePageActions,
  templateMaterialActions,
  templateOperations
} from "../../../store/production";

import ProductDetails from "../../../components/Warehouse/Products/Details/ProductDetails";

const mapStateToProps = (state, ownProps) => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  productDetails: paginationSelectors.getItem(
    state.warehouse.products.items,
    ownProps.match.params.productID,
  ),
  // productDetails: productSelectors.getItem(
  //   state.warehouse.products,
  //   ownProps.match.params.productID
  // ),
  locationList: orgSelectors.refreshLocations(state.orgs),
  notes: baseSelectors.refreshNotes(
    state.warehouse.products.details,
    ownProps.match.params.productID,
  ),
  templatePagination: paginationSelectors.pagination(state.production.templates.items),
  materialPagination: paginationSelectors.pagination(state.materials.items)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDetails: id => dispatch(productActions.getItem(id)),
  getLocations: id => dispatch(orgOperations.getLocations(id)),
  addNote: async (id, data) =>
    dispatch(await productOperations.addNote(id, data)),
  updateDetails: (id, data) => dispatch(productOperations.updateItem(id, data)),
  updateProductField: async (id, fieldData) => dispatch(productOperations.updateItemField(id, fieldData)),
  deleteProduct: (id) => dispatch(productOperations.deleteItem(id)),
  fetchNotes: (id) => dispatch(productActions.getItemNotes(id)),
  createItemQuantity: (data) => dispatch(productOperations.createItemQuantity(data)),
  generateBarcode: (objId, orgId, barcodeType) =>
    dispatch(productActions.generateBarcode({objId, orgId, barcodeType})),
  materialActions: {
    update: (id, data) => dispatch(materialOperations.updateItem(id, data)),
    create: (data) => dispatch(materialOperations.createItem(data)),
    getPage: (page = 1, params = {}) => dispatch(materialPageActions.fetchPage({page, params}))
  },
  templateActions: {
    create: (data) => dispatch(templateOperations.createItem(data)),
    update: (id, data) => dispatch(templateOperations.updateItem(id, data)),
    delete: (id) => dispatch(templateOperations.deleteItem(id)),
    getPage: (page = 1, params = {}) => {
      const finalParams = {
        ...params,
        product: ownProps.match.params.productID,
        expand: "materials"
      };
      console.log("final params", finalParams);
      return dispatch(templatePageActions.fetchPage({page, params: finalParams}));
    }
  },
  templateMaterialActions: {
    create: (id, data) => dispatch(templateMaterialActions.createItem(id, data)),
    update: (templateId, id, data) => dispatch(templateMaterialActions.updateItem(templateId, id, data)),
    delete: (templateId, id) => dispatch(templateMaterialActions.deleteItem(templateId, id)),
  }
});

class ProductDetailsContainer extends Component {
  render() {
    return <ProductDetails {...this.props} />;
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductDetailsContainer);
