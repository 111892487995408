const {
  Aborter,
  BlobURL,
  BlockBlobURL,
  ContainerURL,
  ServiceURL,
  StorageURL,
  AnonymousCredential
} = require("@azure/storage-blob");

const AZURE_ACCOUNT_STORAGE_NAME = "conmittoplatformdiag";

// TODO: Find some way to generate this on the fly
// The SAS Token was generated using the azure-cli:
//    az storage account generate-sas --permissions racwdl --resource-types sco --services b
//        --expiry $(date - u - d '363 day' '+%Y-%m-%d') --account-name conmittoplatformdiag --account-key <ACCOUNT_KEY>
const AZURE_SAS_TOKEN = "se=2025-08-21&sp=rwdlac&sv=2022-11-02&ss=b&srt=sco&sig=Bt%2BRQ2hb/YqYCmI04lMl%2BxsWATGvud6wA77gdWqq0kE%3D";

// The SAS Token was generated using the azure-cli
//    az storage container generate-sas --permissions r --expiry $(date -u -d '363 day' '+%Y-%m-%d') --name conmitto-admin-documents
//        --account-name conmittoplatformdiag --account-key <ACCOUNT_KEY>
const AZURE_LINK_TOKEN = "se=2025-08-21&sp=r&sv=2022-11-02&sr=c&sig=aY0kc0%2BvDKsDzX0130NfkCLNDauMruCGTqCBbOM6CDM%3D";

export const AZURE_BLOB_CONTAINER_NAME = "conmitto-admin-documents";

export const AZURE_ASSET_PREFIX = "assets";
export const AZURE_STOCKROOM_PREFIX = "stockroom_assets";
export const AZURE_PERSONNEL_PREFIX = "personnel";
export const AZURE_PRODUCT_PREFIX = "products";
export const AZURE_MATERIAL_PREFIX = "materials";
export const AZURE_PM_ORDER_PREFIX = "pm_orders";
export const AZURE_VENDOR_PREFIX = "vendors";
export const AZURE_SERVPROVBUS_PREFIX = "service_provider_Business";
export const AZURE_SERVPROVDIV_PREFIX = "service_provider_Diversity";


/**
 * Gets the Azure Storage URL we need for uploading blobs to Azure
 * @returns {ServiceURL} URL for uploading blobs to Azure
 */
function getAzureServiceURL() {
  const pipeline = StorageURL.newPipeline(new AnonymousCredential());

  // When using AnonymousCredential, following url should include a valid SAS
  return new ServiceURL(`https://${AZURE_ACCOUNT_STORAGE_NAME}.blob.core.windows.net?${AZURE_SAS_TOKEN}`, pipeline);
}

/**
 * Gets the container that the blobs will be stored in
 * @param {string} containerName Name of the container
 * @returns {ContainerURL} URL corresponding to the container the blobs will be stored in
 */
function getAzureBlobContainerURL(containerName) {
  const serviceURL = getAzureServiceURL();
  return ContainerURL.fromServiceURL(serviceURL, containerName);
}

/**
 * Gets the URL that is used for downloading a file from Azure - read-only permissions
 * @param {string} filename Name of the file
 * @returns {string} URL to download the file
 */
function getAzureBlobURL(filename) {
  return `https://${AZURE_ACCOUNT_STORAGE_NAME}.blob.core.windows.net/conmitto-admin-documents/${encodeURI(filename)}?${AZURE_LINK_TOKEN}`;
}

/**
 * Creates a container on the Azure platform, the blobs will go inside of this container
 * @param {string} containerName Name of the container
 * @returns {Promise<void>} Resolves when the container has been created
 */
async function createAzureBlobContainer(containerName) {
  await getAzureBlobContainerURL(containerName).create(Aborter.none)
    .then(() => console.log(`Created blob container ${containerName}`),
      () => console.log(`Blob container ${containerName} already exists`));
}

/**
 * Prints the name of all the containers on Azure storage
 * @returns {Promise<void>} Resolves when all containers have been listed
 */
async function listAzureBlobContainers() {
  const serviceURL = getAzureServiceURL();
  let marker = null;
  do {
    const listContainersResponse = await serviceURL.listContainersSegment(Aborter.none, marker);

    marker = listContainersResponse.nextMarker;
    for (const container of listContainersResponse.containerItems) {
      console.log(`Container: ${container.name}`);
    }
  } while (marker);
}

/**
 * Gets a list of blobs inside a container
 * @param {string} containerName The name of the container
 * @param {string} delimiter Optional delimiter that acts as a virtual directory search.
 *      Example: To get files inside stockroom_asset, pass a delimiter of stockroom_asset
 * @returns {Promise<Array>} Resolves when all blobs have been obtained
 */
async function listBlobsInAzureContainer(containerName, delimiter) {
  const containerURL = getAzureBlobContainerURL(containerName);

  let marker = undefined;
  let blobs = [];
  try {
    do {
      const listBlobsResponse = await containerURL.listBlobFlatSegment(Aborter.none, marker, {
        "prefix": delimiter,
        "include": ["metadata"]
      });

      marker = listBlobsResponse.nextMarker;
      for (const blob of listBlobsResponse.segment.blobItems) {
        blobs.push(blob);
      }
    } while (marker);

    // Only return blobs that have 'noteid' in the metadata
    blobs = blobs.reduce((filtered, file) => {
      if (file.metadata && "noteid" in file.metadata) {
        filtered.push({
          "name": file.name,
          "noteID": file.metadata.noteid
        });
      }
      return filtered;
    }, []);
  }
  catch (error) {
    // TODO: Turn this into a toast
    console.error("Unable to get attached files");
  }

  return blobs;
}

/**
 * Uploads a blob to Azure
 * @param {string} containerName Name of the container that the blob is in
 * @param {any} content The blob contents
 * @param {number} size How large the file is
 * @param {string} filename Name of the blob
 * @param {number} noteID The ID of the note that the file is being uploaded for (leave blank if just attaching to object)
 * @returns {Promise<*>} Resolves when the blob has been uploaded
 */
async function uploadBlobToAzureContainer(containerName, content, size, filename, noteID) {
  const containerURL = getAzureBlobContainerURL(containerName);
  const blobURL = BlobURL.fromContainerURL(containerURL, filename);
  const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);
  let options = {metadata: {}};
  if (noteID !== "barcode") {
    options["metadata"]["noteid"] = noteID;
  }
  try {
    await blockBlobURL.upload(Aborter.none, content, size, options);
    return filename;
  } catch (error) {
    // TODO: Turn this into a toast
    console.error("Unable to upload file");
  }
}

/**
 * Uploads blobs to the azure storage, first checking to see if the filename will conflict with any existing blobs,
 * and appends a number to the filename if it does conflict
 * @param {string} UUID The ID of the object we are uploading to (stockroom asset, pm order, etc)
 * @param {string} azureDocumentPrefix What "folder" the blobs are stored in
 * @param {Array} blobs The blobs to upload to Azure
 * @param {string} [noteID] The ID of the note that the file is being uploaded for (leave blank if just attaching to object)
 * @returns {Promise<Array>} Promse that completes when all uploads have finished
 */
async function uploadBlobsToAzureWithFileNameCheck(UUID, azureDocumentPrefix, blobs, noteID = "") {
  let azureObjectPrefix = `${azureDocumentPrefix}/${UUID}`;
  let existingBlobs = await listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, azureObjectPrefix);
  let existingBlobNames = existingBlobs.map((blob) => blob.name);
  let uploadPromises = [];
  blobs.forEach(blob => {
    if (blob) {
      let fullFileName = blob.filename || blob.name;
      let azureFilename = `${azureObjectPrefix}/${fullFileName}`;
      let fileName = fullFileName.replace(/\.[^/.]+$/, "");
      let fileExt = fullFileName.split(".").pop();
      let counter = 0;
      while (existingBlobNames.includes(azureFilename)) {
        azureFilename = `${azureObjectPrefix}/${fileName}_${counter++}.${fileExt}`;
      }
      existingBlobNames.push(azureFilename);
      let file = blob.file || blob;
      uploadPromises.push(uploadBlobToAzureContainer(AZURE_BLOB_CONTAINER_NAME, file, file.size, azureFilename, noteID.toString()));
    }
  });
  return Promise.all(uploadPromises);
}

/**
 * A helper method used to read a Node.js readable stream into string
 * @param {Blob} blobData The data of the blob
 * @returns {Promise} Resolves when the stream has been parsed
 */
function streamToString(blobData) {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.readAsText(blobData);
    fr.addEventListener("loadend", () => resolve(fr.result));
  });
}

/**
 * Downloads and parses the blob with the given name
 * @param {string} containerName Name of the container that the blob is in
 * @param {string} blobName Name of the blob
 * @returns {Promise<*>} Resolves when the blob has been downloaded and read
 */
async function downloadAzureBlob(containerName, blobName) {
  const containerURL = getAzureBlobContainerURL(containerName);
  const blobURL = BlobURL.fromContainerURL(containerURL, blobName);

  const downloadBlockBlobResponse = await blobURL.download(Aborter.none, 0);
  let blobData = await downloadBlockBlobResponse.blobBody;
  const res = await streamToString(blobData);
  return res;
}

/**
 * Deletes a blob from the Azure container
 * @param {string} containerName Name of the container that the blob is in
 * @param {string} blobName Name of the blob
 * @returns {Promise<*>} Resolves when the blob has been deleted
 */
async function deleteBlobFromAzureContainer(containerName, blobName) {
  const containerURL = getAzureBlobContainerURL(containerName);
  const blobURL = BlobURL.fromContainerURL(containerURL, blobName);
  const res = await blobURL.delete(Aborter.none).catch(e => {
    // Ignore not found errors, irrelevant if we delete something that doesn't exist
    if (e.statusCode !== 404) {
      throw e;
    }
  });
  return res;
}

/**
 * Change the display image for the object to the specified image
 * @param {object} obj The state of the container (this)
 * @param {string} imageName The name of the image to change the display image to
 * @param {string} id The id of the object
 * @param {Function} updateFunction The function that sends a request to update the field
 */
async function handleChangeDisplayImage(obj, imageName, id, updateFunction) {
  if (imageName) {
    imageName = getAzureBlobURL(imageName);
  }
  updateFunction(id, {display_image: imageName})
    .then(() => obj.setState({displayImage: imageName}));
}

/**
 * Change the warranty document for the object to the specified image
 * @param {object} obj The state of the container (this)
 * @param {string} docName The name of the document to change the warranty document to
 * @param {string} id The id of the object
 * @param {Function} updateFunction The function that sends a request to update the field
 */
async function handleChangeWarrantyDocument(obj, docName, id, updateFunction) {
  if (docName) {
    docName = getAzureBlobURL(docName);
  }
  updateFunction(id, {warranty_document: docName})
    .then(() => obj.setState({warrantyDocument: docName}));
}

/**
 * Change the warranty document for the object to the specified image
 * @param {object} obj The state of the container (this)
 * @param {string} documentName The name of the document
 * @param {string} id The id of the object
 * @param {Function} updateFunction The function that sends a request to update the field
 * @param {Function} getDocuments The function that fetches the documents for this object
 */
async function removeDocument(obj, documentName, id, updateFunction, getDocuments) {
  const {displayImage, warrantyDocument} = obj.state;
  let azureLink = getAzureBlobURL(documentName);
  azureLink = azureLink.substring(0, azureLink.indexOf("?"));
  if (azureLink === displayImage) {
    updateFunction(id, {display_image: ""})
      .then(() => obj.setState({displayImage: ""}));
  } else if (azureLink === warrantyDocument) {
    updateFunction(id, {warranty_document: ""})
      .then(() => obj.setState({warrantyDocument: ""}));
  }
  deleteBlobFromAzureContainer(AZURE_BLOB_CONTAINER_NAME, documentName).then(getDocuments());
}


export {
  listBlobsInAzureContainer,
  handleChangeDisplayImage,
  handleChangeWarrantyDocument,
  deleteBlobFromAzureContainer,
  downloadAzureBlob,
  uploadBlobsToAzureWithFileNameCheck,
  uploadBlobToAzureContainer,
  listAzureBlobContainers,
  getAzureBlobURL,
  createAzureBlobContainer,
  removeDocument
};
