import React from "react";
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import {Label, LabelText} from "./styled";
import FormInput from "./FormInput";

const FormLinkField = props => {
  const {
    label,
    name,
    handleClick,
    isDisabled,
    url
  } = props;
  return label ?
    <Label htmlFor={name} marginBottom={20} width="100%">
      <LabelText>{label}:</LabelText>
      <a href={url}>
        <Field
          component={FormInput}
          placeholder={label}
          readOnlyMode={isDisabled}
          {...props}
          handleClick={handleClick}
        />
      </a>
    </Label>
    :
    <a href={url}>
      <Field
        component={FormInput}
        placeholder={label}
        readOnlyMode={isDisabled}
        {...props}
        handleClick={handleClick}
      />
    </a>;
};


FormLinkField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  inputType: PropTypes.string,
  display: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  url: PropTypes.string,
};

FormLinkField.defaultProps = {
  inputType: "input",
  display: "block",
  handleClick: () => null,
};

export default FormLinkField;
