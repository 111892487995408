import React from "react";
import moment from "moment";
import {basePreSaveFormat} from "../Base/utils";


export const load = async (values) => {
  console.info("Loading...");
  let result = values;

  console.log("result", result);
  return result;
};


export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  let result = values;

  delete result.skills;
  delete result.desired_skills;
  delete result.orgs;
  delete result.notes;
  delete result.default_org;

  if (values.user) {
    result.role = values.user.user_type;
    result.invitation_email = values.user.email;
    delete result.user;
  }

  if (values.reports_to && values.reports_to.id) {
    result.reports_to = values.reports_to.id;
  }

  return result;
};


export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  delete result.orgs;
  delete result.default_org;

  if (values.role) {
    result.user_type = values.role;
    delete result.role;
  }

  if (values.hired_date) {
    result.hired_date = moment(values.hired_date).format("YYYY-MM-DD");
  }

  if (values.reports_to && values.reports_to.id) {
    result.reports_to = values.reports_to.id;
  }

  if (values.title && values.title.id) {
    result.title = values.title.id;
  }

  return result;
};
