import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import InventoryListItem from "./InventoryListItem";
import {capitalize} from "lodash-es";
import {PRODUCT_INVENTORY} from "../../utils/constants";

const InventoryList = ({type, getInventory, loading, onSelect, onEdit, onRemove, pagination, location}) => {
  const {
    itemsPaged,
    itemsRequested, // can be used as loading boolean
    itemsCount,
  } = pagination;

  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: page => {
          getInventory(page, {location: location.id, page_size: 3});
        },
        total: itemsCount,
        showTotal: (total) => `Total ${total} items`,
        pageSize: 3,
        position: "top",
      }}
      loading={itemsRequested && itemsPaged.length === 0}
      dataSource={itemsPaged}
      header={
        <div>
          <p>Available {capitalize(type)}{"s"}</p>
        </div>
      }
      renderItem={item => (
        <InventoryListItem
          key={item.id}
          item={item}
          loading={loading}
          onSelect={onSelect}
          onEdit={onEdit}
          onRemove={onRemove}
          type={type}
        />
      )
      }
    />
  );
};

InventoryList.propTypes = {
  inventory: PropTypes.array,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

InventoryList.defaultProps = {
  type: PRODUCT_INVENTORY,
};

export default InventoryList;
