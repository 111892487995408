import React from "react";


export const load = async (values) => {
  console.info("Loading...");
  let result = values;

  console.log("result", result);
  return result;
};

export const loading = <div className="loading">Loading...</div>;

export const validate = values => {
  const errors = {};
  if (!values.oldPassword) {
    errors.oldPassword = "Required";
  }
  if (!values.newPassword) {
    errors.newPassword = "Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.confirmPassword !== values.newPassword) {
    errors.confirmPassword = "Must match new password";
  }
  return errors;
};

export const postLoadFormat = (values) => {
  let result = values;

  //

  return result;
};


export const preSaveFormat = (values) => {
  let result = values;

  //

  return result;
};
