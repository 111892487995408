import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as BaseNumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/core";
import {Field, useField} from "react-final-form";
import {LabelText} from "../../Forms/Base/styled";
// Import {CaretUp, CaretDown} from "../Icons";

const Control = ({name, ...rest}) => {
  const {
    meta: {error, touched},
  } = useField(name, {subscription: {touched: true, error: true}});
  return <FormControl {...rest} isInvalid={error && touched}/>;
};

const Error = ({name}) => {
  const {
    meta: {error},
  } = useField(name, {subscription: {error: true}});
  return <FormErrorMessage>{error}</FormErrorMessage>;
};

const NumberInput = props => {
  const {min, max, defaultValue, inputProps, onChange, ...rest} = props;
  const {name} = rest;
  return (
    <BaseNumberInput
      min={min}
      max={max}
      defaultValue={defaultValue}
      {...inputProps}
      {...rest}
    >
      <NumberInputField name={name}/>
      <NumberInputStepper>
        <NumberIncrementStepper className={"increment-stepper"}/>
        <NumberDecrementStepper className={"decrement-stepper"}/>
      </NumberInputStepper>
    </BaseNumberInput>
  );
};

NumberInput.propTypes = {
  defaultValue: PropTypes.number,
};

NumberInput.defaultProps = {
  defaultValue: 0,
  min: 0,
};

const AdaptedNumberInput = ({input, meta, ...rest}) => (
  <NumberInput inputProps={input} {...rest} isInvalid={meta.error && meta.touched}/>
);

const NumberInputControl = ({name, label, defaultValue, ...rest}) => (
  <Control name={name} my={4}>
    <FormLabel htmlFor={name}><LabelText>{label}</LabelText></FormLabel>
    <Field
      name={name}
      component={AdaptedNumberInput}
      placeholder={label}
      id={name}
      defaultValue={defaultValue}
      {...rest}
    />
    <Error name={name}/>
  </Control>
);

export {NumberInputControl};

export default NumberInput;
