import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";


// Use this to copy into a new form.
const BaseForm = ({children, ...props}) => {
  console.log(props);
  const {onSubmit, validate} = props;
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit, pristine, invalid}) => (
        <form onSubmit={handleSubmit}>
          {children}
        </form>
      )}
    />
  );
};


BaseForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  initialValues: PropTypes.object,
};

BaseForm.defaultProps = {
  initialValues: {name: "test"}
};

export default BaseForm;
