import React from "react";
// Import PropTypes from 'prop-types'
import FormField from "./FormField";
import FormDropdown from "./FormDropdown";

const FieldPrefixContext = React.createContext();


export const FieldPrefix = ({prefix, children}) => (
  <FieldPrefixContext.Provider value={prefix}>
    {children}
  </FieldPrefixContext.Provider>
);


export const PrefixedField = ({name, ...props}) => (
  <FieldPrefixContext.Consumer>
    {prefix => <FormField name={`${prefix}.${name}`} {...props} />}
  </FieldPrefixContext.Consumer>
);

export const PrefixedDropdown = ({name, ...props}) => (
  <FieldPrefixContext.Consumer>
    {prefix => <FormDropdown name={`${prefix}.${name}`} {...props} />}
  </FieldPrefixContext.Consumer>
);


export default {
  FieldPrefix,
  PrefixedField,
  PrefixedDropdown
};
