import React from "react";
import PropTypes from "prop-types";
import {Heading} from "../../../components/Common/Base";
import {Box} from "@chakra-ui/core";
import {roleList} from "../../../components/Common/Forms/PersonnelForm/constants";

const AccountInfo = ({account}) => {
  const role = roleList.find(x => x.value === account.user_type);

  return (
    <Box px={5}>
      <Heading as={"h1"} size="2xl" className="settings-view-text-header">{account.personnel.name}</Heading>
      <p className="settings-view-text"><b>Email:</b> {account.email}</p>
      <p className="settings-view-text"><b>Organization Name:</b> {account.personnel.default_org.name}</p>
      <p className="settings-view-text"><b>Organization Plan:</b> {account.personnel.default_org.plan}</p>
      <p className="settings-view-text"><b>Organization Type:</b> {account.personnel.default_org.type}</p>
      <p className="settings-view-text"><b>Role:</b> {role ? role.label : "Undefined"}</p>
      <p className="settings-view-text">
        <b>Title:</b> {account.personnel.title ? account.personnel.title.name : "None"}</p>
      <p className="settings-view-text"><b>Email Frequency:</b> None</p>
    </Box>
  );
};

AccountInfo.propTypes = {
  account: PropTypes.any,
};

export default AccountInfo;
