import {createPaginator} from "../../base/paginator";
import {productEndpoint, inventoryEndpoint} from "./constants";

const productPaginator = createPaginator({
  name: "products",
  endpoint: productEndpoint
});

const paginationActions = productPaginator.actions;

const inventoryPaginator = createPaginator({
  name: "inventory",
  endpoint: inventoryEndpoint,
});

const inventoryPageActions = inventoryPaginator.actions;

export {
  productPaginator,
  paginationActions,
  inventoryPaginator,
  inventoryPageActions,
};
