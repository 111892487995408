import {baseCsvFields, makeCsvColumn} from "../../../utils/csv";

export const selectionOptions = [
  {value: "name", label: "Name"},
  {value: "title", label: "Title"},
  {value: "tags", label: "Tags"},
  {value: "role", label: "Permission Level"},
  {value: "created_at", label: "Created At"},
  {value: "updated_at", label: "Updated At"},
];


export const csvColumns = [
  makeCsvColumn("name"),
  makeCsvColumn("type"),
  makeCsvColumn("classification"),
  makeCsvColumn("wage"),
  makeCsvColumn("wage_currency"),
  makeCsvColumn("over_time_wage"),
  makeCsvColumn("over_time_wage_currency"),
  makeCsvColumn("skills"),
  makeCsvColumn("desired_skills"),
  makeCsvColumn("reports_to"),
  makeCsvColumn("hired_date"),
  makeCsvColumn("time_logged"),
  makeCsvColumn("status"),
  makeCsvColumn("phone_number"),
  makeCsvColumn("title"),
  ...baseCsvFields
];

export const sampleData = [
  {
    "name": "Darth Vader",
    "type": "Contractor",
    "classification": "Repair",
    "wage": "10000",
    "wage_currency": "USD",
    "over_time_wage_currency": "USD",
    "over_time_wage": "5000000",
    "skills": "\"LightSaber Skills, force\"",
    "desired_skills": "Padme",
    "reports_to": "2",
    "hired_date": "2019-03-20",
    "time_logged": "300",
    "status": "Employed",
    "phone_number": "111-111-1111",
    "title": "Darth",
  }
];
