import {withCookieAuth} from "../store/auth/selectors";
import axios from "axios";


/**
 * Performs a general Axios request
 * @param {object} params The request parameters
 * @param {object} [params.headers] The parameter headers to use
 * @param {string} params.method What HTTP method to use
 * @param {string} params.url What URL to use
 * @param {string} [params.responseType] The response type of the request
 * @param {object} [params.data] What data to send along with the request
 * @returns {Promise} A promise that resolves when the request has completed
 */
const generalAxiosResponse = ({headers, method, url, responseType, data}) => {
  if (headers === undefined) {
    headers = withCookieAuth({
      Accept: "application/json",
      "Content-Type": "application/json"
    });
  }
  return axios({
    method,
    url,
    headers,
    responseType,
    data
  });
};

/**
 * An HTTP GET request using Axios
 * @param {object} params The request parameters
 * @param {object} [params.headers] The request headers to use
 * @param {string} params.url What URL to use
 * @param {string} [params.responseType] The response type of the request
 * @returns {Promise} A promise that resolves when the request has completed
 */
const getAxios = (params) => generalAxiosResponse({...params, method: "GET"});

/**
 * An HTTP POST request using Axios
 * @param {object} params The request parameters
 * @param {object} [params.headers] The request headers to use
 * @param {string} params.url What URL to use
 * @param {string} [params.responseType] The response type of the request
 * @param {object} params.data What data to send along with the request
 * @returns {Promise} A promise that resolves when the request has completed
 */
const postAxios = (params) => generalAxiosResponse({...params, method: "POST"});

/**
 * An HTTP PUT request using Axios
 * @param {object} params The request parameters
 * @param {object} [params.headers] The request headers to use
 * @param {string} params.url What URL to use
 * @param {string} [params.responseType] The response type of the request
 * @param {object} params.data What data to send along with the request
 * @returns {Promise} A promise that resolves when the request has completed
 */
const putAxios = (params) => generalAxiosResponse({...params, method: "PUT"});

/**
 * An HTTP PATCH request using Axios
 * @param {object} params The request parameters
 * @param {object} [params.headers] The request headers to use
 * @param {string} params.url What URL to use
 * @param {string} [params.responseType] The response type of the request
 * @param {object} params.data What data to send along with the request
 * @returns {Promise} A promise that resolves when the request has completed
 */
const patchAxios = (params) => generalAxiosResponse({...params, method: "PATCH"});

/**
 * An HTTP DELETE request using Axios
 * @param {object} params The request parameters
 * @param {object} [params.headers] The request headers to use
 * @param {string} params.url What URL to use
 * @param {string} [params.responseType] The response type of the request
 * @returns {Promise} A promise that resolves when the request has completed
 */
const deleteAxios = (params) => generalAxiosResponse({...params, data: undefined, method: "DELETE"});


export {
  getAxios,
  postAxios,
  putAxios,
  patchAxios,
  deleteAxios
};

