import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import {SalesOrderBar} from "../Bar/SalesOrderBar";

const OrderList = ({data, loading, listView}) => {
  return (
    <List
      itemLayout={"vertical"}
      size={"large"}
      className={"sales-order-list"}
      dataSource={data}
      renderItem={item => <SalesOrderBar listView={listView} key={item.id} data={item}/>}
      loading={loading}
    />
  );
};

OrderList.propTypes = {
  data: PropTypes.array
};

export default OrderList;
