import {reducer as reduxFormReducer} from "redux-form";
import {snackbarReducer} from "react-redux-snackbar";
import {calculateJWTTokenExpirationDate, createAuthReducer} from "@tshio/redux-api-auth-middleware";
import {combineReducers} from "redux";

import notificationsModule from "./personnel/notifications";
import authReducer from "./auth";
import orgReducer from "./orgs";
import materialReducer from "./materials";
import productReducer from "./products/reducers";
import assetReducer from "./assets";
import personnelReducer from "./personnel";
import pmOrderReducer from "./pmOrders";
import scheduledPMOrdersReducer from "./scheduledPMOrders";
import stockroomAssetReducer from "./stockroomAssets";
import vendorReducer from "./vendors";
import scheduleShiftReducer from "./scheduledShifts";
import palletsReducer from "./warehouse/pallets";
import purchaseOrderReducer from "./warehouse/purchaseOrders";
import warehouseReducer from "./warehouse/reducers";
import productionReducer from "./production";
import locationReducer from "./locations";

import pmReducer from "./orders/pmOrders";
// See config details below.

const authTokenReducer = createAuthReducer({
  getExpirationTimestamp: calculateJWTTokenExpirationDate,
});


const rootReducer = combineReducers({
  account: authReducer,
  auth: authTokenReducer,
  production: productionReducer,
  warehouse: warehouseReducer,
  assets: assetReducer,
  orgs: orgReducer,
  materials: materialReducer,
  pallets: palletsReducer,
  // needed for csv messages
  products: productReducer,
  personnel: personnelReducer,
  notifications: notificationsModule.reducer(),
  orders: pmReducer,
  pmOrders: pmOrderReducer,
  purchaseOrders: purchaseOrderReducer,
  scheduledPMOrders: scheduledPMOrdersReducer,
  vendors: vendorReducer,
  stockroomAssets: stockroomAssetReducer,
  schedules: scheduleShiftReducer,
  snackbar: snackbarReducer,
  form: reduxFormReducer,
  locations: locationReducer,
});

export default rootReducer;
