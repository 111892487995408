export const formatMaterialOptions = (items) => {
  return items.map(material => ({
    id: material.id,
    manufacturer: material.manufacturer,
    sku: material.sku,
    price: material.price || "",
    price_currency: material.price_currency || "",
    value: material.id,
    label: material.name || "Unnamed"
  }));
};

function refreshNameList(state) {
  if (state.items) {
    return formatMaterialOptions(state.items);
  }
  return [];
}

export default {
  refreshNameList
};
