import types from "./types";

import {filters, sort} from "../../utils";
import {baseReducer, getNumberOfPages, INITIAL_STATE, withCsv} from "../base/reducers";

const initialState = {...INITIAL_STATE};

/* Main reducer for this duck. import into ../reducers.js and add to root reducer. */
const productsReducer = (state = initialState, action) => {
  switch (action.type) {
  // CRUD ACTIONS
  /* Sets items to payload returned from getAllItems in operations. */
  case types.GET_ALL_SUCCESS: {
    let newItems = [];
    /* If success = 0, action.assets.message is a string and not an array
    and must be handled differnetly. Check payload in redux-devtools for
    debugging issues like this. */
    if (action.payload && action.payload.length) {
      newItems = action.payload;
    }
    const data = {};
    newItems.forEach(item => data[item.id] = item);

    let filteredList = sort.sortByKey(filters.filterListByName(newItems, state.filter), state.sortBy);
    if (filteredList.length === 0) {
      filteredList = sort.sortByKey(filters.filterListByManufacturer(newItems, state.filter), state.sortBy);
    }
    const pages = getNumberOfPages(newItems.length, state.itemsPerPage);
    return {
      ...state,
      loading: false,
      items: action.payload,
      data,
      filterList: filteredList,
      pages,
      currentPage: 1,
      pageList: filteredList.slice(0, state.itemsPerPage),
      errors: {}
    };
  }
  case types.GET_ALL_FAILURE: {
    return {
      ...state,
      loading: false,
      errors: action
    };
  }
  case types.GET_ALL_NONE_FOUND: {
    return {
      ...state,
      loading: false,
      noneFound: true
    };
  }
  case types.GET_ITEM_SUCCESS: {
    const newData = {
      ...state.data,
      [action.payload.id]: action.payload
    };
    return {
      ...state,
      loading: false,
      data: newData,
    };
  }
  case types.GET_ITEM_FAILURE: {
    return {
      ...state,
      loading: false,
      errors: action
    };
  }
  case types.CREATE_ITEM_SUCCESS: {
    let {items} = state;
    items.push(action.payload);
    return {
      ...state,
      items
    };
  }
  case types.UPDATE_ITEM_SUCCESS: {
    const {payload} = action;
    let {items} = state;
    const index = items.findIndex(item => item.id === payload.id);
    items[index] = payload;
    return {
      ...state,
      items
    };
  }
  case types.DELETE_ITEM_SUCCESS: {
    const {payload} = action;
    let {items} = state;
    const index = items.findIndex(item => item.id === payload.id);
    items.splice(index, 1);
    return {
      ...state,
      items
    };
  }
  case types.DELETE_ITEM_FAILURE:
  case types.UPDATE_ITEM_FAILURE: {
    return {
      ...state,
      errors: action.payload
    };
  }

  case types.GET_ITEM_NOTES_SUCCESS: {
    const newNotes = {
      ...state.notes,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      notes: newNotes
    };
  }

  /* Filters items in state by those that include the querry in item.name and item.tags. */
  case types.FILTER_LIST: {
    // Const newFilter = action.filter.split(' ');
    let newList = filters.filterListByAll(state.items, action.filter);
    if (newList.length === 0) {
      newList = filters.filterListByManufacturer(state.items, action.filter);
    }
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filter: action.filter,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }
  /* Filters items in state by those that include the querry in item.manufacturer name */
  case types.FILTER_LIST_BY: {
    // Action.payload contains a 'by' key which could be used later to sort by different asset details
    let newList = filters.filterListByManufacturer(state.items, action.payload.filter);
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filter: action.payload.filter,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  default:
    return state;
  }
};

export default withCsv(types, baseReducer(types, productsReducer));
