import {barcodeCsvFields, baseCsvFields, makeCsvColumn} from "../../../../utils/csv";

export const selectionOptions = [
  {value: "name", label: "Name"},
  {value: "created_at", label: "Created At"},
  {value: "updated_at", label: "Updated At"},
];

// TODO: replace this description
export const productsDescriptions = [
  "Conmitto Manager makes it simple to track your catalog of finished goods."
];

export const csvColumns = [
  makeCsvColumn("name"),
  makeCsvColumn("manufacturer"),
  makeCsvColumn("sku"),
  makeCsvColumn("price"),
  makeCsvColumn("price_currency"),
  ...barcodeCsvFields,
  ...baseCsvFields
];

export const csvData = [
  {
    name: "ACS550",
    manufacturer_name: "ABB",
    price: "42",
    sku: "987-321",
  },
  {
    name: "ACS800",
    manufacturer_name: "ABB",
    price: "100",
    sku: "123456",
  }
];
