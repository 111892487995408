import React from "react";
import PropTypes from "prop-types";
import {Descriptions} from "antd";
import {Box} from "rebass";
import {PageHeader} from "../../../Common/Details";
import {ProductForm} from "../../../Common/Forms";

const DetailsHeader = ({data, onUpdate, onDelete, actions, SecondaryContent, Image, formProps}) => {
  const {created_by} = data;

  const renderDescriptions = () => (
    <Box pb={3}>
      <Descriptions
        size="small"
        column={{md: 3, sm: 2, xs: 1}}
        className={"page-details"}
      >
        {created_by && <Descriptions.Item label="Created by">{created_by.name}</Descriptions.Item>}
        <Descriptions.Item label="Name">{data.name || "None"}</Descriptions.Item>
        <Descriptions.Item label="Manufacturer">{data.manufacturer || "None"}</Descriptions.Item>
        <Descriptions.Item label="SKU">{data.sku || "None"}</Descriptions.Item>
        <Descriptions.Item label="Price">{data.price || "None"}</Descriptions.Item>
      </Descriptions>
      <Descriptions
        size={"small"}
        title={"Shipping"}
      >
        <Descriptions.Item label="Width">{data.width || "None"}</Descriptions.Item>
        <Descriptions.Item label="Height">{data.height || "None"}</Descriptions.Item>
        <Descriptions.Item label="Length">{data.depth || "None"}</Descriptions.Item>
        <Descriptions.Item label="Weight">{data.weight || "None"}</Descriptions.Item>
        <Descriptions.Item label="Max Quantity per package">{data.volume || "None"}</Descriptions.Item>
      </Descriptions>
      {/* {SecondaryContent} */}
    </Box>
  );

  return (
    <PageHeader
      data={data}
      DetailsForm={ProductForm}
      onUpdate={onUpdate}
      onDelete={onDelete}
      image={Image}
      renderDescription={renderDescriptions}
      actions={actions}
      formID={"product-form"}
      formProps={formProps}
      text={{
        title: "Product Details",
        name: "Product",
        shortName: "product",
      }}
      editConfig={{
        formID: "product-form",
      }}
      deleteConfig={{
        confirmText: "Deleting this will remove all items assigned to it.",
        redirectUrl: "/dashboard/warehouse/inventory",
      }}
    >
      {SecondaryContent}
    </PageHeader>
  );
};

DetailsHeader.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  actions: PropTypes.array,
  formProps: PropTypes.object
};

export default DetailsHeader;
