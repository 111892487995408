import {combineReducers} from "redux";

import templateReducer from "./templates/templates";
import productionLineReducer from "./lines";
import entryReducer from "./lines/entries";

export default combineReducers({
  templates: templateReducer,
  lines: productionLineReducer,
  entries: entryReducer
});
