import translateLabel from "../utils/translateLabel";

export const DATE_TIME_FORMAT = "YYYY-MM-DD";

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
  "Day",
  "Weekday",
  "Weekend day"
];

export const returnNumeralOptions = (name, translations) => {
  return [
    {
      value: "First",
      label: translateLabel(translations, "numerals.first"),
      name
    },
    {
      value: "Second",
      label: translateLabel(translations, "numerals.second"),
      name
    },
    {
      value: "Third",
      label: translateLabel(translations, "numerals.third"),
      name
    },
    {
      value: "Fourth",
      label: translateLabel(translations, "numerals.fourth"),
      name
    },
    {value: "Last", label: translateLabel(translations, "numerals.last"), name}

  ];
};
