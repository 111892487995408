import React from "react";
import PropTypes from "prop-types";
import {Input, InputWrapper} from "../../styles/styled";
import {numericalDateFieldHandler} from "../../utils/numericalFieldHandler";
import {Flex} from "@chakra-ui/core";

const EndOnDate = ({
  id,
  onDate: {date},
  handleChange,
}) => {
  return (
    <Flex className="form-group" alignItems={"center"} flexGrow={"1"}>
      <InputWrapper width={"100%"}>
        <Input
          id={id}
          background-color={"white"}
          type="date"
          name="end.onDate.date"
          aria-label="End on"
          className="form-control"
          value={date}
          onChange={numericalDateFieldHandler(handleChange)}
        />
      </InputWrapper>
    </Flex>
  );
};

EndOnDate.propTypes = {
  id: PropTypes.string.isRequired,
  onDate: PropTypes.shape({
    date: PropTypes.string.isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired
};

export default EndOnDate;
