import React, {Component} from "react";
import {connect} from "react-redux";
import {authSelectors, orgOperations, paginationSelectors} from "../../../store";
import {linePageActions, lineOperations, lineAssetActions} from "../../../store/production";

import ProductionDashboard from "../../../components/Production/Dashboard/ProductionDashboard";
import {scrollToTop} from "../../../utils/containerUtils";

const mapStateToProps = (state) => {
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    pagination: paginationSelectors.pagination(state.production.lines.items)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeView: (view) => dispatch(linePageActions.changeView(view)),
    actions: {
      create: (data) => dispatch(lineOperations.createItem(data)),
      update: (id, data) => dispatch(lineOperations.updateItem(id, data)),
      delete: (id) => dispatch(lineOperations.deleteItem(id)),
      getPage: (page, params) => {
        const finalParams = {
          ...params,
          expand: "location"
        };
        return dispatch(linePageActions.fetchPage({page, params: finalParams}));
      }
    },
    lineAssetActions: {
      create: (data) => dispatch(lineAssetActions.createItem(data)),
      update: (id, data) => dispatch(lineAssetActions.updateItem(id, data)),
      delete: (id) => dispatch(lineAssetActions.deleteItem(id))
    },
    getLocations: (id) => dispatch(orgOperations.getLocations(id)),

  };
};

class ProductionLinesContainer extends Component {
  componentDidMount() {
    scrollToTop();
  }
  render() {
    return (
      <ProductionDashboard {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionLinesContainer);
