import {css} from "@emotion/core";

const gloablStyle = css`
  @font-face {
    font-family: "Open Sans";
    src: url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap") format('woff2);
   }
`;

export default gloablStyle;
