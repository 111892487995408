import {
  PURCHASE_ORDERS_DASHBOARD_ROUTE,
  SALES_ORDERS_DASHBOARD_ROUTE,
  PALLET_DASHBOARD_ROUTE,
  PRODUCTS_DASHBOARD_ROUTE,
  MATERIAL_DASHBOARD_ROUTE
} from "../../../../routes/constants";

import {dataSoon} from "../../../Common/Dashboard/ScorecardDashboard/constants";

/**
 * Parse information from the summary prop and set certain fields for the MaintenanceScorecard
 * @param {object} summary - the summary object
 * @returns {Array} - parsed summaries
 */
export const summaries = summary =>
  [
    {
      title: "Purchase Orders",
      stats: [{
        name: "Incoming Orders",
        value: summary.pm_orders.inspect_today,
        style: {color: "#3f8600"},
        suffix: "PO",
        route: PURCHASE_ORDERS_DASHBOARD_ROUTE
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    },
    {
      title: "Sales Orders",
      stats: [{
        name: "Orders to Build",
        value: summary.sales_orders.to_build,
        style: {color: "#0945de"},
        suffix: "SO",
        route: SALES_ORDERS_DASHBOARD_ROUTE
      },
      {
        name: "Orders to Ship",
        value: summary.sales_orders.to_ship,
        style: {color: "#6b0acd"},
        suffix: "SO",
        route: SALES_ORDERS_DASHBOARD_ROUTE
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    },
    {
      title: "Pallets",
      stats: [{
        name: "Pallets to build",
        value: summary.pallets.to_build,
        style: {color: "#865500"},
        suffix: "pts",
        route: PALLET_DASHBOARD_ROUTE
      },
      {
        name: "Pallets to break",
        value: summary.pallets.to_break,
        style: {color: "#865500"},
        suffix: "pts",
        route: PALLET_DASHBOARD_ROUTE
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    },
    {
      title: "Inventory",
      stats: [{
        name: "Total Unique Items",
        value: summary.inventory.individual,
        styl: {color: "#3f8600"},
        suffix: "products",
        route: PRODUCTS_DASHBOARD_ROUTE
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    },
    {
      title: "Materials",
      stats: [{
        name: "Total Unique Materials",
        value: summary.materials.individual,
        style: {color: "rgba(63,19,217,0.87)"},
        suffix: "units",
        route: MATERIAL_DASHBOARD_ROUTE
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    }
  ];
