import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {Layout, Loading, Flex} from "Base";
import Side from "./Side";
import {MOBILE, TABLET} from "utils/constants";
import {useMediaQuery} from "react-responsive";
import Navigation from "./Navigation";
import NavAffix from "./NavWrapper";
import {getSelectedKeys, navReducer} from "./navReducer";
import {HeaderWrapper, NavWrapper, MainLayout, MainContent, ContentLayout} from "./styled";


const AppLayout = ({doneLoading, mainHeader, footer, location, match, children, account, resetStore}) => {
  const [expandSider, setExpandSider] = useState(true);
  const isTabletOrMobile = useMediaQuery(MOBILE);

  const isSliderCollapsed = useMediaQuery(TABLET);

  useEffect(() => {
    if (isSliderCollapsed) {
      setExpandSider(false);
    }
  }, [isSliderCollapsed]);


  const sideMenu = navReducer(location, match);

  const checkShowSide = () => {
    if (sideMenu && !isTabletOrMobile && doneLoading) {
      return true;
    }
    return false;
  };
  const selectedKeys = getSelectedKeys(location, sideMenu);

  const toggleSider = () => setExpandSider(!expandSider);

  let layoutConfig = {
    marginLeft: 0,
    marginTop: isTabletOrMobile ? 0 : 150,
    padding: isTabletOrMobile ? 0 : "0 24px 12px",
  };
  if (sideMenu) {
    layoutConfig = {
      marginLeft: isSliderCollapsed ? 0 : 200,
      marginTop: isTabletOrMobile ? 0 : 150,
      // padding: isTabletOrMobile ? 0 : "0 24px 24px"
    };
  }

  layoutConfig.marginTop = 0;


  let mainContent = children;
  if (!doneLoading) {
    mainContent = (<Flex flexWrap={"wrap"} flexDirection={"column"} align={"center"} justifyContent={"center"} className="items-loader">
      <Loading title={"Loading Account..."}/>
    </Flex>);
  }

  const showSideMenu = checkShowSide();
  return (
    <Layout style={{minHeight: "100%"}}>
      <HeaderWrapper>
        {mainHeader}
        <NavAffix>
          <NavWrapper>
            <Navigation
              toggleSider={toggleSider}
              expanded={expandSider}
              showToggle={isSliderCollapsed && sideMenu}
              isMobile={isTabletOrMobile}
              sideMenu={sideMenu}
              submenuSelected={selectedKeys}
              account={account}
              resetStore={resetStore}
            />
          </NavWrapper>
        </NavAffix>
      </HeaderWrapper>

      <MainLayout mt={layoutConfig.marginTop}>

        {showSideMenu &&
        <NavAffix offsetTop={64}>

          <Side
            menu={sideMenu.menu}
            selected={selectedKeys}
            collapsed={!expandSider}
            toggle={toggleSider}
            isCollapsable={isSliderCollapsed}
          />
        </NavAffix>

        }

        <ContentLayout>

          <MainContent
            className="site-layout-background"
          >

            {mainContent}
          </MainContent>

        </ContentLayout>
      </MainLayout>
      {footer}

    </Layout>
  );
};

AppLayout.propTypes = {
  mainHeader: PropTypes.element,
  showSider: PropTypes.bool,
  footer: PropTypes.element,
};

AppLayout.defaultProps = {
  showSider: false,
};
export default withRouter(AppLayout);
