import React, {Component} from "react";
import {Redirect} from "react-router-dom";
// Components and Constants
import {OldStyleDashboard} from "../../Common/Dashboard/OldStyleDashboard/OldStyleDashboard";
import {ErrorHeading, Heading} from "../../Common/Base";
import {AssetBar} from "../Bar/AssetBar";
import {AssetsTable} from "../Table/AssetsTable";
import {assetHelp} from "../../Common/HelpItems/constants";
import {csvColumns, csvData, filterOptions, selectionOptions} from "./constants";
// Utilities
import {
  checkFiltersDidMount,
  checkFiltersDidUpdate,
  checkForResponseError,
  handleErrorResponse,
  handleFilterDropdownChange,
  scrollToTop,
} from "../../../utils/containerUtils";
import {postAxios} from "../../../utils/authWithAxios";

/* This will be the Assets dashboard where a user can create, search, manipulate, and destroy Assets */
class AssetsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedAssetID: "",
      addItemModalSubmitting: false,
      addItemModalError: false,
      errorLoadingMessage: "",
    };
  }

  /* Upon mounting, fetch a list of assets and store the array in state. They
      will be rendered using the map method. */
  componentDidMount() {
    const {getAssetList, token, sortAssets, filterListBy, location} = this.props;
    checkFiltersDidMount(location.search, filterListBy);
    getAssetList(token)
      .then(res => checkForResponseError(res))
      .catch(err => {
        handleErrorResponse(this, err, "Assets");
      });
    sortAssets("created_at");
    scrollToTop();
  }

  componentDidUpdate = (prevPops) => {
    checkFiltersDidUpdate(this.props, prevPops);
  };

  /* Handles filtering the assets from the dropdown element  */
  handleFilterBy = e => {
    const {location, filterListBy, history} = this.props;
    handleFilterDropdownChange(e, "filterby", "assets", filterListBy, location, history);
  };

  /* Handles filtering the assets by type from the dropdown element  */
  handleFilterByType = e => {
    const {location, filterListByType, history} = this.props;
    handleFilterDropdownChange(e, "type", "assets", filterListByType, location, history);
  };

  handleTableFieldChange = async data => {
    const {updateAssetDetails, getAssetList} = this.props;
    const newData = data[0];
    const index = newData.dataIndex;
    const id = newData.key;
    const updateData = {
      [index]: newData[index]
    };
    await updateAssetDetails(id, updateData);
    await getAssetList();
  }

  /* This function will perform the API request required to add a new asset. The new asset
    might have one special attribute defined with key and value pair,
    the user will be forwarded to the Edit Asset page for the new asset where he can then enter details. */
  handleSubmitAddItem = (key = "", value = "") => {
    const {accountInfo} = this.props;

    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false
    });

    const dataToSubmit = {
      org: accountInfo.personnel ? accountInfo.personnel.orgs[0].id : null,
    };
    // Add data if provided
    if (key !== "" && value !== "") {
      dataToSubmit[key] = value;
    }
    postAxios({
      url: `${global.base_url}v1/assets/`,
      data: dataToSubmit
    })
      .then(res => {
        console.log(res);
        this.setState({
          addedAssetID: res.data.id,
          addItemModalSubmitting: false
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          addItemModalSubmitting: false,
          addItemModalError: true
        });
      });
  };

  render() {
    const {
      addItemModalSubmitting,
      addItemModalError,
      addedAssetID,
      errorLoadingMessage
    } = this.state;
    const {
      isEmptyList,
      filterAssets,
      sortAssets,
      getAssetList,
    } = this.props;

    // Privileges for editing asset
    const assetEditAccess = this.props.accountInfo.user_type <= 2;

    /* Upon adding an asset redirect to edit asset page for that particular asset. */
    if (addedAssetID) return <Redirect to={`/dashboard/assets/${addedAssetID}`}/>;

    return (
      <section>
        <OldStyleDashboard
          {...this.props}
          selectionOptions={selectionOptions}
          hasEditAccess={assetEditAccess}
          csvColumns={csvColumns}
          csvData={csvData}
          handleSubmitAddItem={this.handleSubmitAddItem}
          handleUploadCsv={this.handleUploadCsv}
          helpDialog={assetHelp}
          type="Asset"
          addItemModalSubmitting={addItemModalSubmitting}
          addItemModalError={addItemModalError}
          filterItems={filterAssets}
          sortItems={sortAssets}
          handleFilterByType={this.handleFilterByType}
          handleFilterBy={this.handleFilterBy}
          filterOptions={filterOptions}
          getItemList={getAssetList}
          barComponent={<AssetBar />}
          tableComponent={<AssetsTable handleFieldChange={this.handleTableFieldChange}/>}
        />
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}
        {isEmptyList && <Heading as={"h1"} textAlign={"center"}>No Assets Found</Heading>}
      </section>
    );
  }
}

export {AssetsDashboard};
