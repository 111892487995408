import React, {Fragment} from "react";
import {ItemText} from "../../../Common/Bars/styled";

const Details = props => {
  const {
    baseClassName,
    productData
  } = props;

  return (
    <div className={`${baseClassName}-detail`}>
      <Fragment>
        <ItemText style={{"textDecoration": "underline"}}>Quantities</ItemText>
        <ItemText>Total: {productData.quantity}</ItemText>
        <ItemText>In Use: {productData.in_use}</ItemText>
        <ItemText>Available: {productData.available_quantity}</ItemText>
      </Fragment>
    </div>
  );
};

export {Details};
