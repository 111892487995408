const NEW = 1;
const INCOMING = 2;
const OUTGOING = 3;
const RECEIVED = 4;
const RECEIVED_WITH_ERRORS = 5;
const ARRIVED = 5;

/**
 * Pallet statuses that line up with the integers from the backend
 * @type {object}
 */
const palletStatus = {
  NEW,
  INCOMING,
  OUTGOING,
  RECEIVED,
  RECEIVED_WITH_ERRORS,
  ARRIVED,
};

const UNPACK = "unpack";

const STORE = "store";

const PRODUCT = "product";

const MATERIAL = "material";
const PRODUCT_INVENTORY = "product";
const MATERIAL_INVENTORY = "material";

const PURCHASE_ORDER = "purchase_order";
const SALES_ORDER = "sales_order";

const SUPPLIER = "supplier";

const CUSTOMER = "customer";

export const PACKED = 1;
export const UNPACKED = 2;
export const NEEDS_PACKING = 3;
export const NEEDS_UNPACKING = 4;

export const palletQuantityStatus = {
  PACKED,
  UNPACKED,
  NEEDS_PACKING,
  NEEDS_UNPACKING,
};


export {
  palletStatus, UNPACK, STORE, PRODUCT_INVENTORY,
  MATERIAL_INVENTORY, PURCHASE_ORDER, SALES_ORDER,
  PRODUCT, MATERIAL, CUSTOMER, SUPPLIER
};

export default {palletStatus, UNPACK, STORE, PRODUCT, MATERIAL};
