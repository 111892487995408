import React, {Component} from "react";
import {connect} from "react-redux";

import {authSelectors, paginationSelectors} from "../../store";
import {locationPageActions, locationOperations} from "../../store/locations";
import LocationDashboard from "../../components/Locations/Dashboard/LocationDashboard";


const mapStateToProps = (state) => {
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    pagination: paginationSelectors.pagination(state.locations.items)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeView: (view) => dispatch(locationPageActions.changeView(view)),
    actions: {
      create: (data) => dispatch(locationOperations.createItem(data)),
      update: (id, data) => dispatch(locationOperations.updateItem(id, data)),
      delete: (id) => dispatch(locationOperations.deleteItem(id)),
      getPage: (page, params) => {
        const finalParams = {
          ...params,
          expand: "pm_orders,stockroom_assets"
        };
        return dispatch(locationPageActions.fetchPage({page, params: finalParams}));
      }
    },
  };
};

class ProductionLinesContainer extends Component {
  render() {
    return (
      <LocationDashboard {...this.props}/>
    );
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionLinesContainer);
