// import salesOrderReducer, {salesOrderCrud} from "./reducers";
/* These exports are the only thing that need to be edited for a new module */
import contactReducer from "./reducers";
import {supplierOperations, supplierPageActions, supplierActions} from "./suppliers";
import {customerOperations, customerPageActions, customerActions, customerAddressActions} from "./customers";

export {
  supplierOperations,
  supplierActions,
  supplierPageActions,
  customerOperations,
  customerPageActions,
  customerActions,
  customerAddressActions
};

export default contactReducer;
