import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import WebSocketInstance from "./WebSocket";
import {authOperations, authSelectors} from "../store";

function mapStateToProps(state) {
  return {
    accessExpired: authSelectors.isAccessTokenExpired(state.auth),
    refreshToken: authSelectors.refreshToken(state.auth),
    requestId: authSelectors.refreshPersonnelUUID(state.auth)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleTokenRefresh: (token) => dispatch(authOperations.refreshAccessToken(token))

  };
}

class VendorOrderListener extends Component {
  state = {
    data: {},
  };

  componentDidMount() {
    this.handleWebsocket();
  }

  handleWebsocket = async () => {
    const {orderId, requestId} = this.props;
    await this.handleTokenRefresh();

    WebSocketInstance.connect("/vendor_orders/");
    this.waitForSocketConnection(() => {
      WebSocketInstance.sendMessage({
        "action": "subscribe_instance",
        "stream": "vendor_orders",
        "request_id": requestId,
        "pk": orderId,
      });
      WebSocketInstance.addCallbacks(this.handleUpdate.bind(this), this.addMessage.bind(this));
    });
  };

  handleTokenRefresh = async () => {
    const {accessExpired, refreshToken, handleTokenRefresh} = this.props;
    if (accessExpired) {
      await handleTokenRefresh(refreshToken);
    }
  }

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(
      function() {
        // Check if websocket state is OPEN
        if (WebSocketInstance.state() === 1) {
          console.info("Connection is made");
          callback();
          return;
        } else {
          console.info("wait for connection...");
          component.waitForSocketConnection(callback);
        }
      }, 100); // wait 100 milisecond for the connection...
  }

  handleUpdate(data) {
    console.log("update data", data);
    this.setState({data});
  }

  addMessage(message) {
    console.log("message", message);
    this.setState({messages: [...this.state.messages, message]});
  }


  render() {
    return (
      <div className={"vendor-order-listener"}/>
    );
  }
}

VendorOrderListener.propTypes = {
  orderId: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorOrderListener);
