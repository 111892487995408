import actionTyper from "redux-actiontyper";

const {
  ADD_ORDER_ITEM_REQUEST,
  ADD_ORDER_ITEM_SUCCESS,
  ADD_ORDER_ITEM_FAILURE,

  UPDATE_ORDER_ITEM_REQUEST,
  UPDATE_ORDER_ITEM_SUCCESS,
  UPDATE_ORDER_ITEM_FAILURE,

  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILURE,

  PACK_SALES_ORDER_REQUEST,
  PACK_SALES_ORDER_SUCCESS,
  PACK_SALES_ORDER_FAILURE,
} = actionTyper("@@sales_orders/");


export default {
  ADD_ORDER_ITEM_REQUEST,
  ADD_ORDER_ITEM_SUCCESS,
  ADD_ORDER_ITEM_FAILURE,

  UPDATE_ORDER_ITEM_REQUEST,
  UPDATE_ORDER_ITEM_SUCCESS,
  UPDATE_ORDER_ITEM_FAILURE,

  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILURE,

  PACK_SALES_ORDER_REQUEST,
  PACK_SALES_ORDER_SUCCESS,
  PACK_SALES_ORDER_FAILURE,
};
