import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {roleList} from "../Forms/PersonnelForm/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Collapse, Box} from "Base";

export default function IssueComment({id, issue_id, created_at, updated_at, author, description, personnel, service_org, submit, isOpen}) {
  const [commentContent, setCommentContent] = useState(description);
  const [isNoteChanged, setNoteChanged] = useState(false);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    if (commentContent !== description && !isNoteChanged) {
      setNoteChanged(true);
    }
  }, [commentContent, description, isNoteChanged]);

  const handleChange = (e) => {
    setCommentContent(e.target.value);
  };

  const handleEdit = () => {
    if (isEdit) {
      setEdit(false);
      setCommentContent(description);
      setNoteChanged(false);
    } else {
      setEdit(true);
    }
  };

  const handleSubmit = async () => {
    const reportedIssue = {issue: {
      comment: {
        issue_id,
        author_id: personnel.id,
        comment_id: id,
        description: commentContent}
    }};
    const res = await submit(reportedIssue);
    if (res && !res.error) {
      setEdit(false);
      setNoteChanged(false);
    }
  };

  const handleDelete = () => {
    const reportedIssue = {issue: {
      comment: {
        issue_id,
        author_id: personnel.id,
        comment_id: id
      }
    }};
    submit(reportedIssue);
  };

  const created = moment(created_at).format("MMMM Do YYYY, h:mm:ss a");
  const isUserAuthor = personnel.id === author.id;
  const name = author && author.orgs && author.orgs[0] === service_org ? "Service Provider" : "Client";

  return (
    <div className="item-details-pane-right-note" style={{marginRight: "10px"}}>
      <div className="issue-bar-comment-info-left">
        {`${author.name}-${roleList[author.user.user_type - 1].label}-${name}   `}
        {isUserAuthor && isOpen &&
        <FontAwesomeIcon
          icon={"edit"}
          id={`edit-issue-comment-${id}`}
          size="1x"
          style={{cursor: "pointer", marginLeft: "5px"}}
          onClick={handleEdit} />}
      </div>
      <div className="issue-bar-comment-info-right">{created}</div>
      <hr />
      {!(isUserAuthor && isEdit) ? <Box className="issue-bar-comment-content">{description}</Box> : <br/>}
      <Collapse mt={4} isOpen={(isUserAuthor && isEdit)}>
        <textarea
          className="issue-bar-comment-input editable-input"
          value={commentContent}
          onChange={handleChange}
          placeholder="Comment"
          id={`issue-note-input-${id}`}
        />
      </Collapse>
      {isOpen &&
        <Box display="flex" alignItems="center" justifyContent="center">
          {isNoteChanged &&
                <button className="issue-bar-btn" onClick={handleSubmit}>
                    Save
                </button>}
          {isUserAuthor && isEdit &&
                <button className="issue-bar-btn" onClick={handleEdit}>
                    Cancel
                </button>}
          {isEdit && isUserAuthor && !isNoteChanged &&
                <button className="issue-bar-btn" onClick={handleDelete}>
                    Delete
                </button>
          }
        </Box>
      }
    </div>
  );
}

IssueComment.propTypes = {
  id: PropTypes.number,
  issue_id: PropTypes.number,
  submit: PropTypes.func,
  updated_at: PropTypes.string,
  created_at: PropTypes.string,
  author: PropTypes.object,
  description: PropTypes.string,
  personnel: PropTypes.object,
  service_org: PropTypes.number,
  isOpen: PropTypes.bool,
};

IssueComment.defaultProps = {
  author: {},
  personnel: {},
};
