import React, {useEffect, useState} from "react";
import {OrderAdvancement, OrderProgressBar, ProgressLabels} from "../../components/Common";
import IssueContainer from "./IssueContainer";
import PropTypes from "prop-types";
import {isEmpty} from "lodash-es";
import {Collapse} from "../../components/Common/Base";

export default function OrderProgressContainer(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const checkActiveIssue = () => {
    let isActive = false;
    const {issues} = props;
    if (!isEmpty(issues)) {
      for (let i = 0; i < issues.length; i++) {
        if (issues[i].is_active) {
          isActive = true;
          break;
        }
      }
    }
    return isActive;
  };

  const isWindowLarge = windowWidth > 550;
  const {is_on_site, issues} = props;
  const isSiteSelected = is_on_site !== null && typeof is_on_site !== "object";
  const isActiveIssue = checkActiveIssue();

  return (
    <Collapse mt={4} isOpen={isSiteSelected}>
      <OrderProgressBar {...props} isActiveIssue={isActiveIssue} />
      <ProgressLabels {...props} isWindowLarge={isWindowLarge} />
      <OrderAdvancement {...props} isActiveIssue={isActiveIssue} windowWidth={windowWidth}/>
      {!isEmpty(issues) && <IssueContainer issues={issues} isWindowLarge={isWindowLarge} isActiveIssue={isActiveIssue} {...props} />}
      <hr />
    </Collapse>
  );
}

OrderProgressContainer.propTypes = {
  is_on_site: PropTypes.bool,
  issues: PropTypes.array,
};

OrderProgressContainer.defaultProps = {
  is_on_site: null,
  issues: []
};
