import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {Box, Heading, Image} from "../Base";
import PlaceholderImg from "../../../static/img/placeholder.jpg";

const DetailsPaneLeft = styled(Box)``;

DetailsPaneLeft.defaultProps = {
  width: ["100%", "30%"],
};


const StyledDetailsPaneRight = styled(Box)``;

StyledDetailsPaneRight.defaultProps = {
  width: ["100%", "70%"],
};


const DetailsPaneRight = ({children}) => {
  return (
    <StyledDetailsPaneRight>
      {children}
    </StyledDetailsPaneRight>
  );
};

DetailsPaneRight.defaultProps = {
  loading: false,
};


const DetailsHeader = styled(Heading)`
  color: ${props => props.theme.colors.colorPrimary};
  text-transform: uppercase;
  font-size: 1.3rem;
`;

DetailsPaneLeft.defaultProps = {};

/**
 * An image that defaults to the PlaceholderImg if not provided
 * @param {object} props The props of the iamge
 * @returns {ImageWithDefault} An image
 */
const ImageWithDefault = props => {
  const {src, ...rest} = props;
  return <Image
    className="item-details-pane-left-img"
    src={src || PlaceholderImg}
    alt="Placeholder logo"
    {...rest}
  />;
};

ImageWithDefault.propTypes = {
  /**
   * Source of the image
   */
  src: PropTypes.string,
};


export {DetailsPaneLeft, DetailsPaneRight, DetailsHeader, ImageWithDefault};
