import {Link} from "../../../Common/Base";
import moment from "moment-timezone";
import React from "react";
import {renderNameOrSku} from "../../utils/inventory";
import {MATERIAL_DASHBOARD_ROUTE} from "../../../../routes/constants";

export const MATERIAL = "Material";
export const PRODUCT = "Product";

/**
 * Combines materials and products from a pallet into an array for tables
 * @param {object} record - pallet record from a table with materials and products
 * @returns {[]} - combined array of products and materials
 */
export const combineMaterialProductList = record => {
  const materialData = record.material_inventory.map((quantityItem, i) => {
    const childKey = `${record.id}-${quantityItem.id}-m`;
    const {material, ...rest} = quantityItem;

    return {
      key: childKey,
      item: material,
      type: "Material",
      ...rest,
    };
  });

  const productData = record.product_inventory.map((quantityItem, i) => {
    const childKey = `${record.id}-${quantityItem.id}-p`;
    const {product, ...rest} = quantityItem;

    return {
      key: childKey,
      item: product,
      type: "Product",
      ...rest,
    };
  });

  return [...materialData, ...productData];
};

export const renderPalletFacility = data => {
  let result = "None";
  if (data.location && data.location.facility) {
    result = data.location.facility.name;
  }
  return result;
};

export const renderPalletDestination = data => {
  return data?.destination?.name ?? "None";
};

export const orderPalletColumns = [
  {
    title: "SKU",
    dataIndex: "sku",
    render: (text, record) => (
      <Link to={`/dashboard/warehouse/pallets/${record.id}`}>{text ? text : record.id}</Link>
    ),
    width: "10%",
    editable: true,
  },
  {
    title: "Destination",
    dataIndex: "UUID",
    render: (_, record) => <Link to={`/dashboard/warehouse/pallets/${record.id}`}>{renderPalletFacility(record)}</Link>,
    width: "14%",
    // fixed: 'left',
    // editable: false,
  },
  {
    title: "Origin",
    dataIndex: ["origin", "label"],
    width: "25%",
    // fixed: 'left',
    // editable: false,
  },

  {
    title: "Expected",
    dataIndex: "expected_date",
    render: (text, record) => (text ? moment(text).format("YYYY-MM-DD") : "None"),
    width: "10%",
    editable: true,
  },
  {
    title: "Status",
    dataIndex: "status_display",
    width: "11%",
    editable: true,
  },
  {
    title: "# Products",
    // dataIndex: "product_inventory",
    // key: "price",
    width: "15%",
    editable: false,
    render: (text, record) => `${record.product_inventory.length}`,
  },
  {
    title: "# Materials",
    // dataIndex: "material_inventory",
    // key: "total",
    width: "15%",
    editable: false,
    render: (text, record) => `${record.material_inventory.length}`,
  },
];

export const salesOrderPalletColumns = [
  {
    title: "SKU",
    dataIndex: "sku",
    render: (text, record) => (
      <Link to={`/dashboard/warehouse/pallets/${record.id}`}>{text ? text : record.id}</Link>
    ),
    width: "20%",
    editable: true,
  },
  {
    title: "Destination",
    dataIndex: ["destination", "name"],
    render: (_, record) => <Link to={`/dashboard/warehouse/pallets/${record.id}`}>{_}</Link>,
    width: "22%",
    // fixed: 'left',
    // editable: false,
  },
  {
    title: "Expected Date",
    dataIndex: "expected_date",
    render: (text, record) => (text ? moment(text).format("YYYY-MM-DD") : "None"),
    width: "15%",
    editable: true,
  },
  {
    title: "Status",
    dataIndex: "status_display",
    width: "13%",
    editable: true,
  },
  {
    title: "# Products",
    // dataIndex: "product_inventory",
    // key: "price",
    width: "15%",
    editable: false,
    render: (text, record) => `${record.product_inventory.length}`,
  },
  {
    title: "# Materials",
    // dataIndex: "material_inventory",
    // key: "total",
    width: "15%",
    editable: false,
    render: (text, record) => `${record.material_inventory.length}`,
  },
];

/**
 * Returns a link to material or product for the table
 * @param {{item: {name: string, sku: string}}} record - item is inventory
 * @returns {*} - link to product or material
 */
export const renderItemLink = record => {
  const {item, type} = record;
  // console.log("item link", item);
  const name = renderNameOrSku(item);
  if (type === MATERIAL) {
    return <Link to={`${MATERIAL_DASHBOARD_ROUTE}/${item.id}`}>{name}</Link>;
  }
  if (type === PRODUCT) {
    return <Link to={`/dashboard/warehouse/inventory/${item.id}`}>{name}</Link>;
  }
};

export const orderPalletItemColumns = [
  {
    title: "Name",
    width: "35%",
    dataIndex: "UUID",
    render: (text, record) => renderItemLink(record),
  },
  {
    title: "Type",
    width: "10%",
    dataIndex: "type",
  },
  {
    title: "SKU",
    dataIndex: "item",
    render: (text, record) => record.item.sku,
    key: "sku",
    width: "35%",
  },
  {
    title: "Quantities",
    dataIndex: "quantity",
    key: "quantity",
    width: "25%",
  },
];
