import {showSnack} from "react-redux-snackbar";

/**
 * Creates an error snack
 * @param {string} label The message to display
 * @param {number} timeout How long to show the snack for
 * @param {string} timestamp A unique ID for the snack dispatch to use
 * @param {boolean} isErrorSnack If this is an error snack
 * @param {Function} dispatch The redux dispatcher
 */
const createSnack = (label, timeout, timestamp, isErrorSnack, dispatch) => {
  let snackData = {
    title: label,
    duration: timeout
  };
  if (isErrorSnack) {
    snackData["status"] = "error";
    snackData["description"] = "Please try again later";
  }
  dispatch(showSnack(timestamp, snackData));
};

/**
 * Creates an error snack
 * @param {string} label The message to display
 * @param {string} timestamp A unique ID for the snack dispatch to use
 * @param {Function} dispatch The redux dispatcher
 */
const createErrorSnack = (label, timestamp, dispatch) => {
  createSnack(label, 5000, timestamp, true, dispatch);
};

/**
 * Creates a success snack
 * @param {string} label The message to display
 * @param {string} timestamp A unique ID for the snack dispatch to use
 * @param {Function} dispatch The redux dispatcher
 */
const createSuccessSnack = (label, timestamp, dispatch) => {
  createSnack(label, 2000, timestamp, false, dispatch);
};

/**
 * Sets the snack message for a container, and resets after a timeout
 * @param {Function} setMessage The state of the container (this)
 * @param {string} snackMsg The message to show in the snack message
 */
const setSnackMessage = (setMessage, snackMsg) => {
  // obj.setState({snackMsg: snackMsg});
  setMessage(snackMsg);
  setTimeout(() => setMessage(""), 500);
};

export default {
  createSnack,
  createErrorSnack,
  createSuccessSnack
};

export {
  setSnackMessage
};
