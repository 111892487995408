import React, {Fragment} from "react";
import {Field} from "react-final-form";

const Error = ({name, errors = {}, color = "white"}) => {
  return errors ? (
    <Field name={name} subscription={{error: true, touched: true, submitError: true}}>
      {({meta: {touched, error, submitError}}) =>
        (submitError || error) && touched ? (
          <span className={"error-message"} style={{color: color}}>{error || submitError}</span>
        ) :
          <Fragment>
            {errors[name] && <span className={"error-message"} style={{color: color}}>{name} - {errors[name]}</span>}
          </Fragment>
      }
    </Field>
  ) : null;
};

export default Error;
