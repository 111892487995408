import React from "react";
import PropTypes from "prop-types";
import {AntdModal, CloseIcon, Heading} from "../Base";
// import {Button} from "../Base/Button/Button";
import {Button} from "antd";

const defaultModalConfig = {
  wrapClassName: "ant-modal-custom modal",
  centered: true,
  zIndex: 1000
};

const Modal = ({
  title,
  onCancel,
  showCancelButton,
  onOk,
  showConfirmButton,
  disableConfirmButton,
  confirmText,
  confirmLoading,
  visible,
  isDeleteModal,
  footer,
  children,
  modalConfig,
  extraButtons
}) => {
  let config = {...defaultModalConfig, ...modalConfig};

  let footerButtons = footer;
  if (footer === undefined) {
    footerButtons = [];
    if (onCancel && showCancelButton) {
      footerButtons.push(
        <Button
          key={"cancel"}
          className={"cancel-button"}
          onClick={onCancel}
        >
          Cancel
        </Button>
      );
    }
    for (let button of extraButtons) {
      footerButtons.push(
        <Button
          key={button.text}
          onClick={button.action}
          {...button.config}
        >
          {button.text}
        </Button>);
    }
    if (onOk && showConfirmButton) {
      footerButtons.push(
        <Button
          key={"submit"}
          className={"confirm-button"}
          onClick={onOk}
          disabled={disableConfirmButton}
          type={"primary"}
          danger={isDeleteModal}
          loading={confirmLoading}>
          {isDeleteModal ? "Delete" : confirmText}
        </Button>
      );
    }
    // Set to null so that a footer isn't rendered
    if (!footerButtons.length) {
      footerButtons = null;
    }
  }

  return (
    <AntdModal
      visible={visible}
      onCancel={onCancel}
      title={<Heading size="2xl" textAlign="center" mb={0} mt={"5px"}>{title}</Heading>}
      closeIcon={CloseIcon()}
      footer={footerButtons}
      {...config}>
      {children}
    </AntdModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
  onOk: PropTypes.func,
  showConfirmButton: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmLoading: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  isDeleteModal: PropTypes.bool,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  modalConfig: PropTypes.object,
  /**
   * Extra buttons to show on the creation modal
   */
  extraButtons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    config: PropTypes.object,
  })),
};

Modal.defaultProps = {
  title: "Header",
  showCancelButton: true,
  showConfirmButton: true,
  disableConfirmButton: false,
  confirmText: "Save",
  confirmLoading: false,
  isDeleteModal: false,
  modalConfig: defaultModalConfig,
  extraButtons: []
};

export default Modal;
