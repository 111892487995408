/**
 * @file A base set of redux actions that can be used across other stores or multiple object types
 */

import {RSAA} from "redux-api-middleware";

/**
 * Performs a basic RSAA request
 * @param {object} params The request parameters
 * @param {string} params.endpoint What URL to use
 * @param {object} [params.headers] The parameter headers to use
 * @param {string} params.method What HTTP method to use
 * @param {object} [params.body] The request data
 * @param {Array} params.types The list of Redux types that goes with this request
 * @returns {object} The RSAA object
 */
const generalRsaaJson = ({endpoint, headers, method, body, types}) => {
  if (headers === undefined) {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
  }
  return ({
    [RSAA]: {
      endpoint,
      method,
      body,
      headers,
      types
    }
  });
};

/**
 * An HTTP GET request using RSAA
 * @param {object} params The request parameters
 * @param {string} params.endpoint What URL to use
 * @param {object} [params.headers] The parameter headers to use
 * @param {object} [params.body] The request data
 * @param {Array} params.types The list of Redux types that goes with this request
 * @returns {object} The RSAA object
 */
const getRsaa = (params) => generalRsaaJson({...params, method: "GET"});


/**
 * An HTTP POST request using RSAA
 * @param {object} params The request parameters
 * @param {string} params.endpoint What URL to use
 * @param {object} [params.headers] The parameter headers to use
 * @param {object} params.body The request data
 * @param {Array} params.types The list of Redux types that goes with this request
 * @returns {object} The RSAA object
 */
const postRsaa = (params) => generalRsaaJson({...params, method: "POST"});

/**
 * An HTTP PUT request using RSAA
 * @param {object} params The request parameters
 * @param {string} params.endpoint What URL to use
 * @param {object} [params.headers] The parameter headers to use
 * @param {object} params.body The request data
 * @param {Array} params.types The list of Redux types that goes with this request
 * @returns {object} The RSAA object
 */
const putRsaa = (params) => generalRsaaJson({...params, method: "PUT"});

/**
 * An HTTP PATCH request using RSAA
 * @param {object} params The request parameters
 * @param {string} params.endpoint What URL to use
 * @param {object} [params.headers] The parameter headers to use
 * @param {Array} params.types The list of Redux types that goes with this request
 * @returns {object} The RSAA object
 */
const patchRsaa = (params) => generalRsaaJson({...params, method: "PATCH"});

/**
 * An HTTP DELETE request using RSAA
 * @param {object} params The request parameters
 * @param {string} params.endpoint What URL to use
 * @param {object} [params.headers] The parameter headers to use
 * @param {object} params.body The request data
 * @param {Array} params.types The list of Redux types that goes with this request
 * @returns {object} The RSAA object
 */
const deleteRsaa = (params) => generalRsaaJson({...params, method: "DELETE"});

export default {
  getRsaa,
  postRsaa,
  putRsaa,
  patchRsaa,
  deleteRsaa
};
