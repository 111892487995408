export const selectionOptions = [
  {value: "name", label: "Name"},
  {value: "created_at", label: "Created At"},
  {value: "updated_at", label: "Updated At"},
];

/**
 * Filters options for the Production Lines
 */
export const filterOptions = [
  {value: "", label: "All", params: {}},
  {value: 1, label: "Stopped", params: {status: 1}},
  {value: 2, label: "Running", params: {status: 2}},
];
