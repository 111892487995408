import moment from "moment-timezone";


export const dateFormats = {
  date: "YYYY-MM-DD"
};

/**
 * Gets a timestamp
 * @returns {string} The current time
 */
const createTimestamp = () => {
  return Math.floor(Date.now() / 1000).toString();
};

/**
 * Formats the calendar range, to be sent to the backend to get the corresponding events
 * @param {object} range The range to format
 * @returns {{from_date: string, to_date: string}} The formatted range
 */
export const formatCalendarRange = (range) => {
  const dateRange = {
    from_date: moment(range["start"]).format("YYYY-MM-DD"),
    to_date: moment(range["end"]).format("YYYY-MM-DD")
  };
  return dateRange;
};

/**
 * Formats a date before sending it to the backend
 * @param {string} [date] The date to format
 * @returns {string|null} The formatted date
 */
export const formatMomentForSubmit = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  }
  return null;
};

/**
 * Gets the date range of the next month from the current range, to send to the backend
 * @param {string} range The range to add to
 * @returns {{from_date: string, to_date: string}} The formatted range of the next month
 */
export const getNextDateRange = (range) => {
  const dateRange = {
    from_date: moment(range["start"]).add(1, "M").format("YYYY-MM-DD"),
    to_date: moment(range["end"]).add(1, "M").format("YYYY-MM-DD")
  };
  return dateRange;
};

/**
 * Formats the shift date
 * @param {string} date The date to format
 * @returns {string} The formatted date
 */
export const formatShiftDate = (date) => {
  return moment(date).format("dddd MMM Do");
};

/**
 * Formats the shift time
 * @param {string} date The time to format
 * @returns {string} The formatted time
 */
export const formatShiftTime = (date) => {
  return moment(date).format("dddd MMM Do");
};

/**
 * Formats the shift time including the timezone
 * @param {string} date The time to format
 * @returns {string} The formatted time
 */
export const formatShiftTimeWithTimezone = (date) => {
  const tz = moment.tz.guess();
  return moment.tz(date, tz).format(" h:mm:s a - ddd, MMM Do");
};

/**
 * Formats the timestamp including the timezone
 * @param {string} date The time to format
 * @returns {string} The formatted time
 */
export const formatTimestamp = (date) => {
  const tz = moment.tz.guess();
  return moment.tz(date, tz).format("dddd MMM Do - h:mm a");
};

/**
 * Formats "hh:mm:ss" time value in to 12 hour format
 * @param {string} value - time field
 * @returns {string|string} - formatted 12 hour time
 */
const formatTime = (value) => {
  return value ? moment(value, "hh:mm:ss").format("LT") : "";
};

/**
 * Returns two time's and combines them as a time range
 * @param {string} start - time field "8:00:00"
 * @param {string} end - time field "8:00:00"
 * @returns {string|null} - ex: 8:00 AM - 9:00 AM
 */
export const formatStartEndTime = (start, end) => {
  if (start && end) {
    return `${formatTime(start)} - ${formatTime(end)} `;
  }
  return null;
};

export default {
  createTimestamp
};
