import types from "./types";
import {name as endpoint} from "./index";
import base from "../base/actions";
import {
  ASSET_TYPES_ROUTE,
  LOCATIONS_ROUTE,
  PERSONNEL_TITLES_ROUTE, PM_ORDER_MATERIALS_ROUTE,
  SCHEDULE_STATUS_ROUTE,
  STOCK_STATUS_ROUTE,
  VENDOR_TYPES_ROUTE
} from "../../routes/constants";
import {constructRoute} from "../base/utils";

const createLocation = (org_id, data) => base.postRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${LOCATIONS_ROUTE}/${data["level"]}/`),
  body: JSON.stringify(data),
  types: [
    types.CREATE_LOCATION_REQUEST,
    types.CREATE_LOCATION_SUCCESS,
    types.CREATE_LOCATION_FAILURE
  ]
});

const updateLocation = (org_id, data) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${LOCATIONS_ROUTE}/${data["level"]}/${data.id}/`),
  body: JSON.stringify(data),
  types: [
    types.UPDATE_LOCATION_REQUEST,
    types.UPDATE_LOCATION_SUCCESS,
    types.UPDATE_LOCATION_FAILURE
  ]
});

const deleteLocation = (org_id, data) => base.deleteRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${LOCATIONS_ROUTE}/${data["thisLevel"]}/${data["id"]}/`),
  types: [
    types.DELETE_LOCATION_REQUEST,
    types.DELETE_LOCATION_SUCCESS,
    types.DELETE_LOCATION_FAILURE
  ]
});

/**
 * Get the facilities of an org
 * @param {object} params The parameters
 * @param {number} params.org The ID of the org to get facilities for
 * @param {Array} params.typesList The list of Redux types that goes with this request
 * @returns {object} The RSAA object
 */
const getFacilities = ({org, typesList}) => base.getRsaa({
  endpoint: constructRoute(`${endpoint}${org}/${LOCATIONS_ROUTE}/facilities/`),
  types: typesList
});


const createAssetType = (org_id, name, color) => base.postRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${ASSET_TYPES_ROUTE}/`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [types.CREATE_ASSET_TYPE_REQUEST, types.CREATE_ASSET_TYPE_SUCCESS, types.CREATE_ASSET_TYPE_FAILURE]
});

const updateAssetType = (org_id, asset_type_id, name, color) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${ASSET_TYPES_ROUTE}/${asset_type_id}/`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [types.UPDATE_ASSET_TYPE_REQUEST, types.UPDATE_ASSET_TYPE_SUCCESS, types.UPDATE_ASSET_TYPE_FAILURE]
});

const deleteAssetType = (org_id, asset_type_id) => base.deleteRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${ASSET_TYPES_ROUTE}/${asset_type_id}/`),
  types: [types.DELETE_ASSET_TYPE_REQUEST, types.DELETE_ASSET_TYPE_SUCCESS, types.DELETE_ASSET_TYPE_FAILURE]
});

const createPersonnelTitle = (org_id, name) => base.postRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${PERSONNEL_TITLES_ROUTE}/`),
  body: JSON.stringify({
    name: name,
    id: ""
  }),
  types: [
    types.CREATE_PERSONNEL_TITLE_REQUEST,
    types.CREATE_PERSONNEL_TITLE_SUCCESS,
    types.CREATE_PERSONNEL_TITLE_FAILURE
  ]
});

const updatePersonnelTitle = (org_id, personnel_title_id, name) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${PERSONNEL_TITLES_ROUTE}/${personnel_title_id}/`),
  body: JSON.stringify({
    name: name
  }),
  types: [
    types.UPDATE_PERSONNEL_TITLE_REQUEST,
    types.UPDATE_PERSONNEL_TITLE_SUCCESS,
    types.UPDATE_PERSONNEL_TITLE_FAILURE
  ]
});

const deletePersonnelTitle = (org_id, personnel_title_id) => base.deleteRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${PERSONNEL_TITLES_ROUTE}/${personnel_title_id}/`),
  types: [
    types.DELETE_PERSONNEL_TITLE_REQUEST,
    types.DELETE_PERSONNEL_TITLE_SUCCESS,
    types.DELETE_PERSONNEL_TITLE_FAILURE
  ]
});

const createVendorType = (org_id, name, color) => base.postRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${VENDOR_TYPES_ROUTE}/`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [types.CREATE_VENDOR_TYPE_REQUEST, types.CREATE_VENDOR_TYPE_SUCCESS, types.CREATE_VENDOR_TYPE_FAILURE]
});

const updateVendorType = (org_id, vendor_type_id, name, color) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${VENDOR_TYPES_ROUTE}/${vendor_type_id}/`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [types.UPDATE_VENDOR_TYPE_REQUEST, types.UPDATE_VENDOR_TYPE_SUCCESS, types.UPDATE_VENDOR_TYPE_FAILURE]
});

const deleteVendorType = (org_id, vendor_type_id) => base.deleteRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${VENDOR_TYPES_ROUTE}/${vendor_type_id}/`),
  types: [types.DELETE_VENDOR_TYPE_REQUEST, types.DELETE_VENDOR_TYPE_SUCCESS, types.DELETE_VENDOR_TYPE_FAILURE]
});

const createStockStatus = (org_id, name, color) => base.postRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${STOCK_STATUS_ROUTE}`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [types.CREATE_STOCK_STATUS_REQUEST, types.CREATE_STOCK_STATUS_SUCCESS, types.CREATE_STOCK_STATUS_FAILURE]
});

const updateStockStatus = (org_id, stock_status_id, name, color) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${STOCK_STATUS_ROUTE}/${stock_status_id}/`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [types.UPDATE_STOCK_STATUS_REQUEST, types.UPDATE_STOCK_STATUS_SUCCESS, types.UPDATE_STOCK_STATUS_FAILURE]
});

const deleteStockStatus = (org_id, stock_status_id) => base.deleteRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${STOCK_STATUS_ROUTE}/${stock_status_id}/`),
  types: [types.DELETE_STOCK_STATUS_REQUEST, types.DELETE_STOCK_STATUS_SUCCESS, types.DELETE_STOCK_STATUS_FAILURE]
});

const createOrderMaterial = (org_id, data) => base.postRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${PM_ORDER_MATERIALS_ROUTE}/`),
  body: JSON.stringify(data),
  types: [
    types.CREATE_ORDER_MATERIAL_REQUEST,
    types.CREATE_ORDER_MATERIAL_SUCCESS,
    types.CREATE_ORDER_MATERIAL_FAILURE
  ]
});

const updateOrderMaterial = (org_id, data) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${PM_ORDER_MATERIALS_ROUTE}/${data.id}/`),
  body: JSON.stringify(data),
  types: [
    types.UPDATE_ORDER_MATERIAL_REQUEST,
    types.UPDATE_ORDER_MATERIAL_SUCCESS,
    types.UPDATE_ORDER_MATERIAL_FAILURE
  ]
});
const deleteOrderMaterial = (org_id, material_id) => base.deleteRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${PM_ORDER_MATERIALS_ROUTE}/${material_id}/`),
  types: [
    types.DELETE_ORDER_MATERIAL_REQUEST,
    types.DELETE_ORDER_MATERIAL_SUCCESS,
    types.DELETE_ORDER_MATERIAL_FAILURE
  ]
});

const createScheduleStatus = (org_id, name, color) => base.postRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${SCHEDULE_STATUS_ROUTE}/`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [
    types.CREATE_SCHEDULE_STATUS_REQUEST,
    types.CREATE_SCHEDULE_STATUS_SUCCESS,
    types.CREATE_SCHEDULE_STATUS_FAILURE
  ]
});

const updateScheduleStatus = (org_id, schedule_status_id, name, color) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${SCHEDULE_STATUS_ROUTE}/${schedule_status_id}/`),
  body: JSON.stringify({
    name: name,
    color: color
  }),
  types: [
    types.UPDATE_SCHEDULE_STATUS_REQUEST,
    types.UPDATE_SCHEDULE_STATUS_SUCCESS,
    types.UPDATE_SCHEDULE_STATUS_FAILURE
  ]
});

const deleteScheduleStatus = (org_id, schedule_status_id) => base.deleteRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/${SCHEDULE_STATUS_ROUTE}/${schedule_status_id}/`),
  types: [
    types.DELETE_SCHEDULE_STATUS_REQUEST,
    types.DELETE_SCHEDULE_STATUS_SUCCESS,
    types.DELETE_SCHEDULE_STATUS_FAILURE
  ]
});

const updateServiceProviderInfo = (org_id, serviceProviderData) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${org_id}/`),
  body: JSON.stringify({
    service_provider_data: serviceProviderData
  }),
  types: [
    types.UPDATE_SERVICE_PROVIDER_REQUEST,
    types.UPDATE_SERVICE_PROVIDER_SUCCESS,
    types.UPDATE_SERVICE_PROVIDER_FAILURE
  ]
});

const changeSummaryTimescale = (timescale, value) => ({
  type: types.CHANGE_SUMMARY_TIMESCALE,
  payload: {timescale, value}
});

/* Get item actions. */

const getItemRequest = () => ({
  type: types.GET_ITEM_REQUEST
});

const getItemSuccess = item => ({
  type: types.GET_ITEM_SUCCESS,
  payload: item
});

const getItemFailure = error => ({
  type: types.GET_ITEM_FAILURE,
  payload: error
});

const getLogsSuccess = item => ({
  type: types.GET_LOGS_SUCCESS,
  payload: item
});

const getLogsFailure = error => ({
  type: types.GET_LOGS_FAILURE,
  payload: error
});

const receivedLog = (data) => ({
  type: types.RECEIVED_LOG,
  payload: data
});

export default {
  createLocation,
  updateLocation,
  deleteLocation,

  createAssetType,
  updateAssetType,
  deleteAssetType,

  createPersonnelTitle,
  updatePersonnelTitle,
  deletePersonnelTitle,

  createVendorType,
  updateVendorType,
  deleteVendorType,

  createStockStatus,
  updateStockStatus,
  deleteStockStatus,

  createOrderMaterial,
  updateOrderMaterial,
  deleteOrderMaterial,

  createScheduleStatus,
  updateScheduleStatus,
  deleteScheduleStatus,

  getItemRequest,
  getItemSuccess,
  getItemFailure,
  getLogsSuccess,
  getLogsFailure,
  receivedLog,

  getFacilities,

  updateServiceProviderInfo,
  changeSummaryTimescale,
};
