import React from "react";
import PropTypes from "prop-types";
import {AddInventoryQuantityForm} from "../../Forms/PalletForm";
import {List} from "antd";
import {CloseIcon} from "../../../Common/Base/Icons";
import {palletItemSku} from "../../utils";
import {renderLocationInventoryTitle} from "../../utils/inventory";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY} from "../../utils/constants";

const SelectingInventoryListItem = (props) => {
  const {
    item,
    fromOrder,
    onEdit,
    onRemove,
    maxField,
    type,
    formatEditValues
  } = props;

  const handleEditQuantity = (values) => {
    if (!fromOrder && values.quantity <= item[maxField]) {
      onEdit(item.id, values.quantity);
    } else {
      const data = formatEditValues(item, {values, type});
      onEdit(data);
    }
  };

  let initialValues = item;
  if (fromOrder) {
    const values = item[type];
    initialValues.id = values.id;
  }


  return (
    <List.Item
      actions={[
        <AddInventoryQuantityForm
          key="add-quantity"
          max={item[maxField]}
          onSubmit={handleEditQuantity}
          fromOrder={fromOrder}
          initialValues={initialValues}
        />,
        !fromOrder && <CloseIcon key={"remove"} onClick={() => onRemove(item.id)}/>,
      ]}
    >
      <List.Item.Meta
        title={renderLocationInventoryTitle(item, type)}
        description={palletItemSku(item, type)}
      />
    </List.Item>
  );
};

SelectingInventoryListItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  fromOrder: PropTypes.bool,
  maxField: PropTypes.string,
  type: PropTypes.oneOf([PRODUCT_INVENTORY, MATERIAL_INVENTORY]),
  formatEditValues: PropTypes.func
};

SelectingInventoryListItem.defaultProps = {
  fromOrder: false,
  maxField: "available_quantity",
  type: PRODUCT_INVENTORY,
  formatEditValues: (item, values) => ({id: item.id, quantity: values.quantity})
};
export default SelectingInventoryListItem;
