import React from "react";

import {Link} from "../../../../Common/Base";
import {PM_ORDERS_DASHBOARD_ROUTE} from "../../../../../routes/constants";


export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Link to={`${PM_ORDERS_DASHBOARD_ROUTE}/${record.id}`}>
          {record?.name || "No Name"}
        </Link>
      );
    },
  },
  // {
  //   title: "In Use",
  //   dataIndex: "in_use",
  //   key: "in_use",
  //   width: "12%",
  // },
];
