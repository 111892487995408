import actions from "./actions";
import {name as endpoint} from "./index";
import {baseOperations} from "../base";
import {dateUtils, snackUtils} from "../../utils";
import {getAxios} from "../../utils/authWithAxios";

const getAllItems = () => async dispatch => {
  try {
    const res = await getAxios({
      url: `${global.base_url}${endpoint}`
    });
    dispatch(actions.getAllSuccess(res.data));
  } catch (error) {
    dispatch(actions.getAllFailure(error));
  }
};

// This requires development as it was taken from the Stockroom Assets Operations file.
// Currently waiting for endpoint to be developed before moving forward.
const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Scheduled PM Order", 3000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Scheduled PM Order", res.payload.name, dispatch, () => {
  });
};

export default {
  getAllItems,
  updateItem
};
