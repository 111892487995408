import React, {useState} from "react";
import PropTypes from "prop-types";
import {remove} from "lodash-es";
import {Column, Heading, Row, ToggleButton} from "../../Base";
import voca from "voca";

/**
 * Use for additional sections on a Details page for a Many to Many model field.
 * Has helper functions for adding and removing from parent model by submitting
 * List of ID's
 * @param {object} props The object properties
 * @returns {LinkedFieldSection} The linked field section
 */
const LinkedFieldSection = props => {
  const {name, formField, title, data, List, Form, onSubmit, formProps} = props;
  const [showForm, setShowForm] = useState(false);

  const fieldData = data?.[name] ?? [];

  const selectedIds = fieldData.map(item => item.id);

  const toggleForm = () => setShowForm(!showForm);

  const handleSubmit = async (value) => {
    console.log("linked field submit value", value);
    const ids = [
      value[formField],
      ...selectedIds
    ];
    const values = {
      [name]: ids
    };
    const res = await onSubmit(data.id, values);
    toggleForm();
    return res;
  };

  const handleDelete = async (id) => {
    let selected = selectedIds;
    remove(selected, (el) => el === id);
    // if (selected.length < selectedIds.length) {
    const values = {
      [name]: selected
    };
    onSubmit(data.id, values);
    // }
  };

  const renderForm = () => {
    return showForm ?
      <Form
        onSubmit={handleSubmit}
        currentSelected={selectedIds}
        {...formProps}
      />
      : null;
  };

  const baseClassName = voca.snakeCase(title);

  return (
    <Column p={1}>

      <Row p={2}>
        <Heading mr={2}>{title}s</Heading>
        <ToggleButton
          toggled={showForm}
          onClick={toggleForm}
          size={"small"}
          className={`add-${baseClassName}-button`}
        >
         Add {title}
        </ToggleButton>
      </Row>


      {renderForm()}

      <List data={fieldData} onDelete={handleDelete}/>

    </Column>
  );
};

LinkedFieldSection.propTypes = {
  /**
   * Many to Many field name on parent model (data)
   * Values will be submitted as an array of ID's
   */
  name: PropTypes.string.isRequired,
  /**
   * Name of form field to get id from to add to array.
   */
  formField: PropTypes.string.isRequired,
  /**
   * Title of section
   */
  title: PropTypes.string.isRequired,
  /**
   * data of parent object
   */
  data: PropTypes.array.isRequired,
  /**
   * update function for parent object
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * React final form with a Dropdown
   */
  Form: PropTypes.any.isRequired,
  /**
   * List component to render added items
   */
  List: PropTypes.any.isRequired,
  /**
   * Extra props and lists needed for form dropdown options
   */
  formProps: PropTypes.object
};

LinkedFieldSection.defaultProps = {
  formProps: {}
};

export default LinkedFieldSection;
