import moment from "moment";
import voca from "voca";


export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    editable: true,
    render: (text, record) => record[record.level].name || "None",
  },
  {
    title: "Level",
    dataIndex: "level",
    editable: false,
    render: (text, record) => voca.capitalize(record.level[0]) + record.level.slice(1) || "None",
  },
  {
    title: "Parent Location",
    dataIndex: "parent",
    editable: false,
    render: (text, record) => (record.parent_level && record[record.parent_level].name) || "None",
  },
  {
    title: "Created",
    dataIndex: "created_at",
    width: "15%",
    editable: false,
    render: (text, record) => moment(record.created_at).format("MMMM Do YYYY"),
  },
  {
    title: "Last Updated",
    dataIndex: "updated_at",
    width: "15%",
    editable: false,
    render: (text, record) => moment(record.updated_at).format("MMMM Do YYYY"),
  }
];
