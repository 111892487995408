import React, {Component} from "react";
import PropTypes from "prop-types";
import {BusinessInfo, BusinessSize, ContactInfo, DiversityInfo, InsuranceBondingInfo, OwnershipInfo, ServiceInfo} from "../../components/Common";
import {AntdCollapse, Heading, Panel} from "Base";
import {checkNestedKeys} from "../../utils/propUtils";
import {deleteUnwantedProps} from "../../components/Common/Forms/Base/utils";
import voca from "voca";


class ServiceProviderInfo extends Component {
  componentDidMount() {
    const orgID = checkNestedKeys(["account", "personnel", "default_org", "id"], this.props);
    console.log("org id", orgID);
    if (orgID) {
      this.props.getOrg(orgID);
    }
  }

  // Handle the submition of the forms
  handleSubmit = (formData) => {
    if (!formData.business_info) {
      formData = {business_info: formData};
    }
    const formTitle = Object.keys(formData)[0];
    let serviceProviderInfo = formData[formTitle];
    deleteUnwantedProps(serviceProviderInfo);
    const orgID = checkNestedKeys(["account", "personnel", "default_org", "id"], this.props);
    if (orgID) {
      this.props.updateServiceProviderInfo(orgID, serviceProviderInfo);
    }
  };

  render() {
    const {service_provider_info} = this.props.org;
    const uuid = checkNestedKeys(["account", "personnel", "UUID"], this.props);
    const commonFormProps = {
      ...service_provider_info,
      userUUID: uuid,
      handleSubmit: this.handleSubmit
    };

    const AccordionPanel = (title, component) => {
      return <Panel header={<Heading mb={0}>{title}</Heading>} key={voca.slugify(title)} className={"settings-view-center"}>
        {React.createElement(component, commonFormProps)}
      </Panel>;
    };

    return (
      <AntdCollapse>
        {AccordionPanel("Business Information", BusinessInfo)}
        {AccordionPanel("Contact Information", ContactInfo)}
        {AccordionPanel("Business Size", BusinessSize)}
        {AccordionPanel("Ownership", OwnershipInfo)}
        {AccordionPanel("Insurance and Bonding", InsuranceBondingInfo)}
        {AccordionPanel("Diversity Information", DiversityInfo)}
        {AccordionPanel("Service Information", ServiceInfo)}
      </AntdCollapse>
    );
  }
}

ServiceProviderInfo.propTypes = {
  updateServiceProviderInfo: PropTypes.func,
  org: PropTypes.object,
  getOrg: PropTypes.func
};

export default ServiceProviderInfo;
