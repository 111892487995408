import React, {Component} from "react";
import {AddItemModal, ButtonBar, HelpButton, ToolBar, TutorialModal} from "../../../Common";
import {LIST, TILE, TABLE} from "store/base/crud/utils";
import {Redirect} from "react-router-dom";
import {Box} from "Base";
import OrderList from "./OrderList";
import {PaginationControl} from "../../../Common/Paginator";
import BannerOne from "../../../../static/img/BannerOne.png";
import {salesOrderHelp} from "../../../Common/HelpItems/constants";
import {scrollToTop} from "../../../../utils/containerUtils";

class SalesOrders extends Component {
  state = {
    addedID: "",
    showAddItemModal: false,
    addItemModalSubmitting: false,
    addItemModalError: false,
    showTutorialModal: true,
    showImportInventoryModal: false,
    showTutFromHelp: false,
    errorLoadingMessage: "",
  };

  /* Upon mounting, fetch a list of products and store the array in redux. They
    will be rendered using the map method. */
  componentDidMount = () => {
    this.handleRefreshPage();
    scrollToTop();
  };


  handleRefreshPage = () => {
    const {getPage, pagination} = this.props;
    const {itemsCurrentPage} = pagination;
    if (itemsCurrentPage) {
      getPage(itemsCurrentPage);
    } else {
      getPage(1);
    }// sortProduct("created_at");
  };

  /* This function will open the add product modal upon clicking the plus sign
    located within the product item bar. */
  handleOpenAddItemModal = () => {
    this.setState({
      showAddItemModal: true,
    });
  };

  /* This function will open the add product modal upon clicking the plus sign
    located within the product item bar. */
  handleCloseAddItemModal = () => {
    this.setState({
      showAddItemModal: false,
    });
  };

  // Handle the tutorial modal
  handleTutorialModal = () => {
    this.setState({
      showTutorialModal: !this.state.showTutorialModal,
    });
  };

  handleTutFromButton = () => {
    this.setState({
      showTutFromHelp: !this.state.showTutFromHelp,
    });
  };

  handleCloseTutorialModal = () => {
    this.setState({
      showTutorialModal: false,
      showTutFromHelp: false,
    });
  };

  handleSubmitAddItem = async () => {
    const {accountInfo, createItem} = this.props;

    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false,
    });

    const dataToSubmit = {
      org: accountInfo.personnel ? accountInfo.personnel.orgs[0].id : null,
    };

    const res = await createItem(dataToSubmit);

    if (res.error) {
      this.setState({
        addItemModalSubmitting: false,
        addItemModalError: true,
      });
    } else {
      this.setState({
        addedID: res.payload.id,
        addItemModalSubmitting: false,
      });
    }
  };


  render() {
    const {
      addItemModalSubmitting,
      addItemModalError,
      showAddItemModal,
      addedID,
      showTutorialModal,
      showTutFromHelp,
    } = this.state;
    const {
      getPage,
      loading,
      changeView,
      // accountInfo,
      pagination,
      // locationList,
    } = this.props;

    const {
      pageSize,
      pageView,
      itemsPaged,
      itemsCurrentPage,
      itemsCount,
    } = pagination;

    if (addedID) {
      return <Redirect to={`/dashboard/warehouse/sales_orders/${addedID}`}/>;
    }

    const listView = pageView === LIST;
    const tileView = pageView === TILE;
    const tableView = pageView === TABLE;
    return (
      <div>
        <ToolBar
          changeViewList={() => changeView(LIST)}
          changeViewTile={() => changeView(TILE)}
          changeViewTable={() => changeView(TABLE)}
          listView={listView}
          tileView={tileView}
          tableView={tableView}
          // selectionOptions={selectionOptions}
          handleAddItemButtonClick={this.handleOpenAddItemModal}
          objectType="Sales Order"
          withPagination={false}
          hasTableView={false}

        />

        <Box p={1} pl={[2, 1]}>
          <PaginationControl
            pageSize={pageSize}
            current={itemsCurrentPage}
            total={itemsCount}
            onChange={getPage}
          />
        </Box>
        <div className="row">
          <div className={(listView) ? "items-flex-row" : "items-flex-column"}>

            <div>
              <HelpButton
                handleClick={this.handleTutFromButton}
                text={"Open a help Pop up."}
              />
              <ButtonBar
                handleClick={this.handleOpenAddItemModal}
                mainText={"Build an order"}
              />
            </div>

            {!tableView &&
            <OrderList
              listView={listView}
              data={itemsPaged}
              loading={loading}
            />
            }
          </div>
        </div>

        <TutorialModal
          visible={(itemsPaged.length === 0 && showTutorialModal && !loading) || (showTutFromHelp)}
          closeModal={this.handleCloseTutorialModal}
          banner={BannerOne}
          type="Sales Order"
          descriptions={salesOrderHelp}
          handleOpenAddItemModal={[this.handleOpenAddItemModal, "Create One!"]}/>


        <AddItemModal
          visible={showAddItemModal}
          itemName="Sales Order"
          closeAddItemModal={this.handleCloseAddItemModal}
          submitAddItem={this.handleSubmitAddItem}
          addItemSubmitting={addItemModalSubmitting}
          addItemError={addItemModalError}
          // handleUploadCsv={this.handleUploadCsv}
          // columns={csvColumns}
          showCsv={false}
          // csvMessages={csvMessages}
          // clearCsvMessages={clearMessages}
          // sampleData={csvData}
        />
      </div>
    );
  }
}

export default SalesOrders;
