import React from "react";
import PropTypes from "prop-types";
import {List} from "Base";
import AssetListItem from "./AssetListItem";
const AssetList = props => {
  const {data, onDelete} = props;
  return (
    <List
      bordered
      style={{backgroundColor: "white"}}
      dataSource={data}
      renderItem={item => <AssetListItem item={item} onDelete={onDelete} />}
    />
  );
};

AssetList.propTypes = {
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
};

export default AssetList;
