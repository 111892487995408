import React from "react";
import PropTypes from "prop-types";
import ProductionLineBar from "../Bar";
import ProductionLineTable from "../Table";
import {PRODUCTION_DASHBOARD_LINE_ROUTE} from "../../../routes/constants";
import {productionLineHelp} from "../../Common/HelpItems/constants";
import {selectionOptions, filterOptions} from "./constants";
import {DashboardList} from "../../Common/Dashboard";
import {crudActions} from "../../../types/propTypes";

const ProductionDashboard = props => {
  const {
    changeView,
    accountInfo,
    pagination,
    actions
  } = props;

  return (
    <DashboardList
      accountInfo={accountInfo}
      ListItem={ProductionLineBar}
      getPage={actions.getPage}
      Table={ProductionLineTable}
      createItem={actions.create}
      pagination={pagination}
      type={"Production Line"}
      changeView={changeView}
      showCsv={false}
      tutorialText={productionLineHelp}
      sortByOptions={selectionOptions}
      filterOptions={filterOptions}
      newItemUrl={PRODUCTION_DASHBOARD_LINE_ROUTE}
    />
  );
};

ProductionDashboard.propTypes = {
  changeView: PropTypes.func,
  actions: crudActions,
  lineAssetActions: crudActions,
  getLocations: PropTypes.func
};

export default ProductionDashboard;
