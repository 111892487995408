import React from "react";
import PropTypes from "prop-types";

import {Table} from "../../../../Common/Base";
import {columns} from "./constants";


const PMOrderSection = props => {
  const {data} = props;

  return (
    <Table
      className="components-table-demo-nested"
      columns={columns}
      dataSource={data?.pm_orders ?? []}
    />
  );
};


PMOrderSection.propTypes = {
  /**
   * Location data
   */
  data: PropTypes.object,
};

export default PMOrderSection;
