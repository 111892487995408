import React, {Component} from "react";
import {postAxios} from "../../../utils/authWithAxios";
import {MaterialsTable} from "../Table/MaterialsTable";
import {MaterialBar} from "../Bar/MaterialBar";
import {csvColumns, sampleData, selectionOptions} from "./constants";
import {materialsHelp} from "Common/HelpItems/constants";
import {DashboardList} from "Common/Dashboard";

/* This will present a list of material bars. */
class Materials extends Component {
  /* This function will perform the API request required to add a new material. The new material
    might have one attribute defined with key and value pair,
    the user will be forwarded to the Edit Material page for the new material where he can then enter details. */
  handleSubmitAddItem = async (dataToSubmit) => {
    try {
      const res = await postAxios({
        url: `${global.base_url}v1/materials/`,
        data: dataToSubmit,
      });
      console.log("new item res", res);
      return ({
        payload: res.data,
        error: false,
      });
    } catch (error) {
      return ({
        error: true,
        payload: error,
      });
    }
  };

  render() {
    const {
      accountInfo,
      csvMessages,
      clearMessages,
      pagination,
      changeView,
      getPage,
      uploadCsv,
      bulkDelete,
      updateDetails,
      match
    } = this.props;

    return (
      <DashboardList
        accountInfo={accountInfo}
        ListItem={MaterialBar}
        getPage={getPage}
        Table={MaterialsTable}
        updateDetails={updateDetails}
        createItem={this.handleSubmitAddItem}
        uploadCsv={uploadCsv}
        pagination={pagination}
        type={"material"}
        changeView={changeView}
        handleTableFieldChange={this.handleTableFieldChange}
        csvOptions={{
          csvColumns,
          csvData: sampleData,
          clearMessages,
          csvMessages,
        }}
        tutorialText={materialsHelp}
        sortByOptions={selectionOptions}
        newItemUrl={match.path}
        initialValues={{
          name: "",
          manufacturer: "",
          sku: 0
        }}
        bulkDelete={bulkDelete}
      />
    );
  }
}


export default Materials;
