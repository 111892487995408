import React, {useState} from "react";
import PropTypes from "prop-types";
import {MenuItem, HeaderMenu, HeaderWrapper} from "./styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConmittoLogoWithText from "../../../static/img/conmitto_logo_text.png";
import {BarcodeDropdown, ToggleActivityDropdown, LogDropdown} from "./Dropdowns";
import {SnackBar} from "../index";
import {MainMenu} from "./Menu";
import {getShiftListFromAccount} from "./utils";
import {useMediaQuery} from "react-responsive";
import {MOBILE} from "utils/constants";

const Header = props => {
  const {
    account,
    activeActivity,
    logs,
    setPanel,
    toggleShiftActivity,
    recentActivity,
    resetStore,
  } = props;
  const [snackMsg, setSnackMsg] = useState("");

  const isTabletOrMobile = useMediaQuery(MOBILE);

  const shiftList = getShiftListFromAccount(account);

  let recentActivityList = [];
  if (recentActivity && recentActivity.results) {
    recentActivityList = recentActivity.results;
  }

  return (
    <HeaderWrapper>
      <HeaderMenu className={"header-menu"} order={[1, 1]} flexGrow={1} justifyContent={"left"}>
        {account.user_type === 1 &&
        <MenuItem height="">
          <FontAwesomeIcon
            onClick={() => setPanel(true)}
            icon="users-cog"
            size="2x"
            className="header-menu-admin"/>
        </MenuItem>
        }
        <MenuItem height="" order={[2, 1]}>
          <a href="/#/dashboard">
            <img
              className="header-menu-logo"
              alt="Conmitto logo with text"
              src={ConmittoLogoWithText}
            />
          </a>
        </MenuItem>
      </HeaderMenu>

      <HeaderMenu className={"header-menu"} order={[3, 2]} justifyContent={"right"}>
        <MenuItem>
          <ToggleActivityDropdown
            onSubmit={toggleShiftActivity}
            active={activeActivity}
            shiftList={shiftList}
            recentActivity={recentActivityList}
          />

        </MenuItem>

        <MenuItem>
          <BarcodeDropdown
            setSnackMsg={setSnackMsg}
          />

        </MenuItem>

        <MenuItem>
          <LogDropdown
            logList={logs}

          />
        </MenuItem>
      </HeaderMenu>
      {!isTabletOrMobile &&
      <HeaderMenu order={[4, 3]}>
        <MainMenu account={account} onLogout={resetStore}/>
      </HeaderMenu>}
      {snackMsg !== "" && <SnackBar title={snackMsg} status={"error"}/>}
    </HeaderWrapper>
  );
};

Header.propTypes = {
  account: PropTypes.any,
  setPanel: PropTypes.func,
  logs: PropTypes.any,
  toggleShiftActivity: PropTypes.func,
  recentActivity: PropTypes.any,
};

Header.defaultProps = {
  logs: [],
  setPanel: () => console.log("set panel"),
};

export default Header;
