import types from "./types";
import {name as endpoint} from "../pmOrders";
import base from "../base/actions";

const getAllSuccess = data => ({
  type: types.GET_ALL_SUCCESS,
  payload: data
});

const getAllFailure = error => ({
  type: types.GET_ALL_FAILURE,
  payload: error
});

const getAllNoneFound = () => ({
  type: types.GET_ALL_NONE_FOUND,
  payload: "No PM Orders Found"
});

const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/?expand=barcode,org,location,scheduled_pm_orders.location`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

/* Fetch Item Shifts */
const getItemSchedules = (id, range = "") => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/schedules/${range}`,
  types: [
    types.GET_ITEM_SCHEDULES_REQUEST,
    {
      type: types.GET_ITEM_SCHEDULES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_SCHEDULES_FAILURE
  ]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});

const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

/* SimplePagination actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  payload: by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

const filterListBy = by => ({
  type: types.FILTER_LIST_BY,
  payload: by
});

const reverseList = () => ({
  type: types.REVERSE_LIST
});

/* View related actions. */
const changeViewList = () => ({
  type: types.CHANGE_VIEW_LIST
});

const changeViewTile = () => ({
  type: types.CHANGE_VIEW_TILE
});

const changeViewTable = () => ({
  type: types.CHANGE_VIEW_TABLE
});

const changeViewCalendar = () => ({
  type: types.CHANGE_VIEW_CALENDAR
});

/* Update item actions. */

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const createSchedule = (order_id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${order_id}/schedules/`,
  body: JSON.stringify(data),
  types: [types.CREATE_SCHEDULE_REQUEST, types.CREATE_SCHEDULE_SUCCESS, types.CREATE_SCHEDULE_FAILURE],
});

const updateSchedule = (order_id, id, data) => base.patchRsaa({
  endpoint: `${global.base_url}s${endpoint}${order_id}/schedules/${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_SCHEDULE_REQUEST, types.UPDATE_SCHEDULE_SUCCESS, types.UPDATE_SCHEDULE_FAILURE],
});

const deleteSchedule = (order_id, id) => base.deleteRsaa({
  endpoint: `${global.base_url}${endpoint}${order_id}/schedules/${id}/`,
  types: [types.DELETE_SCHEDULE_REQUEST, types.DELETE_SCHEDULE_SUCCESS, types.DELETE_SCHEDULE_FAILURE],
});

const uploadCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}csv/${org}/`,
  headers: {},
  body: data,
  types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE]
});

const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export default {
  getAllSuccess,
  getAllFailure,
  getAllNoneFound,
  getItem,
  getItemNotes,
  getItemSchedules,
  submitNote,
  uploadCsv,
  updateItem,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  reverseList,
  changeViewList,
  changeViewTile,
  changeViewTable,
  changeViewCalendar,
  clearMessages,
  filterListBy,
};
