import styled from "@emotion/styled";
import {Box, Flex, ErrorHeading, Text} from "../Base";
import Modal from "styled-react-modal";


const StyledModal = Modal.styled`
  width: 400px;
  min-height: 20rem;
  max-height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: ${props => props.theme.colors.colorGreyLight3};
`;

const HistoryListWrapper = styled(Flex)`

`;

HistoryListWrapper.defaultProps = {
  flexDirection: "row",
  justifyContent: "space-evenly",
  flexWrap: "wrap",
  alignItems: "center"
};

const HistoryText = styled(Text)`

`;

HistoryText.defaultProps = {
  fontSize: "1rem",
  textAlign: "left"
};

const DateText = styled(Text)`

`;

DateText.defaultProps = {
  fontSize: "0.9rem",
  py: 1,
  textAlign: "left"
  // Color: 'meta'
};

const Dot = styled(Box)`
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
`;


const DescriptionText = styled(Text)``;

DescriptionText.defaultProps = {
  fontSize: "16px"
};

const PageNumberText = styled(Text)`
  font-size: 12px;
  text-align: center;
`;

const ModalErrorHeading = styled(ErrorHeading)``;

ModalErrorHeading.defaultProps = {
  fontSize: "16px",
  mt: "15px",
  color: "colorError"
};


export {StyledModal, DescriptionText, PageNumberText, Dot, DateText, ModalErrorHeading, HistoryText, HistoryListWrapper};
