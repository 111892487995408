/**
 * Gets the object value going down the path specified.
 * Behaves like object[path[0]][path[1]], without having to check that every key exists/
 * @param {Array} path The path to take inside the object
 * @param {object} obj The object to fetch the value of
 * @returns {boolean|any} value of the object, or false if it doesn't exist
 */
const checkNestedKeys = (path, obj) => {
  if (obj && typeof (obj) === "object" && Object.keys(obj)) {
    let i = 0;
    /**
     * Checks if the next key in the path exists for the object, recursing until hitting the last key in the path
     * @param {object} object The object to traverse
     * @returns {boolean|any} The object value if exists, else false
     */
    const checkKey = (object) => {
      if (object[path[i]] && i < path.length - 1) {
        return checkKey(object[path[i++]]);
      } else {
        return i === path.length - 1 ? object[path[i]] : false;
      }
    };
    return checkKey(obj);
  }
  return false;
};

/**
 * Converts a string in camel case to snake case
 * @param {string} string A camel cased string to convert
 * @returns {string} The string in snake case
 */
const camelToSnake = (string) => {
  return string.replace(/[\w]([A-Z])/g, function (m) {
    return m[0] + "_" + m[1];
  }).toLowerCase();
};

export {checkNestedKeys, camelToSnake};
