import React from "react";
import PropTypes from "prop-types";
import ShiftListItem from "./ShiftListItem";
import {SimplePagination} from "../../Base";


/*
  This component renders a list of Shifts inside the PersonnelScheduleForm
  Displays on the PersonnelDetails page
 */
const ShiftList = props => {
  const {items, onItemClick, isEditable} = props;

  const handleClick = (item) => {
    onItemClick(item);
  };


  return (
    <SimplePagination
      items={items}
      ListItem={ShiftListItem}
      handleItemClick={handleClick}
      itemProps={{
        isEditable,
      }}
      isAccordion={true}
      accordionProps={{
        allowToggle: true
      }}
      listItemKey={(item) => `${item.id}-${item.start}`}
    />
  );
};

ShiftList.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  isEditable: PropTypes.bool,
};

ShiftList.defaultProps = {
  isEditable: false,
};

export default ShiftList;
