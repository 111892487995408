import jwtDecode from "jwt-decode";
import types from "./types";
import Cookies from "js-cookie";

const initialState = {
  accountInfo: {},
  settings: null,
  user: null,
  access: null,
  isAuthenticated: true,
  isPasswordChanged: false,
  attemptingLogin: false,
  errors: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
  /* On login attemp, sets attemptingLogin to True for spinner effect. */
  case types.AUTHENTICATION_REQUEST:
    return {
      ...state,
      attemptingLogin: true,
      errors: ""
    };
    // Sets isAuthenticated to True if login successful.
  case types.AUTHENTICATED: {
    Cookies.set("access", action.payload.access_token);

    // An additional access token cookie needs to be set for websockets
    // the domain should be whatever the Base URL is in the swagger docs
    Cookies.set("access", action.payload.access_token, {domain: global.server_domain});


    Cookies.set("refresh", action.payload.refresh_token);
    return {
      ...state,
      isAuthenticated: true,
      attemptingLogin: false,
      errors: "",
      access: {
        token: action.payload.access_token,
        ...jwtDecode(action.payload.access_token)
      },
      refresh: {
        token: action.payload.refresh_token,
        ...jwtDecode(action.payload.refresh_token)
      },
      user: action.payload.user
    };
  }
  case types.AUTHENTICATION_ERROR:
    let error = action.payload.response ? "Invalid Credentials" : "Network Error";
    return {
      ...state,
      errors: error,
      attemptingLogin: false,
      isAuthenticated: false
    };
    // Store account information to be accessed globally.
  case types.ACCOUNT_INFO_STORE:
    return {
      ...state,
      accountInfo: action.payload,
      isAuthenticated: true
    };
  case types.CONFIRM_INVITATION_SUCCESS:
    return {
      ...state,
      isPasswordChanged: true
    };

  case types.CONFIRM_INVITATION_FAILURE:
    return {
      ...state,
      errors: action.payload,
      isPasswordChanged: false
    };

  case types.GET_SETTINGS_SUCCESS:
    return {
      ...state,
      settings: action.payload
    };
  default:
    return state;
  }
};
