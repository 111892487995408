import React, {Component} from "react";
import PropTypes from "prop-types";
import {Box, Button} from "Common/Base";
import {ImageWithDefault} from "Common/Details/Layout";
import {
  AZURE_BLOB_CONTAINER_NAME,
  handleChangeDisplayImage,
  uploadBlobsToAzureWithFileNameCheck,
} from "../../../utils/azure";

/* Component for displaying and managing document display_image fields on Detail Pages  */
class DisplayImage extends Component {
  handleChangeDisplayImageField = () => {
    const {uuid, azurePrefix, fetchDetails} = this.props;

    uploadBlobsToAzureWithFileNameCheck(
      uuid,
      azurePrefix,
      [this.refs.fileUploader.files[0]],
    ).then(async res => {
      // Res contains filenames from uploaded files, use res[0] because only one file was uploaded
      await this.handleChangeDisplayImageNoUpload(res[0]);
      fetchDetails();
    });
  };

  handleChangeDisplayImageNoUpload = async imageName => {
    const {id, updateDetails} = this.props;
    await handleChangeDisplayImage(this, imageName, id, updateDetails);
  };


  render() {
    const {
      displayImage,
    } = this.props;

    return (
      <Box
        className={"display-image-section"}
        alignItems="center"
        alignContent="center"
        textAlign="center"
      >
        <Box width={["100%", "250px"]} p={[0, 2]}>
          <ImageWithDefault src={displayImage}/>
        </Box>
        <Box mt="10px">
          <input
            type="file"
            accept="image/*"
            id="file"
            ref="fileUploader"
            onChange={this.handleChangeDisplayImageField}
            style={{display: "none"}}
          />
          <Button
            className="item-details-form-button"
            onClick={() => this.refs.fileUploader.click()}>
            Change Display Image
          </Button>
        </Box>
      </Box>
    );
  }
}

DisplayImage.propTypes = {
  /**
   * id of item with attachments
   */
  id: PropTypes.string,
  /**
   * DetailsPage fetchDetails function
   */
  fetchDetails: PropTypes.func.isRequired,
  /**
   * Update details action for item with attachments
   */
  updateDetails: PropTypes.func.isRequired,
  /**
   * UUID of item with attachments
   */
  uuid: PropTypes.string.isRequired,
  /**
   * document specific prefix for azure
   */
  azurePrefix: PropTypes.string.isRequired,
  /**
   * project specific prefix for azure
   */
  azureBlob: PropTypes.string,
  /**
   * Warranty document url
   */
  warrantyDocument: PropTypes.string,
  /**
   * Display image url
   */
  displayImage: PropTypes.string,
  updating: PropTypes.bool.isRequired,
};

DisplayImage.defaultProps = {
  azureBlob: AZURE_BLOB_CONTAINER_NAME,
};
export default DisplayImage;
