import React, {useState} from "react";
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";
import {Row} from "../ScheduledShift/styled";
import {FormDropdown} from "../Base";
import {ASSIGN} from "../ScheduledShift/utils";
import AssignPersonnelForm from "../ScheduledShift/AssignPersonnelForm";
import {IconButton, Flex, Text} from "../../Base";
import {FaPlay, FaStop} from "react-icons/fa";
import {formatShiftOptions, formatActive, checkHasActive, formatSubmit} from "./utils";
import {ruleStringToText} from "../../../../utils/rrules";
import {formatShiftTimeWithTimezone} from "../../../../utils/dates";

const ToggleActivityForm = props => {
  const [showSelectShift, setShowSelectShift] = useState(false);
  const {onSubmit, shiftList, validate, active} = props;

  const options = formatShiftOptions(shiftList);

  const submit = (values) => {
    console.log("toggle values", values);
    let data = formatSubmit(values);
    console.log("submit data", data);

    onSubmit(data);
    if (showSelectShift) {
      setShowSelectShift(false);
    }
  };

  const hasActive = checkHasActive(active);

  const initialValues = formatActive(active, shiftList);
  let activeTitle = "None";
  let start = "";

  if (hasActive) {
    activeTitle = active.shift ? `${active.shift_details.schedule.name} - ${ruleStringToText(active.shift_details.repeat)}` : "No Shift Assigned";
    start = formatShiftTimeWithTimezone(active.start_time);
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form onSubmit={handleSubmit} className={"toggle-activity-form"}>
          <Flex flexDirection={"column"} justifyContent={"flex-start"} align={"center"}>

            <Flex justifyContent={"flex-start"} width={"300px"} pr={2}>
              <Field name={"shift"} component={"input"} type={"hidden"} defaultValue={hasActive ? active.shift_details.id : null}/>

              <Flex flexDirection={"column"}>
                <Text className="active-shift-text" fontSize={"sm"}>Active: {activeTitle}</Text>
                {hasActive &&
                <Text fontSize={"sm"} className={"active-start-text"}>Start: {start}</Text>
                }
              </Flex>
            </Flex>

            <Row zIndex={1000}>
              <FormDropdown
                options={options}
                name="shift"
                placeholder="Shift.."
                mb={0}
                className="item-details-form-dropdown-no-margin"
              />

              <IconButton
                ml={2}
                icon={hasActive ? FaStop : FaPlay}
                className="toggle-time-tracker-button"
                type="submit"
              />
            </Row>

          </Flex>
        </form>
      )}
    />
  );
};

ToggleActivityForm.propTypes = {
  onSubmit: PropTypes.func,
  shiftList: PropTypes.array,
  editAccess: PropTypes.bool,
  action: PropTypes.number,
  handleCancel: PropTypes.func,
  active: PropTypes.any,
};

AssignPersonnelForm.defaultProps = {
  editAccess: true,
  action: ASSIGN, // 1 for Add, 2 for Remove
};
export default ToggleActivityForm;
