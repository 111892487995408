import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Box, Button, FlexCenter} from "../../Base";

export default function ProgressStage(props) {
  const {
    isRendered,
    onClick,
    disabled,
    gradient,
    btnText,
    subText,
    subTextConditions,
    secondaryButton,
    preText,
    isFlexVertical
  } = props;

  return (
    <Fragment>
      {isRendered && <Fragment>
        <FlexCenter
          flexDirection={(isFlexVertical ? "column" : "row")}>
          {Boolean(preText) && <Box className="order-progress-pretext" fontSize="16px" margin="5px">
            {preText}
          </Box>}
          <FlexCenter
            width="100%"
            flexDirection={(isFlexVertical ? "column" : "row")}>
            <Button
              className="progress-btn"
              onClick={onClick}
              m={1}
              disabled={disabled}
              color={"white"}
              gradient={gradient}>
              {btnText}
            </Button>
            {secondaryButton}
          </FlexCenter>
        </FlexCenter>
        {subTextConditions && <Box fontSize="16px" marginTop="14px">
          {subText}
        </Box>}
      </Fragment>}
    </Fragment>
  );
}

ProgressStage.propTypes = {
  isRendered: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  gradient: PropTypes.string,
  btnText: PropTypes.string,
  subText: PropTypes.string,
  subTextConditions: PropTypes.bool,
  secondaryButton: PropTypes.element,
  preText: PropTypes.string,
};

ProgressStage.defaultProps = {
  subText: "",
  preText: "",
};
