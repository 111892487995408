import types from "./types";
import {createAction} from "redux-api-middleware";
import {REFRESH_TOKEN_SUCCESS} from "@tshio/redux-api-auth-middleware";
import base from "../base/actions";

import Cookies from "js-cookie";

export const refreshToken = (token, endpoint) => createAction({
  endpoint: `${global.base_url}auth/refresh/`,
  method: "POST",
  body: JSON.stringify({refresh: token}),
  headers: {"Content-Type": "application/json"},
  types: [
    types.TOKEN_REQUEST,
    {
      type: REFRESH_TOKEN_SUCCESS, payload: (action, state, res) => {
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.indexOf("json") !== -1) {
          // Just making sure res.json() does not raise an error
          return res.json().then((json) => {
            Cookies.set("access", json.access);
            Cookies.set("access", json.access, {domain: global.server_domain});

            Cookies.set("refresh", json.refresh);
            return {access_token: json.access, refresh_token: json.refresh};
          });
        }
      }
    },
    types.TOKEN_FAILURE
  ],
  skipAuth: true
});

const login = (username, password) => createAction({
  endpoint: `${global.base_url}auth/login/`,
  method: "POST",
  body: JSON.stringify({email: username, password}),
  headers: {"Content-Type": "application/json"},
  types: [
    types.AUTHENTICATION_REQUEST, types.AUTHENTICATED, types.AUTHENTICATION_ERROR
  ]
});


// Invite actions


const confirmInvitationSuccess = info => ({
  type: types.CONFIRM_INVITATION_SUCCESS,
  payload: info,
});

const confirmInvitationFailure = info => ({
  type: types.CONFIRM_INVITATION_FAILURE,
  payload: info,
});


const getSettings = () => base.getRsaa({
  endpoint: `${global.base_url}v1/profile/settings`,
  types: [
    types.GET_SETTINGS_REQUEST,
    types.GET_SETTINGS_SUCCESS,
    types.GET_SETTINGS_FAILURE,
  ],
});

const updateSettings = (data) => base.patchRsaa({
  endpoint: `${global.base_url}v1/profile/settings`,
  body: JSON.stringify(data),
  types: [
    types.UPDATE_SETTINGS_REQUEST,
    types.UPDATE_SETTINGS_SUCCESS,
    types.UPDATE_SETTINGS_FAILURE,
  ],
});


const getProfile = () => base.getRsaa({
  endpoint: `${global.base_url}v1/profile`,
  method: "GET",
  types: [
    types.ACCOUNT_INFO_REQUEST, types.ACCOUNT_INFO_STORE, types.ACCOUNT_INFO_FAILURE
  ]
});

const requestPasswordReset = (data) => createAction({
  endpoint: `${global.base_url}auth/password/reset/`,
  method: "POST",
  body: JSON.stringify(data),
  headers: {"Content-Type": "application/json"},
  types: [
    types.PASSWORD_RESET_REQUEST, types.PASSWORD_RESET_SUCCESS, types.PASSWORD_RESET_FAILURE
  ]
});

/**
 * Confirms a password reset with token sent to email
 * @param {{new_password1: string, new_password2: string, uid: string, token: string}} data - New password, uid, and token
 * @returns {"RSAA"} - RSAA response
 */
const confirmPasswordReset = (data) => createAction({
  endpoint: `${global.base_url}auth/password/reset/confirm/`,
  method: "POST",
  body: JSON.stringify(data),
  headers: {"Content-Type": "application/json"},

  types: [
    types.CONFIRM_PASSWORD_RESET_REQUEST, types.CONFIRM_PASSWORD_RESET_SUCCESS, types.CONFIRM_PASSWORD_RESET_FAILURE
  ]
});

export default {
  refreshToken,
  login,
  getProfile,
  confirmInvitationSuccess,
  confirmInvitationFailure,
  getSettings,
  updateSettings,
  requestPasswordReset,
  confirmPasswordReset
};
