import {Box} from "../../Base";
import styled from "@emotion/styled";

const dropdownContainerStyles = {
  overflow: "visible",
  zIndex: 90000
};

const DropdownWrapper = styled(Box)`
  overflow: visible;
    z-index: 90000;

`;

DropdownWrapper.defaultProps = {
  shadow: "md",
  borderWidth: "1px",
  bg: "white",
  p: 2,
  maxW: "100vw"
};

const HeaderWrapper = styled(Box)``;

HeaderWrapper.defaultProps = {
  p: 2
};

const CloseWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

CloseWrapper.defaultProps = {
  p: 2
};

export {dropdownContainerStyles, DropdownWrapper, HeaderWrapper, CloseWrapper};
