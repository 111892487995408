import React, {useCallback, useEffect, useState, Fragment} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {DetailsContainer} from "./index";
import DetailsPageHeader from "./DetailsPageHeader";
import {crudActions} from "../../../types/propTypes";
import {basePreSaveFormat} from "Common/Forms/Base/utils";
import {checkIsNew} from "../../../utils/router";
import DetailsLocationSection from "./LocationSection";
import DetailsTabs from "./DetailsTabs";
import DisplayImage from "./DisplayImage";
import Attachments from "./Attachments";
import DetailsNotes from "./DetailsNotes";
import {AZURE_BLOB_CONTAINER_NAME, listBlobsInAzureContainer} from "../../../utils/azure";

const renderTabs = (tabs) => {
  console.log("tabs", tabs);
  return tabs ?
    <DetailsTabs tabs={tabs}/> : null;
};

const DetailsPage = props => {
  const {
    headerExtra,
    details,
    type,
    headerProps,
    apiActions,
    actions,
    location,
    match,
    children,
    fetchData,
    tabs,
    clickToEdit,
    editable,
    preSave,
    hasImage,
    hasAttachments,
    accountInfo,
    fileConfig,
    postSave,
    notes
  } = props;

  const [itemID, setItemID] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const getDocuments = useCallback(() => {
    const {azurePrefix} = fileConfig;
    const uuid = details?.uuid ?? "";

    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${azurePrefix}/${uuid}/`).then(files => {
      setFiles(files);
    });
  }, [fileConfig, details]);

  const handleFetchDetails = useCallback(async () => {
    if (!details) {
      setIsLoading(true);
    }
    let res = await apiActions.getItem(match.params.id);
    if (res.error) {
      setNotFound(true);
    }
    setIsLoading(false);
    fetchData();
  }, [apiActions, match, fetchData, details, setIsLoading]);

  useEffect(() => {
    const fetchExtraData = async () => {
      const isNew = checkIsNew(location);
      if (isNew) {
        setIsNew(true);
      }
      await handleFetchDetails();
      if (fileConfig) {
        getDocuments();
      }
    };
    if (match.params.id !== itemID) {
      setItemID(match.params.id);
      fetchExtraData();
    }
  }, [match.params.id, location, setIsNew, itemID, handleFetchDetails, fileConfig, getDocuments]);


  const handleSubmit = async (values) => {
    setSubmitting(true);
    const data = preSave(values);
    if (data) {
      const res = await apiActions.update(details.id, data);
      postSave();
      setSubmitting(false);
      return res;
    } else {
      setSubmitting(false);
    }
  };

  const handleSubmitLocation = async (value) => {
    console.log("location value", value);

    const updateValue = {location: value.location.location_instance};
    const res = await apiActions.update(details.id, updateValue);
    if (!res.error) {
      handleFetchDetails();
    }
  };

  const renderDisplayImageSection = () => {
    return hasImage ?
      <DisplayImage
        id={itemID}
        fetchDetails={handleFetchDetails}
        uuid={details?.UUID ?? null}
        match={match}
        updating={submitting}
        displayImage={details?.display_image ?? ""}
        updateDetails={apiActions.update}
        files={files}
        getDocuments={getDocuments}
        {...fileConfig}
      /> : null;
  };

  const renderAttachmentSection = () => {
    return hasAttachments ?
      <Attachments
        id={itemID}
        fetchDetails={handleFetchDetails}
        uuid={details?.UUID ?? null}
        match={match}
        warrantyDocument={details?.warranty_document ?? ""}
        files={files}
        getDocuments={getDocuments}
        updateDetails={apiActions.update}
        {...fileConfig}
      />
      : null;
  };


  const renderLocationSection = () => {
    const {
      accountInfo,
      locationList,
      details,
      hasLocation
    } = props;

    return hasLocation ?
      <DetailsLocationSection
        accountInfo={accountInfo}
        details={details}
        locationList={locationList}
        onSubmit={handleSubmitLocation}
      />
      :
      null;
  };

  const renderNoteSection = () => {
    if (notes) {
      const {data, ...notesRest} = notes;
      return ([{
        key: "notes",
        title: "Notes",
        content: <DetailsNotes
          title={`${headerProps?.text?.name ?? "Document"} Notes`}
          endpoint={"/"}
          accountInfo={accountInfo}
          data={details}
          getDocuments={getDocuments}
          notes={data}
          {...notesRest}
          {...fileConfig}
        />
      }]);
    }
    return [];
  };

  const renderFooter = () => {
    return (
      <Fragment>
        {/* {renderAttachmentSection()}*/}
        {/* {renderNoteSection()}*/}
      </Fragment>
    );
  };


  return (
    <DetailsContainer
      type={type}
      notFound={notFound}
      loading={loading}
    >
      <DetailsPageHeader
        data={details}
        apiActions={apiActions}
        onDelete={apiActions.delete}
        onUpdate={handleSubmit}
        actions={actions}
        clickToEdit={clickToEdit}
        editable={editable}
        image={renderDisplayImageSection()}
        footer={renderFooter()}
        isNew={isNew}

        {...headerProps}
      >
        {renderLocationSection()}
        {renderAttachmentSection()}
        {/* {renderNoteSection()}*/}

        {headerExtra}
      </DetailsPageHeader>
      {children}

      {renderTabs([
        ...tabs,
        ...renderNoteSection()
      ])}

    </DetailsContainer>
  );
};


const tabProps = PropTypes.shape({
  /**
   * React component for content
   */
  content: PropTypes.any,
  key: PropTypes.string,
  title: PropTypes.string
});

DetailsPage.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  details: PropTypes.object,
  /**
   * Crud actions for the model type details page is for
   */
  apiActions: crudActions.isRequired,
  /**
   * Extra action for DetailsPageHeader
   * Should be self-contained components with a Button/Modal
   */
  actions: PropTypes.array,
  match: PropTypes.object,
  /**
   * fetches any extra data needed besides details
   */
  fetchData: PropTypes.func,
  headerExtra: PropTypes.any,
  /**
   * Additional props for DetailsPageHeader component
   */
  headerProps: PropTypes.shape({
    renderDescription: PropTypes.func,
    deleteConfig: PropTypes.shape({
      confirmText: PropTypes.string,
      redirectUrl: PropTypes.string.isRequired,
    }),
    editConfig: PropTypes.shape({
      formID: PropTypes.string.isRequired,
      hideMeta: PropTypes.bool,
    }),
    metaFields: PropTypes.array,
    DetailsForm: PropTypes.elementType,
    formProps: PropTypes.object,
    image: PropTypes.element,
    text: PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.string,
      name: PropTypes.string,
      shortName: PropTypes.string,
    }),
    leftDetails: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),

  }),
  hasLocation: PropTypes.bool,
  hasImage: PropTypes.bool,
  hasAttachments: PropTypes.bool,
  hasNotes: PropTypes.bool,
  locationList: PropTypes.array,
  tabs: PropTypes.arrayOf(tabProps),
  /**
   * Set true to open form upon clicking the details section.
   */
  clickToEdit: PropTypes.bool,
  /**
   * If this details page has an editable form
   */
  editable: PropTypes.bool,
  /**
   * formats Form values before submit
   */
  preSave: PropTypes.func,
  /**
   * function to be called after primary save
   */
  postSave: PropTypes.func,
  /**
   * required for hasImage and hasAttachments
   */
  fileConfig: PropTypes.shape({
    azurePrefix: PropTypes.string,
    azureBlob: PropTypes.string
  }),
  /**
   * This destructors into the NoteSection and extra props can be passed
   */
  notes: PropTypes.shape({
    actions: PropTypes.shape({
      addNote: PropTypes.func,
      fetchNotes: PropTypes.func
    }),
    data: PropTypes.array,
    toggleInspectionNote: PropTypes.func,
    endpoint: PropTypes.string.isRequired
  }),
};

DetailsPage.defaultProps = {
  actions: [],
  fetchData: () => null,
  headerExtra: null,
  headerProps: {
    renderDescription: () => null,
    text: {
      title: "Document Details",
      name: "document",
      shortName: "order",
      subTitle: null,
    },
    editConfig: {
      formID: "details-form",
      hideMeta: false,
    },
    deleteConfig: {
      confirmText: "Deleting this will remove all items assigned to it.",
    },
  },
  hasLocation: false,
  locationList: [],
  tabs: [],
  clickToEdit: true,
  editable: true,
  preSave: basePreSaveFormat,
  hasImage: false,
  hasAttachments: false,
  hasNotes: false,
  postSave: () => null,
  notes: null
};

// for usage without router. needs match.params.id
export {DetailsPage};

export default withRouter(DetailsPage);
