import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Quagga from "quagga";
import {ErrorHeading} from "../Base";
import {BrowserMultiFormatReader, NotFoundException} from "@zxing/library";

import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import {ZXING_FORMAT_MAP} from "./constants";

registerPlugin(FilePondPluginFileValidateType);

const BARCODE_READERS = ["code_128_reader"];

class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraPermissionDenied: false,
      data: "",
    };

    this.videoRef = React.createRef();
    this.codeReader = null;
  }

  componentDidMount() {
    const {
      onDetected,
      stopAfterSuccess,
      initializationTimeout,
    } = this.props;

    this.codeReader = new BrowserMultiFormatReader();
    this.startScanner();
    // There is a possible initialization timeout, because when used with react-tiny-popover,
    // Quagga tries to initialize before the element is on the page, and this makes it error out
    setTimeout(() => {
      Quagga.onDetected(data => {
        onDetected(data);
        if (stopAfterSuccess) {
          Quagga.stop();
          Quagga.offDetected(onDetected);
        }
      });
    }, initializationTimeout);
  }

  componentWillUnmount() {
    if (this.codeReader) {
      this.codeReader.reset();
    }
  }

  startScanner = () => {
    const {onDetected} = this.props;
    this.codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices.length > 0) {
          const selectedDeviceId = videoInputDevices[0].deviceId;
          this.codeReader.decodeFromVideoDevice(selectedDeviceId, this.videoRef.current, (result, err) => {
            if (result) {
              if (this.validateBarcode(result.getText())) {
                onDetected({codeResult: {code: result.getText(), format: ZXING_FORMAT_MAP[result.getBarcodeFormat()]}});
              }
            }
            if (err && !(err instanceof NotFoundException)) {
              console.error(err);
            }
          }).catch((err) => {
            this.setState({cameraPermissionDenied: true});
            console.error(err);
          });
        }
      })
      .catch((err) => {
        this.setState({cameraPermissionDenied: true});
        console.error(err);
      });
  };

  validateBarcode = (barcode) => {
    const {onFailed} = this.props;
    const hexPattern = /^[\dA-Fa-f-]+$/;

    if (hexPattern.test(barcode)) {
      return true;
    }
    else {
      console.warn(`Only hex digits and dashes are allowed in barcodes: ${barcode}`);
      onFailed("Only digits, letters, and dashes are allowed in barcodes");
      return false;
    }
  };

  parseFile = (error, fileItem) => {
    const {onFailed} = this.props;
    onFailed("");
    if (error) {
      onFailed("Error with file upload");
    } else {
      Quagga.decodeSingle({
        decoder: {
          readers: BARCODE_READERS
        },
        locator: {
          patchSize: "medium",
          halfSample: true
        },
        numOfWorkers: 2,
        locate: true,
        src: fileItem.getFileEncodeDataURL()
      }, function (result) {
        if (!(result && result.codeResult)) {
          onFailed("Barcode not found in picture");
        }
      });
    }
  };

  render() {
    const {cameraPermissionDenied} = this.state;
    return (
      <Fragment>
        {!cameraPermissionDenied ?
          <video className="barcode-camera-scanner" ref={this.videoRef} style={{width: "100%"}} />
          : <Fragment><ErrorHeading>Camera Permission Denied</ErrorHeading><br/></Fragment>
        }
        <FilePond
          className="barcode-scanner-file-upload"
          acceptedFileTypes={["image/*"]}
          labelIdle={"<span class=\"filepond--label-action\">Upload</span> a barcode"}
          onaddfile={this.parseFile}/>
      </Fragment>
    );
  }
}

BarcodeScanner.propTypes = {
  onDetected: PropTypes.func.isRequired,
  onFailed: PropTypes.func,
  stopAfterSuccess: PropTypes.bool,
  initializationTimeout: PropTypes.number
};

BarcodeScanner.defaultProps = {
  onFailed: () => null,
  stopAfterSuccess: true,
  initializationTimeout: 0
};

export default BarcodeScanner;
