import actionTyper from "redux-actiontyper";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_PRICE_HISTORY_REQUEST,
  GET_ITEM_PRICE_HISTORY_FAILURE,
  GET_ITEM_PRICE_HISTORY_SUCCESS,

  GET_ITEM_STATUS_HISTORY_REQUEST,
  GET_ITEM_STATUS_HISTORY_FAILURE,
  GET_ITEM_STATUS_HISTORY_SUCCESS,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  ADD_NOTE_REQUEST,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  FILTER_LIST,
  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,
  CLEAR_MESSAGES,
} = actionTyper("@@stockroom_assets/");

export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_PRICE_HISTORY_REQUEST,
  GET_ITEM_PRICE_HISTORY_FAILURE,
  GET_ITEM_PRICE_HISTORY_SUCCESS,

  GET_ITEM_STATUS_HISTORY_REQUEST,
  GET_ITEM_STATUS_HISTORY_FAILURE,
  GET_ITEM_STATUS_HISTORY_SUCCESS,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  ADD_NOTE_REQUEST,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  FILTER_LIST,
  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,
  CLEAR_MESSAGES,
};
