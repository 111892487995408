import React from "react";
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";
import {AutoSave} from "../../../Common/Forms/Base";
import {NumberInputControl} from "../../../Common/Base/Inputs/NumberInput";
import {palletQuantityStatus, UNPACK} from "../../utils/constants";
import {WarehouseLocationDropdown} from "../../Fields";

const AdaptedLocationDropdown = ({input, ...rest}) => {
  return <WarehouseLocationDropdown {...input} {...rest}/>;
};

// For receiving Quantities on pallet
const ReceiveInventoryQuantityForm = ({onSubmit, getLocations, initialValues, locationPagination, action, createLocation, facility}) => {
  const max = initialValues.quantity;
  const combinedValues = {
    id: initialValues.id,
    location: facility.id,
    quantity: initialValues.quantity,
    status: action === UNPACK ? palletQuantityStatus.UNPACKED : palletQuantityStatus.PACKED,
  };

  if (action === UNPACK) {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={combinedValues}
        render={({
          handleSubmit,
        }) => (
          <form className={"add-pallet-quantity-form"} onSubmit={handleSubmit}>
            <AutoSave
              save={handleSubmit}
              debounced={[]}
            />
            <NumberInputControl
              label={`Quantity on Pallet: ${max}`}
              backgroundColor="white"
              placeholder={"Quantity..."}
              name={"quantity"}
              defaultValue={0}
              isDisabled={false}
              max={max}
            />

            <Field name={"location"} type={"hidden"} component={"input"}/>
            <Field name={"status"} type={"hidden"} component={"input"}/>

            <Field
              name={"stored_at"}
              component={AdaptedLocationDropdown}
              loadOptions={getLocations}
              pagination={locationPagination}
              onCreate={createLocation}
              facility={facility}
              // format={format}
            />

          </form>
        )}
      />

    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={combinedValues}
      render={({
        handleSubmit,
      }) => (
        <form className={"add-pallet-quantity-form"} onSubmit={handleSubmit}>
          <AutoSave
            save={onSubmit}
            debounced={[]}
          />
          <NumberInputControl
            label={`Quantity on Pallet: ${max}`}
            backgroundColor="white"
            placeholder={"Quantity..."}
            name={"quantity"}
            defaultValue={0}
            isDisabled={false}
            max={max}
          />

        </form>
      )}
    />
  );
};

ReceiveInventoryQuantityForm.propTypes = {
  onSubmit: (values) => null,
  initialValues: PropTypes.any,
  locationPagination: PropTypes.object,
  getLocations: PropTypes.func,
  createLocation: PropTypes.func,
};

ReceiveInventoryQuantityForm.defaultProps = {
  initialValues: {quantity: 0},
};

export default ReceiveInventoryQuantityForm;
