import React from "react";
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";
import {postLoadFormat} from "./utils";


const PalletForm = (props) => {
  const {
    onSubmit,
    validate,
    values,
    id,
  } = props;
  let initialValues = postLoadFormat(values);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form
          id={id}
          className={"receive-pallet-form "}
          onSubmit={handleSubmit}>
          {/* <AutoSave*/}
          {/*  debounce={1000}*/}
          {/*  save={onSubmit}*/}
          {/*  isSubmitting={submitting}*/}
          {/*  debounced={["sku"]}*/}
          {/*  preSaveFormat={preSaveFormat}*/}
          {/*  postLoadFormat={postLoadFormat}*/}
          {/*  formInvalid={invalid}*/}
          {/* />*/}
          <Field name={"status"} type={"hidden"} component={"input"}/>


        </form>
      )}
    />
  );
};


PalletForm.propTypes = {
  onSubmit: (values) => null,
  validate: PropTypes.func,
  values: PropTypes.object,
  handleManufacturerClick: PropTypes.func,
  locationList: PropTypes.array,
  isAdmin: PropTypes.bool,
  locationData: PropTypes.any,
  setLocationData: PropTypes.func,
  forOrder: PropTypes.bool,
  id: PropTypes.string,
};

PalletForm.defaultProps = {
  values: {},
  editAccess: false,
  forOrder: false,
  id: "pallet-details-form",
};

export default PalletForm;
