import React from "react";
import PropTypes from "prop-types";
import {Flex} from "../../../Common/Base";
import {Alert, Button} from "antd";
import {FieldPrefix, PrefixedDropdown, PrefixedField} from "../../../Common/Forms/Base/FieldPrefix";
import {AdaptedInput} from "../../../Common/Base/Inputs/AdaptedInputs";
import {Field, Form} from "react-final-form";
import {getStateOptions} from "utils/us_states";
import {CUSTOMER, SUPPLIER} from "../../utils/constants";

const AddressForm = ({onSubmit, initialValues, editAccess, id, errors, type}) => {
  const stateOptions = getStateOptions();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form id={id} onSubmit={handleSubmit}>
          <FieldPrefix prefix={"address"}>

            <Flex flexDirection={"column"}>
              {errors && errors.name &&
              <Alert message={errors.name[0]} type={"error"}/>
              }
              <PrefixedField
                placeholder={"Name.."}
                label="Name"
                type="text"
                name="name"
                readOnlyMode={!editAccess}
                component={AdaptedInput}
                size={"large"}
                required={true}
              />

              <PrefixedField
                placeholder={"Address Line 1"}
                label="Address Line 1"
                type="text"
                name="address_line1"
                size={"large"}
                required={true}

                readOnlyMode={!editAccess}
                component={AdaptedInput}
              />

              <PrefixedField
                placeholder={"Address Line 2"}
                label="Address Line 2"
                type="text"
                name="address_line2"
                size={"large"}

                readOnlyMode={!editAccess}
                component={AdaptedInput}
              />


              <Flex flexDirection={["column", "row"]}>
                <PrefixedField
                  placeholder={"City"}
                  label="City"
                  type="text"
                  name="city"
                  size={"large"}
                  required={true}

                  readOnlyMode={!editAccess}
                  component={AdaptedInput}
                />

                <PrefixedField
                  placeholder={"Zip Code"}
                  label="Zip Code"
                  type="number"
                  maxLength={10}
                  required={true}
                  name="zip_code"
                  readOnlyMode={!editAccess}
                  size={"large"}
                  component={AdaptedInput}
                />

                <PrefixedDropdown
                  label={"State"}
                  name={"state"}
                  placeholder={"State"}
                  options={stateOptions}
                />
              </Flex>
            </Flex>
          </FieldPrefix>
          <Field name={type} type={"hidden"} component={"input"}/>
          <Button htmlType={"submit"}>Submit</Button>
        </form>
      )}
    />
  );
};

AddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  id: PropTypes.string,
  editAccess: PropTypes.bool,
  errors: PropTypes.object,
  type: PropTypes.oneOf([CUSTOMER, SUPPLIER]),
};

AddressForm.defaultProps = {
  id: "address-form",
  type: CUSTOMER,
};
export default AddressForm;
