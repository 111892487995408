import styled from "@emotion/styled";
import {Box} from "@chakra-ui/core";

const Container = styled(Box)`
  max-width: 1024px;
`;
Container.defaultProps = {
  mx: "auto"
};

export default Container;
