import React from "react";
import {Link as BaseLink} from "@chakra-ui/core";
import styled from "@emotion/styled";
import {Link as ReachLink} from "react-router-dom";

// Specify to prop in Link
const CustomLink = props =>
  <BaseLink as={ReachLink} {...props}>
    {props.children}
  </BaseLink>;

const Link = styled(CustomLink)``;

Link.defaultProps = {
  color: "colorPrimary"
};

const DirectLink = styled(BaseLink)``;

DirectLink.defaultProps = {
  color: "colorPrimary"
};

export {Link, DirectLink};
