import React from "react";
import {Link} from "./Link";
import {Box} from "@chakra-ui/core";

const OptionalLink = props => {
  if (props.to !== undefined) {
    return (
      <Link {...props}>
        {props.children}
      </Link>
    );
  } else if (props.onClick !== false) {
    return (
      <Box {...props}>
        {props.children}
      </Box>
    );
  } else {
    return (
      <Box>
        {props.children}
      </Box>
    );
  }
};

export default OptionalLink;
