import RRule from "rrule";
import moment from "moment";

/**
 * Converts an RRule string to its corresponding English description
 * @param {string} rruleString The RRule string to describe
 * @returns {string} The English description of the RRule string
 */
const ruleStringToText = (rruleString) => {
  let ruleText = "";
  if (rruleString) {
    const rule = RRule.fromString(rruleString);
    ruleText = rule.toText();
  }
  return ruleText;
};

/**
 * Check if the provided RRule string matches today
 * @param {string} rruleStr The string to check
 * @param {moment.Moment} date Today
 * @returns {boolean} True if RRule string matches today
 */
function checkIfToday(rruleStr, date) {
  const rule = RRule.fromString(rruleStr);

  // console.log('date', date, rule)
  // Convert all dates into UTC before comparison
  // const todayutc = date.utc().startOf('day'); // today in UTC
  const todayutc = date.utc().startOf("day").toDate(); // today in UTC

  // const todayutc = date
  const nextOccurrence = rule.after(todayutc, true); // next rule date including today
  // console.log('nextOccurence', nextOccurrence)
  const nextOccurutc = moment(nextOccurrence).utc(); // convert today into utc
  // console.log('nextOccurutc', nextOccurutc)

  const match = moment(nextOccurutc).isSame(todayutc, "day"); // check if 'DAY' is same

  // console.log('match', match)
  return match;
}

export {ruleStringToText, checkIfToday};
