import {PM_ORDERS_DASHBOARD_ROUTE, SCHEDULED_PM_ORDERS_DASHBOARD_ROUTE} from "../routes/constants";

/**
 * Returns url and label for a database content type string
 * @param {string} type - content_type string returned by API
 * @returns {{label: string, url: string}|null} - Details page url and label
 */
export const contentTypeReducer = type => {
  switch (type) {
  case "scheduledpmorder":
    return {
      url: SCHEDULED_PM_ORDERS_DASHBOARD_ROUTE,
      label: "Scheduled PM Order"
    };
  case "pmorder":
    return {
      url: PM_ORDERS_DASHBOARD_ROUTE,
      label: "PM Order"
    };
  default:
    return null;
  }
};
