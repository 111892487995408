import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import IssueComment from "../Note/IssueComment";
import {Button, Box, Collapse, FlexCenter} from "../Base";
import theme from "../../../theme";
import moment from "moment";
import {isEmpty} from "lodash-es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const IssueBar = (props) => {
  const {
    id,
    description,
    created_at,
    updated_at,
    isOpen,
    submit,
    issue_comments,
    type,
    user_type,
    personnel,
    service_org,
    relative_id,
    isWindowLarge
  } = props;

  const [issueDescription, setIssueDescription] = useState(description);
  const [issueChanged, setIssueChanged] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isAddComment, setAddComment] = useState(false);
  const [issueComment, setComment] = useState("");
  const [isShowComments, setShowComments] = useState(false);

  useEffect(() => {
    if (!issueChanged && issueDescription !== description) {
      setIssueChanged(true);
    }
  }, [issueDescription, issueChanged, description]);

  const handleEdit = () => {
    if (isEdit) {
      setEdit(false);
      setIssueDescription(description);
      setIssueChanged(false);
    } else {
      setEdit(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (issueComment) {
      const reportedIssue = {issue: {comment: {issue_id: id, author_id: personnel.id, description: issueComment}}};
      const res = await submit(reportedIssue);
      if (res && !res.error) {
        setAddComment(false);
        setShowComments(true);
        setComment("");
      }
    }
  };

  const handleSubmitEdit = async () => {
    const reportedIssue = {issue: {id, description: issueDescription}};
    const res = await submit(reportedIssue);
    if (res && !res.error) {
      setEdit(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.id.includes("issue-edit")) {
      setIssueDescription(e.target.value);
    }
    else {
      setComment(e.target.value);
    }
  };

  const timeStamp = isOpen ? created_at : updated_at;
  const commentsCount = issue_comments ? issue_comments.length : 0;

  return (
    <Box key={id} display="block" alignItems="center" justifyContent="center" className={`issue-bar-${isOpen ? "open" : "closed"}`}>
      <div style={{textAlign: "center"}}>
        <span style={{textAlign: "center", fontSize: "20px"}}>Reported Issue{`  ${relative_id ? relative_id : ""}  `}</span>
        {type === "Service Provider" && (
          <FontAwesomeIcon
            style={{cursor: "pointer"}}
            icon="edit"
            size="1x"
            onClick={handleEdit}
            id={`order-issue-edit-${id}`}
          />
        )}
      </div>
      <hr className="issue-bar-hr" />
      {!isEdit ? <div className="issue-bar-description">{description}</div> : <br/>}
      <Collapse mt={4} isOpen={isEdit}>
        <Fragment>
          <textarea
            className="issue-bar-comment-input editable-input"
            id={`issue-edit-box-${id}`}
            value={issueDescription}
            onChange={handleChange}
          />
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection={(isWindowLarge ? "row" : "column")}>
            <Button
              className="issue-bar-btn"
              m={1}
              disabled={!issueChanged}
              theme={theme}
              onClick={handleSubmitEdit}
              gradient="blue">
                            Submit Edit
            </Button>
            <Button
              className="issue-bar-btn"
              m={1}
              disabled={false}
              theme={theme}
              onClick={handleEdit}
              gradient="blue">
                            Cancel
            </Button>
          </Box>
        </Fragment>
      </Collapse>
      <span className="issue-bar-comments-title">Comments{` (${commentsCount})   `}</span>
      {!isEmpty(issue_comments) && <FontAwesomeIcon
        icon={`caret-${isShowComments ? "down" : "up"}`}
        name="toggle-issue-comments"
        size="2x"
        style={{cursor: "pointer"}}
        onClick={() => setShowComments(!isShowComments)} />}
      <FontAwesomeIcon
        icon={"plus"}
        size="1x"
        name="add-comment"
        style={{cursor: "pointer", marginLeft: "5px"}}
        onClick={() => setAddComment(true)} />
      <Collapse mt={4} isOpen={isShowComments}>
        {issue_comments.map((comment, index) => {
          return (
            <IssueComment
              key={index}
              {...comment}
              personnel={personnel}
              submit={submit}
              service_org={service_org}
              isOpen={isOpen}
              issue_id={id} />
          );
        })}
      </Collapse>
      <Collapse mt={4} isOpen={(isAddComment && isOpen)}>
        <form onSubmit={handleSubmit}>
          <textarea
            className="issue-bar-comment-input"
            id={`comment-input-${id}`}
            value={issueComment}
            placeholder="Comment"
            onChange={handleChange} />
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection={(isWindowLarge ? "row" : "column")}>
            {issueComment !== "" && <Button
              className="issue-bar-btn"
              htmlType="submit"
              m={1}
              disabled={false}
              theme={theme}
              gradient="blue">
                            Submit Comment
            </Button>}
            <Button
              className="issue-bar-btn"
              onClick={() => {
                setComment("");
                setAddComment(false);
              }}
              m={1}
              disabled={false}
              theme={theme}
              gradient="orange">
                            Cancel
            </Button>
          </Box>
          <br />
        </form>
      </Collapse>
      {isOpen && <FlexCenter>
        {type === "Service Provider" && user_type === 1 && !isAddComment &&
                <Button
                  className="issue-bar-btn"
                  onClick={() => submit({"issue": {id, is_active: false}})}
                  m={1}
                  disabled={type !== "Service Provider" || user_type !== 1}
                  theme={theme}
                  gradient="green">
                    Mark As Resolved
                </Button>}
      </FlexCenter>}
      <div style={{fontSize: "12px"}}>{isOpen ? "Opened: " : "Closed: "}{moment(timeStamp).format("YYYY-MM-DD, h:mm:ss a")}</div>
    </Box>
  );
};

export default IssueBar;

IssueBar.propTypes = {
  id: PropTypes.number,
  relative_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  issue_comments: PropTypes.array,
  user_type: PropTypes.number,
  submit: PropTypes.func,
  isActiveIssue: PropTypes.bool,
  personnel: PropTypes.object,
  service_org: PropTypes.number,
  isWindowLarge: PropTypes.bool
};

IssueBar.defaultProps = {
  issues: [],
};
