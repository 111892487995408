import actionTyper from "redux-actiontyper";

const name = "personnel";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_SHIFTS_REQUEST,
  GET_ITEM_SHIFTS_SUCCESS,
  GET_ITEM_SHIFTS_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  SORT_LIST,
  REVERSE_LIST,

  // Personnel List View Actions
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CHANGE_VIEW_CALENDAR,

  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE,

  CLEAR_MESSAGES,

  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
  CANCEL_INVITATION_REQUEST,
  CANCEL_INVITATION_SUCCESS,
  CANCEL_INVITATION_FAILURE
} = actionTyper("@@personnel/");

export default {
  name,
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_SHIFTS_REQUEST,
  GET_ITEM_SHIFTS_SUCCESS,
  GET_ITEM_SHIFTS_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  SORT_LIST,
  REVERSE_LIST,

  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CHANGE_VIEW_CALENDAR,

  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE,

  CLEAR_MESSAGES,

  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
  CANCEL_INVITATION_REQUEST,
  CANCEL_INVITATION_SUCCESS,
  CANCEL_INVITATION_FAILURE
};
