const MAX_QUANTITY = 10000;
const DEFAULT_PAGE_SIZE = 100;
const statusOptions = [
  {id: 1, value: 1, label: "New"},
  {id: 2, value: 2, label: "In Process"},
  {id: 3, value: 3, label: "Issue Reported"},
  {id: 4, value: 4, label: "Fulfilled"},
];

export {MAX_QUANTITY, DEFAULT_PAGE_SIZE, statusOptions};
