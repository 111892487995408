import React from "react";
import PropTypes from "prop-types";
import {Button} from "Base";
import {FormDropdown} from "../../../../Common/Forms/Base";

import {Form} from "react-final-form";


const AssetForm = props => {
  const {assetList, onSubmit, currentSelected} = props;

  const options = assetList.map(item => ({
    id: item.id,
    label: item.label,
    options: item.stocks.filter(item => !currentSelected.includes(item.value))
  }));

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        // validate={validate}
        // initialValues={initialValues}
        render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
          <form className={"item-details-form"} onSubmit={handleSubmit}>
            <FormDropdown
              options={options}
              name="asset"
              label="ASSET"
              format={(value, name) => {
                if (value && typeof value === "number") {
                  return assetList.find(el => el.id === value);
                }
                return value ? {
                  value: value.id || value.value,
                  label: value.name || value.label,
                } : "";
              }}
              parse={(value, name) => {
                // console.log("parse value", value);
                return value ? value.id || value.value : "";
              }}
            />

            <Button type={"primary"} htmlType={"submit"} className={"submit-asset-button"}>Add</Button>
          </form>
        )}
      />
    </div>
  );
};

AssetForm.propTypes = {
  assetList: PropTypes.array,
  onSubmit: PropTypes.func,
  currentSelected: PropTypes.array
};

AssetForm.defaultProps = {
  assetList: []
};

export default AssetForm;
