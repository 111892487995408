import types from "./types";

import {filters, sort} from "../../utils";
import {baseReducer, getNumberOfPages, INITIAL_STATE, withCsv} from "../base/reducers";


const initialState = {
  ...INITIAL_STATE,
  types: [],
  filteringByType: null,
  personnel: {} // used for Vendor and PM Order forms to select personnel based on asset tags
};

/**
 * Function used to filter Assets by overdue or inspect_today
 * @param {string} filteringBy which filter is applied, '', 'issue_reported', 'inspect_today'
 * @param {Array} items an array of Assets
 * @returns {Array} All assets filtered by the argument
 */
const filteringByList = (filteringBy, items) => {
  if (filteringBy === "issue_reported") {
    return filters.filterListByStockroomReported(items);
  } else if (filteringBy === "inspect_today") {
    return items;
    // TODO inspect today filter for assets
    // return filters.filterListByDate(items, "first_scheduled_date", moment(), (a, b) => a.isSame(b, "day"));
  }
  return items;
};

/**
 * Function used to filter Assets by a specific type
 * @param {string} filteringByType Id of type of assets to show only
 * @param {Array} items an array of Assets
 * @returns {Array} All assets filtered by the specific type
 */
const filteringByTypeList = (filteringByType, items) => {
  if (filteringByType === null || filteringByType === undefined) {
    return items;
  }
  return items.filter((item) => item.type !== null && item.type.id === filteringByType);
};

/**
 * Function used to filter the assets by a generic and a type filter
 * @param {string} filteringBy which filter is applied, '', 'issue_reported', 'inspect_today'
 * @param {number} filteringByType By which type id to filter the assets
 * @param {Array} items an array of Assets
 * @returns {Array} All assets filtered by the generic and the type filter
 */
const applyFiltersBy = (filteringBy, filteringByType, items) => {
  return filteringByList(filteringBy, filteringByTypeList(filteringByType, items));
};

/**
 * From an array of assets returns an array of types that can be used by a dropdown
 * @param {Array} assets an array of Assets
 * @returns {Array} The types that will be used in the dropdown
 */
const getAssetTypes = (assets) => {
  const types = [{value: null, label: "Every Type"}];
  const ids = [];
  assets.forEach((value, index) => {
    if (value.type !== null && !ids.includes(value.type.id)) {
      // I push it to format it as the dropdown wants it in the filters
      // with objects having value and label
      types.push({value: value.type.id, label: value.type.name});
      ids.push(value.type.id);
    }
  });
  return types;
};

/* Main reducer for this duck. import into ../reducers.js and add to root reducer. */
const assetReducer = (state = initialState, action) => {
  switch (action.type) {
  // CRUD ACTIONS
  /* Sets items to payload returned from getAllItems in operations. */
  case types.GET_ALL_SUCCESS: {
    let newItems = [];
    /* If success = 0, action.assets.message is a string and not an array
      and must be handled differnetly. Check payload in redux-devtools for
      debugging issues like this. */
    newItems = action.payload;
    const data = {};
    newItems.forEach(item => data[item.id] = item);

    // Check any filterby
    const newList = applyFiltersBy(state.filteringBy, state.filteringByType, newItems);
    let filteredList = sort.sortByKey(filters.filterListByName(newList, state.filter), state.sortBy);
    if (filteredList.length === 0) {
      filteredList = sort.sortByKey(filters.filterListByManufacturer(newList, state.filter), state.sortBy);
    }
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      loading: false,
      items: action.payload,
      data,
      types: getAssetTypes(newItems),
      filterList: filteredList,
      pages,
      currentPage: 1,
      pageList: filteredList.slice(0, state.itemsPerPage),
      errors: {}
    };
  }
  case types.GET_ALL_FAILURE: {
    return {
      ...state,
      loading: false,
      errors: action
    };
  }
  case types.GET_ALL_NONE_FOUND: {
    return {
      ...state,
      loading: false,
      noneFound: true
    };
  }
  case types.GET_ITEM_SUCCESS: {
    const newData = {
      ...state.data,
      [action.payload.id]: action.payload
    };
    return {
      ...state,
      loading: false,
      data: newData,
    };
  }
  case types.GET_ITEM_FAILURE: {
    return {
      ...state,
      loading: false,
      errors: action
    };
  }

  case types.UPDATE_ITEM_FAILURE: {
    return {
      ...state,
      errors: action.payload
    };
  }

  case types.GET_ITEM_NOTES_SUCCESS: {
    const newNotes = {
      ...state.notes,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      notes: newNotes
    };
  }

  case types.GET_QUALIFIED_PERSONNEL_SUCCESS: {
    const newPersonnel = {
      ...state.personnel,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      personnel: newPersonnel
    };
  }
  /* Filters items in state by those that include the querry in item.name and item.tags. */
  case types.FILTER_LIST: {
    // Const newFilter = action.filter.split(' ');
    let newList = filters.filterListByAll(state.items, action.filter);
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filter: action.filter,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  /* Filters items in state by a field specified in the action payload, overdue or inspect_today. */
  case types.FILTER_LIST_BY: {
    const filteringBy = action.payload;
    // If filtering by is overdue or inspect_today filter otherwise do not filter the items.
    let newList = applyFiltersBy(filteringBy, state.filteringByType, state.items);
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filteringBy,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  /* Filters items in state by a field specified in the action payload, overdue or inspect_today. */
  case types.FILTER_LIST_BY_TYPE: {
    const filteringByType = action.payload;
    // If filtering by is overdue or inspect_today filter otherwise do not filter the items.
    let newList = applyFiltersBy(state.filteringBy, filteringByType, state.items);
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filteringByType,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  default:
    return state;
  }
};

export default withCsv(types, baseReducer(types, assetReducer));

