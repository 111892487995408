import states_list from "./states_titlecase.json";

/**
 * Returns a list of state options for a dropdown
 * mapped from states_titlecase.json
 * @returns {[{value: string, label: string}]} - list of state options
 */
export const getStateOptions = () => {
  return states_list.map(item => ({
    value: item.abbreviation,
    label: item.name,
  }));
};
