import {baseCsvFields, makeCsvColumn} from "../../../utils/csv";

export const csvColumns = [
  makeCsvColumn("name"),
  makeCsvColumn("manufacturer_name"),
  makeCsvColumn("purchased_date"),
  makeCsvColumn("description"),
  makeCsvColumn("purchase_price"),
  makeCsvColumn("purchase_currency"),
  makeCsvColumn("uptime"),
  makeCsvColumn("temperature"),
  makeCsvColumn("tags"),
  ...baseCsvFields
];

export const csvData = [
  {
    name: "ACS550",
    manufacturer_name: "ABB",
    purchased_date: "1/9/05",
    description: "AC Drive for Wind Turbine",
    tags: "\"acs550, abb, ac drive\""
  },
  {
    name: "ACS800",
    manufacturer_name: "ABB",
    purchased_date: "10/19/95",
    description: "AC Drive for Wind Turbine",
    tags: "\"acs800, abb, ac drive\""
  }
];

export const selectionOptions = [
  {value: "name", label: "Name"},
  {value: "manufacturer_name", label: "Manufacturer"},
  {value: "tags", label: "Tags"},
  {value: "type", label: "Type"},
  {value: "notes", label: "# Notes"},
  {value: "stockroom_assets", label: "# Assets"},
  {value: "created_at", label: "Created At"},
  {value: "updated_at", label: "Updated At"}
];

/**
 * Filters options for assets
 */
export const filterOptions = [
  {value: "", label: "All"},
  {value: "issue_reported", label: "Issue Reported"},
  // {value: "inspect_today", label: "Inspect Today"},
];
