import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {ItemText} from "../../Common/Bars/styled";
import chroma from "chroma-js";

const Details = props => {
  const {
    baseClassName,
    assetData,
  } = props;
  const {stockroom_assets} = assetData;
  let stockroomRecords = [];
  let styles = {};

  if (assetData.type !== null) {
    styles["backgroundColor"] = assetData.type.color;
    styles["color"] = chroma.contrast(assetData.type.color, "white") > 3 ? "white" : "black";
  } else {
    styles["color"] = "white";
  }

  if (stockroom_assets) {
    if (stockroom_assets.length > 2) {
      stockroomRecords = stockroom_assets.slice(0, 2);
    } else {
      stockroomRecords = assetData.stockroom_assets;
    }
  }

  return (
    <div className={`${baseClassName}-detail`} style={styles}>
      {(stockroomRecords.length > 0)
        ? <Fragment><ItemText style={{"textDecoration": "underline"}}>Stockroom Assets</ItemText>
          {stockroomRecords.map((record, index) => {
            if (index < 5) return <ItemText key={record.id}>{record.name || "STOCKROOM RECORD "}</ItemText>;
            else return false;
          })}
        </Fragment>
        : <ItemText>No Stockroom Assets</ItemText>}
    </div>
  );
};

Details.propTypes = {
  baseClassName: PropTypes.string,
  assetData: PropTypes.object
};

export {Details};
