import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {AddItemModal, ButtonBar, HelpButton, ToolBar, TutorialModal} from "../../../Common";
import {PalletBar} from "../Bar/PalletBar";
import {Loading} from "../../../Common/Base";
import {selectionOptions} from "../../../../containers/Warehouse/WarehouseContainer/constants";
import {checkNestedKeys} from "../../../../utils/propUtils";
import BannerOne from "../../../../static/img/BannerOne.png";
import {palletsHelp} from "../../../Common/HelpItems/constants";
import {scrollToTop} from "../../../../utils/containerUtils";

class Pallets extends Component {
  constructor() {
    super();
    this.state = {
      showAddItemModal: false,
      showTutFromHelp: false,
      showTutorialModal: true,
      addItemModalSubmitting: false,
      addItemModalError: false,
      addedPalletID: "",
    };
  }

  componentDidMount = () => {
    const {
      accountInfo,
      getOrg,
      getAllPalletDetails,
      getLocations,
    } = this.props;

    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);

    if (orgId) {
      this.setState({
        orgID: orgId,
      });
      getOrg(orgId);
      getLocations(orgId);
      getAllPalletDetails();
    }
    scrollToTop();
  };

  handleSearch = e => {
    e.preventDefault();
    const {filterPallets} = this.props;
    filterPallets(e.target.value);
  };

  updateSortBy = e => {
    const {sortPallets} = this.props;
    sortPallets(e.value);
  };

  handleSubmitAddItem = async (key = "", value = "") => {
    const {accountInfo} = this.props;
    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false,
    });
    const dataToSubmit = {
      org: accountInfo.personnel ? accountInfo.personnel.orgs[0].id : null,
    };
    if (key !== "" && value !== "") {
      dataToSubmit[key] = value;
    }
    console.log("DATA TO SUBMIT: ", dataToSubmit);
    let res = await this.props.createPallet(dataToSubmit);
    this.setState({addedPalletID: res.id, addItemModalSubmitting: false});

    console.log("RES: ", res);
  };

  handleCloseAddItemModal = () => {
    this.setState({
      showAddItemModal: false,
    });
  };

  handleTutFromButton = () => {
    this.setState({
      showTutFromHelp: !this.state.showTutFromHelp,
    });
  };

  handleCloseTutorialModal = () => {
    this.setState({
      showTutorialModal: false,
      showTutFromHelp: false,
    });
  };

  handleOpenAddItemModal = () => {
    this.setState({
      showAddItemModal: true,
    });
  };


  render() {
    const {
      addItemModalError,
      addItemModalSubmitting,
      showAddItemModal,
      showTutFromHelp,
      showTutorialModal,
      addedPalletID,
    } = this.state;
    const {
      loading,
      nextPage,
      prevPage,
      currentPage,
      listView,
      tileView,
      tableView,
      pageNum,
      isLastPage,
      changeViewList,
      changeViewTile,
      changeViewTable,
      filter,
      reverseList,
      isReversed,
      locationList,
    } = this.props;
    if (addedPalletID) {
      return <Redirect to={`/dashboard/warehouse/pallets/${addedPalletID}`}/>;
    }
    return (
      <div>
        {!loading && <ToolBar
          handleChange={this.handleSearch}
          updateSortBy={this.updateSortBy}
          nextPage={nextPage}
          prevPage={prevPage}
          pageNum={pageNum}
          changeViewList={changeViewList}
          changeViewTile={changeViewTile}
          changeViewTable={changeViewTable}
          listView={listView}
          tileView={tileView}
          tableView={tableView}
          isLastPage={isLastPage}
          selectionOptions={selectionOptions}
          filter={filter}
          reverseList={reverseList}
          isReversed={isReversed}
          handleAddItemButtonClick={this.handleOpenAddItemModal}
          objectType="Pallet"
          hasTableView={false}
          // withPagination={false}
        />
        }
        <div className="row">
          <div className={(listView) ? "items-flex-row" : "items-flex-column"}>
            {loading &&
            <Loading title={"Loading Pallets..."}/>}
            {!loading &&
            <div>
              <div data-tip="Open a help popup" data-delay-show="1000">
                <HelpButton handleClick={this.handleTutFromButton}/>
              </div>
              <div data-tip="Build a Pallet." data-delay-show="1000">
                <ButtonBar handleClick={this.handleOpenAddItemModal}/>
              </div>
            </div>
            }
            { (listView || tileView) && currentPage.length > 0
            && currentPage.map((pallet) => (
              <PalletBar
                key={pallet.id}
                data={pallet}
                listView={listView}
                locationList={locationList}
              />
            ))
            }
            {/* tableView && allPalletsInfo
              ? <PalletTable
                palletData={allPalletsInfo}
                createNewPallet={this.handleSubmitAddItem}
              />
              : null
            */}
          </div>
        </div>

        <TutorialModal
          visible={(currentPage.length === 0 && showTutorialModal && !loading) || (showTutFromHelp)}
          closeModal={this.handleCloseTutorialModal}
          banner={BannerOne}
          type="Pallet"
          descriptions={palletsHelp}
          handleOpenAddItemModal={[this.handleOpenAddItemModal, "Create One!"]} />

        <AddItemModal
          visible={showAddItemModal}
          itemName="Pallet"
          closeAddItemModal={this.handleCloseAddItemModal}
          submitAddItem={this.handleSubmitAddItem}
          addItemSubmitting={addItemModalSubmitting}
          addItemError={addItemModalError}
        />
      </div>
    );
  }
}

export default Pallets;
