import React from "react";
import PropTypes from "prop-types";
import {InventoryTable} from "../../../Table";
import {MATERIAL_INVENTORY} from "../../../utils/constants";

const PalletProductTable = ({items}) => {
  return (
    <InventoryTable
      items={items}
      type={MATERIAL_INVENTORY}
    />
  );
};

PalletProductTable.propTypes = {
  items: PropTypes.array,
};

export default PalletProductTable;
