import React, {useState} from "react";
import PropTypes from "prop-types";
import {FormDrawer} from "../../../Common/Drawers";
import {OrderPalletForm} from "../../Forms";
import {validate} from "./utils";
import {formatInventoryForOrderPallet} from "../../utils/inventory";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY} from "../../utils/constants";
import {getContactSource} from "../../utils/orders";

const OrderPalletDrawer = props => {
  const {
    onSubmit,
    values,
    formProps,
    orderDetails,
    formatEdit,
    formatSubmit,
    createAddress,
    addButtonDisabled
  } = props;

  const [selectedMaterials, setSelectedMaterials] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [valid, setIsValid] = useState(true);

  const resetState = () => {
    setSelectedProducts({});
    setSelectedMaterials({});
  };
  const handleSubmit = async (values) => {
    const formattedInventory = formatSubmit({materials: selectedMaterials, products: selectedProducts});
    const submitData = {
      pallet: values,
      ...formattedInventory,
    };
    const isValid = validate(submitData, orderDetails);
    if (isValid) {
      resetState();
      return onSubmit(submitData);
    } else {
      console.log("not valid");
      return ({error: true});
    }
  };

  const handleEditQuantity = (values, type) => {
    let materials = selectedMaterials;
    let products = selectedProducts;
    if (type === PRODUCT_INVENTORY) {
      products = {
        ...selectedProducts,
        [values.id]: values,
      };
      setSelectedProducts(products);
    }
    if (type === MATERIAL_INVENTORY) {
      materials = {
        ...selectedMaterials,
        [values.id]: values,
      };
      setSelectedMaterials(materials);
    }
    const formattedInventory = formatSubmit({materials, products});
    const isValid = validate(formattedInventory, orderDetails);

    if (!isValid) {
      setIsValid(false);
    }
    if (isValid) {
      setIsValid(true);
    }
  };

  const formConfig = {
    orderDetails: orderDetails,
    onEdit: handleEditQuantity,
    formatEditValues: formatEdit,
    createAddress: createAddress,
    ...formProps,
  };
  const {orderType} = formProps;


  const contactType = getContactSource(orderType);
  return (
    <FormDrawer
      onSubmit={handleSubmit}
      FormComponent={OrderPalletForm}
      values={values}
      text={{
        title: "New Pallet",
        tooltip: "Assign order items to a pallet.",
        openButton: "Add Pallet",
      }}
      formID={"order-pallet-form"}
      buttonConfig={{
        type: "primary",
        ariaLabel: "open new pallet form",
        size: "small",
        style: {marginLeft: "10px"},
        disabled: addButtonDisabled,
        tooltip: `Select inventory and a ${contactType} to add pallets to order.`,
      }}
      submitClassname={"save-pallet-button"}
      formConfig={formConfig}
      valid={valid}
    />
  );
};

OrderPalletDrawer.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.func,
  formProps: PropTypes.object,
  orderDetails: PropTypes.object,
  formatEdit: PropTypes.func,
  formatSubmit: PropTypes.func,
  createAddress: PropTypes.func.isRequired,
  contactType: PropTypes.string,
  addButtonDisabled: PropTypes.bool
};

OrderPalletDrawer.defaultProps = {
  formatEdit: (item, values) => {
    console.log("order drawer item", item);
    console.log("order drawer item", values);

    return ({id: item.id, quantity: values.quantity});
  },
  formatSubmit: formatInventoryForOrderPallet,
  contactType: "customer",
  addButtonDisabled: false
};

export default OrderPalletDrawer;
