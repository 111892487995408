import styled from "@emotion/styled";
import {space} from "styled-system";
import {Box, Image, Link} from "../../Base";

export const DropdownWrapper = styled(Box)`
  z-index: 10000;
  position: absolute;
  background-color: white;
  max-height: 100%;
  overflow: auto;
  top: 55px;
  border: 1px solid grey;
  box-shadow: 3px 3px 5px grey;
  padding: 15px;
  border-radius: 5px;
  min-width: 200px;
`;
DropdownWrapper.defaultProps = {
  maxWidth: "350px",
  right: ["0%", "5%", "10%"]
};

export const CloseWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const LogLink = styled(Link)`
display: block;
font-size: 1.2em;
width: 100%;
cursor: ${props => props.cursor};
border: 1px solid lightgrey;
box-shadow: 1px 1px 2px grey;
border-radius: 3px;
text-decoration: none;
color: black;

&:hover {
  text-decoration: none;
}
${space}
`;

LogLink.defaultProps = {
  p: "6px",
  mb: "10px"
};

export const LogImage = styled(Image)`
  height: 40px;
  ${space}
`;
LogImage.defaultProps = {
  m: "0 15px 0 5px"
};
