import React from "react";
import PropTypes from "prop-types";
import ShiftListItem from "./ShiftListItem";
import {List} from "@material-ui/core";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

/*
Renders a list of Shifts
 */
const ShiftList = props => {
  const {items, handleItemClick, classes, current} = props;

  const handleClick = (item) => {
    console.log("edit schedule item", item);
    handleItemClick(item);
  };


  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      {items.length !== 0 &&
      <List className={classes.list}>
        {items.map(item => {
          return (
            <ShiftListItem
              key={item.id}
              item={item}
              onClick={handleClick}
              classes={classes}
              current={current}
            />
          );
        })}
      </List>
      }
    </ReactCSSTransitionGroup>
  );
};

ShiftList.propTypes = {
  items: PropTypes.array,
  classes: PropTypes.any,
  handleItemClick: PropTypes.func
};

export default ShiftList;
