import React, {Fragment} from "react";
import {AntdCollapse, Heading, List, Panel, Box} from "Base";
import {crudActions, paginationProps} from "../../../../types/propTypes";

/**
 * A form showing the productions lines, meant to be used in the admin panel
 * @param {object} props The props
 * @returns {ProductionLineForm} The form
 */
const ProductionLineForm = ({pagination, productionLineActions}) => {
  const {
    itemsPaged,
    loading, // can be used as loading boolean
    itemsCount,
    errored,
    pageSize
  } = pagination;

  const renderItem = item => {
    return (
      <List.Item>
        <List.Item.Meta
          title={<Box><b>Name:</b> {item.name || "No Name"}</Box>}
          description={`Assets: ${item.assets.length}, Products: ${item.products.length}`}
        />
        {(item.assets.length !== 0 || item.products.length !== 0) &&
        <AntdCollapse>
          <Panel header="View Details" key={0}>
            {item.assets.length !== 0 &&
            <Fragment>
              <Heading>Assets</Heading>
              <List
                itemLayout={"vertical"}
                dataSource={item.assets}
                renderItem={item => <p><b>Name: </b> {item.name || "No Name"}</p>}/>
            </Fragment>
            }
            {item.products.length !== 0 &&
            <Fragment>
              <Heading pt={"10px"}>Products</Heading>
              <List
                itemLayout={"vertical"}
                dataSource={item.products}
                renderItem={item => <p><b>Name: </b> {item.name || "No Name"}</p>}/>
            </Fragment>
            }
          </Panel>
        </AntdCollapse>
        }
      </List.Item>
    );
  };

  return (
    !errored ?
      <List
        itemLayout={"vertical"}
        className={"production-line-list"}
        pagination={{
          onChange: page => {
            productionLineActions.getPage(page);
          },
          total: itemsCount,
          showTotal: (total) => `Total ${total} items`,
          pageSize: pageSize,
          position: "top",
        }}
        loading={loading && !errored}
        dataSource={itemsPaged}
        renderItem={renderItem}
      />
      : <b>Something went wrong</b>
  );
};

ProductionLineForm.propTypes = {
  pagination: paginationProps.isRequired,
  productionLineActions: crudActions.isRequired,
};

export default ProductionLineForm;

