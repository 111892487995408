import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import {Text} from "../../../Common/Base";
import SelectingMaterialListItem from "./SelectingMaterialListItem";

const renderHeader = (count, fromOrder) => {
  if (fromOrder) {
    return (
      <Text color={"black"}>{` ${count} Materials on Order`}</Text>
    );
  }
  return <Text color={"black"}>{` ${count} Materials Selected`}</Text>;
};

/*
This component is for selecting material quantities to perform actions with
 */
const SelectingMaterialList = ({data, onEdit, onRemove, fromOrder, maxField}) => {
  const count = data.length;

  return (
    <List
      className={"select-material-list"}
      dataSource={data}
      header={renderHeader(count, fromOrder)}
      renderItem={item =>
        <SelectingMaterialListItem
          key={item.id}
          item={item}
          onEdit={onEdit}
          onRemove={onRemove}
          fromOrder={fromOrder}
          maxField={maxField}
          onSelect={(values) => console.log("selected", values)}
        />
      }
    />
  );
};

SelectingMaterialList.propTypes = {
  data: PropTypes.array,
  fromOrder: PropTypes.bool,
  maxField: PropTypes.string,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
};

SelectingMaterialList.defaultProps = {
  data: [],
  fromOrder: false,
};

export default SelectingMaterialList;
