/* Returns a list of items formatted {id, value} to be used in AutocompleteInput */
function refreshNameList(state) {
  if (state.items) {
    return state.items.map(personnel => ({
      id: personnel.id,
      value: personnel.id,
      label: personnel.name || "Unnamed",
      user_type: personnel.user ? personnel.user.user_type : 6
    }));
  }
  return [];
}


const refreshShiftList = (state, id) => {
  if (state.shifts && state.shifts[id]) {
    return state.shifts[id];
  }
  return [];
};

export default {
  refreshNameList,
  refreshShiftList
};
