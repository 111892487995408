import React from "react";
import {ScorecardDashboard} from "../../../Common/Dashboard/ScorecardDashboard";
import {summaries} from "./data";

const MaintenanceScorecard = props => {
  const {summary} = props;

  return (
    <ScorecardDashboard
      title="Maintenance Scorecard"
      summaries={summaries(summary)}
    />
  );
};

export {MaintenanceScorecard};
