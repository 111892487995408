import React from "react";
import PropTypes from "prop-types";
import {Button as BaseButton, Popconfirm} from "antd";

const PopConfirmButton = ({Button, title, placement, onConfirm, children, popConfirmOptions, className, ...rest}) => {
  const okButtonProps = {
    className: `${className}-ok`,
  };
  const cancelButtonProps = {
    className: `${className}-cancel`,
  };
  return (
    <Popconfirm
      placement={placement}
      title={title}
      onConfirm={onConfirm}
      okText="Yes"
      cancelText="No"
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      {...popConfirmOptions}
    >
      <Button className={className} {...rest}>
        {children}
      </Button>
    </Popconfirm>
  );
};

PopConfirmButton.propTypes = {
  title: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  placement: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  popConfirmOptions: PropTypes.object,
  className: PropTypes.string,
  Button: PropTypes.any
};

PopConfirmButton.defaultProps = {
  title: "Are you sure you want to do this?",
  placement: "topLeft",
  okText: "Yes",
  cancelText: "No",
  popConfirmOptions: {},
  className: "pop-confirm-button",
  Button: BaseButton
};

export default PopConfirmButton;
