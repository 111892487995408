import types from "./types";
import {stockroomEndpoint as endpoint} from "./constants";
import base from "../base/actions";
import {stockroomBarcodes} from "./reducers";

/* Standard fetch actions. */
const getAllSuccess = data => ({
  type: types.GET_ALL_SUCCESS,
  payload: data
});

const getAllFailure = error => ({
  type: types.GET_ALL_FAILURE,
  payload: error
});

/* Fetches a single item from the api */
const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

const getItemPriceHistory = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/history/`,
  types: [
    types.GET_ITEM_PRICE_HISTORY_REQUEST,
    {
      type: types.GET_ITEM_PRICE_HISTORY_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_PRICE_HISTORY_FAILURE
  ]
});

const getItemStatusHistory = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/status_history/`,
  types: [
    types.GET_ITEM_STATUS_HISTORY_REQUEST,
    {
      type: types.GET_ITEM_STATUS_HISTORY_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_STATUS_HISTORY_FAILURE
  ]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/?expand=status`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});

const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

const getAllItems = () => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const uploadCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}csv/${org}/`,
  headers: {},
  body: data,
  types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE]
});

/* Update item actions. */

/* SimplePagination actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

/* View related actions. */
const changeViewList = () => ({
  type: types.CHANGE_VIEW_LIST
});

const changeViewTile = () => ({
  type: types.CHANGE_VIEW_TILE
});

const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export default {
  ...stockroomBarcodes.actions,
  getItem,
  getAllSuccess,
  getAllFailure,
  getAllItems,
  getItemPriceHistory,
  getItemStatusHistory,
  getItemNotes,
  submitNote,
  updateItem,
  uploadCsv,
  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  changeViewList,
  changeViewTile,

  clearMessages
};
