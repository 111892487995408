import React from "react";
import {Flex} from "rebass";
import {PositiveNumberField} from "./Base";
import {antAdapt} from "./Base/utils";
import {Input} from "antd";
import {Title} from "../Base";
import ShippingDimensions from "./ShippingDimensions";

const AdaptedInput = antAdapt(Input);

const InventoryShippingDimensions = ({editAccess}) => {
  return (
    <>
      <Title level={3}>Shipping Dimensions</Title>
      <Flex flexDirection={["column", "row"]}>
        <ShippingDimensions editAccess={editAccess}/>
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <PositiveNumberField
          component={AdaptedInput}
          label={"Weight"}
          name={"weight"}
          isDisabled={!editAccess}
          size={"large"}
          precision={2}
        />
        <PositiveNumberField
          component={AdaptedInput}
          label={"Max quantity per shipping unit"}
          name={"volume"}
          isDisabled={!editAccess}
          size={"large"}
          precision={2}
        />
      </Flex>
    </>
  );
};

InventoryShippingDimensions.propTypes = {};

export default InventoryShippingDimensions;
