import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import {Text} from "../../../Common/Base";
import SelectingInventoryListItem from "./SelectingInventoryListItem";
import {capitalize} from "lodash-es";
import {PRODUCT_INVENTORY, MATERIAL_INVENTORY} from "../../utils/constants";
/*
This component will render an inventory list of type material or product
Used in SelectInventoryDrawer
 */
const SelectingInventoryList = (props) => {
  const {
    data,
    title,
    fromOrder,
    onEdit,
    onRemove,
    maxField,
    type,
    formatEditValues
  } = props;
  const count = data.length;

  let header = <Text color={"black"}>{` ${count} ${capitalize(type)}s Selected`}</Text>;

  if (title) {
    header = title;
  }

  // const returnListItem = () => {
  //   if (type === "material") {
  //     return SelectingMaterialListItem;
  //   }
  //   return SelectingInventoryListItem;
  // };
  //
  // let ListItem = returnListItem();
  return (
    <List
      className={`select-${type}-list`}
      dataSource={data}
      header={header}
      renderItem={item =>
        <SelectingInventoryListItem
          key={item.id}
          item={item}
          onEdit={onEdit}
          onRemove={onRemove}
          maxField={maxField}
          fromOrder={fromOrder}
          type={type}
          onSelect={(values) => console.log("selected", values)}
          formatEditValues={formatEditValues}
        />
      }
    />
  );
};

SelectingInventoryList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  fromOrder: PropTypes.bool,
  maxField: PropTypes.string,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  type: PropTypes.oneOf([PRODUCT_INVENTORY, MATERIAL_INVENTORY]),
  formatEditValues: PropTypes.func
};

SelectingInventoryList.defaultProps = {
  data: [],
  fromOrder: false,
  maxField: "available_quantity",
  type: PRODUCT_INVENTORY,
  formatEditValues: (item, values) => ({id: item.id, quantity: values.quantity})
};
export default SelectingInventoryList;
