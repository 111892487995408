import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {CsvDownload} from "../Base";
import {Box, Button, ErrorText, Flex, Heading, Text} from "../../Base";

import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(FilePondPluginFileValidateType);


const CsvUploadForm = props => {
  const {columns, datas, csvFile, loadCsvFile, handleUploadCsv, name, messages, helpText, noHeader, externalSubmit, children} = props;

  const handleAddFile = (error, fileItem) => {
    if (!error) {
      loadCsvFile(fileItem.file);
    }
    if (externalSubmit) {
      let formData = new FormData();
      formData.append("file", fileItem.file);
      handleUploadCsv(formData);
    }
    // if(externalSubmit){
    //   handleSubmit()
    // }
  };

  return (
    <Flex flexWrap={"wrap"} flexDirection={"column"} className="csv-upload-form">

      {!noHeader &&
      <Heading size="2xl" className="modal-card-header">Add {name}s</Heading>
      }
      <section className="container">
        <FilePond
          className="csv-file-form"
          acceptedFileTypes={["text/csv"]}
          {...csvFile}
          onaddfile={handleAddFile}
          onremovefile={() => loadCsvFile(null)}/>

        {children}
        <h3>Accepted date formats: YYYY-MM-DD, MM/DD/YY</h3>
        <h3>Tag fields must be wrapped in &quot; &quot; with a {"'"},{"'"} after each tag:</h3>
        <h3> &quot;Tag 1, Tag 2&quot;</h3>
        {helpText.map((text, i) => <h3 key={i}>{text}</h3>)}
      </section>

      <CsvDownload columns={columns} datas={datas} filename={name} bom={false}>
        <Button className="modal-card-button">Download Blank CSV</Button>
      </CsvDownload>

      {Array.isArray(messages) ?
        <Flex flexDirection={"column"}>
          {/* If the message is just an array with a string, then it is probably an issue with the barcode*/}
          {typeof (messages[0]) === "string" ?
            <ErrorText>{messages[0]}</ErrorText>
            :
            <Fragment>
              <Box m={1}>
                {messages[0].map((message, i) => <Text key={i} color={i === 0 ? "green.500" : "black"}>{message}</Text>)}
              </Box>
              <Box m={1}>
                {messages[1].map((message, i) =>
                  <Fragment key={i}>
                    {typeof message !== "string" ?
                      <Flex flexDirection={"column"}>
                        <ErrorText>{message[0]}</ErrorText>
                        <Box>
                          {Object.keys(message[1]).map(function (item, j) {
                            return <Text fontSize="sm" textAlign="left" key={j}>{item} : {message[1][item]}</Text>;
                          })}
                        </Box>
                      </Flex>
                      :
                      <Text color={i === 0 ? "red.500" : "black"}>{message}</Text>
                    }
                  </Fragment>
                )}
              </Box>
            </Fragment>
          }
        </Flex>
        : (typeof messages === "object" && messages !== null) &&
        <Text color={"red.500"}>{messages?.detail ?? "An unknown error occurred"}</Text>
      }
    </Flex>
  );
};

CsvUploadForm.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.array,
  datas: PropTypes.array,
  handleUploadCsv: PropTypes.func,
  messages: PropTypes.any,
  helpText: PropTypes.array,
  externalSubmit: PropTypes.bool,
  noHeader: PropTypes.bool,
  csvFile: PropTypes.object,
  loadCsvFile: PropTypes.func
};

CsvUploadForm.defaultProps = {
  name: "Sample",
  columns: [
    {
      id: "name",
      displayName: "name"
    },
    {
      id: "description",
      displayName: "description"
    }
  ],
  helpText: [],
  externalSubmit: false,
  noHeader: false
};
export default CsvUploadForm;
