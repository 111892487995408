import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormField} from "../Base";

import {SubmitButton} from "../styled";

const OwnershipInfo = props => {
  const {
    handleSubmit,
    validate,
    ownership_type,
    ownership_name
  } = props;

  const initialValues = {
    ownership_type,
    ownership_name
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        pristine,
        invalid,
        submitting,
        values
      }) => (
        <form className={"item-details-form serv-prov-info"} onSubmit={handleSubmit}>
          <FormField
            label="Type of Ownership"
            placeholder="Type"
            type="text"
            name="ownership_type"
          />
          <FormField
            label="Ownership Name"
            placeholder="Name"
            type="text"
            name="ownership_name"
          />
          <br/>
          <SubmitButton className="item-details-form-button" loading={submitting}>Submit</SubmitButton>
        </form>
      )
      }
    />
  );
};

OwnershipInfo.propTypes = {
  handleSubmit: PropTypes.func,
  validate: PropTypes.func
};

OwnershipInfo.defaultProps = {
  validate: (values) => null
};

export default OwnershipInfo;
