import {WAREHOUSE_NAV, PRODUCTION_NAV, PM_ORDER_NAV, LOCATION_NAV} from "../../../routes/routes";

/**
 * This returns the list of active side menu items
 * @param {{}} location - React router location prop
 * @param {[]} sideMenu - side menu array
 * @returns {[]} - list of active keys
 */
export const getSelectedKeys = (location, sideMenu) => {
  let result = [];
  const {pathname, search} = location;
  const fullPath = `${pathname}${search.split("&")[0]}`;

  if (sideMenu && sideMenu.menu) {
    const menu = sideMenu.menu;

    for (let i in menu) {
      let item = menu[i];
      // const exa
      // search param is split to lny
      const exactMatch = item?.exact ?? false;
      if (!exactMatch && fullPath.startsWith(item.to)) {
        result.push(item.key);
      } else if (fullPath === item.to) {
        result.push(item.key);
      }
      if (item.submenu) {
        let items = item.items;
        for (let j in items) {
          let subItem = items[j];
          if (fullPath.includes(subItem.to) || fullPath === subItem.to) {
            result.push(subItem.key);
            // Make sure parent menu is also active
            if (!result.includes(item.key)) {
              result.push(item.key);
            }
          }
        }
      }
    }
  }
  return result;
};

export const navReducer = location => {
  const {pathname} = location;

  const key = pathname.split("/")[2];
  switch (key) {
  case "warehouse":
    return {menu: WAREHOUSE_NAV, label: "Warehouse"};
  case "production":
    return {menu: PRODUCTION_NAV, label: "Production"};
  case "orders":
    return {menu: PM_ORDER_NAV, label: "PM Orders"};
  case "locations":
    return {menu: LOCATION_NAV, label: "Locations"};
  default:
    return null;
  }
};
