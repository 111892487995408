import React from "react";
import PropTypes from "prop-types";
import {Link, Menu, SubMenu, Sider} from "Base";
import {AppstoreOutlined, SolutionOutlined, SettingOutlined, CompassOutlined, TagOutlined} from "@ant-design/icons";
import {TruckIcon, BookIcon} from "Base/Icons";


const renderIcon = (icon) => {
  let Component = AppstoreOutlined;
  if (icon === "upload") Component = TruckIcon;
  if (icon === "store") Component = AppstoreOutlined;
  if (icon === "product") Component = BookIcon;
  if (icon === "solution") Component = SolutionOutlined;
  if (icon === "settings") Component = SettingOutlined;
  if (icon === "compass") Component = CompassOutlined;
  if (icon === "tag") Component = TagOutlined;
  return (
    <Component/>
  );
};
const Side = ({menu, selected, collapsed, toggle, isCollapsable}) => {
  const handleClick = () => {
    // if (isCollapsable) {
    //   toggle();
    // }
  };

  const renderMenuItem = (item) => {
    const {key, title, items} = item;
    if (item.submenu) {
      return (
        <SubMenu
          className={`navigation-submenu-item ${key}-navigation`}
          key={item.key}
          icon={renderIcon(item.icon)}
          title={item.title}
        >
          {items.map(({key, title, to}) => (
            <Menu.Item
              className={`navigation-submenu-item-dropdown ${key}-navigation`}
              key={key}
              onClick={handleClick}
            >
              <Link to={to}>{title}</Link>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }
    return (
      <Menu.Item
        className={`navigation-submenu-item ${key}-navigation`}
        key={key}
        title={title}
        icon={renderIcon(item.icon)}>
        <Link to={item.to}><span>{title}</span></Link>
      </Menu.Item>
    );
  };

  return (
    <Sider
      // collapsedWidth="20"
      breakpoint="lg"
      collapsible
      // trigger={null}
      // collapsed={collapsed}
      width={200}
      className="site-layout-sider"
      onBreakpoint={broken => {
        if (broken && !collapsed) toggle();
        if (!broken && collapsed) toggle();
      }}
      onCollapse={(collapsed, type) => {
      }}
    >
      <Menu
        mode="inline"
        selectedKeys={selected}
        defaultOpenKeys={selected}
        // defaultSelectedKeys={["1"]}
        // defaultOpenKeys={["sub1"]}
        style={{height: "100%", borderRight: 0}}
      >
        {menu.map(item => (
          renderMenuItem(item)
        ))}

      </Menu>
    </Sider>
  );
};

Side.propTypes = {
  menu: PropTypes.array,
};


export default Side;
