import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import {DetailsCloseIcon, ErrorHeading, Flex} from "../../Common/Base";
import {AssetForm, TagInput} from "../../Common/Forms";
import {AttachedFiles, Container, NoteSection} from "../../Common";
import StockroomAsset from "./StockroomAsset/StockroomAsset";
import {baseDetailsState, checkForResponseError, handleErrorResponse, scrollToTop} from "../../../utils/containerUtils";
import {
  AZURE_ASSET_PREFIX,
  AZURE_BLOB_CONTAINER_NAME,
  handleChangeDisplayImage,
  handleChangeWarrantyDocument,
  listBlobsInAzureContainer,
  removeDocument,
  uploadBlobsToAzureWithFileNameCheck,
} from "../../../utils/azure";
import AdminControls from "./AdminControls";
import {cloneDeep} from "lodash-es";
import {DetailsHeader, DetailsPaneLeft, DetailsPaneRight} from "Common/Details/Layout";
import {Button, Heading, Loading} from "Common/Base";
import {ImageWithDefault} from "../../Common/Details/Layout";

/* This presents the details for a particular asset. The imported item ID is acquired via the URL. */
class AssetDetails extends Component {
  constructor(props) {
    super(props);
    this.assetID = props.match.params.assetID;
    this.state = {
      selectedAssetType: "",
      selectedAssetTypeTemplate: null,
      assetTags: [],
      stockroomAssets: [],
      newAssetType: {
        name: "",
        color: "#2c3655",
        id: ""
      },
      ...baseDetailsState("asset")
    };
  }

  /* Upon mounting, fetch data for the specific asset by assetID and store the data in state. */
  componentDidMount() {
    const {getOrg, getAssetTypes} = this.props;
    this.fetchAssetDetails().then(() => {
      if (this.state.assetOrgID) {
        getOrg(this.state.assetOrgID);
        getAssetTypes(this.state.assetOrgID);
      }
    });
    scrollToTop();
  }

  /* This function will handle the fetching of asset details. */
  fetchAssetDetails = async () => {
    const {fetchDetails} = this.props;
    return fetchDetails(this.assetID)
      .then(res => {
        console.log("fetch asset details return", res);
        checkForResponseError(res);
        let data = res.payload;
        let newState = {
          submitting: true,
          assetData: data,
          assetID: data.id,
          assetUUID: data.UUID || data.uuid,
          assetName: data.name || "",
          assetOrgID: data.org.id || "",
          selectedAssetType: data.type || "",
          selectedAssetTypeTemplate: data.template || {id: "", name: ""},
          originalAssetTypeTemplate: data.template || {id: "", name: ""},
          assetTags: data.tags || [],
          stockroomAssets: data.stockroom_assets || [],
          displayImage: data.display_image,
          warrantyDocument: data.warranty_document,
          templateData: data.template_data || {},
          editAccess: data?.permissions?.write ?? false
        };
        this.setState(newState);
        this.getAssetDocuments();
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        handleErrorResponse(this, error, "Asset");
      }).then(() => {
        this.setState({
          showLoader: false,
          showFormLoader: false,
          submitting: false
        });
      });
  };

  /* This is passed to the redux-form which returns all values passed in from state */
  submit = async values => {
    console.log("submitting values", values);
    const {assetID, submitting, showAddStockroomAssetModal} = this.state;
    if (!submitting && !showAddStockroomAssetModal) {
      this.setState({submitting: true});
      const {updateAssetDetails} = this.props;

      if (values.org) {
        delete values.org;
      }
      if (values.type) {
        if (values.type.id) {
          values.type = values.type.id;
        }
      }
      if (values.template) {
        if (values.template.id) {
          values.template = values.template.id;
        }
      }

      await updateAssetDetails(assetID, values);
      this.setState({submitting: false});
    }
  };

  /* If field is read only, filter main page results by field contents (manufacturer name) and route to page */
  handleManufacturerClick = (by) => {
    const {name} = by.target;
    const assetEditAccess = (this.props.accountInfo.user_type <= 2);
    const {manufacturer_name} = this.state.assetData;
    const {filterListBy} = this.props;
    if (!assetEditAccess) {
      filterListBy({by: name, filter: manufacturer_name});
    }
  };


  /* This will handle the updating of tags. */
  updateTags = tags => {
    this.submit({tags: tags});
    this.setState({
      assetTags: tags
    });
  };

  // Azure Document stuff
  getAssetDocuments = () => {
    const {assetUUID} = this.state;
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_ASSET_PREFIX}/${assetUUID}/`).then(files => {
      this.setState({
        assetFiles: files
      });
    });
  };

  handleChangeDisplayImageField = () => {
    const {assetUUID} = this.state;
    uploadBlobsToAzureWithFileNameCheck(assetUUID, AZURE_ASSET_PREFIX, [this.refs.fileUploader.files[0]])
      .then(async res => {
        // Res contains filenames from uploaded files, use res[0] because only one file was uploaded
        await this.handleChangeDisplayImageNoUpload(res[0]);
        this.fetchAssetDetails();
      });
  };

  handleChangeDisplayImageNoUpload = async imageName => {
    await handleChangeDisplayImage(this, imageName, this.assetID, this.props.updateAssetField);
  };

  handleChangeWarrantyDocumentField = async documentName => {
    await handleChangeWarrantyDocument(this, documentName, this.assetID, this.props.updateAssetField);
  };

  handleRemoveDocument = documentName => {
    removeDocument(this, documentName, this.assetID, this.props.updateAssetDetails, this.getAssetDocuments);
  };

  // Handle Asset Type/Template Functions
  submitCreateAssetTemplate = async template => {
    const {assetID, assetOrgID, selectedAssetType} = this.state;
    let newTemplate = await this.props.createAssetTemplate(assetID, assetOrgID, selectedAssetType.id, template);
    // after creating an asset template, automatically select the new template
    if (newTemplate.payload) {
      const formattedTemplateData = (({id, fields, name}) => ({id, name, fields, label: name, value: id}))(newTemplate.payload);
      this.handleAssetTypeTemplateSelect(formattedTemplateData);
    }
  };

  submitEditAssetTemplate = async templateToSubmit => {
    const {assetID, assetOrgID, selectedAssetType, selectedAssetTypeTemplate} = this.state;
    this.props.updateAssetTemplate(assetID, assetOrgID, selectedAssetType.id, selectedAssetTypeTemplate.id, templateToSubmit)
      .then(data => {
        let {assetData} = this.state;
        let newState = {
          assetData: cloneDeep(assetData),
          selectedAssetTypeTemplate: data
        };
        newState.assetData.template_data = [];
        // Update the Template Fields Section, if the template being edited is the one currently assigned to the Asset
        if (data.id === this.state.originalAssetTypeTemplate.id) {
          newState["originalAssetTypeTemplate"] = data;
          data.fields.forEach((td, i) => {
            let match = assetData.template_data.findIndex(x => x.field.id === td.id);
            if (match >= 0) {
              let field = assetData.template_data[match].field;
              field.name = td.name;
              field.units = td.units;
              newState.assetData.template_data.push(assetData.template_data[match]);
            } else {
              newState.assetData.template_data.push({
                id: td.id,
                field: td,
                value: ""
              });
            }
          });
        }
        this.setState(newState);
      });
  };
  submitDeleteAssetTemplate = () => {
    const {assetID, assetOrgID, selectedAssetType, selectedAssetTypeTemplate} = this.state;
    this.props.deleteAssetTemplate(assetID, assetOrgID, selectedAssetType.id, selectedAssetTypeTemplate.id)
      .then(() => this.setState({selectedAssetTypeTemplate: {}}));
  };

  handleShowCreateAssetType = () => {
    this.setState({
      newAssetType: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };
  handleShowEditAssetType = () => {
    this.setState({newAssetType: this.state.selectedAssetType});
  };
  handleAssetTypeColorChange = (color) => {
    this.setState({
      newAssetType: {
        name: this.state.newAssetType.name,
        color: color.hex,
        id: this.state.newAssetType.id
      }
    });
  };
  handleAssetTypeNameChange = (event) => {
    this.setState({
      newAssetType: {
        name: event.target.value,
        color: this.state.newAssetType.color,
        id: this.state.newAssetType.id
      }
    });
  };
  handleCreateAssetType = async (name, color) => {
    const assetType = await this.props.createAssetType(this.state.assetOrgID, name, color);
    // after creating an asset type, automatically select the new type
    if (assetType.payload) {
      const {id, color, name} = assetType.payload;
      this.handleAssetTypeSelect({id, color, value: name});
    }
  };
  handleUpdateAssetType = (asset_type_id, name, color) => {
    this.props.updateAssetType(this.state.assetOrgID, asset_type_id, name, color);
    this.setState({selectedAssetType: this.state.newAssetType});
  };
  handleDeleteAssetType = () => {
    const {name, color, id} = this.state.selectedAssetType;
    this.props.deleteAssetType(this.state.assetOrgID, id, name, color);
    this.setState({
      selectedAssetType: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };

  // Handle Select Asset type and template
  handleAssetTypeTemplateSelect = async data => {
    // Let asset = this.state.assetData;
    console.log("selected template", data);
    await this.submit({
      template: data.id || null
    });
    this.setState({selectedAssetTypeTemplate: data || {id: "", name: ""}, showFormLoader: true});

    this.fetchAssetDetails();
  };
  handleAssetTypeSelect = async data => {
    const obj = {
      name: data["value"],
      color: data["color"],
      id: data["id"]
    };
    this.setState({
      selectedAssetType: obj,
      selectedAssetTypeTemplate: null
    });
    await this.submit({type: obj.id || null, template: null});
    // This.fetchAssetDetails()
  };


  render() {
    const {
      submitting,
      assetData,
      assetID,
      assetUUID,
      selectedAssetType,
      selectedAssetTypeTemplate,
      originalAssetTypeTemplate,
      newAssetType,
      assetTags,
      showLoader,
      showFormLoader,
      stockroomAssets,
      errorLoadingMessage,
      assetFiles,
      displayImage,
      warrantyDocument,
      editAccess
    } = this.state;
    const {assetTypeList, accountInfo} = this.props;
    const tags = assetTags || [];

    /* Upon adding an asset redirect to edit asset page for that particular asset. */

    return (
      <div>
        {assetID && (
          <Container>
            {/* <DetailsPageHeader data={assetData}/>*/}
            <Flex className="item-details" flexDirection={["column", "row"]}>
              <DetailsCloseIcon href={"/#/dashboard/assets"} dataTip={"Close and return to assets."}/>
              <DetailsPaneLeft
                className="item-details-pane-left">
                <ImageWithDefault src={displayImage} />
                <input type="file" accept="image/*" id="file" ref="fileUploader"
                  onChange={this.handleChangeDisplayImageField}
                  style={{display: "none"}}/>
                <Button className="item-details-form-button"
                  onClick={() => this.refs.fileUploader.click()}>
                  Change Display Image
                </Button>

                <div className="item-details-pane-left-text">
                  <ReactTooltip />
                  <Heading color={"colorPrimary"} display="inline">TAGS</Heading>
                  <span><div className="tooltip-circle"
                    data-tip="Press enter or , to add a tag. Press backspace to edit a previous tag." /></span>
                  <TagInput handleUpdate={this.updateTags} tagList={tags} />
                  {(accountInfo.user_type === 1) ? this.renderControls() : null}
                </div>
              </DetailsPaneLeft>
              <DetailsPaneRight
                className="item-details-pane-right"
                width={["100%", "50%", "75%"]} // Full width a @ smallest screen, 1/3 width normally
              >
                <div className="item-details-pane-right-text">
                  <DetailsHeader>ASSET DETAILS</DetailsHeader>

                  <AssetForm
                    loadingTemplate={showFormLoader}
                    onSubmit={this.submit}
                    submitting={submitting}
                    values={assetData}
                    triggerSubmit={this.triggerSubmit}
                    editAccess={editAccess}
                    assetTypes={assetTypeList}
                    selectedAssetType={selectedAssetType}
                    selectedAssetTypeTemplate={selectedAssetTypeTemplate}
                    originalAssetTypeTemplate={originalAssetTypeTemplate}
                    newAssetType={newAssetType}
                    submitCreateAssetTemplate={this.submitCreateAssetTemplate}
                    submitEditAssetTemplate={this.submitEditAssetTemplate}
                    submitDeleteAssetTemplate={this.submitDeleteAssetTemplate}
                    handleAssetTypeSelect={this.handleAssetTypeSelect}
                    handleAssetTypeTemplateSelect={this.handleAssetTypeTemplateSelect}
                    handleColorPickerClick={this.handleColorPickerClick}
                    handleShowCreateAssetType={this.handleShowCreateAssetType}
                    handleShowEditAssetType={this.handleShowEditAssetType}
                    handleAssetTypeNameChange={this.handleAssetTypeNameChange}
                    handleAssetTypeColorChange={this.handleAssetTypeColorChange}
                    handleCreateAssetType={this.handleCreateAssetType}
                    handleUpdateAssetType={this.handleUpdateAssetType}
                    handleDeleteAssetType={this.handleDeleteAssetType}
                    handleManufacturerClick={this.handleManufacturerClick}
                  />
                  <br />
                  <AttachedFiles
                    files={assetFiles}
                    displayImage={displayImage}
                    warrantyDocument={warrantyDocument}
                    azureDocumentPrefix={AZURE_ASSET_PREFIX}
                    UUID={assetUUID}
                    getDocuments={this.getAssetDocuments}
                    handleChangeDisplayImage={this.handleChangeDisplayImageNoUpload}
                    handleChangeWarrantyDocument={this.handleChangeWarrantyDocumentField}
                    handleRemoveDocument={this.handleRemoveDocument}
                    refreshObject={this.fetchAssetDetails} />
                </div>

                {this.renderNoteSection()}

                <br />
                <div className="item-details-pane-right-text">
                  <DetailsHeader>STOCKROOM ASSETS</DetailsHeader>
                </div>
                {stockroomAssets.length > 0
                  ? stockroomAssets.map(asset => (
                    <StockroomAsset
                      key={asset.id}
                      id={asset.id}
                      name={asset.name}
                      stockroom_asset_name={asset.name}
                      stockroom_tags={asset.tags}
                      purchased_price={asset.purchase_price}
                      purchase_price_currency={asset.purchase_price_currency}
                      purchased_date={asset.purchased_date}
                      implemented_date={asset.implemented_date}
                      displayImage={asset.display_image}
                    />
                  ))
                  : <p className="item-details-alert">No Stockroom Assets found.</p>
                }
              </DetailsPaneRight>
            </Flex>
          </Container>
        )}
        {showLoader && <Loading title={"Loading Asset..."}/>}
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}

      </div>
    );
  }

  renderNoteSection = () => {
    const {
      assetID,
      assetFiles,
      assetUUID
    } = this.state;
    const {accountInfo, getRecentLogs, notes, fetchNotes, addNote} = this.props;
    return (
      <NoteSection
        UUID={assetUUID}
        accountInfo={accountInfo}
        documentID={assetID}
        handleSubmit={addNote}
        refreshObject={() => fetchNotes(assetID)}
        getDocuments={this.getAssetDocuments}
        azureDocumentPrefix={AZURE_ASSET_PREFIX}
        notes={notes}
        files={assetFiles}
        getRecentLogs={getRecentLogs}
        title={"ASSET NOTES"}
        endpoint={"assets"}
      />
    );
  };


  // This renders admin Controls in the Main render
  renderControls = () => {
    const {
      assetData,
      assetOrgID
    } = this.state;
    const {accountInfo, csvMessages, clearCsvMessages, uploadCsv, getRecentLogs, getAllVendors, getAllPMOrders} = this.props;
    return (

      <AdminControls
        accountInfo={accountInfo}
        asset={assetData}
        orgID={assetOrgID}
        clearCsvMessages={clearCsvMessages}
        csvMessages={csvMessages}
        uploadCsv={uploadCsv}
        getRecentLogs={getRecentLogs}
        fetchDetails={this.fetchAssetDetails}
        getAllVendors={getAllVendors}
        getAllPMOrders={getAllPMOrders}
      />

    );
  };
}


export default AssetDetails;
