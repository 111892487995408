import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Button, Descriptions, message, PageHeader as BasePageHeader} from "antd";
import {Redirect} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

import {Modal} from "Common";
import {DescriptionText} from "Common/Modals/styled";
import {MOBILE} from "utils/constants";
import {Box, Flex} from "../Base";
import moment from "moment";


const DetailsPageHeader = ({
  data,
  onUpdate,
  onDelete,
  actions,
  text,
  editConfig,
  metaFields = [],
  deleteConfig,
  DetailsForm,
  renderDescription,
  image,
  formProps,
  children,
  clickToEdit,
  editable,
  footer,
  leftDetails,
  isNew
}) => {
  const {title, subTitle, name, shortName} = text;

  const {formID, hideMeta} = editConfig;
  const {confirmText, redirectUrl} = deleteConfig;
  const isTabletOrMobile = useMediaQuery(MOBILE);

  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isNew) {
      setIsEditing(true);
    }
  }, [isNew, setIsEditing]);

  if (!data) {
    return null;
  }
  const {created_by, org} = data;


  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleEdit = async (values) => {
    await onUpdate(values);
    setIsEditing(false);
  };

  const handleMessage = (text, error = false) => {
    // setAdminMessage(text)
    if (error) {
      message.error(text);
    } else {
      message.info(text);
    }
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    const res = await onDelete(data.id);
    if (!res.error) {
      handleMessage(`${name} deleted.`);
      setDeleteSuccess(true);
    } else {
      handleMessage(`Could not delete this ${shortName}.`, true);
    }
    setIsSubmitting(false);

    toggleDeleteModal();
  };

  const renderImage = () => {
    if (image) {
      if (isTabletOrMobile && isEditing) {
        return null;
      }
      return image;
    }
  };

  // const renderMobileImage = () => {
  //   if (isTabletOrMobile && image) {
  //     return image;
  //   }
  // };

  const renderMetaDetails = () => {
    if (isEditing && hideMeta) {
      return null;
    }
    return (
      <Descriptions size="small" column={{sm: 2, xs: 1}} className={"meta-details"}>

        {created_by && <Descriptions.Item label="Created by">{created_by.name}</Descriptions.Item>}
        {org &&
        <Descriptions.Item label="Org">
          {org.name}
        </Descriptions.Item>
        }
        <Descriptions.Item label="Created At">
          {moment(data.created_at).format("LLL")}
        </Descriptions.Item>
        <Descriptions.Item label="Last Updated">
          {moment(data.updated_at).format("LLL")}
        </Descriptions.Item>

        {metaFields.map(item => (
          <Descriptions.Item
            span={item.span} key={item.field} label={item.label}>
            {data[item.field]}
          </Descriptions.Item>
        ))}
      </Descriptions>
    );
  };

  const renderDetails = () => {
    if (isEditing) {
      return (
        <DetailsForm
          onSubmit={handleEdit}
          initialValues={data}
          {...formProps}
        />
      );
    }
    if (clickToEdit) {
      return (
        <div onClick={() => setIsEditing(true)}>
          {renderDescription()}
        </div>
      );
    }
    return (
      <div>
        {renderDescription()}
      </div>
    );
  };


  if (deleteSuccess) {
    return <Redirect to={redirectUrl}/>;
  }

  let cancelButton = null;
  let extraActions = [];
  if (editable) {
    extraActions.push((
      <Button
        key={"edit"}
        className={"admin-edit-button"}
        onClick={() => setIsEditing(!isEditing)}
        loading={isSubmitting}
      >
        Edit
      </Button>
    ));
  }

  if (isEditing) {
    cancelButton = (
      <Button
        size={"small"}
        type={"dashed"}
        key={"edit"}
        className={"admin-edit-button"}
        onClick={() => setIsEditing(!isEditing)}
        loading={isSubmitting}
      >
        Cancel
      </Button>
    );

    extraActions = [
      cancelButton,
      <Button
        key={"save"}
        className={"admin-save-button"}
        type={"primary"}
        onClick={() => {
          const element = document.getElementById(formID);
          if (element) {
            element.dispatchEvent(new Event("submit", {cancelable: true}));
          }
        }}
        loading={isSubmitting}
      >
        Save
      </Button>,
    ];
  }

  return (
    <BasePageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={title}
      subTitle={subTitle}
      footer={footer}
      extra={[
        ...extraActions,
        ...actions,
        <Button
          danger
          key={"delete"}
          className={"admin-delete-button"}
          onClick={toggleDeleteModal}
          loading={isSubmitting}
        >
          Delete
        </Button>,
      ]}
    >

      <Flex
        className={"details-content"}
        flexDirection={["column", "column", "row"]}
        // flexWrap={"wrap"}
      >

        <Flex className={"left-details"} flexDirection={["column", "row", "column"]}>
          {renderImage()}
          {leftDetails()}
        </Flex>
        <Box className={"main-details"}>
          {renderDetails()}

          {renderMetaDetails()}
          {children}

        </Box>
      </Flex>

      <Modal
        visible={showDeleteModal}
        title={`Delete ${name.toLowerCase()}?`}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
        isDeleteModal
      >
        <DescriptionText>
          {confirmText}
        </DescriptionText>
      </Modal>

    </BasePageHeader>
  );
};

DetailsPageHeader.propTypes = {
  /**
   * Data of the primary object of details page
   */
  data: PropTypes.object,
  /**
   * Primary form that edits values from `data`
   */
  DetailsForm: PropTypes.elementType,
  /**
   * Update function passed to DetailsForm
   */
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateBol: PropTypes.func,
  /**
   * Array for extra actions specific to the details page
   * Could be a component with a button that opens a modal or drawer
   */
  actions: PropTypes.array,
  text: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    name: PropTypes.string,
    shortName: PropTypes.string,
  }),
  renderDescription: PropTypes.func,
  deleteConfig: PropTypes.shape({
    confirmText: PropTypes.string,
    redirectUrl: PropTypes.string.isRequired,
  }),
  editConfig: PropTypes.shape({
    formID: PropTypes.string.isRequired,
    hideMeta: PropTypes.bool,
  }),
  metaFields: PropTypes.array,
  leftDetails: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),
  children: PropTypes.node,
  /**
   * Extra props to passed to the DetailsForm
   */
  formProps: PropTypes.object,
  image: PropTypes.element,
  /**
   * Set true to open form upon clicking the details section.
   */
  clickToEdit: PropTypes.bool,
  /**
   * If this details page has an editable form
   */
  editable: PropTypes.bool,
  footer: PropTypes.elementType,
  /**
   * If true, edit mode is initially set to True
   */
  isNew: PropTypes.bool
};

DetailsPageHeader.defaultProps = {
  actions: [],
  metaFields: [],
  renderDescription: () => null,
  text: {
    title: "Document Details",
    name: "document",
    shortName: "order",
    subTitle: null,
  },
  editConfig: {
    formID: "details-form",
    hideMeta: false,
  },
  deleteConfig: {
    confirmText: "Deleting this will remove all items assigned to it.",
  },
  image: null,
  updateBol: () => null,
  clickToEdit: true,
  editable: true,
  footer: null,
  leftDetails: () => null,
  isNew: false
};

export default DetailsPageHeader;
