import React, {Fragment} from "react";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Box, Button, Flex, Search} from "Base";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchContainer} from "./styled";
import SearchInput from "./Search/SearchInput";
import {nonBulkDeletable} from "../../Dashboard/ToolBar/constants";


/* The toolbar contains the buttons for pagination, as well as search functionality. */
const ToolBar = ({
  handleChange, updateSortBy, nextPage, prevPage, pageNum, isLastPage,
  changeViewList, changeViewTile, changeViewTable, changeViewCalendar,
  listView, tileView, tableView, calendarView, selectionOptions, filter,
  reverseList, isReversed, filterListBy, filteringBy, filterOptions,
  filterListByType, filteringByType, filterTypeOptions, objectType, handleAddItemButtonClick,
  withPagination, hasTableView, showDelete, handleShowDelete
}) => (
  <div className="toolbar">
    <ReactTooltip/>
    <Flex flexWrap="wrap" alignItems="center">
      <div className="toolbar-row">
        {withPagination &&
        <div className="toolbar-row-button">
          {!tableView && !calendarView &&
          <Fragment>
            <FontAwesomeIcon
              className={(pageNum.currentPage === 1) ? "toolbar-row-button-icon-active" : "toolbar-row-button-icon-inactive"}
              icon="caret-up"
              size="2x"
              onClick={prevPage}
              data-tip={pageNum.currentPage !== 1 ? "Previous 12 items." : ""}
            />
            <FontAwesomeIcon
              className={isLastPage ? "toolbar-row-button-icon-active" : "toolbar-row-button-icon-inactive"}
              icon="caret-down"
              size="2x"
              onClick={nextPage}
              data-tip={!isLastPage ? "Next 12 items." : ""}
            />
            {/* Currently a bug after deleting an item where currentPage won't get updated until refresh*/}
            <span className="toolbar-row-pagenums">Page {Math.min(pageNum.currentPage, pageNum.pages) || 0} of {pageNum.pages || 0}</span>
          </Fragment>
          }
        </div>
        }
        {!tableView && !calendarView &&
        <Fragment>
          {withPagination ?

            <SearchContainer order={[5, 2]} alignItems="center" m={["10px 0", "10px 20px"]}>
              <Search
                onChange={handleChange}
                name="search"
                type="text"
                className="toolbar-row-search"
                placeholder="Search..."
                value={filter}
              />


            </SearchContainer>
            :
            <Fragment>
              <SearchContainer order={[5, 2]} alignItems="center" pt={[2, 0]}>
                <SearchInput
                  onSubmit={handleChange}
                  filter={filter}
                />
              </SearchContainer>
            </Fragment>
          }

          <Box
            order={[2, 3]}
            mx="20px"
            p={"10px 15px"}
            mt={0}
            width={"fit-content"}
          >
            <Button
              className="add-item-button"
              type={"primary"}
              onClick={handleAddItemButtonClick}>
              Add {objectType}
            </Button>
          </Box>


        </Fragment>
        }

        {withPagination &&
        <Fragment>
          {filterListBy &&
          <Flex order={[4, 3]} m={"15px 10px 15px 0"} flex={"1 0 100px"} justifyContent="center">
            <Select
              onChange={filterListBy}
              className="toolbar-row-select filter-select"
              options={filterOptions}
              defaultValue={{value: "", label: "Filter By"}}
              value={filterOptions.find(option => option.value === filteringBy)}
            />
          </Flex>
          }
          {filterListByType && filterTypeOptions.length > 1 &&
          <Flex order={[5, 4]} m={"15px 10px 15px 0"} flex={"1 0 140px"} justifyContent="center">
            <Select
              onChange={filterListByType}
              className="toolbar-row-select filter-type-select"
              options={filterTypeOptions}
              defaultValue={{value: null, label: "Every Type"}}
              value={filterTypeOptions.find(option => option.value === filteringByType)}
            />
          </Flex>
          }
        </Fragment>
        }

        {withPagination &&
        <Flex order={[2, 5]} m={"15px 10px 15px 0"} flex={"1 0 140px"} justifyContent="center">
          {!tableView && !calendarView &&
          <Fragment>
            <button
              className="toolbar-row-button-color"
              onClick={reverseList}>
              {isReversed ?
                <FontAwesomeIcon
                  icon="caret-up"
                  size="lg"
                />
                :
                <FontAwesomeIcon
                  icon="caret-down"
                  size="lg"
                />
              }
            </button>
            <Select
              onChange={updateSortBy}
              className="toolbar-row-select"
              options={selectionOptions}
              defaultValue={{value: "null", label: "Sort By"}}
            />
          </Fragment>
          }
        </Flex>
        }
        <Box order={[3, 6]}>
          <div className="toolbar-row-button">
            {hasTableView &&
            <FontAwesomeIcon
              className={"table-view-button " + ((!tableView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
              icon="table"
              size="lg"
              onClick={changeViewTable}
              data-tip="Table view."
            />
            }
            <FontAwesomeIcon
              className={"list-view-button " + ((!listView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
              icon="list"
              size="lg"
              onClick={changeViewList}
              data-tip="List view."
            />
            <FontAwesomeIcon
              className={"tile-view-button " + ((!tileView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
              icon="th"
              size="lg"
              onClick={changeViewTile}
              data-tip="Tile view."
            />
            {changeViewCalendar &&
            <FontAwesomeIcon
              className={"calendar-view-button " + ((!calendarView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
              icon="calendar-alt"
              size="lg"
              onClick={changeViewCalendar}
              data-tip="Calendar view."
            />
            }
            {!nonBulkDeletable.includes(objectType) &&
            <FontAwesomeIcon
              className={"trash-view-button " + ((!showDelete) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
              id="trashIcon"
              icon="trash"
              size="lg"
              onClick={handleShowDelete}
              data-tip={`Delete ${objectType}s`}
            />
            }

          </div>
        </Box>
      </div>
    </Flex>
  </div>
);

ToolBar.defaultProps = {
  withPagination: true,
  hasTableView: true,
};
export default ToolBar;
