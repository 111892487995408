import React, {Component} from "react";
import {connect} from "react-redux";
import {authSelectors, baseSelectors, orgOperations, orgSelectors, palletActions, palletOperations, palletSelectors} from "../../../store";
import Warehouse from "./Warehouse";

class WarehouseContainer extends Component {
  render() {
    return <Warehouse {...this.props}/>;
  }
}
/* This is where Redux state is mapped to this component. */
const mapStateToProps = (state, ownProps) => ({
  allPalletsInfo: palletSelectors.refreshNameList(state),
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  currentPage: baseSelectors.refreshCurrentPage(state.pallets),
  loading: baseSelectors.isLoading(state.pallets),
  listView: baseSelectors.isListView(state.pallets),
  tileView: baseSelectors.isTileView(state.pallets),
  tableView: baseSelectors.isTableView(state.pallets),
  pageNum: baseSelectors.currPageNum(state.pallets),
  isLastPage: baseSelectors.isLastPage(state.pallets),
  hasErrors: baseSelectors.hasErrors(state.pallets),
  isEmptyList: baseSelectors.isEmptyList(state.pallets),
  filter: baseSelectors.filter(state.pallets),
  isReversed: baseSelectors.isListReversed(state.pallets),
  csvMessages: baseSelectors.refreshMessages(state.assets),
  locationList: orgSelectors.refreshLocations(state.orgs),
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  getOrg: (id) => dispatch(orgOperations.getItem(id)),
  getAllPalletDetails: () => dispatch(palletOperations.getAllItems()),
  createPallet: (data) => dispatch(palletOperations.createItem(data)),
  nextPage: () => dispatch(palletActions.nextPage()),
  prevPage: () => dispatch(palletActions.prevPage()),
  uploadCsv: (data, org) => dispatch(palletOperations.uploadCsv(data, org)),
  filterPallets: filter => dispatch(palletActions.filterList(filter)),
  sortPallets: sortBy => dispatch(palletActions.sortList(sortBy)),
  reverseList: () => dispatch(palletActions.reverseList()),
  changeViewList: () => dispatch(palletActions.changeViewList()),
  changeViewTile: () => dispatch(palletActions.changeViewTile()),
  changeViewTable: () => dispatch(palletActions.changeViewTable()),
  getRecentLogs: () => dispatch(orgOperations.getRecentLogs()),
  clearMessages: () => dispatch(palletActions.clearMessages()),
  getLocations: (id) => dispatch(orgOperations.getLocations(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseContainer);
