import {renderNameOrSku} from "./inventory";
import {Link} from "../../Common/Base";
import React from "react";

/**
 * Returns a link to material or product for the table
 * @param {object} record - inventory item
 * @param {string} type - 'inventory | material' used for url
 * @returns {*} - link to product or material
 */
export const renderItemLink = (record, type = "inventory") => {
  // console.log("item link", item);
  const id = record ? record.id : "";
  const name = renderNameOrSku(record);
  return <Link to={`/dashboard/${type}/${id}`}>{name}</Link>;
};

/**
 * Returns link to product based on product or product_inventory record
 * @param {object} pallet - pallet with expanded inventory
 * @returns {*} - link to product
 */
export const renderProductInventoryLink = (pallet = {}) => {
  if (pallet.product) {
    return renderItemLink(pallet.product, "inventory");
  }
  if (pallet.product_inventory && pallet.product_inventory.product) {
    return renderItemLink(pallet.product_inventory.product, "inventory");
  }
};

export const getOrderTableProducts = (data) => {
  const flattenedItems = [];
  const purchaseOrderItemLookup = {};
  if (data && data.items) {
    data.items.forEach((item, i) => {
      const instance = item.product;
      flattenedItems.push({...item, name: instance.name, productID: instance.id, key: i});
      purchaseOrderItemLookup[instance.id] = true;
    });
  }
  return {flattenedItems, purchaseOrderItemLookup};
};


export const getOrderTableMaterials = (data) => {
  const flattenedMaterials = [];
  const materialItemLookup = {};
  if (data && data.materials) {
    data.materials.forEach((item, i) => {
      const instance = item.material;
      if (instance && instance.name) {
        flattenedMaterials.push({...item, name: instance.name, productID: instance.id, key: i});
        materialItemLookup[instance.id] = true;
      }
    });
  }
  return {flattenedMaterials, materialItemLookup};
};

