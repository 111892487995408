import React from "react";
import PropTypes from "prop-types";
// TODO: replace with chakra
import {ListItemSecondaryAction, ListItemText, ListItem} from "@material-ui/core";
import {ListButton} from "../styled";
import {FaTrash} from "react-icons/fa";

const PersonneListItem = props => {
  const {item, classes, isEditable, onClick} = props;

  const handleEdit = () => {
    onClick(item);
  };


  return (
    <ListItem className={`${classes.listItem} personnel-list-item`}>
      <ListItemText
        className={classes.listItemText}
        primary={item.name}
      />
      {isEditable &&
      <ListItemSecondaryAction>
        <ListButton className={"remove-personnel"} icon={FaTrash} aria-label={"remove"} onClick={handleEdit} />

      </ListItemSecondaryAction>
      }
    </ListItem>
  );
};

PersonneListItem.propTypes = {
  item: PropTypes.object,
  isEditable: PropTypes.bool,
  onClick: PropTypes.func
};

PersonneListItem.defaultProps = {
  isEditable: false,
  onClick: (data) => console.log("clicked ", data)
};

export default PersonneListItem;
