import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {PaginatedSelect} from "../../../../Common/Fields";
import {FormDropdown} from "../../../../Common/Forms/Base";
import {Button} from "../../../../Common/Base";


const formatOptions = (data) => {
  return data.map(item => ({label: item.sku, value: item.id}));
};
const AdaptedSelect = ({input, ...rest}) => {
  return <PaginatedSelect {...input} {...rest}/>;
};

const ProductForm = props => {
  const {onSubmit, getPage} = props;

  const formatSubmit = (values) => {
    const {product} = values;
    return onSubmit({
      product: product.value
    });
  };
  return (
    <div>
      <Form
        onSubmit={formatSubmit}
        // validate={validate}
        // initialValues={initialValues}
        render={({handleSubmit, pristine, submitting}) => (
          <form className={"item-details-form"} onSubmit={handleSubmit}>
            <FormDropdown
              component={AdaptedSelect}
              getPage={getPage}
              name="product"
              label="PRODUCT"
              formatOptions={formatOptions}
              format={(value) => {
                // if (value && typeof value === "number") {
                //   return assetList.find(el => el.id === value);
                // }
                return value ? {
                  value: value.id || value.value,
                  label: value.name || value.label,
                } : "";
              }}
              parse={(value) => {
                // console.log("parse value", value);
                // return value ? value.id || value.value : "";
                return value;
              }}
            />

            <Button
              disabled={pristine || submitting}
              type={"primary"}
              loading={submitting}
              htmlType={"submit"}
              className={"submit-product-button"}>
              Add
            </Button>
          </form>
        )}
      />
    </div>
  );
};

ProductForm.propTypes = {
  getPage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ProductForm;
