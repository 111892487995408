import React, {Component} from "react";
import {connect} from "react-redux";
import {
  assetOperations,
  assetSelectors,
  authSelectors,
  orgOperations,
  orgSelectors,
  paginationSelectors,
  productPaginationActions,
  salesOrderActions,
} from "../../../store";
import {entryOperations, entryPageActions, lineAssetActions, lineOperations, linePageActions} from "../../../store/production";
import ProductionDetails from "../../../components/Production/Details/ProductionDetails";

const mapStateToProps = (state, ownProps) => {
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    orgID: authSelectors.defaultOrgID(state.account),
    details: paginationSelectors.getItem(
      state.production.lines.items,
      ownProps.match.params.id,
    ),
    locationList: orgSelectors.refreshLocations(state.orgs),
    assetList: assetSelectors.refreshNameList(state.assets),
    productPagination: paginationSelectors.pagination(state.warehouse.products.items),
    entryPagination: paginationSelectors.pagination(state.production.entries.items)
    // productList: productSelectors.refreshNameList(state.products)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const getParams = "?expand=location,assets,products,org";
  const itemID = ownProps.match.params.id;
  return {
    lineActions: {
      create: (data) => dispatch(lineOperations.createItem(data)),
      update: (id, data) => dispatch(lineOperations.updateItem(id, data, getParams)),
      delete: (id) => dispatch(lineOperations.deleteItem(id)),
      getItem: (id = itemID) => dispatch(lineOperations.getItem(
        id,
        getParams
      )),
      getPage: (page, params) => {
        const finalParams = {
          ...params,
          expand: "location"
        };
        return dispatch(linePageActions.fetchPage({page, params: finalParams}));
      }
    },
    lineAssetActions: {
      create: (data) => dispatch(lineAssetActions.createItem(data)),
      update: (id, data) => dispatch(lineAssetActions.updateItem(id, data)),
      delete: (id) => dispatch(lineAssetActions.deleteItem(id))
    },
    entryActions: {
      create: (data) => dispatch(entryOperations.createItem(data)),
      update: (id, data) => dispatch(entryOperations.updateItem(id, data)),
      delete: (id) => dispatch(entryOperations.deleteItem(id)),
      getItem: (id) => dispatch(entryOperations.getItem(
        id,
        getParams
      )),
      getPage: (page, params) => {
        const finalParams = {
          ...params,
          line: ownProps.match.params.id,
          expand: "sales_order,product,product_template",
          page_size: 100
        };
        return dispatch(entryPageActions.fetchPage({page, params: finalParams}));
      }
    },
    salesOrderActions: {
      getPage: (page, params) => {
        const finalParams = {
          ...params,
          // line: ownProps.match.params.id,
          // expand: "location"
        };
        return dispatch(salesOrderActions.fetchPage({page, params: finalParams}));
      }
    },
    getLocations: (id) => dispatch(orgOperations.getLocations(id)),
    getAssets: () => dispatch(assetOperations.getAllItems()),
    getProducts: (page, params) => {
      const finalParams = {
        ...params
      };
      return dispatch(productPaginationActions.fetchPage({page, params: finalParams}));
    }
  };
};

class ProductionLineDetailsContainer extends Component {
  render() {
    return (
      <ProductionDetails {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionLineDetailsContainer);
