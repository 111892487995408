import {theme as chakraTheme} from "@chakra-ui/core";
import typography from "./typography";
import colors from "./colors";
import buttonVariants from "./buttons";

export default {
  ...chakraTheme,
  breakpoints: ["600px", "769px", "1024px", "1600px"],
  shadows: {
    light: "2px 4px 8px 0 rgba(46, 61, 73, .2)",
    dark: "2px 4px 8px 0 rgba(46, 61, 73, .4)"
  },
  buttons: {
    ...buttonVariants
  },
  icons: {
    ...chakraTheme.icons
  },
  colors: {
    ...chakraTheme.colors,
    ...colors,
  },
  ...typography
};

