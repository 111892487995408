import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Box} from "../../Base";

const LocationCard = props => {
  const {region, district, facility, wing, zone, subzone} = props;

  return (
    <Box
      className="assigned-location-section"
      mt={"15px"}>
      {region && <Fragment><b>Region:</b> {region.name}<br/></Fragment>}
      {district && <Fragment><b>District:</b> {district.name}<br/></Fragment>}
      {facility && <Fragment><b>Facility:</b> {facility.name}<br/></Fragment>}
      {wing && <Fragment><b>Wing:</b> {wing.name}<br/></Fragment>}
      {zone && <Fragment><b>Zone:</b> {zone.name}<br/></Fragment>}
      {subzone && <Fragment><b>Subzone:</b> {subzone.name}</Fragment>}
    </Box>
  );
};

LocationCard.propTypes = {
  region: PropTypes.object,
  district: PropTypes.object,
  facility: PropTypes.object,
  wing: PropTypes.object,
  zone: PropTypes.object,
  subzone: PropTypes.object,
};

LocationCard.defaultProps = {};

export default LocationCard;
