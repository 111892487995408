import React from "react";
import PropTypes from "prop-types";
import {TabPane, Tabs} from "Base";

const defaultTabContainerProps = {
  type: "card"
};

const DetailsTabs = ({tabs, tabContainerProps}) => {
  let tabContainerConfig = {...defaultTabContainerProps, ...tabContainerProps};
  return (
    <Tabs {...tabContainerConfig}>
      {tabs.map(tab => (
        <TabPane tab={tab.title} key={tab.key}>
          {tab.content}
        </TabPane>
      ))}
    </Tabs>
  );
};

DetailsTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.node,
    key: PropTypes.string,
    title: PropTypes.string
  })),
  tabContainerProps: PropTypes.object,
};

DetailsTabs.defaultProps = {
  tabContainerProps: defaultTabContainerProps
};

export default DetailsTabs;
