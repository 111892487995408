import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {DAYS, returnNumeralOptions} from "../../../constants";
import translateLabel from "../../../utils/translateLabel";
import selectFieldHandler from "../../../utils/selectFieldHandler";

import {InputWrapper, Label, Row, RowLabelWrapper, RowOptions} from "../../../styles/styled";

const RepeatMonthlyOnThe = ({
  id,
  mode,
  onThe,
  hasMoreModes,
  handleChange,
  translations
}) => {
  const isActive = mode === "on the";

  return (
    <Row className={`form-group ${!isActive && "opacity-50"}`}>
      <RowLabelWrapper>
        {hasMoreModes && (
          <input
            id={id}
            type="radio"
            name="repeat.monthly.mode"
            aria-label="Repeat monthly on the"
            value="on the"
            checked={isActive}
            onChange={handleChange}
          />
        )}

        <Label>
          {translateLabel(translations, "repeat.monthly.on_the")}
        </Label>
      </RowLabelWrapper>


      <RowOptions>
        <InputWrapper width={1 / 2}>
          <Select
            id={`${id}-which`}
            name="repeat.monthly.onThe.which"
            aria-label="Repeat monthly on the which"
            className="form-control"
            value={{value: onThe.which, label: translateLabel(translations, `numerals.${onThe.which.toLowerCase()}`)}}
            isDisabled={!isActive}
            onChange={selectFieldHandler(handleChange)}
            options={returnNumeralOptions("repeat.monthly.onThe.which", translations)}
          />


        </InputWrapper>

        <InputWrapper width={1 / 2}>
          <Select
            id={`${id}-day`}
            name="repeat.monthly.onThe.day"
            aria-label="Repeat monthly on the day"
            className="form-control"
            value={{value: onThe.day, label: translateLabel(translations, `days.${onThe.day.toLowerCase()}`)}}
            isDisabled={!isActive}
            onChange={selectFieldHandler(handleChange)}
            options={
              DAYS.map(day => ({
                value: day,
                label: translateLabel(translations, `days.${day.toLowerCase()}`),
                name: "repeat.monthly.onThe.day"
              }))
            }
          />

        </InputWrapper>
      </RowOptions>


    </Row>
  );
};
RepeatMonthlyOnThe.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  onThe: PropTypes.shape({
    which: PropTypes.oneOf(["First", "Second", "Third", "Fourth", "Last"]).isRequired,
    day: PropTypes.oneOf(DAYS).isRequired,
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RepeatMonthlyOnThe;
