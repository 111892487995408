import {
  ASSETS_ROUTE,
  INVENTORY_DASHBOARD_ROUTE,
  MATERIAL_DASHBOARD_ROUTE,
  PALLET_DASHBOARD_ROUTE,
  PM_ORDERS_DASHBOARD_ROUTE,
  PRODUCTION_DASHBOARD_LINE_ROUTE,
  PURCHASE_ORDERS_DASHBOARD_ROUTE,
  SALES_ORDERS_DASHBOARD_ROUTE,
  PERSONNEL_ROUTE,
  PM_ORDERS_ROUTE,
  PRODUCTION_LINE_ROUTE,
  VENDORS_ROUTE,
  INVENTORY_WAREHOUSE_ROUTE,
  PRODUCTION_DASHBOARD_ROUTE,
  WAREHOUSE_DASHBOARD_ROUTE,
  PRODUCT_ROUTE, MATERIALS_ROUTE,
  LOCATIONS_ROUTE, LOCATION_DASHBOARD_ROUTE,
} from "./constants";

import {LOCATION_LEVELS} from "../components/Locations/utils";

/**
 * The navigation links that are used for the main navigation
 * @type {Array<object>}
 */
const NAV_LINKS = [
  {href: ASSETS_ROUTE, text: "ASSETS", key: "assets"},
  {href: PRODUCTION_LINE_ROUTE, text: "PRODUCTION", key: "production"},
  {href: INVENTORY_WAREHOUSE_ROUTE, text: "WAREHOUSE", key: "warehouse"},
  {href: PERSONNEL_ROUTE, text: "PERSONNEL", key: "personnel"},
  {href: PM_ORDERS_ROUTE, text: "PM ORDERS", key: "orders"},
  {href: VENDORS_ROUTE, text: "VENDOR ORDERS", key: "vendors"},
  {href: `${LOCATIONS_ROUTE}?all`, text: "LOCATIONS", key: "locations"},
];

/**
 * The navigation links that are used for the main navigation on small screens
 * @type {Array<object>}
 */
const MOBILE_NAV_LINKS = [{href: "", text: "HOME", key: "home"}, ...NAV_LINKS];

const LOCATION_LEVEL_PARAM = "level";

const PM_ORDER_NAV = [
  {
    key: "all_orders",
    title: "All",
    icon: "orders",
    submenu: false,
    to: `${PM_ORDERS_DASHBOARD_ROUTE}`,
    exact: true
  },
  {
    key: "asset_order",
    title: "Assets",
    icon: "tag",
    submenu: false,
    to: `${PM_ORDERS_DASHBOARD_ROUTE}?asset__isnull=false`,
  },
  {
    key: "location_order",
    title: "Location",
    icon: "compass",
    submenu: false,
    to: `${PM_ORDERS_DASHBOARD_ROUTE}?location__isnull=false`,
  },
];

const PRODUCTION_NAV = [
  {
    key: "lines",
    title: "Lines",
    icon: "materials",
    submenu: true,
    to: `${PRODUCTION_DASHBOARD_LINE_ROUTE}`,
    items: [
      {
        key: "lines",
        title: "All",
        to: `${PRODUCTION_DASHBOARD_LINE_ROUTE}`,
      },
    ],
  },
  {
    key: "materials",
    title: "Materials",
    icon: "materials",
    // submenu: true,
    to: `${MATERIAL_DASHBOARD_ROUTE}`,
    items: [
      {
        key: "materials",
        title: "Catalog",
        to: `${MATERIAL_DASHBOARD_ROUTE}`,
      },
    ],
  },
  {
    key: "products",
    title: "Products",
    icon: "product",
    // submenu: true,
    to: `${PRODUCTION_DASHBOARD_ROUTE}/${PRODUCT_ROUTE}`,
    items: [
      {
        key: "products",
        title: "Catalog",
        to: `${PRODUCTION_DASHBOARD_ROUTE}/${PRODUCT_ROUTE}`,
      },
    ],
  },
];

const WAREHOUSE_NAV = [
  {
    key: "products",
    title: "Products",
    icon: "product",
    // submenu: true,
    to: `${INVENTORY_DASHBOARD_ROUTE}`,

    items: [
      {
        key: "products",
        title: "Catalog",
        to: `${INVENTORY_DASHBOARD_ROUTE}`,
      },
    ],
  },
  {
    key: "materials",
    title: "Materials",
    icon: "materials",
    // submenu: true,
    to: `${WAREHOUSE_DASHBOARD_ROUTE}/${MATERIALS_ROUTE}`,
    items: [
      {
        key: "materials",
        title: "Catalog",
        to: `${WAREHOUSE_DASHBOARD_ROUTE}/${MATERIALS_ROUTE}`,
      },
    ],
  },
  {
    key: "pallets",
    title: "Shipping",
    icon: "upload",
    submenu: true,
    to: `${PALLET_DASHBOARD_ROUTE}`,
    items: [
      {
        key: "pallets",
        title: "Pallets",
        to: `${PALLET_DASHBOARD_ROUTE}`,
      },
    ],
  },
  {
    key: "orders",
    title: "Orders",
    icon: "solution",
    submenu: true,
    to: `${PURCHASE_ORDERS_DASHBOARD_ROUTE}`,
    items: [
      {
        key: "purchase_orders",
        title: "Purchase",
        to: `${PURCHASE_ORDERS_DASHBOARD_ROUTE}`,
      },
      {
        key: "sales_orders",
        title: "Sales",
        to: `${SALES_ORDERS_DASHBOARD_ROUTE}`,
      },
    ],
  },
];

const LOCATION_NAV = [
  {
    key: "locations",
    title: "Locations",
    icon: "materials",
    submenu: true,
    to: `${LOCATION_DASHBOARD_ROUTE}?all`,
    items: [
      {
        key: "locations-all",
        title: "All",
        to: `${LOCATION_DASHBOARD_ROUTE}?${LOCATION_LEVELS.ALL}`,
      },
      {
        key: "regions",
        title: "Regions",
        to: `${LOCATION_DASHBOARD_ROUTE}?${LOCATION_LEVEL_PARAM}=${LOCATION_LEVELS.REGION}`,
      },
      {
        key: "districts",
        title: "Districts",
        to: `${LOCATION_DASHBOARD_ROUTE}?${LOCATION_LEVEL_PARAM}=${LOCATION_LEVELS.DISTRICT}`,
      },
      {
        key: "facility",
        title: "Facility",
        to: `${LOCATION_DASHBOARD_ROUTE}?${LOCATION_LEVEL_PARAM}=${LOCATION_LEVELS.FACILITY}`,
      },
      {
        key: "wing",
        title: "Wing",
        to: `${LOCATION_DASHBOARD_ROUTE}?${LOCATION_LEVEL_PARAM}=${LOCATION_LEVELS.WING}`,
      },
      {
        key: "zone",
        title: "Zone",
        to: `${LOCATION_DASHBOARD_ROUTE}?${LOCATION_LEVEL_PARAM}=${LOCATION_LEVELS.ZONE}`,
      },
      {
        key: "subzone",
        title: "Subzone",
        to: `${LOCATION_DASHBOARD_ROUTE}?${LOCATION_LEVEL_PARAM}=${LOCATION_LEVELS.SUBZONE}`,
      },
    ],
  },
];


export {NAV_LINKS, MOBILE_NAV_LINKS, WAREHOUSE_NAV, PRODUCTION_NAV, PM_ORDER_NAV, LOCATION_NAV};
