import React from "react";
import PropTypes from "prop-types";
import {ItemText, ItemName} from "../../styled";
import {List} from "Base";

/* This a component to display a list of information. Each row is defined as {title: "foo", value: "bar"} and defaults
to the left side unless isRight is set to true*/
const SideList = ({baseClassName, isRight, list}) => {
  const side = isRight ? "-main-right" : "-main-left";
  const baseAndSide = baseClassName + side;

  return (
    <div className={baseAndSide}>
      <ItemName as={"h2"} className={`${baseAndSide}-text`}>
        {`${list[0].title}: ${list[0].value}`}
      </ItemName>

      <List
        split={false}
        size="small"
        dataSource={list.slice(1, 4)}
        renderItem={item => <ListItem item={item} baseAndSide={baseAndSide}/>}
      />
    </div>
  );
};

SideList.propTypes = {
  baseClassName: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired
};

export {SideList};

const ListItem = ({baseAndSide, item}) => {
  return (
    <ItemText className={`${baseAndSide}-text`}>
      <strong className={`${baseAndSide}-text-dark`}>
        {item.title}
      </strong>
      {": "}{item.value}
    </ItemText>
  );
};
