import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Flex} from "../../Base";
import {Input} from "./Inputs";
import {Modal} from "../../index";
import FormDropdown from "./FormDropdown";
import {OnChange} from "react-final-form-listeners";
import {DescriptionText} from "../../Modals/styled";
import {Label, LabelText} from "./styled";

class EditableFormDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentOption: undefined,
      createFieldShowing: false,
      editFieldShowing: false,
      deleteFieldModalShowing: false
    };
  }

  handleShowCreateField = () => {
    const {handleShowCreateFieldSuper} = this.props;
    this.setState({
      createFieldShowing: !this.state.createFieldShowing,
      editFieldShowing: false
    });
    handleShowCreateFieldSuper();
  };
  handleShowEditField = () => {
    const {handleShowEditFieldSuper} = this.props;
    this.setState({
      createFieldShowing: false,
      editFieldShowing: !this.state.editFieldShowing
    });
    handleShowEditFieldSuper();
  };

  hideCreateField = () => {
    this.setState({
      createFieldShowing: false
    });
  };

  hideEditField = () => {
    this.setState({
      editFieldShowing: false
    });
  };

  handleCreateFieldSubmit = () => {
    const {handleCreateField, newField} = this.props;
    handleCreateField(newField.name);
    this.hideCreateField();
  };

  handleEditFieldSubmit = () => {
    const {handleEditField, newField} = this.props;
    handleEditField(newField.id, newField.name);
    this.hideEditField();
  };

  toggleDeleteFieldModal = () => {
    this.setState({deleteFieldModalShowing: !this.state.deleteFieldModalShowing});
  };

  deleteFieldAndCloseModal = () => {
    const {handleDeleteField} = this.props;
    handleDeleteField();
    this.toggleDeleteFieldModal();
  };

  shouldShowEditButton = () => {
    const {
      selectedOption,
    } = this.props;

    const {
      currentOption
    } = this.state;
    let showEdit = false;

    if (selectedOption.name) {
      showEdit = true;
    }
    if (currentOption !== undefined) {
      showEdit = currentOption.value !== null;
    }
    return showEdit;
  };

  render() {
    const {
      createFieldShowing,
      editFieldShowing,
      deleteFieldModalShowing,
    } = this.state;
    const {
      name,
      label,
      dropdownName,
      canChangeSelection,
      canCreateSelections,
      options,
      selectedOption,
      newField,
      handleNewFieldNameChange,
      handleFieldSelect,
      format,
      parse,
      styles,
      includeNone,
      id
    } = this.props;

    let selectOptions = includeNone ? [{value: null, label: "None"}, ...options] : options;

    const showEdit = this.shouldShowEditButton();
    return (
      <Fragment>
        <Label htmlFor={name}>
          <LabelText>{label}: </LabelText>
        </Label>

        <Box className="item-details-form-flex" mb={"30px"}>
          <FormDropdown
            name={name}
            options={selectOptions}
            disabled={!canChangeSelection}
            format={format}
            parse={parse}
            styles={styles}
            id={id}
          />

          {canCreateSelections &&
          <Fragment>
            <OnChange name={name}>
              {(value, previous) => {
                // This will unhide the edit and trash buttons
                // and update the container's state
                if (previous && value && value.value !== previous.value) {
                  this.setState({currentOption: value});
                  if (handleFieldSelect) {
                    handleFieldSelect(value);
                  }
                }
              }}
            </OnChange>
            <FontAwesomeIcon
              id="dropdownPlus"
              icon="plus"
              size="lg"
              onClick={this.handleShowCreateField}
              data-tip={`Create New ${label}.`}
            />
            {showEdit && (
              <FontAwesomeIcon
                id="dropdownEdit"
                icon="edit"
                size="lg"
                onClick={this.handleShowEditField}
                data-tip={`Edit ${label}.`}
              />
            )}
            {showEdit && (
              <FontAwesomeIcon
                id="dropdownDelete"
                icon="trash"
                size="lg"
                onClick={this.toggleDeleteFieldModal}
                data-tip={`Delete ${dropdownName}.`}
              />
            )}
          </Fragment>
          }
        </Box>

        {(createFieldShowing || editFieldShowing) && (
          <Flex mb={"30px"}>
            <Input
              value={newField.id}
              readOnly={true}
              display="none"
              name="newFieldID"
            />
            <Input
              placeholder={`New ${dropdownName} Name.`}
              value={newField.name}
              type="text"
              mb={0}
              mr={"15px"}
              name="fieldNameNew"
              label={`${dropdownName} Name.`}
              readOnly={!canCreateSelections}
              onChange={handleNewFieldNameChange}
            />
            <Button type="button" mt={0}
              id="editOrCreateButton"
              disabled={newField.name === ""}
              onClick={() => {
                editFieldShowing ? this.handleEditFieldSubmit() : this.handleCreateFieldSubmit();
              }}>
              {editFieldShowing ? "Edit" : "Create"}
            </Button>
          </Flex>
        )}
        <Modal
          visible={deleteFieldModalShowing}
          title={`Delete ${selectedOption ? selectedOption.name : "Option"}?`}
          onCancel={this.toggleDeleteFieldModal}
          onOk={this.deleteFieldAndCloseModal}
          isDeleteModal
        >
          <DescriptionText>Deleting this {dropdownName} will remove it from any object.</DescriptionText>
        </Modal>
      </Fragment>
    );
  }
}

EditableFormDropdown.propTypes = {
  dropdownName: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  editAccess: PropTypes.bool,
  options: PropTypes.array,
  selectedOption: PropTypes.object,
  newField: PropTypes.object,
  handleFieldSelect: PropTypes.func,
  handleCreateField: PropTypes.func,
  handleEditField: PropTypes.func,
  handleNewFieldNameChange: PropTypes.func,
  styles: PropTypes.object,
  includeNone: PropTypes.bool
};

EditableFormDropdown.defaultProps = {
  name: "dropdown-field",
  label: "Dropdown",
  styles: {},
  canChangeSelection: false,
  canCreateSelections: false,
  includeNone: true
};


export default EditableFormDropdown;
