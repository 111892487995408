import React from "react";
import PropTypes from "prop-types";
import {Route, withRouter} from "react-router-dom";

import {
  InventoryContainer,
  PalletDetailsContainer,
  PalletsContainer,
  PurchaseOrderContainer,
  PurchaseOrderDetailsContainer,
  SalesOrdersContainer,
  SalesOrderDetailsContainer
} from "../containers/Warehouse";

import {MaterialDetailsContainer, MaterialsContainer, ProductDetailsContainer} from "../containers";
import {MATERIALS_ROUTE} from "./constants";


const WarehouseRoutes = ({match}) => {
  return (
    <>

      <Route
        exact
        path={`${match.path}/inventory`}
        name="Inventory"
        component={InventoryContainer}
      />
      <Route
        exact
        path={`${match.path}/inventory/:productID`}
        name="Product Details"
        component={ProductDetailsContainer}
      />

      <Route
        exact
        path={`${match.path}/pallets/`}
        name="Pallets Container"
        component={PalletsContainer}
      />

      <Route
        exact
        path={`${match.path}/pallets/:palletID`}
        name="Pallet Details"
        component={PalletDetailsContainer}
      />
      <Route
        exact
        path={`${match.path}/purchase_orders/`}
        name="Purchase Orders"
        component={PurchaseOrderContainer}
      />

      <Route
        exact
        path={`${match.path}/purchase_orders/:orderID`}
        name="Purchase Order Details"
        component={PurchaseOrderDetailsContainer}
      />

      <Route
        exact
        path={`${match.path}/sales_orders/`}
        name="Sales Orders"
        component={SalesOrdersContainer}
      />
      <Route
        exact
        path={`${match.path}/sales_orders/:orderID`}
        name="Sales Order Details"
        component={SalesOrderDetailsContainer}
      />
      <Route
        exact
        path={`${match.path}/${MATERIALS_ROUTE}`}
        name="Materials"
        component={MaterialsContainer}
      />
      <Route
        exact
        path={`${match.path}/${MATERIALS_ROUTE}/:materialID`}
        name="Material Details"
        component={MaterialDetailsContainer}
      />
      {/* <Redirect from="/dashboard/warehouse" to="/dashboard/warehouse/inventory"/>*/}

    </>
  );
};

WarehouseRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(WarehouseRoutes);
