import React, {useState} from "react";
import PropTypes from "prop-types";
import {FormDrawer} from "../../Drawers";
import {Plus} from "Base/Icons";
import {Flex} from "Base";
import {ReactSelectAdapter} from "../../Forms/Base/FormDropdown";

const defaultHandleResponse = (res) => {
  let newOption = null;
  if (!res.error) {
    if (res.payload) {
      newOption = {id: res.payload.id, value: res.payload.id, label: res.payload.name};
    } else if (res.id) {
      newOption = {id: res.id, value: res.id, label: res.name};
    }
  }
  return newOption;
};

const CreateSelectField = props => {
  const {
    onSubmit,
    values,
    form,
    text,
    options,
    input,
    meta,
    inputConfig,
    errors,
    InputComponent,
    handleResponse,
    ...rest
  } = props;

  const [newOption, setNewOption] = useState([]);
  const {onChange, name} = input;

  // updates Field value and return res to FormDrawer to process errors
  const handleSubmit = async (values) => {
    let res = await onSubmit(values);
    const newOption = handleResponse(res);

    onChange(newOption);

    setNewOption([newOption]);

    return res;
  };


  const finalOptions = [
    ...options,
    ...newOption,
  ];
  return (
    <Flex flexDirection={"row"} alignItems={"center"} maxWidth={["300px", "500px"]}>
      <InputComponent
        name={name}
        input={input}
        meta={meta}
        style={{width: 150, marginRight: "10px", marginLeft: "25px"}}
        options={finalOptions}
        {...inputConfig}
        {...rest}
      />
      <FormDrawer
        onSubmit={handleSubmit}
        FormComponent={form}
        values={values}
        text={text}
        toggleIcon={<Plus size={"sm"}/>}
        formID={`${name}-form`}
        buttonConfig={{
          size: "small",
          type: "default",
          shape: "circle",
          ariaLabel: "Open New Supplier Form",
          style: {marginLeft: "5px"},
        }}
      />
    </Flex>
  );
};

CreateSelectField.propTypes = {
  form: PropTypes.elementType.isRequired,
  text: PropTypes.shape({
    title: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  name: PropTypes.string,
  options: PropTypes.array,
  drawerFormID: PropTypes.string,
  onSubmit: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  inputConfig: PropTypes.object,
  InputComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  handleResponse: PropTypes.func, // signature: (res)
};

CreateSelectField.defaultProps = {
  text: {
    title: "New Item",
    tooltip: "Create A New Item",
  },
  inputConfig: {
    size: "small",
  },
  InputComponent: ReactSelectAdapter,
  handleResponse: defaultHandleResponse
};

export default CreateSelectField;
