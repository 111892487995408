import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Note from "./Note";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// How many comments to show before putting them in a collapsible section
const NUM_COMMENTS_TO_SHOW = 5;

/* This component will have all notes passed in and displayed for the user. */
class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreComments: false
    };
  }

  toggleExpand = () => {
    this.setState({showMoreComments: !this.state.showMoreComments});
  };

  render() {
    const {
      notes,
      title,
      objectEndpoint,
      objectID,
      accountID,
      displayStatus,
      files,
      refreshObject
    } = this.props;
    const {showMoreComments} = this.state;

    return (
      <Fragment>
        {(notes.length !== 0) &&
        <Fragment>
          <h2>{title}</h2>
          {notes.slice(0, NUM_COMMENTS_TO_SHOW).map((note, index) => {
            return (
              <Note
                key={note.id}
                note={note}
                objectEndpoint={objectEndpoint}
                objectID={objectID}
                isDisabled={accountID !== note.created_by.account_id}
                displayStatus={displayStatus}
                files={files}
                refreshObject={refreshObject}
              />
            );
          })}
          {notes.length > NUM_COMMENTS_TO_SHOW &&
          <Fragment>
            <div className="item-details-pane-right-expand">
              <span className="item-details-pane-right-expand-text" onClick={this.toggleExpand}>
                  SHOW MORE COMMENTS{" "}
                {(!showMoreComments)
                  ? <FontAwesomeIcon
                    className="item-details-pane-right-expand-icon"
                    icon="caret-up"
                    size="lg"
                  />
                  : <FontAwesomeIcon
                    className="item-details-pane-right-expand-icon"
                    icon="caret-down"
                    size="lg"
                  />
                }
              </span>
            </div>
            {showMoreComments ? notes.slice(NUM_COMMENTS_TO_SHOW).map((note, index) => {
              return (
                <Note
                  key={index}
                  note={note}
                  objectEndpoint={objectEndpoint}
                  objectID={objectID}
                  displayStatus={displayStatus}
                  files={files}
                />
              );
            }) : ""}
          </Fragment>
          }
        </Fragment>
        }
      </Fragment>
    );
  }
}

Notes.propTypes = {
  notes: PropTypes.array,
  title: PropTypes.string,
  objectEndpoint: PropTypes.string.isRequired,
  objectID: PropTypes.number,
  accountID: PropTypes.number,
  displayStatus: PropTypes.bool,
  files: PropTypes.array,
  refreshObject: PropTypes.func.isRequired
};

Notes.defaultProps = {
  notes: [],
  files: [],
  displayStatus: false
};
export default Notes;
