import vendorReducer from "./reducers";

/* These exports are the only thing that need to be edited for a new module */
export const name = "v1/vendor_orders/";

export {default as vendorsTypes} from "./types";
export {default as vendorsSelectors} from "./selectors";
export {default as vendorsOperations} from "./operations";
export {default as vendorsActions} from "./actions";

export default vendorReducer;
