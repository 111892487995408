import React, {Fragment, useState} from "react";
import IssueBar from "../../components/Common/Bars/IssueBar";
import PropTypes from "prop-types";
import {checkNestedKeys} from "../../utils/propUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Collapse} from "../../components/Common/Base";

export default function IssueContainer({issues, submit, user_type, personnel, isActiveIssue, service_org, isWindowLarge}) {
  const [isShowClosedIssues, setShowClosedIssues] = useState(false);
  const [isShowOpenIssues, setShowOpenIssues] = useState(true);

  const seperateIssuesByActive = () => {
    let openIssues = [];
    let closedIssues = [];
    for (let i = 0; i < issues.length; i++) {
      if (issues[i].is_active) {
        openIssues.unshift(issues[i]);
      } else {
        closedIssues.unshift(issues[i]);
      }
    }
    return {openIssues, closedIssues};
  };

  const type = checkNestedKeys(["default_org", "type"], personnel);
  const {openIssues, closedIssues} = seperateIssuesByActive();

  return (
    <Fragment>
      {type === "Client" && isActiveIssue && <div style={{fontSize: "16px"}}>
                A problem has been reported by the service provider.
                The order cannot proceed until the service provider marks the issue as resolved.
      </div>}
      <span className="issue-bar-title">OPEN ISSUES{`  (${openIssues.length})  `}</span>
      {openIssues.length > 0 && <FontAwesomeIcon
        icon={`caret-${isShowOpenIssues ? "down" : "up"}`}
        size="2x"
        style={{cursor: "pointer"}}
        onClick={() => setShowOpenIssues(!isShowOpenIssues)} />}
      <Collapse mt={4} isOpen={isShowOpenIssues}>
        {openIssues.map(issue => (
          <IssueBar
            key={issue.id}
            {...issue}
            user_type={user_type}
            submit={submit}
            type={type}
            isOpen={true}
            isWindowLarge={isWindowLarge}
            service_org={service_org}
            personnel={personnel} />))}
      </Collapse>
      <span className="issue-bar-title">CLOSED ISSUES{`  (${closedIssues.length})  `}</span>
      {closedIssues.length > 0 && <FontAwesomeIcon
        icon={`caret-${isShowClosedIssues ? "down" : "up"}`}
        size="2x"
        style={{cursor: "pointer"}}
        onClick={() => setShowClosedIssues(!isShowClosedIssues)} />}
      <Collapse mt={4} isOpen={isShowClosedIssues}>
        {closedIssues.map(issue => {
          return (
            <IssueBar
              key={issue.id}
              {...issue}
              user_type={user_type}
              submit={submit}
              type={type}
              isOpen={false}
              service_org={service_org}
              personnel={personnel} />
          );
        })}
      </Collapse>
      <br />
      <br />
    </Fragment>
  );
}

IssueContainer.propTypes = {
  issues: PropTypes.array,
  user_type: PropTypes.number,
  submit: PropTypes.func,
  isActiveIssue: PropTypes.bool,
  service_org: PropTypes.number,
  isWindowLarge: PropTypes.bool
};

IssueContainer.defaultProps = {
  issues: [],
};
