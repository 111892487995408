import React, {Component} from "react";
import {connect} from "react-redux";
import {
  authSelectors, baseSelectors, orgSelectors,
  paginationSelectors,
  salesOrderActions,
  salesOrderOperations,
} from "../../../store";
import {SalesOrders} from "../../../components/Warehouse";

import {fetchPageWithDefaults} from "../../../store/base/paginator/utils";

const mapStateToProps = (state) => {
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    loading: paginationSelectors.getIsCurrentPageLoading(state.warehouse.sales.items),
    // hasErrors: baseSelectors.hasErrors(state.warehouse.sales),
    errors: baseSelectors.refreshErrors(state.warehouse.sales),
    csvMessages: baseSelectors.refreshMessages(state.warehouse.sales),
    // new pagination
    pagination: paginationSelectors.pagination(state.warehouse.sales.items),
    locationList: orgSelectors.refreshLocations(state.orgs),

  };
};

const defaultParams = {
  expand: "purchase_order,location"
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getPage: (page = 1, params = {}) => dispatch(salesOrderActions.fetchPage({page, params})),
    getPage: (page = 1, params = {}) => fetchPageWithDefaults({
      dispatch,
      action: salesOrderActions.fetchPage,
      page,
      params,
      defaultParams
    }),
    changeView: (view) => dispatch(salesOrderActions.changeView(view)),
    createItem: (data) => dispatch(salesOrderOperations.createItem(data)),
  };
};

class SalesOrdersContainer extends Component {
  render() {
    return (
      <SalesOrders {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesOrdersContainer);
