import React from "react";
import PropTypes from "prop-types";

// This is the middle bottom strip of the Bar layout, you can populate it with up to 2 buttons (or small components)
const Bottom = ({baseClassName, leftButton, rightButton}) => {
  return (
    <div className={`${baseClassName}-sub`}>
      {leftButton &&
            <div className={`${baseClassName}-sub-left`}>
              {leftButton}
            </div>
      }
      {rightButton &&
            <div className={`${baseClassName}-sub-right`}>
              {rightButton}
            </div>
      }
    </div>
  );
};

Bottom.propTypes = {
  baseClassName: PropTypes.string,
  leftButton: PropTypes.node,
  rightButton: PropTypes.node,
};

export {Bottom};
