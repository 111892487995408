import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormField} from "../Base";
import {postLoadFormat, validate} from "./utils";
import {SubmitButton} from "../styled";

const PasswordForm = (props) => {
  const {
    onSubmit,
    values,
  } = props;

  const minPassLength = 8;
  const tooShortMessage = `Password must be at least ${minPassLength} characters long`;

  // Use postLoadFormat and preSaveFormat the format data
  const initialValues = postLoadFormat(values);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"item-details-form"} onSubmit={handleSubmit}>
          <FormField
            type="password"
            name="oldPassword"
            label="Old Password"
            minLength={minPassLength}
            tooShort={tooShortMessage}
          />
          <FormField
            type="password"
            name="newPassword"
            label="New Password"
            minLength={minPassLength}
            tooShort={tooShortMessage}
          />
          <FormField
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            minLength={minPassLength}
            tooShort={tooShortMessage}
          />
          <SubmitButton
            className="item-details-form-button-pane"
            htmlType="submit"
            type={"primary"}
            loading={submitting}>
            Save
          </SubmitButton>
        </form>
      )}
    />
  );
};

PasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool
};

PasswordForm.defaultProps = {
  values: {},
  submitting: false,
  invalid: false
};

export default PasswordForm;
