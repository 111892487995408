import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormDropdown} from "../Base";
import {Row} from "./styled";
import {SubmitButton} from "../styled";

/*
This component is used for Assigning a schedule to Personnel
Takes a scheduleList and provides a dropdown with a single "schedule" field.
 */
const AssignScheduleForm = props => {
  const {onSubmit, scheduleList, editAccess, validate} = props;
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form onSubmit={handleSubmit} className={"assign-schedule-form"}>
          <FormDropdown
            options={scheduleList}
            isDisabled={!editAccess}
            name="schedule"
            label="SELECT SCHEDULE"
            format={(value, name) => {
              if (value && typeof value === "number") {
                return scheduleList.find(el => el.id === value);
              }
              return (value ? {
                value: value.id || value.value,
                label: value.name || value.label
              } : "");
            }}
            parse={(value, name) => {
              return (value ? (value.id || value.value) : "");
            }}
          />

          <Row py={2} alignItems={"center"}>

            <SubmitButton className="item-details-form-button-pane submit-schedule">
              ASSIGN SCHEDULE
            </SubmitButton>
          </Row>
        </form>
      )}
    />

  );
};

AssignScheduleForm.propTypes = {
  onSubmit: PropTypes.func,
  personnelList: PropTypes.array,
  editAccess: PropTypes.bool,
  scheduleList: PropTypes.array
};

AssignScheduleForm.defaultProps = {
  editAccess: true
};

export default AssignScheduleForm;
