import actionTyper from "redux-actiontyper";

/**
 * Create all the needed barcode types
 * @param {string} name The name of the module
 * @returns {object} The barcode types
 */
const createBarcodeActionTypes = (name) => {
  const {
    GENERATE_BARCODE_REQUEST,
    GENERATE_BARCODE_SUCCESS,
    GENERATE_BARCODE_FAILURE
  } = actionTyper(`@@${name}/`);

  return {
    GENERATE_BARCODE_REQUEST,
    GENERATE_BARCODE_SUCCESS,
    GENERATE_BARCODE_FAILURE
  };
};

export {createBarcodeActionTypes};
