import {barcodeCsvFields, baseCsvFields, makeCsvColumn} from "../../../utils/csv";

export const selectionOptions = [
  {value: "name", label: "Name"},
  {value: "created_at", label: "Created At"},
  {value: "updated_at", label: "Updated At"},
];

export const csvColumns = [
  makeCsvColumn("name"),
  makeCsvColumn("manufacturer"),
  makeCsvColumn("sku"),
  makeCsvColumn("price_currency"),
  makeCsvColumn("price"),
  ...barcodeCsvFields,
  ...baseCsvFields
];

export const sampleData = [
  {
    name: "Iron",
    manufacturer: "Madara Uchiha",
    sku: "10",
    price_currency: "USD",
    price: "500",
    notes: "Iron is also known as Ferrous",
    barcode_format: "code128",
    barcode_data: "ABC-fgr-1543"
  }
];
