import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {DayPickerSingleDateController as BaseDatePicker, isInclusivelyAfterDay} from "react-dates";
import {DatePickerWrapper, datePopoverContainerStyles} from "../styles/styles";
import {Box, Input as BaseInput, InputGroup} from "@chakra-ui/core";
import Popover from "react-tiny-popover";
import {adapt, dateAdapter} from "../../../Forms/Base/utils";
import moment from "moment";
import "react-dates/initialize";

const InputWrapper = styled(InputGroup)`
  input::-webkit-calendar-picker-indicator{
    display: none;
}

  input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
}
`;

const AdaptedInput = adapt(BaseInput);
const SingleDatePicker = (props) => {
  const {value, onChange, calendarOptions, defaultCalendarOptions, ...rest} = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleDateChange = (date) => {
    let parseDate = moment(date).format("YYYY-MM-DD");
    if (onChange) {
      onChange(parseDate);
    }
  };
  const currentValue = value ? moment(value) : null;

  const inputValue = value ? moment(value).format("MM/DD/YYYY") : null;
  return (
    <Box className={"single-date-picker"}>
      <Popover
        isOpen={isFocused}
        onClickOutside={() => setIsFocused(false)}
        position={"bottom"}
        align={"start"}
        containerStyle={datePopoverContainerStyles}
        closeOnBlur={true}
        content={(

          <DatePickerWrapper>
            <BaseDatePicker
              hideKeyboardShortcutsPanel
              focused={isFocused}
              onFocusChange={({focused}) => setIsFocused(focused)} // PropTypes.func.isRequired
              onDateChange={handleDateChange}
              date={currentValue}
              {...defaultCalendarOptions}
              {...calendarOptions}
            />
          </DatePickerWrapper>
        )}
      >
        <InputWrapper>
          <AdaptedInput type="date" mb={1} {...rest} onClick={() => setIsFocused(!isFocused)} value={inputValue} onChange={onChange}/>

        </InputWrapper>

      </Popover>
    </Box>
  );
};

SingleDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  calendarOptions: PropTypes.object,
  defaultCalendarOptions: PropTypes.object,
};

SingleDatePicker.defaultProps = {
  defaultCalendarOptions: {
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment())
  }
};

export default dateAdapter(SingleDatePicker);
