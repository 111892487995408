import React, {Component} from "react";
import {Redirect} from "react-router-dom";
// Components and constants
import {Calendar} from "../../Common";
import {ErrorHeading} from "../../Common/Base";
import {PersonnelBar} from "../Bar/PersonnelBar";
import {PersonnelTable} from "../Table/PersonnelTable";
import {selectionOptions, csvColumns, sampleData} from "./constants";
import {personnelHelp} from "../../Common/HelpItems/constants";
// Utilities
import {checkForResponseError, handleErrorResponse, scrollToTop} from "../../../utils/containerUtils";
import {formatCalendarRange} from "../../../utils/dates";
import {postAxios} from "../../../utils/authWithAxios";
import {OldStyleDashboard} from "../../Common/Dashboard/OldStyleDashboard/OldStyleDashboard";
import CalendarEvent from "../../Common/Calendar/CalendarEvent";

/* This will be the Personnel dashboard where a user can create, search, manipulate, and destroy Personnel */
class PersonnelDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedPersonnelID: "",
      addItemModalSubmitting: false,
      addItemModalError: false,
      errorLoadingMessage: ""
    };
  }

  /* Upon mounting, fetch a list of personnel and store the array in redux. They
    will be rendered using the map method. */
  componentDidMount = () => {
    this.fetchPersonnel();
  };

  fetchPersonnel = async () => {
    const {getPersonnelList, sortPersonnel} = this.props;
    await getPersonnelList()
      .then(res => checkForResponseError(res))
      .catch(err => {
        handleErrorResponse(this, err, "PersonnelDashboard");
      });
    sortPersonnel("created_at");
    this.handleRefreshCalendar();
    scrollToTop();
  };

  handleRefreshCalendar = () => {
    const {calendarDates, getShiftCalendar} = this.props;
    getShiftCalendar(calendarDates);
  };

  handleDateChange = (event) => {
    const {getShiftCalendar} = this.props;
    const dateRange = formatCalendarRange(event);
    getShiftCalendar(dateRange);
  };

  handleCalendarEvent = (event) => {
    console.log("clicked", event);
  };

  handleUploadCsv = async (data) => {
    const {getPersonnelList, uploadCsv, accountInfo} = this.props;
    await uploadCsv(data, accountInfo.personnel.orgs[0].id);
    getPersonnelList();
  };

  handleTableFieldChange = async data => {
    const {updateDetails, getPersonnelList} = this.props;
    const newData = data[0];
    const index = newData.dataIndex;
    const id = newData.key;
    const updateData = {
      [index]: newData[index]
    };
    await updateDetails(id, updateData);
    getPersonnelList();
  }

  /* This function will perform the API request required to add a new personnel. The new personnel
    might have one attribute defined with key and value pair,
    the user will be forwarded to the Edit PersonnelDashboard page for the new personnel where he can then enter details. */
  handleSubmitAddItem = (key = "", value = "") => {
    const {accountInfo} = this.props;
    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false
    });
    const dataToSubmit = {
      default_org: accountInfo.personnel ? accountInfo.personnel.orgs[0].id : null,
      name: "",
      description: "",
      role: 6,
      needs_approval: false
      // Tags: []
    };
    if (key !== "" && value !== "") {
      dataToSubmit[key] = value;
    }
    postAxios({
      url: `${global.base_url}v1/personnel/`,
      data: dataToSubmit
    })
      .then(res => {
        console.log(res);
        this.setState({
          addedPersonnelID: res.data.id,
          addItemModalSubmitting: false
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          addItemModalSubmitting: false,
          addItemModalError: true
        });
      });
  };

  render() {
    const {
      addItemModalSubmitting,
      addItemModalError,
      addedPersonnelID,
      errorLoadingMessage
    } = this.state;
    const {
      personnelList,
      calendarView,
      accountInfo,
      calendar,
      filterPersonnel,
      sortPersonnel
    } = this.props;

    // Privileges for editing asset
    const personnelEditAccess = accountInfo.user_type <= 2;

    /* Upon adding an asset redirect to edit asset page for that particular asset. */
    if (addedPersonnelID)
      return <Redirect to={`/dashboard/personnel/${addedPersonnelID}`}/>;

    return (
      <section>
        <OldStyleDashboard
          {...this.props}
          handleUploadCsv={this.handleUploadCsv}
          csvColumns={csvColumns}
          csvData={sampleData}
          selectionOptions={selectionOptions}
          hasEditAccess={personnelEditAccess}
          handleSubmitAddItem={this.handleSubmitAddItem}
          helpDialog={personnelHelp}
          type="Personnel"
          addItemModalSubmitting={addItemModalSubmitting}
          addItemModalError={addItemModalError}
          currentPage={personnelList}
          barComponent={<PersonnelBar />}
          filterItems={filterPersonnel}
          sortItems={sortPersonnel}
          tableComponent={<PersonnelTable handleFieldChange={this.handleTableFieldChange}/>}
        />
        {calendarView && calendar.length > 0 &&
          <Calendar
            items={calendar}
            onEventClick={this.handleCalendarEvent}
            onRangeChange={this.handleDateChange}
            components={{
              event: CalendarEvent
            }}
          />
        }
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}
      </section>
    );
  }
}

export default PersonnelDashboard;
