import React from "react";
import PropTypes from "prop-types";

/* This Component displays help information in bulletted format
   with and image attatched for visual aid and requires two props:

   image: an image file,
   content: an array of strings that will be the bullet points
*/

const HelpBox = (props) => {
  // Size the image
  const imgStyle = {
    width: "15%",
    height: "15%"
  };

  // Override main class modal-card-button
  const btnStyle = {
    marginLeft: "10%",
    marginTop: "2em"
  };

  const {
    image,
    content,
    objType
  } = props;

  return (
    <div className="help-view-box-ul">
      <img style={imgStyle} src={image} alt={""}/>
      <ul>
        {content.map((bullet, idx) => (
          <li key={idx} className="help-view-box-li">
            <h1>{bullet}</h1>
          </li>
        ))}
      </ul>
      <a href={`/#/dashboard/${objType}`}>
        <button style={btnStyle} className="modal-card-button">Try it!
        </button>
      </a>
    </div>
  );
};

HelpBox.propTypes = {
  image: PropTypes.node,
  content: PropTypes.array.isRequired,
  objType: PropTypes.string
};

HelpBox.defaultProps = {
  content: ["1", "2", "3", "4"],
};

export default HelpBox;
