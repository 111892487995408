import React from "react";
import {Provider} from "react-redux";
import {ThemeProvider} from "emotion-theming";

import {Global} from "@emotion/core";

import store from "./store";
import Routing from "./routes";
import theme from "./theme";
import globalStyle from "./theme/globalStyle";
import importIcons from "./static/importIcons";

import "./static/scss/main.scss";

import "./config/env";

import initializeStoreData from "./config/initializeStoreData";

importIcons();
initializeStoreData(store);


const App = () => (
  <ThemeProvider theme={theme}>
    <Global styles={globalStyle}/>
    <Provider store={store}>
      <Routing/>
    </Provider>
  </ThemeProvider>
);

export default App;
