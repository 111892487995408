import actionTyper from "redux-actiontyper";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_NONE_FOUND,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,

  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CLEAR_MESSAGES,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE
} = actionTyper("@@materials/");

export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_NONE_FOUND,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,

  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CLEAR_MESSAGES,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE
};
