import React from "react";
import PropTypes from "prop-types";
import {crudActions} from "../../../../../types/propTypes";
import {EditableTable} from "../../../../Common/Tables";
import {entryColumns} from "./constants";

const EntryTable = props => {
  const {
    data,
    actions
  } = props;

  const handleDelete = async (id) => {
    await actions.delete(id);
    actions.getPage();
    actions.getProductionLine();
  };

  // const title = `${item.start} - ${item.end}`;

  const handleSubmit = async (values) => {
    console.log("submit values", values);

    const {date, start, end, id, sales_order, product, ...rest} = values;
    await actions.update(values.id, rest);
    actions.getPage();
  };


  const tableData = data?.map(item => ({key: item.id, ...item})) ?? [];
  return (
    <EditableTable
      rowClassName={"entry-row"}
      size={"small"}
      scroll={{x: 420}}
      columns={entryColumns}
      data={tableData}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      rowKey={"id"}
      pagination={false}
    />
  );
};

EntryTable.propTypes = {
  data: PropTypes.array,
  item: PropTypes.object,
  actions: crudActions
};

export default EntryTable;
