import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import jsPDF from "jspdf";
import {Box, Button, Heading, Tooltip} from "../Base";
import BarcodeScanner from "./BarcodeScanner";
import Barcode from "./Barcode";
import ErrorBoundary from "../Base/ErrorBoundary";
import {BARCODE_MAP, barcodeFormatStringToInt} from "./constants";

/**
 * Some magic number that jsPDF uses to convert a measurement to inches
 * @type {number}
 */
const INCH_CONVERT = 72;

/* This container will show the barcode that is attached to an object, and allow adding, updating, and removing it */
class BarcodeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanningBarcode: false,
      barcodeData: "",
      barcodeFormat: "",
      isNewBarcode: false,
    };
    this.barcodeRef = React.createRef();
  }

  componentDidMount() {
    const {data, format} = this.props;
    this.setState({
      barcodeData: data,
      barcodeFormat: format,
    });
  }

  handleBarcodeScan = ({codeResult: {code, format}}) => {
    this.setState({
      barcodeData: code,
      barcodeFormat: format,
      scanningBarcode: false,
      isNewBarcode: true,
    });
  };

  handleExportBarcode = () => {
    if (this.state.barcodeData !== "") {
      console.log(this.barcodeRef.current);
      let document = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4 * INCH_CONVERT, 6 * INCH_CONVERT],
      });
      document.addImage(this.barcodeRef.current, "JPEG", 0.5, 0.5, 5, 3);
      document.save("file.pdf");
    }
  };

  handleSaveBarcode = () => {
    const {barcodeData, barcodeFormat} = this.state;
    this.setState({isNewBarcode: false});
    this.props.updateBarcode({
      "barcode": {
        "data": barcodeData,
        "format": barcodeFormatStringToInt(barcodeFormat),
      },
    });
  };

  handleGenerateBarcode = () => {
    this.setState({isNewBarcode: false});
    this.props.generateBarcode().then(({payload: {data, format}}) => {
      this.setState({
        barcodeData: data,
        barcodeFormat: format,
      });
    }).catch(() => console.error("Failed to generate barcode"));
  };

  handleDeleteBarcode = () => {
    this.setState({
      isNewBarcode: false,
      barcodeData: "",
      barcodeFormat: "",
    });
    this.props.updateBarcode({
      "barcode": null,
    });
  };

  render() {
    const {barcodeData, barcodeFormat, isNewBarcode, scanningBarcode} = this.state;
    const barcodeIsEmpty = barcodeData === "" || barcodeData === undefined;
    const format = typeof (barcodeFormat) === "number" ? BARCODE_MAP[barcodeFormat] : barcodeFormat;
    return (
      <Box m={"20px 0 30px 0"}>
        <Heading mb={"10px"} color={"colorPrimary"}>BARCODE</Heading>
        {barcodeIsEmpty &&
        //  TODO: Add support for generating different types of barcodes
        <Button
          block
          style={{marginBottom: "10px"}}
          type={"primary"}
          className="item-details-form-button generate-barcode"
          onClick={this.handleGenerateBarcode}>
          Generate Random Barcode
        </Button>
        }
        <Button
          block
          type={"primary"}
          className="item-details-form-button scan-barcode"
          onClick={() => this.setState({scanningBarcode: !scanningBarcode})}>
          Scan {!barcodeIsEmpty ? " New" : " "} Barcode
        </Button>
        {scanningBarcode &&
        <Fragment>
          <BarcodeScanner onDetected={this.handleBarcodeScan}/>
          <Box mt="20px">
            <Tooltip title="Currently only Code-128 barcodes are supported">Having Issues?</Tooltip>
          </Box>
        </Fragment>
        }
        {!barcodeIsEmpty &&
        <Fragment>
          <ErrorBoundary errorText="Unable to render barcode">
            <Barcode
              data={barcodeData}
              format={format}
              barcodeRef={this.barcodeRef}/>
          </ErrorBoundary>
          <Button
            style={{marginBottom: "10px"}}
            className="item-details-form-button export-barcode"
            onClick={this.handleExportBarcode}>
            Export Barcode
          </Button>
          {isNewBarcode ?
            <Button className="item-details-form-button save-barcode" onClick={this.handleSaveBarcode}>
              Save Barcode
            </Button>
            :
            <Button className="item-details-form-button delete-barcode" onClick={this.handleDeleteBarcode}>
              Delete Barcode
            </Button>
          }
        </Fragment>
        }
      </Box>
    );
  }
}

BarcodeSection.propTypes = {
  data: PropTypes.string,
  format: PropTypes.number,
  updateBarcode: PropTypes.func.isRequired,
  generateBarcode: PropTypes.func.isRequired,
};

BarcodeSection.defaultProps = {
  data: "",
  format: -1,
};

export default BarcodeSection;
