import React from "react";
import {basePreSaveFormat} from "../../../Common/Forms/Base/utils";
// Import moment from 'moment';


export const load = async (values) => {
  console.info("Loading...");
  let result = values;

  console.log("result", result);
  return result;
};


export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  let result = values;

  if (values.personnel) {
    result.personnel = {id: values.personnel.id, label: values.personnel.name};
  }

  return result;
};


export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  delete result.pm_order;
  delete result.asset;
  delete result.stockroom_asset;

  if (values.personnel && values.personnel.id) {
    result.personnel = values.personnel.id;
  }

  if (values.status && values.status.id) {
    result.status = values.status.id;
  }

  return result;
};
