import {baseCsvFields, makeCsvColumn} from "../../../utils/csv";

export const csvColumns = [
  makeCsvColumn("name"),
  makeCsvColumn("asset_id"),
  makeCsvColumn("purchased_date"),
  makeCsvColumn("implemented_date"),
  makeCsvColumn("status"),
  makeCsvColumn("tags"),
  ...baseCsvFields
];

export const makeSampleData = (asset) => {
  let result = [
    {
      asset_id: asset.id,
      name: "Chicago Yards 22156",
      UUID: "22156",
      purchased_date: "1997-01-22",
      implemented_date: "1997-04-23",
      status: "Functioning Normally",
      tags: "\"Chicago, Trains\""
    },
    {
      asset_id: asset.id,
      name: "Topeka Yards 11231",
      UUID: "11231",
      purchased_date: "1997-01-22",
      implemented_date: "1997-04-23",
      status: "Functioning Normally",
      tags: "\"Topeka, Trains\""
    },
    {
      asset_id: asset.id,
      name: "Fort Worth Yards 43432",
      UUID: "43432",
      purchased_date: "1997-01-22",
      implemented_date: "1997-04-23",
      status: "Functioning Normally",
      tags: "\"Fort Worth, Trains\""
    }
  ];

  return result;
};
