// customers duck
import {combineReducers} from "redux";
import {createCrud, createNestedCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {customerEndpoint} from "./constants";

const customerPaginator = createPaginator({
  name: "customer",
  endpoint: customerEndpoint
});

export const customerCrud = createCrud({
  endpoint: customerEndpoint,
  name: "customer",
  withNotes: true
});


export const customerAddressCrud = createNestedCrud({
  endpoint: customerEndpoint,
  nestedEndpoint: "address/",
  name: "customerAddress"
});

// Customer
const customerActions = customerCrud.actions;
const customerOperations = customerCrud.operations;
const customerPageActions = customerPaginator.actions;


// Address
const customerAddressActions = customerAddressCrud.actions;

export {
  customerPaginator,
  customerPageActions,
  customerActions,
  customerOperations,
  customerAddressActions
};


export default combineReducers({
  items: customerPaginator.reducer(),
  details: customerCrud.reducer(),
});
