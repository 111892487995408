import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {Box, Button} from "../../Base";
import {LocationModal} from "../../index";
import LocationCard from "./LocationCard";

const LocationSection = props => {
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const {
    locationList,
    locationData,
    isAdmin,
    submit,
    blockButton,
    hideCard,
    buttonConfig,
    wrapperStyle,
    allowLocationEdit,
    buttonDisabled
  } = props;

  return (
    <Box {...wrapperStyle} className={"location-section"}>
      {allowLocationEdit &&
      <Fragment>
        <Button
          block={blockButton}
          htmlType={"submit"}
          type={"primary"}
          className={"item-details-form-button assign-location"}
          onClick={() => setLocationModalOpen(!locationModalOpen)}
          disabled={buttonDisabled}
          {...buttonConfig}
        >
          {Object.keys(locationData).length !== 0 ? "Change Location" : "Assign Location"}
        </Button>
        <LocationModal
          visible={locationModalOpen}
          headerText={locationList.length !== 0 ? "Assign a Location" : "No locations available"}
          locationList={locationList}
          closeModal={() => setLocationModalOpen(!locationModalOpen)}
          assignedLocation={locationData}
          submit={submit}
          isAdmin={isAdmin}/>
      </Fragment>
      }
      {!hideCard && Object.keys(locationData).length !== 0 &&
      <LocationCard
        region={locationData.region}
        district={locationData.district}
        facility={locationData.facility}
        wing={locationData.wing}
        zone={locationData.zone}
        subzone={locationData.subzone}/>
      }
    </Box>
  );
};

LocationSection.propTypes = {
  locationData: PropTypes.object.isRequired,
  locationList: PropTypes.array,
  isAdmin: PropTypes.bool,
  submit: PropTypes.func,
  blockButton: PropTypes.bool,
  hideCard: PropTypes.bool,
  buttonConfig: PropTypes.object,
  wrapperStyle: PropTypes.object,
  /**
   * Whether to allow editing the location
   */
  allowLocationEdit: PropTypes.bool,
  /**
   * Whether the location modal button is disabled
   */
  buttonDisabled: PropTypes.bool,
};

LocationSection.defaultProps = {
  locationData: {},
  blockButton: false,
  hideCard: false,
  buttonConfig: {},
  wrapperStyle: {
    mt: "20px"
  },
  allowLocationEdit: true,
  buttonDisabled: false,
};

export default LocationSection;
