import {barcodeCsvFields, baseCsvFields, makeCsvColumn} from "../../utils/csv";

export const csvColumns = [
  makeCsvColumn("name"),
  makeCsvColumn("asset"),
  makeCsvColumn("purchase_date"),
  makeCsvColumn("implemented_date"),
  makeCsvColumn("status"),
  makeCsvColumn("tags"),
  ...barcodeCsvFields,
  ...baseCsvFields
];

export const sampleData = [
  {
    name: "StockRoom_ACS550",
    asset: "ACS550",
    purchased_date: "1/9/05",
    implemented_date: "1/9/05",
    status: "active",
    tags: "\"acs550, abb, ac drive\"",
    notes: "it is chicken"
  }
];
