import {getItems} from "../../base/paginator/selectors";
import selectors from "../common/selectors";

const refreshNameList = (state) => {
  const items = getItems(state);
  if (items) {
    return items.map(product => ({
      id: product.id,
      manufacturer: product.manufacturer,
      sku: product.sku,
      price: product.price || "",
      price_currency: product.price_currency || "",
      value: product.id,
      label: product.name
    }));
  }
  return [];
};

const refreshFacilityList = selectors.refreshFacilityList;

export default {
  refreshNameList,
  refreshFacilityList
};
