import actionTyper from "redux-actiontyper";

const {
  AUTHENTICATION_REQUEST,
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  TOKEN_REQUEST,
  TOKEN_RECEIVED,
  TOKEN_FAILURE,

  ACCOUNT_INFO_REQUEST,
  ACCOUNT_INFO_STORE,
  ACCOUNT_INFO_FAILURE,

  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,

  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,

  CONFIRM_INVITATION_SUCCESS,
  CONFIRM_INVITATION_FAILURE,

  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,

  CONFIRM_PASSWORD_RESET_REQUEST,
  CONFIRM_PASSWORD_RESET_SUCCESS,
  CONFIRM_PASSWORD_RESET_FAILURE
} = actionTyper("@@auth/");

export {TOKEN_RECEIVED};
export default {
  AUTHENTICATION_REQUEST,
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  TOKEN_REQUEST,
  TOKEN_RECEIVED,
  TOKEN_FAILURE,

  ACCOUNT_INFO_REQUEST,
  ACCOUNT_INFO_STORE,
  ACCOUNT_INFO_FAILURE,

  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,

  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,

  CONFIRM_INVITATION_SUCCESS,
  CONFIRM_INVITATION_FAILURE,

  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,

  CONFIRM_PASSWORD_RESET_REQUEST,
  CONFIRM_PASSWORD_RESET_SUCCESS,
  CONFIRM_PASSWORD_RESET_FAILURE
};
