import React from "react";
import PropTypes from "prop-types";
import {FormField} from "../../../Common/Forms/Base";
import {preLoadFormat} from "./utils";
import {Form} from "react-final-form";

const ProductionLineForm = props => {
  const {
    onSubmit,
    validate,
    initialValues,
    // editAccess,
    id,
  } = props;

  const formattedValues = preLoadFormat(initialValues);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={formattedValues}
      render={({handleSubmit}) => (
        <form
          id={id}
          className={"production-line-form "}
          onSubmit={handleSubmit}>
          {/* <AutoSave*/}
          {/*  debounce={1000}*/}
          {/*  save={onSubmit}*/}
          {/*  isSubmitting={submitting}*/}
          {/*  debounced={["sku"]}*/}
          {/*  preSaveFormat={preSaveFormat}*/}
          {/*  postLoadFormat={postLoadFormat}*/}
          {/*  formInvalid={invalid}*/}
          {/* />*/}

          <FormField
            label={"Name"}
            placeholder={"Name.."}
            name="name"
          />
        </form>
      )}
    />
  );
};

ProductionLineForm.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  values: PropTypes.object
};

ProductionLineForm.defaultProps = {
  values: {},
  editAccess: false,
  forOrder: false,
  id: "production-line-form",
};

export default ProductionLineForm;
