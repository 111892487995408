import React, {Fragment, useReducer, useState} from "react";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import {Button, Radio} from "antd";
import {Box, Drawer} from "Common/Base";
// import {Button} from "../../Common/Base/Button/Button";
import {InventoryList, SelectedInventoryList} from "../../Lists/PurchaseOrder";
import {actions, getItems, initialState, selectMaterialReducer, selectProductReducer} from "../../utils/cartReducer";
import {SearchInput} from "Common/Forms";
import {inventoryType} from "../../utils/propTypes";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY} from "../../utils/constants";
import {capitalize} from "lodash-es";

const getReducer = (type) => {
  if (type === PRODUCT_INVENTORY) return selectProductReducer;
  if (type === MATERIAL_INVENTORY) return selectMaterialReducer;
};

const DEFAULT_PAGE_SIZE = 3;

const renderSupplierOption = (order) => {
  if (order && order.supplier) {
    return <Radio value={order.supplier.id}>{order.supplier.name}</Radio>;
  }
  return null;
};
const PurchaseInventoryDrawer = (props) => {
  const {
    pagination,
    fetchPage,
    loading,
    addQuantity,
    purchaseOrderItemLookup,
    type,
    orderDetails,
    // suppliers,
    // supplierPagination,
  } = props;

  const inventoryReducer = getReducer(type);
  const [selectedState, dispatch] = useReducer(inventoryReducer, initialState);

  const [modelOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({});
  const isTabletOrMobile = useMediaQuery({query: "(max-width: 600px)"});

  const {
    selectItem,
    deselectItem,
    editItem,
  } = actions;


  const productList = pagination.itemsPaged;

  const handleSelectItem = (item) => {
    /* For selecting a Product/Inventory that will have a quanitty added */
    dispatch(selectItem(item));
  };

  const handleEditQuantity = (values) => {
    dispatch(editItem(values));
  };

  const handleDeselectItem = (id) => {
    dispatch(deselectItem(id));
  };

  const toggleModal = () => {
    setModalOpen(!modelOpen);
    if (modelOpen === false) {
      dispatch(actions.reset());
    }
  };

  const handleSubmit = async () => {
    const idList = selectedState.ids;
    const submitValues = selectedState.forSubmit;
    if (idList.length > 0) {
      setIsSubmitting(true);
      for (let i in idList) {
        console.log("submitting", submitValues[idList[i]]);

        let res = await addQuantity(submitValues[idList[i]]);
        console.log("res", res);
      }
      setIsSubmitting(false);
    }
    toggleModal();
  };


  const handleFetchPage = (page, filterQuery = filters) => {
    fetchPage(page, {search: searchTerm, page_size: DEFAULT_PAGE_SIZE, ...filterQuery});
  };

  const handleFilter = (name, e) => {
    console.log("name", name);
    console.log("e", e);
    const value = e.target.value;
    console.log("value", value);
    let newFilters = filters;

    if (value === 0) {
      delete newFilters[name];
      setFilters(newFilters);
    } else {
      newFilters = {
        ...filters,
        [name]: value
      };
      setFilters(newFilters);
      console.log("newfilters", newFilters);
    }

    handleFetchPage(1, newFilters);
  };

  const handleSearch = (value) => {
    // fetchPage(1, {search: value, page_size: DEFAULT_PAGE_SIZE});
    setSearchTerm(value);
    handleFetchPage(1);
  };
  const selectedProductList = getItems(selectedState);

  // console.log("selected Products", selectedProduct);

  // const title = makeTitle(locationData);
  const filteredProductList = productList.filter(product => !purchaseOrderItemLookup[product.id]);
  const drawerWidth = isTabletOrMobile ? "100%" : 600;

  const disableSave = selectedState.ids.length === 0;
  const typeTitle = capitalize(type);

  return (
    <Fragment>
      {!loading &&
      <Button
        className={`add-${type}-button`}
        onClick={toggleModal}
        my={3}
        type={"primary"}>
        Add {typeTitle}s
      </Button>
      }
      <Drawer
        className={"pallet-inventory-modal-wrapper"}
        visible={modelOpen}
        onClose={toggleModal}
        width={drawerWidth}
        title={`Select ${typeTitle} For Order`}
        zIndex={isTabletOrMobile ? 10000 : 1000}
        footer={
          <Box textAlign={"right"}>
            <Button
              key={"cancel"}
              className={"modal-close-button"}
              onClick={toggleModal}
              style={{marginRight: 8}}
              // variantColor={"teal"}
            >Close
            </Button>
            <Button
              className={"save-btn"}
              onClick={handleSubmit}
              type="primary"
              disabled={disableSave}
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        }
      >
        <SearchInput
          onSubmit={handleSearch}
        />

        <Radio.Group name="supplier" defaultValue={0} onChange={(e) => handleFilter("suppliers", e)}>
          <Radio value={0}>All</Radio>
          {renderSupplierOption(orderDetails)}
        </Radio.Group>
        {modelOpen && // this forces InventoryList to rerender and clears hooks when modal opens
        <Box minHeight={"455px"}>
          <InventoryList
            pagination={pagination}
            productList={filteredProductList}
            fetchPage={handleFetchPage}
            // hasLocation={hasLocation}
            selected={selectedState.ids}
            onSelect={handleSelectItem}
            onDeselect={handleDeselectItem}
            loading={loading}
            type={type}
          />
        </Box>
        }

        <SelectedInventoryList
          data={selectedProductList}
          onEdit={handleEditQuantity}
          onRemove={handleDeselectItem}
          type={type}
          title={typeTitle}
        />
      </Drawer>
    </Fragment>
  );
};

PurchaseInventoryDrawer.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  type: inventoryType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  location: PropTypes.any,
  addQuantity: PropTypes.func,
  purchaseOrderItemLookup: PropTypes.object,
  max: PropTypes.number,
  // title of the object drawer is being used for, eg: "Orders", "Templates"
  drawerTitle: PropTypes.string
};

PurchaseInventoryDrawer.defaultProps = {
  purchaseOrderItemLookup: {},
  location: null,
  max: null,
  drawerTitle: "Order"
};

export default PurchaseInventoryDrawer;
