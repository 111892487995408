import moment from "moment";

/**
 * Utility function to format the MATERIALS table data
 * @param {Array} data The list of data to parse
 * @returns {Array} The list of parsed items
 */
export const materialsTableData = (data) => {
  return data.map(item => ({
    key: item.id,
    name: item.name || "\xa0",
    manufacturer_name: item.manufacturer || "\xa0",
    sku: item.sku || "\xa0",
    price: item.price || "\xa0",
    quantity: item.quantities.map(item => ({location: item.location, quantity: item.quantity})),
    created_at: moment(item.created_at).format("LLL"),
    updated_at: moment(item.updated_at).format("LLL")
  }));
};
