import React, {useState} from "react";
import PropTypes from "prop-types";
import {PopConfirmButton} from "Base/Button";

const PickupButton = ({onSubmit}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  };
  return (
    <PopConfirmButton
      title={"Mark pallet as Shipped and update inventory quantities?"}
      className={"pick-up-button"}
      type={"primary"}
      disabled={isSubmitting}
      loading={isSubmitting}
      onConfirm={handleSubmit}
      popConfirmOptions={{
        okButtonProps: {
          className: "confirm-pickup-button"
        },
      }}
    >
      Pick Up
    </PopConfirmButton>
  );
};

PickupButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PickupButton;
