import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Descriptions, message, PageHeader} from "antd";
import {Box} from "Base";
import {Redirect} from "react-router-dom";
import {BarcodeSection, Modal} from "../../../Common";
import {DescriptionText} from "../../../Common/Modals/styled";
import {LocationDetails, AddressDetails} from "../../../Common/Details";
import {palletStatus, renderNestedName} from "../../utils";
import {DirectLink} from "../../../Common/Base";

const HAS_SUPPLIER = [
  palletStatus.INCOMING, palletStatus.RECEIVED,
];
const DetailsHeader = ({
  data, locationSection, FormComponent, locationData, onDelete, addItemButton, actions,
  isEditing, toggleEdit, handleSubmitBarcode, generateBarcode,
}) => {
  // const [isEditing, setIsEditing] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  if (!data) {
    return null;
  }
  const {
    created_by, org,
    sku,
    purchase_order, sales_order,
    destination, origin,
    status, barcode} = data;

  // const toggleEdit = () => {
  //   setIsEditing(!isEditing);
  // };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleMessage = (text, error = false) => {
    // setAdminMessage(text)
    if (error) {
      message.error(text);
    } else {
      message.info(text);
    }
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    const res = await onDelete(data.id);
    if (!res.error) {
      handleMessage("Pallet deleted.");
      setDeleteSuccess(true);
    } else {
      handleMessage("Could not delete this order.", true);
    }
    setIsSubmitting(false);

    toggleDeleteModal();
  };


  if (deleteSuccess) {
    return <Redirect to={"/dashboard/warehouse/pallets"}/>;
  }


  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Pallet Details"
      subTitle={data.status_display}
      extra={[
        <Button
          key={"edit"}
          className={"admin-edit-button"}
          onClick={toggleEdit}
          loading={isSubmitting}
        >
          {isEditing ? "Cancel" : "Edit"}
        </Button>,
        ...actions,
        <Button
          danger
          key={"delete"}
          className={"admin-delete-button"}
          onClick={toggleDeleteModal}
          loading={isSubmitting}
        >
          Delete
        </Button>,
        <Box display={"inline-block"} key={"add-item"} py={[1, 0]}>
          {addItemButton}
        </Box>,
      ]}
    >
      {isEditing && FormComponent}
      <Descriptions size="small" column={3} className={"page-details"}>
        <Descriptions.Item label="Expected Date">{data.expected_date}</Descriptions.Item>

        {!isEditing && <Descriptions.Item label="SKU">{sku}</Descriptions.Item>}

        {HAS_SUPPLIER.includes(status) &&
        <Descriptions.Item label={"Supplier"} span={3}>
          {renderNestedName(purchase_order, "supplier")}
        </Descriptions.Item>
        }
        <Descriptions.Item label={"Customer"} span={3}>
          {renderNestedName(sales_order, "customer")}
        </Descriptions.Item>
        <Descriptions.Item label={"BOL"}>
          {data.bol ? <DirectLink href={data.bol} onClick={e => e.stopPropagation()}>View Bill of Lading</DirectLink> : "None"}
        </Descriptions.Item>

        {created_by && <Descriptions.Item label="Created by">{created_by.name}</Descriptions.Item>}
        {org &&
        <Descriptions.Item label="Org">
          {org.name}
        </Descriptions.Item>
        }
        <Descriptions.Item label="Created At">{data.created_at}</Descriptions.Item>
        <Descriptions.Item label="Last Updated">{data.updated_at}</Descriptions.Item>
      </Descriptions>
      {locationData &&
      <LocationDetails
        addButton={locationSection}
        region={locationData.region}
        district={locationData.district}
        facility={locationData.facility}
        wing={locationData.wing}
        zone={locationData.zone}
        subzone={locationData.subzone}
      />
      }

      {destination &&
      <AddressDetails
        title={"Destination"}
        className={"pallet-destination"}
        address={destination}
      />
      }

      {origin &&
      <AddressDetails
        title={"Origin"}
        className={"pallet-origin"}
        address={origin}
      />
      }


      <BarcodeSection
        data={barcode ? barcode.data : ""}
        format={(barcode && barcode.format !== false) ? barcode.format : -1}
        updateBarcode={handleSubmitBarcode}
        generateBarcode={generateBarcode}/>

      <Modal
        visible={showDeleteModal}
        title={"Delete pallet?"}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
        isDeleteModal
      >
        <DescriptionText>Deleting this Pallet will remove all items from it..</DescriptionText>
      </Modal>

    </PageHeader>
  );
};

DetailsHeader.propTypes = {
  data: PropTypes.object,
  FormComponent: PropTypes.element,
  locationData: PropTypes.object,
  onDelete: PropTypes.func,
  addItemButton: PropTypes.element || PropTypes.func,
  actions: PropTypes.array,
  isEditing: PropTypes.bool,
  toggleEdit: PropTypes.func,
  handleSubmitBarcode: PropTypes.func,
  generateBarcode: PropTypes.func,
};

export default DetailsHeader;
