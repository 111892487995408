import React from "react";
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import {Label, LabelText} from "./styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const ReactDateTimeAdapter = ({input, label, isDisabled, ...rest}) => {
  return (
    <DatePicker
      className={"item-details-form-input" + (!isDisabled ? "-editable" : "")}
      readOnly={isDisabled}
      selected={input.value}
      onChange={date => input.onChange(date)}
      isClearable
      placeholderText={label}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={60}
      timeCaption="Time"
      dateFormat="MMMM d, yyyy h:mm aa"/>
  );
};

const FormDateTimeField = props => {
  const {
    label,
    name
  } = props;
  return label ?
    <Label htmlFor={name} marginBottom="20px" width="100%">
      <LabelText>{label}:</LabelText>
      <Field
        component={ReactDateTimeAdapter}
        placeholder={label}
        {...props}
      />
    </Label>
    :
    <Field
      component={ReactDateTimeAdapter}
      placeholder={label}
      {...props}
    />;
};


FormDateTimeField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  inputType: PropTypes.string,
  display: PropTypes.string
};

FormDateTimeField.defaultProps = {
  inputType: "input",
  display: "block"
};

export default FormDateTimeField;
