import React, {useState} from "react";
import PropTypes from "prop-types";
import {Flex, Heading} from "Base";
import ProductTemplateList from "./ProductTemplateList";
import ProductTemplateForm from "./ProductTemplateForm";
import {Plus} from "../../Common/Base/Icons";
import {crudActions} from "../../../types/propTypes";

const ProductTemplateSection = props => {
  const {
    product,
    pagination,
    templateActions,
    templateMaterialActions,
    materialActions,
    materialPagination,
  } = props;

  const [showForm, setShowForm] = useState(false);


  const toggleForm = () => setShowForm(!showForm);

  const handleCreate = async (values) => {
    const res = await templateActions.create(values);
    if (!res.error) {
      setShowForm(false);
      templateActions.getPage(1);
    }
  };

  const renderForm = () => {
    const values = {
      product: product.id,
      org: product.org.id,
    };
    return showForm ?
      <ProductTemplateForm onSubmit={handleCreate} initialValues={values}/>
      :
      null;
  };

  return (
    <Flex className={"product-template-section"} flexDirection={"column"}>
      <Flex flexDirection={"row"}>
        <Heading pr={2}>
          Templates
        </Heading>
        <Plus
          onClick={toggleForm}
          data-tip={"Create new Product Template."}
          className="create-template"
        />
      </Flex>

      {renderForm()}

      <ProductTemplateList
        product={product}
        pagination={pagination}
        templateMaterialActions={templateMaterialActions}
        materialActions={materialActions}
        templateActions={templateActions}
        materialPagination={materialPagination}
      />
    </Flex>
  );
};

ProductTemplateSection.propTypes = {
  product: PropTypes.object,
  pagination: PropTypes.object.isRequired,
  templateActions: crudActions.isRequired,
  templateMaterialActions: crudActions.isRequired,
  materialActions: crudActions.isRequired,
  materialPagination: PropTypes.object,
};

export default ProductTemplateSection;
