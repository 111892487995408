

/**
 * Validates the quantities added to a new incoming pallet.
 * @param {{materials: [], products: []}} values - material and inventory quantities
 * @param {{materials: [], items: []}} order - order details with
 * @returns {boolean} - True if quantities are within inventory quantity
 */
export const validate = (values, order) => {
  const {materials, products} = values;

  console.log("validate", values);
  console.log("validate", order);
  if (order.materials.length > 0) {
    for (let i in materials) {
      const material = materials[i];
      const orderMaterial = order.materials.find(item => item.material.id === material.material);
      if (material.quantity > orderMaterial.available) {
        console.log("material", material);
        console.log("order material", orderMaterial);

        return false;
      }
    }
  }
  const {items} = order;
  if (items && items.length > 0) {
    for (let i in products) {
      const product = products[i];

      console.log(" items", items);
      console.log("product", product);
      const orderProduct = items.find(item => item.product.id === product.product);
      console.log("orderProduct", orderProduct);
      if (orderProduct && product.quantity > orderProduct.available) {
        return false;
      }
    }
  }

  return true;
};
