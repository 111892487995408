import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {Button, FlexCenter} from "../../Base";
import theme from "../../../../theme";

export default function IssueForm({submit, closeIssue}) {
  const [issueDescription, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (issueDescription) {
      const reportedIssue = {issue: {description: issueDescription}};
      submit(reportedIssue);
      closeIssue();
    }
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Fragment>
      <form className="issue-form" onSubmit={handleSubmit}>
        <textarea
          className="issue-bar-comment-input"
          name="issue-form"
          value={issueDescription}
          placeholder="Issue Description"
          onChange={handleChange} />
        <FlexCenter>
          <Button
            className="issue-form-btn"
            htmlType="submit"
            type={"primary"}
            m={1}
            disabled={false}
            theme={theme}
            gradient="green">
              Submit Issue
          </Button>
          <Button
            className="issue-form-btn"
            onClick={closeIssue}
            m={1}
            disabled={false}
            theme={theme}
            gradient="orange">
              Cancel
          </Button>
        </FlexCenter>
        <br />
      </form>
    </Fragment>
  );
}

IssueForm.propTypes = {
  submit: PropTypes.func,
  closeIssue: PropTypes.func,
};
