import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {Field, Form} from "react-final-form";
import {FormField, PositiveNumberField} from "../../../Common/Forms/Base";
import {postLoadFormat} from "./utils";
import {SubmitButton} from "../../../Common/Forms/styled";
import {Box} from "../../../Common/Base";
import {AdaptedDatePicker, AdaptedInput, AdaptedTextarea} from "../../../Common/Base/Inputs/AdaptedInputs";
import {parseDate, parseText} from "../../../Common/Forms/Base/utils";

const PalletForm = (props) => {
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    id,
  } = props;
  let initialValues = postLoadFormat(values);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form
          id={id}
          className={"pallet-details-form "}
          onSubmit={handleSubmit}>
          {/* <AutoSave*/}
          {/*  debounce={1000}*/}
          {/*  save={onSubmit}*/}
          {/*  isSubmitting={submitting}*/}
          {/*  debounced={["sku"]}*/}
          {/*  preSaveFormat={preSaveFormat}*/}
          {/*  postLoadFormat={postLoadFormat}*/}
          {/*  formInvalid={invalid}*/}
          {/* />*/}
          <PositiveNumberField
            component={AdaptedInput}
            label={"SKU"}
            type={"text"}
            name={"sku"}
            isDisabled={editAccess}
          />

          <FormField
            label={"Expected Date"}
            placeholder={"Expected Date"}
            type="text"
            name="expected_date"
            component={AdaptedDatePicker}
            parse={parseDate}
            format={(value, name) => value ? moment(value).format("YYYY-MM-DD") : null}
          />

          <Field
            placeholder={"Special Instructions"}
            type="text"
            name="instructions"
            component={AdaptedTextarea}
            size="small"
            parse={parseText}
            format={parseText}
          />

          <Box py={3} mb={3}>
            <SubmitButton
              htmlType="submit"
              className="item-details-form-button"
              loading={submitting}
            >
              Save
            </SubmitButton>
          </Box>


        </form>
      )}
    />
  );
};


PalletForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  values: PropTypes.object,
  handleManufacturerClick: PropTypes.func,
  locationList: PropTypes.array,
  isAdmin: PropTypes.bool,
  locationData: PropTypes.any,
  setLocationData: PropTypes.func,
  forOrder: PropTypes.bool,
  id: PropTypes.string,
};

PalletForm.defaultProps = {
  onSubmit: (values) => null,
  values: {},
  editAccess: false,
  forOrder: false,
  id: "pallet-details-form",
};

export default PalletForm;
