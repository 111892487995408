import {baseCsvFields, makeCsvColumn} from "../../../utils/csv";
import moment from "moment-timezone";
import {dateFormats} from "../../../utils/dates";

export const csvHelpText = [
  "An asset is required before setting the First Scheduled Date value"
];

export const csvColumns = [
  makeCsvColumn("asset"),
  makeCsvColumn("stockroom_asset"),
  makeCsvColumn("name"),
  makeCsvColumn("description"),
  makeCsvColumn("tags"),
  makeCsvColumn("first_scheduled_date"),
  makeCsvColumn("repeating"),
  makeCsvColumn("repeat_num"),
  makeCsvColumn("repeat_unit"),
  makeCsvColumn("photo_required"),
  makeCsvColumn("inspection_note_required"),
  ...baseCsvFields
];

export const sampleData = [
  {
    name: "Cabinet Inspections",
    description: "Review cabinet circuitry in lead railcar for each locomotive",
    // First_scheduled_date: '2019-08-15',
    note: "Track 3",

    photo_required: true,
    inspection_note_required: true,
    tags: "\"Railcar, Inspections\""
  },
  {
    name: "Cabinet Inspections",
    description: "Review cabinet circuitry in lead railcar for each locomotive",
    // First_scheduled_date: '2019-08-15',
    note: "Track 3",

    photo_required: true,
    inspection_note_required: true,
    tags: "\"Railcar, Inspections\""
  }
];

export const selectionOptions = [
  {value: "name", label: "Name"},
  {value: "description", label: "Description"},
  {value: "first_scheduled_date", label: "First Scheduled Date"},
  {value: "tags", label: "Tags"},
  {value: "repeating", label: "Repeating"},
  {value: "repeat_unit", label: "Frequency"},
  {value: "created_at", label: "Created At"},
  {value: "updated_at", label: "Updated At"},
];


/**
 * Filters options for the PM Orders
 */
export const filterOptions = [
  {value: "", label: "All", params: {}},
  {
    value: "overdue", label: "Overdue",
    params: {
      start__date__lt: moment().tz("UTC").format(dateFormats.date),
    }
  },
  {
    value: "inspect_today", label: "Inspect Today",
    params: {
      start__date: moment().tz("UTC").format(dateFormats.date),
      // start_before: moment().format(dateFormats.date)
    }
  },
];
