import React from "react";
import PropTypes from "prop-types";
import {Form, Field} from "react-final-form";

import {Button, Column} from "../../Common/Base";

const ConfirmPasswordResetForm = props => {
  const {
    onSubmit,
    values,
  } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form
          onSubmit={handleSubmit}>
          <Column>
            <Field
              component={"input"}
              className={"login-card-input"}
              placeholder={"New password..."}
              name="new_password1"
              type={"password"}
            />
            <Field
              component={"input"}
              className={"login-card-input"}
              placeholder={"Confirm password..."}
              name="new_password2"
              type={"password"}
            />
            <Field type={"hidden"} component={"input"} name={"uid"}/>
            <Field type={"hidden"} component={"input"} name={"token"}/>

          </Column>
          <Button
            className="login-card-button"
            htmlType="submit"
            // type={'pr'}
            value="Submit"
            size={"large"}
            loading={submitting}>
            Submit
          </Button>
        </form>
      )}
    />
  );
};

ConfirmPasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,

};

export default ConfirmPasswordResetForm;
