import moment from "moment";

/**
 * Utility function to format the PMORDER table data
 * @param {Array} data The list of data to parse
 * @returns {Array} The list of parsed items
 */
export const pmOrderTableData = (data) => {
  return data.map(item => ({
    key: item.id,
    name: item.name || "\xa0",
    description: item.description || "\xa0",
    first_scheduled_date: item.first_scheduled_date ? moment(item.first_scheduled_date).format("LLL") : null,
    number_notes: item?.notes?.length ?? 0,
    repeating: item.repeating ? `${item.repeat_unit}ly` : "No",
    frequency: item.repeat_num || "\xa0",
    created_at: moment(item.created_at).format("LLL"),
    updated_at: moment(item.updated_at).format("LLL")
  }));
};
