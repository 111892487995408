import React from "react";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";
import {
  AssetDetailsContainer,
  AssetsContainer,
  HelpContainer,
  HomeContainer,
  LogContainer,
  PersonnelContainer,
  PersonnelDetailsContainer,
  PMOrderDetailsContainer,
  PMOrdersContainer,
  ScheduledPMOrderDetailsContainer,
  SettingsContainer,
  StockroomAssetDetailsContainer,
  VendorsContainer,
  VendorsDetailsContainer,
  WarehouseContainer,
} from "../containers";

import {ProductionRoutes} from "./Production";
import {authSelectors} from "../store";
import {LOCATION_DASHBOARD_ROUTE, DASHBOARD_ROUTE} from "./constants";
import LocationsContainer from "../containers/Locations/LocationsContainer";
import LocationDetailsContainer from "../containers/Locations/LocationDetailsContainer";

const DashboardRoutes = ({accountInfo}) => {
  const assetsEnabled = authSelectors.moduleEnabled(accountInfo, "assets");
  const personnelEnabled = authSelectors.moduleEnabled(accountInfo, "personnel");
  const pmOrdersEnabled = authSelectors.moduleEnabled(accountInfo, "pm_orders");
  const vendorOrdersEnabled = authSelectors.moduleEnabled(accountInfo, "vendor_orders");
  const warehouseEnabled = authSelectors.moduleEnabled(accountInfo, "warehouse");
  const productionEnabled = authSelectors.moduleEnabled(accountInfo, "production");
  return (
    <Switch>
      <Route
        exact
        path="/dashboard"
        name="Home Container"
        component={HomeContainer}
      />
      {assetsEnabled &&
      <Route
        exact
        path="/dashboard/assets/:assetID"
        name="Asset Details Container"
        component={AssetDetailsContainer}
      />
      }
      {assetsEnabled &&
      <Route
        exact
        path="/dashboard/assets"
        name="Assets Container"
        component={AssetsContainer}
      />
      }
      {assetsEnabled &&
      <Route
        exact
        path="/dashboard/stockroom_asset/:stockID"
        name="Stockroom Asset Details Container"
        component={StockroomAssetDetailsContainer}
      />
      }
      {personnelEnabled &&
      <Route
        exact
        path="/dashboard/personnel/:personnelID"
        name="Personnel Details Container"
        component={PersonnelDetailsContainer}
      />
      }
      {personnelEnabled &&
      <Route
        exact
        path="/dashboard/personnel"
        name="Personnel Container"
        component={PersonnelContainer}
      />
      }
      {pmOrdersEnabled &&
      <Route
        exact
        path="/dashboard/orders/:orderID"
        name="PM Order Details Container"
        component={PMOrderDetailsContainer}
      />
      }
      {pmOrdersEnabled &&
      <Route
        exact
        path="/dashboard/orders/scheduled/:orderID"
        name="Scheduled PM Order Container"
        component={ScheduledPMOrderDetailsContainer}
      />
      }
      {pmOrdersEnabled &&
      <Route
        exact
        path="/dashboard/orders"
        name="PM Orders Container"
        component={PMOrdersContainer}
      />
      }
      {vendorOrdersEnabled &&
      <Route
        exact
        path="/dashboard/vendors"
        name="Vendor Orders Container"
        component={VendorsContainer}
      />
      }
      {vendorOrdersEnabled &&
      <Route
        exact
        path="/dashboard/vendors/:vendorID"
        name="Vendor Details Container"
        component={VendorsDetailsContainer}
      />
      }
      <Route
        exact
        path="/dashboard/logs"
        name="Log Container"
        component={LogContainer}
      />
      <Route
        exact
        path="/dashboard/help"
        name="Help Container"
        component={() => <HelpContainer accountInfo={accountInfo} />}
      />
      <Route
        exact
        path="/dashboard/settings"
        name="Settings Container"
        component={SettingsContainer}
      />
      {warehouseEnabled &&
      <Route
        path="/dashboard/warehouse"
        name="Warehouse Container"
        component={WarehouseContainer}
      />
      }
      {productionEnabled &&
      <Route
        path="/dashboard/production"
        name="Production Routes"
        component={ProductionRoutes}
      />
      }

      <Route
        exact
        path={`${LOCATION_DASHBOARD_ROUTE}`}
        name="Locations"
        component={LocationsContainer}
      />
      <Route
        exact
        path={`${LOCATION_DASHBOARD_ROUTE}/:id`}
        name="Location Details"
        component={LocationDetailsContainer}
      />

      <Redirect from={DASHBOARD_ROUTE} to={DASHBOARD_ROUTE}/>
    </Switch>
  );
};

DashboardRoutes.propTypes = {
  accountInfo: PropTypes.object,
};

export default DashboardRoutes;
