import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
// import apiMiddleware from "./middleware";
import {apiMiddleware} from "redux-api-middleware";
import {createAuthMiddleware} from "@tshio/redux-api-auth-middleware";
import {refreshToken as refreshTokenAction} from "./auth/actions";
import rootReducer from "./reducers.js";


// See config details below.
const authMiddleware = createAuthMiddleware({
  authConfig: {
    header: "Authorization",
    type: "Bearer",
  },
  refreshConfig: {
    endpoint: `${global.base_url}auth/refresh/`,
    failedAction: {type: "LOGOUT"},
    actionDefinition: ({refreshToken, endpoint}) => refreshTokenAction(refreshToken, endpoint), // not required
  },
});
// List of middleware to be created with the store to be used in actions.
const middleware = [thunk, authMiddleware, apiMiddleware];

const rootReducerWithLogout = (state, action) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
  }
  return rootReducer(state, action);
};

// For redux devtools.
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create store with rootReducer for reducers/index.js.
const store = createStore(
  rootReducerWithLogout,
  composeEnhancer(applyMiddleware(...middleware))
);

export default store;
