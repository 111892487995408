import React from "react";
import FormField from "./FormField";

const PositiveNumberField = props => {
  return (
    <FormField
      type="number"
      min={0}
      rangeUnderflow="Please enter a positive number"
      badInput="Please enter a positive number"
      {...props}
    />
  );
};

export default PositiveNumberField;
