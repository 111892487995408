import React from "react";
import PropTypes from "prop-types";
import {DateRangePickerWrapper} from "../../Base/Datepicker";
// Base Form components
import moment from "moment-timezone";

// Base components
import {Box} from "../../Base";
import {formatMomentForSubmit} from "../../../../utils/dates";
import {MOBILE} from "utils/constants";

import {useMediaQuery} from "react-responsive";

const returnRangePickerDefaults = (values) => {
  let result = {};
  if (values) {
    result.initialStartDate = moment(values.start);
    result.initialEndDate = moment(values.end);
  }
  return result;
};


const DateRangeForm = props => {
  const {onSubmit, values, validate, ...rest} = props;
  const isMobile = useMediaQuery(MOBILE);

  const handleSubmit = (range) => {
    const {startDate, endDate} = range;
    const result = {
      start: formatMomentForSubmit(startDate),
      end: formatMomentForSubmit(endDate),
    };
    // only submit complete date ranges
    if (result.start && result.end) {
      onSubmit(result);
    }
  };

  const datePickerDefaults = returnRangePickerDefaults(values);
  return (
    <Box overflow={"visible"} {...rest}>
      <DateRangePickerWrapper
        {...datePickerDefaults}

        isMobile={isMobile}
        onSubmit={handleSubmit}
        startDateId={"upcoming-shift-start-input"}
        endDateId={"upcoming-shift-end-input"}
      />
    </Box>
  );
};

DateRangeForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  editAccess: PropTypes.bool,
};

DateRangeForm.defaultProps = {
  values: {start: null, end: null},
};

export default DateRangeForm;
