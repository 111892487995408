import React from "react";
import {Table} from "../../../../Common/Base";


const ManifestTable = ({items}) => {
  const numberedItems = items.map((item, index) => {
    return {order: index + 1, ...item};
  });
  const columns = [
    {
      title: "Order",
      key: "order",
      dataIndex: "order"
    },
    {
      title: "Name",
      width: "35%",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "35%",
    },
    {
      title: "Dimensions",
      key: "dimensions",
      defaultSortOrder: "descend",
      width: "12.5%",
      render: (text, record) => `${record.width}x${record.height}x${record.depth}`
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      defaultSortOrder: "descend",
      width: "12.5%",
    },
    {
      title: "Weight",
      key: "weight",
      defaultSortOrder: "descend",
      render: (text, record) => `${record.quantity * record.inventory_data.product.weight}`
    },
    {
      title: "Position",
      dataIndex: "position",
      defaultSortOrder: "descend",
      width: "12.5%",
      render: (text, record) => `${record.position[0]}-${record.position[1]}-${record.position[2]}`
    },
  ];


  return (
    <Table
      className={"manifest-table"}
      columns={columns}
      dataSource={numberedItems}
      rowClassName={"manifest-row"}
    />
  );
};

ManifestTable.propTypes = {

};

export default ManifestTable;
