import React, {Fragment} from "react";
import {Icon} from "@chakra-ui/core";
import {Accordion, AccordionHeader, AccordionItem, AccordionPanel, Box, Button, List} from "../Base";
import PropTypes from "prop-types";

const HelpAccordion = ({information}) => {
  const renderItem = (item, i) => {
    return (
      <AccordionItem key={i}>
        {({isExpanded}) => (
          <Fragment>
            <AccordionHeader>
              <Box flex="1" textAlign="left">{item.name}</Box>
              <Icon id={item.id} size="12px" name={isExpanded ? "minus" : "add"}/>
            </AccordionHeader>
            <AccordionPanel pb={4}>
              <List dataSource={item.data} renderItem={data => <Box>{data}</Box>} itemLayout={"vertical"}/>
              {item.url && (
                <a href={`/#/dashboard/${item.url}`}>
                  <Button id={`tryButton-${item.id}`} className="modal-card-button">
                    Try it!
                  </Button>
                </a>
              )}
            </AccordionPanel>
          </Fragment>
        )}
      </AccordionItem>
    );
  };

  return (
    <Accordion allowMultiple>
      <List dataSource={information} renderItem={renderItem} itemLayout={"vertical"}/>
    </Accordion>
  );
};

HelpAccordion.propTypes = {
  information: PropTypes.arrayOf(PropTypes.object)
};

export default HelpAccordion;
