export const DASHBOARD_ROUTE = "/dashboard";

export const MATERIALS_ROUTE = "materials";

export const ASSETS_ROUTE = "assets";

export const STOCKROOM_ROUTE = "stockroom_asset";

export const WAREHOUSE_ROUTE = "warehouse";

export const PERSONNEL_ROUTE = "personnel";

export const PM_ORDERS_ROUTE = "orders";
// export const PM_ORDERS_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${PM_ORDERS_ROUTE}`;

export const SCHEDULED_PM_ORDERS_ROUTE = "scheduled";
// export const SCHEDULED_PM_ORDERS_DASHBOARD_ROUTE = `${PM_ORDERS_DASHBOARD_ROUTE}/${SCHEDULED_PM_ORDERS_ROUTE}`;

export const VENDORS_ROUTE = "vendors";
export const PALLETS_ROUTE = "pallets";
export const INVENTORY_ROUTE = "inventory";
export const PURCHASE_ORDERS_ROUTE = "purchase_orders";
export const SALES_ORDERS_ROUTE = "sales_orders";
export const PRODUCT_ROUTE = "products";

export const PRODUCTION_ROUTE = "production";
export const PRODUCTION_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${PRODUCTION_ROUTE}`;
export const LOCATIONS_ROUTE = "locations";


export const LINES_ROUTE = "lines";

export const PRODUCTION_MATERIAL_ROUTE = `${PRODUCTION_ROUTE}/${MATERIALS_ROUTE}`;

export const PRODUCTION_LINE_ROUTE = `${PRODUCTION_ROUTE}/${LINES_ROUTE}`;

// Dashboard Production Routes

export const MATERIAL_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${PRODUCTION_MATERIAL_ROUTE}`;

export const PRODUCTION_DASHBOARD_LINE_ROUTE = `${DASHBOARD_ROUTE}/${PRODUCTION_LINE_ROUTE}`;


// Dashboard Warehouse Routes

export const WAREHOUSE_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${WAREHOUSE_ROUTE}`;

export const INVENTORY_WAREHOUSE_ROUTE = `${WAREHOUSE_ROUTE}/${INVENTORY_ROUTE}`;
export const INVENTORY_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${WAREHOUSE_ROUTE}/${INVENTORY_ROUTE}`;

export const PALLET_WAREHOUSE_ROUTE = `${WAREHOUSE_ROUTE}/${PALLETS_ROUTE}`;
export const PALLET_DASHBOARD_ROUTE = `${WAREHOUSE_DASHBOARD_ROUTE}/${PALLETS_ROUTE}`;

export const PRODUCTS_WAREHOUSE_ROUTE = `${WAREHOUSE_ROUTE}/${INVENTORY_ROUTE}`;
export const PRODUCTS_DASHBOARD_ROUTE = `${WAREHOUSE_DASHBOARD_ROUTE}/${INVENTORY_ROUTE}`;

export const PURCHASE_ORDERS_WAREHOUSE_ROUTE = `${WAREHOUSE_ROUTE}/${PURCHASE_ORDERS_ROUTE}`;
export const PURCHASE_ORDERS_DASHBOARD_ROUTE = `${WAREHOUSE_DASHBOARD_ROUTE}/${PURCHASE_ORDERS_ROUTE}`;

export const SALES_ORDERS_WAREHOUSE_ROUTE = `${WAREHOUSE_ROUTE}/${SALES_ORDERS_ROUTE}`;
export const SALES_ORDERS_DASHBOARD_ROUTE = `${WAREHOUSE_DASHBOARD_ROUTE}/${SALES_ORDERS_ROUTE}`;

export const BILL_OF_LADING_ROUTE = "bol";


// Dashboard Routes

export const ASSET_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${ASSETS_ROUTE}`;

export const STOCKROOM_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${STOCKROOM_ROUTE}`;

export const PERSONNEL_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${PERSONNEL_ROUTE}`;

export const VENDORS_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${VENDORS_ROUTE}`;

export const PM_ORDERS_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${PM_ORDERS_ROUTE}`;

export const SCHEDULED_PM_ORDERS_DASHBOARD_ROUTE = `${PM_ORDERS_DASHBOARD_ROUTE}/${SCHEDULED_PM_ORDERS_ROUTE}`;

export const LOCATION_DASHBOARD_ROUTE = `${DASHBOARD_ROUTE}/${LOCATIONS_ROUTE}`;


// Type/Title Routes

export const ASSET_TYPES_ROUTE = "asset_types";

export const STOCK_STATUS_ROUTE = "stock_asset_statuses";

export const PERSONNEL_TITLES_ROUTE = "personnel_titles";

export const VENDOR_TYPES_ROUTE = "vendor_types";

export const SCHEDULE_STATUS_ROUTE = "schedule_statuses";

// Miscellaneous Routes

export const PM_ORDER_MATERIALS_ROUTE = "pm_order_materials";

export const BULK_DELETE = "bulk_delete";

export const HELP_ROUTE = "help";


export const EDIT_PARAM = "edit=true";
