import {createSelector} from "reselect";

// SELECTORS

export const getCurrentView = state => state.view;

export const getItemsRequested = state => state.requested;

export const getItemsErrored = state => state.errored;

export const getItem = (state, id) => state.byId[id];

const getItemsById = state => state.byId;

const getItemsIds = state => state.ids;

export const getItems = createSelector(
  [getItemsById, getItemsIds],
  (pById, pIds) => pIds.map(o => pById[o]),
);

export const getItemsCurrentPage = state => state.currentPage;

export const getItemsLastPage = state => state.lastPage;

const getIsPageFetched = (state, page) => state.pages[page] !== undefined;

/**
 * Get is current page loading
 * @param {object} state - redux state
 * @returns {boolean} loading - True if no page for params exists
 */
const getIsCurrentPageLoading = (state) => !state.pages[state.currentParams] || state.pages[state.currentParams][state.currentPage] === undefined;

/**
 * get item id's paged
 * @param {object} state - redux state
 * @returns {[]} - list of id's
 */
const getItemsIdsPaged = (state) => {
  const page = state.currentPage;
  const paramString = state.currentParams;
  if (page === undefined || Object.keys(state.pages).length === 0) {
    return [];
  }


  const pageIds = state.pages[paramString] ? state.pages[paramString][page] : undefined;
  if (pageIds === undefined) {
    return [];
  }
  return pageIds;
};

export const getItemsPaged = createSelector(
  [getItemsById, getItemsIdsPaged],
  (pById, pIds) => pIds.map(o => pById[o]),
);

/**
 * Number of items on all pages
 * @param {object} state - redux state
 * @returns {number} pageSize - number of all items in pagination set
 */
const getCurrentCount = (state) => state.currentCount;

/**
 * Current page size
 * @param  {object} state - redux state
 * @returns {number} pageSize - number of items per page
 */
const getCurrentPageSize = (state) => state?.pageSize ?? 1;

/**
 * Returns everything needed to handle pagination by a component
 * @param {object} state - redux state where pagination exists
 * @returns {{itemsLastPage: number, itemsPaged: [], itemsErrored: boolean, itemsRequested: boolean, isEmpty: boolean, pageSize: number, itemsCurrentPage: number, itemsCount: number, pageView: string, pageLoading: boolean}} - returns itemsPaged, the array of items, with page info
 */
const pagination = (state) => {
  const result = {
    itemsCurrentPage: getItemsCurrentPage(state),
    itemsErrored: getItemsErrored(state),
    itemsLastPage: getItemsLastPage(state),
    itemsPaged: getItemsPaged(state),
    itemsRequested: getItemsRequested(state),
    itemsCount: getCurrentCount(state),
    pageSize: getCurrentPageSize(state),
    pageView: getCurrentView(state),
    pageLoading: getIsCurrentPageLoading(state),
    loading: getIsCurrentPageLoading(state)

  };
  return {
    ...result,
    isEmpty: result.itemsCount === 0,
  };
};

export default {
  getItemsRequested,
  getItemsErrored,
  getItem,
  getItemsIds,
  getItems,
  getItemsCurrentPage,
  getItemsLastPage,
  getIsPageFetched,
  getIsCurrentPageLoading,
  getItemsIdsPaged,
  getItemsPaged,
  getCurrentCount,
  getCurrentPageSize,
  pagination,
};
