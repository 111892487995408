import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {ItemText} from "../../Common/Bars/styled";

const Details = ({baseClassName}) => {
  return (
    <div className={`${baseClassName}-detail`}>
      <Fragment>
        <ItemText style={{"textDecoration": "underline"}}>Location</ItemText>
        <ItemText>None</ItemText>
      </Fragment>
    </div>
  );
};

Details.propTypes = {
  baseClassName: PropTypes.string
};

export {Details};
