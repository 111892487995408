import React from "react";
import {Link} from "react-router-dom";
import {Button, Input, Space} from "Base";
import {DASHBOARD_ROUTE} from "../routes/constants";
import {SearchOutlined} from "@ant-design/icons";
import {dateSorter, numberSorter, stringSorter} from "./sort";

/**
 * Utility function to return standard table configuration
 * @param {Function} tableFormatter the table data parsing function
 * @param {object} props the tables props
 * @returns {object} The config object
 */
export const getTableConfigWithProps = (tableFormatter, props) => {
  const {currentPage, selectedRowsIds, onTableSelectChange} = props;
  const tableData = tableFormatter(currentPage);
  const rowSelection = {
    selectedRowKeys: selectedRowsIds,
    onChange: onTableSelectChange,
    hideDefaultSelections: true,
  };
  return {tableData, rowSelection};
};

// ////////////////////////////////////////
// /// Common Column Definitions //////////
// ////////////////////////////////////////

/**
 * Utility function to sort and display the CREATED AT column
 * @param {object} params Function parameters
 * @param {number} params.width first element to compare
 * @returns {object} The list of parsed items
 */
export const createdAtColumn = ({width}) => {
  return {
    title: "Created at",
    dataIndex: "created_at",
    width: width,
    sorter: dateSorter,
  };
};

/**
 * Utility function to sort and display the UPDATED AT column
 * @param {object} params Function parameters
 * @param {number} params.width first element to compare
 * @returns {object} The list of parsed items
 */
export const updatedAtColumn = ({width}) => {
  return {
    title: "Updated at",
    dataIndex: "updated_at",
    width: width,
    sorter: dateSorter,
  };
};

/**
 * Utility function to sort and display the VIEW MORE column
 * @param {string} objType first element to compare
 * @returns {object} The list of parsed items
 */
export const viewMoreColumn = (objType) => {
  return {
    title: "View More",
    dataIndex: "",
    fixed: "right",
    width: 100,
    render: key => <Link to={`${DASHBOARD_ROUTE}/${objType}/${key.key}`}><Button size="small" type="primary">Details</Button></Link>
  };
};

/**
 * Utility function to sort and display the UPDATED AT column
 * @param {object} params Function parameters
 * @param {number} params.width first element to compare
 * @returns {object} The list of parsed items
 */
export const numberOfNotesColumn = ({width}) => {
  return {
    title: "# of Notes",
    dataIndex: "number_notes",
    width: width,
    sorter: numberSorter("number_notes"),
  };
};

/**
 * Utility function to sort and display the UPDATED AT column
 * @param {object} params Function parameters
 * @param {number} params.width first element to compare
 * @returns {object} The list of parsed items
 */
export const manufacturerColumn = ({width}) => {
  return {
    title: "Manufacturer",
    dataIndex: "manufacturer_name",
    width: width,
    elipsis: true,
    editable: true,
    searchable: true,
    sorter: stringSorter("manufacturer_name"),
  };
};

/**
 * Utility function to sort and display the UPDATED AT column
 * @param {object} params Function parameters
 * @param {number} params.width first element to compare
 * @returns {object} The list of parsed items
 */
export const nameColumn = ({width}) => {
  return {
    title: "Name",
    dataIndex: "name",
    width: width,
    elipsis: true,
    editable: true,
    searchable: true,
    sorter: stringSorter("name"),
  };
};

/**
 * Utility function to add search-ability to a column
 * @param {object} dataIndex Function parameters
 * @returns {object} filter options for the column
 */
export const getColumnSearchProps = dataIndex => {
  let searchInput;
  return {
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => { searchInput = node; }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Space>
          <Button
            type="primary"
            onClick={confirm}
            size="small"
            style={{width: 90}}
          >
                    Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{width: 90}}>
                    Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? "#485fe0" : undefined}} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => { visible && setTimeout(() => searchInput.select()); },
  }; };
