import React from "react";
import PropTypes from "prop-types";
import {Field as BaseField} from "react-final-form";
import {Field as ValidField} from "react-final-form-html5-validation";
import {Label} from "../../Base";
import FormInput from "./FormInput";
import {LabelText} from "./styled";

const FormField = props => {
  const {
    label,
    name,
    isDisabled,
    handleClick,
    parse,
    withValidation,
    id,
    options,
    ...remainingProps
    // isSelect
  } = props;

  let extraConfig = {};

  if (options) {
    const format = (value, name) => {
      if (value && typeof value === "number") {
        return options.find(el => el.id === value);
      }
      return (value ? {
        value: value.id || value.value,
        label: value.name || value.label,
      } : "");
    };

    const parse = (value, name) => (value ? (value.value || value.id || value) : "");
    extraConfig = {
      parse,
      format
    };
  }
  const Field = withValidation ? ValidField : BaseField;
  // Allows a null value to be present in the field
  let parseFn = parse || ((val) => val === null ? "" : val);

  return label ?
    <Label htmlFor={name} m={0}>
      <LabelText id={id + "label"}>{label}:</LabelText>
      <Field
        name={name}
        component={FormInput}
        placeholder={label}
        readOnlyMode={isDisabled}
        parse={parseFn}
        id={id}
        options={options}
        {...extraConfig}

        {...remainingProps}
        handleClick={handleClick}
      />
    </Label>
    :
    <Field
      id={id}
      name={name}
      component={FormInput}
      placeholder={label}
      readOnlyMode={isDisabled}
      parse={parseFn}
      {...remainingProps}
      handleClick={handleClick}
    />;
};


FormField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  display: PropTypes.string,
  handleClick: PropTypes.func,
  withValidation: PropTypes.bool,
  isSelect: PropTypes.bool,
  options: PropTypes.array
};

FormField.defaultProps = {
  type: "input",
  display: "block",
  handleClick: () => null,
  withValidation: true,
  isSelect: false,
  options: null
};

export default FormField;
