function refreshLogs(state) {
  return state.logs;
}

function loadingSummary(state) {
  return state?.loadingSummary ?? false;
}

function refreshSummary(state) {
  return state?.summary ?? {};
}

function refreshErrors(state) {
  return state?.errors ?? "";
}

function refreshAssetTypes(state) {
  if (state.asset_types) {
    return state.asset_types.map(type => ({
      id: type.id,
      value: type.id,
      label: type.name,
      color: type.color,
      templates: type.templates
    }));
  }
  return [];
}

function refreshPersonnelTitles(state) {
  if (state?.personnel_titles) {
    return state.personnel_titles.map(title => ({
      id: title.id,
      value: title.id,
      label: title.name
    }));
  }
  return [];
}

function refreshVendorTypes(state) {
  if (state?.vendor_types) {
    return state.vendor_types.map(type => ({
      id: type.id,
      value: type.id,
      label: type.name,
      color: type.color
    }));
  }
  return [];
}

function refreshStockStatuses(state) {
  if (state?.stock_asset_statuses) {
    return state.stock_asset_statuses.map(status => ({
      id: status.id,
      value: status.id,
      label: status.name,
      color: status.color
    }));
  }
  return [];
}

function refreshScheduleStatuses(state) {
  if (state?.schedule_statuses) {
    return state.schedule_statuses.map(status => ({
      id: status.id,
      value: status.id,
      label: status.name,
      color: status.color
    }));
  }
  return [];
}

function refreshLocations(state) {
  return state?.locations ?? [];
}

/* Returns True if the getAllItems operation for this module hasn't returned anything yet. */
const isLoading = state => state.loading;

const allItems = state => state.items;

const item = state => state.item;

const summaryTimescale = state => state.summaryTimescale;

const summaryTimescaleValue = state => state.summaryTimescaleValue;

const summaryYear = state => state.summaryYear;

export default {
  refreshLogs,
  refreshSummary,
  loadingSummary,
  refreshErrors,
  refreshAssetTypes,
  refreshPersonnelTitles,
  refreshVendorTypes,
  refreshStockStatuses,
  refreshScheduleStatuses,
  refreshLocations,
  isLoading,
  allItems,
  item,
  summaryTimescale,
  summaryTimescaleValue,
  summaryYear
};
