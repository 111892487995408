import React from "react";
import PropTypes from "prop-types";
import {Pagination} from "../Base";

function showTotal(total) {
  return `Total ${total} items`;
}


const PaginationControl = ({current, total, onChange}) => {
  const handlePageChange = (page) => {
    onChange(page);
  };
  return (
    <Pagination
      showQuickJumper
      size={"small"}
      showTotal={showTotal}
      current={current}
      total={total}
      onChange={handlePageChange}
      pageSize={12}
    />
  );
};


PaginationControl.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  params: PropTypes.object,
  defaultParams: PropTypes.object
};
PaginationControl.defaultProps = {
  defaultParams: {}
};

export default PaginationControl;
