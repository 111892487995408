import React from "react";
import {renderDestination} from "./utils";


const Details = ({data, baseClassName}) => {
  return (
    <div className={`${baseClassName}-detail`} style={{"color": "white"}}>
      {renderDestination(data, baseClassName)}
    </div>
  );
};

export {Details};
