import React, {Component} from "react";
import {connect} from "react-redux";

import Details from "../../components/Locations/Details/Details";
import {
  authSelectors, paginationSelectors,
} from "../../store";
import {locationOperations, locationPageActions} from "../../store/locations";
import {operations as pmOrderOperations} from "../../store/orders/pmOrders";
import {scrollToTop} from "../../utils/containerUtils";


const mapStateToProps = (state, ownProps) => {
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    orgID: authSelectors.defaultOrgID(state.account),
    details: paginationSelectors.getItem(
      state.locations.items,
      ownProps.match.params.id,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  // materials,materials.material,products,products.product
  const getParams = "?expand=stockroom_assets,pm_orders";
  const itemID = ownProps.match.params.id;
  return {
    locationActions: {
      create: (data) => dispatch(locationOperations.createItem(data)),
      update: (id, data) => dispatch(locationOperations.updateItem(id, data, getParams)),
      delete: (id) => dispatch(locationOperations.deleteItem(id)),
      getItem: (id = itemID) => dispatch(locationOperations.getItem(
        id,
        getParams
      )),
      getPage: (page, params) => {
        const finalParams = {
          ...params,
          // expand: ""
        };
        return dispatch(locationPageActions.fetchPage({page, params: finalParams}));
      },
      createPMOrder: (data) => dispatch(pmOrderOperations.createItem(data)),
    },
  };
};

class LocationDetailsContainer extends Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    return (
      <Details {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationDetailsContainer);
