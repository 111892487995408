import React from "react";

import CompanyLogo from "../../../static/img/conmitto_logo_text_white.png";

/* This is the footer for the app. */
const Footer = () => (
  <footer className="footer">
    <div className="footer-inner">
      <img src={CompanyLogo} className="footer-img" alt="Conmitto logo"/>
    </div>
  </footer>
);

export default Footer;
