import React from "react";
import PropTypes from "prop-types";

import {InventoryTable} from "../../../Table";
import {PRODUCT_INVENTORY} from "../../../utils/constants";

const PalletProductTable = ({items}) => {
  return (
    <InventoryTable
      type={PRODUCT_INVENTORY}
      items={items}
    />
  );
};

PalletProductTable.propTypes = {
  items: PropTypes.array,
};

export default PalletProductTable;
