import React, {Component} from "react";
import {connect} from "react-redux";
import {authActions} from "../../store";
import ConfirmPasswordReset from "../../components/Login/PasswordReset/ConfirmPasswordReset";
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(authActions.confirmPasswordReset(data))
  };
};

class ConfirmPasswordResetContainer extends Component {
  render() {
    return (
      <ConfirmPasswordReset {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPasswordResetContainer);
