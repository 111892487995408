import {makeCsvColumn} from "../../../utils/csv";

export const csvColumns = [
  makeCsvColumn("sku"),
  makeCsvColumn("order_no"),
  makeCsvColumn("location"),
  makeCsvColumn("quantity"),
  makeCsvColumn("date"),
  makeCsvColumn("time")
];

// BST-400060-01,1200,D15E,5000
// BST-400060-01,1200,D15E,5000
// BST-400060-02,1200,H02D,2930
// 804696-12,500,D30F,4000,1/16/2019,10:51:06
// 804696-12,500,D30F,5000,1/16/2019,10:52:54
// BOTT/KENNDY METAL,1200,G06L,1,8/8/2019,14:45:53
// BOXES DURALABEL TORO,1200,G01D,1,8/8/2019,14:46:19
// BST-0264PDM,1200,BDOCK,2793,2/25/2020,9:50:09

export const csvData = [
  {
    sku: "BST-400-01",
    order_no: "1200",
    location: "D15E",
    quantity: "5000",
    date: "1/16/2019",
    time: "10:51:06"
  },
  {
    sku: "BST-400-01",
    order_no: "1200",
    location: "D15E",
    quantity: "4000",
    date: "1/16/2019",
    time: "10:52:54"
  },
  {
    sku: "BST-400-02",
    order_no: "1200",
    location: "H02D",
    quantity: "2930",
    date: "2/25/2020",
    time: "9:50:09"
  }
];
