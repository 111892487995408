import {
  Tabs,
  Modal as AntdModal,
  Spin,
  Skeleton,
  List,
  Menu,
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Collapse as AntdCollapse,
  Pagination,
  Descriptions,
  Drawer,
  Layout,
  Button,
  Tooltip,
  Space,
  TimePicker,
  Card,
  Typography,
  Statistic,
  Checkbox,
  Tag
} from "antd";

const {Search} = Input;
const {Panel} = AntdCollapse;
const {SubMenu} = Menu;
const {RangePicker} = TimePicker;
const {Content, Sider} = Layout;
const {TabPane} = Tabs;
const {Text, Title} = Typography;
const AntdText = Text;
/*
Components are imported from Ant Design here so that babel-plugin-import will import on demand.

NOTE: Does not work if components are not exported as default somewhere first
 */


export default {
  Tabs,
  AntdModal,
  Spin,
  Skeleton,
  Space,
  List,
  Menu,
  Table,
  Input,
  Search,
  InputNumber,
  Popconfirm,
  Form,
  AntdCollapse,
  Pagination,
  Descriptions,
  Drawer,
  Layout,
  Button,
  Tooltip,
  TimePicker,
  Card,
  Typography,
  Checkbox,
  Panel,
  SubMenu,
  RangePicker,
  Content,
  Sider,
  AntdText,
  TabPane,
  Statistic,
  Tag,
  Title
};
