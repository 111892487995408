import React from "react";
import PropTypes from "prop-types";

import LocationBar from "../Bar";
import LocationTable from "../Table";
import {LOCATION_DASHBOARD_ROUTE} from "../../../routes/constants";
import {locationHelp} from "../../Common/HelpItems/constants";
import {selectionOptions, filterOptions} from "./constants";
import {DashboardList} from "../../Common/Dashboard";
import {crudActions} from "../../../types/propTypes";


const LocationDashboard = props => {
  const {
    changeView,
    accountInfo,
    pagination,
    actions,
  } = props;

  return (
    <DashboardList
      accountInfo={accountInfo}
      ListItem={LocationBar}
      getPage={actions.getPage}
      Table={LocationTable}
      createItem={actions.create}
      pagination={pagination}
      type={"Location"}
      changeView={changeView}
      showCsv={false}
      tutorialText={locationHelp}
      sortByOptions={selectionOptions}
      filterOptions={filterOptions}
      newItemUrl={LOCATION_DASHBOARD_ROUTE}
      readOnly={true}
    />
  );
};


LocationDashboard.propTypes = {
  /**
   * View change callback
   */
  changeView: PropTypes.func,
  /**
   * Store actions
   */
  actions: crudActions,
  /**
   * Account data of logged in user
   */
  accountInfo: PropTypes.object,
  /**
   * Pagination data structure
   */
  pagination: PropTypes.object,
};

export default LocationDashboard;
