import React from "react";
import PropTypes from "prop-types";
import ReceiveInventoryQuantityForm from "../../Forms/ReceivePallet/ReceiveInventoryQuantityForm";
import {List} from "antd";
import {CloseIcon} from "../../../Common/Base/Icons";
import {palletItemSku} from "../../utils";
import {STORE, UNPACK} from "../../utils/constants";
import {renderLocationInventoryTitle} from "../../utils/inventory";

const SelectingInventoryListItem = (props) => {
  const {
    item,
    fromOrder,
    onEdit,
    onRemove,
    maxField,
    action,
    type,
    locationPagination,
    getLocations,
    location,
    createLocation,
  } = props;

  const handleEditQuantity = (values) => {
    console.log("edit values", values);
    const {stored_at, ...rest} = values;
    onEdit({stored_at: stored_at ? stored_at.value : null, ...rest});
  };

  return (
    <List.Item
      actions={[
        <ReceiveInventoryQuantityForm
          key="add-quantity"
          max={item[maxField]}
          onSubmit={handleEditQuantity}
          fromOrder={fromOrder}
          initialValues={item}
          action={action}
          locationPagination={locationPagination}
          getLocations={getLocations}
          facility={location}
          createLocation={createLocation}
        />,
        !fromOrder && <CloseIcon key={"remove"} onClick={() => onRemove(item.id)}/>,
      ]}
    >
      <List.Item.Meta
        title={renderLocationInventoryTitle(item, type)}
        description={palletItemSku(item, type)}
      />
    </List.Item>
  );
};

SelectingInventoryListItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  fromOrder: PropTypes.bool,
  maxField: PropTypes.string,
  action: PropTypes.oneOf([UNPACK, STORE]),
  type: PropTypes.oneOf(["product", "material"]),
  locationPagination: PropTypes.object,
  getLocations: PropTypes.func,
  location: PropTypes.object,
  createLocation: PropTypes.func,
};

SelectingInventoryListItem.defaultProps = {
  fromOrder: false,
  maxField: "available_quantity",
  type: "product",
};
export default SelectingInventoryListItem;
