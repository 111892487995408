import React from "react";
import PropTypes from "prop-types";
import ProgressPoint from "./ProgressPoint";
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  onSiteProgressPoints,
  offSiteProgressPoints,
  onSitePercentMap,
  offSitePercentMap
} from "./constants";

export default function OrderProgressBar({status, is_on_site, isActiveIssue}) {
  const getIcon = () => {
    return <FontAwesomeIcon icon="check" size="1x" style={{marginLeft: "2px", marginTop: "2px"}}/>;
  };

  const percent = status && is_on_site ? onSitePercentMap[`${status}`] : offSitePercentMap[`${status}`];
  const progressPoints = is_on_site ? onSiteProgressPoints : offSiteProgressPoints;

  return (
    <div className="progress-bar">
      <ProgressBar
        percent={percent}
        filledBackground={isActiveIssue ? "#FFD632" : "#33DB20"}>
        {progressPoints.map((point, index) => {
          const icon = point.icon ? getIcon() : null;
          return (
            <Step key={index} transition="scale">
              {({accomplished}) => (
                <ProgressPoint
                  {...point}
                  icon={icon}
                  accomplished={accomplished}
                  isActiveIssue={isActiveIssue} />
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
}

OrderProgressBar.propTypes = {
  status: PropTypes.number,
  is_on_site: PropTypes.bool,
  isActiveIssue: PropTypes.bool
};

OrderProgressBar.defaultProps = {
  status: 1,
  is_on_site: null,
};
