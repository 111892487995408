import React, {useState} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButton, Flex} from "../../Base";
import {ShiftItem, PrimaryText} from "./styled";
import {Modal} from "../../index";

const Trash = () => <FontAwesomeIcon icon={"trash"}/>;


const ScheduleListItem = props => {
  const {item, isEditable, onRemove} = props;
  const [modalOpen, setModalOpen] = useState(false);

  const handleRemove = () => {
    onRemove(item);
  };

  return (
    <ShiftItem className={"schedule-list-item"} p={3}>
      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <PrimaryText>{item.name}</PrimaryText>

        {isEditable &&
        <IconButton
          mr={3}
          size="sm"
          className={"remove-shift"}
          aria-label="edit"
          icon={Trash}
          onClick={() => setModalOpen(true)}/>
        }

        <Modal
          visible={modalOpen}
          title={"Confirm Remove"}
          onCancel={() => setModalOpen(false)}
          onOk={handleRemove}
          isDeleteModal>
          Are you sure you&apos;d like to remove personnel from this Schedule?
        </Modal>
      </Flex>
    </ShiftItem>
  );
};

ScheduleListItem.propTypes = {
  item: PropTypes.object,
  isEditable: PropTypes.bool,
  classes: PropTypes.any,
  onClick: PropTypes.func,
  onRemove: PropTypes.func
};

export default ScheduleListItem;
