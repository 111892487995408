import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {Flex} from "@chakra-ui/core";
import {DAYS, MONTHS} from "../../../constants";
import translateLabel from "../../../utils/translateLabel";
import selectFieldHandler from "../../../utils/selectFieldHandler";

import {InputWrapper, Label, LabelWrapper, Row, RowLabelWrapper, RowOptions} from "../../../styles/styled";

const RepeatYearlyOnThe = ({
  id,
  mode,
  onThe,
  hasMoreModes,
  handleChange,
  translations
}) => {
  const isActive = mode === "on the";


  return (
    <Row className={`form-group ${!isActive && "opacity-50"}`}>
      <RowLabelWrapper>
        {hasMoreModes && (
          <input
            id={id}
            type="radio"
            aria-label="Repeat yearly on the"
            name="repeat.yearly.mode"
            checked={isActive}
            value="on the"
            onChange={handleChange}
          />
        )}
        <Label>
          {translateLabel(translations, "repeat.yearly.on_the")}
        </Label>
      </RowLabelWrapper>

      <RowOptions>

        <InputWrapper width={5 / 16}>
          <Select
            id={`${id}-which`}
            name="repeat.yearly.onThe.which"
            aria-label="Repeat yearly on the which"
            className="form-control"
            value={{value: onThe.which, label: translateLabel(translations, `numerals.${onThe.which.toLowerCase()}`)}}
            isDisabled={!isActive}
            onChange={selectFieldHandler(handleChange)}
            options={[
              {
                value: "First",
                label: translateLabel(translations, "numerals.first"),
                name: "repeat.yearly.onThe.which"
              },
              {
                value: "Second",
                label: translateLabel(translations, "numerals.second"),
                name: "repeat.yearly.onThe.which"
              },
              {
                value: "Third",
                label: translateLabel(translations, "numerals.third"),
                name: "repeat.yearly.onThe.which"
              },
              {
                value: "Fourth",
                label: translateLabel(translations, "numerals.fourth"),
                name: "repeat.yearly.onThe.which"
              },
              {value: "Last", label: translateLabel(translations, "numerals.last"), name: "repeat.yearly.onThe.which"}

            ]}
          />

        </InputWrapper>

        <Flex width={11 / 16} flexWrap={"wrap"} flexDirection={"row"}>
          <InputWrapper width={1 / 2}>
            <Select
              id={`${id}-day`}
              name="repeat.yearly.onThe.day"
              aria-label="Repeat yearly on the day"
              className="form-control"
              value={{value: onThe.day, label: translateLabel(translations, `days.${onThe.day.toLowerCase()}`)}}
              isDisabled={!isActive}
              onChange={selectFieldHandler(handleChange)}
              options={
                DAYS.map(day => (
                  {
                    value: day,
                    label: translateLabel(translations, `days.${day.toLowerCase()}`),
                    name: "repeat.yearly.onThe.day"
                  }
                ))
              }
            />

          </InputWrapper>

          <LabelWrapper width={1 / 16}>
            <Label>{translateLabel(translations, "repeat.yearly.of")}</Label>
          </LabelWrapper>

          <InputWrapper width={7 / 16}>
            <Select
              id={`${id}-month`}
              name="repeat.yearly.onThe.month"
              aria-label="Repeat yearly on the month"
              className="form-control"
              value={{value: onThe.month, label: translateLabel(translations, `months.${onThe.month.toLowerCase()}`)}}
              isDisabled={!isActive}
              onChange={selectFieldHandler(handleChange)}
              options={
                MONTHS.map(month => (
                  {
                    value: month,
                    label: translateLabel(translations, `months.${month.toLowerCase()}`),
                    name: "repeat.yearly.onThe.month"
                  }
                ))
              }
            />

          </InputWrapper>
        </Flex>
      </RowOptions>
    </Row>
  );
};


RepeatYearlyOnThe.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  onThe: PropTypes.shape({
    which: PropTypes.oneOf(["First", "Second", "Third", "Fourth", "Last"]).isRequired,
    month: PropTypes.oneOf(MONTHS).isRequired,
    day: PropTypes.oneOf(DAYS).isRequired
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default RepeatYearlyOnThe;
