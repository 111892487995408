import React, {useState} from "react";
import PropTypes from "prop-types";

import {Button, Flex, List, Skeleton, Stat, StatGroup, StatLabel, StatNumber} from "../../../Common/Base";


import {renderLocationInventoryTitle} from "../../utils/inventory";

const InventoryListItem = ({type, item, loading, onSelect, onRemove}) => {
  const [itemSelected, handleSelectItem] = useState(false);

  // const [quantity, setQuantity] = useState(0);

  const handleSelect = () => {
    handleSelectItem(true);
    if (!itemSelected) {
      onSelect({
        id: item.id,
        available_quantity: item.available_quantity,
        add_quantity: 0,
        [type]: item[type]
      },
      );
    }
  };

  const handleRemove = () => {
    onRemove(item.id);
    handleSelectItem(false);
  };

  const getActionList = () => {
    let actions = [];
    let selectDisabled = false;
    if (!item.available_quantity || item.available_quantity === 0) {
      selectDisabled = true;
    }
    if (!itemSelected) {
      actions.push(
        <Flex justifyContent={"flex-start"}>
          <Button disabled={selectDisabled} className="select-inventory-btn" variant={"outline"} onClick={handleSelect}>Select</Button>
        </Flex>,
      );
    } else {
      actions.push(
        <Flex justifyContent={"flex-start"}>

          <Button onClick={handleRemove}>
            Remove
          </Button>
        </Flex>);
    }

    return actions;
  };

  const title = renderLocationInventoryTitle(item, type);

  const actions = getActionList();
  return (
    <List.Item
      actions={actions}
      className={`${type}-list-item`}
      extra={
        <Flex flexDirection={"column"} width={"230px"}>
          <StatGroup>
            <Stat>
              <StatLabel>Quantity:</StatLabel>
              <StatNumber>{item.quantity}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>In Use:</StatLabel>

              <StatNumber>{item.in_use}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Available</StatLabel>
              <StatNumber>{item.available_quantity}</StatNumber>
            </Stat>
          </StatGroup>
        </Flex>
      }
    >
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          title={title}
          description={`SKU: ${item[type].sku}`}
        />
      </Skeleton>

    </List.Item>
  );
};

InventoryListItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
};

export default InventoryListItem;
