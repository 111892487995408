import actions from "./actions";
import types from "./types";
import {name as endpoint} from "./index";
import {dateUtils} from "../../utils";
import {assetOperations} from "../assets";
import {personnelOperations} from "../personnel";
import {vendorsOperations} from "../vendors";
import {stockroomAssetOperations} from "../stockroomAssets";
import {scheduledPMOrdersOperations} from "../scheduledPMOrders";
import base from "../base/actions";
import moment from "moment";

import {baseOperations} from "../base";
import {
  ASSET_TYPES_ROUTE,
  LOCATIONS_ROUTE,
  PERSONNEL_TITLES_ROUTE,
  SCHEDULE_STATUS_ROUTE,
  STOCK_STATUS_ROUTE,
  VENDOR_TYPES_ROUTE
} from "../../routes/constants";
import {constructRoute} from "../base/utils";

/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

const getAllItems = () => base.getRsaa({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  endpoint: `${global.base_url}${endpoint}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});

const getRecentLogs = (num_to_get = 5) => base.getRsaa({
  endpoint: `${global.base_url}v1/logs/?limit=${num_to_get}&ordering=-created_at`,
  types: [types.GET_LOGS_REQUEST, types.GET_LOGS_SUCCESS, types.GET_LOGS_FAILURE]
});

const getOrgSummary = (timescale = "week", value, year = moment().year().toString()) => base.getRsaa({
  endpoint: `${global.base_url}v1/org/summary/?timescale=${timescale}&value=${value}&year=${year}`,
  types: [types.GET_ORG_SUMMARY_REQUEST, types.GET_ORG_SUMMARY_SUCCESS, types.GET_ORG_SUMMARY_FAILURE]
});

const getOrgSummaryNewTimescale = (timescale, value, year = null) => async dispatch => {
  dispatch(actions.changeSummaryTimescale(timescale, value));
  if (timescale === "year") {
    year = value;
  }
  if (timescale === "day") {
    // value should be equivalent to a string of the day 2020-02-25
    // so we overwrite the year
    year = moment(value).year();
  }
  const res = await dispatch(getOrgSummary(timescale, value, year));
  return res;
};

const getLocations = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/${LOCATIONS_ROUTE}/regions/?expand=districts.facilities.wings.zones.subzones`,
  types: [types.GET_LOCATIONS_REQUEST, types.GET_LOCATIONS_SUCCESS, types.GET_LOCATIONS_FAILURE]
});

// Location Operations
const createLocation = (org_id, data) => async dispatch => {
  const res = await dispatch(actions.createLocation(org_id, data));
  baseOperations.handleCreateResponse(res, org_id, "location", data.name, dispatch, () => {
    dispatch(getLocations(org_id));
  });
  return res;
};

const updateLocation = (org_id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateLocation(org_id, data));
  baseOperations.handleUpdateResponse(res, timestamp, "location", data.name, dispatch, () => {
    dispatch(getLocations(org_id));
  });
  return res;
};

const deleteLocation = (org_id, data) => async dispatch => {
  const res = await dispatch(actions.deleteLocation(org_id, data));
  console.log(res);
  dispatch(getLocations(org_id));
  return res;
};

// Asset Type Operations
const getAssetTypes = id => base.getRsaa({
  endpoint: constructRoute(`${endpoint}${id}/${ASSET_TYPES_ROUTE}`),
  types: [types.GET_ASSET_TYPES_REQUEST, types.GET_ASSET_TYPES_SUCCESS, types.GET_ASSET_TYPES_FAILURE]
});

const createAssetType = (org_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.createAssetType(org_id, name, color));
  baseOperations.handleCreateResponse(res, timestamp, "asset type", name, dispatch, () => {
    dispatch(getAssetTypes(org_id));
    dispatch(assetOperations.getAllItems());
  });
  return res;
};

const updateAssetType = (org_id, asset_type_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateAssetType(org_id, asset_type_id, name, color));
  baseOperations.handleUpdateResponse(res, timestamp, "asset type", name, dispatch, () => {
    dispatch(getAssetTypes(org_id));
    dispatch(assetOperations.getAllItems());
  });
  return res;
};

const deleteAssetType = (org_id, asset_type_id) => async dispatch => {
  const res = await dispatch(actions.deleteAssetType(org_id, asset_type_id));
  dispatch(getAssetTypes(org_id));
  dispatch(assetOperations.getAllItems());
  return res;
};

// Personnel Title Operations
const getPersonnelTitles = id => base.getRsaa({
  endpoint: constructRoute(`${endpoint}${id}/${PERSONNEL_TITLES_ROUTE}`),
  types: [types.GET_PERSONNEL_TITLES_REQUEST, types.GET_PERSONNEL_TITLES_SUCCESS, types.GET_PERSONNEL_TITLES_FAILURE]
});

const createPersonnelTitle = (org_id, name) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.createPersonnelTitle(org_id, name));
  baseOperations.handleCreateResponse(res, timestamp, "personnel title", name, dispatch, () => {
    dispatch(getPersonnelTitles(org_id));
  });
  return res;
};

const updatePersonnelTitle = (org_id, personnel_title_id, name) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updatePersonnelTitle(org_id, personnel_title_id, name));
  baseOperations.handleUpdateResponse(res, timestamp, "personnel title", name, dispatch, getItem, () => {
    dispatch(getPersonnelTitles(org_id));
    dispatch(personnelOperations.getAllItems());
  });
};

const deletePersonnelTitle = (org_id, personnel_title_id) => async dispatch => {
  const res = await dispatch(actions.deletePersonnelTitle(org_id, personnel_title_id));
  console.log(res);
  dispatch(getPersonnelTitles(org_id));
  dispatch(personnelOperations.getAllItems());
};

// Vendor Type Operations
const getVendorTypes = id => base.getRsaa({
  endpoint: constructRoute(`${endpoint}${id}/${VENDOR_TYPES_ROUTE}`),
  types: [types.GET_VENDOR_TYPES_REQUEST, types.GET_VENDOR_TYPES_SUCCESS, types.GET_VENDOR_TYPES_FAILURE]
});

const createVendorType = (org_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.createVendorType(org_id, name, color));
  baseOperations.handleCreateResponse(res, timestamp, "vendor type", name, dispatch, () => {
    dispatch(getVendorTypes(org_id));
  });
  return res;
};

const updateVendorType = (org_id, vendor_type_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateVendorType(org_id, vendor_type_id, name, color));
  baseOperations.handleUpdateResponse(res, timestamp, "vendor type", name, dispatch, () => {
    dispatch(getVendorTypes(org_id));
    dispatch(vendorsOperations.getAllItems());
  });
};

const deleteVendorType = (org_id, vendor_type_id) => async dispatch => {
  const res = await dispatch(actions.deleteVendorType(org_id, vendor_type_id));
  console.log(res);
  dispatch(getVendorTypes(org_id));
  dispatch(vendorsOperations.getAllItems());
};

// Stock Status Operations
const getStockAssetStatuses = id => base.getRsaa({
  endpoint: constructRoute(`${endpoint}${id}/${STOCK_STATUS_ROUTE}`),
  types: [types.GET_STOCK_STATUSES_REQUEST, types.GET_STOCK_STATUSES_SUCCESS, types.GET_STOCK_STATUSES_FAILURE]
});

const createStockStatus = (org_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.createStockStatus(org_id, name, color));
  baseOperations.handleCreateResponse(res, timestamp, "stock asset status", name, dispatch, () => {
    dispatch(getStockAssetStatuses(org_id));
  });
  return res;
};

const updateStockStatus = (org_id, stock_status_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateStockStatus(org_id, stock_status_id, name, color));
  baseOperations.handleUpdateResponse(res, timestamp, "stock asset status", name, dispatch, () => {
    dispatch(getStockAssetStatuses(org_id));
    dispatch(stockroomAssetOperations.getAllItems());
  });
};

const deleteStockStatus = (org_id, stock_status_id) => async dispatch => {
  const res = await dispatch(actions.deleteStockStatus(org_id, stock_status_id));
  console.log(res);
  dispatch(getStockAssetStatuses(org_id));
  dispatch(stockroomAssetOperations.getAllItems());
};

// Schedule Status Operations
const getScheduleStatuses = id => base.getRsaa({
  endpoint: constructRoute(`${endpoint}${id}/${SCHEDULE_STATUS_ROUTE}`),
  types: [types.GET_SCHEDULE_STATUSES_REQUEST, types.GET_SCHEDULE_STATUSES_SUCCESS, types.GET_SCHEDULE_STATUSES_FAILURE]
});

const createScheduleStatus = (org_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.createScheduleStatus(org_id, name, color));
  baseOperations.handleCreateResponse(res, timestamp, "schedule status", name, dispatch, () => {
    dispatch(getScheduleStatuses(org_id));
  });
};

const updateScheduleStatus = (org_id, schedule_status_id, name, color) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateScheduleStatus(org_id, schedule_status_id, name, color));
  baseOperations.handleUpdateResponse(res, timestamp, "schedule status", name, dispatch, () => {
    dispatch(getScheduleStatuses(org_id));
    dispatch(scheduledPMOrdersOperations.getAllItems());
  });
};

const deleteScheduleStatus = (org_id, schedule_status_id) => async dispatch => {
  const res = await dispatch(actions.deleteScheduleStatus(org_id, schedule_status_id));
  console.log(res);
  dispatch(getScheduleStatuses(org_id));
  dispatch(scheduledPMOrdersOperations.getAllItems());
};

const updateServiceProviderInfo = (org_id, serviceProviderData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateServiceProviderInfo(org_id, serviceProviderData));
  baseOperations.handleUpdateResponse(res, timestamp, "service provider information", null, dispatch, () => {
    dispatch(getItem(org_id));
  });
};

export default {
  getItem,
  getAllItems,
  getRecentLogs,
  getOrgSummary,
  getOrgSummaryNewTimescale,
  getLocations,

  createLocation,
  updateLocation,
  deleteLocation,

  getAssetTypes,
  createAssetType,
  updateAssetType,
  deleteAssetType,

  getPersonnelTitles,
  createPersonnelTitle,
  updatePersonnelTitle,
  deletePersonnelTitle,

  getVendorTypes,
  createVendorType,
  updateVendorType,
  deleteVendorType,

  getStockAssetStatuses,
  createStockStatus,
  updateStockStatus,
  deleteStockStatus,

  getScheduleStatuses,
  createScheduleStatus,
  updateScheduleStatus,
  deleteScheduleStatus,

  updateServiceProviderInfo,
};
