import React from "react";
import PropTypes from "prop-types";
import {Bottom, TagList, SideList, Wrapper, SeeMoreButton, AddTagsButton} from "../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../Common/Bars/BarComponents/constants";

/* A personnel bar is a div containing information regarding a team member. A list of
personnel bars bars are visible within the Personnel section. */

const PersonnelBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const baseClassName = listView ? listStyle : tileStyle;
  const leftList = [
    {title: "NAME", value: data.name || "None"},
    {title: "JOB", value: data.title?.name ?? "None"},
    {title: "REPORTS TO", value: data.reports_to?.name ?? "Nobody"}
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="personnel"
      itemData={data}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} rightButton={AddTagsButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
      {data.skills &&
            <TagList
              baseClassName={baseClassName}
              listName="SKILLS"
              listArray={data.skills}
            />
      }
    </Wrapper>
  );
};

PersonnelBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array
};

export {PersonnelBar};
