import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {ItemText} from "../../Common/Bars/styled";


const Details = props => {
  const {
    baseClassName,
    itemData,
  } = props;

  return (
    <div className={`${baseClassName}-detail`}>
      <Fragment>
        <ItemText>PM Orders: {itemData.pm_orders.length}</ItemText>
        <ItemText>Stockroom Assets: {itemData.stockroom_assets.length}</ItemText>
      </Fragment>
    </div>
  );
};


Details.propTypes = {
  /**
   * Pass details bar class name for consistent styling.
   */
  baseClassName: PropTypes.string,
  /**
   * Pass data item details object to display item details.
   */
  itemData: PropTypes.object,
};

export {Details};
