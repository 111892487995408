import productReducer, {productCrud, productBarcodes} from "./reducers";

export {default as productTypes} from "./types";
export {default as productSelectors} from "./selectors";
export {default as productOperations} from "./operations";
export {default as productActions} from "./actions";

export {productCrud, productBarcodes};

export default productReducer;
