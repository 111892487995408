import React, {Component, Fragment} from "react";
import {Box, DirectLink, Heading} from "../Base";
import {getAzureBlobURL, uploadBlobsToAzureWithFileNameCheck} from "../../../utils/azure";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import Modal from "../Modals/Modal";
import {DescriptionText} from "../Modals/styled";
import {Button} from "Common/Base";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImageExifOrientation, FilePondPluginImageResize,
  FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginImageTransform);

/* This container will show the files that are attached to an object, and allow attaching new files */
class AttachedFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAttachFile: false,
      deleteFileModalShowing: false,
      fileToDelete: ""
    };
  }

  toggleDeleteFileModal = filename => {
    let modalCurrentlyShowing = this.state.deleteFileModalShowing;
    this.setState({
      deleteFileModalShowing: !modalCurrentlyShowing,
      fileToDelete: modalCurrentlyShowing ? "" : filename
    });
  };

  handleToggleAttachFile = () => {
    this.setState({showAttachFile: !this.state.showAttachFile});
  };

  handleSubmitNewFile = async () => {
    const {refreshObject, azureDocumentPrefix, UUID, getDocuments} = this.props;
    const filepondFiles = this.featurePond.current.getFiles();
    await uploadBlobsToAzureWithFileNameCheck(UUID, azureDocumentPrefix, filepondFiles, "")
      .then(() => this.featurePond.current.removeFiles());
    refreshObject();
    getDocuments();
  };

  render() {
    const {
      files,
      displayImage,
      warrantyDocument,
      handleChangeDisplayImage,
      handleChangeWarrantyDocument,
      handleRemoveDocument,
    } = this.props;
    const {showAttachFile, deleteFileModalShowing, fileToDelete} = this.state;

    this.featurePond = React.createRef();

    // Make the display image and warranty document be first in the list of files
    let orderedFiles = [];
    files.forEach(document => {
      let azureLink = getAzureBlobURL(document.name);
      if (azureLink === warrantyDocument) {
        orderedFiles.unshift(document);
      } else if (azureLink === displayImage) {
        // Always show the warranty document first if it exists
        if (orderedFiles[0] && getAzureBlobURL(orderedFiles[0].name) === warrantyDocument) {
          orderedFiles.splice(1, 0, document);
        } else {
          orderedFiles.unshift(document);
        }
      } else {
        orderedFiles.push(document);
      }
    });
    return (
      <Fragment>
        <Heading color={"colorPrimary"} mr="10px" display="inline">ATTACHED FILES</Heading>
        <ReactTooltip/>
        <FontAwesomeIcon
          style={{cursor: "pointer"}}
          icon="plus"
          size="lg"
          onClick={this.handleToggleAttachFile}
          data-tip={"Attach New Files"}
        />
        <br/>
        {showAttachFile &&
        <Fragment>
          <br/>
          <FilePond
            ref={this.featurePond}
            className="hideProcessFile"
            allowMultiple={true}
            imageCropAspectRatio="1:1"
            imageResizeTargetWidth={600}
            imageResizeTargetHeight={600}
            imageResizeUpscale={false}
            imageResizeMode="cover"
            labelIdle="Drag and drop files to this document"/>
          <Button className="item-details-form-button" onClick={this.handleSubmitNewFile}>
            Upload File
          </Button>
          <br/>
          <br/>
        </Fragment>
        }
        {orderedFiles.map((file, index) => {
          let azureLink = getAzureBlobURL(file.name);
          return (
            <Fragment key={index}>
              <DirectLink href={azureLink} color="#1e6cb6" display="block" className="attached-file">
                {file.name.split("/")[2]}
              </DirectLink>
              <Box color="#1e6cb6">
                {["png", "gif", "jpg", "jpeg"].includes(file.name.split(".").pop()) ?
                  <Fragment>
                    {handleChangeDisplayImage &&
                    <Fragment>
                      {displayImage !== azureLink ?
                        <DirectLink ml={"20px"} color="inherit"
                          onClick={() => handleChangeDisplayImage(file.name)}>
                          <FontAwesomeIcon icon="image" style={{marginRight: "5px"}}/>
                          Make Display Image
                        </DirectLink>
                        :
                        <DirectLink ml={"20px"} color="inherit"
                          onClick={() => handleChangeDisplayImage("")}>
                          <FontAwesomeIcon icon="image" style={{marginRight: "5px"}}/>
                          Remove Display Image
                        </DirectLink>
                      }
                    </Fragment>
                    }
                  </Fragment>
                  :
                  <Fragment>
                    {warrantyDocument !== azureLink ?
                      <DirectLink ml={"20px"} color="inherit"
                        onClick={() => handleChangeWarrantyDocument(file.name)}>
                        <FontAwesomeIcon icon="file-alt" style={{marginRight: "5px"}}/>
                        Make Warranty Document
                      </DirectLink>
                      :
                      <DirectLink ml={"20px"} color="inherit"
                        onClick={() => handleChangeWarrantyDocument("")}>
                        <FontAwesomeIcon icon="file-alt" style={{marginRight: "5px"}}/>
                        Remove Warranty Document
                      </DirectLink>
                    }
                  </Fragment>
                }
                <DirectLink ml={"20px"} color="inherit" cursor="pointer"
                  onClick={() => this.toggleDeleteFileModal(file.name)}>
                  <FontAwesomeIcon icon="trash" style={{marginRight: "5px"}}/>
                  Remove File
                </DirectLink>
              </Box>
            </Fragment>
          );
        })}
        <Modal
          visible={deleteFileModalShowing}
          title={`Delete ${fileToDelete.split("/").pop() || "File"}?`}
          onCancel={this.toggleDeleteFileModal}
          onOk={() => {
            this.toggleDeleteFileModal();
            handleRemoveDocument(fileToDelete);
          }}
          isDeleteModal
        >
          <DescriptionText>Are you sure you want to delete this file?</DescriptionText>
        </Modal>
      </Fragment>
    );
  }
}

AttachedFiles.propTypes = {
  files: PropTypes.array,
  displayImage: PropTypes.string,
  warrantyDocument: PropTypes.string,
  handleChangeDisplayImage: PropTypes.func,
  handleChangeWarrantyDocument: PropTypes.func,
  handleRemoveDocument: PropTypes.func,
};

AttachedFiles.defaultProps = {
  displayImage: "",
  files: []
};

export default AttachedFiles;
