/* Returns the complete list of items returned from getAllItems operation. */
import moment from "moment";
import {calendarUtils} from "../../utils";

function refreshAllItems(state) {
  if (state.items) {
    return state.items;
  }
  return [];
}


function refreshCalendarDates(state) {
  if (state.calendar_dates) {
    return state.calendar_dates;
  }
  return {from_date: moment().toISOString(), to_date: moment().add(1, "month").toISOString()};
}

function refreshCalendar(state) {
  if (state.calendar) {
    let result = calendarUtils.parseCalendarQueryResult(state.calendar);
    return result;
  }
  return [];
}


const refreshScheduleNameList = (state) => {
  if (state.items) {
    return state.items.map(item => ({
      id: item.id,
      value: item.id,
      label: item.name
    }));
  }
};

const refreshActiveActivity = (state) => {
  if (state.timeTracker && state.timeTracker.active) {
    return state.timeTracker.active;
  }
  return null;
};


const refreshRecentActivity = (state) => {
  if (state.timeTracker && state.timeTracker.recent) {
    return state.timeTracker.recent;
  }
  return null;
};


export default {
  refreshAllItems,
  refreshCalendarDates,
  refreshCalendar,
  refreshScheduleNameList,
  refreshActiveActivity,
  refreshRecentActivity
};
