import React from "react";
import {basePreSaveFormat, deleteUnwantedProps} from "../../../Common/Forms/Base/utils";


export const load = async (values) => {
};


export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  let result = values;

  return result;
};


export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  if (values.quantity && values.quantityLocationData) {
    // Adding a new quantity with a location assigned
    if (Object.keys(values.quantityLocationData).length !== 0) {
      const {location_instance, level} = values.quantityLocationData.location;
      result.quantities = [{
        "quantity": values.quantity,
        "in_use": values.in_use,
        "location": location_instance,
        "level": level
      }];
    } else {
      result.quantities = [{"quantity": values.quantity}];
    }
    delete result.quantity;
  }
  delete result.quantityLocationData;

  if (values.quantities) {
    result.quantities = result.quantities.map(result => {
      result = deleteUnwantedProps(result);
      if (result.location && result.location.id) {
        result.location = result.location.id;
      }
      return result;
    });
  }

  return result;
};
