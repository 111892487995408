import React from "react";
import {basePreSaveFormat} from "../../../Common/Forms/Base/utils";
import {palletQuantityStatus, palletStatus} from "../../utils";

export const load = async (values) => {
};

export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  return {
    ...values,
    status: palletStatus.RECEIVED,
  };
};

export const postLoadQuantityFormat = (values, type = "product") => {
  // const {}
  // let result = {
  //
  // }
};

export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);
  // if (result.product) {
  //   delete result.product;
  // }
  return result;
};

const quantityHelper = (product, location) => {
  let productQuantity = product.quantities.find(quantity => {
    if (location && quantity.location && (quantity.location.id === location.location.id)) {
      return true;
    }
    return false;
  });
  return productQuantity;
};

export const buildProductOptions = (theList, location) => {
  let result = [];
  let localList = theList.filter(product => quantityHelper(product, location));
  let length = localList.length;
  for (let i = 0; i < length; i++) {
    result.push({value: i + 1, label: localList[i].name, productId: localList[i].id, quantity: quantityHelper(localList[i], location).quantity});
  }
  return result;
};


export const buildInventoryOptions = (inventory) => {
  let result = [];
  let length = inventory.length;
  for (let i = 0; i < length; i++) {
    result.push(
      {
        value: inventory[i].id,
        label: inventory[i].name,
        productId: inventory[i].product.id,
        quantity: inventory[i].avaliable_quantity,
      },
    );
  }
};


export const initializeReceiveInventory = ({inventory, location}) => {
  const data = {};
  inventory.forEach(item => data[item.id] = {
    id: item.id,
    location: location.id,
    status: palletQuantityStatus.PACKED,
    stored_at: null,
    quantity: item.quantity,
  });
  return data;
};

