import {sort} from "../../utils";

export const NUM_ITEMS_PER_PAGE = 12;
export const DEFAULT_SORT_BY_KEY = "created_at";

export const INITIAL_STATE = {
  loading: true,
  items: [],
  data: {},
  notes: {},
  currentPage: 1,
  pages: 1,
  itemsPerPage: NUM_ITEMS_PER_PAGE,
  listView: true,
  tileView: false,
  tableView: false,
  filter: "",
  filterList: [],
  pageList: [],
  messages: null,
  errors: {},
  noneFound: false,
  sortBy: DEFAULT_SORT_BY_KEY,
  isReversed: false,
};

/* Helper function to compute number of pages */
export const getNumberOfPages = (length, itemsPerPage) => (
  Math.ceil(length / itemsPerPage)
);

/**
 * Higher order function that returns a reducer with the pagination actions managed
 * @param {object} types Object of action types - the types of actions for that reducer
 * @param {Function} reducer the reducer that will extend the functionality of this one
 * @returns {object} A reducer with the pagination actions managed
 */
export const withPagination = (types, reducer) => (state, action) => {
  switch (action.type) {
  // ---------------------
  // PAGINATION
  /* Calculates pageList for the next page. */
  case types.NEXT_PAGE: {
    const list = state.filterList;
    const {currentPage, itemsPerPage} = state;
    const nextPage = currentPage + 1;

    if (nextPage > Math.ceil(list.length / itemsPerPage)) {
      // Return state if next page out of bounds.
      return {
        ...state
      };
    }
    const lastIndex = nextPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;

    const currentList = list.slice(firstIndex, lastIndex);
    return {
      ...state,
      currentPage: nextPage,
      pageList: currentList
    };
  }
  /* Calculates pageList for prev page.  */
  case types.PREV_PAGE: {
    const list = state.filterList;
    const {currentPage, itemsPerPage} = state;
    const prevPage = currentPage - 1;
    if (prevPage < 1) {
      // Return state if prev page out of bounds.
      return {
        ...state
      };
    }
    const lastIndex = prevPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentList = list.slice(firstIndex, lastIndex);
    return {
      ...state,
      currentPage: prevPage,
      pageList: currentList
    };
  }
  default:
    return reducer(state, action);
  }
};

/**
 * Higher order function that returns a reducer with the sorting actions managed
 * @param {object} types Object of action types - the types of actions for that reducer
 * @param {Function} reducer the reducer that will extend the functionality of this one
 * @returns {object} A reducer with the sorting actions managed
 */
export const withSorting = (types, reducer) => (state, action) => {
  switch (action.type) {
  // ---------------------
  // PAGINATION
  /* Calculates pageList for the next page. */
  /* Sorts items in state by sort-by selection  */
  case types.SORT_LIST: {
    const newSortBy = action.payload;
    const newFilterList = sort.sortByKey(state.filterList, newSortBy, state.isReversed);
    return {
      ...state,
      sortBy: newSortBy,
      filterList: newFilterList,
      pageList: newFilterList.slice(0, state.itemsPerPage)
    };
  }

  // Reverses the list of items on the page
  case types.REVERSE_LIST: {
    const filterList = state.filterList.reverse();
    const pages = getNumberOfPages(filterList.length, state.itemsPerPage);
    const isReversed = !state.isReversed;
    return {
      ...state,
      pages,
      filterList,
      pageList: filterList.slice(0, state.itemsPerPage),
      isReversed
    };
  }
  default:
    return reducer(state, action);
  }
};

/**
 * Higher order function that returns a reducer with the view Mode actions managed
 * @param {object} types Object of action types - the types of actions for that reducer
 * @param {Function} reducer the reducer that will extend the functionality of this one
 * @returns {object} A reducer with the view Mode actions managed
 */
export const withViewModes = (types, reducer) => (state, action) => {
  switch (action.type) {
  /* Change to a list view. */
  case types.CHANGE_VIEW_LIST: {
    return {
      ...state,
      listView: true,
      tileView: false,
      tableView: false,
      calendarView: false
    };
  }

  /* Change to a tile view. */
  case types.CHANGE_VIEW_TILE: {
    return {
      ...state,
      listView: false,
      tileView: true,
      tableView: false,
      calendarView: false
    };
  }

  /* Change to a table view. */
  case types.CHANGE_VIEW_TABLE: {
    return {
      ...state,
      listView: false,
      tileView: false,
      tableView: true,
      calendarView: false
    };
  }

  /* Change to a calendar view. */
  case types.CHANGE_VIEW_CALENDAR: {
    return {
      ...state,
      listView: false,
      tileView: false,
      tableView: false,
      calendarView: true
    };
  }
  default:
    return reducer(state, action);
  }
};

/**
 * Higher order function that returns a reducer with the CSV actions managed
 * @param {object} types Object of action types - the types of actions for that reducer
 * @param {Function} reducer the reducer that will extend the functionality of this one
 * @returns {object} A reducer with the CSV actions managed
 */
export const withCsv = (types, reducer) => (state, action) => {
  switch (action.type) {
  case types.UPLOAD_CSV_SUCCESS: {
    return {
      ...state,
      messages: action.payload
    };
  }

  case types.UPLOAD_CSV_FAILURE: {
    return {
      ...state,
      messages: action.payload.response
    };
  }

  case types.CLEAR_MESSAGES: {
    return {
      ...state,
      messages: null
    };
  }

  default:
    return reducer(state, action);
  }
};

/**
 * Higher order function that returns a reducer with the sorting, view modes, and pagination actions managed
 * @param {object} types Object of action types - the types of actions for that reducer
 * @param {Function} reducer the reducer that will extend the functionality of this one
 * @returns {object} A reducer with the sorting, view modes, and pagination actions managed
 */
export const baseReducer = (types, reducer) => withViewModes(types, withSorting(types, withPagination(types, reducer)));
