import React from "react";
import ReactDOM from "react-dom";
// import "whatwg-fetch";
import App from "./App";

import * as serviceWorker from "./serviceWorker";


/* Inject React into div with ID root, wrapped with a Redux provider. */
ReactDOM.render(
  <App/>,
  document.getElementById("root")
);

serviceWorker.unregister();
