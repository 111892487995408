import types from "./types";
import {name as endpoint} from "../vendors";
import base from "../base/actions";
import {constructRoute} from "../base/utils";
import {BULK_DELETE} from "../../routes/constants";

/*
// GET all items actions
*/

/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getAllItems = () => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});

const getAllSuccess = data => ({
  type: types.GET_ALL_SUCCESS,
  payload: data
});

const getAllFailure = error => ({
  type: types.GET_ALL_FAILURE,
  payload: error
});

const getAllNoneFound = () => ({
  type: types.GET_ALL_NONE_FOUND,
  payload: "No Vendor Orders Found"
});

/* Fetches a single item from the api */
const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

/* Update item actions. */

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});

const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

const bulkDelete = (data) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${BULK_DELETE}`),
  body: JSON.stringify(data),
  types: [
    types.BULK_DELETE_REQUEST,
    types.BULK_DELETE_SUCCESS,
    types.BULK_DELETE_FAILURE
  ]
});

const updateItemRequest = () => ({
  type: types.UPDATE_ITEM_REQUEST
});

const updateItemSuccess = item => ({
  type: types.UPDATE_ITEM_SUCCESS,
  payload: item
});

const updateItemFailure = error => ({
  type: types.UPDATE_ITEM_FAILURE,
  payload: error
});

/* SimplePagination actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  payload: by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

const filterListBy = by => ({
  type: types.FILTER_LIST_BY,
  payload: by
});

const reverseList = () => ({
  type: types.REVERSE_LIST
});

/* View related actions. */
const changeViewList = () => ({
  type: types.CHANGE_VIEW_LIST
});

const changeViewTile = () => ({
  type: types.CHANGE_VIEW_TILE
});

const changeViewTable = () => ({
  type: types.CHANGE_VIEW_TABLE
});

const changeViewCalendar = () => ({
  type: types.CHANGE_VIEW_CALENDAR
});

export default {
  getAllItems,
  getAllSuccess,
  getAllFailure,
  getAllNoneFound,
  getItem,
  getItemNotes,
  submitNote,
  updateItem,
  updateItemRequest,
  updateItemSuccess,
  updateItemFailure,
  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  filterListBy,
  reverseList,
  changeViewList,
  changeViewTile,
  changeViewTable,
  changeViewCalendar,
  bulkDelete
};
