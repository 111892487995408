import {isEqual} from "lodash-es";

/**
 * Creates list of options for react-select fields
 *
 * @param {[{}]} values - array of values
 * @param {{label: string, value: string}} options - fields to be used
 * @returns {[]} {value: string, label: string} - Array of select options
 */
export const formatSelectOptions = (values = [], options = {}) => {
  const {label, value} = Object.assign({label: "name", value: "id"}, options);

  return values.map(item => ({label: item[label], value: item[value], ...item}));
};

/**
 * Compares the two objects and returns an object with only the changed values
 * Does not go deeper than 1 key
 * @param {object} values - new values
 * @param {object} originalValues - original values
 * @returns {object} - Changed values
 */
export const getChangedValues = (values, originalValues) => {
  const changedValues = Object.keys(values).reduce((result, key) => {
    // We need to use lodash's isEqual algorithm to check the values of any object or array type values
    // since JS compares instances of objects and not the values by default
    // this first if handles edge cases with lodash's isEqual and comparing null
    if (!values[key] && !originalValues[key]) {
      return result;
    }
    if (!(isEqual(values[key], originalValues[key]))) {
      result[key] = values[key];
    }
    return result;
  }, {});

  return changedValues;
};
