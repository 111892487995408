import React, {Fragment} from "react";
import {Link} from "Base";
import {ItemName, ItemText} from "Common/Bars/styled";
import {palletStatus} from "../../utils";
import {generateBreadcrumb} from "../../../../utils/location";

/**
 * Returns the Facility location of the pallet
 * @param {object} data - pallet data
 * @returns {*} - Render's location with label based on status
 */
export const parseLocation = (data) => {
  const {location, status_display} = data;
  let label = "LOCATION";
  let value = "None";
  if (status_display === "Incoming") {
    label = "DESTINATION";
  }
  if (location && location.id) {
    value = generateBreadcrumb(location);
  }
  return {value: value, label: label};
};

/**
 * Renders pallet destination address if it exists
 *
 * @param {object} data - pallet data
 * @param {string} baseClassName - class name derived from redux
 * @returns {*} - renders Address or No History
 */
export const renderDestination = (data, baseClassName) => {
  const {destination, origin, status} = data;
  if (status === palletStatus.OUTGOING) {
    return destination ? (
      <ItemText className={`${baseClassName}-main-left-text`}>
        <strong
          className={`${baseClassName}-main-left-text-dark`}>DESTINATION:</strong>
        <br/>
        {destination.name && destination.name}
        <br/>
        {destination.address_line1}
        <br/>
        {destination.city}, {destination.state} {destination.zip_code}
      </ItemText>

    ) : <ItemText color={"white"}>No History</ItemText>;
  }
  if (status === palletStatus.INCOMING) {
    return origin ? (
      <ItemText className={`${baseClassName}-main-left-text`}>
        <strong
          className={`${baseClassName}-main-left-text-dark`}>ORIGIN:</strong>
        <br/>
        {origin.name && origin.name}
        <br/>
        {origin.address_line1}
        <br/>
        {origin.city}, {origin.state} {origin.zip_code}
      </ItemText>

    ) : <ItemText color={"white"}>No History</ItemText>;
  }
};

/**
 * Gets the reference of the order data, or if that doesn't exist, then the ID
 * @param {object} orderData The data of the purchase or sales order
 * @returns {string} The main label of the order
 */
const getOrderLabel = (orderData) => {
  return orderData.reference ? orderData.reference : orderData.id;
};

export const renderOrderInfo = (data, baseClassName) => {
  let poValue = "None";
  let soValue = "None";
  let customer = "None";
  let supplier = "None";

  const {purchase_order, sales_order} = data;
  if (purchase_order) {
    const label = getOrderLabel(purchase_order);
    poValue = <Link to={`/dashboard/warehouse/purchase_orders/${purchase_order.id}`}>{label}</Link>;
    if (purchase_order.supplier) {
      supplier = purchase_order.supplier.name;
    }
  }

  if (sales_order) {
    const label = getOrderLabel(sales_order);
    soValue = <Link to={`/dashboard/warehouse/sales_orders/${sales_order.id}`}>{label}</Link>;
    if (sales_order.customer) {
      customer = sales_order.customer.name;
    }
  }

  const className = `${baseClassName}-main-left-text`;
  return (
    <Fragment>
      <ItemName className={className}>
                PO #: {poValue}
      </ItemName>
      <ItemName className={className}>
                SO #: {soValue}
      </ItemName>
      <ItemName className={className}>
                SUPPLIER: {supplier}
      </ItemName>
      <ItemName className={className}>
                CUSTOMER: {customer}
      </ItemName>
    </Fragment>
  );
};
