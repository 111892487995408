import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {Drawer, TooltipButton} from "../Base";
import {Button} from "antd";
import {IconButton} from "@chakra-ui/core";
import {useMediaQuery} from "react-responsive";
import {MOBILE} from "utils/constants";


const FormDrawer = ({
  values, formID, text, onSubmit, FormComponent, toggleDisabled, toggleIcon, buttonConfig, formConfig, valid, submitClassname,
}) => {
  const isTabletOrMobile = useMediaQuery(MOBILE);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // TODO: show errors
  const [errors, setErrors] = useState(null);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  const handleSubmit = async (values) => {
    setSubmitting(true);
    let res = await onSubmit(values);
    let returnData = {};
    console.log("form drawer res", res);
    if (!res.error) {
      returnData = res.payload;
      toggleDrawer();
    } else {
      if (res.payload && res.payload.response) {
        setErrors(res.payload.response);
        returnData = null;
      }
      console.log("error saving form", errors);
    }
    setSubmitting(false);
    return returnData;
  };

  const renderForm = () => {
    return drawerOpen ? (
      <FormComponent
        id={formID}
        onSubmit={handleSubmit}
        initialValues={values}
        // values={values}
        errors={errors}
        {...formConfig}
      />
    ) : null;
  };

  const drawerWidth = isTabletOrMobile ? "100%" : 600;

  const {title, openButton} = text;

  const {ariaLabel, ...restButtonConfig} = buttonConfig;
  const buttonClassName = `open-${formID}`;
  return (
    <Fragment>

      <TooltipButton
        className={`form-drawer-button ${buttonClassName} `}
        aria-label={ariaLabel}
        onClick={toggleDrawer}
        disabled={toggleDisabled}
        icon={toggleIcon}
        {...restButtonConfig}
      >
        {openButton}
      </TooltipButton>
      <Drawer
        visible={drawerOpen}
        onClose={toggleDrawer}
        width={drawerWidth}
        title={title}
        className={`${formID}-drawer`}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={toggleDrawer}
              style={{marginRight: 8}}
            >
              Cancel
            </Button>
            <Button
              className={submitClassname}
              onClick={() => {
                console.log("formID", formID);
                document
                  .getElementById(formID)
                  .dispatchEvent(new Event("submit", {cancelable: true}));
              }}
              type="primary"
              loading={submitting}
              disabled={!valid}
            >
              Submit
            </Button>
          </div>
        }
      >

        {renderForm()}

      </Drawer>
    </Fragment>
  );
};

FormDrawer.propTypes = {
  // default values to initialize main form with
  values: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  FormComponent: PropTypes.any.isRequired,
  ToggleButton: PropTypes.any,
  toggleDisabled: PropTypes.bool,
  text: PropTypes.shape({
    title: PropTypes.string,
    tooltip: PropTypes.string,
    openButton: PropTypes.string,
  }),
  toggleIcon: PropTypes.element,
  buttonConfig: PropTypes.shape({
    ariaLabel: PropTypes.string.isRequired,
  }),
  formConfig: PropTypes.object,
  valid: PropTypes.bool,
  submitClassname: PropTypes.string,
  formID: PropTypes.string.isRequired,
};

FormDrawer.defaultProps = {
  ToggleButton: IconButton,
  toggleDisabled: false,
  title: "New Item",
  tooltip: null,
  text: {
    tooltip: null,
    title: "New Item",
    openButton: "New Item",
  },
  toggleIcon: null,
  buttonConfig: {
    ariaLabel: "Open Create Form",
    type: "primary",
  },
  submitClassname: "save-btn",
  formConfig: {},
  valid: true,
};

export default FormDrawer;
