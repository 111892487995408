/**
 * Returns customer list formatted for select options
 * @param {[object]} data - customer list from api
 * @returns {[{label, string}]} - options list
 */
export const formatCustomerList = (data) => {
  if (data) {
    return data.map(item => ({value: item.id, label: item.name}));
  } else {
    return [];
  }
};
