import actionTyper from "redux-actiontyper";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_NONE_FOUND,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,

  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CLEAR_MESSAGES,

  GET_INVENTORY_REQUEST,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,

  ADD_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILURE,

  RECEIVE_INVENTORY_REQUEST,
  RECEIVE_INVENTORY_SUCCESS,
  RECEIVE_INVENTORY_FAILURE,

  PICK_UP_PALLET_REQUEST,
  PICK_UP_PALLET_SUCCESS,
  PICK_UP_PALLET_FAILURE,
} = actionTyper("@@pallets/");

export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ALL_NONE_FOUND,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,

  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CLEAR_MESSAGES,

  GET_INVENTORY_REQUEST,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,

  ADD_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILURE,

  RECEIVE_INVENTORY_REQUEST,
  RECEIVE_INVENTORY_SUCCESS,
  RECEIVE_INVENTORY_FAILURE,

  PICK_UP_PALLET_REQUEST,
  PICK_UP_PALLET_SUCCESS,
  PICK_UP_PALLET_FAILURE,
};
