import React from "react";
import PropTypes from "prop-types";
import {Bottom, SeeMoreButton, SideList, Wrapper} from "../../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../../Common/Bars/BarComponents/constants";
import {Details} from "./Details";

/* An Sales Order bar is a div containing information regarding an asset. A list of
asset bars are visible within the assets section. */
const SalesOrderBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const {total_cost, location, reference, items, created_at, created_by} = data;
  const baseClassName = listView ? listStyle : tileStyle;
  const leftList = [
    {title: "SALES ORDER #", value: reference || "None"},
    {title: "CREATED AT", value: created_at || "None"},
    {title: "CREATED BY", value: created_by && created_by.name ? created_by.name : ""}
  ];
  const rightList = [
    {title: "TOTAL", value: total_cost || "None"},
    {title: "ITEMS", value: items && items.length ? items.length : "None"}
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="warehouse/sales_orders"
      itemData={data}
      detailsPane={<Details baseClassName={baseClassName} location={location}/>}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
      <SideList
        baseClassName={baseClassName}
        isRight={true}
        list={rightList}
      />
    </Wrapper>
  );
};

SalesOrderBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array
};

export {SalesOrderBar};
