import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import voca from "voca";
import {List} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Heading} from "../Base";
import Modal from "./";
import {LocationGroup} from "../Forms";
import {checkNestedKeys} from "../../../utils/propUtils";
import {getDeepestLocation} from "../../../utils/location";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
  subheader: {
    backgroundColor: "white"
  }
}));

const LocationModal = (props) => {
  const [selectedLocation, setSelectedLocation] = useState({});

  const {
    headerText,
    closeModal,
    submit,
    locationList,
    isAdmin,
    assignedLocation,
    visible
  } = props;

  const classes = useStyles();

  const handleSubmit = () => {
    const [selectedLevel, selectedId, selectedName] = selectedLocation.uniqueID.split(":", 3);
    submit({
      location: {
        "level": selectedLevel,
        "id": selectedId,
        "name": selectedName,
        "location_instance": selectedLocation.item.location_instance
      }
    });
    closeModal();
  };

  const handleRemove = () => {
    submit({location: {"id": -1, "location_instance": null}});
    closeModal();
  };

  const locationListIsEmpty = locationList.length === 0;
  let [loc_rung_name, loc_obj] = getDeepestLocation(assignedLocation);

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      title={headerText}>
      {Object.keys(loc_obj).length !== 0 &&
      <Box mb={15} fontSize="1.5rem">Current Location: <b>{voca.capitalize(loc_rung_name)}</b> {loc_obj.name}</Box>
      }

      {!locationListIsEmpty ?
        <List className={classes.list}>
          <LocationGroup
            handleEdit={() => {
            }}
            items={locationList}
            levels={["regions", "districts", "facilities", "wings", "zones", "subzones"]}
            classes={classes}
            isEditable={false}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
        </List>
        : <Fragment>
          {isAdmin && <Heading as="h3">Create one from the admin panel, which you can access in the top left</Heading>}
        </Fragment>
      }
      <br/>
      {!locationListIsEmpty &&
      <Fragment>
        {(assignedLocation.id || checkNestedKeys(["location", "id"], assignedLocation)) &&
        <Button
          className="modal-card-button remove-location"
          onClick={handleRemove}>
          Remove Location
        </Button>
        }
        {submit &&
        <Button
          className="modal-card-button confirm-location"
          type={"primary"}
          onClick={handleSubmit}
          disabled={Object.keys(selectedLocation).length === 0}>
          Confirm
        </Button>
        }
      </Fragment>
      }
    </Modal>
  );
};

LocationModal.propTypes = {
  /**
   * The text to display at the top of the modal
   */
  headerText: PropTypes.string,
  /**
   * The function that closes the modal
   */
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  locationList: PropTypes.array.isRequired,
  assignedLocation: PropTypes.object,
  /**
   * If the current user is an admin
   */
  isAdmin: PropTypes.bool,
  /**
   * If the modal is visible
   */
  visible: PropTypes.bool.isRequired
};

LocationModal.defaultProps = {
  headerText: "Header",
  assignedLocation: {},
  isAdmin: false
};

export default LocationModal;
