import createCrudReducer from "./reducers";
import createCrudActionTypes from "./types";
import createCrudActions, {createNestedCrudActions} from "./actions";
import createCrudOperations from "./operations";
import {createPaginator} from "../paginator";
/**
 * @typedef {object} CrudModule
 * @property {object} types - The X Coordinate
 * @property {object} actions - deleteItem, createItem, getItem, updateItem,
 * getItemNotes, submitNote
 * @property {object} operations - deleteItem, createItem, getItem, updateItem,
 * addNote
 */

/**
 * Creates CRUD store utilities
 * @param {object} options create options
 * @param {string} options.endpoint base endpoint eg: "v1/products/"
 * @param {string} options.name name of duck eg: "products"
 * @param {boolean} options.withNotes includes note operations and reducer
 * @param {boolean} options.withPagination includes pagination operations and reducer
 * @param {boolean} options.withCsv includes csv operations and reducer
 * @returns {CrudModule} - Redux CRUD utilities
 */
const createCrud = ({endpoint, name, withNotes = false, withPagination = false, withCsv = false, withBulkDelete = false}) => {
  let paginator = null;
  if (withPagination) {
    paginator = createPaginator({
      name,
      endpoint
    });
  }
  const types = createCrudActionTypes({name, withNotes, withCsv, withBulkDelete});

  const actions = createCrudActions({types, endpoint, withNotes, withCsv, paginator, withBulkDelete});

  const operations = createCrudOperations({actions, name, withNotes, withCsv, paginator, withBulkDelete});

  const reducer = () => createCrudReducer(types, {withNotes, withCsv, paginator});

  return {
    types,
    actions,
    operations,
    reducer,
  };
};

const createNestedCrud = ({endpoint, name, nestedEndpoint}) => {
  const types = createCrudActionTypes(name);

  const actions = createNestedCrudActions({types, endpoint, nestedEndpoint});

  const operations = createCrudOperations({actions, name});

  const reducer = () => createCrudReducer(
    types,
    {withCsv: false, withNotes: false, paginator: false}
  );

  return {
    types,
    actions,
    operations,
    reducer,
  };
};

export {createNestedCrud};

export default createCrud;
