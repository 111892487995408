import React from "react";
import {generateBreadcrumb} from "../../../../utils/location";
import PropTypes from "prop-types";
import {Box} from "../../../Common/Base";
// import ProductInventoryList from "../Lists/ProductInventory/ProductInventoryList";
import {ProductInventoryTable} from "../../Table";

const ProductInventoryDetails = props => {
  const {productDetails} = props;
  const {inventory} = productDetails;

  const tableCompatableInventory = inventory && inventory.map((item, index) => {
    const {location} = item;
    const key = item.id;
    let locationString = "None";

    if (location) {
      locationString = generateBreadcrumb(location);
      if (location.code) {
        locationString = locationString === "" ? location.code : `${locationString} - ${location.code} `;
      }
    }
    return {
      ...item,
      location: locationString,
      available: item.available_quantity,
      key
    };
  });

  return (
    <Box pt={2}>
      <ProductInventoryTable
        items={tableCompatableInventory}
      />
    </Box>
  );
};

ProductInventoryDetails.propTypes = {
  productDetails: PropTypes.object
};

export default ProductInventoryDetails;
