import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {CheckboxControl} from "../../Base/Inputs/InputControls";
import {AutoSave} from "../Base";
import {Label, Box} from "../../Base";

const NotificationSettingsForm = props => {
  const {
    onSubmit,
    values,
  } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      render={({
        handleSubmit,
      }) => (
        <form className={"notification-settings-form"} onSubmit={handleSubmit}>
          <AutoSave
            save={onSubmit}
            debounced={[]}
          />
          <Label>Email summary of recent activity:</Label>
          <Box px={2}>
            <CheckboxControl name="daily">Daily</CheckboxControl>

            <CheckboxControl name="weekly">Weekly</CheckboxControl>

            <CheckboxControl name="monthly">Monthly</CheckboxControl>

          </Box>
        </form>
      )}
    />
  );
};

NotificationSettingsForm.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.any,
};

export default NotificationSettingsForm;
