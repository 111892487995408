import React from "react";
// Import PropTypes from "prop-types";
import {Flex, Text} from "@chakra-ui/core";
// Import {ErrorBoundry} from "../Base";
import moment from "moment";

export const ColoredDateCellWrapper = ({children}) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      // BackgroundColor: 'lightblue',
    },
  });

const CalendarEvent = props => {
  const {event, isAllDay} = props;
  const {title} = event;
  const showTime = isAllDay === null;
  const showPersonnel = !props.localizer;
  const start = moment(event.start_time, "HH:mm:ss").format("h:mm a");
  const end = moment(event.end_time, "HH:mm:ss").format("h:mm a");
  return (
    <Flex flexDirection={"column"} className={"rbc-event"}>
      {showPersonnel ?

        <Text mb={2}>{title} {showTime && `${start} - ${end}`}</Text>

        :
        <Text>{title} {`${start} - ${end}`}</Text>
      }

      {showPersonnel &&

      event.personnel.map(item => (
        <Text key={item}>{item}</Text>
      )
      )
      }

    </Flex>
  );
};

CalendarEvent.propTypes = {

};

export default CalendarEvent;
