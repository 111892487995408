import React from "react";
import PropTypes from "prop-types";
import {SCHEDULED_PM_ORDERS_DASHBOARD_ROUTE} from "../../../../routes/constants";
import {Link} from "../../../Common/Base";
import {getDeepestLocation} from "../../../../utils/location";
import {formatStartEndTime} from "../../../../utils/dates";

/* Used exclusively by the PMOrderDetailsContainer, this will display an scheduled
PM order's details. */
const ScheduledPMOrder = ({item}) => {
  let itemTitle = "No Asset Or Location";
  if (item?.stockroom_asset?.name) {
    itemTitle = item.stockroom_asset.name || "Unnamed Asset";
  } else if (item?.location) {
    const [rung, locationObj] = getDeepestLocation(item.location);
    itemTitle = `${rung}: ${locationObj.name || "Unnamed Location"}`;
  }

  const timeRange = formatStartEndTime(item?.start_time ?? null, item?.end_time ?? null);

  return (
    <div className="item-details-pane-right-scheduled">
      <Link
        className="item-details-pane-right-scheduled-content"
        to={`${SCHEDULED_PM_ORDERS_DASHBOARD_ROUTE}/${item.id}`}>
        <div className="item-details-pane-right-scheduled-text">
          <h3>{itemTitle} </h3>
          <p>
            <strong>DATE:</strong>
            {item.date}

          </p>
          {timeRange &&
            <p>{timeRange}</p>
          }
        </div>
        <div className="item-details-pane-right-scheduled-text">
          <p>
            <strong>STATUS:</strong> {item?.status?.name ?? "None"}
          </p>
          <p>
            <strong>ASSIGNED TO:</strong> {item?.personnel?.name ?? "Nobody"}
          </p>
        </div>
      </Link>
    </div>
  );
};


ScheduledPMOrder.propTypes = {
  item: PropTypes.shape({
    stockroom_asset: PropTypes.object,
    date: PropTypes.string,
    status: PropTypes.object,
    personnel: PropTypes.object,
  }).isRequired,
};

export default ScheduledPMOrder;
