import React, {Component} from "react";
import {connect} from "react-redux";
import {operations, actions, scheduleActions} from "../../store/orders/pmOrders";
import {
  authSelectors,
  paginationSelectors,
  crudSelectors,
  pmOrderSelectors,
  orgSelectors,
  orgOperations
} from "../../store";
import PMOrderDashboard from "../../components/PMOrders/Dashboard/PMOrdersDashboard";

const mapStateToProps = (state) => {
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    pagination: paginationSelectors.pagination(state.orders.pmOrders.items),
    csvMessages: crudSelectors.refreshCsvMessages(state.orders.pmOrders),
    scheduleList: pmOrderSelectors.refreshSchedulePmOrderList(state.orders.scheduledPm.items),
    locationList: orgSelectors.refreshLocations(state.orgs),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      create: (data) => dispatch(operations.createItem(data)),
      update: (id, data) => dispatch(operations.updateItem(id, data)),
      delete: (id) => dispatch(operations.deleteItem(id)),
      getPage: (page, params) => {
        const finalParams = {
          ...params,
          expand: "location"
        };
        return dispatch(actions.fetchPage({page, params: finalParams}));
      }
    },
    changeView: (view) => dispatch(actions.changeView(view)),
    uploadCsv: (data, org) => dispatch(operations.uploadCsv(data, org)),
    clearMessages: () => dispatch(actions.clearMessages()),
    bulkDelete: (data) => dispatch(operations.bulkDelete(data)),
    getAllSchedules: (page = 1, params = {}) => {
      const finalParams = {
        ...params,
        expand: "location"
      };
      return dispatch(scheduleActions.fetchPage({page, params: finalParams}));
    },
    getLocations: (id) => dispatch(orgOperations.getLocations(id)),
  };
};

class PmOrderDashboardContainer extends Component {
  render() {
    return (
      <PMOrderDashboard {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PmOrderDashboardContainer);
