import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {Flex, Button, Menu, SubMenu, Drawer} from "Base";
import {renderSubMenuItem} from "./utils";
import MenuIcon from "@material-ui/icons/Menu";
import {NAV_LINKS, MOBILE_NAV_LINKS} from "../../../routes/routes";
import {authSelectors} from "../../../store/";
import {MainMenu} from "../Header/Menu";

const RegularNav = (props) => {
  const {
    isMobile,
    location,
    sideMenu,
    submenuSelected,
    account,
    resetStore,
  } = props;

  const [subMenuVisible, setSubMenuVisible] = useState([]);
  const [visible, setVisible] = useState(false);
  const {pathname} = location;

  const activeKey = pathname.split("/")[2];

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const toggleSubmenuVisible = () => {
    if (subMenuVisible.includes("submenu")) {
      setSubMenuVisible([]);
    } else {
      setSubMenuVisible(["submenu"]);
    }
  };

  const handleItemClick = ({key}) => {
    console.log("clicked", key);
    setSubMenuVisible([]);
    // if(subMenuVisible.includes(key)){
    //   setSubMenuVisible(subMenuVisible.filter(item => item !== key));
    //
    // } else {
    //   setSubMenuVisible([...subMenuVisible, key]);
    //
    // }
  };

  const handleTitleClick = (key) => {
    console.log("clicked", key);
    if (subMenuVisible.includes(key)) {
      setSubMenuVisible(subMenuVisible.filter(item => item !== key));
    } else {
      setSubMenuVisible([...subMenuVisible, key]);
    }
  };

  const renderNavLink = (link) => {
    if (authSelectors.moduleEnabled(account, link.key)) {
      const url = `/dashboard/${link.href}`;
      return (
        <Menu.Item key={link.key}>
          <Link
            to={url}>{link.text}
          </Link>
        </Menu.Item>
      );
    }
  };


  const renderSideMenu = () => {
    if (sideMenu) {
      return (
        <Menu
          className={"navigation navigation-submenu"}
          mode="inline"
          forceSubMenuRender={true}
          openKeys={[...subMenuVisible, ...submenuSelected]}
          style={{minWidth: "50vw"}}
          selectedKeys={submenuSelected}
          onClick={handleItemClick}
        >
          <SubMenu
            key={"submenu"}
            onTitleClick={toggleSubmenuVisible}

            style={{marginLeft: "auto"}}
            title={<Flex justifyContent={"flex-end"} flexWrap={"wrap"}>{sideMenu.label}</Flex>}
          >
            {sideMenu.menu.map(item => renderSubMenuItem(item, handleTitleClick))}
          </SubMenu>
        </Menu>
      );
    }
    return null;
  };

  if (isMobile) {
    return (
      <div className={"nav-wrapper"}>
        <Flex
          className={"navigation"}
          flexDirection={"row"}
          alignItems="center"
          // flexWrap={'wrap'}
          justifyContent={"space-between"}
          height={"48px"}

          bg={"#f7f7f7"}
        >
          <Button
            type={"link"}
            className={"mobile-menu-button"}
            onClick={() => setVisible(true)}
          >
            <MenuIcon/>
          </Button>

          {renderSideMenu()}
          <Drawer
            title={<MainMenu account={account} onLogout={resetStore} onClick={toggleVisible}/>}
            placement="left"
            width={"100vw"}
            closable={true}
            onClose={() => setVisible(false)}
            visible={visible}
            zIndex={2000}

          >
            <Menu
              mode="inline"
              selectedKeys={[activeKey]}
              // defaultSelectedKeys={["1"]}
              onClick={toggleVisible}
              defaultOpenKeys={["sub1"]}
              style={{height: "100%", borderRight: 0}}
            >
              {MOBILE_NAV_LINKS.map((link, i) => (
                renderNavLink(link)
              ))}

            </Menu>
          </Drawer>
        </Flex>
      </div>
    );
  }

  return (
    <Menu
      className={"regularMenu navigation"}
      mode="horizontal"
      selectedKeys={[activeKey]}
      style={{
        zIndex: [10, 2],
        lineHeight: "64px",
        display: "flex",
        justifyContent: isMobile ? "flex-start" : "space-evenly",
        width: "100%",

      }}
    >
      {
        NAV_LINKS.map((link, i) => (
          renderNavLink(link)
        ))
      }
    </Menu>
  );
};

RegularNav.propTypes = {
  active: PropTypes.string,
  // navLinks: PropTypes.array.isRequired
};

export default withRouter(RegularNav);
