import {createSelector} from "reselect";


const initialState = {
  byId: {},
  ids: [],
  forSubmit: {},
};

const selectItem = (item) => ({type: "SELECT", payload: item});

const deselectItem = (id) => ({type: "DESELECT", payload: id});

const editItem = (values) => ({type: "EDIT_QUANTITY", payload: values});

const reset = () => ({type: "RESET"});

const actions = {
  selectItem,
  deselectItem,
  editItem,
  reset
};

const getItemsById = state => state.byId;

const getItemsIds = state => state.ids;

const getItems = createSelector(
  [getItemsById, getItemsIds],
  (pById, pIds) => pIds.map(o => pById[o]),
);

const selectProductReducer = (state, action) => {
  const entry = {}; // OUTSIDE BECAUSE OF LINTER

  const submitEntry = {};
  let newIds = state.ids;

  switch (action.type) {
  case "SELECT": {
    const details = action.payload;
    entry[details.id] = details;
    if (!state.ids.includes(details.id)) {
      newIds = [
        ...state.ids,
        action.payload.id,
      ];
    }
    submitEntry[details.id] = {
      product: details.id,
      quantity: 0,
    };

    return {
      ...state,
      byId: {
        ...state.byId,
        ...entry,
      },
      ids: newIds,
      forSubmit: {
        ...state.forSubmit,
        ...submitEntry,
      },
    };
  }
  case "DESELECT": {
    const newByIds = state.byId;
    delete newByIds[action.payload];
    newIds = newIds.filter(id => id !== action.payload);
    return {
      byId: newByIds,
      ids: newIds,
    };
  }
  case "EDIT_QUANTITY": {
    const details = action.payload;

    submitEntry[details.id] = {
      product: details.id,
      quantity: details.quantity,
    };
    return {
      ...state,
      forSubmit: {
        ...state.forSubmit,
        ...submitEntry,
      },
    };
  }
  case "RESET": {
    return {
      ...initialState,
    };
  }
  default:
    throw new Error();
  }
};


const selectMaterialReducer = (state, action) => {
  const entry = {}; // OUTSIDE BECAUSE OF LINTER

  const submitEntry = {};
  let newIds = state.ids;

  switch (action.type) {
  case "SELECT": {
    console.log("select action", action);

    const details = action.payload;
    entry[details.id] = details;
    if (!state.ids.includes(details.id)) {
      newIds = [
        ...state.ids,
        action.payload.id,
      ];
    }
    submitEntry[details.id] = {
      material: details.id,
      quantity: 0,
    };

    return {
      ...state,
      byId: {
        ...state.byId,
        ...entry,
      },
      ids: newIds,
      forSubmit: {
        ...state.forSubmit,
        ...submitEntry,
      },
    };
  }
  case "DESELECT": {
    const newByIds = state.byId;
    delete newByIds[action.payload];
    newIds = newIds.filter(id => id !== action.payload);
    return {
      byId: newByIds,
      ids: newIds,
    };
  }
  case "EDIT_QUANTITY": {
    const details = action.payload;
    console.log("edit action", action);
    submitEntry[details.id] = {
      material: details.id,
      quantity: details.quantity,
    };
    return {
      ...state,
      forSubmit: {
        ...state.forSubmit,
        ...submitEntry,
      },
    };
  }
  case "RESET": {
    return {
      ...initialState,
    };
  }
  default:
    throw new Error();
  }
};


export {selectProductReducer, selectMaterialReducer, getItems, initialState, actions};
