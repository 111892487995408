import styled from "@emotion/styled";
import {Box, Button, Flex, Heading} from "@chakra-ui/core";
import {right, space, top} from "styled-system";

export const Span = styled(Box)`
  display: inline-block;
  ${top};
  ${right};
  ${space};
`;

export const CloseWrapper = styled(Box)`
  position: absolute;

  cursor: pointer;
  ${top};
  ${right};
  ${space};
`;

export const InlineButton = styled(Button)`
  display: inline-block !important;
  margin-right: 15px !important;
  ${top};
  ${right};
  ${space};
`;

export const FlexCenter = styled(Flex)`
  align-items: center !important;
  justify-content: center !important;
`;

export const H2 = styled(Heading)`
  //font-size: 1.7em !important;
  text-transform: uppercase;
  //font-weight: normal;
`;

H2.defaultProps = {
  color: "colorPrimary",
  as: "h2",
  fontSize: "17px",
  py: 3
  // FontWeight: 'semibold'
};
