import moment from "moment-timezone";
import {paginationSelectors} from "../index";


const combineDateFormat = "YYYY-MM-DD HH:mm:ss";

const getOrderName = (order) => {
  let name = "";
  if (order.location) {
    name = order.location[order.location.level].name;
  } else {
    name = order?.stockroom_asset?.name ?? "";
    if (name === "") {
      name = order?.asset?.name ?? "";
    }
  }
  return name;
};

/**
 * Takes scheduledPMOrder redux state and returns list formatted for calendar
 * @param {object} state -scheduledPMOrder redux state
 * @returns {[]} - list of calendar items
 */
const refreshSchedulePmOrderList = (state) => {
  const allItems = paginationSelectors.getItems(state);
  if (allItems) {
    let items = [];
    allItems.forEach(it => {
      if (it.date) {
        let name = getOrderName(it);
        let start = moment(it.date);
        let end = moment(it.date);
        if (it.start_time && it.end_time) {
          start = moment(`${it.date} ${it.start_time}`, combineDateFormat);
          end = moment(`${it.date} ${it.end_time}`, combineDateFormat);
        }
        items.push({
          "title": name || "Undefined",
          "id": it.id,
          "start": start.toDate(),
          "end": end.toDate()
        });
      }
    });
    return items;
  }
  return [];
};

export default {
  refreshSchedulePmOrderList
};
