import actions from "./actions";
import {baseOperations} from "../../base";
import {orgOperations} from "../../orgs";
import {dateUtils, snackUtils} from "../../../utils";

const getItem = (id) => async dispatch => {
  const res = await dispatch(actions.getItem(id));

  return res.payload;
};

const getAllItems = (filter = "") => async dispatch => {
  const res = await dispatch(actions.getAllItems(filter));

  return res.payload;
};

const createItem = data => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Creating Purchase Order", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.createItem(data));
  baseOperations.handleCreateResponse(res, timestamp + 1, "Purchase Order type", res.payload.sku, dispatch, () => {
  });
  return res.payload;
};

const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Purchase Order", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Purchase Order", res.payload.sku, dispatch, () => {
  });
  return res.payload;
};

const updateItemFile = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Purchase Order", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItemFile(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Purchase Order", res.payload.sku, dispatch, () => {
  });
  return res.payload;
};

const updateBol = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Purchase Order", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateBol(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Purchase Order", "", dispatch, () => {
  });
  return res.payload;
};

const addQuantity = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Adding Purchase Order Quantity", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.addQuantity(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Purchase Order", res.payload.name, dispatch, () => {
    dispatch(orgOperations.getRecentLogs());
  });
  // TODO include product data in return data from API so that we can simply use the update reducer and not send another get request
  if (!res.error) {
    dispatch(actions.getItem(id));
  }
  return res.payload;
};

const addMaterial = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Adding Purchase Order Material", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.addMaterial(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Purchase Order", res.payload.name, dispatch, () => {
    dispatch(orgOperations.getRecentLogs());
  });
  // TODO include product data in return data from API so that we can simply use the update reducer and not send another get request
  if (!res.error) {
    dispatch(actions.getItem(id));
  }
  return res.payload;
};

const updateQuantity = (id, itemID, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Adding Purchase Order Quantity", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateQuantity(id, itemID, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Purchase Order", res.payload.name, dispatch, () => {
    dispatch(orgOperations.getRecentLogs());
  });
  // TODO include product data in return data from API so that we can simply use the update reducer and not send another get request
  if (!res.error) {
    dispatch(actions.getItem(id));
  }
  return res.payload;
};

const updateMaterial = (id, itemID, data) => async dispatch => {
  console.log("updating materail", id, data);
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Adding Purchase Order Material Quantity", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateMaterial(id, itemID, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Purchase Order", res.payload.name, dispatch, () => {
    dispatch(orgOperations.getRecentLogs());
  });
  // TODO include product data in return data from API so that we can simply use the update reducer and not send another get request
  if (!res.error) {
    dispatch(actions.getItem(id));
  }
  return res.payload;
};
const deleteItem = id => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Deleting PurchaseOrder", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.deleteItem(id));
  console.log(res);
  return res;
};

const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateItem(id, fieldData));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "PurchaseOrder", res.payload.name, dispatch, () => {
    dispatch(getAllItems());
  });
};

const addNote = (id, data) => async dispatch => {
  const res = await dispatch(actions.submitNote(id, data));
  baseOperations.handleSubmitNoteResponse(res, dispatch);
  return res.payload;
};

const uploadCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch, getAllItems);
};

export default {
  getItem,
  getAllItems,
  createItem,
  updateItem,
  updateBol,
  addQuantity,
  addMaterial,
  updateQuantity,
  updateMaterial,
  deleteItem,
  updateItemField,
  updateItemFile,
  addNote,
  uploadCsv,
};
