import React from "react";
import PropTypes from "prop-types";
import voca from "voca";

import {Bottom, SeeMoreButton, SideList, Wrapper} from "../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../Common/Bars/BarComponents/constants";
import {Details} from "./Details";


const LocationBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const baseClassName = listView ? listStyle : tileStyle;
  const level = data?.level ?? "None";
  const leftList = [
    {title: "NAME", value: data?.[level]?.name ?? "None"},
    {title: "LEVEL", value: voca.capitalize(data?.level ?? "None")},
    {title: "PARENT LOCATION", value: (data.parent_level && data[data.parent_level].name) || "None"},
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="locations"
      itemData={data}
      detailsPane={<Details baseClassName={baseClassName} itemData={data}/>}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
    </Wrapper>
  );
};


LocationBar.propTypes = {
  /**
   * Location Instance Data
   */
  data: PropTypes.object,
  /**
   * Pass true to display info in list format
   */
  listView: PropTypes.bool,
  /**
   * Callback handler for when delete checkbox is checked
   */
  onCheckChange: PropTypes.func,
  /**
   * Pass true to allow deletion in list view
   */
  showDelete: PropTypes.bool,
  /**
   * Array of selected instanced
   */
  selectedRowsIds: PropTypes.array,
};

export default LocationBar;
