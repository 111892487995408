import React from "react";
import PropTypes from "prop-types";
import {numericalFieldHandler} from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import {Input, InputWrapper, Label, Row} from "../../../styles/styled";

const RepeatHourly = ({
  id,
  hourly: {
    interval,
  },
  handleChange,
  translations
}) => (
  <Row className="form-group">
    <Label width={1 / 8} textAlign={"left"}>
      {translateLabel(translations, "repeat.hourly.every")}
    </Label>
    <InputWrapper width={1 / 2}>
      <Input
        id={`${id}-interval`}
        name="repeat.hourly.interval"
        aria-label="Repeat hourly interval"
        className="form-control"
        value={interval}
        onChange={numericalFieldHandler(handleChange)}
      />
    </InputWrapper>
    <Label width={1 / 8}>
      {translateLabel(translations, "repeat.hourly.hours")}
    </Label>
  </Row>
);
RepeatHourly.propTypes = {
  id: PropTypes.string.isRequired,
  hourly: PropTypes.shape({
    interval: PropTypes.number.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RepeatHourly;
