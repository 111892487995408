import React from "react";
import {Box, Flex} from "../../Base";
import moment from "moment-timezone";
import UserPlaceholder from "../../../../static/img/user_placeholder.jpg";
import {LogImage, LogLink} from "./styled";
import {Span} from "../../Base/styled";

const Log = props => {
  const {logInfo} = props;

  let linkLocation = "/#/dashboard/";
  if (logInfo.module === "Assets") {
    linkLocation += "assets";
  } else if (logInfo.module === "Orgs") {
    linkLocation += "orgs";
  } else if (logInfo.module === "Personnel") {
    linkLocation += "personnel";
  } else if (logInfo.module === "PMOrders") {
    linkLocation += "orders";
  } else if (logInfo.module === "ScheduledPMOrders") {
    linkLocation += "orders/scheduled";
  } else if (logInfo.module === "StockroomAssets") {
    linkLocation += "stockroom_asset";
  } else if (logInfo.module === "VendorOrders") {
    linkLocation += "vendors";
  }

  const deletedAction = logInfo.action_taken === "deleted";
  const defaultName = logInfo.action_taken === "created" ? "a new document" : "a document";

  return (
    <Box fontSize={"0.7rem"}>
      <LogLink
        to={!deletedAction ? `${linkLocation}/${logInfo.document_id}` : null}
        cursor={!deletedAction ? "pointer" : "default"}>
        <Flex>
          <LogImage
            src={(logInfo.personnel && logInfo.personnel.display_image) || UserPlaceholder}
            alt="Conmitto user avatar"
          />
          <Box>
            {logInfo.personnel.name} {logInfo.action_taken}{" "}
            <Span fontWeight="bold">{logInfo.document_name || defaultName}</Span>
            <Box color="grey">{logInfo.module} - {moment(logInfo.created_at).fromNow()}</Box>
          </Box>
        </Flex>
      </LogLink>
    </Box>
  );
};

export default Log;
