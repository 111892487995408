import moment from "moment";

/**
 * Utility function to format the ASSETS table data
 * @param {Array} data The list of data to parse
 * @returns {Array} The list of parsed items
 */
export const assetTableData = (data) => {
  return data.map(item => (
    {
      key: item.id,
      name: item.name || "\xa0",
      manufacturer_name: item.manufacturer_name || "\xa0",
      tags: item.tags || "\xa0",
      asset_type: item.type,
      number_assets: item.stockroom_assets.length,
      created_at: moment(item.created_at).format("LLL"),
      updated_at: moment(item.updated_at).format("LLL")
    }
  )
  );
};
