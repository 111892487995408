import React from "react";
import PropTypes from "prop-types";
import {FormField, PositiveNumberField} from "../../../Common/Forms/Base";

import {AdaptedDatePicker, AdaptedInput} from "../../../Common/Base/Inputs/AdaptedInputs";
import {parseDate} from "../../../Common/Forms/Base/utils";
import moment from "moment-timezone";
import {Form} from "react-final-form";
import {Box} from "../../../Common/Base";
import {SubmitButton} from "../../../Common/Forms/styled";
import {ShippingDimensions} from "../../../Common/Forms";
import {Flex} from "rebass";
import {DEFAULT_CONTAINER_DIMENSIONS} from "../../../../utils/constants";

const ContainerPackingForm = props => {
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    id,
  } = props;

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={values}
      render={({handleSubmit, submitting}) => (
        <form
          id={id}
          className={"pack-container-form "}
          onSubmit={handleSubmit}>

          <Flex flexDirection={["column", "row"]}>

            <ShippingDimensions editAccess={editAccess}/>

          </Flex>
          <Flex flexDirection={["column", "row"]}>
            <PositiveNumberField
              component={AdaptedInput}
              label={"Max Weight"}
              name={"max_weight"}
              isDisabled={!editAccess}
              size={"large"}
              precision={2}
            />
            <PositiveNumberField
              component={AdaptedInput}
              label={"Max number of containers"}
              name={"max_containers"}
              isDisabled={!editAccess}
              size={"large"}
              precision={2}
            />
          </Flex>
          <FormField
            label={"Expected Date"}
            placeholder={"Expected Date"}
            type="text"
            name="expected_date"
            component={AdaptedDatePicker}
            parse={parseDate}
            format={(value, name) => value ? moment(value).format("YYYY-MM-DD") : null}
          />

          <Box py={3} mb={3}>
            <SubmitButton
              htmlType="submit"
              id="pack-container-form-submit-btn"
              loading={submitting}
            >
              Pack
            </SubmitButton>
          </Box>
        </form>
      )}/>
  );
};

ContainerPackingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  values: PropTypes.object,
  editAccess: PropTypes.bool
};

ContainerPackingForm.defaultProps = {
  values: DEFAULT_CONTAINER_DIMENSIONS,
  editAccess: true,
  id: "container-packing-form"
};

export default ContainerPackingForm;
