import React from "react";
import PropTypes from "prop-types";
import {Input} from "../Base/Inputs/AdaptedInput";
import {Field, Form} from "react-final-form";
import {Row} from "../styled";
import {Button} from "../../Base";
// import {SecondaryButton as Button} from "../../Base/Button/Button";
// Import {AutoSave} from "../Base";
// import FormInput from "../Base/FormInput";
// import {Button} from "../../Base";

const ScheduleForm = props => {
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    createShift,
    // AddPersonnel
  } = props;

  const handleCreateShift = (e) => {
    e.preventDefault();
    createShift();
  };
  const showScheduleOptions = Boolean(values) && Boolean(values.id);

  console.log("show options", showScheduleOptions);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={values}
      render={({
        handleSubmit,
        pristine,
        invalid,
        form,
        submitting,
        values
      }) => (
        <form className={"schedule-form"} onSubmit={handleSubmit}>

          <Field
            backgroundColor="white"
            placeholder={"Name..."}
            label={"Schedule Name"}
            name={"name"}
            isDisabled={!editAccess}
            component={Input}
            className={"input-pane"}
          />


          {showScheduleOptions ?
            <Row py={2} alignItems={"space-evenly"}>
              <Button className="form-button secondary-button submit-schedule">
                Edit Schedule
              </Button>

              <Button className={"form-button  new-shift"} onClick={handleCreateShift} type="primary" htmlType={"submit"}>
                Create Shift
              </Button>

              <Button className="form-button delete-schedule" danger>
                Delete Schedule
              </Button>

            </Row>
            :
            <Row py={2} alignItems={"center"} justifyContent={"center"}>

              <Button className={"submit-schedule"} type={"primary"}>CREATE SCHEDULE</Button>

            </Row>
          }

        </form>
      )}
    />
  );
};

ScheduleForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  editAccess: PropTypes.bool,
  createShift: PropTypes.func,
  deleteSchedule: PropTypes.func,
  addPersonnel: PropTypes.func
};

ScheduleForm.defaultProps = {
  addPersonnel: (values) => console.log("addpersonnel value", values)
};

export default ScheduleForm;
