import React from "react";
import {renderOrderInfo} from "./utils";


const RightSideList = ({data, baseClassName}) => {
  return (
    <div className={`${baseClassName}-main-right`}>
      {renderOrderInfo(data, baseClassName)}
    </div>
  );
};

export {RightSideList};
