import {createCrud} from "../base/crud";
import {locationEndpoint} from "./constants";
import {createPaginator} from "../base/paginator";
import {combineReducers} from "redux";


export const locationCrud = createCrud({
  endpoint: locationEndpoint,
  name: "location",
});

const locationActions = locationCrud.actions;
const locationOperations = locationCrud.operations;

const locationPaginator = createPaginator({
  name: "location",
  endpoint: locationEndpoint
});

const locationPageActions = locationPaginator.actions;

export default combineReducers({
  items: locationPaginator.reducer(),
  details: locationCrud.reducer(),
});


export {
  locationActions,
  locationOperations,
  locationPaginator,
  locationPageActions,
};
