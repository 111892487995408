/* eslint react/no-children-prop: 0*/
// Disabling no-children-prop for the whole file because can't just disable the next line in JSX
import React from "react";
import PropTypes from "prop-types";
import {toPairs} from "lodash-es";
import {numericalFieldHandler} from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import {Flex, Checkbox, FormLabel as Label} from "@chakra-ui/core";
import {Input, InputWrapper, Row} from "../../../styles/styled";

const RepeatWeekly = ({
  id,
  weekly: {
    interval,
    days,
    options
  },
  handleChange,
  translations
}) => {
  let daysArray = toPairs(days);
  if (options.weekStartsOnSunday) {
    daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
  }

  return (
    <div className="px-3">
      <Row className="form-group">
        <Label width={1 / 8}>
          {translateLabel(translations, "repeat.weekly.every")}
        </Label>
        <InputWrapper width={1 / 2}>
          <Input
            id={`${id}-interval`}
            name="repeat.weekly.interval"
            aria-label="Repeat weekly interval"
            className="form-control"
            value={interval}
            onChange={numericalFieldHandler(handleChange)}
          />
        </InputWrapper>
        <Label width={1 / 8}>
          {translateLabel(translations, "repeat.weekly.weeks")}
        </Label>

      </Row>

      <Row className="form-group">
        <Flex flexDirection={"row"}>
          {daysArray.map(([dayName, isDayActive]) => (
            <Checkbox
              key={dayName}
              px={2}
              type="checkbox"
              id={`${id}-${dayName}`}
              name={`repeat.weekly.days[${dayName}]`}
              className="form-control"
              isChecked={isDayActive}
              children={translateLabel(translations, `days_short.${dayName.toLowerCase()}`)}
              onChange={(event) => {
                const editedEvent = {
                  ...event,
                  target: {
                    ...event.target,
                    value: !isDayActive,
                    name: event.target.name
                  }
                };

                handleChange(editedEvent);
              }}
            />
          ))
          }
        </Flex>
      </Row>
    </div>
  );
};

RepeatWeekly.propTypes = {
  id: PropTypes.string.isRequired,
  weekly: PropTypes.shape({
    interval: PropTypes.number.isRequired,
    days: PropTypes.shape({
      mon: PropTypes.bool.isRequired,
      tue: PropTypes.bool.isRequired,
      wed: PropTypes.bool.isRequired,
      thu: PropTypes.bool.isRequired,
      fri: PropTypes.bool.isRequired,
      sat: PropTypes.bool.isRequired,
      sun: PropTypes.bool.isRequired
    }).isRequired,
    options: PropTypes.shape({
      weekStartsOnSunday: PropTypes.bool
    }).isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default RepeatWeekly;
