import React, {Component} from "react";
import {Box, Flex} from "../Base";
import {Link} from "react-router-dom";
import Select from "react-select";

/* Keystroke types used by this tag component's functions. */
const ENTER_KEY = 13;
const BACKSPACE_KEY = 8;
const COMMA_KEY = 188;

/* This handles functionality related to a tag array and various front end inputs. */
class AutocompleteTagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      value: "",
      editValue: "",
      editIndex: -1,
      autocompleteList: []
    };
  }

  /* Upon mounting, set the list of tags. */
  componentDidMount() {
    const {tagList} = this.props;
    this.setState({tags: tagList});
  }

  /* Update state for edit value. */
  handleEditChange = e => {
    this.setState({editValue: e.target.value});
  };

  /* Perform edit tag upon typing enter. */
  handleEditKeyUp = e => {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) this.editTag();
  };

  /* The primary function for editing a tag, or removing one. */
  editTag = () => {
    const {tags, editValue, editIndex} = this.state;
    const {handleUpdate} = this.props;
    let editedTag = editValue.trim();
    let newTags = [];

    editedTag = editedTag.replace(/,/g, "");

    // If tag has all elements removed, remove it from the tags array.
    // Otherwise, update array with potentially modified tag.
    if (editedTag === "") {
      let i = 0;
      while (i < tags.length) {
        if (i !== editIndex) {
          newTags.push(tags[i]);
        }
        i += 1;
      }
    } else {
      newTags = tags;
      newTags[editIndex] = editedTag;
    }

    // Apply the changes.
    handleUpdate(newTags);
    this.setState({
      tags: newTags,
      editValue: "",
      editIndex: -1
    });
  };

  /* Handle tag edit. */
  addTag = (tag) => {
    const {tags} = this.state;
    const {handleUpdate} = this.props;
    tag = {
      "id": tag.id,
      "name": tag.label
    };

    handleUpdate([...tags, tag]);
    this.setState({
      tags: [...tags, tag],
      value: ""
    });
  };

  /* Upon pressing a key, evaluate the key type. */
  handleKeyDown = (e) => {
    const key = e.keyCode;
    const {value} = this.state;

    if (key === BACKSPACE_KEY && !value) this.editPrevTag();
  };

  /* Upon pressing a key, evaluate the key type. */
  handleKeyUp = (e) => {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) this.addTag();
  };

  /* Perform a change. */
  handleChange = (data) => {
    if (data.id) {
      this.setState({value: data.id});
      this.addTag(data);
    }
  };

  /* Edit the previous tag. */
  editPrevTag = () => {
    const {tags} = this.state;
    const tag = tags.pop();

    this.setState({tags, value: tag});
  };

  render() {
    const {autocompleteList} = this.props;
    const {
      tags,
      value,
      editIndex
    } = this.state;

    return (
      <Flex flexDirection="column" className="tags">
        <Box className="tags-list">
          <ul>
            {tags.map((tag, i) => (
              <li
                className={editIndex === i ? "tag-edit" : "tag stockroom-tag"}
                key={tag.id}
                value={i}>
                <button type="button" value={i}>
                  <Link to={`/dashboard/stockroom_asset/${tag.id}`}>{tag.name}</Link>
                </button>
              </li>
            ))}
          </ul>
        </Box>
        <Box width="100%" fontSize={14} mt={"10px"}>
          <Select
            value={value}
            options={autocompleteList}
            onChange={this.handleChange}
            className="tags-input"
            placeholder="Add stockroom asset..."
          />
        </Box>
      </Flex>
    );
  }
}

export default AutocompleteTagInput;
