import actionTyper from "redux-actiontyper";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_LOGS_REQUEST,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAILURE,

  RECEIVED_LOG,

  GET_ORG_SUMMARY_REQUEST,
  GET_ORG_SUMMARY_SUCCESS,
  GET_ORG_SUMMARY_FAILURE,

  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,

  GET_ASSET_TYPES_REQUEST,
  GET_ASSET_TYPES_SUCCESS,
  GET_ASSET_TYPES_FAILURE,
  CREATE_ASSET_TYPE_REQUEST,
  CREATE_ASSET_TYPE_SUCCESS,
  CREATE_ASSET_TYPE_FAILURE,
  UPDATE_ASSET_TYPE_REQUEST,
  UPDATE_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_TYPE_FAILURE,
  DELETE_ASSET_TYPE_REQUEST,
  DELETE_ASSET_TYPE_SUCCESS,
  DELETE_ASSET_TYPE_FAILURE,

  GET_PERSONNEL_TITLES_REQUEST,
  GET_PERSONNEL_TITLES_SUCCESS,
  GET_PERSONNEL_TITLES_FAILURE,
  CREATE_PERSONNEL_TITLE_REQUEST,
  CREATE_PERSONNEL_TITLE_SUCCESS,
  CREATE_PERSONNEL_TITLE_FAILURE,
  UPDATE_PERSONNEL_TITLE_REQUEST,
  UPDATE_PERSONNEL_TITLE_SUCCESS,
  UPDATE_PERSONNEL_TITLE_FAILURE,
  DELETE_PERSONNEL_TITLE_REQUEST,
  DELETE_PERSONNEL_TITLE_SUCCESS,
  DELETE_PERSONNEL_TITLE_FAILURE,

  GET_VENDOR_TYPES_REQUEST,
  GET_VENDOR_TYPES_SUCCESS,
  GET_VENDOR_TYPES_FAILURE,
  CREATE_VENDOR_TYPE_REQUEST,
  CREATE_VENDOR_TYPE_SUCCESS,
  CREATE_VENDOR_TYPE_FAILURE,
  UPDATE_VENDOR_TYPE_REQUEST,
  UPDATE_VENDOR_TYPE_SUCCESS,
  UPDATE_VENDOR_TYPE_FAILURE,
  DELETE_VENDOR_TYPE_REQUEST,
  DELETE_VENDOR_TYPE_SUCCESS,
  DELETE_VENDOR_TYPE_FAILURE,

  GET_STOCK_STATUSES_REQUEST,
  GET_STOCK_STATUSES_SUCCESS,
  GET_STOCK_STATUSES_FAILURE,
  CREATE_STOCK_STATUS_REQUEST,
  CREATE_STOCK_STATUS_SUCCESS,
  CREATE_STOCK_STATUS_FAILURE,
  UPDATE_STOCK_STATUS_REQUEST,
  UPDATE_STOCK_STATUS_SUCCESS,
  UPDATE_STOCK_STATUS_FAILURE,
  DELETE_STOCK_STATUS_REQUEST,
  DELETE_STOCK_STATUS_SUCCESS,
  DELETE_STOCK_STATUS_FAILURE,

  CREATE_ORDER_MATERIAL_REQUEST,
  CREATE_ORDER_MATERIAL_SUCCESS,
  CREATE_ORDER_MATERIAL_FAILURE,
  UPDATE_ORDER_MATERIAL_REQUEST,
  UPDATE_ORDER_MATERIAL_SUCCESS,
  UPDATE_ORDER_MATERIAL_FAILURE,
  DELETE_ORDER_MATERIAL_REQUEST,
  DELETE_ORDER_MATERIAL_SUCCESS,
  DELETE_ORDER_MATERIAL_FAILURE,

  GET_SCHEDULE_STATUSES_REQUEST,
  GET_SCHEDULE_STATUSES_SUCCESS,
  GET_SCHEDULE_STATUSES_FAILURE,
  CREATE_SCHEDULE_STATUS_REQUEST,
  CREATE_SCHEDULE_STATUS_SUCCESS,
  CREATE_SCHEDULE_STATUS_FAILURE,
  UPDATE_SCHEDULE_STATUS_REQUEST,
  UPDATE_SCHEDULE_STATUS_SUCCESS,
  UPDATE_SCHEDULE_STATUS_FAILURE,
  DELETE_SCHEDULE_STATUS_REQUEST,
  DELETE_SCHEDULE_STATUS_SUCCESS,
  DELETE_SCHEDULE_STATUS_FAILURE,

  UPDATE_SERVICE_PROVIDER_REQUEST,
  UPDATE_SERVICE_PROVIDER_SUCCESS,
  UPDATE_SERVICE_PROVIDER_FAILURE,

  CHANGE_SUMMARY_TIMESCALE
} = actionTyper("@@orgs/");

export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_LOGS_REQUEST,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAILURE,

  RECEIVED_LOG,

  GET_ORG_SUMMARY_REQUEST,
  GET_ORG_SUMMARY_SUCCESS,
  GET_ORG_SUMMARY_FAILURE,

  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,

  GET_ASSET_TYPES_REQUEST,
  GET_ASSET_TYPES_SUCCESS,
  GET_ASSET_TYPES_FAILURE,
  CREATE_ASSET_TYPE_REQUEST,
  CREATE_ASSET_TYPE_SUCCESS,
  CREATE_ASSET_TYPE_FAILURE,
  UPDATE_ASSET_TYPE_REQUEST,
  UPDATE_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_TYPE_FAILURE,
  DELETE_ASSET_TYPE_REQUEST,
  DELETE_ASSET_TYPE_SUCCESS,
  DELETE_ASSET_TYPE_FAILURE,

  GET_PERSONNEL_TITLES_REQUEST,
  GET_PERSONNEL_TITLES_SUCCESS,
  GET_PERSONNEL_TITLES_FAILURE,
  CREATE_PERSONNEL_TITLE_REQUEST,
  CREATE_PERSONNEL_TITLE_SUCCESS,
  CREATE_PERSONNEL_TITLE_FAILURE,
  UPDATE_PERSONNEL_TITLE_REQUEST,
  UPDATE_PERSONNEL_TITLE_SUCCESS,
  UPDATE_PERSONNEL_TITLE_FAILURE,
  DELETE_PERSONNEL_TITLE_REQUEST,
  DELETE_PERSONNEL_TITLE_SUCCESS,
  DELETE_PERSONNEL_TITLE_FAILURE,

  GET_VENDOR_TYPES_REQUEST,
  GET_VENDOR_TYPES_SUCCESS,
  GET_VENDOR_TYPES_FAILURE,
  CREATE_VENDOR_TYPE_REQUEST,
  CREATE_VENDOR_TYPE_SUCCESS,
  CREATE_VENDOR_TYPE_FAILURE,
  UPDATE_VENDOR_TYPE_REQUEST,
  UPDATE_VENDOR_TYPE_SUCCESS,
  UPDATE_VENDOR_TYPE_FAILURE,
  DELETE_VENDOR_TYPE_REQUEST,
  DELETE_VENDOR_TYPE_SUCCESS,
  DELETE_VENDOR_TYPE_FAILURE,

  GET_STOCK_STATUSES_REQUEST,
  GET_STOCK_STATUSES_SUCCESS,
  GET_STOCK_STATUSES_FAILURE,
  CREATE_STOCK_STATUS_REQUEST,
  CREATE_STOCK_STATUS_SUCCESS,
  CREATE_STOCK_STATUS_FAILURE,
  UPDATE_STOCK_STATUS_REQUEST,
  UPDATE_STOCK_STATUS_SUCCESS,
  UPDATE_STOCK_STATUS_FAILURE,
  DELETE_STOCK_STATUS_REQUEST,
  DELETE_STOCK_STATUS_SUCCESS,
  DELETE_STOCK_STATUS_FAILURE,

  CREATE_ORDER_MATERIAL_REQUEST,
  CREATE_ORDER_MATERIAL_SUCCESS,
  CREATE_ORDER_MATERIAL_FAILURE,
  UPDATE_ORDER_MATERIAL_REQUEST,
  UPDATE_ORDER_MATERIAL_SUCCESS,
  UPDATE_ORDER_MATERIAL_FAILURE,
  DELETE_ORDER_MATERIAL_REQUEST,
  DELETE_ORDER_MATERIAL_SUCCESS,
  DELETE_ORDER_MATERIAL_FAILURE,

  GET_SCHEDULE_STATUSES_REQUEST,
  GET_SCHEDULE_STATUSES_SUCCESS,
  GET_SCHEDULE_STATUSES_FAILURE,
  CREATE_SCHEDULE_STATUS_REQUEST,
  CREATE_SCHEDULE_STATUS_SUCCESS,
  CREATE_SCHEDULE_STATUS_FAILURE,
  UPDATE_SCHEDULE_STATUS_REQUEST,
  UPDATE_SCHEDULE_STATUS_SUCCESS,
  UPDATE_SCHEDULE_STATUS_FAILURE,
  DELETE_SCHEDULE_STATUS_REQUEST,
  DELETE_SCHEDULE_STATUS_SUCCESS,
  DELETE_SCHEDULE_STATUS_FAILURE,

  UPDATE_SERVICE_PROVIDER_REQUEST,
  UPDATE_SERVICE_PROVIDER_SUCCESS,
  UPDATE_SERVICE_PROVIDER_FAILURE,

  CHANGE_SUMMARY_TIMESCALE
};
