import {customerAddressActions, customerOperations, customerActions} from "./contacts";

import {addressActions as supplierAddressActions, supplierPageActions, supplierActions, supplierOperations} from "./contacts/suppliers";

import {paginationActions as productPaginationActions} from "./products/pagination";

export {default as productTypes} from "./products/types";
export {default as productSelectors} from "./products/selectors";
export {default as productOperations} from "./products/operations";
export {default as productActions} from "./products/actions";


export {
  productPaginationActions,
  // customers
  customerAddressActions, customerOperations, customerActions,
  // suppliers
  supplierAddressActions, supplierPageActions, supplierActions,
  supplierOperations

};
