import React, {Component} from "react";
import {connect} from "react-redux";
import {
  authSelectors,
  baseSelectors,
  inventoryPageActions,
  orgOperations,
  orgSelectors,
  paginationSelectors,
  palletActions,
  palletOperations,
  productPaginationActions,
  warehouseLocationPageActions,
  warehouseLocationOperations
} from "../../../store";
import {PalletDetails} from "../../../components/Warehouse";
import {checkNestedKeys} from "../../../utils/propUtils";

/* This is where Redux state is mapped to this component. */
const mapStateToProps = (state, ownProps) => ({
  palletDetails: baseSelectors.returnItemWithID(
    state.pallets,
    ownProps.match.params.palletID,
  ),
  productPagination: paginationSelectors.pagination(state.warehouse.products.items),
  locationList: orgSelectors.refreshLocations(state.orgs),
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  inventoryList: paginationSelectors.getItemsPaged(state.warehouse.products.inventory),
  inventoryPagination: paginationSelectors.pagination(state.warehouse.products.inventory),
  warehouseLocationPagination: paginationSelectors.pagination(state.warehouse.locations.items)
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = (dispatch, ownProps) => ({
  getProductList: (page = 1, params = {}) => dispatch(productPaginationActions.fetchPage({page, params})),
  getInventory: (page = 1, params = {}) => dispatch(inventoryPageActions.fetchPage({page, params})),
  getWarehouseLocations: (page = 1, params = {}) => dispatch(warehouseLocationPageActions.fetchPage({page, params})),
  getPalletDetails: (id) => dispatch(palletOperations.getItem(id)),
  getAllPalletDetails: () => dispatch(palletOperations.getAllItems()),
  updatePallet: (id, data) => dispatch(palletOperations.updateItem(id, data)),
  deletePallet: (id) => dispatch(palletOperations.deleteItem(id)),
  updateItemField: (id, fieldData) => dispatch(palletOperations.updateItemField(id, fieldData)),
  addNote: (id, data) => dispatch(palletOperations.addNote),
  uploadCsv: (data, org) => dispatch(palletOperations.uploadCsv(data, org)),
  getLocations: (id) => dispatch(orgOperations.getLocations(id)),
  addInventory: (data) => dispatch(palletOperations.addInventory(ownProps.match.params.palletID
    , data, true)),
  receivePallet: (data) => {
    console.log("receive pallet data", data);
    return dispatch(palletOperations.receivePallet(data));
  },
  pickupPallet: () => dispatch(palletOperations.pickupPallet(ownProps.match.params.palletID)),
  createWarehouseLocation: (data) => dispatch(warehouseLocationOperations.createItem(data)),
  generateBarcode: (objId, orgId, barcodeType) =>
    dispatch(palletActions.generateBarcode({objId, orgId, barcodeType})),
});

class PalletDetailsContainer extends Component {
  state = {
    itemNotFound: false,
    loading: false,
  };

  componentDidMount() {
    // this.fetchData();
    const {
      palletDetails,
      match: {params},
    } = this.props;
    const {palletID} = params;

    console.log("palletID", palletID);
    console.log("details", palletDetails);
    if (!palletDetails || (palletID !== palletDetails.id)) {
      this.setState({loading: true});
    }

    this.fetchData();
  }

  fetchData = async () => {
    const {
      getProductList,
      getPalletDetails,
      getLocations,
      accountInfo,
      match,
    } = this.props;
    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);
    getProductList();
    const details = await getPalletDetails(match.params.palletID);
    if (details) {
      console.log("detials", details.message);
      if (!details.id) {
        if (details.message === "404 - Not Found") {
          this.setState({itemNotFound: true});
        }
      }
      await getLocations(orgId);
      this.setState({loading: false});
      if (details.location) {
        this.fetchInventory(details.location.id);
      }
    }
    return details;
  };

  fetchInventory = async (location, page = 1) => {
    const {
      getInventory,
    } = this.props;
    let inventoryRes = await getInventory(page, {location: location, page_size: 3});
    console.log("res", inventoryRes);
    return inventoryRes;
  };


  render() {
    const {loading, itemNotFound} = this.state;
    return (
      <PalletDetails
        loading={loading}
        itemNotFound={itemNotFound}
        fetchinventory={this.fetchInventory}
        fetchData={this.fetchData}
        {...this.props}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PalletDetailsContainer);
