import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";


/*
FormatForm is a wrapper for a 🏁 React Final Form component.
On mount, it loads a record from the database/API, and formats it to the
shape of the form inputs.
 */
class FormatForm extends React.Component {
  static propTypes = {
    load: PropTypes.func.isRequired,
    loading: PropTypes.node.isRequired,
    postLoadFormat: PropTypes.func,
    preSaveFormat: PropTypes.func,
    save: PropTypes.func.isRequired
  }

  state = {
    isLoading: false,
    originalValues: undefined,
    initialValues: undefined
  }

  load = () => {
    const {values, postLoadFormat} = this.props;
    // This.setState({isLoading: true})
    const originalValues = values;
    const initialValues = postLoadFormat
      ? postLoadFormat(originalValues)
      : originalValues;
    this.setState({
      isLoading: false,
      originalValues,
      initialValues
    });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      postLoadFormat,
      ...rest
    } = this.props;
    const {initialValues} = this.state;
    return (
      <Form {...rest} initialValues={initialValues}/>
    );
  }
}

FormatForm.propTypes = {
  postLoadFormat: PropTypes.func,
  values: PropTypes.object
};

FormatForm.defaultProps = {
  initialValues: {}
};

export default FormatForm;
