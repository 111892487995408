import React, {useState} from "react";
import PropTypes from "prop-types";
import {FlexCenter, Heading, Link, Text} from "Base";
import PasswordResetForm from "../Forms/PasswordResetForm";
import CompanyLogo from "../../../static/img/conmitto_logo_color.png";

const RequestPasswordReset = props => {
  const [requestSent, setRequestSent] = useState(false);
  const {onSubmit} = props;

  const handleSubmit = async (values) => {
    await onSubmit(values);
    setRequestSent(true);
  };

  return (
    <FlexCenter className="login" flexDirection="column">

      <div className={"login-card"}>
        <img
          src={CompanyLogo}
          className="login-card-logo"
          alt="Conmitto logo"
        />
        <Heading className="login-card-header-text">
          REQUEST PASSWORD RESET
        </Heading>
        {requestSent ?
          <Text color={"white"}>
          If an account exists with that email, password reset email will be sent shortly.
          </Text>
          :
          <PasswordResetForm onSubmit={handleSubmit}/>
        }
      </div>
      <FlexCenter>
        <Link to={"/"}>
        Back to login
        </Link>
      </FlexCenter>
    </FlexCenter>
  );
};

RequestPasswordReset.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RequestPasswordReset;
