function refreshNameList(state) {
  if (state.items) {
    const {items} = state;
    const list = [];
    for (let i = 0; i < items.length; i += 1) {
      let stocks = items[i].stockroom_assets ? items[i].stockroom_assets.map(stock => {
        return {
          id: stock.id,
          value: stock.id,
          label: stock.name || "Unnamed"
        };
      }) : [];
      list.push({
        id: items[i].id,
        value: items[i].id,
        label: items[i].name || "Name",
        stocks: stocks
      });
    }
    return list;
  }
  return [];
}

const refreshPersonnelForAsset = (state, id) => {
  if (state.personnel && state.personnel[id]) {
    return state.personnel[id];
  }
  return [];
};

const refreshPersonnel = (state) => {
  if (state.personnel) {
    return state.personnel;
  }
  return {};
};

/* Returns boolean value of filteringBy state. */
const filteringBy = state => state.filteringBy;

const filteringByType = state => state.filteringByType;

const types = state => state.types;

export default {
  refreshNameList,
  refreshPersonnel,
  refreshPersonnelForAsset,
  filteringBy,
  filteringByType,
  types
};
