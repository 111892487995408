import React from "react";
import PropTypes from "prop-types";
import {List} from "Base";
import {paginationProps} from "../../../../types/propTypes";

const PaginatedList = (props) => {
  const {
    pagination,
    fetchPage,
    renderItem,
    pageSize,
    paginationConfig,
    ...rest
  } = props;

  const {
    itemsPaged,
    itemsCount,
    loading
  } = pagination;

  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: page => {
          !loading && fetchPage(page);
        },
        total: itemsCount,
        showTotal: (total) => <span>Total <span className="pagination-total">{total}</span> items</span>,
        pageSize: pageSize,
        position: "top",
        ...paginationConfig
      }}
      loading={loading}
      dataSource={itemsPaged}
      renderItem={renderItem}
      {...rest}
    />
  );
};

PaginatedList.propTypes = {
  pagination: paginationProps.isRequired,
  // function that accepts a single param for page number,
  // add extra params needed in the component your using this in
  fetchPage: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  // override default pagination settings
  // https://ant.design/components/pagination/#API
  paginationConfig: PropTypes.object
};

PaginatedList.defaultProps = {
  pageSize: 12,
  paginationConfig: {}
};

export default PaginatedList;
