
const ALL = "all";
const REGION = "region";
const DISTRICT = "district";
const FACILITY = "facility";
const WING = "wing";
const ZONE = "zone";
const SUBZONE = "subzone";

const LOCATION_LEVELS = {
  ALL,
  REGION,
  DISTRICT,
  FACILITY,
  WING,
  ZONE,
  SUBZONE
};

export {
  ALL,
  REGION,
  DISTRICT,
  FACILITY,
  WING,
  ZONE,
  SUBZONE,
  LOCATION_LEVELS
};
export default LOCATION_LEVELS;
