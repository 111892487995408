import React, {Fragment} from "react";
import moment from "moment";
import {Heading} from "../../components/Common/Base";

/* This stateless functional component will have all notes for a given asset
passed in and displayed for the user. */
const VendorOrderHistory = ({history}) => {
  return (
    <Fragment>
      <br/>
      <br/>
      <Heading color={"colorPrimary"}>Vendor Order History</Heading>
      {history && history.length > 0
        ? history.map((vendor, index) => (
          <a key={index} href={`/#/dashboard/vendors/${vendor.id}`}>
            <div className="item-details-pane-right-note">
              <h3>{vendor.title ? vendor.title : `Vendor Order #${vendor.id}`}</h3>

              <p><strong>Status:</strong> {vendor.status}</p>
              <p><strong>Date:</strong> {vendor.date ? moment(vendor.date).format("MMMM DD, YYYY") : "Not Scheduled"}
              </p>
            </div>
          </a>
        ))
        : <p className="item-details-alert">No vendor order history available.</p>
      }
    </Fragment>
  );
};

export default VendorOrderHistory;
