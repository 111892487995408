import types from "./types";
import {name as endpoint} from "../scheduledPMOrders";
import base from "../base/actions";

/* Standard fetch actions. */
const getAllSuccess = data => ({
  type: types.GET_ALL_SUCCESS,
  payload: data
});

const getAllFailure = error => ({
  type: types.GET_ALL_FAILURE,
  payload: error
});

const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/?expand=location`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});

const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify({...data}),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

/* SimplePagination actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

export default {
  getAllSuccess,
  getAllFailure,
  updateItem,

  getItemNotes,
  submitNote,

  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  getItem
};
