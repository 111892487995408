import React, {useCallback, useEffect, useState} from "react";
import {Box, Heading} from "Base";
import DetailsHeader from "./DetailsHeader";
import {LocationSection} from "../../../Common";
import {SelectInventoryDrawer} from "../../Drawers";

import {DetailsContainer} from "../../../Common/Details";
import {formatInventoryForSalesOrder, formatMaterialsForSalesOrder, formatInventoryForSalesOrderPallet} from "../../utils/inventory";
import {OrderMaterialTable, OrderProductTable} from "../../Table";
import {getOrderTableMaterials, getOrderTableProducts} from "../../utils/tables";

import {MATERIAL_INVENTORY, PRODUCT_INVENTORY, SALES_ORDER} from "../../utils/constants";
import authSelectors from "store/auth/selectors";
import {OrderPalletSection} from "../../Sections";

const SalesOrderDetails = (props) => {
  const {
    accountInfo,
    fetchDetails,
    updateDetails,
    deleteOrder,
    getLocations,
    getFacilities,
    getMaterials,
    orderDetails,
    locationList,
    inventoryPagination,
    materialPagination,
    customerPagination,
    getInventory,
    createOrderItem,
    createOrderMaterial,
    updateOrderItem,
    updateOrderMaterial,
    match,
    createCustomer,
    getCustomers,
    orgID,
    facilities,
    personnelList,
    createCustomerAddress,
    purchaseOrders,
    updateFiles
  } = props;

  const [orderID, setOrderID] = useState(null);

  const [loadingInventory, setLoadingInventory] = useState(false);

  const customerList = customerPagination.itemsPaged;

  const handleFetchDetails = useCallback(async () => {
    let res = await fetchDetails();
    const orgID = authSelectors.defaultOrgID(props);
    if (res.location) {
      setLoadingInventory(true);
      await getInventory(1, {location: res.location.id});
      await getMaterials(1, {location: res.location.id});
      setLoadingInventory(false);
    }
    if (!locationList || locationList.length === 0) {
      getLocations(orgID);
    }
    getFacilities(orgID);
  }, [fetchDetails, locationList, getLocations, getFacilities, props, getInventory, getMaterials]);

  useEffect(() => {
    if (match.params.orderID !== orderID) {
      setOrderID(match.params.orderID);
      handleFetchDetails();
    }
  }, [match.params.orderID, orderID, handleFetchDetails]);

  const handleEdit = async (values) => {
    const {customer} = values;
    if (customer && customer.id) values.customer = customer.id;
    const res = await updateDetails(orderDetails.id, values);
    if (!res.error) {
      await handleFetchDetails();
    }
  };

  const handleSubmitLocation = async (values) => {
    console.log("submit location values", values);
    const updateValue = {location: values.location.location_instance || null};
    handleEdit(updateValue);
  };

  const handleSubmitItem = async (values) => {
    console.log("values", values);
    const res = await createOrderItem(orderID, values);
    console.log("res", res);
  };

  const handleSubmitMaterial = async (values) => {
    console.log("values", values);
    const res = await createOrderMaterial(orderID, values);
    console.log("res", res);
  };

  // submit multiple products
  const handleSubmitItems = async (values) => {
    for (const item of values) {
      await handleSubmitItem(item);
    }
    await handleFetchDetails();
  };

  // submit multiple materials
  const handleSubmitMaterials = async (values) => {
    for (const item of values) {
      await handleSubmitMaterial(item);
    }
    await handleFetchDetails();
  };

  const handleUpdateItem = async (values) => {
    const submitData = {
      quantity: values.quantity,
      price: values.price,
    };
    let res = await updateOrderItem(orderID, values.id, submitData);
    console.log("values", res);
    await handleFetchDetails();
  };

  const handleUpdateMaterial = async (values) => {
    const submitData = {
      quantity: values.quantity,
      price: values.price,
    };
    let res = await updateOrderMaterial(orderID, values.id, submitData);
    console.log("values", res);
    await handleFetchDetails();
  };

  const handleCreateCustomer = async (values) => {
    let res = await createCustomer({...values, org: orgID});
    console.log("createCustomer", res);

    if (!res.error) {
      let customerRes = await getCustomers(1);
      console.log("getCustomers", customerRes);
      handleEdit({customer: res.payload.id});
    }
    return res;
  };

  const handlePackItems = async (values) => {
    console.log("packItem values", values);
    const {packItems} = props;
    const res = await packItems(orderDetails.id, values);
    if (!res.error) {
      await handleFetchDetails();
    }
    return res;
  };

  const handleCreatePallet = async (values) => {
    console.log("values", values);
    const {createPallet} = props;
    let res = await createPallet(values);
    console.log("SO Details create pallet res", res);
    if (!res.error) {
      await handleFetchDetails();
    }
    return res;
  };

  const renderAddSalesPallet = () => {
    const formatEdit = (item, data) => {
      const {values} = data;
      // const inventory_key = type === PRODUCT_INVENTORY ? "product_inventory" : "inventory";
      return ({id: item.id, inventory: item.inventory, quantity: values.quantity});
    };
    return (
      <OrderPalletSection
        orderDetails={orderDetails}
        facilities={facilities}
        personnelList={personnelList}
        handleCreatePallet={handleCreatePallet}
        orderType={SALES_ORDER}
        formatEdit={formatEdit}
        formatSubmit={formatInventoryForSalesOrderPallet}
        createAddress={createCustomerAddress}
        packItems={handlePackItems}
      />
    );
  };

  const {flattenedItems} = getOrderTableProducts(orderDetails);

  const {flattenedMaterials} = getOrderTableMaterials(orderDetails);

  const locationData = orderDetails && orderDetails.location ? orderDetails.location : {};

  const isAdmin = accountInfo.user_type <= 1;

  return (
    <DetailsContainer
      type={"Sales Order"}
      notFound={!orderDetails}
      loading={false}
    >
      <DetailsHeader
        data={orderDetails}
        locationData={locationData}
        onDelete={deleteOrder}
        onUpdate={handleEdit}
        updateFiles={updateFiles}
        customers={customerList}
        purchaseOrders={purchaseOrders}
        createCustomer={handleCreateCustomer}
        locationSection={
          <Box display={"inline-block"} key={"location-section"}>
            <LocationSection
              locationData={locationData}
              locationList={locationList}
              submit={handleSubmitLocation}
              isAdmin={isAdmin}
              hideCard
              buttonConfig={{
                size: "small",
                type: Object.keys(locationData).length !== 0 ? "link" : "default",
              }}
              wrapperStyle={{mt: 0}}
            />
          </Box>
        }
        actions={[
          <SelectInventoryDrawer
            key={"add-inventory"}
            loading={loadingInventory}
            locationData={locationData}
            onSubmit={handleSubmitItems}
            pagination={inventoryPagination}
            getInventory={getInventory}
            formatInventory={formatInventoryForSalesOrder}
            type={PRODUCT_INVENTORY}
            buttonText={"Add Inventory"}
          />,
          <SelectInventoryDrawer
            key={"add-materials"}
            loading={loadingInventory}
            locationData={locationData}
            onSubmit={handleSubmitMaterials}
            pagination={materialPagination}
            getInventory={getMaterials}
            formatInventory={formatMaterialsForSalesOrder}
            type={MATERIAL_INVENTORY}
            buttonText={"Add Materials"}
          />,
        ]}
      />

      {renderAddSalesPallet()}

      <Box py={2}>
        <Heading py={2}>Inventory</Heading>
        <OrderProductTable
          data={flattenedItems}
          onSubmit={handleUpdateItem}
        />
      </Box>

      <Box py={2}>
        <Heading py={2}>Materials</Heading>
        <OrderMaterialTable
          data={flattenedMaterials}
          onSubmit={handleUpdateMaterial}
        />
      </Box>
    </DetailsContainer>
  );
};

export default SalesOrderDetails;
