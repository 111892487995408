import React, {useState} from "react";
import {Field, Form} from "react-final-form";
import Error from "Common/Forms/Base/Error";
import {Box, Flex, Label, DirectLink, Button} from "Base";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {OnChange} from "react-final-form-listeners";

const minCharacters = value => (value && value.length > 7) ? undefined : "minimum of 8 Characters";

const required = value => (value ? undefined : "Required");

const customStyles = {
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    padding: 3,
  }),
};


const orgTypeOptions = [
  {value: 0, label: "Client"},
  {value: 1, label: "Service Provider"},
];

const orgDropdown = ({input, ...rest}) => (
  <CreatableSelect
    styles={customStyles}
    className="item-details-form-dropdown"
    {...input} {...rest} searchable>
  </CreatableSelect>
);

const regularDropdown = ({input, ...rest}) => (
  <Select
    styles={customStyles}
    className="item-details-form-dropdown"
    {...input} {...rest} searchable>
  </Select>
);

const FieldPrefixContext = React.createContext();
const FieldPrefix = ({prefix, children}) => (
  <FieldPrefixContext.Provider value={prefix}>
    {children}
  </FieldPrefixContext.Provider>
);
const PrefixedField = ({name, ...props}) => (
  <FieldPrefixContext.Consumer>
    {prefix => <Field name={`${prefix}.${name}`} {...props} />}
  </FieldPrefixContext.Consumer>
);

const GeneralSignUpForm = (props) => {
  const {
    onSubmit,
    validate,
    errors,
    orgList,
    signupSuccess,
    handleDirectLoginClick,
  } = props;

  const [showOrgType, setShowOrgType] = useState(true);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit, submitting, pristine}) => (
        <form onSubmit={handleSubmit}>
          <Flex
            pb={3}
            flexDirection="row"
            flexWrap={"wrap"}
          >
            <Box margin="0 auto" paddingRight={10}>
              <Field name="name" type="text"
                render={({input, meta}) => (
                  <div>
                    <input
                      required={true}
                      className="login-card-input"
                      placeholder="Name"
                      {...input}
                    />
                  </div>
                )}
              />
              <FieldPrefix prefix={"user"}>
                <PrefixedField name="email" validate={required} type="email"
                  render={({input, meta}) => (
                    <div>
                      <input
                        required={true}
                        className="login-card-input"
                        placeholder="Email"
                        {...input}
                      />
                    </div>
                  )}
                />
              </FieldPrefix>
              <FieldPrefix prefix={"title"}>
                <PrefixedField name="name" placeholder="Job Title" type="text"
                  render={({input, meta}) => (
                    <div>
                      <input
                        required={true}
                        className="login-card-input"
                        placeholder="Job Title"
                        {...input}
                      />
                    </div>
                  )}
                />
              </FieldPrefix>
              <Box margin="10px 0">
                <Field
                  name="company_name"
                  placeholder="Company Name"
                  required
                  options={orgList ? orgList.map(x => {
                    return {label: x.name, value: x.id};
                  }) : []}
                  component={orgDropdown}
                />
              </Box>
              <OnChange name="company_name">
                {(value, previous) => {
                  if (value.value !== previous.value) {
                    setShowOrgType(value && "__isNew__" in value && value["__isNew__"]);
                  }
                }}
              </OnChange>

              {showOrgType &&
              <Box mt={"-8px"}>
                <Field
                  name="org_type"
                  placeholder="Organization Type"
                  required
                  options={orgTypeOptions}
                  defaultValue={orgTypeOptions.find(x => x.label === "Client")}
                  component={regularDropdown}
                />
              </Box>
              }
            </Box>
            <Box margin="0 auto" paddingRight={10}>
              <FieldPrefix prefix={"user"}>
                <PrefixedField name="password" validate={minCharacters} type="password"
                  render={({input, meta}) => (
                    <div>
                      <input
                        required={true}
                        className="login-card-input"
                        placeholder="Password"
                        {...input}
                      />
                      <br/>
                      <Error name={"password"} errors={errors}/>
                    </div>
                  )}
                />
                <Field name="confirm_password" validate={minCharacters} type="password"
                  render={({input, meta}) => (
                    <div>
                      <input
                        required={true}
                        className="login-card-input"
                        placeholder="Confirm Password"
                        {...input}
                      />
                      <br/>
                      <Error name={"confirm_password"} errors={errors}/>
                    </div>
                  )}
                />
              </FieldPrefix>
              <Field name="phone_number" type="tel"
                render={({input, meta}) => (
                  <div>
                    <input
                      className="login-card-input"
                      placeholder="Phone Number"
                      {...input}
                    />
                  </div>
                )}
              />
              <Field name="other_systems_used" type="text"
                render={({input, meta}) => (
                  <div>
                    <input
                      className="login-card-input"
                      placeholder="Other Maintenance/Operations Sytems Used"
                      {...input}
                    />
                  </div>
                )}
              />
            </Box>
          </Flex>
          <Box width={400} maxWidth="100%" margin="0 auto">
            <Field name="terms" type="checkbox"
              render={({input, meta}) => (
                <Box marginTop={2} textAlign="left">
                  <input
                    required={true}
                    id="terms"
                    {...input}
                  />
                  <Label htmlFor="terms"
                    color="white"
                    fontSize={14 + 2 / 3}
                    display="inline"
                    marginLeft={10}>
                         I agree to the{" "}
                    <DirectLink href="https://www.termsfeed.com/terms-service/c475ad5332fd330c4cda7714acfd2332"
                      target="_blank"
                      color="#42afda">
                           Terms and Conditions
                    </DirectLink>
                  </Label>
                </Box>
              )}
            />

            <Field name="subscribed" type="checkbox" defaultValue={true}
              render={({input, meta}) => (
                <Box marginTop={2} textAlign="left">
                  <input
                    id="subscribed"
                    {...input}
                  />
                  <Label htmlFor="subscribed"
                    color="white"
                    fontSize={14 + 2 / 3}
                    display="inline"
                    marginLeft={10}>
                         Subscribe to the mailing list
                  </Label>
                </Box>
              )}
            />
          </Box>
          <DirectLink href="https://conmitto.io/privacy-policy/"
            target="_blank"
            color="#42afda"
            marginTop={2}>
            View our Privacy Policy
          </DirectLink>
          {!signupSuccess ?
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="login-card-button"
              disabled={pristine}
              loading={submitting}>
              Submit
            </Button> :
            <Button
              size="large"
              className="login-card-button"
              id="signupLogin"
              onClick={handleDirectLoginClick}>
              Login Here
            </Button>}
        </form>
      )}
    />
  );
};

export default GeneralSignUpForm;
