import React from "react";
import PropTypes from "prop-types";
import {ItemName, ItemText} from "../../Common/Bars/styled";
import {List} from "Base";

const Template = (props) => {
  const {data, baseClassName} = props;
  return (
    <div className={`${baseClassName}-main-right`}>
      <ItemName className={`${baseClassName}-main-right-text`}>TEMPLATE: {data.template?.name ?? "None"}</ItemName>
      <List
        locale={{emptyText: " "}}
        size="small"
        dataSource={data.template_data.slice(0, 3)}
        renderItem={item => <ListItem item={item} baseClassName={baseClassName}/>}
      />
    </div>
  );
};

Template.propTypes = {
  baseClassName: PropTypes.string,
  data: PropTypes.object.isRequired
};

export {Template};

const ListItem = ({baseClassName, item}) => {
  return (
    <ItemText className={`${baseClassName}-main-right-text`}>
      <strong
        className={`${baseClassName}-main-right-text`}>
        {item.field.name}:
      </strong>
      {" "}{item?.value ?? "None"}
    </ItemText>
  );
};
