import {dateUtils, snackUtils} from "../../utils";
import {checkNestedKeys} from "../../utils/propUtils";

// A generic response handler, will take in 403 error message, reg error message, and a success message
const handleGenericResponse = (res, dispatch, permissionError, genericError, success) => {
  const timestamp = dateUtils.createTimestamp();
  const statusCode = res?.payload?.status;
  if (statusCode === 403) {
    snackUtils.createErrorSnack(permissionError, timestamp, dispatch);
  } else if (res.error || (400 <= statusCode && statusCode <= 599)) {
    snackUtils.createErrorSnack(genericError, timestamp, dispatch);
  } else {
    snackUtils.createSuccessSnack(success, timestamp, dispatch);
  }
};

// Handles create responses to functions across operations
const handleCreateResponse = (res, timestamp, type, name, dispatch, successCb) => {
  let label = type;
  if (name !== "") {
    label = label + `called ${name}!`;
  }
  if (res?.payload?.status === 403) {
    snackUtils.createErrorSnack(`You don't have permission to create ${type}`, timestamp, dispatch);
  } else if (res.error) {
    snackUtils.createErrorSnack(`Failed to Create New ${type} ${name}`, timestamp, dispatch);
  } else {
    snackUtils.createSuccessSnack(`Created new ${label}!`, timestamp, dispatch);
    successCb();
  }
};

// Handles general update responses across operations
const handleUpdateResponse = (res, timestamp, type, name, dispatch, successCb) => {
  if (res?.payload?.status === 403) {
    snackUtils.createErrorSnack(`You don't have permission to update ${type}`, timestamp, dispatch);
  } else if (res.error) {
    if (Array.isArray(checkNestedKeys(["payload", "response"], res))) {
      snackUtils.createErrorSnack(res.payload.response[0], timestamp, dispatch);
    } else {
      snackUtils.createErrorSnack(`Failed to update ${type}`, timestamp, dispatch);
    }
  } else if (!name) {
    snackUtils.createSuccessSnack(`Updated ${type}!`, timestamp, dispatch);
    successCb();
  } else {
    snackUtils.createSuccessSnack(`Updated ${type} called ${name}!`, timestamp, dispatch);
    successCb();
  }
};

// Calls on generic response and handles the response for the Addnote functions across operations
const handleSubmitNoteResponse = (res, dispatch) => {
  handleGenericResponse(
    res,
    dispatch,
    "You don't have permission to submit a note",
    "Submit Note Failed!",
    "Submitted Note!"
  );
};

// Handles the response for the CSVUpload functions across operations
const handleCSVUploadResponse = (res, dispatch, getAllItems) => {
  const timestamp = dateUtils.createTimestamp();
  if (res.payload.status === 403) {
    snackUtils.createErrorSnack("You don't have permission to upload this csv", timestamp, dispatch);
  } else if (res.error) {
    snackUtils.createErrorSnack("CSV Upload Failed", timestamp + 1, dispatch);
  } else {
    snackUtils.createSuccessSnack("Uploaded CSV Successfully!", timestamp + 1, dispatch);
    getAllItems && dispatch(getAllItems());
  }
};

export default {
  handleCreateResponse,
  handleUpdateResponse,
  handleSubmitNoteResponse,
  handleCSVUploadResponse,
  handleGenericResponse
};
