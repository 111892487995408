import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import {MOBILE} from "utils/constants";

import {Button, Tooltip} from "antd";
import {Box, Drawer} from "../../../Common/Base";

import {InventoryList, SelectingInventoryList} from "../../Lists/Inventory";
import {formatInventoryForSubmit, makeInventoryTitle} from "../../utils/inventory";
import {SearchInput} from "../../../Common/Forms";
import {PRODUCT_INVENTORY} from "../../utils/constants";

// Used in Sales Orders and Pallets for selecting inventory with quantity info
const SelectInventoryDrawer = ({buttonText, buttonConfig, type, loading, locationData,
  onSubmit, pagination, getInventory, formatInventory, fromOrder}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [modelOpen, setModalOpen] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [disableSave, setSaveDisabled] = useState(true);
  const isTabletOrMobile = useMediaQuery(MOBILE);


  const refreshInventory = () => {
    const {itemsCurrentPage} = pagination;
    if (searchTerm !== "") {
      getInventory(itemsCurrentPage, {location: locationData.id, page_size: 3, search: searchTerm});
    } else {
      getInventory(1, {location: locationData.id, page_size: 3});
    }
  };

  const resetSearch = () => {
    setSearchTerm("");
    getInventory(1, {location: locationData.id, page_size: 3});
  };

  const handleSelectItem = (item) => {
    /*

      For selecting a Product/Inventory that will have a quanitty added
     */
    let newSelected = selectedInventory;

    if (newSelected) {
      newSelected = {
        ...newSelected,
        [item.id]: item,
      };
      setSelectedInventory(newSelected);
    } else {
      newSelected = {[item.id]: item};
      // delete newSelected[item.id]
      setSelectedInventory(newSelected);
    }
    // Disable save button if no items selected
    if (disableSave) {
      setSaveDisabled(false);
    }
  };

  const handleRemove = (id) => {
    let newSelected = selectedInventory;
    if (newSelected && newSelected[id]) {
      delete newSelected[id];
      if (Object.keys(newSelected).length === 0) {
        setSaveDisabled(true);
      }
    }
    setSelectedInventory(newSelected);
  };

  const handleItemEditQuantity = (id, quantity) => {
    /*
    To edit quantity of a selected item from handleSelectItem
     */
    let newSelectedInventory = {...selectedInventory};
    if (selectedInventory && selectedInventory[id]) {
      newSelectedInventory[id].add_quantity = quantity;
    }

    setSelectedInventory(newSelectedInventory);
  };

  const toggleModal = () => {
    setModalOpen(!modelOpen);
    if (modelOpen === false) {
      setSelectedInventory(null);
    } else {
      refreshInventory();
    }
  };

  const handleSubmit = async () => {
    let data = formatInventory(selectedInventory, type);
    await onSubmit(data);
    toggleModal();
  };


  const getSelectedList = () => {
    /*
      Builds list from selectedInventory object
     */
    let result = [];
    if (selectedInventory) {
      let keys = Object.keys(selectedInventory);
      for (let i = 0; i < keys.length; i++) {
        result.push(selectedInventory[keys[i]]);
      }
    }
    return result;
  };

  const isToggleDisabled = (locationData) => {
    return !locationData || Object.keys(locationData).length === 0 ||
      locationData["level"] === "None";
  };

  const handleSearch = (values) => {
    console.log("search", values);
    setSearchTerm(values);
    getInventory(1, {location: locationData.id, search: values, page_size: 3});
  };
  const selectedList = getSelectedList();


  const title = makeInventoryTitle(locationData);

  const drawerWidth = isTabletOrMobile ? "100%" : 600;

  const toggleDisabled = isToggleDisabled(locationData);
  return (
    <Fragment>

      <Tooltip placement="topLeft" title={toggleDisabled ? "Choose a location to add inventory" : null}>
        <Button
          className={`add-${type}-button`}
          onClick={toggleModal}
          type={"primary"}
          disabled={toggleDisabled}
          {...buttonConfig}
        >
          {buttonText}
        </Button>
      </Tooltip>
      <Drawer
        visible={modelOpen}
        onClose={toggleModal}
        width={drawerWidth}
        title={title}
        zIndex={isTabletOrMobile ? 10000 : 1000}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={toggleModal}
              style={{marginRight: 8}}
            >
              Cancel
            </Button>
            <Button
              className={"save-btn"}
              onClick={handleSubmit}
              type="primary"
              disabled={disableSave}>
              Submit
            </Button>
          </div>
        }
      >

        <SearchInput
          onSubmit={handleSearch}
          filter={searchTerm}
          reset={resetSearch}
        />


        {modelOpen && // this forces InventoryList to rerender and clears hooks when modal opens
        <Box minHeight={"455px"}>
          <InventoryList
            onSelect={handleSelectItem}
            onEdit={handleItemEditQuantity}
            onRemove={handleRemove}
            loading={loading}
            pagination={pagination}
            getInventory={getInventory}
            location={locationData}
            type={type}
          />
        </Box>
        }

        <SelectingInventoryList
          data={selectedList}
          onEdit={handleItemEditQuantity}
          onRemove={handleRemove}
          type={type}
          fromOrder={fromOrder}
        />
      </Drawer>
    </Fragment>
  );
};

SelectInventoryDrawer.propTypes = {
  locationData: PropTypes.object,
  onSubmit: PropTypes.func,
  pagination: PropTypes.object,
  getInventory: PropTypes.func,
  formatInventory: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  buttonConfig: PropTypes.object,
  fromOrder: PropTypes.bool
};

SelectInventoryDrawer.defaultProps = {
  formatInventory: formatInventoryForSubmit,
  loading: false,
  title: "Add Inventory",
  type: PRODUCT_INVENTORY,
  buttonConfig: {},
  fromOrder: false
};

export default SelectInventoryDrawer;
