import React, {useState} from "react";
import PropTypes from "prop-types";

import {Button, Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {dropdownMenuPropType} from "../../../types";

const DropdownButtonMenu = ({onClick, menu, text}) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => { setVisible(!visible); };
  const handleClick = (e) => {
    console.log(e);
    onClick(e);
  };

  const menuItems = (
    <Menu onClick={handleClick}>
      {menu.map(item =>
        <Menu.Item key={item.key}>{item.text}</Menu.Item>
      )}
    </Menu>
  );
  return (
    <Dropdown
      overlay={menuItems}
      onVisibleChange={toggleVisible}
      visible={visible}
    >
      <Button>
        {text}
        <DownOutlined/>
      </Button>
    </Dropdown>
  );
};

DropdownButtonMenu.propTypes = {
  menu: PropTypes.arrayOf(dropdownMenuPropType),
  text: PropTypes.string
};

DropdownButtonMenu.defaultProps = {
  menu: [
    {
      key: "1",
      text: "Clicking me will not close the menu."
    }
  ],
  text: "Actions"
};

export default DropdownButtonMenu;
