import React from "react";
import PropTypes from "prop-types";
import {Box, Flex, Card} from "../../Base";
import {DashTile} from "./DashTile";

// A scorecard to display cards of information about a users workload for them to strategize their shift
const ScorecardDashboard = ({title, summaries}) => {
  const testId = title.split(" ")[0];
  return (
    <Box py={5}>
      <Card id={testId} title={title}>
        <Flex pt={3} flexWrap="wrap" justifyContent="space-evenly">
          {summaries.map((item, key) => {
            return <DashTile
              key={key}
              {...item}
            />;
          })}
        </Flex>
      </Card>
    </Box>
  );
};
ScorecardDashboard.propTypes = {
  title: PropTypes.string,
  topGradientColor: PropTypes.string,
  hrGradientColor: PropTypes.string,
  summaries: PropTypes.arrayOf(PropTypes.object),
  scorecardPhase: PropTypes.string
};

ScorecardDashboard.defaultProps = {
  title: "Generic Department Dashboard",
  topGradientColor: "linear-gradient(to right, rgb(0, 255, 255), rgb(0, 75, 255)",
  hrGradientColor: "linear-gradient(to right, rgba(0, 255, 255, .5), rgba(0, 0, 255, 0.75), rgba(0, 255, 255, .5)",
  summaries: [],
  scorecardPhase: "Generic Scorecard Phase"
};

export {ScorecardDashboard};
