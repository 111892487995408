import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {Button} from "Base";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import {uploadBlobsToAzureWithFileNameCheck} from "../../../utils/azure";
import PropTypes from "prop-types";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImageExifOrientation, FilePondPluginImageResize,
  FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginImageTransform);

/* This class allows adding a note to an object, with attaching files to the note supported */
class AddNoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteData: ""
    };
  }

  handleNoteEdit = (e) => {
    this.setState({noteData: e.target.value});
  };

  handleSubmit = async e => {
    const {refreshObject, submitNoteToServer, azureDocumentPrefix, UUID, getDocuments} = this.props;
    const {noteData} = this.state;
    let noteID = "";
    e.preventDefault();
    if (noteData) {
      let res = await submitNoteToServer(noteData);
      noteID = res.id;
      if (noteID) {
        this.setState({noteData: ""});
      }
    }
    const filepondFiles = this.featurePond.current.getFiles();
    if (filepondFiles.length > 0) {
      await uploadBlobsToAzureWithFileNameCheck(UUID, azureDocumentPrefix, filepondFiles, noteID)
        .then(() => this.featurePond.current.removeFiles());
    }
    refreshObject();
    getDocuments();
  };

  render() {
    const {noteData} = this.state;
    this.featurePond = React.createRef();

    return (
      <form className="item-details-form" onSubmit={this.handleSubmit}>
        <textarea
          className="item-details-pane-right-notes"
          name="notes"
          value={noteData}
          placeholder="Add a new note"
          onChange={this.handleNoteEdit}/>
        <FilePond
          ref={this.featurePond}
          className="hideProcessFile"
          allowMultiple={true}
          imageCropAspectRatio="1:1"
          imageResizeTargetWidth={600}
          imageResizeTargetHeight={600}
          imageResizeUpscale={false}
          imageResizeMode="cover"
          labelIdle="Drag and drop files to the note"/>
        <br/>
        <Button htmlType={"submit"} type={"primary"} className="item-details-form-button">
          Submit Note
        </Button>
      </form>
    );
  }
}

AddNoteForm.propTypes = {
  refreshObject: PropTypes.func,
  submitNoteToServer: PropTypes.func,
  azureDocumentPrefix: PropTypes.string,
  documentID: PropTypes.number
};

AddNoteForm.defaultProps = {};

export default reduxForm({
  form: "add-note"
  // AsyncValidate,
  // asyncBlurFields: ["assetName", "assetDescription"]
})(AddNoteForm);
