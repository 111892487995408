import React from "react";
import PropTypes from "prop-types";
import {DashboardTable} from "../../Common/Dashboard";
import {columns} from "./constants";


const LocationTable = props => {
  const {currentPage} = props;
  return (
    <DashboardTable
      data={currentPage}
      columns={columns}
      style={{width: "100%"}}
    />
  );
};


LocationTable.propTypes = {
  /**
   * Pagination data structure
   */
  currentPage: PropTypes.array.isRequired
};

export default LocationTable;
