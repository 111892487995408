import React, {Component} from "react";
import {LocationSection} from "Common";
import {Box, ErrorBoundary, Heading} from "Base";
import {DetailsContainer} from "Common/Details";
import {checkNestedKeys} from "utils/propUtils";
import {isEmpty} from "lodash-es";
import {DEFAULT_PAGE_SIZE, MAX_QUANTITY} from "./constants";
import {OrderProductTable, OrderMaterialTable} from "../../Table";
import {PurchaseInventoryDrawer} from "../../Drawers";
import DetailsHeader from "./DetailsHeader";
import {getOrderTableMaterials, getOrderTableProducts} from "../../utils/tables";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY, PURCHASE_ORDER} from "../../utils/constants";
import {OrderPalletSection} from "../../Sections";

class PurchaseOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.purchaseOrderID = props.match.params.orderID;
    this.state = {
      palletUUID: "",
      palletSKU: "",
      productOptions: [],
      inventoryList: [],
      loading: false,
      notFound: false,
    };
  }

  componentDidMount = () => {
    const {
      accountInfo,
      purchaseOrderDetails,
      locationList,
      getLocations,
      // productList,
      getProductList,
      productPagination,
    } = this.props;
    const productList = productPagination.itemsPaged;

    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);

    if (isEmpty(locationList) && orgId) getLocations(orgId);
    if (isEmpty(productList) && orgId) getProductList(1, {page_size: DEFAULT_PAGE_SIZE});
    if (purchaseOrderDetails && this.purchaseOrderID === purchaseOrderDetails.id) {
      this.setState({loading: false});
    } else {
      this.setState({loading: true});
    }
    this.fetchData();
  };

  fetchData = async () => {
    const {
      getPurchaseOrderDetails,
    } = this.props;

    const res = await getPurchaseOrderDetails(this.purchaseOrderID);
    if (!res.id) {
      if (res.message === "404 - Not Found") {
        this.setState({notFound: true});
      }
    }
    this.setState({loading: false});
  };

  onFormSubmit = async (values) => {
    const {updatePurchaseOrder} = this.props;
    const res = await updatePurchaseOrder(this.purchaseOrderID, values);
    if (!res.error) {
      await this.fetchData();
    }
  };

  onFormSubmitFiles = async (values) => {
    const {updateFiles} = this.props;
    const res = await updateFiles(this.purchaseOrderID, values);
    if (!res?.error) {
      await this.fetchData();
    }
  };

  onSubmitLocation = async (values) => {
    const {updatePurchaseOrder} = this.props;
    const updateValue = {location: values.location.location_instance};
    const res = await updatePurchaseOrder(this.purchaseOrderID, updateValue);
    if (!res.error) {
      await this.fetchData();
    }
  };

  handleSubmitQuantity = (values) => {
    const {addQuantity} = this.props;
    return addQuantity(this.purchaseOrderID, values);
  };

  handleSubmitMaterial = (values) => {
    const {addMaterial} = this.props;
    addMaterial(this.purchaseOrderID, values);
  };

  updateItemQuantity = async (values) => {
    const itemID = values.id;
    const data = {
      price: values.price,
      quantity: values.quantity,
    };
    const submissionData = {...data, purchase_order: this.purchaseOrderID};
    const {updateQuantity} = this.props;
    updateQuantity(this.purchaseOrderID, itemID, submissionData);
  };

  updateMaterialQuantity = async (values) => {
    const itemID = values.id;
    const data = {
      price: values.price,
      quantity: values.quantity,
    };
    const submissionData = {...data, purchase_order: this.purchaseOrderID};
    const {updateMaterial} = this.props;
    updateMaterial(this.purchaseOrderID, itemID, submissionData);
  };

  handleCreateSupplier = async (values) => {
    const {createSupplier, getSuppliers, accountInfo} = this.props;
    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);

    let res = await createSupplier({...values, org: orgId});

    if (!res.error) {
      await getSuppliers(1);
    }
    return res;
  };

  handleCreatePallet = async (values) => {
    const {createPallet} = this.props;
    let res = await createPallet(values);
    if (!res.error) {
      this.fetchData();
    }
    return res;
  };

  renderAddPurchasePallet = () => {
    const {
      purchaseOrderDetails,
      facilities,
      personnelList,
      createSupplierAddress
    } = this.props;

    const formatEdit = (item, data) => {
      const {values} = data;
      return ({id: item.id, quantity: values.quantity});
    };

    return (
      <OrderPalletSection
        orderDetails={purchaseOrderDetails}
        facilities={facilities}
        personnelList={personnelList}
        handleCreatePallet={this.handleCreatePallet}
        orderType={PURCHASE_ORDER}
        formatEdit={formatEdit}
        createAddress={createSupplierAddress}
      />
    );
  };

  render() {
    const {loading, notFound} = this.state;
    const {
      accountInfo,
      purchaseOrderDetails,
      deletePurchaseOrder,
      getProductList,
      getMaterialList,
      productPagination,
      materialPagination,
      supplierPagination,
      locationList,
      isAdmin,
      personnelList,
    } = this.props;
    const {flattenedItems, purchaseOrderItemLookup} = getOrderTableProducts(purchaseOrderDetails);

    const {flattenedMaterials, materialItemLookup} = getOrderTableMaterials(purchaseOrderDetails);

    const locationData = purchaseOrderDetails && purchaseOrderDetails.location ? purchaseOrderDetails.location : {};

    const supplierList = supplierPagination.itemsPaged;

    return (
      <DetailsContainer loading={loading} notFound={notFound}>
        <ErrorBoundary>

          <DetailsHeader
            account={accountInfo}
            onDelete={deletePurchaseOrder}
            onUpdate={this.onFormSubmit}
            updateBol={this.onFormSubmitFiles}
            data={purchaseOrderDetails}
            createSupplier={this.handleCreateSupplier}
            suppliers={supplierList}
            personnel={personnelList}
            locationData={locationData}
            locationSection={
              <Box display={"inline-block"} key={"location-section"}>
                <LocationSection
                  locationData={locationData}
                  locationList={locationList}
                  submit={this.onSubmitLocation}
                  isAdmin={isAdmin}
                  hideCard
                  buttonConfig={{
                    size: "small",
                    type: Object.keys(locationData).length !== 0 ? "link" : "default",
                  }}
                  wrapperStyle={{mt: 0}}
                />
              </Box>
            }
            actions={[
              <PurchaseInventoryDrawer
                key={"add-item"}
                loading={loading}
                orderDetails={purchaseOrderDetails}
                pagination={productPagination}
                fetchPage={getProductList}
                onSubmit={this.onFormSubmit}
                purchaseOrderID={this.purchaseOrderID}
                addQuantity={this.handleSubmitQuantity}
                purchaseOrderItemLookup={purchaseOrderItemLookup}
                max={MAX_QUANTITY}
                type={PRODUCT_INVENTORY}
                suppliers={supplierList}
                supplierPagination={supplierPagination}
              />,
              <PurchaseInventoryDrawer
                key={"add-materials"}
                loading={loading}
                orderDetails={purchaseOrderDetails}
                pagination={materialPagination}
                fetchPage={getMaterialList}
                purchaseOrderID={this.purchaseOrderID}
                addQuantity={this.handleSubmitMaterial}
                purchaseOrderItemLookup={materialItemLookup}
                max={MAX_QUANTITY}
                type={MATERIAL_INVENTORY}
                suppliers={supplierList}
                supplierPagination={supplierPagination}
              />]}
          />
        </ErrorBoundary>

        {this.renderAddPurchasePallet()}

        <Box pt={2}>
          <Heading py={2}>Inventory</Heading>
          <OrderProductTable
            data={flattenedItems || []}
            onSubmit={this.updateItemQuantity}
          />
        </Box>

        <Box>
          <Heading py={2}>Materials</Heading>
          <OrderMaterialTable
            data={flattenedMaterials || []}
            onSubmit={this.updateMaterialQuantity}
          />
        </Box>

        {/*  TODO: Implement the note section */}
        {/*  <NoteSection*/}
        {/*  UUID={orderUUID}*/}
        {/*  accountInfo={accountInfo}*/}
        {/*  documentID={orderID}*/}
        {/*  handleSubmit={addNote}*/}
        {/*  refreshObject={() => fetchNotes(palletDetails.id)}*/}
        {/*  azureDocumentPrefix={AZURE_PM_ORDER_PREFIX}*/}
        {/*  getDocuments={this.getOrdersDocuments}*/}
        {/*  notes={notes}*/}
        {/*  files={orderFiles}*/}
        {/*  getRecentLogs={getRecentLogs}*/}
        {/*  title={"PALLET NOTES"}*/}
        {/*  endpoint={"pallets"}*/}
        {/* />*/}
        {/* <br/>*/}
        {/* <br/>*/}
        {/* )}*!/*/}

      </DetailsContainer>

    );
  }
}


export default PurchaseOrderDetails;
