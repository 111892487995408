import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icon, {DownOutlined, ImportOutlined} from "@ant-design/icons";
import {FaBook, FaTruckLoading} from "react-icons/fa";
import {HelpIcon} from "./HelpIcon";

const iconDefaults = {
  size: "lg"
};

const CaretUp = props => (
  <FontAwesomeIcon icon={"caret-up"} {...iconDefaults} {...props}/>
);


const CaretDown = props => (
  <FontAwesomeIcon icon={"caret-down"} {...iconDefaults} {...props}/>
);


const Plus = props => (
  <FontAwesomeIcon icon={"plus"} {...iconDefaults} {...props}/>
);

const Edit = props => (
  <FontAwesomeIcon icon="edit" {...iconDefaults} {...props} />
);

const CloseIcon = props => (
  <FontAwesomeIcon icon="window-close" className={"closeIcon"} {...iconDefaults} {...props} />
);

const DetailsCloseIcon = props => (
  <a
    href={props.href}
    className="item-details-close"
    data-tip={props.dataTip}
    data-delay-show="1000">
    <CloseIcon/>
  </a>
);

const BookIcon = props => <Icon
  component={FaBook}
  {...props}

/>;

const TruckIcon = props => <Icon
  component={FaTruckLoading}
  {...props}
/>;

DetailsCloseIcon.propTypes = {
  href: PropTypes.string.isRequired,
  dataTip: PropTypes.string.isRequired
};

export {
  BookIcon,
  CaretDown,
  CaretUp,
  CloseIcon,
  DetailsCloseIcon,
  DownOutlined,
  Edit,
  HelpIcon,
  ImportOutlined,
  Plus,
  TruckIcon,
};
