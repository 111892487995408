export const roleList = [
  {value: 1, label: "Admin"},
  {value: 2, label: "Manager"},
  {value: 3, label: "Lead"},
  {value: 4, label: "Technician"},
  {value: 5, label: "Contractor"},
  {value: 6, label: "Service Provider"}
];
export const classificationList = [
  {value: 1, label: "Maintenance"},
  {value: 2, label: "Repair"},
  {value: 3, label: "Operations"}
];
export const typeList = [
  {value: 1, label: "Full Time"},
  {value: 2, label: "Part Time"},
  {value: 3, label: "Contractor"}
];
export const CLIENT_ORG_TYPE = "Client";
export const SERVICE_PROV_ORG_TYPE = "Service Provider";
