import React, {useState} from "react";
import PropTypes from "prop-types";
import {Heading, Box, CloseIcon} from "../../Base";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Popover from "react-tiny-popover";
import {ToggleActivityForm} from "../../Forms/Timetracker";
import {DropdownWrapper, HeaderWrapper, CloseWrapper, dropdownContainerStyles} from "./styled";
import {RecentActivityList} from "../../Lists";

const ToggleActivityDropdown = ({shiftList, active, onSubmit, recentActivity}) => {
  const [isFocused, setIsFocused] = useState(false);

  const toggleDropdown = () => {
    setIsFocused(!isFocused);
  };

  const handleOnClickOutside = (e) => {
    // this ensures click outside does not close popover when selecting from dropdown
    if (!e.target.id.includes("react-select")) {
      setIsFocused(false);
    }
  };

  return (
    <Popover
      isOpen={isFocused}
      onClickOutside={handleOnClickOutside}
      position={"bottom"}
      align={"center"}
      containerStyle={dropdownContainerStyles}
      content={(
        <DropdownWrapper width={"400px"} className={"toggle-activity-dropdown"}>
          <HeaderWrapper>
            <Heading textAlign="center">Toggle Time Tracking</Heading>
            <CloseWrapper>
              <CloseIcon onClick={toggleDropdown}/>
            </CloseWrapper>
          </HeaderWrapper>
          <ToggleActivityForm
            shiftList={shiftList}
            active={active}
            onSubmit={onSubmit}
          />

          <RecentActivityList
            items={recentActivity}
          />
        </DropdownWrapper>
      )}
    >
      <Box mr={"15px"} cursor={"pointer"} onClick={toggleDropdown}>
        <FontAwesomeIcon
          icon="clock"
          size="lg"/>
      </Box>
    </Popover>
  );
};

ToggleActivityDropdown.propTypes = {
  shiftList: PropTypes.array,
  active: PropTypes.any,
  onSubmit: PropTypes.func,
  recentActivity: PropTypes.array
};

export default ToggleActivityDropdown;
