import React from "react";
import PropTypes from "prop-types";
import {ListItem, Text} from "../../Base";
import {ruleStringToText} from "../../../../utils/rrules";
import {formatShiftTimeWithTimezone} from "../../../../utils/dates";

const ActivityListItem = props => {
  const {item} = props;


  const title = item.shift ? `${item.shift_details.schedule.name} - ${ruleStringToText(item.shift_details.repeat)}` : "No Shift Associated";

  const tzStart = formatShiftTimeWithTimezone(item.start_time);
  const tzEnd = formatShiftTimeWithTimezone(item.end_time);

  // const tzEnd = moment.tz(item.end_time, tz).format('')
  return (
    <ListItem className={"activity-list-item"} p={2} m={1} shadow={"md"} borderWidth={"1px"}>
      <Text fontSize={"sm"} className={"title"}>{title}</Text>
      <Text fontSize={"xs"} className={"start-time"}>Start: {tzStart}</Text>
      <Text fontSize={"xs"}>End: {tzEnd}</Text>
    </ListItem>
  );
};

ActivityListItem.propTypes = {
  item: PropTypes.any,
};

export default ActivityListItem;
