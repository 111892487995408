import React, {useState, useCallback} from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import {withAsyncPaginate} from "react-select-async-paginate";
import {loadOptions} from "./utils";

const AsyncPaginate = withAsyncPaginate(CreatableSelect);

const PaginatedCreateSelect = ({value, onChange, onCreate, getPage, name}) => {
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);

  const handleChange = (newValue) => {
    console.log("location onChange", newValue);
    onChange(newValue);
  };

  const handleCreate = useCallback(async (values) => {
    setIsAddingInProgress(true);

    const res = await onCreate(values);
    setIsAddingInProgress(false);
    if (!res.error) {
      const {payload} = res;
      const newOption = {
        label: payload.code,
        value: payload.id,
      };
      onChange(newOption);
    }

    console.log("create res", res);
  }, [onCreate, onChange]);

  const handleLoad = async (search, loadedOptions, {page}) => {
    return loadOptions(search, loadedOptions, {page, getPage});
  };
  return (
    <AsyncPaginate
      isDisabled={isAddingInProgress}
      value={value}
      loadOptions={handleLoad}
      onChange={handleChange}
      onCreateOption={handleCreate}
      name={name}
      additional={{
        page: 1,
      }}
    />
  );
};

PaginatedCreateSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  pagination: PropTypes.object,
  getPage: PropTypes.func,
};

export default PaginatedCreateSelect;
