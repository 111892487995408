import React, {Fragment} from "react";
import {AntdCollapse, Heading, List, Panel, Box} from "Base";
import {crudActions, paginationProps} from "../../../../types/propTypes";

/**
 * A form showing the suppliers/customers and their addresses, meant to be used in the admin panel
 * @param {object} props The props
 * @returns {AdminAddressForm} The form
 */
const AdminAddressForm = ({pagination, actions}) => {
  const {
    itemsPaged,
    loading, // can be used as loading boolean
    itemsCount,
    errored,
    pageSize
  } = pagination;

  const renderAddress = item => {
    const {address} = item;
    const {address_line1, address_line2, city, state, zip_code} = address;
    return <Box>
      <Heading fontSize={"md"} mt={"15px"} mb={"10px"}>Name: {address.name || "No Name"}</Heading>
      <Box><b>Address Line 1: </b> {address_line1}</Box>
      {address_line2 && <Box><b>Address Line 2: </b> {address_line2}</Box>}
      <Box><b>City: </b> {city}</Box>
      <Box><b>State: </b> {state}</Box>
      <Box><b>Zip: </b> {zip_code}</Box>
    </Box>;
  };

  const renderItem = item => {
    return (
      <List.Item>
        <List.Item.Meta
          title={<Box><b>Name:</b> {item.name || "No Name"}</Box>}
          description={`Addresses: ${item?.addresses?.length ?? 0}`}
        />
        {item?.addresses?.length !== 0 &&
        <AntdCollapse>
          <Panel header="View Addresses" key={0}>
            <Fragment>
              <Heading>Addresses</Heading>
              <List
                itemLayout={"vertical"}
                dataSource={item?.addresses}
                renderItem={renderAddress}/>
            </Fragment>
          </Panel>
        </AntdCollapse>
        }
      </List.Item>
    );
  };

  return (
    !errored ?
      <List
        itemLayout={"vertical"}
        className={"address-list"}
        pagination={{
          onChange: page => {
            actions.getPage(page);
          },
          total: itemsCount,
          showTotal: (total) => `Total ${total} items`,
          pageSize: pageSize,
          position: "top",
        }}
        loading={loading && !errored}
        dataSource={itemsPaged}
        renderItem={renderItem}
      />
      : <b>Something went wrong</b>
  );
};

AdminAddressForm.propTypes = {
  pagination: paginationProps.isRequired,
  actions: crudActions.isRequired,
};

export default AdminAddressForm;

