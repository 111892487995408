

const selectFieldHandler = callback => (value) => {
  console.log("selectFieldHandler value", value);

  const editedEvent = {target: {value: value.value, name: value.name}};
  callback(editedEvent);
};


export default selectFieldHandler;
