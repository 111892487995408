import {Input} from "@rebass/forms";
import styled from "@emotion/styled";
import {Button as BaseButton, Box, Flex} from "../../Base";
import {adapt} from "../Base/utils";

const AdaptedInput = adapt(Input);


const Row = styled(Flex)`

`;

Row.defaultProps = {
  flexDirection: "row",
  flexWrap: "wrap"
};

const CreateButton = styled(BaseButton)``;


const Button = styled(BaseButton)`

`;

Button.defaultProps = {
  width: ["120px", "180px"],
  p: "10px 15px 10px 20px",
  fontSize: ["1em, 1.4em"],
  m: "10px"
};


const FormWrapper = styled(Box)`
    
`;

FormWrapper.defaultProps = {
  py: 3
};


const ListWrapper = styled(Box)`
`;

ListWrapper.defaultProps = {
  pt: "1em"
};

export {FormWrapper, ListWrapper, Button, CreateButton, Row, AdaptedInput};
