import actionTyper from "redux-actiontyper";

const name = "pm_orders";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  GET_ITEM_SCHEDULES_REQUEST,
  GET_ITEM_SCHEDULES_SUCCESS,
  GET_ITEM_SCHEDULES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,
  ADD_NOTE_REQUEST,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,

  GET_ALL_NONE_FOUND,
  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,

  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,

  CHANGE_VIEW_TABLE,
  CHANGE_VIEW_CALENDAR,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,

  UPLOAD_CSV_FAILURE,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_REQUEST,
  CLEAR_MESSAGES,
} = actionTyper(`@@${name}/`);

export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,

  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  GET_ITEM_SCHEDULES_REQUEST,
  GET_ITEM_SCHEDULES_SUCCESS,
  GET_ITEM_SCHEDULES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,
  ADD_NOTE_REQUEST,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,

  GET_ALL_NONE_FOUND,
  GOTO_PAGE,
  NEXT_PAGE,
  PREV_PAGE,
  FILTER_LIST,
  FILTER_LIST_BY,
  SORT_LIST,
  REVERSE_LIST,
  CHANGE_VIEW_LIST,
  CHANGE_VIEW_TILE,
  CHANGE_VIEW_TABLE,
  CHANGE_VIEW_CALENDAR,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,


  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,

  UPLOAD_CSV_FAILURE,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_REQUEST,
  CLEAR_MESSAGES,
};
