
const LIST = "list";
const TABLE = "table";
const TILE = "tile";
const CALENDAR = "calendar";

const viewTypes = {
  list: LIST,
  table: TABLE,
  tile: TILE,
  calendar: CALENDAR
};

export {LIST, TILE, TABLE, CALENDAR, viewTypes};

