import React from "react";
import columns from "./columns.js";
import {Table} from "../../Common/Tables/Table";
import {materialsTableData} from "./data";

const MaterialsTable = (props) => {
  return (
    <Table
      handleFieldChange={props.handleFieldChange}
      columns={columns}
      tableData={materialsTableData}
      {...props}
    />
  );
};

export {MaterialsTable};
