import React, {useState} from "react";
import PropTypes from "prop-types";
import {Switch} from "antd";
import {Tooltip, Flex, Box} from "Base";


// A switch to turn beta features on/off
const BetaSwitch = ({onchange, textContent}) => {
  const [displayText, setDisplayText] = useState(false);

  /**
   * Set text to display and call parents onchange when beta is selected
   */
  const setFeatureAndTextDisplay = () => {
    setDisplayText(!displayText);
    onchange();
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center">
      <Tooltip title="Not all functionality is implemented in Beta">
        <Switch id="betaFeatureSwitch" onChange={setFeatureAndTextDisplay} checkedChildren="Beta Test" unCheckedChildren="Standard"/>
      </Tooltip>
      {displayText &&
      <Box
        textAlign="center"
        w={["90%", "85%", "80%", "80%"]}
        mt="10px">
        {textContent}<br/>
        <a style={{color: "#1853A5"}} href="https://conmitto.io/contact/" target="_blank" rel="noopener noreferrer">Send Feedback</a>.
      </Box>}
    </Flex>
  );
};

BetaSwitch.propTypes = {
  /**
   *  A function scoped in the parent that uses the switch
   */
  onChange: PropTypes.func,
  /**
   * Content string to tell the user a message about the Beta feature
   */
  textContent: PropTypes.string
};

BetaSwitch.defaultProps = {
  onChange: () => console.log("Beta Switch clicked"),
  textContent: "Thank you for trying out our Beta features. These are works in progress and your feedback is incredibly " +
    "valued as we take into consideration not only which features to build out, but how to add as much value as possible " +
    "from you, our valued user. Please contact us with anything that may help us helpyou."
};

export {BetaSwitch};
