import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import Notes from "./Notes";
import {AddNoteForm} from "../Forms";
import {CaretDown, CaretUp} from "../Base/Icons";

const NoteSection = props => {
  const [showExpansion, handleExpand] = useState(false);
  const [initStatus, updateInitStatus] = useState(false);
  const {
    title,
    endpoint,
    documentID,
    handleSubmit,
    notes,
    refreshObject,
    files,
    accountInfo,
    displayStatus,
    UUID,
    getDocuments,
    azureDocumentPrefix,
    toggleInspectionNotes,
    itemStatus
  } = props;

  /* This will handle adding a note to the current element */
  const handleNoteSubmit = async noteData => {
    let data = {
      content: noteData
    };
    if (displayStatus) {
      data["status"] = itemStatus;
    }
    let noteRes = await handleSubmit(documentID, data);
    return noteRes;
  };

  const handleNotesExpand = () => {
    toggleInspectionNotes();
    handleExpand(!showExpansion);
  };

  const handleInit = () => {
    updateInitStatus(true);
    refreshObject();
  };

  if (initStatus === false) {
    handleInit();
  }

  return (
    <Fragment>
      <div className="item-details-pane-right-expand">
        <span className="item-details-pane-right-expand-text note-expand" onClick={handleNotesExpand}>
          EXPAND NOTES <span className="note-count">({notes.length}){" "}</span>
          {!showExpansion ? (
            <CaretUp className="item-details-pane-right-expand-icon" size="2x" />
          ) : (
            <CaretDown className="item-details-pane-right-expand-icon" size="2x" />
          )}
        </span>
      </div>
      {showExpansion && (
        <div className="item-details-pane-right-text">
          <Notes
            notes={notes}
            title={title}
            objectEndpoint={endpoint}
            objectID={documentID}
            accountID={accountInfo.id}
            files={files}
            displayStatus={displayStatus}
            refreshObject={refreshObject}
          />
          <AddNoteForm
            submitNoteToServer={handleNoteSubmit}
            azureDocumentPrefix={azureDocumentPrefix}
            documentID={documentID}
            UUID={UUID}
            refreshObject={refreshObject}
            getDocuments={getDocuments}
          />
        </div>
      )}
    </Fragment>
  );
};

NoteSection.propTypes = {
  handleSubmit: PropTypes.func,
  refreshObject: PropTypes.func,
  documentID: PropTypes.any,
  notes: PropTypes.array,
  title: PropTypes.string,
  endpoint: PropTypes.string,
  files: PropTypes.array,
  accountInfo: PropTypes.object,
  /**
   * Whether to display the status of the item
   */
  displayStatus: PropTypes.bool,
  /**
   * Function to toggle the visibility of the inspection note field
   */
  toggleInspectionNotes: PropTypes.func,
  /**
   * The status of the item. This only gets used if the displayStatus property is true
   */
  itemStatus: PropTypes.number,
};

NoteSection.defaultProps = {
  displayStatus: false,
  toggleInspectionNotes: () => {}
};
export default NoteSection;
