import React from "react";
import PropTypes from "prop-types";
import {Details} from "./Details";
import {Bottom, SeeMoreButton, SideList, Wrapper} from "../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../Common/Bars/BarComponents/constants";

/* An asset bar is a div containing information regarding an asset. A list of
asset bars are visible within the assets section. */

const MaterialBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds, url} = props;
  const baseClassName = listView ? listStyle : tileStyle;
  const leftList = [
    {title: "MATERIAL NAME", value: data.name || "None"},
    {title: "MANUFACTURER", value: data.manufacturer || "None"},
    {title: "PRICE", value: data.price ? `${data.price} ${data.price_currency}` : "None"}
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="production/materials"
      url={url}
      itemData={data}
      detailsPane={<Details baseClassName={baseClassName}/>}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
    </Wrapper>
  );
};

MaterialBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array,
  url: PropTypes.string
};

export {MaterialBar};
