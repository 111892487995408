

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    // width: "25%",
    editable: true,
  },
  {
    title: "Status",
    dataIndex: "status_display",
    // width: "25%",
    editable: false,
  },
  {
    title: "Created",
    dataIndex: "created_at",
    // width: "25%",
    editable: false,
  },
  {
    title: "Last Updated",
    dataIndex: "updated_at",
    // width: "25%",
    editable: false,
  }
];
