import actionTyper from "redux-actiontyper";

const createCrudActionTypes = ({name, withNotes, withCsv, withBulkDelete}) => {
  const {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,

    CREATE_ITEM_REQUEST,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_FAILURE,

    GET_ITEM_REQUEST,
    GET_ITEM_SUCCESS,
    GET_ITEM_FAILURE,

    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE,

    DELETE_ITEM_REQUEST,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE,
  } = actionTyper(`@@${name}/`);

  let bulkDeleteTypes = {};
  if (withBulkDelete) {
    const {
      BULK_DELETE_REQUEST,
      BULK_DELETE_SUCCESS,
      BULK_DELETE_FAILURE
    } = actionTyper(`@@${name}/`);
    bulkDeleteTypes = {
      BULK_DELETE_REQUEST,
      BULK_DELETE_SUCCESS,
      BULK_DELETE_FAILURE
    };
  }

  let noteTypes = {};
  if (withNotes) {
    const {
      GET_ITEM_NOTES_REQUEST,
      GET_ITEM_NOTES_SUCCESS,
      GET_ITEM_NOTES_FAILURE,

      SUBMIT_NOTE_REQUEST,
      SUBMIT_NOTE_FAILURE,
      SUBMIT_NOTE_SUCCESS,

      UPDATE_NOTE_REQUEST,
      UPDATE_NOTE_FAILURE,
      UPDATE_NOTE_SUCCESS,
    } = actionTyper(`@@${name}/`);
    noteTypes = {
      GET_ITEM_NOTES_REQUEST,
      GET_ITEM_NOTES_SUCCESS,
      GET_ITEM_NOTES_FAILURE,

      SUBMIT_NOTE_REQUEST,
      SUBMIT_NOTE_FAILURE,
      SUBMIT_NOTE_SUCCESS,

      UPDATE_NOTE_REQUEST,
      UPDATE_NOTE_FAILURE,
      UPDATE_NOTE_SUCCESS
    };
  }
  let csvTypes = {};

  if (withCsv) {
    const {
      UPLOAD_CSV_FAILURE,
      UPLOAD_CSV_SUCCESS,
      UPLOAD_CSV_REQUEST,
      CLEAR_MESSAGES
    } = actionTyper(`@@${name}/`);
    csvTypes = {
      UPLOAD_CSV_FAILURE,
      UPLOAD_CSV_SUCCESS,
      UPLOAD_CSV_REQUEST,
      CLEAR_MESSAGES
    };
  }
  return {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,

    CREATE_ITEM_REQUEST,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_FAILURE,

    GET_ITEM_REQUEST,
    GET_ITEM_SUCCESS,
    GET_ITEM_FAILURE,

    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE,

    DELETE_ITEM_REQUEST,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE,

    ...noteTypes,
    ...csvTypes,
    ...bulkDeleteTypes
  };
};

export default createCrudActionTypes;
