import React from "react";
import PropTypes from "prop-types";
import {numericalFieldHandler} from "../../utils/numericalFieldHandler";
import translateLabel from "../../utils/translateLabel";
import {Input, InputWrapper, Label} from "../../styles/styled";
import {Flex} from "@chakra-ui/core";

const EndAfter = ({
  id,
  after,
  handleChange,
  translations
}) => (
  <Flex className="form-group" alignItems={"center"}>
    <InputWrapper>
      <Input
        id={id}
        name="end.after"
        aria-label="End after"
        className="form-control"
        value={after}
        onChange={numericalFieldHandler(handleChange)}
      />
    </InputWrapper>
    <Label>
      {translateLabel(translations, "end.executions")}
    </Label>
  </Flex>
);

EndAfter.propTypes = {
  id: PropTypes.string.isRequired,
  after: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EndAfter;
