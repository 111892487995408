import React from "react";
import PropTypes from "prop-types";
import {Route, withRouter} from "react-router-dom";
import {MATERIALS_ROUTE, LINES_ROUTE, PRODUCT_ROUTE} from "./constants";
import {
  MaterialDetailsContainer,
  MaterialsContainer,
  ProductionLinesContainer,
  ProductionLineDetailsContainer,
  InventoryContainer, ProductDetailsContainer,
} from "../containers";

export const ProductionRoutes = ({match}) => {
  return (
    <>
      <Route
        exact
        path={`${match.path}/${MATERIALS_ROUTE}`}
        name="Materials"
        component={MaterialsContainer}
      />

      <Route
        exact
        path={`${match.path}/${MATERIALS_ROUTE}/:materialID`}
        name="Material Details"
        component={MaterialDetailsContainer}
      />

      <Route
        exact
        path={`${match.path}/${LINES_ROUTE}`}
        name="Production Lines"
        component={ProductionLinesContainer}
      />

      <Route
        exact
        path={`${match.path}/${LINES_ROUTE}/:id`}
        name="Production Line Details"
        component={ProductionLineDetailsContainer}
      />

      <Route
        exact
        path={`${match.path}/${PRODUCT_ROUTE}`}
        name={"Products"}
        component={InventoryContainer}
      />
      <Route
        exact
        path={`${match.path}/${PRODUCT_ROUTE}/:productID`}
        name="Product Details"
        component={ProductDetailsContainer}
      />

    </>
  );
};

ProductionRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(ProductionRoutes);
