import {combineReducers} from "redux";
import productItems from "./products/reducers";
import salesReducer from "./sales/reducers";
import purchaseOrderReducer from "./purchaseOrders/reducers";
import contactReducer from "./contacts/reducers";
import materialReducer from "./materials/reducers";
import locationReducer from "./locations/locations";

const warehouseReducer = combineReducers({
  products: productItems,
  sales: salesReducer,
  purchase: purchaseOrderReducer,
  contacts: contactReducer,
  materials: materialReducer,
  locations: locationReducer,
});


export default warehouseReducer;
