import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import MaterialListItem from "./MaterialListItem";

const MaterialList = ({pagination, data, selected, onSelect, onDeselect}) => {
  const {
    itemsPaged,
    itemsRequested, // can be used as loading boolean
    itemsCount,
  } = pagination;

  const loading = itemsRequested && itemsPaged.length === 0;
  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: page => {
          // fetchPage(page);
        },
        total: itemsCount,
        showTotal: (total) => `Total ${total} items`,
        pageSize: 3,
        position: "top",
      }}
      loading={loading}
      dataSource={data}
      header={
        <div>
          <p>Available Materials</p>
        </div>
      }
      renderItem={item => (
        <MaterialListItem
          key={item.id}
          item={item}
          isSelected={selected.includes(item.id)}
          loading={loading}
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
      )
      }
    />
  );
};

MaterialList.propTypes = {
  productList: PropTypes.array,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  loading: PropTypes.bool,
  selectedProductID: PropTypes.number
};

export default MaterialList;
