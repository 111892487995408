/* Returns the complete list of items returned from getAllItems operation. */
import moment from "moment";

function refreshSchedulePmOrderList(state) {
  if (state.items) {
    let items = [];
    state.items.forEach(it => {
      if (it.date) {
        let name = it.stockroom_asset ? it.stockroom_asset.name : "";
        if (name === "") {
          name = it.asset ? it.asset.name : "";
        }
        items.push({
          // Add 6 hours to the date to account for timezone
          // TODO: Come up with more robust solution
          "date": moment(it.date).add(6, "hours"),
          "title": name || "Undefined",
          "id": it.id
        });
      }
    });
    return items;
  }
  return [];
}

const refreshScheduleList = (state, id) => {
  if (state.schedules && state.schedules[id]) {
    return state.schedules[id];
  }
  return [];
};

/* Returns boolean value of filteringBy state. */
const filteringBy = state => state.filteringBy;

export default {
  refreshSchedulePmOrderList,
  refreshScheduleList,
  filteringBy
};
