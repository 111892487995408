import React from "react";
import {Tag} from "antd";
import {
  updatedAtColumn,
  createdAtColumn,
  viewMoreColumn,
  numberOfNotesColumn,
  nameColumn
} from "../../../utils/tables";
import {USER_TYPES} from "./data";
import {SearchOutlined} from "@ant-design/icons";
import {stringSorter} from "../../../utils/sort";

const userTypeOptions = USER_TYPES.map(userType => ({text: userType, value: userType}));

// Configure the columns of the table
const columns = [
  nameColumn({width: 100}),
  {
    title: "Title",
    dataIndex: "title",
    width: 100,
    elipsis: true,
    searchable: true,
    sorter: stringSorter("title")
  },
  {
    title: "Skills has/need",
    dataIndex: "skills",
    width: 100,
    render: skills => (
      <span>
        {skills.map((skill, key) => {
          return (
            <Tag key={key} color={skill.color}>
              {skill.name.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
    sorter: {compare: (a, b) => a.skills.length - b.skills.length}
  },
  {
    title: "User Type",
    dataIndex: "user_type",
    width: 100,
    sorter: stringSorter("user_type"),
    filters: userTypeOptions,
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? "#485fe0" : undefined}} />,
    onFilter: (value, record) => record.user_type.indexOf(value) === 0,
  },
  numberOfNotesColumn({width: 75}),
  createdAtColumn({width: 75}),
  updatedAtColumn({width: 75}),
  viewMoreColumn("personnel")
];

export default columns;
