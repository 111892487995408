import React from "react";
import {Table} from "../../Common/Tables/Table";
import columns from "./columns.js";
import {pmOrderTableData} from "./data";

const PMOrdersTable = (props) => {
  return (
    <Table
      handleFieldChange={props.handleFieldChange}
      columns={columns}
      tableData={pmOrderTableData}
      {...props}
    />
  );
};

export {PMOrdersTable};
