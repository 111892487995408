import React, {Component} from "react";
import ReactTooltip from "react-tooltip";

import {Button, DetailsCloseIcon, ErrorHeading, Flex, Heading, Loading} from "../../Common/Base";
import {Redirect} from "react-router-dom";
import {deleteAxios} from "../../../utils/authWithAxios";
import {PersonnelForm, TagInput} from "../../Common/Forms";
import {PersonnelScheduleForm} from "../../Common/Forms/ScheduledShift";
import {AdminControlsDetails, AttachedFiles, Container, LocationSection, Modal, NoteSection, SnackBar} from "../../Common";
import {PMOrderHistory, VendorOrderHistory} from "../../../containers";
import {message} from "antd";
import {
  AZURE_BLOB_CONTAINER_NAME,
  AZURE_PERSONNEL_PREFIX,
  handleChangeDisplayImage,
  handleChangeWarrantyDocument,
  listBlobsInAzureContainer,
  removeDocument,
  uploadBlobsToAzureWithFileNameCheck,
} from "../../../utils/azure";
import {setSnackMessage} from "../../../utils/snackUtils";
import {preSaveFormat} from "../../Common/Forms/PersonnelForm/utils";
import {baseDetailsState, checkForResponseError, handleErrorResponse, scrollToTop} from "../../../utils/containerUtils";
import {DetailsHeader, DetailsPaneLeft, DetailsPaneRight, ImageWithDefault} from "../../Common/Details/Layout";
import {DescriptionText} from "../../Common/Modals/styled";
import authSelectors from "../../../store/auth/selectors";

/* This presents the details for a particular personnel. The imported personnel ID is acquired via the URL. */
class PersonnelDetails extends Component {
  constructor(props) {
    super(props);
    this.personnelID = props.match.params.personnelID;
    this.state = {
      personnelInvitationPending: false,
      invitationRequestLoading: false,
      invitationEmail: "",
      personnelSkills: [],
      personnelDesiredSkills: [],
      personnelVendorHistory: [],
      personnelPMOrderHistory: [],
      selectedPersonnelTitle: {},
      newPersonnelTitle: {
        name: "",
        id: "",
      },
      deletedPersonnel: false,
      deletePersonnelModalShowing: false,
      snackMsg: "",
      ...baseDetailsState("personnel")
    };
  }

  /* Upon mounting, fetch data for the specific personnel by personnelID and store the data in state. */
  componentDidMount = async () => {
    const {personnelDetails, getPersonnelList, getSchedules, getOrg, getLocations, getShifts, getPersonnelTitles} = this.props;
    if (personnelDetails) {
      this.setState({
        personnelID: personnelDetails._id,
        personnelName: personnelDetails.name,
        personnelOrgID: personnelDetails?.org?.id ?? "",
        personnelSkills: personnelDetails.skills,
        personnelDesiredSkills: personnelDetails.desired_skills,
        selectedPersonnelTitle: personnelDetails.personnel_title,
        showLoader: false,
      });
    }
    await this.fetchPersonnelDetails();
    const {personnelOrgID} = this.state;
    if (personnelOrgID) {
      getOrg(personnelOrgID);
      getLocations(personnelOrgID);
      getPersonnelTitles(personnelOrgID);
    }
    getSchedules();
    getPersonnelList();
    getShifts(this.personnelID);
    scrollToTop();
  };


  /* This function will handle the fetching of details for a personnel. */
  fetchPersonnelDetails = async () => {
    const {fetchDetails} = this.props;
    // const data = res.payload;

    await fetchDetails(this.personnelID)
      .then(res => {
        console.log(res);
        checkForResponseError(res);
        let data = res.payload;
        this.setState({
          personnelData: data,
          personnelID: data.id,
          personnelUUID: data.UUID || data.uuid,
          personnelName: data.name,
          personnelOrgID: data.default_org.id,
          personnelSkills: data.skills || [],
          personnelDesiredSkills: data.desired_skills || [],
          personnelInvitationPending: data.invitation_pending || false,
          personnelVendorHistory: data.vendor_order_history,
          personnelPMOrderHistory: data.pm_order_history,
          selectedPersonnelTitle: data.title || {},
          displayImage: data.display_image,
          warrantyDocument: data.warranty_document,
          editAccess: data?.permissions?.write ?? false
        });
        this.getPersonnelDocuments();
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        handleErrorResponse(this, error, "Personnel");
      }).then(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  /* This is passed to the redux-form which returns all values passed in from state */
  submit = async values => {
    console.log("values", values);
    const {updateDetails} = this.props;
    let {submitting, personnelID, personnelData} = this.state;

    if (!submitting) {
      this.setState({submitting: true});

      let res = await updateDetails(personnelID, preSaveFormat(values));
      personnelData.location = res.location;
      this.setState({
        personnelData: personnelData,
        submitting: false,
      });
    }
  };

  handleSubmitScheduleForm = async (id, data) => {
    const {assignPersonnelToSchedule} = this.props;
    this.setState({submitting: true});
    let res = await assignPersonnelToSchedule(id, data);
    console.log("schedule res", res);
    await this.fetchPersonnelDetails();
    this.setState({submitting: false});
  };

  /* This function will handle deletion of a personnel. */
  handleDeletePersonnel = () => {
    this.setState({
      adminControlsSubmitting: true,
      adminControlsMessage: "",
    });
    deleteAxios({
      url: `${global.base_url}v1/personnel/${this.personnelID}/`
    })
      .then(res => {
        console.log(res);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Personnel deletion successful.",
          deletedPersonnel: true,
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Error occurred during personnel deletion.",
          deletePersonnelModalShowing: false,
        });
      });
  };

  getPersonnelDocuments = () => {
    const {personnelUUID} = this.state;
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_PERSONNEL_PREFIX}/${personnelUUID}/`).then(files => {
      this.setState({
        personnelFiles: files,
      });
    });
  };

  handleChangeDisplayImageField = () => {
    const {personnelUUID} = this.state;
    uploadBlobsToAzureWithFileNameCheck(personnelUUID, AZURE_PERSONNEL_PREFIX, [this.refs.fileUploader.files[0]], "")
      .then(async res => {
        // Res contains filenames from uploaded files, use res[0] because only one file was uploaded
        await this.handleChangeDisplayImageNoUpload(res[0]);
        this.fetchPersonnelDetails();
      });
  };

  handleChangeDisplayImageNoUpload = async imageName => {
    await handleChangeDisplayImage(this, imageName, this.personnelID, this.props.updatePersonnelField);
  };

  handleChangeWarrantyDocumentField = async documentName => {
    await handleChangeWarrantyDocument(this, documentName, this.personnelID, this.props.updatePersonnelField);
  };

  handleRemoveDocument = documentName => {
    removeDocument(this, documentName, this.personnelID, this.props.updatePersonnelField, this.getPersonnelDocuments);
  };


  handleInvitationEmailChange = e => {
    this.setState({invitationEmail: e.target.value});
  };

  handleSendInvitation = () => {
    const {personnelID, invitationEmail} = this.state;
    let re = /\S+@\S+\.\S+/;
    if (!re.test(invitationEmail)) {
      message.error("Invalid email");
      return;
    }
    this.setState({invitationRequestLoading: true});
    this.props.sendInvitationEmail(personnelID, invitationEmail).then((res) => {
      if (!res.error) {
        this.setState({personnelInvitationPending: true, invitationRequestLoading: false});
      }
    });
  };

  handleCancelInvitation = () => {
    const {personnelID} = this.state;
    this.setState({invitationRequestLoading: true});
    this.props.cancelInvitationEmail(personnelID).then((res) => {
      this.setState({personnelInvitationPending: res.error, invitationRequestLoading: false});
    });
  };

  updateSkills = skills => {
    this.props.updateDetails(this.personnelID, {"skills": skills});
    this.setState({
      personnelSkills: skills,
    });
  };

  updateDesiredSkills = desiredSkills => {
    this.props.updateDetails(this.personnelID, {"desired_skills": desiredSkills});
    this.setState({
      personnelDesiredSkills: desiredSkills,
    });
  };

  handlePersonnelTitleSelect = data => {
    const {personnelData} = this.state;
    this.setState({
      selectedPersonnelTitle: data,
      personnelData: {
        ...personnelData,
        title: data
      }
    });
  };
  handleShowCreatePersonnelTitle = () => {
    this.setState({
      newPersonnelTitle: {
        name: "",
        id: "",
      },
    });
  };
  handleShowEditPersonnelTitle = () => {
    this.setState({
      newPersonnelTitle: this.state.selectedPersonnelTitle,
    });
  };
  handleNewPersonnelTitleNameChange = (event) => {
    this.setState({
      newPersonnelTitle: {
        name: event.target.value,
        id: this.state.newPersonnelTitle.id,
      },
    });
  };
  handleCreatePersonnelTitle = async (name, color) => {
    const {createPersonnelTitle} = this.props;
    const res = await createPersonnelTitle(this.state.personnelOrgID, name, color);
    // if the request is successful, automatically select the newly created title
    if (res && res.payload) {
      const {id, name} = res.payload;
      this.handlePersonnelTitleSelect({id, name});
      this.submit({title: id});
    }
  };
  handleUpdatePersonnelTitle = (personnel_title_id, name) => {
    const {updatePersonnelTitle} = this.props;
    updatePersonnelTitle(this.state.personnelOrgID, personnel_title_id, name);
    this.setState({
      selectedPersonnelTitle: this.state.newPersonnelTitle,
    });
  };
  handleDeletePersonnelTitle = () => {
    const {deletePersonnelTitle} = this.props;
    const {id} = this.state.selectedPersonnelTitle;
    const {personnelData} = this.state;
    deletePersonnelTitle(this.state.personnelOrgID, id);
    this.setState({
      selectedPersonnelTitle: {
        name: "",
        id: "",
      },
      personnelData: {
        ...personnelData,
        title: {name: "", id: ""}
      }
    });
  };
  toggleDeletePersonnelModal = () => {
    if (this.props.accountInfo.personnel.id === this.state.personnelID) {
      setSnackMessage((msg) => this.setState({snackMsg: msg}), "You can't delete your own personnel");
    } else {
      this.setState({deletePersonnelModalShowing: !this.state.deletePersonnelModalShowing});
    }
  };

  render() {
    const {
      personnelData,
      personnelID,
      personnelUUID,
      personnelName,
      showLoader,
      personnelSkills,
      personnelDesiredSkills,
      adminControlsSubmitting,
      adminControlsMessage,
      personnelVendorHistory,
      personnelPMOrderHistory,
      selectedPersonnelTitle,
      newPersonnelTitle,
      errorLoadingMessage,
      deletedPersonnel,
      deletePersonnelModalShowing,
      personnelInvitationPending,
      invitationRequestLoading,
      personnelFiles,
      displayImage,
      warrantyDocument,
      snackMsg,
      editAccess,
      invitationEmail
    } = this.state;

    const {
      accountInfo,
      personnelTitleList,
      personnelList,
      scheduleList,
      getRecentLogs,
      notes,
      fetchNotes,
      addNote,
      locationList,
      shifts,
      getShifts
    } = this.props;
    if (deletedPersonnel) return <Redirect to={"/dashboard/personnel"}/>;
    const pmOrdersEnabled = authSelectors.moduleEnabled(accountInfo, "pm_orders");
    const vendorOrdersEnabled = authSelectors.moduleEnabled(accountInfo, "vendor_orders");

    return (
      <div>
        <Container>
          {personnelID && (
            <Flex className="item-details" flexDirection={["column", "row"]}>
              <DetailsCloseIcon href={"/#/dashboard/personnel"} dataTip={"Close and return to personnel."}/>
              <DetailsPaneLeft
                className="item-details-pane-left"
              >
                <ImageWithDefault src={displayImage} />
                <input type="file" accept="image/*" id="file" ref="fileUploader"
                  onChange={this.handleChangeDisplayImageField}
                  style={{display: "none"}}/>
                <Button className="item-details-form-button"
                  onClick={() => this.refs.fileUploader.click()}>
                  Change Display Image
                </Button>
                <div className="item-details-pane-left-text">
                  <ReactTooltip/>
                  <Heading color={"colorPrimary"} display="inline">SKILLS</Heading>
                  <span><div className="tooltip-circle"
                    data-tip="Press enter or , to add a skill. Press backspace to edit a previous skill."/></span>
                  <br/>
                  <TagInput handleUpdate={this.updateSkills} tagList={personnelSkills}/>
                  <br/>
                  <ReactTooltip/>
                  <Heading color={"colorPrimary"} display="inline">DESIRED SKILLS</Heading>
                  <span><div className="tooltip-circle"
                    data-tip="Press enter or , to add a skill. Press backspace to edit a previous skill."/></span>
                  <br/>
                  <TagInput handleUpdate={this.updateDesiredSkills} tagList={personnelDesiredSkills}/>
                  <br/>
                  <Heading color={"colorPrimary"}>LOCATION</Heading>
                  <LocationSection
                    blockButton
                    locationData={personnelData.location || {}}
                    locationList={locationList}
                    submit={this.submit}
                    isAdmin={accountInfo.user_type <= 1}
                  />
                  <br/>

                  {(accountInfo.user_type === 1) ?
                    <AdminControlsDetails
                      deleteItem={this.toggleDeletePersonnelModal}
                      controlsSubmitting={adminControlsSubmitting}
                      controlsMessage={adminControlsMessage}
                    /> : null
                  }


                  {personnelData.needs_approval && !personnelData.invitation_pending &&
                  <Button id="approveUserButton" className="item-details-pane-left-control"
                    onClick={() => this.submit({needs_approval: false})}>
                    Approve User
                  </Button>
                  }
                </div>
              </DetailsPaneLeft>
              <DetailsPaneRight
                className="item-details-pane-right"
              >
                <div className="item-details-pane-right-text">
                  <DetailsHeader>PERSONNEL DETAILS</DetailsHeader>
                  <PersonnelForm
                    onSubmit={this.submit}
                    values={personnelData}
                    editAccess={editAccess}
                    personnelTitles={personnelTitleList}
                    invitationEmail={invitationEmail}
                    handleInvitationEmailChange={this.handleInvitationEmailChange}
                    handleSendInvitation={this.handleSendInvitation}
                    handleCancelInvitation={this.handleCancelInvitation}
                    invitationPending={personnelInvitationPending}
                    invitationRequestLoading={invitationRequestLoading}
                    personnelList={personnelList}
                    selectedPersonnelTitle={selectedPersonnelTitle}
                    newPersonnelTitle={newPersonnelTitle}
                    handleRole={this.updateRole}
                    handleType={this.updateType}
                    handleClassification={this.updateClassification}
                    handlePersonnelTitleSelect={this.handlePersonnelTitleSelect}
                    handleShowCreatePersonnelTitle={this.handleShowCreatePersonnelTitle}
                    handleShowEditPersonnelTitle={this.handleShowEditPersonnelTitle}
                    handleCreatePersonnelTitle={this.handleCreatePersonnelTitle}
                    handleUpdatePersonnelTitle={this.handleUpdatePersonnelTitle}
                    handleDeletePersonnelTitle={this.handleDeletePersonnelTitle}
                    handleNewPersonnelTitleNameChange={this.handleNewPersonnelTitleNameChange}
                  />

                  <PersonnelScheduleForm
                    personnel={personnelData}
                    scheduleList={scheduleList}
                    editAccess={editAccess}
                    shifts={shifts}
                    submitScheduleForm={this.handleSubmitScheduleForm}
                    getShifts={getShifts}
                    submitDateRange={(values) => getShifts(this.personnelID, values)}
                  />

                  <AttachedFiles
                    files={personnelFiles}
                    displayImage={displayImage}
                    warrantyDocument={warrantyDocument}
                    azureDocumentPrefix={AZURE_PERSONNEL_PREFIX}
                    UUID={personnelUUID}
                    getDocuments={this.getPersonnelDocuments}
                    handleChangeDisplayImage={this.handleChangeDisplayImageNoUpload}
                    handleChangeWarrantyDocument={this.handleChangeWarrantyDocumentField}
                    handleRemoveDocument={this.handleRemoveDocument}
                    refreshObject={this.fetchPersonnelDetails}/>

                  <NoteSection
                    UUID={personnelUUID}
                    accountInfo={accountInfo}
                    documentID={personnelID}
                    handleSubmit={addNote}
                    refreshObject={() => fetchNotes(personnelID)}
                    azureDocumentPrefix={AZURE_PERSONNEL_PREFIX}
                    getDocuments={this.getPersonnelDocuments}
                    notes={notes}
                    files={personnelFiles}
                    getRecentLogs={getRecentLogs}
                    title={"PERSONNEL NOTES"}
                    endpoint={"personnel"}
                  />

                  <br/>
                  {pmOrdersEnabled && <PMOrderHistory history={personnelPMOrderHistory}/>}
                  {vendorOrdersEnabled && <VendorOrderHistory history={personnelVendorHistory}/>}
                </div>
              </DetailsPaneRight>
            </Flex>
          )}
        </Container>
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}

        {showLoader && <Loading title={"Loading Personnel..."}/>}
        <Modal
          visible={deletePersonnelModalShowing}
          title={`Delete ${personnelName || "Personnel"}?`}
          onCancel={this.toggleDeletePersonnelModal}
          onOk={this.handleDeletePersonnel}
          isDeleteModal
        >
          <DescriptionText>Deleting this personnel will remove it from all PM and Vendor Orders.</DescriptionText>
        </Modal>
        {snackMsg !== "" && <SnackBar title={snackMsg} status={"error"}/>}
      </div>
    );
  }
}

export default PersonnelDetails;
