// Sales Order Details Header
import React from "react";
import PropTypes from "prop-types";
import {Descriptions} from "antd";
import {PageHeader, LocationDetails} from "Common/Details";
import {Link, DirectLink} from "Common/Base";
import {PURCHASE_ORDERS_DASHBOARD_ROUTE} from "../../../../routes/constants";
import SalesOrderForm from "../../Forms/SalesOrderForm/SalesOrderForm";

const DetailsHeader = ({
  data, actions, onDelete, locationData, onUpdate, locationSection, customers, createCustomer, updateFiles, purchaseOrders
}) => {
  const {reference, customer, total_cost, currency, purchase_order} = data;

  const renderDetails = () => {
    return (
      <Descriptions size="small" column={{md: 3, sm: 2, xs: 1}} className={"page-details"}>
        <Descriptions.Item label="Reference #">{reference}</Descriptions.Item>
        <Descriptions.Item label="Customer">{customer ? customer.name : ""}</Descriptions.Item>
        <Descriptions.Item label={"Total Cost"}>{total_cost}</Descriptions.Item>
        <Descriptions.Item label={"Currency"}>{currency}</Descriptions.Item>
        <Descriptions.Item label={"Order"}>
          {data?.order ? <DirectLink href={data.order} onClick={e => e.stopPropagation()}>View Order Document</DirectLink> : "None"}
        </Descriptions.Item>
        <Descriptions.Item label={"Invoice"}>
          {data?.invoice ? <DirectLink href={data.invoice} onClick={e => e.stopPropagation()}>View Invoice</DirectLink> : "None"}
        </Descriptions.Item>
        <Descriptions.Item label={"Purchase Order"}>
          {purchase_order ?
            <Link to={`${PURCHASE_ORDERS_DASHBOARD_ROUTE}/${purchase_order.id}`}>
              {purchase_order?.reference ?? purchase_order.id}
            </Link> : "None"
          }
        </Descriptions.Item>
      </Descriptions>
    );
  };


  return (
    <PageHeader
      data={data}
      DetailsForm={SalesOrderForm}
      formProps={{
        customers: customers,
        createCustomer: createCustomer,
        updateFiles: updateFiles,
        purchaseOrders: purchaseOrders
      }}
      onUpdate={onUpdate}
      onDelete={onDelete}
      renderDescription={renderDetails}
      actions={actions}
      text={{
        title: "Sales Order Details",
        name: "Sales Order",
        shortName: "order",
      }}
      editConfig={{
        formID: "sales-order-form",
      }}
      deleteConfig={{
        confirmText: "Deleting this will remove all items assigned to it.",
        redirectUrl: "/dashboard/warehouse/sales_orders",
      }}
    >
      <LocationDetails
        addButton={locationSection}
        region={locationData.region}
        district={locationData.district}
        facility={locationData.facility}
        wing={locationData.wing}
        zone={locationData.zone}
        subzone={locationData.subzone}
      />
    </PageHeader>
  );
};

DetailsHeader.propTypes = {
  data: PropTypes.object,
  onMenuClick: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  createCustomer: PropTypes.func.isRequired,
  actions: PropTypes.array,
  locationData: PropTypes.any,
  customers: PropTypes.array
};

export default DetailsHeader;
