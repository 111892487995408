import actions from "./actions";
import {baseOperations} from "../base";
import types from "./types";
import {dateUtils, snackUtils} from "../../utils";
import base from "../base/actions";
import {name as endpoint} from "./index";

/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getAllItems = () => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});

/* This Function handles sending an Item update request to the API.
Then dispatches either the updateSuccess or updateFailure action.
Also dispatches snackbar messages on start and finish.
*/
const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Personnel", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log("res", res, "data", data);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "personnel", res.payload.name, dispatch, () => {
  });
  return res.payload;
};

/* This Function handles sending an Item update request to the API
for a specific field. Then dispatches either the updateSuccess or
updateFailure action. Also dispatches snackbar messages on start
and finish. */
const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateItem(id, fieldData));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Personnel", res.payload.name, dispatch, () => {
    dispatch(getAllItems());
  });
};

const uploadCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch, getAllItems);
};

/* This Function handles adding a new item to the current parent item.
The first example of this is adding a note to an asset. */
const addNote = (id, data) => async dispatch => {
  const res = await dispatch(actions.submitNote(id, data));
  baseOperations.handleSubmitNoteResponse(res, dispatch);
  return res.payload;
};

const bulkDelete = (data) => async dispatch => {
  const res = await dispatch(actions.bulkDelete(data));
  console.log("BULK OPERATIONS: ", res);
  dispatch(getAllItems());
};

const sendInvitationEmail = (id, email) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack(`Sending Invitation to ${email}`, 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.sendInvitationEmail(id, email));
  baseOperations.handleGenericResponse(
    res,
    dispatch,
    `You don't have permission to send invitation to ${email}`,
    `Failed to send invitiation to ${email}`,
    `Successfully sent invitation to ${email}`
  );
  return res;
};

const cancelInvitationEmail = (id, email) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Cancelling invitation", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.cancelInvitationEmail(id, email));
  baseOperations.handleGenericResponse(
    res,
    dispatch,
    "You don't have permission to cancel this email",
    "Failed Cancelling Invitation",
    "Successfully Cancelled Invitation"
  );
  return res;
};

export default {
  getAllItems,
  updateItem,
  updateItemField,
  uploadCsv,
  addNote,
  sendInvitationEmail,
  cancelInvitationEmail,
  bulkDelete
};
