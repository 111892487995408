import React from "react";
import PropTypes from "prop-types";
import {Form, Field} from "react-final-form";
import {Button} from "../../Common/Base";

const PasswordResetForm = props => {
  const {
    onSubmit,
    values,
  } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form
          onSubmit={handleSubmit}>
          <Field
            component={"input"}
            className={"login-card-input"}
            placeholder={"Email..."}
            name="email"
          />
          <Button
            className="login-card-button"
            htmlType="submit"
            // type={'pr'}
            value="Submit"
            size={"large"}
            loading={submitting}>
            Submit
          </Button>
        </form>
      )}
    />
  );
};

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,

};

export default PasswordResetForm;
