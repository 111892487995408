import {FlexCenter, H2} from "./styled";
import {SingleDatePicker} from "./Datepicker";
import {Row, Column} from "./Flex";
import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button as ChakraButton,
  ButtonGroup,
  CloseButton,
  Collapse,
  Divider,
  Flex,
  FormLabel as Label,
  Grid,
  IconButton,
  Image,
  Input,
  ListIcon,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  useToast as Toast,
} from "@chakra-ui/core";
import AntComponents from "./AntComponents";
import TooltipButton from "./Button/TooltipButton";
import {ToggleButton, PopConfirmButton} from "./Button";


export {CloseIcon, DetailsCloseIcon, HelpIcon} from "./Icons";


const {
  AntdCollapse,
  AntdModal,
  AntdText,
  Button,
  Card,
  Content,
  Descriptions,
  Drawer,
  Form,
  InputNumber,
  Layout,
  List,
  Menu,
  Pagination,
  Panel,
  Popconfirm,
  Tag,
  RangePicker,
  Search,
  Sider,
  Skeleton,
  Space,
  Spin,
  SubMenu,
  Table,
  TabPane,
  Tabs,
  TimePicker,
  Tooltip,
  Typography,
  Checkbox,
  Statistic,
  Title
} = AntComponents;

export {default as Container} from "./Container";
export {default as ErrorBoundary} from "./ErrorBoundary";
export {default as ListItem} from "./Lists/ListItem";
export {Heading, ErrorHeading} from "./Heading";
export {default as Loading} from "./Loading";
export {ErrorText, Text} from "./Text";
export {default as Popover} from "./Popover/Popover";
export {default as NumberInput} from "./Inputs/NumberInput";
export {default as SimplePagination} from "./Pagination";
export {default as Datepicker} from "./Datepicker";
export {DirectLink, Link} from "./Link";
export {default as OptionalLink} from "./OptionalLink";
export {BetaSwitch} from "./BetaSwitch";


export {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AntdCollapse,
  AntdModal,
  AntdText,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  ChakraButton,
  Checkbox,
  CloseButton,
  Collapse,
  Column,
  Content,
  Descriptions,
  Divider,
  Drawer,
  Flex,
  FlexCenter,
  Form,
  Grid,
  H2,
  IconButton,
  Image,
  Input,
  InputNumber,
  Label,
  Layout,
  List,
  ListIcon,
  Menu,
  Pagination,
  Panel,
  PopConfirmButton,
  Popconfirm,
  RangePicker,
  Row,
  Search,
  Sider,
  SingleDatePicker,
  Space,
  Spin, Skeleton,
  Stack,
  Stat,
  Statistic,
  StatGroup,
  StatLabel,
  StatNumber,
  SubMenu,
  Table,
  TabPane,
  Tabs,
  Tag,
  TimePicker,
  Toast,
  ToggleButton,
  Tooltip,
  TooltipButton,
  Typography,
  Title
};
