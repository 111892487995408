
if (process.env.REACT_APP_ENV === "development") {
  global.base_url = "http://localhost:8000/";
  global.base_socket_url = "ws://localhost:8000";
  global.server_domain = "localhost:8000";
} else {
  global.base_url = `https://api.${process.env.REACT_APP_BASE_URL}/`;
  global.base_socket_url = `wss://api.${process.env.REACT_APP_BASE_URL}`;
  global.server_domain = `.${process.env.REACT_APP_BASE_URL}`;
}

