import React, {Component} from "react";
import PropTypes from "prop-types";
import WebSocketInstance from "./WebSocket";

import {connect} from "react-redux";
import {authOperations, authSelectors} from "../store";

function mapStateToProps(state) {
  return {
    accessExpired: authSelectors.isAccessTokenExpired(state.auth),
    refreshToken: authSelectors.refreshToken(state.auth)
  };
}

const mapDispatchToProps = dispatch => ({
  handleTokenRefresh: (token) => dispatch(authOperations.refreshAccessToken(token))
});

class ActivityLogListener extends Component {
  state = {
    data: {},
  };

  componentDidMount() {
    this.handleWebsocket();
  }

  handleWebsocket = async () => {
    await this.handleTokenRefresh();
    WebSocketInstance.connect("/activity_logs/");
    this.waitForSocketConnection(() => {
      WebSocketInstance.addCallbacks(this.handleUpdate.bind(this), this.addMessage.bind(this));
    });
  };

  handleTokenRefresh = async () => {
    const {accessExpired, refreshToken, handleTokenRefresh} = this.props;
    if (accessExpired) {
      await handleTokenRefresh(refreshToken);
    }
  }

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(
      function() {
        // Check if websocket state is OPEN
        if (WebSocketInstance.state() === 1) {
          console.info("Connection is made");
          callback();
          return;
        } else {
          console.info("wait for connection...");
          component.waitForSocketConnection(callback);
        }
      }, 100); // wait 100 milisecond for the connection...
  }

  handleUpdate(data) {
    const {onReceive} = this.props;
    console.log("update data", data);
    onReceive && onReceive(data);
    this.setState({data});
  }

  addMessage(message) {
    console.log("message", message);
    this.setState({messages: [...this.state.messages, message]});
  }

  render() {
    return (
      <div className={"activity-log-listener"}/>
    );
  }
}

ActivityLogListener.propTypes = {
  onReceive: PropTypes.func
};

ActivityLogListener.defaultProps = {
  onReceive: (data) => console.log("recived", data)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLogListener);
