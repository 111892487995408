import moment from "moment";

/**
 * Gets all items with the matching name
 * @param {Array<object>} list The objects to filter through
 * @param {string} filter The name to look for
 * @returns {Array} Array of items that match the name
 */
const filterListByName = (list, filter) => {
  const newFilter = filter.split(" ");
  let newList = list;
  if (filter !== "" && filter !== undefined) {
    newList = newList.filter(item => {
      let includes = false;
      let includesTags = false;

      // Make sure item has name property first
      if (typeof item.name === "string") {
        includes = true;
        for (let i = 0; i < newFilter.length; i += 1) {
          const query = newFilter[i].toLowerCase();
          if (!item.name.toLowerCase().includes(query) &&
            (item.description ? !item.description.toLowerCase().includes(query) : true) &&
            (item.manufacturer_name ? !item.manufacturer_name.toLowerCase().includes(query) : true)) {
            includes = false;
          }
          if (!includes && item.tags) {
            const {tags} = item;
            for (let j = 0; j < tags.length; j += 1) {
              if (tags[j].toLowerCase().includes(query)) {
                includesTags = true;
              }
            }
          }
        }
      }

      return includes || includesTags;
    });
  }

  return newList;
};

/**
 * Gets all items with the matching title
 * @param {Array<object>} list The objects to filter through
 * @param {string} filter The title to look for
 * @returns {Array} Array of items that match the title
 */
const filterListByTitle = (list, filter) => {
  const newFilter = filter.split(" ");
  const newList = list.filter(item => {
    let includes = false;

    // Make sure item has title property first
    if (typeof item.title === "string") {
      includes = true;
      for (let i = 0; i < newFilter.length; i += 1) {
        const query = newFilter[i].toLowerCase();
        if (!item.title.toLowerCase().includes(query)) {
          includes = false;
        }
      }
    }
    return includes;
  });

  return newList;
};

/**
 * Gets all items with the matching manufacturer name
 * @param {Array<object>} list The objects to filter through
 * @param {string} filter The manufacturer name to look for
 * @returns {Array} Array of items that match the manufacturer
 */
const filterListByManufacturer = (list, filter) => {
  const newFilter = filter.split(" ");
  const newList = list.filter(item => {
    let includes = false;

    // Make sure item has manufacturer_name property first
    if (typeof item.manufacturer_name === "string") {
      includes = true;
      for (let i = 0; i < newFilter.length; i += 1) {
        const query = newFilter[i].toLowerCase();
        if (!item.manufacturer_name.toLowerCase().includes(query)) {
          includes = false;
        }
      }
    }
    return includes;
  });
  return newList;
};

/**
 * Searches for items related to search term in the search bar
 * @param {Array} list The state of the item/items you are searching
 * @param {string} filter The search term being used to search the object
 * @returns {Array} Array of items that match the search term
 */
const filterListByAll = (list, filter) => {
  const newList = list.filter(item => {
    let includes = false;
    let regex = new RegExp(filter, "i");
    let values = Object.values(item);

    values.forEach(value => {
      switch (typeof value) {
      case "string":
        includes = includes || regex.test(value);
        break;
      case "object":
        if (value !== null) {
          let nestedValues = Object.values(value);
          // searches the second level values of the item
          nestedValues.forEach(nestedValue => {
            if (typeof nestedValue === "string") {
              includes = includes || regex.test(value);
            }
          });
        }
        break;
      default:
        break;
      }
    });
    return includes;
  });
  return newList;
};

/**
 * Filter a list of objects by a property that is passed the date provided.
 * @param {Array} list list of the items
 * @param {string} field property name of the date item in the list
 * @param {moment.Moment} date date to compare the item with
 * @param {Function} compare filter comparison function between 2 dates objects
 * @returns {Array} The filtered list
 */
const filterListByDate = (list, field, date, compare) => {
  return list.filter(item => compare(moment(item[field]), date));
};

/**
 * Filter a list of objects by a property that is passed the date provided.
 * @param {Array} list list of the items
 * @param {string} key property name of the item in the list
 * @param {Function} filter filter function
 * @returns {Array} The filtered list
 */
const filterListByKey = (list, key, filter) => {
  return list.filter(item => filter(item[key]));
};

/**
 * Filter a list of objects by its stockroom_assets that they need to have Issue
 * @param {Array} list The list of objects
 * @returns {Array} All stockroom assets with a reported issue
 */
const filterListByStockroomReported = (list) => {
  return list.filter(item => item.stockroom_assets.filter(
    stockroom_asset => stockroom_asset.status !== null && stockroom_asset.status.name === "Issue Reported"
  ).length > 0);
};


export default {
  filterListByAll,
  filterListByName,
  filterListByTitle,
  filterListByManufacturer,
  filterListByDate,
  filterListByKey,
  filterListByStockroomReported
};
