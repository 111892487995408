import React, {Fragment, useEffect, useState} from "react";
import {Box, Heading, Input} from "../../Base";
import {Form} from "react-final-form";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {MapComponent} from "./Map";

import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";

import {checkNestedKeys} from "../../../../utils/propUtils";
import {Modal} from "../../index";
import LocationGroup from "./LocationGroup";
import {Row, SecondaryButton} from "../styled";
import {DISTRICTS_STR, FACILITIES_STR, REGIONS_STR, SUBZONES_STR, WINGS_STR, ZONES_STR} from "./constants";
import {coordinatesToDjangoLocation, findLocationByCoordinates} from "../../../../utils/location";
import {DescriptionText} from "../../Modals/styled";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
  subheader: {
    backgroundColor: "white"
  }
}));

const LocationForm = (props) => {
  const [isEditing, setEditing] = useState(false);
  const [isAddingNewLevel, setAddingNewLevel] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteLocationModalShowing, setDeleteLocationModalShowing] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [locationNewLevelName, setLocationNewLevelName] = useState();
  const [locationString, setLocationString] = useState("");
  const [locationCoordinates, setLocationCoordinates] = useState([]);
  const [locationType, setLocationType] = useState({
    name: "",
    thisLevel: "",
    nextLevel: "",
    id: "",
    coordinates: []
  });

  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    if (locationName !== locationType.name) {
      let location = locationType;
      location.name = locationName;
      setLocationType(location);
    }
  }, [locationName, locationType]);

  useEffect(() => {
    if (locationCoordinates !== checkNestedKeys(["location", "coordinates"], locationType)) {
      let location = locationType;
      location.coordinates = locationCoordinates;
      setLocationType(location);
    }
  }, [locationCoordinates, locationType]);

  const handleChange = (ev) => {
    const {value} = ev.target;
    setLocationName(value);
  };

  const handleEditItem = (locationToEdit, thisLevel, nextLevel, location) => {
    setLocationName(locationToEdit.name);
    const coords = location ? location.coordinates : undefined;
    setLocationCoordinates(coords);
    setLocationType({
      id: locationToEdit.id,
      name: locationToEdit.name,
      thisLevel: thisLevel,
      nextLevel: nextLevel,
      coordinates: coords
    });
    setEditing(true);
    if (isAddingNewLevel) {
      setAddingNewLevel(false);
    }
    if (coords) {
      findLocationByCoordinates(coords[1], coords[0]).then(data => setLocationString(data.display_name));
    }
  };

  const toggleAddingNewLevel = (e) => {
    if (e) {
      e.preventDefault();
    }
    setEditing(false);
    setAddingNewLevel(!isAddingNewLevel);
  };

  const toggleDeleteLocationModal = (e) => {
    // Prevent form from submitting
    if (e) {
      e.preventDefault();
    }
    setDeleteLocationModalShowing(!deleteLocationModalShowing);
  };

  const clearFields = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLocationName("");
    setLocationNewLevelName("");
    setLocationType({
      name: "",
      nextLevel: "",
      id: ""
    });
    setEditing(false);
    setAddingNewLevel(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    clearFields();
    setEditing(false);
  };

  const handleDelete = () => {
    const {deleteLocation, accountInfo} = props;
    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);
    const response = deleteLocation(orgId, locationType);
    if (response && !response.error) {
      clearFields();
      toggleDeleteLocationModal();
    }
  };

  const onSubmit = async () => {
    const {createLocation, updateLocation} = props;
    const orgId = checkNestedKeys(["accountInfo", "personnel", "default_org", "id"], props);
    if (orgId) {
      let response;
      if (isEditing) {
        const submitData = {
          "name": locationType.name,
          "level": locationType.thisLevel,
          "id": locationType.id,
        };
        // only add location property to submitData if coordinates are defined
        if (locationType.coordinates) submitData["location"] = coordinatesToDjangoLocation(locationType.coordinates);
        response = await updateLocation(orgId, submitData);
      } else {
        let submitData = locationType;
        if (isAddingNewLevel) {
          submitData = {
            "name": locationNewLevelName,
            "level": locationType.nextLevel,
            "id": locationType.id
          };
        } else {
          submitData["level"] = REGIONS_STR;
        }
        response = await createLocation(orgId, submitData);
      }
      if (response && !response.error) {
        // We don't want to clear all of the fields here because then the user will have
        // to navigate the location tree to add another location at the same level.
        setLocationNewLevelName("");
        if (isEditing) {
          setEditing(false);
          setLocationName("");
        }
      }
    }
  };

  const setMarker = (e, override = false) => {
    if (override || e.originalEvent.target.classList.contains("leaflet-grab")) {
      let marker = e.latlng;
      setLocationCoordinates([marker.lng, marker.lat]);
    }
  };

  const {locationList} = props;
  const classes = useStyles();
  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      {visible &&
      <Box className="admin-location-form">
        {locationList.length !== 0 &&
        <List className={classes.list}>
          <LocationGroup
            handleEdit={handleEditItem}
            items={props.locationList}
            updateLocation={props.updateLocation}
            accountInfo={props.accountInfo}
            levels={[REGIONS_STR, DISTRICTS_STR, FACILITIES_STR, WINGS_STR, ZONES_STR, SUBZONES_STR]}
            classes={classes}/>
        </List>
        }
        <Form
          onSubmit={onSubmit}
          initialValues={null}
          render={({handleSubmit, form, submitting, pristine, values}) => (
            <form className="location-form" onSubmit={handleSubmit}>
              <div className="center-contents-vertical">
                <Box>
                  <Input
                    backgroundColor="white"
                    placeholder={"New Region Name"}
                    value={locationName}
                    minWidth={100}
                    maxWidth={400}
                    type="text"
                    name="fieldNameNew"
                    disabled={isAddingNewLevel}
                    onChange={handleChange}
                    className="input-pane"
                    display="block"
                    margin="0 auto"
                  />
                  {locationType.thisLevel === FACILITIES_STR && !isAddingNewLevel &&
                  <Box my={"20px"}>
                    <Heading>Current Location: {locationString || "Unknown"}</Heading>
                    <MapComponent
                      setMarker={setMarker}
                      location={locationCoordinates ? locationCoordinates : [-92.62, 39.78]}/>
                  </Box>
                  }
                  {isAddingNewLevel &&
                    <Fragment>
                      <Box flexBasis={"100%"} height={"20px"}/>
                      <Input
                        backgroundColor="white"
                        placeholder={`New ${locationType.nextLevel} name`}
                        value={locationNewLevelName}
                        minWidth={100}
                        maxWidth={400}
                        type="text"
                        name="fieldNewLevel"
                        disabled={false}
                        onChange={e => setLocationNewLevelName(e.target.value)}
                        className="input-pane new-location-level"
                        display="block"
                        margin="20px auto 0"
                      />
                    </Fragment>
                  }
                </Box>
              </div>
              <div className="align-center">
                <ReactCSSTransitionGroup
                  transitionName="mapping"
                  transitionEnterTimeout={700}
                  transitionLeaveTimeout={700}>
                  {isAddingNewLevel ?
                    <Row justifyContent={"center"}>
                      <SecondaryButton type="primary" htmlType="submit" className="form-button">
                        Submit {locationType.nextLevel}
                      </SecondaryButton>
                      <SecondaryButton onClick={handleCancel} className="item-details-form-button-pane">
                        Cancel Edit
                      </SecondaryButton>
                    </Row>
                    :
                    <Row justifyContent={"center"}>
                      <SecondaryButton type={"primary"} htmlType="submit" className="form-button">
                        {isEditing ? "Edit " : "Create "}Location
                      </SecondaryButton>
                      {isEditing &&
                      <Fragment>
                        {locationType.nextLevel &&
                        <SecondaryButton onClick={toggleAddingNewLevel} className="form-button">
                          Add {locationType.nextLevel}
                        </SecondaryButton>
                        }
                        <SecondaryButton onClick={toggleDeleteLocationModal} className="form-button">
                          Delete Location
                        </SecondaryButton>
                      </Fragment>
                      }
                    </Row>
                  }
                </ReactCSSTransitionGroup>
              </div>
            </form>)}/>
        <Modal
          visible={deleteLocationModalShowing}
          title={`Delete ${locationType.name || "Location Type"}?`}
          onCancel={toggleDeleteLocationModal}
          onOk={handleDelete}
          isDeleteModal
        >
          <DescriptionText>Deleting this location type will remove it from all Assets and Personnel.</DescriptionText>
        </Modal>
      </Box>
      }
    </ReactCSSTransitionGroup>
  );
};

LocationForm.propTypes = {
  onSubmit: PropTypes.func,
  locationList: PropTypes.array,
  createLocation: PropTypes.func,
  deleteLocation: PropTypes.func,
  updateLocation: PropTypes.func
};

LocationForm.defaultProps = {
  accountInfo: {},
  allOrgs: [],
  locationList: []
};

export default LocationForm;
