import React from "react";
import {Tag} from "antd";
import {
  updatedAtColumn,
  createdAtColumn,
  viewMoreColumn,
  manufacturerColumn,
  nameColumn
} from "../../../utils/tables";
import {numberSorter} from "../../../utils/sort";

// Configure the columns of the table
const columns = [
  nameColumn({width: 150}),
  manufacturerColumn({width: 150}),
  {
    title: "SKU",
    dataIndex: "sku",
    width: 150,
    editable: true,
    searchable: true,
    sorter: numberSorter("sku")
  },
  {
    title: "Price",
    dataIndex: "price",
    width: 100,
    editable: true,
    sorter: numberSorter("price")
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    width: 150,
    render: quantities => (
      <span>
        {quantities.map((quantity, key) => {
          return (
            <Tag key={key}>
              {quantity.location}: {quantity.quantity}
            </Tag>
          );
        })}
      </span>
    ),
    sorter: {compare: (a, b) => a.quantity.length - b.quantity.length}
  },
  createdAtColumn({width: 100}),
  updatedAtColumn({width: 100}),
  viewMoreColumn("production/materials"),
];

export default columns;
