import React from "react";
import moment from "moment";
import {basePreSaveFormat} from "../Base/utils";


export const load = async (values) => {
  console.info("Loading...");
  let result = values;

  console.log("result", result);
  return result;
};


export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  let result = values;
  //
  return result;
};


export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  delete result.created_by;
  delete result.asset;
  delete result.stockroom_asset;

  if (values.date) {
    result.date = moment(values.date).format("YYYY-MM-DD");
  }

  if (values.type && values.type.id) {
    result.type = values.type.id || null;
  }

  if (values.personnel && values.personnel.id) {
    result.personnel = values.personnel.id;
  }

  if (values.materials) {
    // Don't send anything if the save button was clicked
    if (!Array.isArray(values.materials)) {
      result.materials = [{
        id: values.materials["id"],
        amount: values.materials["quantity"],
      }];
    } else {
      delete result.materials;
    }
  }

  return result;
};
