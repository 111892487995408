import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {Box, Button} from "../../Common/Base";
import {Tooltip} from "antd";
import {Modal} from "../../Common";
import {clearMutator} from "../../Common/Forms/Base/mutators";
import arrayMutators from "final-form-arrays";
import {QuantityFormField} from "../../Common/Forms/Base";
import {checkNestedKeys} from "../../../utils/propUtils";

const ProductQuantityModal = props => {
  const [modelOpen, setModalOpen] = useState(false);
  const [selectedLocation, setLocation] = useState({});
  const [isError, setError] = useState(false);
  const {
    locationList,
    isAdmin,
    editAccess,
    values,
    createItemQuantity,
    productID,
  } = props;

  const toggleModal = () => {
    setModalOpen(!modelOpen);
  };

  const handleSetError = () => {
    setError(true);
    setTimeout(() => setError(false), 4000);
  };

  const checkHasLocation = () => {
    const location_instance = checkNestedKeys(["location", "location_instance"], selectedLocation);
    return Boolean(location_instance);
  };

  const handleSubmit = async (values) => {
    console.log("submit values", values);
    const {quantity} = values;
    const location_instance = checkNestedKeys(["location", "location_instance"], selectedLocation);
    if (quantity && location_instance) {
      const res = await createItemQuantity({quantity, warehouse: location_instance, product: productID});
      if (!res.error) toggleModal();
    }
    handleSetError();
  };

  const setLocationData = (data) => {
    setLocation(data.location.id > 0 ? data : {});
  };

  const hasLocation = checkHasLocation();

  return (
    <Fragment>

      <Button
        id="add-product-quantity"
        type={"primary"}
        onClick={toggleModal}
        size={"small"}
        style={{
          marginLeft: "8px"
        }}
      >
        Add Quantity
      </Button>

      <Modal
        visible={modelOpen}
        onCancel={toggleModal}
        showConfirmButton={false}
        title={"Add Quantity"}>
        {modelOpen &&
        <Fragment>
          {isError && <Box color="red.500">Invalid submission. Please enter a quantity and select a location.</Box>}
          <Form
            onSubmit={handleSubmit}
            initialValues={values}
            mutators={{
              ...arrayMutators,
              clearMutator,
            }}
            render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
              <form className={"item-details-form"} onSubmit={async e => {
                await handleSubmit(e);
                form.mutators.clearMutator("quantity");
              }}>
                <Box textAlign="center">
                  <QuantityFormField
                    label="QUANTITY"
                    type="number"
                    name="quantity"
                    isDisabled={!editAccess}
                    mr={"20px"}
                    locationData={selectedLocation}
                    locationList={locationList}
                    handleSelectLocation={setLocationData}
                    isAdmin={isAdmin}
                  />
                </Box>
                <Box textAlign="center">
                  <Tooltip
                    title={
                      !hasLocation && pristine ?
                        "Select a location and quantity."
                        :
                        null
                    }
                  >
                    <Button
                      className="item-details-form-button"
                      htmlType="submit"
                      disabled={!hasLocation || pristine}
                    >
                      Save Quantity
                    </Button>
                  </Tooltip>
                </Box>
              </form>
            )}
          />
        </Fragment>
        }
      </Modal>
    </Fragment>
  );
};

ProductQuantityModal.propTypes = {
  inventoryList: PropTypes.array,
  location: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default ProductQuantityModal;
