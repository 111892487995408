import {isNaN} from "lodash-es";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../constants";

const numericalFieldHandler = callback => (event) => {
  // Convert input from a string to a number
  const inputNumber = Number(event.target.value);
  // Check if is a number and is less than 1000
  if (isNaN(inputNumber) || inputNumber >= 1000) return;

  const editedEvent = {target: {value: inputNumber, name: event.target.name}};
  callback(editedEvent);
};

const numericalSelectFieldHandler = callback => (value) => {
  // Convert input from a string to a number
  console.log("value", value);
  const inputNumber = Number(value.value);
  console.log("inputNumber", inputNumber);

  // Check if is a number and is less than 1000
  if (isNaN(inputNumber) || inputNumber >= 1000) return;

  const editedEvent = {target: {value: inputNumber, name: value.name}};
  callback(editedEvent);
};

const numericalDateFieldHandler = callback => (event) => {
  // Convert input from an integer to a date
  const date = moment(event.target.value).format(DATE_TIME_FORMAT);
  const editedEvent = {target: {value: date, name: event.target.name}};
  callback(editedEvent);
};

export {numericalFieldHandler, numericalDateFieldHandler, numericalSelectFieldHandler};
