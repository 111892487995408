import {
  ORDER_CREATED_STR,
  CLIENT_ADMIN_APPROVED_STR,
  SERVICE_PROVIDER_ACCEPTED_STR,
  CLIENT_ADMIN_ACCEPTED_STR,
  ORDER_SCHEDULED_STR,
  ORDER_STARTED_STR,
  ASSETS_IN_TRANSIT_STR,
  AT_VENDOR_SITE_STR,
  ORDER_COMPLETE_STR,
} from "../Forms/VendorForm/constants";

export const onSiteProgressLabels = [
  {
    className: "point-label-left",
    p: 3,
    width: "10%",
    label: ORDER_CREATED_STR,
    paddingTop: "12px",
    paddingLeft: "0",
    paddingRight: "0"
  },
  {
    className: "point-label",
    p: 3,
    width: "16%",
    label: CLIENT_ADMIN_APPROVED_STR,
  },
  {
    className: "point-label",
    p: 3,
    width: "16%",
    label: SERVICE_PROVIDER_ACCEPTED_STR,
  },
  {
    className: "point-label",
    p: 3,
    width: "16%",
    label: CLIENT_ADMIN_ACCEPTED_STR,
  },
  {
    className: "point-label",
    p: 3,
    width: "16%",
    label: ORDER_SCHEDULED_STR,
  },
  {
    className: "point-label",
    p: 3,
    width: "16%",
    label: ORDER_STARTED_STR,
  },
  {
    className: "point-label-right",
    p: 3,
    width: "10%",
    label: ORDER_COMPLETE_STR,
    paddingTop: "12px",
    paddingLeft: "0",
    paddingRight: "0"
  },
];

export const offSiteProgressLabels = [
  {
    className: "point-label-left",
    p: 3,
    width: "9%",
    label: ORDER_CREATED_STR,
    paddingTop: "12px",
    paddingLeft: "0",
    paddingRight: "0"
  },
  {
    className: "point-label",
    p: 3,
    width: "13.66%",
    label: CLIENT_ADMIN_APPROVED_STR
  },
  {
    className: "point-label",
    p: 3,
    width: "13.66%",
    label: SERVICE_PROVIDER_ACCEPTED_STR
  },
  {
    className: "point-label",
    p: 3,
    width: "13.66%",
    label: CLIENT_ADMIN_ACCEPTED_STR
  },
  {
    className: "point-label",
    p: 3,
    width: "13.66%",
    label: ORDER_STARTED_STR
  },
  {
    className: "point-label",
    p: 3,
    width: "13.66%",
    label: ASSETS_IN_TRANSIT_STR
  },
  {
    className: "point-label",
    p: 3,
    width: "13.66%",
    label: AT_VENDOR_SITE_STR
  },
  {
    className: "point-label-right",
    p: 3,
    width: "9%",
    label: ORDER_COMPLETE_STR,
    paddingTop: "12px",
    paddingLeft: "0",
    paddingRight: "0"
  },
];

export const onSiteProgressPoints = [
  {pointName: ORDER_CREATED_STR},
  {pointName: CLIENT_ADMIN_APPROVED_STR},
  {pointName: SERVICE_PROVIDER_ACCEPTED_STR},
  {pointName: CLIENT_ADMIN_ACCEPTED_STR},
  {pointName: ORDER_SCHEDULED_STR},
  {pointName: ORDER_STARTED_STR},
  {pointName: ORDER_COMPLETE_STR, icon: true},
];

export const offSiteProgressPoints = [
  {pointName: ORDER_CREATED_STR},
  {pointName: CLIENT_ADMIN_APPROVED_STR},
  {pointName: SERVICE_PROVIDER_ACCEPTED_STR},
  {pointName: CLIENT_ADMIN_ACCEPTED_STR},
  {pointName: ORDER_STARTED_STR},
  {pointName: ASSETS_IN_TRANSIT_STR},
  {pointName: AT_VENDOR_SITE_STR},
  {pointName: ORDER_COMPLETE_STR, icon: true},
];

export const onSitePercentMap = {
  1: 0,
  2: 17,
  3: 34,
  4: 51,
  5: 68,
  6: 84,
  9: 100
};

export const offSitePercentMap = {
  1: 0,
  2: 15,
  3: 30,
  4: 44,
  6: 58,
  7: 72,
  8: 86,
  9: 100
};

