// Import React from 'react';
// import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import {color, space, width} from "styled-system";

const Textarea = styled.textarea`
      display: block;
      //padding: 10px 10px 10px 10px;
      //margin-bottom: 0 !important;
      font-family: 'Open Sans', sans-serif;
      border: none;
      ${color}
      ${width}
      ${space}
`;

Textarea.defaultProps = {
  color: "colorGreyDark2",
  bg: "colorWhite",
  width: "95%",
  p: "10px",
  mb: "20px",
  display: "block"
};

export default Textarea;
