import React from "react";
import PropTypes from "prop-types";
import {FilePond} from "react-filepond";
import {Field} from "react-final-form";

const FileAdapter = ({input: {value, onChange}, meta: {submitError, error}}) => (
  <FilePond
    onaddfile={(error, fileItem) => {
      onChange(fileItem);
    }}
    acceptedFileTypes={["image/*", "application/pdf"]}
  />
);

const FileField = props => {
  const {
    inputSize,
    name,
    ...rest
  } = props;
  return (
    <Field
      name={name}
      component={FileAdapter}
      size={inputSize}
      {...rest}
    />
  );
};

FileField.propTypes = {
  inputSize: PropTypes.string
};

FileField.defaultProps = {
  inputSize: "medium"
};
export default FileField;
