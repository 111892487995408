import {dateUtils, snackUtils} from "../../../utils";
import {baseOperations} from "../index";

const createCrudOperations = ({actions, name, withNotes = false, withCsv = false, paginator = null, withBulkDelete = false}) => {
  const type = name.toLowerCase();

  /**
   * Get item operation
   * @param {number} id - id or primary key to fetch
   * @param {string} params - additional url params to shape response
   * @returns {object} - RSAA action payload
   */
  const getItem = (id, params = "") => async dispatch => {
    const res = await dispatch(actions.getItem(id, params));

    if (res.error) {
      return res;
    }
    return res.payload;
  };

  /**
   * Create item operation
   * @param {object} data - initial values for new item
   * @returns {object} - RSAA action payload
   */
  const createItem = data => async dispatch => {
    const timestamp = dateUtils.createTimestamp();
    snackUtils.createSnack(`Creating ${name}`, 1000, timestamp, false, dispatch);
    const res = await dispatch(actions.createItem(data));
    baseOperations.handleCreateResponse(res, timestamp + 1, type, res.payload.name, dispatch, () => {
    });

    return res;
  };

  /**
   * Update item operation
   *
   * @param {number} id - The id or primary key
   * @param {object} data - values to update with
   * @param {string} params - param string for fetch item after update
   * @returns {object} - RSAA action payload
   */
  const updateItem = (id, data, params = "") => async dispatch => {
    const timestamp = dateUtils.createTimestamp();
    snackUtils.createSnack(`Updating ${name}`, 1000, timestamp, false, dispatch);
    const res = await dispatch(actions.updateItem(id, data));
    baseOperations.handleUpdateResponse(res, timestamp + 1, type, res.payload.name, dispatch, () => {
    });

    if (res.error) {
      return res;
    }
    dispatch(actions.getItem(id, params));
    return res.payload;
  };

  /**
   * Update item operation. Expects FormData for files
   * @param {number} id - The id or primary key
   * @param {object} data - FormData to update with
   * @param {string} params - param string for fetch item after update
   * @returns {object} - RSAA action payload
   */
  const updateItemFile = (id, data, params = "") => async dispatch => {
    const timestamp = dateUtils.createTimestamp();
    snackUtils.createSnack(`Updating ${name}`, 1000, timestamp, false, dispatch);
    const res = await dispatch(actions.updateItemFile(id, data));
    baseOperations.handleUpdateResponse(res, timestamp + 1, type, res.payload.name, dispatch, () => {
    });

    if (res.error) {
      return res;
    }
    dispatch(actions.getItem(id, params));
    return res.payload;
  };

  /**
   * Delete item operation
   * @param {number} id - The id or primary key to be deleted
   * @returns {object} - RSAA action payloads
   */
  const deleteItem = id => async dispatch => {
    const timestamp = dateUtils.createTimestamp();
    snackUtils.createSnack(`Deleting ${name}`, 1000, timestamp, false, dispatch);
    return dispatch(actions.deleteItem(id));
  };

  let bulkDelete = {};
  if (withBulkDelete) {
    const bulkDeleteOperation = (data) => async dispatch => {
      const res = await dispatch(actions.bulkDelete(data));
      console.log("BULK OPERATIONS: ", res);
      dispatch(actions.fetchPage({page: 1}));
    };
    bulkDelete = {bulkDelete: bulkDeleteOperation};
  }


  let noteOperations = {};

  if (withNotes) {
    /**
     * Creates a new note on an item
     * @param {number} id - id of parent object
     * @param {object} data - note values
     * @returns {object} - RSAA action payload
     */
    const addNote = (id, data) => async dispatch => {
      const res = await dispatch(actions.submitNote(id, data));
      baseOperations.handleSubmitNoteResponse(res, dispatch);
      return res.payload;
    };

    noteOperations = {addNote};
  }
  let csvOperations = {};

  if (withCsv) {
    const uploadCsv = (data, org) => async dispatch => {
      const timestamp = dateUtils.createTimestamp();
      snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
      const res = await dispatch(actions.uploadCsv(data, org));
      baseOperations.handleCSVUploadResponse(
        res,
        dispatch
      );
    };
    csvOperations = {uploadCsv};
  }

  return {
    getItem,
    createItem,
    updateItem,
    updateItemFile,
    deleteItem,
    ...bulkDelete,
    ...noteOperations,
    ...csvOperations
  };
};

export default createCrudOperations;
