import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  Divider,
  ListItemSecondaryAction,
  ListItemText,
  List,
  ListItem,
  ListSubheader
  // IconButton,
} from "@material-ui/core";
import ShiftList from "./ShiftList";
import PersonnelList from "./PersonnelList";
import {Flex} from "../../Base";
import {FaEdit, FaPlus} from "react-icons/fa";
import {CaretUp, CaretDown} from "../../Base/Icons";
import {ListButton} from "../styled";
/*
This Component is used inside the ScheduleShiftForm.
Click handlers are for updating selected items inside the main Form Components
All submits happen will happen there outside of the List.
 */
const ScheduleListItem = props => {
  const [open, setOpen] = useState(false);

  const {item, onClick, onClickShift, selectPersonnel, addPersonnel, setSchedule, classes, current} = props;

  const handleEdit = (item) => {
    // SetOpen(!open);
    onClick(item);
  };

  const handleClick = () => {
    // Sets schedule in ScheduleShiftForm so Shift form can submit with Schedule ID
    if (!open) {
      setSchedule(item);
    } else {
      setSchedule(null);
    }
    setOpen(!open);
  };

  const handleShiftClick = (item) => {
    onClickShift(item);
  };

  const handleAddPersonnelClick = () => {
    /*
    This function is for the + button.
    Opening the Assigning Personnel form.
    */
    console.log("clicked schdule");

    addPersonnel(item);
  };

  const handlePersonnelClick = (personnel) => {
    /*
   This function is passed to the Personnel List
   handles selecting a Personnel to remove from a Schedule
   */

    selectPersonnel(personnel);
  };


  const shifts = item.shifts;
  const personnel = item.personnel;
  const hasChildren = shifts.length !== 0 || personnel.length !== 0;

  const isSelected = current.schedule !== null && current.schedule.id === item.id;
  return (
    <Fragment>
      <ListItem
        button
        onClick={handleClick}
        className={`${isSelected ?
          classes.selectedListItem : classes.listItem}  
          schedule-list-item`}
      >
        <ListItemText primary={item.name} className={classes.listItemText}/>
        {hasChildren &&
        <Flex width={"15px"} mr={"60px"}>
          {/* Toggle List */}
          {open ? <CaretUp/> : <CaretDown/>}
        </Flex>
        }

        <ListItemSecondaryAction>


          {/* Edit Schedule */}
          <ListButton icon={FaEdit} aria-label={isSelected ? "cancel" : "edit"} onClick={() => handleEdit(item)} />


          <ListButton icon={FaPlus} aria-label={"add-personnel"} onClick={handleAddPersonnelClick} />


        </ListItemSecondaryAction>


      </ListItem>
      {hasChildren &&
      <Collapse in={open} timeout="auto" unmountOnExit>
        {personnel.length !== 0 &&
        <List component="div" disablePadding className={classes.nested}>
          <ListSubheader className={classes.subheader}>Personnel</ListSubheader>
          <PersonnelList
            handleClick={handlePersonnelClick}
            items={personnel}
            classes={classes}
          />
          {/**/}
        </List>}
        {shifts.length !== 0 &&
        <List component="div" disablePadding className={classes.nested}>
          <ListSubheader className={classes.subheader}>Shifts</ListSubheader>
          <ShiftList
            handleItemClick={handleShiftClick}
            items={shifts}
            classes={classes}
            current={current.shift}
          />
          {/**/}
        </List>}

      </Collapse>
      }
      <Divider/>
    </Fragment>
  );
};

ScheduleListItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  onClickShift: PropTypes.func,
  selectPersonnel: PropTypes.func,
  addPersonnel: PropTypes.func,
  classes: PropTypes.any,
  setSchedule: PropTypes.func,
  current: PropTypes.shape({schedule: PropTypes.object, shift: PropTypes.object}),
};

export default ScheduleListItem;
