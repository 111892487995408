import React from "react";
import PropTypes from "prop-types";
import BigCalendar from "react-big-calendar";
import moment from "moment-timezone";
// Import 'react-big-calendar/lib/css/react-big-calendar.css';

/**
 * https://jquense.github.io/react-big-calendar/examples/index.html#api
 */

const localize = BigCalendar.momentLocalizer(moment);

const Calendar = props => {
  const {items, components, views, onEventClick, onRangeChange, startAccessor, endAccessor} = props;
  return (
    <BigCalendar
      popup={true}
      // PopupOffset={2}
      // min={min}
      step={60}
      showMultiDayTimes
      localizer={localize}
      events={items}
      startAccessor={startAccessor}
      endAccessor={endAccessor}
      views={views}
      style={{
        height: "60vh",
        width: "100%",
        padding: "0 20px"
      }}
      components={{
        ...components
      }}
      onRangeChange={onRangeChange}
      onSelectEvent={(event, e) => onEventClick(event)}
    />
  );
};

Calendar.propTypes = {
  items: PropTypes.array,
  onEventClick: PropTypes.func,
  onRangeChange: PropTypes.func,
  min: PropTypes.string,
  startAccessor: PropTypes.string,
  endAccessor: PropTypes.string,
  view: PropTypes.object,
  /**
   * component overrides for BigCalendar
   */
  components: PropTypes.object
};

Calendar.defaultProps = {
  startAccessor: "start",
  endAccessor: "end",
  views: {month: true, week: true, day: true},
  components: {},
  min: "06:00:00",

};

export default Calendar;
