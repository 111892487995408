import React, {Component} from "react";
import PropTypes from "prop-types";
import JsBarcode from "jsbarcode";
import {Box} from "../Base";

/**
 * Object that maps the format types that Quagga outputs to the ones that JSBarcode uses
 */
const quaggaFormatMap = {
  "code_128": "code128"
};

const getBarcodeOptions = format => ({
  format: quaggaFormatMap[format],
  width: 1,
  height: 100,
  displayValue: true
});

class Barcode extends Component {
  componentDidMount() {
    const {data, format} = this.props;
    JsBarcode("#barcode", data, getBarcodeOptions(format));
  }


  componentDidUpdate(prevProps, prevState) {
    const {data, format} = this.props;
    JsBarcode("#barcode", data, getBarcodeOptions(format));
  }


  render() {
    return (
      <Box maxWidth="300px" py="20px" margin="0 auto">
        <img id="barcode" alt={"Barcode"} ref={this.props.barcodeRef} style={{"margin": "0 auto"}}/>
      </Box>
    );
  }
}

Barcode.propTypes = {
  data: PropTypes.string.isRequired,
  format: PropTypes.string,
  barcodeRef: PropTypes.any
};

Barcode.defaultProps = {
  format: "CODE128"
};

export default Barcode;
