import {
  ASSET_DASHBOARD_ROUTE,
  VENDORS_DASHBOARD_ROUTE,
  PM_ORDERS_DASHBOARD_ROUTE
} from "../../../../routes/constants";

import {dataSoon} from "../../../Common/Dashboard/ScorecardDashboard/constants";

/**
 * Parse information from the summary prop and set certain fields for the MaintenanceScorecard
 * @param {object} summary - the summary object
 * @returns {Array} - parsed summaries
 */
export const summaries = summary =>
  [
    {
      title: "Assets",
      stats: [{
        name: "Total Assets in Need",
        value: summary.pm_orders.inspect_today,
        style: {color: "#cd0111"},
        suffix: "items",
        route: ASSET_DASHBOARD_ROUTE
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    },
    {
      title: "Vendor Orders",
      stats: [{
        name: "On site",
        value: summary.vendor_orders.on_site,
        style: {color: "#032d99"},
        suffix: "items",
        route: VENDORS_DASHBOARD_ROUTE
      },
      {
        name: "Off site",
        value: summary.vendor_orders.off_site,
        style: {color: "#032d99"},
        suffix: "items",
        route: VENDORS_DASHBOARD_ROUTE
      },
      {
        name: "Needs Approval",
        value: summary.vendor_orders.needs_approval,
        style: {color: "#032d99"},
        suffix: "items",
        route: `${VENDORS_DASHBOARD_ROUTE}?filterby=needs_approval`
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    },
    {
      title: "Maintenance Orders",
      stats: [{
        name: "High Priority",
        value: summary.pm_orders.overdue,
        style: {color: "#cd0111"},
        suffix: "items",
        route: PM_ORDERS_DASHBOARD_ROUTE
      },
      {
        name: "Medium Priority",
        value: summary.pm_orders.inspect_today,
        style: {color: "#a18c01"},
        suffix: "items",
        route: PM_ORDERS_DASHBOARD_ROUTE
      },
      {
        name: "Low Priority",
        value: summary.pm_orders.near_future,
        style: {color: "#01cd15"},
        suffix: "items",
        route: PM_ORDERS_DASHBOARD_ROUTE
      }],
      meta: {
        title: "",
        description: dataSoon // visual component here
      }
    }
  ];
