import React from "react";
import PropTypes from "prop-types";
import {Heading, Flex, Link} from "../../Base";
import UserPlaceholder from "../../../../static/img/user_placeholder.jpg";

const MainMenu = ({account, onLogout, onClick = null}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Flex>
      <div className="header-menu-text-left">
        <div>
          {account && account.personnel && (
            <Heading
              p={0}
              mb={2}
              mx={1}
              as="h2"
              fontWeight="semibold"
              fontSize="20px"
              className="header-menu-text-username">
              {account.personnel.name}
            </Heading>
          )}
        </div>

        <Flex flexWrap={"wrap"} className="header-menu-text-help-settings">
          <Link
            onClick={handleClick}
            mx={1}
            id="helpLink"
            className="header-menu-text-help-settings-item"
            to="/dashboard/help"
          >
            Help
          </Link>
          <Link
            mx={1}
            onClick={handleClick}
            className="header-menu-text-help-settings-item"
            to="/dashboard/settings"
          >
            Settings
          </Link>
          <Link
            mx={1}
            className="header-menu-text-help-settings-item"
            to="/"
            onClick={onLogout}
          >
            Sign Out
          </Link>
        </Flex>
      </div>
      <img
        className="header-menu-text-img"
        src={(account && account.personnel && account.personnel.display_image) || UserPlaceholder}
        alt="Conmitto user avatar"
      />
    </Flex>
  );
};

MainMenu.propTypes = {
  account: PropTypes.any,
  onLogout: PropTypes.func.isRequired,
  onClick: PropTypes.func
};

export default MainMenu;
