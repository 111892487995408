import React from "react";
import {Tag} from "antd";
import {
  updatedAtColumn,
  createdAtColumn,
  viewMoreColumn,
  numberOfNotesColumn,
  manufacturerColumn,
  nameColumn,

} from "../../../utils/tables";
import {numberSorter} from "../../../utils/sort";

// Configure the columns of the table
const columns = [
  nameColumn({width: 150}),
  manufacturerColumn({width: 100}),
  {
    title: "Tags",
    dataIndex: "tags",
    width: 100,
    render: tags => (
      <span>
        {tags.map(tag => {
          return (
            <Tag key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
    sorter: {compare: (a, b) => a.tags.length - b.tags.length},
  },
  {
    title: "Type",
    dataIndex: "asset_type",
    width: 100,
    render: type => {
      return type && <span>
        {type && <Tag key={type.name} color={type.color}>
          {type.name.toUpperCase()}
        </Tag>}
      </span>;
    },
    // TODO: fix this filter, may need to change incoming data

  },
  numberOfNotesColumn({width: 100}),
  {
    title: "# of Assets",
    dataIndex: "number_assets",
    width: 100,
    sorter: numberSorter("number_assets")
  },
  createdAtColumn({width: 100}),
  updatedAtColumn({width: 100}),
  viewMoreColumn("assets"),
];

export default columns;
