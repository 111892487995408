import React from "react";
import PropTypes from "prop-types";
import {Descriptions, Flex, Column} from "../Base";

const LocationDetails = props => {
  const {region, district, facility, wing, zone, subzone, addButton} = props;

  const renderTitle = () => (
    <Flex flexDirection={"row"}>
      <Flex justifyContent={"center"} flexDirection={"column"} pr={1}>Location</Flex>
    </Flex>
  );
  return (
    <Column alignItem={"left"}>
      <Descriptions title={renderTitle()}
        className="assigned-location-section"
      >
        {region && <Descriptions.Item label={"Region"}> {region.name}</Descriptions.Item>}
        {district && <Descriptions.Item label={"District"}> {district.name}</Descriptions.Item>}
        {facility && <Descriptions.Item label={"Facility"}> {facility.name}</Descriptions.Item>}
        {wing && <Descriptions.Item label={"Wing"}> {wing.name}</Descriptions.Item>}
        {zone && <Descriptions.Item label={"Zone"}> {zone.name}</Descriptions.Item>}
        {subzone && <Descriptions.Item label={"Subzone"}> {subzone.name}</Descriptions.Item>}
      </Descriptions>
      {addButton}
    </Column>
  );
};

LocationDetails.propTypes = {
  region: PropTypes.object,
  district: PropTypes.object,
  facility: PropTypes.object,
  wing: PropTypes.object,
  zone: PropTypes.object,
  subzone: PropTypes.object,
};

LocationDetails.defaultProps = {};

export default LocationDetails;
