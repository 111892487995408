import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {Label} from "../../Base";
import {Box} from "Base";
import {LabelText} from "../../Forms/Base/styled";
import {Field} from "react-final-form";

export const SelectField = props => {
  const {onChange, value, options, id, ...rest} = props;
  const handleChange = (values) => {
    return values.value;
  };
  return (
    <div>
      <Select
        className="item-details-form-dropdown"
        onChange={handleChange}
        defaultValue={""}
        {...rest}
      />
    </div>
  );
};

export const selectAdapt = Component => ({input: {value, ...inputRest}, meta: {valid}, ...rest}) => {
  console.log("input value", value);
  return <Component value={value} valid={valid} {...inputRest} {...rest}/>;
};

export const ReactSelectAdapter = ({input, wrapperConfig = {}, ...rest}) => {
  return (
    <Box {...wrapperConfig}>
      <Select className="item-details-form-dropdown" {...input} {...rest} searchable/>
    </Box>
  );
};


const FormDropdown = props => {
  const {
    label,
    name,
  } = props;
  return (
    <Fragment>
      {
        label ?
          <Label htmlFor={name} mb={"30px"}>
            <LabelText>{label}: </LabelText>
            <Field
              name={name}
              component={ReactSelectAdapter}
              {...props}
            />
          </Label>
          :
          <Label>
            <Field
              name={name}
              component={ReactSelectAdapter}
              {...props}
            />
          </Label>
      }
    </Fragment>
  );
};


FormDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  inputType: PropTypes.string,
  display: PropTypes.string,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  selectClassName: PropTypes.string,
};

FormDropdown.defaultProps = {
  inputType: "input",
  display: "block",
};

export default FormDropdown;

