import React from "react";
import PropTypes from "prop-types";
import {crudActions} from "../../../../../types/propTypes";
import {List, Card} from "../../../../Common/Base";
import EntryTable from "./EntryTable";

const EntryCardItem = props => {
  const {
    item,
    actions
  } = props;


  const {data, key} = item;

  return (
    <List.Item
      className={"entry-list-item"}
    >
      <Card title={key}>
        <EntryTable data={data} actions={actions}/>
      </Card>
    </List.Item>
  );
};

EntryCardItem.propTypes = {
  item: PropTypes.object,
  actions: crudActions
};

export default EntryCardItem;
