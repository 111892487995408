import actionTyper from "redux-actiontyper";

const {
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  UPLOAD_INVENTORY_CSV_REQUEST,
  UPLOAD_INVENTORY_CSV_SUCCESS,
  UPLOAD_INVENTORY_CSV_FAILURE,

  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILURE,

  CLEAR_MESSAGES,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE
} = actionTyper("@@products/");


export default {
  UPLOAD_CSV_REQUEST,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,

  UPLOAD_INVENTORY_CSV_REQUEST,
  UPLOAD_INVENTORY_CSV_SUCCESS,
  UPLOAD_INVENTORY_CSV_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,

  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILURE,

  CLEAR_MESSAGES,

  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE
};
