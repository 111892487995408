import React, {Component, Fragment} from "react";
import ReactTooltip from "react-tooltip";
import {DetailsCloseIcon, ErrorHeading, Flex, Link, Loading} from "../../../Common/Base";
import {ScheduledPMOrderForm} from "../../Forms";
import {BackButton, Container, LocationSection} from "../../../Common";
import {DetailsHeader, DetailsPaneLeft, DetailsPaneRight, ImageWithDefault} from "../../../Common/Details/Layout";
import {preSaveFormat} from "../../Forms/ScheduledPMOrderForm/utils";

import {checkForResponseError, handleErrorResponse, scrollToTop} from "utils/containerUtils";
import {ASSET_DASHBOARD_ROUTE, PM_ORDERS_DASHBOARD_ROUTE, STOCKROOM_DASHBOARD_ROUTE} from "../../../../routes/constants";
import authSelectors from "../../../../store/auth/selectors";

/* This presents the details for a particular scheduled PM order. The imported item ID is acquired via the URL. */
class ScheduledPMOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.orderID = props.match.params.orderID;
    this.state = {
      scheduleData: {},
      scheduledPMOrderOrgID: "",
      scheduledMOrderID: "",
      personnelId: "",
      personnelName: "",
      orderDate: "",
      inspectionNote: "",
      showLoader: true,
      errorLoadingMessage: "",
      selectedScheduleStatus: "",
      newScheduleStatus: {
        name: "",
        color: "#2c3655",
        id: ""
      },
      editAccess: false
    };
  }

  componentDidMount() {
    const {getPersonnelList, getOrg, getScheduleStatuses} = this.props;
    this.fetchScheduledPMOrderDetails().then(() => {
      if (this.state.scheduledPMOrderOrgID) {
        getOrg(this.state.scheduledPMOrderOrgID);
        getScheduleStatuses(this.state.scheduledPMOrderOrgID);
      }
    });
    getPersonnelList();
    scrollToTop();
  }

  /* This function will handle the fetching of asset details. */
  fetchScheduledPMOrderDetails = () => {
    const {fetchDetails} = this.props;
    return fetchDetails(this.orderID)
      .then(res => {
        console.log(res);
        checkForResponseError(res);
        let data = res.payload;
        this.setState({
          scheduleData: data,
          scheduledPMOrderOrgID: data.org.id,
          scheduledPMOrderID: data.id,
          pmOrder: data.pm_order,
          assetName: data?.asset?.name ?? "",
          stockAssetName: data.stockroom_asset ? data.stockroom_asset.name : "",
          assetData: data.asset,
          stockAssetData: data.stockroom_asset,
          personnelId: data.personnel ? data.personnel.id : "",
          personnelName: data.personnel ? data.personnel.name : "",
          orderDate: data.date,
          selectedScheduleStatus: data.status || {},
          inspectionNote: data.inspection_note,
          pmOrderID: data.pm_order.id,
          editAccess: data?.permissions?.write ?? false
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        handleErrorResponse(this, error, "Schedule");
      }).then(() => {
        this.setState({
          showLoader: false
        });
      });
  };

  /* Submit redux form. */
  submit = async values => {
    console.log(values);
    const {submitting} = this.state;
    const {updateScheduledOrder} = this.props;
    if (!submitting) {
      this.setState({submitting: true});
      await updateScheduledOrder(this.orderID, preSaveFormat(values));
      this.setState({submitting: false});
    }
  };

  /* Alter state upon selecting a personnel item. */
  handlePersonnelSelect = data => {
    this.setState({personnelId: data.id, personnelName: data.value});
  };

  handleScheduleStatusSelect = data => {
    const obj = {
      name: data["value"],
      color: data["color"],
      id: data["id"]
    };
    this.setState({
      selectedScheduleStatus: obj
    });
    this.submit({status: obj["id"] || null});
  };
  handleShowCreateScheduleStatus = () => {
    this.setState({
      newScheduleStatus: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };
  handleShowEditScheduleStatus = () => {
    this.setState({newScheduleStatus: this.state.selectedScheduleStatus});
  };
  handleScheduleStatusColorChange = (color) => {
    this.setState({
      newScheduleStatus: {
        name: this.state.newScheduleStatus.name,
        color: color.hex,
        id: this.state.newScheduleStatus.id
      }
    });
  };
  handleScheduleStatusNameChange = (event) => {
    this.setState({
      newScheduleStatus: {
        name: event.target.value,
        color: this.state.newScheduleStatus.color,
        id: this.state.newScheduleStatus.id
      }
    });
  };
  handleCreateScheduleStatus = (name, color) => {
    this.props.createScheduleStatus(this.state.scheduledPMOrderOrgID, name, color);
  };
  handleUpdateScheduleStatus = (order_status_id, name, color) => {
    this.props.updateScheduleStatus(this.state.scheduledPMOrderOrgID, order_status_id, name, color);
    this.setState({selectedScheduleStatus: this.state.newScheduleStatus});
  };
  handleDeleteScheduleStatus = () => {
    const {name, color, id} = this.state.selectedScheduleStatus;
    this.props.deleteScheduleStatus(this.state.scheduledPMOrderOrgID, id, name, color);
    this.setState({
      selectedScheduleStatus: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };

  render() {
    const {
      scheduleData,
      scheduledPMOrderID,
      assetName,
      stockAssetName,
      assetData,
      stockAssetData,
      pmOrderID,
      showLoader,
      errorLoadingMessage,
      selectedScheduleStatus,
      newScheduleStatus,
      editAccess,
      pmOrder
    } = this.state;
    const {accountInfo, personnelList, scheduleStatusList} = this.props;
    const personnelEnabled = authSelectors.moduleEnabled(accountInfo, "personnel");

    return (
      <div>
        {scheduledPMOrderID && (
          <Container>
            <Flex className="item-details" flexDirection={["column", "row"]}>
              <DetailsCloseIcon href={`/#${PM_ORDERS_DASHBOARD_ROUTE}/${pmOrderID}`} dataTip={"Close and return to the corresponding PM Order."}/>
              <DetailsPaneLeft
                className="item-details-pane-left"
              >
                <ImageWithDefault/>
                <div className="item-details-pane-left-text"/>
              </DetailsPaneLeft>
              <DetailsPaneRight
                className="item-details-pane-right"
              >
                <div className="item-details-pane-right-text">
                  <ReactTooltip/>
                  <DetailsHeader>SCHEDULED PM ORDER DETAILS</DetailsHeader>
                  <h3><b>PM Order Name: </b>
                    <Link color="#1e6cb6" to={`${PM_ORDERS_DASHBOARD_ROUTE}/${pmOrderID}`}>{pmOrder?.name || "Unnamed"}</Link>
                  </h3>
                  <h3><b>PM Order Description: </b>{pmOrder?.description || "None"}</h3>
                  {assetData &&
                  <Fragment>
                    <h3><b>Asset Name: </b>
                      {assetName && <Link color="#1e6cb6" to={`${ASSET_DASHBOARD_ROUTE}/${assetData.id}`}>{assetName}</Link>}
                    </h3>
                    <h3><b>Stockroom Asset Name: </b>
                      {stockAssetName !== null ? <Link color="#1e6cb6"
                        to={`${STOCKROOM_DASHBOARD_ROUTE}/${stockAssetData.id}`}>{stockAssetName}</Link> : "Not Specified"}
                    </h3>
                  </Fragment>
                  }
                  {scheduleData.location &&
                    <LocationSection locationData={scheduleData.location} allowLocationEdit={false}/>
                  }
                  <br/>
                  <div className="item-details-form">
                    <ScheduledPMOrderForm
                      onSubmit={this.submit}
                      values={scheduleData}
                      editAccess={editAccess}
                      personnelList={personnelList}
                      scheduleStatuses={scheduleStatusList}
                      selectedScheduleStatus={selectedScheduleStatus}
                      newScheduleStatus={newScheduleStatus}
                      handleScheduleStatusSelect={this.handleScheduleStatusSelect}
                      handleColorPickerClick={this.handleColorPickerClick}
                      handleShowCreateScheduleStatus={this.handleShowCreateScheduleStatus}
                      handleShowEditScheduleStatus={this.handleShowEditScheduleStatus}
                      handleCreateScheduleStatus={this.handleCreateScheduleStatus}
                      handleUpdateScheduleStatus={this.handleUpdateScheduleStatus}
                      handleDeleteScheduleStatus={this.handleDeleteScheduleStatus}
                      handleScheduleStatusColorChange={this.handleScheduleStatusColorChange}
                      handleScheduleStatusNameChange={this.handleScheduleStatusNameChange}
                      personnelEnabled={personnelEnabled}
                    />
                    <br/>
                  </div>
                </div>
              </DetailsPaneRight>
            </Flex>
          </Container>
        )}
        {showLoader ?
          <Loading title={"Loading Scheduled PM Order..."}/> :
          pmOrderID && <BackButton prevPage={`/dashboard/orders/${pmOrderID}`}/>
        }
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>
        }
      </div>
    );
  }
}

export default ScheduledPMOrderDetails;
