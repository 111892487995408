import React, {Component, Fragment} from "react";
import {ProductsTable} from "../Table/ProductsTable";
import {ProductBar} from "../Bar/ProductBar";
import {ImportInventoryModal} from "../../Modals";
import {csvColumns, csvData, productsDescriptions, selectionOptions} from "./constants";
import {DashboardList} from "../../../Common/Dashboard";

class Inventory extends Component {
  constructor() {
    super();
    this.state = {
      showImportInventoryModal: false,
    };
  }

  /* Upon mounting, fetch a list of products and store the array in redux. They
    will be rendered using the map method. */
  componentDidMount = () => {
    this.handleRefreshPage();
  };

  handleRefreshPage = () => {
    const {getProductPage, pagination, getFacilities, accountInfo} = this.props;
    const {itemsCurrentPage} = pagination;
    if (itemsCurrentPage) {
      getProductPage(itemsCurrentPage);
    } else {
      getProductPage();
    }// sortProduct("created_at");
    const orgID = accountInfo?.personnel?.orgs[0]?.id ?? false;
    if (orgID) {
      getFacilities(orgID);
    }
  };

  handleUploadInventoryCsv = (data) => {
    const {uploadInventoryCsv, accountInfo} = this.props;
    return uploadInventoryCsv(data, accountInfo.personnel.orgs[0].id);
  };


  render() {
    const {
      showImportInventoryModal,
    } = this.state;

    const {
      changeView,
      csvMessages,
      clearMessages,
      accountInfo,
      getProductPage,
      pagination,
      facilities,
      uploadCsv,
      createItem,
      bulkDelete,
      updateDetails,
      match
    } = this.props;

    // Privileges for editing asset


    return (
      <Fragment>

        <DashboardList
          accountInfo={accountInfo}
          ListItem={ProductBar}
          getPage={getProductPage}
          Table={ProductsTable}
          updateDetails={updateDetails}
          createItem={createItem}
          uploadCsv={uploadCsv}
          pagination={pagination}
          type={"product"}
          changeView={changeView}
          handleTableFieldChange={this.handleTableFieldChange}
          csvOptions={{
            csvColumns,
            csvData,
            clearMessages,
            csvMessages,
          }}
          tutorialText={productsDescriptions}
          sortByOptions={selectionOptions}
          buttonBarActions={[
            {
              text: "Import Inventory",
              onClick: () => this.setState({showImportInventoryModal: true}),
              icon: "import",
              key: "import-inventory",
            },
          ]}
          bulkDelete={bulkDelete}
          newItemUrl={match.path}
        />

        <ImportInventoryModal
          visible={showImportInventoryModal}
          onSubmit={this.handleUploadInventoryCsv}
          onSelect={(values) => console.log("values", values)}
          toggleModal={() => this.setState({showImportInventoryModal: !showImportInventoryModal})}
          facilityList={facilities}
        />
      </Fragment>
    );
  }
}

export default Inventory;
