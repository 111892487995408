import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../Common/";
import {CsvUploadForm} from "../../Common/Forms";
import {csvColumns, csvData} from "./constants";
import Select from "react-select";

const ImportInventoryModal = ({onSubmit, onSelect, visible, toggleModal, facilityList}) => {
  // const [formData, setFormData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [facility, selectFacility] = useState(null);


  const handleSubmitCsv = async (data) => {
    setSubmitting(true);
    const res = await onSubmit(data);
    console.log("modal res", res);
    if (res.payload && res.payload.length) {
      setSubmitResponse(res.payload);
    }
    setSubmitting(false);
    setTimeout(() => {
      toggleModal();
    }, 2000);
  };

  const handleSelectFacility = (values) => {
    console.log("values", values);
    selectFacility(values);
    onSelect(values);
  };

  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("file", csvFile);
    formData.append("location", facility.value);
    await handleSubmitCsv(formData);
    setCsvFile(null);
  };

  return (
    <div className={"import-inventory-wrapper"}>
      <Modal
        visible={visible}
        onCancel={toggleModal}
        onOk={handleSubmit}
        showConfirmButton={Boolean(csvFile && facility)}
        confirmLoading={submitting}
        title={"Import Inventory"}
      >
        {visible &&
        <CsvUploadForm
          name={"Product Quantities"}
          columns={csvColumns}
          handleUploadCsv={handleSubmit}
          messages={submitResponse}
          clearMessages={() => console.log("clear messages")}
          submitting={submitting}
          datas={csvData}
          loadCsvFile={setCsvFile}
          helpText={[
            // TODO: add help text
            "This is for importing received products with quantities.",
            "To import Product info only, close this Popup and click the Blue Plus button for the standard create options.",
            "For received at Date and Time, if Date and Time are already included together in the Date column (M/DD/YYYY-H:MM:SS), " +
            "the time column is optional and can be deleted."

          ]}
          noHeader
        >
          <Select
            style={{padding: 8}}
            className={"select-facility"}
            placeholder="Select a Facility"
            onChange={handleSelectFacility}
            options={facilityList}
          />
        </CsvUploadForm>
        }
      </Modal>
    </div>
  );
};

ImportInventoryModal.propTypes = {
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  toggleModal: PropTypes.func,
  facilityList: PropTypes.array
};

export default ImportInventoryModal;
