import React from "react";
import PropTypes from "prop-types";
import {FormSpy} from "react-final-form";
import diff from "object-diff";
import {basePreSaveFormat} from "./utils";
import {isEqual, differenceBy} from "lodash-es";

class AutoSave extends React.Component {
  static defaultProps = {
    debounced: [],
  };

  constructor(props) {
    super(props);
    this.state = {values: props.values, submitting: false};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.submitting === true && prevState.submitting === false) {
      return {values: nextProps.values, submitting: true};
    }
    if (nextProps.submitting === false && prevState.submitting === true) {
      return {submitting: false};
    } else return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {values, debounce, debounced, ignored, arrayFields} = this.props;

    const debouncedValues = {};
    const immediateValues = {};
    const arrayValues = {};
    let allIgnored = ["notes"];
    const arrayFieldKeys = Object.keys(arrayFields);
    if (ignored || arrayFields) {
      // Combine ignored and arrayFields so arrayFields can be handled differently
      allIgnored = ["notes", ...ignored, ...arrayFieldKeys];
    }

    Object.keys(values).forEach(key => {
      if (debounced.indexOf(key) !== -1) {
        debouncedValues[key] = values[key];
      } else if (allIgnored.indexOf(key) === -1) {
        immediateValues[key] = values[key];
      }
      if (arrayFieldKeys.indexOf(key) !== -1) {
        arrayValues[key] = values[key];
      }
    });
    if (Object.keys(immediateValues).length) {
      this.save(immediateValues);
    }
    if (Object.keys(debouncedValues).length) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.save(debouncedValues);
      }, debounce);
    }
    if (Object.keys(arrayValues).length) {
      if (this.arrayTimeout) {
        clearTimeout(this.arrayTimeout);
      }
      this.arrayTimeout = setTimeout(() => {
        this.saveArrayFields(arrayValues);
      }, debounce);
    }
  }


  saveArrayFields = async (values) => {
    const {save, preSaveFormat, formInvalid} = this.props;
    if (formInvalid) {
      return;
    }
    // Values = basePreSaveFormat(values);
    if (this.promise) {
      await this.promise;
    }

    const currentValues = this.state.values;

    const changedValues = this.getChangedArrayValues(values, currentValues);

    if (Object.keys(changedValues).length) {
      diff(this.state.values);
      // Values have changed
      this.setState(state => ({
        values: {...state.values, ...changedValues},
      }));

      this.promise = preSaveFormat ? save(preSaveFormat(changedValues)) : save(changedValues);
      await this.promise;
      delete this.promise;
    }
  };

  save = async values => {
    const {save, preSaveFormat, formInvalid} = this.props;

    const {submitting} = this.state;
    if (formInvalid) {
      return;
    }
    values = basePreSaveFormat(values);
    if (this.promise) {
      await this.promise;
    }


    const changedValues = this.getChangedValues(values, this.state.values);
    if (Object.keys(changedValues).length) {
      diff(this.state.values);
      // Values have changed
      if (submitting) {
        this.setState(state => ({
          values: {...state.values, ...changedValues},
        }));
      } else {
        this.setState(state => ({
          submitting: true,
          values: {...state.values, ...changedValues},
        }));
      }


      this.promise = preSaveFormat ? save(preSaveFormat(changedValues)) : save(changedValues);
      await this.promise;
      delete this.promise;

      this.setState({submitting: false});
    }
  };

  getChangedValues = (values, originalValues) => {
    const changedValues = Object.keys(values).reduce((result, key) => {
      // We need to use lodash's isEqual algorithm to check the values of any object or array type values
      // since JS compares instances of objects and not the values by default
      // this first if handles edge cases with lodash's isEqual and comparing null
      if (!values[key] && !originalValues[key]) {
        return result;
      }
      if (!(isEqual(values[key], originalValues[key]))) {
        result[key] = values[key];
      }
      return result;
    }, {});

    return changedValues;
  };


  getChangedArrayValues = (values, currentValues) => {
    const {arrayFields} = this.props;
    const arrayKeyValues = Object.keys(values);

    const changedValues = {};

    for (let i in arrayKeyValues) {
      const key = arrayKeyValues[i];
      const currentValue = currentValues[key];
      const newValue = values[key];
      let difference = differenceBy(currentValue, newValue, arrayFields[key]);
      if (difference.length > 0) {
        const changedIds = difference.map(item => item.id);
        changedValues[key] = newValue.filter(item => changedIds.includes(item.id));
      }
    }
    return changedValues;
  };

  render() {
    // This component doesn't have to render anything, but it can render
    // submitting state.
    return (
      this.state.submitting && <div className={"submitting"}/>
    );
  }
}

AutoSave.propTypes = {
  /**
   * How long to wait before automatically submitting the form
   */
  debounce: PropTypes.number,
  debounced: PropTypes.arrayOf(PropTypes.string),
  /**
   * Fields that don't trigger an autosave
   */
  ignored: PropTypes.arrayOf(PropTypes.string),
  /**
   * Fields that are arrays
   */
  arrayFields: PropTypes.object,
  /**
   * The submit function to use
   */
  save: PropTypes.func,
  /**
   * The function to call before saving
   */
  preSaveFormat: PropTypes.func,
  /**
   * If the form is invalid
   */
  formInvalid: PropTypes.bool
};

AutoSave.defaultProps = {
  isSubmitting: false,
  ignored: [],
  arrayFields: {}, // Array fields must be passed in as an object with key/pairs
  onlyChangedFields: true,
  debounce: 2000
};

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
export default props => (
  <FormSpy {...props} subscription={{
    values: true,
    submitting: true
  }} component={AutoSave}/>
);
