import React from "react";
import PropTypes from "prop-types";
import {Wrapper, ColumnWrapper, CloseWrapper} from "./styled";
import {CloseIcon} from "../../Common/Base";

const MaterialBar = (props) => {
  const {
    materialDetails,
    deleteMaterialFromOrder,
    accountUserType,
    accountPersonnelId
  } = props;
  const material = materialDetails.material;
  return (
    <Wrapper className={"material-bar"}>
      <ColumnWrapper>
        {material.name}<br/>
        {material.manufacturer}<br/>
        SKU: {material.sku}
      </ColumnWrapper>
      <ColumnWrapper>
        {material.price && `${material.price} ${material.price_currency}`}<br/>
        Quantity: {materialDetails.amount}
      </ColumnWrapper>

      {(accountUserType <= 2 || accountPersonnelId === materialDetails.added_by) &&
      <CloseWrapper
        onClick={() => deleteMaterialFromOrder({id: material.id, quantity: 0})}>
        <CloseIcon/>
      </CloseWrapper>
      }
    </Wrapper>
  );
};


MaterialBar.propTypes = {
  materialDetails: PropTypes.object.isRequired,
  deleteMaterialFromOrder: PropTypes.func.isRequired,
  accountUserType: PropTypes.number.isRequired,
  accountPersonnelId: PropTypes.number.isRequired
};

MaterialBar.defaultProps = {};

export default MaterialBar;
