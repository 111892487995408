import styled from "@emotion/styled";
import {Flex, Box} from "../../../../Base";
import {color, width, space, minWidth, maxWidth, textAlign} from "styled-system";


export const Label = styled.label`
      ${color}
      ${width}
      ${space}
      ${textAlign}
`;

Label.defaultProps = {
  textAlign: "center",
  pl: 1
};


export const RepeatWrapper = styled(Flex)`
  //min-width: 300px;
  width: 500px;
`;

RepeatWrapper.defaultProps = {
  flexDirection: "column",
  flexWrap: "wrap",
};


export const RepeatOptionsWrapper = styled(Flex)`
  //min-width: 300px;
  width: 500px;
`;

RepeatOptionsWrapper.defaultProps = {
  flexDirection: "column",
  flexWrap: "wrap",
};


// Use Row, RowLabelWrapper, and RowOptions for a complete row
export const Row = styled(Flex)`
  ${minWidth}
  ${maxWidth}
`;

Row.defaultProps = {
  flexDirection: "row",
  flexWrap: "wrap",
  p: 1,
  width: 1,
  minWidth: "430px",
  // minWidth: ['400px', '500px'],
  maxWidth: "500px",
  alignItems: "center"
};


export const RowLabelWrapper = styled(Flex)`
  //align-content: center;
`;

RowLabelWrapper.defaultProps = {
  alignItems: "center",
  width: 3 / 16,
  p: 1
};


export const RowOptions = styled(Flex)`
  ${maxWidth}
`;

RowOptions.defaultProps = {
  width: [13 / 16], // add responsive widths here
  flexDirection: "row"
};


export const LabelWrapper = styled(Flex)`
 align-items: center;
`;


export const InputWrapper = styled(Box)`

`;

InputWrapper.defaultProps = {
  p: 1
};


export const Input = styled.input`
      display: inline-block;
      //padding: 10px 10px 10px 10px;
      //margin-bottom: 0 !important;
      font-family: 'Open Sans', sans-serif;
      border: none;
      ${color}
      ${width}
      ${space}
`;

Input.defaultProps = {
  color: "colorGreyDark2",
  bg: "colorGreyLight1",
  width: "95%",
  p: "10px",
  mb: 0,
  fontSize: "1.6em"
};
