import React from "react";
import PropTypes from "prop-types";
import {Form, Field} from "react-final-form";
import {FormDropdown} from "../Base";
import {Row} from "./styled";
import {ASSIGN} from "./utils";
/*
// This component takes a personnelList and provides a dropdown with a single "personnel" field
 */
const AssignPersonnelForm = props => {
  const {onSubmit, personnelList, editAccess, validate, action, handleCancel} = props;
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form onSubmit={handleSubmit} className={"assign-personnel-form"}>
          <Field name={"action"} component={"input"} type={"hidden"} defaultValue={action}/>
          <Row py={2} justifyContent={"center"}>

            <FormDropdown
              options={personnelList}
              isDisabled={!editAccess}
              name="personnel"
              label="ASSIGN TO"
              format={(value, name) => {
                if (value && typeof value === "number") {
                  return personnelList.find(el => el.id === value);
                }
                return (value ? {
                  value: value.id || value.value,
                  label: value.name || value.label
                } : "");
              }}
              parse={(value, name) => {
                return (value ? (value.id || value.value) : "");
              }}
            />
          </Row>

          <Row py={2} alignItems={"center"}>

            <button className="item-details-form-button-pane submit-schedule" type="submit">
              {action === ASSIGN ? "ASSIGN" : "REMOVE"} PERSONNEL
            </button>

            {handleCancel &&
            <button className="item-details-form-button-pane submit-schedule" onClick={handleCancel}>
              Cancel
            </button>
            }
          </Row>
        </form>
      )}
    />

  );
};

AssignPersonnelForm.propTypes = {
  onSubmit: PropTypes.func,
  personnelList: PropTypes.array,
  editAccess: PropTypes.bool,
  action: PropTypes.number,
  handleCancel: PropTypes.func
};

AssignPersonnelForm.defaultProps = {
  editAccess: true,
  action: ASSIGN // 1 for Add, 2 for Remove
};

export default AssignPersonnelForm;
