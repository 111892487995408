import {combineReducers} from "redux";
// import types from "./types";
import {buildParamsString} from "./utils";

const getPageUrlFromAction = ({meta: {params}}) =>
  buildParamsString(params);


const getPageFromAction = ({meta: {page}}) => page;

const createPaginationReducer = (types) => {
  const view = (state = "list", action) => {
    switch (action.type) {
    case types.CHANGE_VIEW:
      return action.payload;
      // case types.GET_PAGE_SUCCESS:
      //   return false;
      // case types.GET_PAGE_FAILURE:
      //   return false;
    default:
      return state;
    }
  };
  const pageSize = (state = 12, action) => {
    switch (action.type) {
    case types.GET_PAGE_REQUEST:

      return action?.meta?.params?.page_size ?? state;
      // case types.GET_PAGE_SUCCESS:
      //   return false;
      // case types.GET_PAGE_FAILURE:
      //   return false;
    default:
      return state;
    }
  };

  // REDUCER
  const requested = (state = false, action) => {
    switch (action.type) {
    case types.GET_PAGE_REQUEST:
      return true;
    case types.GET_PAGE_SUCCESS:
      return false;
    case types.GET_PAGE_FAILURE:
      return false;
    default:
      return state;
    }
  };

  const byId = (state = {}, action) => {
    // console.log("action", action);
    const entry = {}; // OUTSIDE BECAUSE OF LINTER
    switch (action.type) {
    case types.GET_PAGE_SUCCESS:
      if (action.error) {
        return state;
      }
      for (let i = 0; i < action.payload.results.length; i += 1) {
        const item = action.payload.results[i];
        entry[item.id] = item;
      }
      return {
        ...state,
        ...entry,
      };
    case types.GET_ITEM_SUCCESS:
      const details = action.payload;
      entry[details.id] = details;
      return {
        ...state,
        ...entry,
      };
    default:
      return state;
    }
  };

  const ids = (state = [], action) => {
    switch (action.type) {
    case types.GET_PAGE_SUCCESS:
      if (action.error) {
        return state;
      }
      const resultIDs = action.payload.results.map(o => o.id);
      return [
        // removes duplicate ids
        ...new Set([...state, ...resultIDs])
      ];
    case types.GET_ITEM_SUCCESS:
      if (action.error) {
        return state;
      }
      if (state.includes(action.payload.id)) {
        return state;
      }
      return [
        ...state,
        action.payload.id,
      ];
    default:
      return state;
    }
  };

  const errored = (state = false, action) => {
    switch (action.type) {
    case types.GET_PAGE_REQUEST:
      return false;
    case types.GET_PAGE_SUCCESS:
      return false;
    case types.GET_PAGE_FAILURE:
      return true;
    default:
      return state;
    }
  };

  const currentPage = (state = 0, action) => {
    switch (action.type) {
    case types.SET_ITEMS_CURRENT_PAGE:
      return action.payload.page;
    default:
      return state;
    }
  };

  const currentParams = (state = "default", action) => {
    switch (action.type) {
    case types.SET_ITEMS_CURRENT_PAGE:
      const searchKey = buildParamsString(action.payload.params);
      return searchKey;
    default:
      return state;
    }
  };

  const currentCount = (state = 0, action) => {
    switch (action.type) {
    case types.GET_PAGE_SUCCESS:
      return action.payload.count;
    default:
      return state;
    }
  };

  const lastPage = (state = 0, action) => {
    switch (action.type) {
    case types.GET_PAGE_SUCCESS:
      if (action.error) {
        return state;
      }
      return action.payload.pageCount - 1;
    default:
      return state;
    }
  };

  const pages = (state = {default: {}}, action) => {
    let pageIds;
    switch (action.type) {
    case types.GET_PAGE_SUCCESS:
      if (action.error) {
        return state;
      }
      pageIds = action.payload.results.map(item => item.id);
      const searchKey = getPageUrlFromAction(action);
      const page = getPageFromAction(action);
      return {
        ...state,
        [searchKey]: {
          ...state[searchKey],
          [page]: pageIds,
        },
      };
    default:
      return state;
    }
  };


  return combineReducers({
    byId,
    pageSize,
    currentPage,
    currentParams,
    currentCount,
    errored,
    ids,
    lastPage,
    pages,
    requested,
    view
  });
};


export default createPaginationReducer;

