

class WebSocketService {
  static instance = null;
  callbacks = {};

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connect(channel = "") {
    const path = global.base_socket_url + channel;
    this.socketRef = new WebSocket(path);
    this.socketRef.onopen = () => {
      console.info("WebSocket open");
    };
    this.socketRef.onmessage = e => {
      this.socketNewMessage(e.data);
    };

    this.socketRef.onerror = e => {
      console.log(e.message);
    };
    this.socketRef.onclose = () => {
      console.info("WebSocket closed");
      // this.connect(channel);
    };
  }

  // close() {
  //   this.socketRef
  // }

  socketNewMessage(data) {
    const parsedData = JSON.parse(data);
    console.info("new message", parsedData);

    const action = parsedData.action;
    // const command = parsedData.command;
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (action === "update") {
      this.callbacks[action](parsedData.data);
    }
    // if (command === "new_message") {
    //   this.callbacks[command](parsedData.message);
    // }
  }

  initAssetStream() {
    this.sendMessage({action: "list", stream: "asssets", request_id: "test"});
  }

  initChatUser(username) {
    this.sendMessage({command: "init_chat", username: username});
  }

  fetchMessages(username) {
    this.sendMessage({command: "fetch_messages", username: username});
  }

  newChatMessage(message) {
    this.sendMessage({command: "new_message", from: message.from, text: message.text});
  }

  addCallbacks(messagesCallback, newMessageCallback) {
    this.callbacks["update"] = messagesCallback;
    this.callbacks["new_message"] = newMessageCallback;
  }

  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({...data}));
    }
    catch (err) {
      console.log(err.message);
    }
  }

  state() {
    return this.socketRef.readyState;
  }

  waitForSocketConnection(callback) {
    const socket = this.socketRef;
    const recursion = this.waitForSocketConnection;
    setTimeout(
      function () {
        if (socket.readyState === 1) {
          console.info("Connection is made");
          if (callback !== null) {
            callback();
          }
          return;
        } else {
          console.info("wait for connection...");
          recursion(callback);
        }
      }, 1); // Wait 5 milisecond for the connection...
  }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
