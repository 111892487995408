import React from "react";
import PropTypes from "prop-types";
import {Box, Flex, Heading} from "../../../Common/Base";
import {OrderPalletDrawer} from "../../Drawers";
import {OrderPalletTable} from "../../Table";
import {formatInventoryForOrderPallet} from "../../utils/inventory";
import PackContainerModal from "../../Modals/PackContainerModal";
import {getContactSource, hasContact} from "../../utils/orders";

const OrderPalletSection = props => {
  const {
    orderDetails,
    facilities,
    personnelList,
    handleCreatePallet,
    orderType,
    formatEdit,
    formatSubmit,
    createAddress,
    packItems
  } = props;
  const addButtonDisabled = !hasContact(orderDetails, orderType);
  const contactType = getContactSource(orderType);

  return (
    <Flex p={2} flexDirection={"column"}>
      <Box>
        <Heading py={2}>
          Pallets
          <OrderPalletDrawer
            key={"add-pallet"}
            onSubmit={handleCreatePallet}
            formProps={{facilities: facilities, personnel: personnelList, orderType: orderType}}
            orderDetails={orderDetails}
            formatEdit={formatEdit}
            formatSubmit={formatSubmit}
            createAddress={createAddress}
            addButtonDisabled={addButtonDisabled}
            contactType={contactType}
          />
          {packItems &&
            <PackContainerModal
              onSubmit={packItems}
              disabled={addButtonDisabled}
              contactType={contactType}
            />
          }
        </Heading>

      </Box>

      {orderDetails &&
      <OrderPalletTable
        type={orderType}
        data={orderDetails.pallets}
        onSubmit={(values) => console.log("pallet values", values)}
      />
      }
    </Flex>
  );
};

OrderPalletSection.propTypes = {
  orderDetails: PropTypes.object,
  facilities: PropTypes.array,
  personnelList: PropTypes.array,
  handleCreatePallet: PropTypes.func,
  orderType: PropTypes.string,
  formatEdit: PropTypes.func,
  formatSubmit: PropTypes.func,
  createAddress: PropTypes.func.isRequired,
  packItems: PropTypes.func
};

OrderPalletSection.defaultProps = {
  formatSubmit: formatInventoryForOrderPallet,
  packItems: null
};

export default OrderPalletSection;
