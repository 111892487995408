import types from "./types";
import {name as endpoint} from "../personnel";
import base from "../base/actions";
import {constructRoute} from "../base/utils";
import {BULK_DELETE} from "../../routes/constants";

const getAllSuccess = data => ({
  type: types.GET_ALL_SUCCESS,
  payload: data
});

const getAllFailure = error => ({
  type: types.GET_ALL_FAILURE,
  payload: error
});

const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

/* Fetch Item Shifts */
const getItemShifts = (id, range = "") => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/shifts/${range}`,
  types: [
    types.GET_ITEM_SHIFTS_REQUEST,
    {
      type: types.GET_ITEM_SHIFTS_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_SHIFTS_FAILURE
  ]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});


const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const bulkDelete = (data) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${BULK_DELETE}`),
  body: JSON.stringify(data),
  types: [
    types.BULK_DELETE_REQUEST,
    types.BULK_DELETE_SUCCESS,
    types.BULK_DELETE_FAILURE
  ]
});

const sendInvitationEmail = (id, email) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/invite`,
  body: JSON.stringify({email: email}),
  types: [types.SEND_INVITATION_REQUEST, types.SEND_INVITATION_SUCCESS, types.SEND_INVITATION_FAILURE]
});

const cancelInvitationEmail = (id, email) => base.deleteRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/invite`,
  body: JSON.stringify({email: email}),
  types: [types.CANCEL_INVITATION_REQUEST, types.CANCEL_INVITATION_SUCCESS, types.CANCEL_INVITATION_FAILURE]
});

const uploadCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}csv/${org}/`,
  headers: {},
  body: data,
  types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE]
});

/* SimplePagination related actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  payload: by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

const reverseList = () => ({
  type: types.REVERSE_LIST
});

/* View related actions. */
const changeViewList = () => ({
  type: types.CHANGE_VIEW_LIST
});

const changeViewTile = () => ({
  type: types.CHANGE_VIEW_TILE
});

const changeViewTable = () => ({
  type: types.CHANGE_VIEW_TABLE
});

const changeViewCalendar = () => ({
  type: types.CHANGE_VIEW_CALENDAR
});

const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

/* Update item actions. */

// const sendInvitationSuccess = item => ({
//   type: types.SEND_INVITATION_SUCCESS,
//   payload: item
// });

// const sendInvitationFailure = error => ({
//   type: types.SEND_INVITATION_FAILURE,
//   payload: error
// });

// const cancelInvitationSuccess = item => ({
//   type: types.CANCEL_INVITATION_SUCCESS,
//   payload: item
// });

// const cancelInvitationFailure = error => ({
//   type: types.CANCEL_INVITATION_FAILURE,
//   payload: error
// });

export default {
  getAllSuccess,
  getAllFailure,
  getItem,
  getItemShifts,
  getItemNotes,
  submitNote,
  updateItem,
  sendInvitationEmail,
  cancelInvitationEmail,
  uploadCsv,
  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  reverseList,
  changeViewList,
  changeViewTile,
  changeViewTable,
  changeViewCalendar,
  clearMessages,
  bulkDelete,
  // SendInvitationSuccess,
  // sendInvitationFailure,
  // cancelInvitationSuccess,
  // cancelInvitationFailure
};
