import React, {useState} from "react";
import PropTypes from "prop-types";
import {AntdText, Box, Flex} from "Base";
import {Space} from "antd";
import {PopConfirmButton, ToggleButton} from "../../Common/Base/Button";
import ProductTemplateTable from "./ProductTemplateTable";
import ProductTemplateForm from "./ProductTemplateForm";

import {crudActions} from "../../../types/propTypes";
import TemplateMaterialForm from "./TemplateMaterialForm";

const TemplateListItem = ({data, templateActions, materialActions, templateMaterialActions, materialPagination, itemsCurrentPage}) => {
  const [showForm, setShowForm] = useState(false);
  const [showMaterialForm, setShowMaterialForm] = useState(false);

  const toggleForm = () => setShowForm(!showForm);
  const toggleMaterialForm = () => setShowMaterialForm(!showMaterialForm);

  const refreshPage = () => templateActions.getPage(itemsCurrentPage);

  const handleSubmitTable = async (values) => {
    const {id, quantity} = values;
    const submitData = {
      quantity,
    };
    let res = await templateMaterialActions.update(data.id, id, submitData);
    if (!res.error) {
      refreshPage();
    }
  };

  const handleDeleteMaterial = async (id) => {
    await templateMaterialActions.delete(data.id, id);
    refreshPage();
  };

  const handleDeleteTemplate = async () => {
    await templateActions.delete(data.id);
    refreshPage();
  };


  const handleAddMaterial = async (values) => {
    console.log("add material", values);
    const res = await templateMaterialActions.create(data.id, values);
    if (!res.error) {
      toggleMaterialForm();
      templateActions.getPage(itemsCurrentPage);
    }
    return res.payload;
  };

  const renderForm = () => {
    const handleSubmit = async (values) => {
      await templateActions.update(data.id, values);
      toggleForm();
    };
    return showForm ?
      <ProductTemplateForm
        onSubmit={handleSubmit}
        initialValues={data}
      />
      :
      <AntdText>{data?.description ?? null}</AntdText>;
  };

  const renderMaterialForm = () => {
    return showMaterialForm ?
      <TemplateMaterialForm
        onSubmit={handleAddMaterial}
        materialActions={materialActions}
        materialPagination={materialPagination}
        org={data?.org ?? 0}
        values={{}}
      />
      :
      null;
  };
  return (
    <Flex flexDirection={"column"} className={"template-list-item"}>

      {/* <Flex flexDirection={"row"} p={1}>*/}
      <Box pb={1}>
        {renderForm()}

      </Box>
      <Box pb={1}>
        <Space>
          <ToggleButton
            toggled={showForm}
            text={"Cancel"}
            size={"small"}
            onClick={toggleForm}
            disabled={showMaterialForm}
            className={"edit-template"}
          >
            Edit
          </ToggleButton>

          <ToggleButton
            toggled={showMaterialForm}
            onClick={toggleMaterialForm}
            text={"Cancel"}
            disabled={showForm}
            size={"small"}
            className={"add-material"}
          >
            Add Material
          </ToggleButton>
          <PopConfirmButton
            size={"small"}
            className={"delete-template"}
            type={"danger"}
            onConfirm={handleDeleteTemplate}>
            Delete
          </PopConfirmButton>
        </Space>
      </Box>
      {/* </Flex>*/}
      {renderMaterialForm()}

      <ProductTemplateTable
        data={data.materials}
        onSubmit={handleSubmitTable}
        onDelete={handleDeleteMaterial}
      />

    </Flex>
  );
};

TemplateListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  templateActions: crudActions.isRequired,
  templateMaterialActions: crudActions.isRequired,
  materialActions: crudActions.isRequired,
  materialPagination: PropTypes.object,
};

TemplateListItem.defaultProps = {
  materialPagination: {
    itemsPaged: [],
  },
};

export default TemplateListItem;
