import styled from "@emotion/styled";
// import PropTypes from ''
import {Box, Content, Flex, Layout} from "../Base";
import {zIndex, space, overflow} from "styled-system";

const HeaderWrapper = styled(Box)`
  //position: fixed;
  top: 0;
  left: 0;
  //width: 100vw;
  .header {
    background-color: white;
  }
  ${zIndex};
`;

HeaderWrapper.defaultProps = {
  zIndex: 2,
  flexDirection: "column",
  flexWrap: "wrap",
  // position: ['default', 'fixed']
};

const NavWrapper = styled(Flex)`
  z-index: 2;
`;

NavWrapper.defaultProps = {
  flexDirection: "row",
  flexWrap: "wrap"
};

const MainLayout = styled(Layout)`
  ${space};
  ${zIndex};
   .site-layout-sider {
    overflow: auto;
    height: 100vh;
    //padding-top: 160px;
    position: relative;
    left: 0;
    //min-height: calc( 100vh - 315px );

    @media(max-width: 990px) {
        z-index: 2;
    }
    @media(max-width: 660px) {
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 500;

    }
}
`;

const ContentLayout = styled(Layout)`
  ${space};
  min-height: calc( 100vh - 315px );

`;


ContentLayout.defaultProps = {
  p: 0,

};

const MainContent = styled(Content)`
  ${space};
  ${zIndex};
  ${overflow};
`;

MainContent.defaultProps = {
  // overflow: "initial",
  m: 0,
  pt: [0, 10],
  p: [1, 10]
};

export {HeaderWrapper, NavWrapper, MainLayout, MainContent, ContentLayout};
