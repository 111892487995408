import qs from "query-string";

// based on http://stackoverflow.com/a/7616484/1836434
export const hashUrl = (url) => {
  let hash = 0, i, chr, len;
  if (url.length === 0) return hash;
  for (i = 0, len = url.length; i < len; i++) {
    chr = url.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};


export const buildParamsString = (params) => {
  // paramString will be used as the key in redux to find pages
  let paramString = "default";
  if (Object.keys(params).length > 0) {
    paramString = qs.stringify(params);
  }
  return paramString;
};

export const buildPageString = (page = 1, params = {}) => {
  let queryString = `?page=${page}`;
  // param String will be used as the key in redux to find pages
  let paramString = buildParamsString(params);
  if (paramString !== "default") {
    queryString = queryString + `&${paramString}`;
  }
  return queryString;
};

export const buildSuffix = (pageArgName, page, params) => {
  // const parsedParams = qs.parse(params);
  // console.log("suffix parsed params", parsedParams);
  let finalParsedParams = {};
  let startString = "";
  // for (let key of Object.keys(parsedParams)) {
  //   if (parsedParams[key] === null) {
  //     startString += key;
  //   }
  //   else {
  //     finalParsedParams = {
  //       ...finalParsedParams,
  //       [key]: parsedParams[key]
  //     };
  //   }
  // }
  startString = startString === "" ? "?" : startString + "?";

  // if (Object.keys(finalParsedParams).length > 0 && qs.extract(params) !== "") {
  //   startString = params.replace(qs.extract(params), "");
  // }
  return startString + qs.stringify({
    ...finalParsedParams,
    [pageArgName]: page
  }, {encode: false}).replace(startString, "");
};


export const fetchPageWithDefaults = ({dispatch, action, page, params, defaultParams}) => {
  const finalParams = {
    ...defaultParams,
    ...params,
  };
  dispatch(action({page, params: finalParams}));
};
