import React, {useState, Fragment} from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import moment from "moment-timezone";
import {Condition, FormDropdown, FormField} from "../../Common/Forms/Base";
import {booleanOptions} from "./constants.js";
import {isDateHighlighted, postLoadFormat} from "./utils";
import {Box, Flex, Row, SingleDatePicker} from "../../Common/Base";
import {dateAdapter} from "../../Common/Forms/Base/utils";
import CalendarInfo from "./CalendarInfo";
import {ScheduleField} from "../../Common/Fields/Schedules";
import {NumberInputControl} from "../../Common/Base/Inputs/NumberInput";
import {assetSelectors} from "../../../store";

const AdaptedDatePicker = dateAdapter(SingleDatePicker);

const formFieldWidth = ["250px", "250px", "300px"];

// const formWidth = "calc( 100% - 250px )";
const PMOrderForm = props => {
  const [assetId, setAssetId] = useState(null);
  const {
    onSubmit,
    validate,
    initialValues,
    assetList,
    // stockAssetList,
    editAccess,
    orderInspectionNoteVisible,
    availablePersonnel,
    assetsEnabled,
    fetchPersonnel
  } = props;

  const postLoadValues = postLoadFormat(initialValues);
  const assetSelectionDisabled = !editAccess || Boolean(postLoadValues.first_scheduled_date) || Boolean(postLoadValues.location);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={postLoadValues}
      render={({handleSubmit, values}) => {
        let stockAssetList = null;
        if (values.asset) {
          // refresh available personnel if asset changes
          let currentAssetId = values?.asset?.id ?? values.asset;

          if (currentAssetId !== assetId) {
            setAssetId(currentAssetId);
            fetchPersonnel(currentAssetId);
          }
          const stockAsset = assetList.find(item => item.id === values?.asset ?? null);
          stockAssetList = stockAsset?.stocks ?? null;
        }

        const personnelList = assetSelectors.refreshPersonnelForAsset(
          {personnel: availablePersonnel}, assetId);

        return (

          <form id="pm-order-form" className={"item-details-form"} onSubmit={handleSubmit}>
            <Flex
              // flexDirection={"row"}
              // maxW={formWidth}
              // flexDirection={["column", "column", "column", "row"]}
              flexWrap={"wrap"}
              pb={10}
            >
              {assetsEnabled && initialValues.order_type !== "location" &&

                <Fragment>
                  <FormDropdown
                    options={assetList}
                    isDisabled={assetSelectionDisabled}
                    name="asset"
                    label="ASSET NAME"
                    format={(value) => {
                      if (value && typeof value === "number") {
                        return assetList.find(el => el.id === value);
                      }
                      return value ? {
                        value: value.id || value.value,
                        label: value.name || value.label,
                      } : "";
                    }}
                    parse={(value) => {
                      return value ? value.id || value.value : "";
                    }}
                  />


                  <FormDropdown
                    options={stockAssetList}
                    control
                    isDisabled={assetSelectionDisabled || !stockAssetList}
                    name="stockroom_asset"
                    label="STOCKROOM ASSET NAME"
                    format={(value) => {
                      if (value && typeof value === "number") {
                        return stockAssetList.find(el => el.id === value);
                      }
                      return value ? {
                        value: value.id || value.value,
                        label: value.name || value.label,
                      } : "";
                    }}
                    parse={(value) => {
                      return value ? value.id || value.value : "";
                    }}
                  />
                </Fragment>
              }

              <FormField
                minW={formFieldWidth}
                type="text" name="name"
                label="PM ORDER NAME"
                isDisabled={!editAccess}/>

              <FormField
                minW={formFieldWidth}
                type="text"
                name="description"
                label="PM ORDER DESCRIPTION"
                isDisabled={!editAccess}
              />


              <FormField
                withValidation={false}
                name="start"
                label="FIRST PM ORDER DATE"
                isDisabled={!editAccess}
                component={AdaptedDatePicker}
                wrapperProps={{
                  width: "300px"
                }}
                parse={(value) => value ? moment(value).tz(moment.tz.guess()).format() : null}
                calendarOptions={{
                  renderCalendarInfo: () => (
                    <CalendarInfo personnel={personnelList}/>
                  ),
                  isDayHighlighted: (date) => {
                    return isDateHighlighted(personnelList, date);
                  },
                  calendarInfoPosition: "after",
                }}
              />

              <FormDropdown
                options={booleanOptions}
                name="repeating"
                label="REPEATING"
                isDisabled={!editAccess}
                format={(value) => ({value: value, label: value ? "True" : "False"})}
                parse={(value) => value.value}
              />

            </Flex>

            <Condition when="repeating" is={true}>
              <Flex flexWrap={"wrap"} flexDirection={"column"}>
                <ScheduleField name={"repeat"} generatorConfig={{hideHourly: true}}/>
                <Row>
                  <Box width={1 / 2}>
                    <FormField
                      label="Start Time"
                      type="time"
                      name="start_time"
                      readOnlyMode={!editAccess}
                    />
                  </Box>
                  <Box width={1 / 2}>
                    <FormField
                      label="End Time"
                      type="time"
                      name="end_time"
                      readOnlyMode={!editAccess}
                    />
                  </Box>
                </Row>
                <Box width={1 / 2}>
                  <NumberInputControl
                    label={"Hourly?"}
                    name={"hourly"}
                    defaultValue={null}
                  />
                </Box>
              </Flex>
            </Condition>


            <Flex
              flexWrap={"wrap"}
              // flexDirection={["column", "column", "column", "row"]}
            >
              <FormDropdown
                options={booleanOptions}
                name="photo_required"
                label="PHOTO REQUIRED"
                isDisabled={!editAccess}
                format={(value) => ({value: value, label: value ? "True" : "False"})}
                parse={(value) => value.value}
              />

              <FormDropdown
                options={booleanOptions}
                name="inspection_note_required"
                label="INSPECTION NOTE REQUIRED"
                isDisabled={!editAccess}
                format={(value) => ({value: value, label: value ? "True" : "False"})}
                parse={(value) => value.value}
              />
            </Flex>
            {/* Everyone can edit inspection notes */}
            {orderInspectionNoteVisible && (
              <FormField
                className="item-details-pane-right-notes"
                type="textarea"
                name="inspection_note"
                label="INSPECTION NOTE"
                placeholder="Inspection Note..."
                isDisabled={false}
              />
            )}

          </form>
        );
      }}
    />
  );
};

PMOrderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  values: PropTypes.object.isRequired,
  assetList: PropTypes.array,
  accountUserType: PropTypes.number.isRequired,
  accountPersonnelId: PropTypes.number.isRequired,
  stockAssetList: PropTypes.array,
  editAccess: PropTypes.bool,
  selectedMaterial: PropTypes.object,
  repeating: PropTypes.bool,
  createOrderMaterial: PropTypes.func.isRequired,
  updateOrderMaterial: PropTypes.func.isRequired,
  deleteOrderMaterial: PropTypes.func.isRequired,
  addMaterialToOrder: PropTypes.func.isRequired,
  orderInspectionNoteVisible: PropTypes.bool,
  availablePersonnel: PropTypes.array,
  /**
   * If the Asset module is enabled for this org
   */
  assetsEnabled: PropTypes.bool,
};

PMOrderForm.defaultProps = {
  values: {},
  editAccess: false,
  orderInspectionNoteVisible: true,
};

export default PMOrderForm;
