import React from "react";
import PropTypes from "prop-types";
import ScheduleListItem from "./ScheduleListItem";
import {List} from "../../Base";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
/*
Renders a list of Shifts
 */
const ScheduleList = props => {
  const {items, classes, current, isEditable, onRemove} = props;


  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      {items && items.length !== 0 &&
      <List>
        {items.map(item => {
          return (
            <ScheduleListItem
              key={item.id}
              item={item}
              onRemove={onRemove}
              classes={classes}
              current={current}
              isEditable={isEditable}
            />
          );
        })}
      </List>
      }
    </ReactCSSTransitionGroup>
  );
};

ScheduleList.propTypes = {
  items: PropTypes.array,
  classes: PropTypes.any,
  handleItemClick: PropTypes.func,
  onRemove: PropTypes.func,
  isEditable: PropTypes.bool
};

ScheduleList.defaultProps = {
  isEditable: false,
};

export default ScheduleList;
