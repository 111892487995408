/**
 * Creates a CSV column name, to be used with the blank CSV download
 * @param {string} columnName The name of the column
 * @returns {object} The CSV column
 */
function makeCsvColumn(columnName) {
  return {
    id: columnName,
    displayName: columnName
  };
}

const barcodeCsvFields = [
  makeCsvColumn("barcode_data"),
  makeCsvColumn("barcode_format")
];

const baseCsvFields = [
  makeCsvColumn("note")
];

export {makeCsvColumn, barcodeCsvFields, baseCsvFields};
