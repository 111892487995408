import React, {Fragment} from "react";
import ReactTooltip from "react-tooltip";
import {Button, Heading} from "../Base";
/* This is a small controls area located on the left side of a Details page (i.e. PersonnelDetailsContainer)
that will include admin only functionality. */
const AdminControlsDetails = ({deleteItem, controlsSubmitting, controlsMessage, editMode}) => {
  return (
    <Fragment>
      <ReactTooltip/>
      <Heading color={"colorPrimary"} display="inline-block">ADMIN CONTROLS</Heading>
      <div className="tooltip-circle" data-tip="This area is only accessible to admins."/>
      <Button
        danger
        // type={'primary'}
        block
        className="item-details-pane-left-control admin-delete-button button"
        onClick={deleteItem}
        loading={controlsSubmitting}>
          Delete
      </Button>
      {(editMode) &&
      <Button
        type={"primary"}
        block
        className="item-details-pane-left-control"
        onClick={editMode}>TOGGLE EDIT</Button>}
      {controlsMessage && <p className="text-center">{controlsMessage}</p>}
    </Fragment>
  );
};

export default AdminControlsDetails;
