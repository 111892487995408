import React from "react";
import PropTypes from "prop-types";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY} from "../../utils/constants";
import {Table} from "../../../Common/Base";
import {renderLocationInventoryTitle} from "../../utils/inventory";
import {capitalize} from "lodash-es";

const getQuantityKey = (type) => {
  // if (type === PRODUCT_INVENTORY) {
  //   return "pallet_inventory_quantities";
  // }
  return "quantities";
};

// InventoryTable with expandable Rows for quantities.
const InventoryTable = ({items, type}) => {
  const expandedRowRender = (record) => {
    const quantityKey = getQuantityKey(type);
    // console.log("expanded record", record);
    const columns = [
      {
        title: "Location",
        dataIndex: "location_code",
        key: "location",
        width: "50%",
        // render: (text, record) => record.location.code
      },
      {
        title: "Status",
        dataIndex: "status_display",
        key: "status_display",
        width: "25%",
        // render: (text, record) => record.location.code
      },
      {
        title: "Quantities",
        dataIndex: "quantity",
        key: "quantity",
        width: "25%",
      },
    ];

    const data = record[quantityKey].map((quantityItem, i) => {
      const childKey = `${record.id}-${i}`;
      return {
        key: childKey,
        ...quantityItem,
      };
    });
    return (
      <Table
        className={`${type}-quantity-table`}
        rowClassName={`${type}-quantity-row`}
        columns={columns}
        dataSource={data}
        pagination={false}/>
    );
  };

  const columns = [
    {
      title: capitalize(type),
      width: "35%",
      dataIndex: `${type}.name`,
      render: (text, record) => renderLocationInventoryTitle(record, type),
    },
    {
      title: "SKU",
      dataIndex: type,
      render: (text, record) => (
        record[type].sku
      ),
      key: "sku",
      width: "35%",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      defaultSortOrder: "descend",
      width: "12.5%",
      sorter: (a, b) => a.allocated_quantity - b.allocated_quantity,
    },
    {
      title: "Allocated",
      dataIndex: "allocated_quantity",
      defaultSortOrder: "descend",
      width: "12.5%",
      sorter: (a, b) => a.allocated_quantity - b.allocated_quantity,
    },
  ];


  return (
    <Table
      className={`${type}-table`}
      columns={columns}
      expandable={{expandedRowRender}}
      dataSource={items}
      rowClassName={`${type}-row`}
    />
  );
};

InventoryTable.propTypes = {
  items: PropTypes.array,
  type: PropTypes.oneOf([PRODUCT_INVENTORY, MATERIAL_INVENTORY]),
  quantityKey: PropTypes.oneOf(["pallet_inventory_quantities", "quantities"]),
};

export default InventoryTable;
