import React from "react";
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";
import {AdaptedTextarea} from "../../Common/Base/Inputs/AdaptedInputs";
import {parseText} from "../../Common/Forms/Base/utils";
import {Box} from "../../Common/Base";
import {SubmitButton} from "../../Common/Forms/styled";

const ProductTemplateForm = (props) => {
  const {
    onSubmit,
    initialValues,
    id
  } = props;
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit, submitting}) => (
        <form
          id={id}
          className={"product-template-form "}
          onSubmit={handleSubmit}>

          <Field name={"product"} component={"input"} type={"hidden"}/>
          <Field name={"org"} component={"input"} type={"hidden"}/>

          <Field
            placeholder={"Description"}
            type="text"
            name="description"
            component={AdaptedTextarea}
            parse={parseText}
            format={parseText}
          />

          <Box py={3} mb={3}>
            <SubmitButton
              htmlType="submit"
              className="submit-template item-details-form-button"
              loading={submitting}
            >
              Save
            </SubmitButton>
          </Box>


        </form>
      )}
    />
  );
};

ProductTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  id: PropTypes.string
};

ProductTemplateForm.defaultProps = {
  initialValues: null,
  id: "product-template-form"
};

export default ProductTemplateForm;
