import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormField} from "../Base";
import PositiveNumberField from "../Base/PositiveNumberField";
import {SubmitButton} from "../styled";

const ContactInfo = props => {
  const {
    handleSubmit,
    validate,
    contact_name,
    contact_title,
    phone_number,
    email
  } = props;

  const initialValues = {
    contact_name,
    contact_title,
    phone_number,
    email
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        pristine,
        invalid,
        submitting,
        values
      }) => (
        <form className={"item-details-form serv-prov-info"} onSubmit={handleSubmit}>
          <FormField
            label="Contact Name"
            placeholder="Name"
            type="text"
            name="contact_name"
          />
          <FormField
            label="Contact Title"
            placeholder="Title"
            type="text"
            name="contact_title"
          />
          <PositiveNumberField
            label="Phone Number"
            placeholder="Phone"
            name="phone_number"
          />
          <FormField
            label="Email"
            placeholder="Email"
            type="text"
            name="email"
          />
          <br/>
          <SubmitButton className="item-details-form-button" loading={submitting}>Submit</SubmitButton>
        </form>
      )
      }
    />
  );
};

ContactInfo.propTypes = {
  handleSubmit: PropTypes.func,
  validate: PropTypes.func
};

ContactInfo.defaultProps = {
  validate: (values) => null
};

export default ContactInfo;
