import React from "react";
import PropTypes from "prop-types";
import RRuleGenerator from "./lib";


const defaultConfig = {
  hideEnd: true,
  hideStart: true,
  hideHourly: false,
  weekStartsOnSunday: true
};

const ScheduleRule = props => {
  const {value, onChange, generatorConfig} = props;

  let config = {...defaultConfig, ...generatorConfig};

  return (
    <RRuleGenerator
      onChange={onChange}
      value={value}
      config={config}
    />
  );
};

ScheduleRule.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  generatorConfig: PropTypes.object
};

ScheduleRule.defaultProps = {
  generatorConfig: defaultConfig
};


export default ScheduleRule;
