import styled from "@emotion/styled";
import {Flex, Button as BaseButton} from "../Base";
import {Input} from "@rebass/forms";
import {adapt} from "./Base/utils";

export const Row = styled(Flex)`

`;

Row.defaultProps = {
  flexDirection: "row",
  flexWrap: "wrap"
};

export const CreateButton = styled(BaseButton)``;


export const SecondaryButton = styled(BaseButton)`
  margin: 20px auto;
`;

SecondaryButton.defaultProps = {
  width: ["120px", "180px"],
  p: "10px 15px 10px 20px",
  fontSize: ["14px"],
  m: "10px auto"
};

export const SubmitButton = styled(BaseButton)`
  //font-size: 1.4rem;
  width: 180px;
  margin: 0 auto;
  display: block;
`;

SubmitButton.defaultProps = {
  type: "primary",
  htmlType: "submit"
};

export const AdaptedInput = adapt(Input);
