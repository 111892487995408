import React, {Component, Fragment} from "react";
import {connect} from "react-redux";

import {
  authOperations,
  authSelectors,
  orgActions,
  orgSelectors,
  scheduledShiftActions,
  scheduledShiftOperations,
  scheduledShiftSelectors,
  receiveNotification,
  notificationActions, paginationSelectors,
} from "../../store";

import Header from "../../components/Common/Header";
import {ActivityLogListener, NotificationListener} from "../../services";
import {ErrorBoundary} from "../../components/Common/Base";

class HeaderContainer extends Component {
  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    const {getActiveShiftActivity, getRecentActivity, notifyActions} = this.props;
    getActiveShiftActivity();
    getRecentActivity(1);
    notifyActions.getPage(1);
  };

  renderListeners = () => {
    const {receiveLog, isAuthenticated, notifyActions} = this.props;

    return isAuthenticated && process.env.REACT_APP_ENV !== "development" ?
      <ErrorBoundary>
        <ActivityLogListener onReceive={receiveLog}/>
        <NotificationListener onReceive={notifyActions.receive}/>
      </ErrorBoundary>
      :
      null;
  }

  render() {
    return (
      <Fragment>
        {this.renderListeners()}
        <Header {...this.props}/>
      </Fragment>
    );
  }
}

/* Here we will map Redux's store state to props to be accessed and manipulated within the
context of this container */
const mapStateToProps = state => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  isAuthenticated: authSelectors.isAuthenticated(state.account),
  logList: orgSelectors.refreshLogs(state.orgs),
  activeActivity: scheduledShiftSelectors.refreshActiveActivity(state.schedules),
  recentActivity: scheduledShiftSelectors.refreshRecentActivity(state.schedules),
  notifications: paginationSelectors.getItems(state.notifications.items)
  // notificationPagination: paginationSelectors.pagination(state.)
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  checkLogin: async () => dispatch(await authOperations.checkLogin()),
  resetStore: () => dispatch(authOperations.logout()),
  getActiveShiftActivity: () => dispatch(scheduledShiftActions.getActiveShiftActivity()),
  toggleShiftActivity: (data) => dispatch(scheduledShiftOperations.toggleShiftActivity(data)),
  getRecentActivity: (page) => dispatch(scheduledShiftActions.getRecentShiftActivity(page)),
  receiveLog: (data) => dispatch(orgActions.receivedLog(data)),
  notifyActions: {
    getPage: (page, params) => dispatch(notificationActions.fetchPage({page, params})),
    receive: (data) => dispatch(receiveNotification(data))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderContainer);
