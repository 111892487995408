import React from "react";

import moment from "moment";
import {Heading} from "Base";
import {ImageWithDefault} from "../../../Common/Details/Layout";
import {TagDisplay} from "../../../Common/Details";

/* Used exclusively by the PMOrderDetailsContainer, this will display an scheduled
PM order's details. */
const StockroomAsset = props => {
  const {
    id,
    name,
    stockroom_asset_name,
    stockroom_tags,
    purchased_price,
    purchase_price_currency,
    purchased_date,
    implemented_date,
    displayImage
  } = props;

  return (
    <div className="item-details-pane-right-generic">
      <a className="item-details-pane-right-generic-content" href={`/#/dashboard/stockroom_asset/${id}/`}>
        <div>
          <ImageWithDefault className="item-details-pane-right-generic-img" src={displayImage} />
        </div>
        <div className="item-details-pane-right-generic-text">
          <Heading as="h3" size="sm">{name || stockroom_asset_name || "Unnamed"}</Heading>
          <TagDisplay tags={stockroom_tags}/>
        </div>
        <div className="item-details-pane-right-generic-text">
          <br />
          <p><strong>Purchased Price: </strong>
            {(purchased_price)
              ? `${purchased_price} ${purchase_price_currency}`
              : "N/A"}</p>
          <p><strong>Purchased Date: </strong>
            {(purchased_date)
              ? `${moment(purchased_date).format("MM-DD-YYYY")}`
              : "N/A"}</p>
          <p><strong>Implemented Date: </strong>
            {(implemented_date)
              ? `${moment(implemented_date).format("MM-DD-YYYY")}`
              : "N/A"}
          </p>
        </div>
      </a>
    </div>
  );
};

export default StockroomAsset;
