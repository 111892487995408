import React from "react";
import PropTypes from "prop-types";
import {Flex, Box, AccordionHeader, AccordionPanel, AccordionIcon} from "../../Base";

import {SecondaryText, PrimaryText, ShiftItem} from "./styled";
import {formatShiftDate} from "utils/dates";

import ShiftOrdersList from "./ShiftOrdersList";

const ListItem = (props) => <Flex p={3} {...props}/>;
const checkHasOrders = (item) => {
  let result = false;
  if (item.scheduled_pm_orders && item.scheduled_pm_orders.length > 0) {
    result = true;
  }
  if (item.vendor_orders && item.vendor_orders.length > 0) {
    result = true;
  }
  if (item.shift_activity && item.shift_activity.length > 0) {
    result = true;
  }
  return result;
};

const ShiftListItem = props => {
  const {item, onClick, ...rest} = props;


  const date = formatShiftDate(item.start);
  let Header = AccordionHeader;
  const hasOrders = checkHasOrders(item);

  if (!hasOrders) {
    Header = ListItem;
  }
  return (
    <ShiftItem className={"shift-list-item"} bg={"white"} onClick={onClick} p={3} {...rest}>
      <Header>
        <Box flex="1" textAlign="left">
          <Flex flexWrap={"wrap"} justifyContent={"space-between"} flexDirection={"row"}>
            <Flex flexDirection={"column"}>
              <PrimaryText fontWeight={"bold"}>{date}</PrimaryText>
              <SecondaryText className={"shift-start-end"}>{item.start_time}{item.end_time && ` - ${item.end_time}`}</SecondaryText>


            </Flex>
            <Flex justifyContent={"center"} flexDirection={"column"} pr={hasOrders ? 0 : "24px"}>
              <PrimaryText>{`${item.schedule.name}`}</PrimaryText>

            </Flex>

          </Flex>
        </Box>
        {hasOrders &&
        <AccordionIcon/>
        }
      </Header>
      {hasOrders &&
      <AccordionPanel p={3}>
        <ShiftOrdersList
          scheduled_pm_orders={item.scheduled_pm_orders}
          vendor_orders={item.vendor_orders}
          shift_activity={item.shift_activity}
        />
      </AccordionPanel>
      }
    </ShiftItem>
  );
};

ShiftListItem.propTypes = {
  item: PropTypes.object,
  isEditable: PropTypes.bool,
  onClick: PropTypes.func,
};

ShiftListItem.defaultProps = {
  onClick: (item) => null,
};

export default ShiftListItem;
