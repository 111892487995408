/** *
 * A Pack Container button that opens a modal with the ContainerPackingForm
 */
import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {TooltipButton, Text} from "../../Common/Base";
import {Modal} from "../../Common";
import ContainerPackingForm from "../Forms/PalletForm/ContainerPackingForm";
import PackingResults from "../ContainerPacking/PackingResults";

const PackContainerModal = props => {
  const [modelOpen, setModalOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [response, setResponse] = useState(null);
  const [message, setMessage] = useState("");
  const {
    editAccess,
    onSubmit,
    disabled,
    contactType,
  } = props;

  const toggleModal = () => {
    setModalOpen(!modelOpen);
  };

  const reset = () => {
    setError(false);
    setResponse(null);
    setMessage("");
  };

  const handleSubmit = async (values) => {
    isError && reset();
    const res = await onSubmit(values);
    console.log("res", res);
    if (res.error) {
      setError(true);
      setMessage("Something went wrong");
    } else {
      setResponse(res.payload);
    }
  };

  const renderBody = () => {
    if (!isError && response) {
      return <PackingResults results={response} reset={reset}/>;
    }
    return modelOpen && (
      <ContainerPackingForm onSubmit={handleSubmit} editAccess={editAccess} error={isError} disabled={disabled}/>

    );
  };

  return (
    <Fragment>
      <TooltipButton
        id={"pack-order-items-btn"}
        onClick={toggleModal}
        size={"small"}
        disabled={disabled}
        tooltip={`Pack all items on to pallets. Select inventory and a ${contactType} to add pallets to order.`}
        style={{marginLeft: 4}}>
        Pack
      </TooltipButton>

      <Modal
        visible={modelOpen}
        onCancel={toggleModal}
        showConfirmButton={false}
        title={"Pack Inventory"}
      >
        <Text fontSize={"sm"}>All Products and Materials must have shipping dimensions set from on their details pages.</Text>
        {renderBody()}
        {isError &&
          <Text fontSize={"sm"} color={"red"}>{message}</Text>
        }
      </Modal>
    </Fragment>
  );
};

PackContainerModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  disabled: PropTypes.bool
};

PackContainerModal.defaultProps = {
  editAccess: true,
  contactType: "customer",
  disabled: false
};

export default PackContainerModal;
