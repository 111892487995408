import React, {Fragment} from "react";
import moment from "moment";
import {Heading} from "../../components/Common/Base";

const PMOrderHistory = ({history}) => {
  return (
    <Fragment>
      <br/>
      <br/>
      <Heading color={"colorPrimary"}>PM Order History</Heading>
      {history && history.length > 0
        ? history.map((order, index) => (
          <a key={index} href={`/#/dashboard/orders/scheduled/${order.id}`}>
            <div className="item-details-pane-right-note">
              <h3>PM Order #{order.id}</h3>
              <p><strong>Status:</strong> {order.status ? order.status.name : "None"}</p>
              <p><strong>Service Date:</strong> {order.date ? moment(order.date).format("MMMM DD, YYYY") : "None"}</p>
            </div>
          </a>
        ))
        : <p className="item-details-alert">No PM order history available.</p>
      }
    </Fragment>
  );
};

export default PMOrderHistory;
