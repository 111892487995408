import scheduledPMOrdersReducer from "./reducers";

/* These exports are the only thing that need to be edited for a new module. */
export const name = "v1/scheduled_pm_orders/";

export {default as scheduledPMOrdersTypes} from "./types";
export {default as scheduledPMOrdersSelectors} from "./selectors";
export {default as scheduledPMOrdersOperations} from "./operations";
export {default as scheduledPMOrdersActions} from "./actions";

export default scheduledPMOrdersReducer;
