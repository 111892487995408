import React from "react";
import PropTypes from "prop-types";
import {PositiveNumberField} from "./Base";
import {antAdapt} from "./Base/utils";
import {Input} from "antd";
const AdaptedInput = antAdapt(Input);

const ShippingDimensions = ({editAccess}) => {
  return (
    <>
      <PositiveNumberField
        component={AdaptedInput}
        label={"Width"}
        name={"width"}
        isDisabled={!editAccess}
        size={"large"}
        precision={2}
      />
      <PositiveNumberField
        component={AdaptedInput}
        label={"Height"}
        name={"height"}
        isDisabled={!editAccess}
        size={"large"}
        precision={2}
      />
      <PositiveNumberField
        component={AdaptedInput}
        label={"Length"}
        name={"depth"}
        isDisabled={!editAccess}
        size={"large"}
        precision={2}
      />

    </>
  );
};

ShippingDimensions.propTypes = {
  editAccess: PropTypes.bool
};

ShippingDimensions.defaultProps = {
  editAccess: true
};

export default ShippingDimensions;
