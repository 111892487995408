import React from "react";
import PropTypes from "prop-types";
import {Box, List, ListItem} from "../../Base";
import {PrimaryText} from "./styled";
import {Link} from "react-router-dom";

const ShiftOrdersList = props => {
  const {vendor_orders, scheduled_pm_orders, shift_activity} = props;

  return (
    <Box>
      {shift_activity.length > 0 &&
      <Box className={"shift-activity-wrapper"}>
        <PrimaryText>Activity</PrimaryText>
        <List>
          {shift_activity.map(item =>
            <ListItem key={item.id}>
              {item.start_time} - {item.end_time}
            </ListItem>
          )}
        </List>
      </Box>
      }
      {scheduled_pm_orders.length > 0 &&
      <Box className={"shift-pm-orders-wrapper"}>
        <PrimaryText>Scheduled PM Orders</PrimaryText>
        <List>
          {scheduled_pm_orders.map(order =>
            <ListItem key={order.id}>
              <Link to={`/dashboard/orders/scheduled/${order.id}`}>
                {order.name && `${order.name} - `} {order.asset_name} - {order.stock_asset_name}
              </Link>
            </ListItem>,
          )}
        </List>
      </Box>
      }
      {vendor_orders.length > 0 &&
      <Box className={"shift-vendor-orders-wrapper"}>
        <PrimaryText>Vendor Orders</PrimaryText>
        <List>
          {vendor_orders.map(order =>
            <ListItem key={order.id}>
              <Link to={`/dashboard/vendor_orders/${order.id}`}>
                {order.title && `${order.title} - `} {order.asset_name} - {order.stock_asset_name}
              </Link>
            </ListItem>,
          )}
        </List>
      </Box>
      }
    </Box>
  );
};

ShiftOrdersList.propTypes = {
  vendor_orders: PropTypes.array,
  scheduled_pm_orders: PropTypes.array,
  shift_activity: PropTypes.array,
};

export default ShiftOrdersList;
