import {
  PRODUCTION_MATERIAL_ROUTE,
  LOCATIONS_ROUTE,
  PALLET_WAREHOUSE_ROUTE,
  PRODUCTS_WAREHOUSE_ROUTE,
  PURCHASE_ORDERS_WAREHOUSE_ROUTE,
  SALES_ORDERS_WAREHOUSE_ROUTE,
  PRODUCTION_LINE_ROUTE
} from "../../../routes/constants";

export const assetHelp = [
  "Conmitto Manager is all about increasing the lifespan of your critical assets.",
  "Get started tracking Assets by clicking by clicking the button below.",
];

export const personnelHelp = [
  "Conmitto Manager works better with your whole team online.",
  "Get started inviting and provisioning team members by clicking the button below."
];

export const pmOrderHelp = [
  "Conmitto Manager provides internal scheduling for Asset preventive and reactive maintenance plans.",
  "Keep Assets in peak condition by scheduling tasks when they are needed and tracking who completes them.",
  "Schedule tasks when they are needed, and track who completes them.",
];

export const vendorHelp = [
  "Conmitto Manager puts leading MRO Specialist firms a click away.",
  "Create a Vendor Order to get help from leading experts to fix routine or edge-case Asset issues.",
  "Vendor Orders can be created while browsing your Organization's Assets, and show up on this page.",
];

export const materialsHelp = [
  "Conmitto Manager makes it simple to track production and maintenance materials."
];

export const palletsHelp = [
  "Conmitto Manager makes it simple to track incoming and outgoing pallets across your facilities."
];

export const productsHelp = [
  "Conmitto Manager makes it simple to track your catalog of finished goods."
];

export const purchaseOrderHelp = [
  "Conmitto Manager makes it simple to create and track Purchase Orders from your suppliers."
];

export const salesOrderHelp = [
  "Conmitto Manager makes it simple to create and track Sales Orders to your customers."
];

export const productionLineHelp = [
  "Conmitto Manager makes it simple to create and manage Production Lines for building your products."
];

export const locationHelp = [
  "Conmitto Manager makes it simple to manage your Locations and get in depth info on what goes on at each."
];

export const helpItems = [
  {data: assetHelp, name: "Asset Information", url: "assets", id: "assetsHelp"},
  {data: materialsHelp, name: "Materials Information", url: PRODUCTION_MATERIAL_ROUTE, id: "materialsHelp"},
  {data: personnelHelp, name: "Personnel Information", url: "personnel", id: "personnelHelp"},
  {data: pmOrderHelp, name: "PM Order Information", url: "orders", id: "orderHelp"},
  {data: vendorHelp, name: "Vendor Information", url: "vendors", id: "vendorHelp"},
  {data: locationHelp, name: "Locations", url: LOCATIONS_ROUTE, id: "locationHelp"},
  {data: palletsHelp, name: "Pallets Information", url: PALLET_WAREHOUSE_ROUTE, id: "palletsHelp"},
  {data: productsHelp, name: "Product Information", url: PRODUCTS_WAREHOUSE_ROUTE, id: "productsHelp"},
  {data: purchaseOrderHelp, name: "Purchase Order Information", url: PURCHASE_ORDERS_WAREHOUSE_ROUTE, id: "poHelp"},
  {data: salesOrderHelp, name: "Sales Order Information", url: SALES_ORDERS_WAREHOUSE_ROUTE, id: "soHelp"},
  {data: productionLineHelp, name: "Production Lines", url: PRODUCTION_LINE_ROUTE, id: "productionLineHelp"},
];
