import React, {Component} from "react";
import {connect} from "react-redux";
import {actions, operations, scheduleActions} from "../../store/orders/pmOrders";
import {
  assetActions, assetOperations,
  assetSelectors,
  authSelectors,
  baseSelectors,
  materialOperations,
  materialPageActions, orgOperations,
  orgSelectors,
  paginationSelectors,
} from "../../store";
import PMOrderDetails from "../../components/PMOrders/Details";
import {scrollToTop} from "../../utils/containerUtils";

const mapStateToProps = (state, ownProps) => {
  let assetId = null;

  const itemID = ownProps.match.params.orderID;
  let orderDetails = state.orders.pmOrders.items.byId[ownProps.match.params.orderID];
  if (orderDetails && orderDetails.asset && orderDetails.asset.id) {
    assetId = orderDetails.asset.id;
  }
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    defaultOrg: authSelectors.defaultOrgID(state.account),
    details: paginationSelectors.getItem(
      state.orders.pmOrders.items,
      itemID,
    ),
    notes: baseSelectors.refreshNotes(state.orders.pmOrders, ownProps.match.params.orderID),
    materialPagination: paginationSelectors.pagination(state.materials.items),
    schedulePagination: paginationSelectors.pagination(state.orders.scheduledPm.items),
    assetList: assetSelectors.refreshNameList(state.assets),
    locationList: orgSelectors.refreshLocations(state.orgs),
    availablePersonnel: assetSelectors.refreshPersonnel(state.assets, assetId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  console.log("dispatch ownProps", ownProps);
  const itemID = ownProps.match.params.orderID;
  const getParams = "?expand=barcode,org,location";

  return {
    actions: {
      create: (data) => dispatch(operations.createItem(data)),
      update: (id, data) => dispatch(operations.updateItem(id, data)),
      getItem: (id = itemID) => dispatch(operations.getItem(id, getParams)),
      delete: (id) => dispatch(operations.deleteItem(id))
    },
    noteActions: {
      addNote: async (id, data) => dispatch(await operations.addNote(id, data)),
      fetchNotes: (id) => dispatch(actions.getItemNotes(id)),
      updateNote: (id, data) => dispatch(actions.updateNote(itemID, id, data))
    },
    scheduleActions: {
      create: (order_id, data) => dispatch(scheduleActions.createItem(order_id, data)),
      update: (order_id, id, data) => dispatch(scheduleActions.updateItem(order_id, id, data)),
      delete: (order_id, id) => dispatch(scheduleActions.deleteItem(order_id, id)),
      getPage: (page = 1, params = {}) => {
        const finalParams = {
          ...params,
          pm_order: itemID,
          expand: "location"
        };
        return dispatch(scheduleActions.fetchPage({page, params: finalParams}));
      },
    },
    materialActions: {
      getPage: (page = 1, params = {}) => dispatch(materialPageActions.fetchPage({page, params})),
      create: data => dispatch(materialOperations.createItem(data)),
      update: (id, data) => dispatch(materialOperations.updateItem(id, data)),
      delete: id => dispatch(materialOperations.deleteItem(id)),
      // getItem: id => dispatch()
    },
    extra: {
      fetchAvailablePersonnel: (id) => dispatch(assetActions.getQualifiedPersonnel(id)),
      getLocations: (id) => dispatch(orgOperations.getLocations(id)),
      fetchNotes: (id) => dispatch(actions.getItemNotes(id)),
      getAssetList: () => dispatch(assetOperations.getAllItems()),

    }
  };
};

class DetailsContainer extends Component {
  componentDidMount() {
    scrollToTop();
  }
  render() {
    return (
      <PMOrderDetails {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsContainer);
