import React from "react";
import PropTypes from "prop-types";
import {OrderMaterialForm} from "../Forms";
import {crudActions, paginationProps} from "../../../types/propTypes";
import {preSaveFormat} from "../Forms/utils";


const MaterialSection = props => {
  const {
    onSubmit,
    accountInfo,
    details,
    actions,
    pagination,
  } = props;

  const materialList = pagination.itemsPaged;

  const handleUpdate = async (id, values) => {
    await actions.update(id, values);
    actions.getPage(pagination.itemsCurrentPage);
  };

  const handleSubmitMaterial = async (value) => {
    const data = preSaveFormat({materials: value});
    return onSubmit(details.id, data);
  };

  const materialFormProps = {
    materialList,
    createOrderMaterial: actions.create,
    updateOrderMaterial: handleUpdate,
    deleteOrderMaterial: actions.delete,
    addMaterialToOrder: handleSubmitMaterial,
    deleteMaterialFromOrder: handleSubmitMaterial,
    orderDetails: details,
    editAccess: details?.permissions?.write ?? false,
    accountUserType: accountInfo?.user_type ?? null,
    accountPersonnelId: accountInfo?.personnel?.id ?? null
  };
  return (
    <OrderMaterialForm
      initialValues={details}
      onSubmit={handleSubmitMaterial}
      {...materialFormProps}
    />
  );
};

MaterialSection.propTypes = {
  /**
   * update action for order adding materials to
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Material CRUD actions
   */
  actions: crudActions.isRequired,
  /**
   * Logged in user's accountInfo
   */
  accountInfo: PropTypes.object.isRequired,
  /**
   * Order details
   */
  details: PropTypes.object.isRequired,
  /**
   * Material pagination
   */
  pagination: paginationProps.isRequired,
};

export default MaterialSection;
