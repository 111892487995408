import {renderInventoryTitle} from "../../Warehouse/utils/inventory";
import {MATERIAL_INVENTORY} from "../../Warehouse/utils/constants";


export const templateColumns = [
  {
    title: "Material",
    dataIndex: ["material", "name"],
    render: (text, record) => (
      renderInventoryTitle(record.material, MATERIAL_INVENTORY)
    ),
    key: "material",
    width: "60%",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "40%",
    editable: true
  }
];
