import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {isInclusivelyAfterDay} from "react-dates";
import {datePopoverContainerStyles} from "../styles/styles";
import {DatePickerWrapper} from "../styles/default";
import "react-dates/initialize";

import {Box, Input as BaseInput, InputGroup} from "@chakra-ui/core";
import Popover from "react-tiny-popover";
import moment from "moment";
import DayPickerRangeControllerWrapper from "./DayPickerRangerControllerWrapper";

const InputWrapper = styled(InputGroup)`
  input::-webkit-calendar-picker-indicator{
    display: none;
}

  input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
}
`;


const DateRangerPicker = (props) => {
  const {
    onChange,
    calendarOptions,
    defaultCalendarOptions,
    popoverOptions,
    startName,
    endName,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [dates, setDates] = useState({startDate: null, endDate: null});


  const handleDateChange = (dates) => {
    console.log("dates", dates);
    setDates(dates);
    // let parseDate = moment(date).format("YYYY-MM-DD");
    if (onChange) {
      onChange(dates);
    }
  };

  const handleInputDateChange = (e) => {
    e.preventDefault();
    // console.log("input change", e.target.value);
    const newValue = e.target.value;
    // console.log(moment(newValue).year())
    // console.log(moment().year())
    if (moment(newValue).year() >= moment().year()) {
      onChange && onChange(e.target.value);
    }
  };


  // const currentDateString = value ? currentValue.format("MM/DD/YYYY") : "Select Date";
  const {startDate, endDate} = dates;
  const startInputValue = startDate ? moment(startDate).format("MM/DD/YYYY") : null;
  const endInputValue = endDate ? moment(endDate).format("MM/DD/YYYY") : null;

  return (
    <Box className={"single-date-picker"}>
      <Popover
        isOpen={isFocused}
        onClickOutside={() => setIsFocused(false)}
        {...popoverOptions}
        containerStyle={datePopoverContainerStyles}
        closeOnBlur={true}
        content={(

          <DatePickerWrapper className={"datepicker-wrapper"}>
            <DayPickerRangeControllerWrapper
              hideKeyboardShortcutsPanel
              focused={isFocused}
              // onFocusChange={({focused}) => setIsFocused(focused)} // PropTypes.func.isRequired
              handleChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              {...defaultCalendarOptions}
              {...calendarOptions}
            />
          </DatePickerWrapper>
        )}
      >
        <InputWrapper>
          <BaseInput
            type="date"
            mb={1}
            onClick={() => setIsFocused(!isFocused)}
            defaultValue={startInputValue}
            onChange={handleInputDateChange}
            name={startName}
            value={startInputValue}


          />

          <BaseInput
            type="date"
            mb={1}
            onClick={() => setIsFocused(!isFocused)}
            defaultValue={endInputValue}
            onChange={handleInputDateChange}
            name={endName}
            value={endInputValue}

          />

        </InputWrapper>

      </Popover>
    </Box>
  );
};

DateRangerPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  calendarOptions: PropTypes.object,
  defaultCalendarOptions: PropTypes.object,
  popoverOptions: PropTypes.object,
  startName: PropTypes.string,
  endName: PropTypes.string,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
};

DateRangerPicker.defaultProps = {
  defaultCalendarOptions: {
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  },
  popoverOptions: {
    position: "bottom",
    align: "start",
  },
  startName: "start",
  endName: "end",
  start: moment(),
};

export default DateRangerPicker;
