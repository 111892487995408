import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {crudActions, paginationProps} from "../../../types/propTypes";
import {DashboardList} from "../../Common/Dashboard";
import {PM_ORDERS_DASHBOARD_ROUTE} from "../../../routes/constants";
import {PMOrderBar} from "../Bar/PMOrderBar";
import {PMOrdersTable} from "../Table/PMOrdersTables";
import {pmOrderHelp} from "../../Common/HelpItems/constants";
import {csvColumns, csvHelpText, filterOptions, sampleData, selectionOptions} from "./constants";
import authSelectors from "../../../store/auth/selectors";
import {LocationModal} from "../../Common";
import Moment from "moment";
import {extendMoment} from "moment-range";

const moment = extendMoment(Moment);

const PMOrderDashboard = props => {
  const {
    changeView,
    accountInfo,
    pagination,
    actions,
    csvMessages,
    uploadCsv,
    clearMessages,
    scheduleList,
    getAllSchedules,
    getLocations,
    locationList,
    bulkDelete
  } = props;

  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [submitCreate, setSubmitCreate] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  // this will request paginated responses from the api for the date range
  // until all pages have been fetched
  const fetchScheduleForRange = async (dateRange) => {
    console.log("range", dateRange);
    let page = 1;
    const rangeParams = {
      from_date: dateRange.start.format("YYYY-MM-DD"),
      to_date: dateRange.end.format("YYYY-MM-DD")
    };
    let res = await getAllSchedules(page, rangeParams);
    while (!res.error && res.payload.next) {
      page++;
      res = await getAllSchedules(page, rangeParams);
    }
  };

  const fetchData = async () => {
    const orgID = authSelectors.defaultOrgID(props);
    const date = moment();
    const range = date.range("month");
    console.log("fetchData range", range);
    getLocations(orgID);
    await fetchScheduleForRange(range);
  };

  const handleSubmitLocation = (values) => {
    console.log("values", values);
    setInitialValues({location: values?.location?.location_instance ?? null});
    setSubmitCreate(true);
  };

  const handleDateRange = async (page, dateRange) => {
    console.log("date page", page);
    console.log("range", dateRange);
    let res = await getAllSchedules(page, dateRange);
    console.log("res", res);
  };

  return (
    <Fragment>
      <DashboardList
        ListItem={PMOrderBar}
        getPage={actions.getPage}
        fetchData={fetchData}
        Table={PMOrdersTable}
        createItem={actions.create}
        pagination={pagination}
        accountInfo={accountInfo}
        type={"PM Order"}
        title={"PM Order"}
        changeView={changeView}
        tutorialText={pmOrderHelp}
        newItemUrl={PM_ORDERS_DASHBOARD_ROUTE}
        filterOptions={filterOptions}
        sortByOptions={selectionOptions}
        uploadCsv={uploadCsv}
        showCalendar
        onDateChange={handleDateRange}
        calendarOptions={{
          startAccessor: "start",
          endAccessor: "end",
          // TODO: figure out why week view isn't working
          views: {month: true, week: true, day: true},
          // views: {month: true},
          items: scheduleList
        }}
        calendarUrl={"/dashboard/orders/scheduled/"}
        showCsv
        csvOptions={{
          csvMessages,
          clearMessages,
          csvColumns,
          csvData: sampleData,
          csvHelpText
        }}
        initialValues={initialValues}
        extraCreationModalButtons={[{
          text: "Create with Location",
          action: () => setLocationModalVisible(!locationModalVisible),
          config: {"type": "primary"}
        }]}
        submitCreate={submitCreate}
        bulkDelete={bulkDelete}
      />

      <LocationModal
        headerText={"Create a PM Order with a location"}
        submit={handleSubmitLocation}
        locationList={locationList}
        closeModal={() => setLocationModalVisible(!locationModalVisible)}
        visible={locationModalVisible}/>
    </Fragment>
  );
};

PMOrderDashboard.propTypes = {
  changeView: PropTypes.func,
  actions: crudActions,
  pagination: paginationProps,
  bulkDelete: PropTypes.func
};

export default PMOrderDashboard;
