import React, {Component} from "react";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import {Redirect} from "react-router-dom";
import {ErrorHeading, Heading} from "../../Common/Base";
import {VendorBar} from "../Bar/VenderBar";
import {VendorsTable} from "../Table/VenderOrdersTable";
import {filterOptions, selectionOptions} from "./constants";
import {vendorHelp} from "../../Common/HelpItems/constants";
import {OldStyleDashboard} from "../../Common/Dashboard/OldStyleDashboard/OldStyleDashboard";
import {
  checkFiltersDidMount,
  checkFiltersDidUpdate,
  checkForResponseError,
  handleErrorResponse,
  handleFilterDropdownChange, scrollToTop,
} from "../../../utils/containerUtils";

/* This will present a list of vendor order bars. */
class VendorOrdersDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedCalendarID: "",
      errorLoadingMessage: ""
    };
  }

  /* Upon mounting, fetch a list of vendor orders and store the array in state. They
      will be rendered using the map method. */
  componentDidMount() {
    const {getServicesList, sortVendors, filterListBy, location} = this.props;
    checkFiltersDidMount(location.search, filterListBy);
    getServicesList();
    getServicesList()
      .then(res => checkForResponseError(res))
      .catch(err => {
        handleErrorResponse(this, err, "Vendor Orders");
      });
    sortVendors("created_at");
    scrollToTop();
  }

  componentDidUpdate = (prevPops) => {
    checkFiltersDidUpdate(this.props, prevPops);
  };

  handleScheduleClick = event => {
    this.setState({clickedCalendarID: event.id});
  };

  /* Handles filtering the pm orders  */
  handleFilterBy = e => {
    const {location, filterListBy, history} = this.props;
    handleFilterDropdownChange(e, "filterby", "vendors", filterListBy, location, history);
  }

  render() {
    const {
      calendarView,
      calendarEvents,
      isEmptyList,
      sortVendors,
      filterVendors
    } = this.props;

    const {
      clickedCalendarID,
      errorLoadingMessage
    } = this.state;

    if (clickedCalendarID) return <Redirect to={`/dashboard/vendors/${clickedCalendarID}`} />;

    return (
      <section>
        <OldStyleDashboard
          {...this.props}
          selectionOptions={selectionOptions}
          helpDialog={vendorHelp}
          handleFilterBy={this.handleFilterBy}
          handleSubmitAddItem={this.handleTutFromButton}
          hasEditAccess={true}
          barComponent={<VendorBar/>}
          tableComponent={<VendorsTable/>}
          type="Vendor Order"
          sortItems={sortVendors}
          filterItems={filterVendors}
          filterOptions={filterOptions}
        />
        { calendarView &&
        // TODO: This shows the date on the calendar 1 day early
          <BigCalendar
            popup
            localizer={BigCalendar.momentLocalizer(moment)}
            events={calendarEvents}
            startAccessor="date"
            endAccessor="date"
            views={{month: true}}
            style={{
              height: "60vh",
              width: "100%",
              padding: "0 20px"
            }}
            onSelectEvent={(event) => this.handleScheduleClick(event)}
          />
        }
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}
        { isEmptyList && <Heading as={"h1"} textAlign={"center"}>No Vendor Orders Found</Heading> }
      </section>
    );
  }
}

export {VendorOrdersDashboard};
