import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Fab} from "react-tiny-fab";

/**
 * This displays a help icon on the bottom right of the UI for opening the help modal.
 * @class
 */
const HelpButton = ({handleClick, text}) => {
  return (
    <span className="button-bar">
      <Fab
        mainButtonStyles={{backgroundColor: "#1e6cb6"}}
        position={{bottom: 24, left: 24}}
        event={"click"}
        text={text}
        alwaysShowTitle={false}
        icon={<FontAwesomeIcon
          id="helpButton"
          icon={"question"}
          className="button-bar-left"
        />}
        onClick={handleClick}
      >
      </Fab>
    </span>

  );
};

HelpButton.propTypes = {
  /**
   * What to do when the help button is clicked
   */
  handleClick: PropTypes.func,
  /**
   * Text to display next to the button when hovered
   */
  text: PropTypes.string,
};

HelpButton.defaultProps = {
  handleClick: null,
  text: "",
};

export default HelpButton;
