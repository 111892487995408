// suppliers duck
import {combineReducers} from "redux";
import {createCrud, createNestedCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {supplierEndpoint} from "./constants";

const suppliersPaginator = createPaginator({
  name: "suppliers",
  endpoint: supplierEndpoint
});

export const suppliersCrud = createCrud({
  endpoint: supplierEndpoint,
  name: "suppliers",
  withNotes: true
});

export const addressCrud = createNestedCrud({
  endpoint: supplierEndpoint,
  nestedEndpoint: "address/",
  name: "supplierAddress"
});


const supplierActions = suppliersCrud.actions;
const supplierOperations = suppliersCrud.operations;

const supplierPageActions = suppliersPaginator.actions;

const addressActions = addressCrud.actions;

export {
  suppliersPaginator,
  supplierPageActions,
  supplierActions,
  supplierOperations,
  addressActions
};


export default combineReducers({
  items: suppliersPaginator.reducer(),
  details: suppliersCrud.reducer(),
});
