import React from "react";
import {ScorecardDashboard} from "../../../Common/Dashboard/ScorecardDashboard";
import {summaries} from "./data";

const ProductionScorecard = props => {
  const {summary} = props;

  return (
    <ScorecardDashboard
      title="Production Scorecard"
      summaries={summaries(summary)}
    />
  );
};

export {ProductionScorecard};
