import React from "react";
import PropTypes from "prop-types";

import {Table} from "../../../../Common/Base";
import {columns} from "./constants";


const StockroomAssetSection = props => {
  const {data} = props;

  return (
    <Table
      className="components-table-demo-nested"
      columns={columns}
      // expandable={{expandedRowRender}}
      dataSource={data.stockroom_assets}
    />
  );
};


StockroomAssetSection.propTypes = {
  data: PropTypes.object,
};

export default StockroomAssetSection;
