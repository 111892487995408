import orgReducer from "./reducers";

/* These exports are the only thing that need to be edited for a new module */
export const name = "v1/orgs/";

export {default as orgTypes} from "./types";
export {default as orgSelectors} from "./selectors";
export {default as orgOperations} from "./operations";
export {default as orgActions} from "./actions";

export default orgReducer;
