// salesOrders/reducers.js
import {combineReducers} from "redux";
// import {salesOrderCrud} from "./index";
import SupplierReducer from "./suppliers";
import CustomerReducer from "./customers";


export default combineReducers({
  supplier: SupplierReducer,
  customer: CustomerReducer
});
