import React from "react";
import {basePreSaveFormat} from "../../../Common/Forms/Base/utils";

export const load = async (values) => {
};

export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  let result = values;

  return result;
};

export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);
  // if (result.product) {
  //   delete result.product;
  // }
  return result;
};


export const formatSupplierList = (data) => {
  return data.map(item => ({id: item.id, value: item.id, label: item.name}));
};
