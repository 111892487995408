// ***** Old Stuff *****

// export const statusOptions = [
//   {value: 1, label: 'Needs Approval'},
//   {value: 2, label: 'Pending'},
//   {value: 3, label: 'Confirmed'},
//   {value: 4, label: 'Work in Progress'},
//   {value: 5, label: 'Assets in Transit'}
// ];

// export const onSiteStatusOptions = [
//   {value: ORDER_CREATED, label: ORDER_CREATED_STR},
//   {value: CLIENT_ADMIN_APPROVED, label: CLIENT_ADMIN_APPROVED_STR},
//   {value: SERVICE_PROVIDER_ACCEPTED, label: SERVICE_PROVIDER_ACCEPTED_STR},
//   {value: CLIENT_ADMIN_ACCEPTED, label: CLIENT_ADMIN_ACCEPTED_STR},
//   {value: ORDER_SCHEDULED, label: ORDER_SCHEDULED_STR},
//   {value: ORDER_STARTED, label: ORDER_STARTED_STR},
//   {value: ORDER_COMPLETE, label: ORDER_COMPLETE_STR}
// ];

// export const offSiteStatusOptions = [
//   {value: ORDER_CREATED, label: ORDER_CREATED_STR},
//   {value: CLIENT_ADMIN_APPROVED, label: CLIENT_ADMIN_APPROVED_STR},
//   {value: SERVICE_PROVIDER_ACCEPTED, label: SERVICE_PROVIDER_ACCEPTED_STR},
//   {value: CLIENT_ADMIN_ACCEPTED, label: CLIENT_ADMIN_ACCEPTED_STR},
//   {value: ORDER_STARTED, label: ORDER_STARTED_STR},
//   {value: ASSETS_IN_TRANSIT, label: ASSETS_IN_TRANSIT_STR},
//   {value: AT_VENDOR_SITE, label: AT_VENDOR_SITE_STR},
//   {value: ORDER_COMPLETE, label: ORDER_COMPLETE_STR}
// ];

// ***********

export const ORDER_CREATED = 1;
export const CLIENT_ADMIN_APPROVED = 2;
export const SERVICE_PROVIDER_ACCEPTED = 3;
export const CLIENT_ADMIN_ACCEPTED = 4;
export const ORDER_SCHEDULED = 5;
export const ORDER_STARTED = 6;
export const ASSETS_IN_TRANSIT = 7;
export const AT_VENDOR_SITE = 8;
export const ORDER_COMPLETE = 9;

export const ORDER_CREATED_STR = "Vendor Order Created";
export const CLIENT_ADMIN_APPROVED_STR = "Client Admin Approved";
export const SERVICE_PROVIDER_ACCEPTED_STR = "Service Provider Accepted";
export const CLIENT_ADMIN_ACCEPTED_STR = "Client Admin Accepted";
export const ORDER_SCHEDULED_STR = "Vendor Order Scheduled";
export const ORDER_STARTED_STR = "Vendor Order Started";
export const ASSETS_IN_TRANSIT_STR = "Assets In Transit";
export const AT_VENDOR_SITE_STR = "At Vendor Site";
export const ORDER_COMPLETE_STR = "Vendor Order Complete";

export const allStatusOptions = {
  [ORDER_CREATED]: {value: ORDER_CREATED, label: ORDER_CREATED_STR},
  [CLIENT_ADMIN_APPROVED]: {value: CLIENT_ADMIN_APPROVED, label: CLIENT_ADMIN_APPROVED_STR},
  [SERVICE_PROVIDER_ACCEPTED]: {value: SERVICE_PROVIDER_ACCEPTED, label: SERVICE_PROVIDER_ACCEPTED_STR},
  [CLIENT_ADMIN_ACCEPTED]: {value: CLIENT_ADMIN_ACCEPTED, label: CLIENT_ADMIN_ACCEPTED_STR},
  [ORDER_SCHEDULED]: {value: ORDER_SCHEDULED, label: ORDER_SCHEDULED_STR},
  [ORDER_STARTED]: {value: ORDER_STARTED, label: ORDER_STARTED_STR},
  [ASSETS_IN_TRANSIT]: {value: ASSETS_IN_TRANSIT, label: ASSETS_IN_TRANSIT_STR},
  [AT_VENDOR_SITE]: {value: AT_VENDOR_SITE, label: AT_VENDOR_SITE_STR},
  [ORDER_COMPLETE]: {value: ORDER_COMPLETE, label: ORDER_COMPLETE_STR},
};

export const siteOptions = [
  {value: false, label: "Off-Site"},
  {value: true, label: "On-Site"}
];
