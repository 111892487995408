import moment from "moment-timezone";

/**
 * Formats a datetime to 24hr
 * @param {string} value - datetime string
 * @returns {string} - HH:mm formatted date
 */
export const formatSubmitTime = (value) => {
  return value ? moment(value).format("HH:mm") : "";
};


export const formatSubmitValues = (values) => {
  const {date, ...rest} = values;
  const keys = Object.keys(rest);
  let start = null;
  let end = null;
  let sales_order = null;
  if (keys.includes("start")) {
    const dateString = date + " " + formatSubmitTime(values.start);
    console.log("datestring", dateString);
    start = moment(dateString, "YYYY-MM-DD HH:mm").format();
  }

  if (keys.includes("end") && values.end !== null) {
    end = moment(date + " " + formatSubmitTime(values.end), "YYYY-MM-DD HH:mm").format();
  }

  if (keys.includes("sales_order")) {
    sales_order = values.sales_order?.value ?? null;
  }

  return end ? ({
    ...rest,
    sales_order,
    start,
    end
  }) :
    ({
      ...rest,
      sales_order,
      start
    });
};

/**
 * Formats a datetime to 12hr time only
 * @param {string} value - datetime string
 * @returns {string} - hh:mm a formatted date
 */
export const formatTime = (value) => {
  return value ? moment(value).format("h:mm a") : "None";
};

/**
 * Formats a start and end to single string
 * @param {object} values - object with 'start' and 'end' properties
 * @returns {string} - hh:mm - h:mm a
 */
export const formatStartEndTime = (values) => {
  return `${moment(values.start).format("h:mm")} - ${values.end ? moment(values.end).format("h:mm a") : ""}`;
};

/**
 * Formats a start and end to single string
 * @param {object} values - object with 'start' and 'end'
 * @returns {{start_after: string, start_before: string}} - start range params
 */
export const formatDateParams = (values) => {
  const {start, end} = values;
  return ({"start_after": start, "start_before": end});
};
