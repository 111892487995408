import React, {Component} from "react";
import {helpItems} from "../../components/Common/HelpItems/constants";
import {HelpAccordion} from "../../components/Common/Accordion";
import {Box} from "../../components/Common/Base";
import PropTypes from "prop-types";
import {authSelectors} from "../../store";

/* This page will provide instructional information for using the Conmitto system. */
class HelpContainer extends Component {
  render() {
    const {accountInfo} = this.props;
    const enabledHelpItems = helpItems.filter(item => authSelectors.moduleEnabled(accountInfo, item.url));
    return (
      <section className="help">
        <Box className="help-view" w={["100%", "80%"]} margin={"0 auto"} bg={"#fff"}>
          <HelpAccordion information={enabledHelpItems} accountInfo={accountInfo} />
        </Box>
      </section>
    );
  }
}

HelpContainer.propTypes = {
  accountInfo: PropTypes.object,
};

export default HelpContainer;
