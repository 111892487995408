import React from "react";
import PropTypes from "prop-types";
import {Box} from "../Base";
import {LocationDetails} from "./index";
import {LocationSection} from "../index";

const DetailsLocationSection = props => {
  const {
    accountInfo,
    locationList,
    details,
    onSubmit,
  } = props;

  const locationData = details?.location ?? {};
  const isAdmin = accountInfo && accountInfo.user_type <= 1;

  return (
    <Box display={"inline-block"} key={"location-section"}>
      <LocationDetails
        addButton={
          <LocationSection
            locationData={locationData}
            locationList={locationList}
            submit={onSubmit}
            isAdmin={isAdmin}
            hideCard
            buttonConfig={{
              size: "small",
              type: Object.keys(locationData).length !== 0 ? "link" : "default",
            }}
            wrapperStyle={{mt: 0}}

          />
        }
        region={locationData.region}
        district={locationData.district}
        facility={locationData.facility}
        wing={locationData.wing}
        zone={locationData.zone}
        subzone={locationData.subzone}
      />
    </Box>
  );
};

DetailsLocationSection.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  locationList: PropTypes.array,
  details: PropTypes.object,
  hasLocation: PropTypes.bool,
  onSubmit: PropTypes.func
};

DetailsLocationSection.defaultProps = {
  locationList: []
};

export default DetailsLocationSection;
