import React, {Fragment} from "react";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Box, Button, Flex} from "Base";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchContainer} from "./styled";
import SearchInput from "./Search/SearchInput";
import {nonBulkDeletable} from "./constants";


/* The toolbar contains the buttons for pagination, as well as search functionality. */
const ToolBar = (props => {
  const {
    handleChange,
    updateSortBy,
    showCalendar,
    changeViewList,
    changeViewTile,
    changeViewTable,
    changeViewCalendar,
    listView,
    tileView,
    tableView,
    calendarView,
    selectionOptions,
    filter,
    reverseList,
    isReversed,
    filterListBy,
    filteringBy,
    filterOptions,
    filterListByType,
    filteringByType,
    filterTypeOptions,
    objectType,
    handleAddItemButtonClick,
    hasTableView,
    showDelete,
    handleShowDelete,
    readOnly
  } = props;

  return (
    <div className="toolbar">
      <ReactTooltip/>
      <Flex flexWrap="wrap" alignItems="center">
        <div className="toolbar-row">

          {!tableView && !calendarView &&
          <Fragment>
            <SearchContainer order={[5, 2]} alignItems="center" pt={[2, 0]}>
              <SearchInput
                onSubmit={handleChange}
                filter={filter}
              />
            </SearchContainer>

            {!readOnly &&
            <Box
              order={[2, 3]}
              mx="20px"
              p={"10px 15px"}
              mt={0}
              width={"fit-content"}
            >
              <Button
                className="add-item-button"
                type={"primary"}
                onClick={handleAddItemButtonClick}>
                Add {objectType}
              </Button>
            </Box>
            }
          </Fragment>
          }

          <Fragment>
            {filterOptions.length > 0 &&
            <Flex order={[4, 3]} m={"15px 10px 15px 0"} flex={"1 0 100px"} justifyContent="center">
              <Select
                onChange={filterListBy}
                className="toolbar-row-select filter-select"
                options={filterOptions}
                defaultValue={{value: "", label: "Filter By"}}
                value={filterOptions.find(option => option.value === filteringBy)}
              />
            </Flex>
            }
            {filterListByType && filterTypeOptions.length > 1 &&
            <Flex order={[5, 4]} m={"15px 10px 15px 0"} flex={"1 0 140px"} justifyContent="center">
              <Select
                onChange={filterListByType}
                className="toolbar-row-select filter-type-select"
                options={filterTypeOptions}
                defaultValue={{value: null, label: "Every Type"}}
                value={filterTypeOptions.find(option => option.value === filteringByType)}
              />
            </Flex>
            }
          </Fragment>


          <Flex order={[2, 5]} m={"15px 10px 15px 0"} flex={"1 0 140px"} justifyContent="center">
            {!tableView && !calendarView &&
            <Fragment>
              <button
                className="toolbar-row-button-color"
                onClick={reverseList}>
                {isReversed ?
                  <FontAwesomeIcon
                    icon="caret-up"
                    size="lg"
                  />
                  :
                  <FontAwesomeIcon
                    icon="caret-down"
                    size="lg"
                  />
                }
              </button>
              <Select
                onChange={updateSortBy}
                className="toolbar-row-select"
                options={selectionOptions}
                defaultValue={{value: "null", label: "Sort By"}}
              />
            </Fragment>
            }
          </Flex>

          <Box order={[3, 6]}>
            <div className="toolbar-row-button">
              {hasTableView &&
              <FontAwesomeIcon
                className={"table-view-button " + ((!tableView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
                icon="table"
                size="lg"
                onClick={changeViewTable}
                data-tip="Table view."
              />
              }
              <FontAwesomeIcon
                className={"list-view-button " + ((!listView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
                icon="list"
                size="lg"
                onClick={changeViewList}
                data-tip="List view."
              />
              <FontAwesomeIcon
                className={"tile-view-button " + ((!tileView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
                icon="th"
                size="lg"
                onClick={changeViewTile}
                data-tip="Tile view."
              />
              {showCalendar &&
              <FontAwesomeIcon
                className={"calendar-view-button " + ((!calendarView) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
                icon="calendar-alt"
                size="lg"
                onClick={changeViewCalendar}
                data-tip="Calendar view."
              />
              }
              {!nonBulkDeletable.includes(objectType) &&
              <FontAwesomeIcon
                className={"trash-view-button " + ((!showDelete) ? "toolbar-row-centered-icon-inactive" : "toolbar-row-centered-icon-active")}
                id="trashIcon"
                icon="trash"
                size="lg"
                onClick={handleShowDelete}
                data-tip={`Delete ${objectType}s`}
              />
              }
            </div>
          </Box>
        </div>
      </Flex>
    </div>
  );
});

ToolBar.defaultProps = {
  withPagination: true,
  hasTableView: true,
  selectionOptions: [
    {value: "created_at", label: "Created At"},
    {value: "updated_at", label: "Updated At"},
  ],
  readOnly: false,
};
export default ToolBar;
