import types from "./types";
import {name as endpoint} from "./index";
import base from "../../base/actions";
import {BILL_OF_LADING_ROUTE} from "../../../routes/constants";


/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getAllItems = (filter) => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${filter}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});


const getItem = (id) => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/?expand=supplier.addresses,materials.material,location,purchaser,pallets,org`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE]
});

const createItem = data => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  body: JSON.stringify(data),
  types: [types.CREATE_ITEM_REQUEST, types.CREATE_ITEM_SUCCESS, types.CREATE_ITEM_FAILURE]
});

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const updateItemFile = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: data,
  headers: {},
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const updateBol = (id, data) => base.putRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/${BILL_OF_LADING_ROUTE}/`,
  body: data,
  headers: {},
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const addQuantity = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/products/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const addMaterial = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/materials/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});


const updateQuantity = (id, itemID, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/products/${itemID}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const updateMaterial = (id, itemID, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/materials/${itemID}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const deleteItem = id => base.deleteRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [types.DELETE_ITEM_REQUEST, {
    type: types.DELETE_ITEM_SUCCESS,
    meta: {id}
  }, types.DELETE_ITEM_FAILURE]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});

const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

const uploadCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}csv/${org}/`,
  headers: {},
  body: data,
  types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE]
});

/* SimplePagination actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  payload: by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

const filterListBy = query => ({
  type: types.FILTER_LIST_BY,
  payload: query
});

const reverseList = () => ({
  type: types.REVERSE_LIST
});

/* View related actions. */
const changeViewList = () => ({
  type: types.CHANGE_VIEW_LIST
});

const changeViewTile = () => ({
  type: types.CHANGE_VIEW_TILE
});

const changeViewTable = () => ({
  type: types.CHANGE_VIEW_TABLE
});

const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export default {
  getAllItems,
  getItem,
  createItem,
  updateItem,
  updateBol,
  addQuantity,
  addMaterial,
  updateQuantity,
  updateMaterial,
  deleteItem,
  getItemNotes,
  submitNote,
  updateItemFile,
  uploadCsv,

  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  filterListBy,
  reverseList,
  changeViewList,
  changeViewTile,
  changeViewTable,

  clearMessages
};
