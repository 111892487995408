import React from "react";
import PropTypes from "prop-types";
import {List} from "../../Base";
import ActivityListItem from "./ActivityListItem";


const RecentActivityList = ({items}) => {
  return (
    <List className={"recent-activity-list"}>
      {items.map(item =>
        <ActivityListItem
          key={item.id}
          item={item}
        />
      )}
    </List>
  );
};

RecentActivityList.propTypes = {
  items: PropTypes.array
};

export default RecentActivityList;
