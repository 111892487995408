import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {Button, Flex} from "../Base";
import Modal from "./";

import {CsvUploadForm} from "../Forms";
import {DescriptionText, ModalErrorHeading} from "./styled";

const defaultColumns = [
  {
    id: "name",
    displayName: "name",
  },
  {
    id: "description",
    displayName: "description",
  },
];

class AddItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadCsv: false,
      submittingCsv: false,
      file: null,
      uploadSuccess: false,
      closeTimerSet: false,
      csvFile: null
    };
  }

  // Submit csv Upload
  handleSubmitCsv = async (data) => {
    const {handleUploadCsv} = this.props;
    this.setState({submittingCsv: true});
    await handleUploadCsv(data);
    this.setState({submittingCsv: false});
  };

  // Checks for csv messages, and start success timeout
  static getDerivedStateFromProps(props, state) {
    const {csvMessages} = props;
    let data = {uploadSuccess: state.uploadSuccess};
    // A successful response consists of an array 2 arrays
    // The first array is the success messages
    // The second array is the failure messages
    // If the failure message array has a length of one, then it was successful
    if (csvMessages && csvMessages.length === 2 && csvMessages[1].length === 1) {
      data.uploadSuccess = true;
    }
    return data;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.uploadSuccess && !this.state.closeTimerSet) {
      this.delayedCloseModal();
    }
  }

  // If upload csv was successful, clear messages and close modal after timeout
  delayedCloseModal = () => {
    this.setState({closeTimerSet: true});
    setTimeout(() => {
      this.closeModal();
      this.setState({closeTimerSet: false});
    }, 2000);
  };

  closeModal = () => {
    const {closeAddItemModal, clearCsvMessages} = this.props;
    this.setState({uploadCsv: false});
    clearCsvMessages();
    closeAddItemModal();
  };

  handleSubmit = async () => {
    let formData = new FormData();
    formData.append("file", this.state.csvFile);
    await this.handleSubmitCsv(formData);
    this.loadCsvFile(null);
  };

  loadCsvFile = file => {
    this.setState({csvFile: file});
  };

  render() {
    const {uploadCsv, submittingCsv, csvFile} = this.state;
    const {
      itemName,
      submitAddItem,
      addItemSubmitting,
      addItemError,
      showCsv,
      columns,
      csvMessages,
      clearCsvMessages,
      sampleData,
      csvHelpText,
      visible,
      extraButtons,
    } = this.props;
    let confirmText = "Are you sure you want to add a";
    if (itemName === "Asset") {
      confirmText += "n";
    }

    return (
      <Modal
        visible={visible}
        onCancel={uploadCsv ? () => this.setState({uploadCsv: false}) : this.closeModal}
        onOk={uploadCsv ? this.handleSubmit : submitAddItem}
        showConfirmButton={!uploadCsv || Boolean(csvFile)}
        confirmLoading={uploadCsv ? submittingCsv : addItemSubmitting}
        title={`Add ${itemName}`}
        confirmText={"Create"}
        extraButtons={extraButtons}
      >
        <div className={"add-item-modal"}>
          {uploadCsv ?
            <CsvUploadForm
              name={itemName}
              columns={columns}
              messages={csvMessages}
              clearMessages={clearCsvMessages}
              datas={sampleData}
              helpText={csvHelpText}
              csvFile={csvFile}
              loadCsvFile={this.loadCsvFile}
            />
            :
            <Fragment>
              <DescriptionText>{confirmText} {itemName}?</DescriptionText>
              <DescriptionText>Clicking confirm will redirect you to the new {itemName}.</DescriptionText>

              {showCsv &&
              <DescriptionText>Click Upload CSV if you{"'"}d like to add Assets with a CSV file.</DescriptionText>
              }
              {addItemError && <ModalErrorHeading>An error occurred. Please try again.</ModalErrorHeading>}
              <br/>

              {showCsv &&
              <Flex flexWrap={"wrap"} flexDirection={"column"}>
                <Button className="modal-card-button upload-csv"
                  onClick={() => this.setState({uploadCsv: true})}>
                  Upload CSV
                </Button>
              </Flex>
              }

            </Fragment>
          }
        </div>
      </Modal>
    );
  }
}

AddItemModal.propTypes = {
  addItemError: PropTypes.bool,
  addItemSubmitting: PropTypes.bool,
  /**
   * Extra buttons to show on the creation modal
   */
  extraButtons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    config: PropTypes.object,
  })),
  clearCsvMessages: PropTypes.func,
  closeAddItemModal: PropTypes.func,
  columns: PropTypes.array,
  csvHelpText: PropTypes.array,
  csvMessages: PropTypes.any,
  itemName: PropTypes.string,
  handleUploadCsv: PropTypes.func,
  messages: PropTypes.any,
  sampleData: PropTypes.array,
  showCsv: PropTypes.bool,
  /**
   * The function that is called when the user clicks the "Create" button
   */
  submitAddItem: PropTypes.func,
  /**
   * If the modal is visible
   */
  visible: PropTypes.bool.isRequired
};

AddItemModal.defaultProps = {
  showCsv: false,
  columns: defaultColumns,
  sampleData: [],
  csvHelpText: [],
  clearCsvMessages: () => null,
  extraButtons: []
};

export default AddItemModal;
