import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";
import Modal from "../../Modals/Modal";
import TemplateModal from "../../Modals/TemplateModal";
import {DescriptionText} from "../../Modals/styled";
import {Box} from "../../Base";

class EditableTemplateDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createModalShowing: false,
      editModalShowing: false,
      deleteModalShowing: false
    };
  }

  toggleCreateModal = () => {
    this.setState({createModalShowing: !this.state.createModalShowing});
  };

  toggleEditModal = () => {
    this.setState({editModalShowing: !this.state.editModalShowing});
  };

  toggleDeleteModal = () => {
    this.setState({deleteModalShowing: !this.state.deleteModalShowing});
  };

  deleteAndCloseModal = () => {
    this.props.submitDeleteTemplate();
    this.toggleDeleteModal();
  };

  render() {
    const {
      createModalShowing,
      editModalShowing,
      deleteModalShowing
    } = this.state;
    const {
      dropdownName,
      canChangeData,
      canCreateTemplates,
      dropdownOptions,
      selectedOption,
      handleFieldSelect,
      currentTemplate,
      submitCreateTemplate,
      submitEditTemplate,
    } = this.props;

    let selectOptions = [{value: null, label: "None"}];
    dropdownOptions.forEach(type => {
      selectOptions.push({
        value: type.id,
        label: type.name,
        name: type.name,
        id: type.id,
        fields: type.fields
      });
    });

    return (
      <Fragment>
        <Box className="item-details-form-flex" mb={"30px"}>
          <Select
            id="templateDropdown"
            className="item-details-form-dropdown"
            defaultValue={selectOptions.find(option => option.label === "None")}
            value={selectedOption ? selectOptions.find(option => option.id === selectedOption.id) : {label: "None"}}
            isDisabled={!canChangeData}
            onChange={data => {
              handleFieldSelect(data);
            }}
            options={selectOptions}>
          </Select>
          {canCreateTemplates && (
            <FontAwesomeIcon
              id="templateDropdownPlus"
              icon="plus"
              size="lg"
              onClick={this.toggleCreateModal}
              data-tip={`Create New ${dropdownName}.`}
            />
          )}
          {selectedOption && <Fragment>
            {canCreateTemplates && selectedOption.id && (
              <FontAwesomeIcon
                id="templateDropdownEdit"
                icon="edit"
                size="lg"
                onClick={this.toggleEditModal}
                data-tip={`Edit ${dropdownName}.`}
              />
            )}
            {canCreateTemplates && selectedOption.id && (
              <FontAwesomeIcon
                id="templateDropdownDelete"
                icon="trash"
                size="lg"
                onClick={this.toggleDeleteModal}
                data-tip={`Delete ${dropdownName}.`}
              />
            )}
          </Fragment>
          }
        </Box>

        <TemplateModal
          visible={createModalShowing}
          title="Create Template"
          toggleModal={this.toggleCreateModal}
          submit={submitCreateTemplate}
        />
        <TemplateModal
          visible={editModalShowing}
          title="Edit Template"
          toggleModal={this.toggleEditModal}
          currentTemplate={currentTemplate}
          submit={submitEditTemplate}
        />
        <Modal
          visible={deleteModalShowing}
          title={`Delete ${selectedOption ? selectedOption.name : dropdownName}?`}
          onCancel={this.toggleDeleteModal}
          onOk={this.deleteAndCloseModal}
          isDeleteModal
        >
          <DescriptionText>Deleting this {dropdownName} will remove it from any Asset.</DescriptionText>
        </Modal>
      </Fragment>
    );
  }
}

EditableTemplateDropdown.propTypes = {
  dropdownName: PropTypes.string,
  editAccess: PropTypes.bool,
  dropdownOptions: PropTypes.array,
  selectedOption: PropTypes.object,
  currentTemplate: PropTypes.object,
  handleFieldSelect: PropTypes.func,
  submitCreateTemplate: PropTypes.func,
  submitEditTemplate: PropTypes.func,
  canChangeData: PropTypes.bool,
  canCreateTemplates: PropTypes.bool,
};

EditableTemplateDropdown.defaultProps = {
  dropdownName: "Template",
  selectedOption: {value: "", id: 0}
};

export default EditableTemplateDropdown;
