import React from "react";
import PropTypes from "prop-types";
import {Box, Heading} from "../../../components/Common/Base";
import {NotificationSettingsForm} from "../../../components/Common/Forms/UserSettings";

const Notifications = ({updateSettings, settings}) => {
  return (
    <Box px={5}>
      <Heading className="settings-view-text-header">Notifications</Heading>

      <NotificationSettingsForm
        values={settings}
        onSubmit={updateSettings}
      />
    </Box>
  );
};

Notifications.propTypes = {
  account: PropTypes.any,
  settings: PropTypes.any,
  updateSettings: PropTypes.func,
};

export default Notifications;
