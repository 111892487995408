import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {Button, Heading, Label} from "../../Base";
import {AutoSave, Condition, EditableFormDropdown, FormDropdown, FormField, FormMoneyField} from "../Base";
import {classificationList, roleList, typeList} from "./constants.js";
import {postLoadFormat, preSaveFormat} from "./utils";
import {Input} from "antd";

import {SubmitButton} from "../styled";
import {LabelText} from "../Base/styled";

const PersonnelForm = (props) => {
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    personnelID,
    personnelTitles,
    handleSendInvitation,
    handleCancelInvitation,
    handleInvitationEmailChange,
    selectedPersonnelTitle,
    personnelList,
    invitationPending,
    invitationEmail,
    invitationRequestLoading,
    newPersonnelTitle,
    handlePersonnelTitleSelect,
    handleNewPersonnelTitleNameChange,
    handleShowCreatePersonnelTitle,
    handleShowEditPersonnelTitle,
    handleCreatePersonnelTitle,
    handleUpdatePersonnelTitle,
    handleDeletePersonnelTitle,
  } = props;

  const initialValues = postLoadFormat(values);
  const newPersonnelList = personnelList ? personnelList.filter(personnel => {
    return personnel.id !== personnelID && personnel.user_type < initialValues.role;
  }) : [];

  let titleOptions = [{value: null, label: "None"}];
  personnelTitles.map(item => titleOptions.push(({value: item.id, label: item.name})));

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"item-details-form"} onSubmit={handleSubmit}>
          <AutoSave
            save={onSubmit}
            debounced={["name",
              "reports_to",
              "hired_date",
              "title",
              "role",
              "type",
              "classification",
              "invitation_email",
              "wage",
              "wage_currency",
              "over_time_wage",
              "over_time_wage_currency"]}
            preSaveFormat={preSaveFormat}
            postLoadFormat={postLoadFormat}
            formInvalid={invalid}
          />

          <FormField
            label={"Personnel Name"}
            type={"text"}
            name={"name"}
            isDisabled={!editAccess}
          />

          <FormField
            label={"Hired Date"}
            type={"date"}
            name={"hired_date"}
            isDisabled={!editAccess}
          />

          <FormDropdown
            options={roleList}
            isDisabled={!editAccess}
            name="role"
            label="ROLE"
            format={(value, name) => (roleList[value - 1])}
            parse={(value, name) => value.value || value}
          />

          <Condition when="role" isGreaterThan={1} propValue={initialValues.role}>
            <Fragment>
              <FormDropdown
                options={newPersonnelList}
                name="reports_to"
                label="Reports To"
                format={(value, name) => {
                  if (value && typeof value === "number") {
                    return newPersonnelList.find(el => el.id === value);
                  }
                  return (value ? {
                    value: value.id || value.value,
                    label: value.name || value.label
                  } : "");
                }}
                parse={(value, name) => {
                  return (value ? (value.id || value.value) : "");
                }}
                isDisabled={!editAccess}
              />
            </Fragment>
          </Condition>

          <FormDropdown
            options={typeList}
            isDisabled={!editAccess}
            name="type"
            label="TYPE"
            format={(value, name) => (typeList[value - 1])}
            parse={(value, name) => value.value}
          />

          <FormDropdown
            options={classificationList}
            isDisabled={!editAccess}
            name="classification"
            label="CLASSIFICATION"
            format={(value, name) => (classificationList[value - 1])}
            parse={(value, name) => value.value}
          />

          <FormMoneyField
            label={"PERSONNEL WAGE"}
            name={"wage"}
            currencyName={"wage_currency"}
            isDisabled={!editAccess}
          />

          <FormMoneyField
            label={"OVERTIME WAGE"}
            name={"over_time_wage"}
            currencyName={"over_time_wage_currency"}
            isDisabled={!editAccess}
          />

          <EditableFormDropdown
            id="personnelTitle"
            name={"title"}
            label={"Title"}
            dropdownName="Personnel Title"
            canChangeSelection={true}
            canCreateSelections={editAccess}
            options={personnelTitles}
            selectedOption={selectedPersonnelTitle}
            format={(value, name) => {
              if (value && typeof value === "number") {
                return personnelTitles.find(el => el.value === value);
              }
              return (value ? {
                id: value.id || value.value,
                value: value.id || value.value,
                label: value.name || value.label
              } : "");
            }}
            parse={(value, name) => {
              return (value ? (value.id || value.value) : "");
            }}
            newField={newPersonnelTitle}
            handleFieldSelect={handlePersonnelTitleSelect}
            handleShowCreateFieldSuper={handleShowCreatePersonnelTitle}
            handleShowEditFieldSuper={handleShowEditPersonnelTitle}
            handleCreateField={handleCreatePersonnelTitle}
            handleEditField={handleUpdatePersonnelTitle}
            handleDeleteField={handleDeletePersonnelTitle}
            handleNewFieldNameChange={handleNewPersonnelTitleNameChange}
          />

          {editAccess &&
          <Fragment>
            {!invitationPending ?
              <Fragment>
                <Label>
                  <LabelText>
                    Invite This User:
                  </LabelText>
                </Label>
                <Input
                  type={"email"}
                  className={"invite-field"}
                  placeholder="Invitation Email"
                  disabled={!editAccess || invitationPending}
                  onChange={handleInvitationEmailChange}
                  onPressEnter={e => {
                    e.preventDefault();
                    handleSendInvitation();
                  }}
                  size={"large"}

                />
              </Fragment>
              : <Heading size={"medium"} className={"invite-confirm"}>
                Invitation sent to {invitationEmail || initialValues.invitation_email}
              </Heading>}
            {!invitationPending ?
              <Button
                className="item-details-form-button-email send-invite"
                type="button"
                loading={invitationRequestLoading}
                onClick={handleSendInvitation}>
                Send Invitation
              </Button>
              :
              <Button
                className={"cancel-invite"}
                danger
                type="button"
                loading={invitationRequestLoading}
                onClick={handleCancelInvitation}>
                Cancel Invitation
              </Button>
            }
            <br/>
            <br/>
            <br/>
          </Fragment>
          }

          {editAccess &&
          <SubmitButton className="item-details-form-button" loading={submitting}>
            Save
          </SubmitButton>
          }
        </form>
      )}
    />
  );
};


PersonnelForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  showInvitation: PropTypes.bool,
  invitationPending: PropTypes.bool,
  /**
   * If the invitation for this personnel is pending
   */
  invitationEmail: PropTypes.string,
  /**
   * If the invitation request for the personnel is loading
   */
  invitationRequestLoading: PropTypes.bool,
  personnelRole: PropTypes.any,
  personnelType: PropTypes.number,
  handleSendInvitation: PropTypes.func,
  handleCancelInvitation: PropTypes.func,
  handleInvitationEmailChange: PropTypes.func,
  personnelList: PropTypes.array,
  selectedPersonnelTitle: PropTypes.object,
  personnelTitles: PropTypes.array,
  newPersonnelTitle: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleRole: PropTypes.func,
  handleType: PropTypes.func,
  handleClassification: PropTypes.func,
  handlePersonnelSelect: PropTypes.func,
  handlePersonnelTitleSelect: PropTypes.func,
  handleNewPersonnelTitleNameChange: PropTypes.func,
  handleShowCreatePersonnelTitle: PropTypes.func,
  handleShowEditPersonnelTitle: PropTypes.func,
  handleCreatePersonnelTitle: PropTypes.func,
  handleUpdatePersonnelTitle: PropTypes.func,
  handleDeletePersonnelTitle: PropTypes.func,
};

PersonnelForm.defaultProps = {
  values: {},
  editAccess: true
};

export default PersonnelForm;
