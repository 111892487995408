import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {Heading, Box, Link, CloseIcon} from "../../Base";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Popover from "react-tiny-popover";
import Log from "../Logs/Log";
import {DropdownWrapper, HeaderWrapper, CloseWrapper, dropdownContainerStyles} from "./styled";

const LogDropdown = props => {
  const [isFocused, setIsFocused] = useState(false);

  const {logList, maxNumLogEntries} = props;
  const toggleDropdown = () => {
    setIsFocused(!isFocused);
  };

  return (
    <Popover
      isOpen={isFocused}
      onClickOutside={() => setIsFocused(false)}
      position={"bottom"}
      align={"center"}
      containerStyle={dropdownContainerStyles}
      content={(
        <DropdownWrapper w={"300px"} className={"log-dropdown"}>
          <HeaderWrapper>
            <Heading textAlign="center">Recent Activity</Heading>
            <CloseWrapper>
              <CloseIcon onClick={toggleDropdown}/>
            </CloseWrapper>
          </HeaderWrapper>
          {(!logList || logList.length === 0) ? <Heading as="h4">No Logs Available</Heading> :
            <Fragment>
              {Array.isArray(logList) && logList.map((log, index) => {
                if (index >= maxNumLogEntries) {
                  return null;
                }
                return <Log key={index} logInfo={log}/>;
              })}
              <Link onClick={toggleDropdown} to="/dashboard/logs">View All Logs</Link>
            </Fragment>
          }
        </DropdownWrapper>
      )}
    >
      <Box mr={"20px"} cursor={"pointer"} onClick={toggleDropdown}>
        <FontAwesomeIcon
          icon="bell"
          size="lg"
          color="#1e6cb6"
        />
      </Box>
    </Popover>
  );
};

LogDropdown.propTypes = {
  logList: PropTypes.array,
  maxNumLogEntries: PropTypes.number
};

LogDropdown.defaultProps = {
  logList: [],
  maxNumLogEntries: 5
};

export default LogDropdown;
