// products/reducers.js
import {combineReducers} from "redux";


const createCrudReducer = (types, {withNotes, withCsv, paginator}) => {
  /* Main reducer for this duck. import into ../reducers.js and add to root reducer. */
  const errors = (state = null, action) => {
    switch (action.type) {
    case types.GET_ITEM_FAILURE:
      return action.payload;
    case types.UPDATE_ITEM_FAILURE:
      return action.payload;
    case types.CREATE_ITEM_FAILURE:
      return action.payload;
    case types.DELETE_ITEM_FAILURE:
      return action.payload;
    default:
      return state;
    }
  };

  const notes = (state = {}, action) => {
    switch (action.type) {
    case types.GET_ITEM_NOTES_SUCCESS:
      return {
        ...state,
        [action.meta.id]: action.payload,
      };
    default:
      return state;
    }
  };

  let result = {
    errors,
  };

  const csvMessages = (state = null, action) => {
    switch (action.type) {
    case types.UPLOAD_CSV_SUCCESS: {
      return action.payload;
    }

    case types.UPLOAD_CSV_FAILURE: {
      return action.payload.response;
    }

    case types.CLEAR_MESSAGES: {
      return null;
    }

    default:
      return state;
    }
  };

  if (withNotes) {
    result = {
      ...result,
      notes
    };
  }
  if (paginator) {
    result = {
      ...result,
      items: paginator.reducer()
    };
  } else {
    const requested = (state = false, action) => {
      switch (action.type) {
      case types.GET_PAGE_REQUEST:
        return true;
      case types.GET_PAGE_SUCCESS:
        return false;
      case types.GET_PAGE_FAILURE:
        return false;
      default:
        return state;
      }
    };
    const byId = (state = {}, action) => {
      // console.log("action", action);
      const entry = {}; // OUTSIDE BECAUSE OF LINTER
      switch (action.type) {
      case types.GET_ALL_SUCCESS:
        if (action.error) {
          return state;
        }
        for (let i = 0; i < action.payload.length; i += 1) {
          const item = action.payload[i];
          entry[item.id] = item;
        }
        return {
          ...state,
          ...entry,
        };
      case types.GET_ITEM_SUCCESS:
        const details = action.payload;
        entry[details.id] = details;
        return {
          ...state,
          ...entry,
        };
      default:
        return state;
      }
    };

    const ids = (state = [], action) => {
      switch (action.type) {
      case types.GET_ALL_SUCCESS:
        if (action.error) {
          return state;
        }
        return [
          ...state,
          ...action.payload.map(o => o.id),
        ];
      case types.GET_ITEM_SUCCESS:
        if (action.error) {
          return state;
        }
        if (state.includes(action.payload.id)) {
          return state;
        }
        return [
          ...state,
          action.payload.id,
        ];
      default:
        return state;
      }
    };
    result = {
      ...result,
      items: {
        requested,
        byId,
        ids
      }
    };
  }

  if (withCsv) {
    result = {
      ...result,
      csvMessages
    };
  }

  return combineReducers({
    ...result
  });
};


export default createCrudReducer;
