/* Returns the complete list of items returned from getAllItems operation. */
function refreshAllItems(state) {
  if (state.items && state.items.length) {
    return state.items;
  }
  return [];
}

/* Returns True if the getAllItems operation for this module hasn't returned anything yet. */
const isLoading = state => state.loading;

/* Returns boolean value of listView state. */
const isListView = state => state.listView;

/* Returns True while update is in progress */
const isSaving = state => state.savingItem;

/* Returns boolean value of tileView state. */
const isTileView = state => state.tileView;

/* Returns boolean value of tableView state. */
const isTableView = state => state.tableView;

/* Returns boolean value of calendarView state. */
const isCalendarView = state => state.calendarView;

const hasErrors = state => Object.keys(state.errors).length !== 0;

const isEmptyList = state => state.noneFound;

const isListReversed = state => state.isReversed;

/* Access to all items. */
const allItems = state => state.items;

const refreshErrors = (state) => {
  if (state.errors) {
    return state.errors;
  }
  return {};
};

/* Return the current page number with number of pages. */
const currPageNum = state => {
  return {
    currentPage: state.currentPage,
    pages: state.pages
  };
};

/* Return true or false depending on if current page is the final visible page of items. */
function isLastPage(state) {
  let lastPage = 0;
  if (state.items) {
    lastPage = Math.floor(state.items.length / 12) + 1; // Determine what last page actually is knowing 12 items per page
  }
  return state.currentPage === lastPage;
}

/* Returns what the current section of the main list of items should be for the current page. */
function refreshCurrentPage(state) {
  if (state.pageList) {
    return state.pageList;
  }
  return [];
}

/* Given an item id, this will return that item if it exists in the main item list. */
function returnItemWithID(state, id) {
  if (state.data && state.data[id]) {
    return state.data[id];
  }
  if (state.items && id) {
    const item = state.items.find(i => Number(i.id) === Number(id));
    if (item) {
      return item;
    }
  }
  return {loading: true, found: false};
}

const refreshMessages = (state) => {
  if (state.messages) {
    return state.messages;
  }
  return null;
};

const refreshNotes = (state, id) => {
  if (state.notes && state.notes[id]) {
    return state.notes[id];
  }
  return [];
};

const filter = state => state.filter;

export default {
  refreshAllItems,
  isLoading,
  isSaving,
  isListView,
  isTileView,
  isTableView,
  isCalendarView,
  hasErrors,
  isEmptyList,
  currPageNum,
  isLastPage,
  allItems,
  refreshCurrentPage,
  returnItemWithID,
  refreshMessages,
  refreshNotes,
  filter,
  refreshErrors,
  isListReversed,
};
