import styled from "@emotion/styled";
import {Box, Text} from "@chakra-ui/core";
import {Label} from "../../Base";
// Import {space, width} from "styled-system";

// const Label = styled.label`
//       ${space}
//       ${width}
// `;
//
// Label.defaultProps = {
//   mb: 20,
//   width: 1
// };

const ColorWrapper = styled(Box)`
  
`;

/**
 * A label for forms
 */
const LabelText = styled(Text)`
  font-weight: bolder;
  font-size: 1rem;
  color: #444;
  text-transform: uppercase;
`;


export {Label, LabelText, ColorWrapper};
