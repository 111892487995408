import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {AutoSave, EditableColorFormDropdown, FormDropdown, FormField} from "../Base";
import {siteOptions} from "./constants.js";
import {postLoadFormat, preSaveFormat} from "./utils";
import EditableMaterialDropdown from "../Base/EditableMaterialDropdown";
import MaterialBar from "../../../PMOrders/Forms/MaterialBar";
import {Flex} from "../../Base";
import {formatMaterialOptions} from "../../../../store/materials/selectors";
import {SubmitButton} from "../styled";

const VendorForm = props => {
  const {
    onSubmit,
    editAccess,
    validate,
    values,
    vendorTypes,
    selectedVendorType,
    newVendorType,
    handleVendorTypeSelect,
    handleShowCreateVendorType,
    handleShowEditVendorType,
    handleCreateVendorType,
    handleUpdateVendorType,
    handleDeleteVendorType,
    handleVendorTypeColorChange,
    handleVendorTypeNameChange,
    personnelList,
    materialList,
    createOrderMaterial,
    updateOrderMaterial,
    deleteOrderMaterial,
    addMaterialToOrder,
    deleteMaterialFromOrder,
    accountUserType,
    accountPersonnelId,
    inspectionNotesVisible,
    personnelEnabled,
  } = props;

  const initialValues = postLoadFormat(values);
  console.log(initialValues.personnel);

  const materialOptions = formatMaterialOptions(materialList);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"item-details-form"} onSubmit={handleSubmit}>
          <AutoSave
            save={onSubmit}
            debounced={["title", "date", "status", "inspection_note", "personnel"]}
            preSaveFormat={preSaveFormat}
            postLoadFormat={postLoadFormat}
            formInvalid={invalid}
          />
          <FormDropdown
            options={siteOptions}
            name="is_on_site"
            label="Location"
            isDisabled={!editAccess || initialValues.status > 3}
            format={(value, name) => (
              {
                "value": value !== null ? value : "", "label": value !== null ?
                  (value ? "On-Site" : "Off-Site")
                  :
                  "Select a Location"
              })}
            parse={(value, name) => value.value}
          />
          <FormField type="text" name="title" label="Vendor Order Title" isDisabled={!editAccess}/>
          <FormField type="date" name="date" label="Date" isDisabled={!editAccess}/>
          {initialValues &&
          initialValues.org && (
            <EditableMaterialDropdown
              label="Order Materials"
              name="materials"
              dropdownName="Order Material"
              options={materialOptions}
              org={initialValues.org.id}
              canChangeSelection={true}
              canCreateSelections={accountUserType <= 1}
              includeNone={false}
              handleCreateField={createOrderMaterial}
              handleUpdateField={updateOrderMaterial}
              handleDeleteField={deleteOrderMaterial}
              handleAddFieldToObject={addMaterialToOrder}
            />
          )}
          <Flex flexWrap="wrap" mb="15px">
            {initialValues.materials &&
            initialValues.materials.map((material, index) => (
              <MaterialBar
                key={index}
                materialDetails={material}
                accountUserType={accountUserType}
                accountPersonnelId={accountPersonnelId}
                deleteMaterialFromOrder={deleteMaterialFromOrder}
              />
            ))}
          </Flex>

          <EditableColorFormDropdown
            dropdownName="Vendor Type"
            label={"VENDOR TYPE"}
            name="type"
            canChangeSelection={true}
            canCreateSelections={editAccess}
            dropdownOptions={vendorTypes}
            selectedOption={selectedVendorType}
            newField={newVendorType}
            handleFieldSelect={handleVendorTypeSelect}
            handleShowCreateFieldSuper={handleShowCreateVendorType}
            handleShowEditFieldSuper={handleShowEditVendorType}
            handleCreateField={handleCreateVendorType}
            handleEditField={handleUpdateVendorType}
            handleDeleteField={handleDeleteVendorType}
            handleColorChange={handleVendorTypeColorChange}
            handleNewFieldNameChange={handleVendorTypeNameChange}/>

          {personnelEnabled &&
          <FormDropdown
            options={personnelList}
            isDisabled={!editAccess}
            name="personnel"
            label="ASSIGNED TO"
            format={(value, name) => {
              if (value && typeof value === "number") {
                return personnelList.find(el => el.id === value);
              }
              return (value ? {
                value: value.id || value.value,
                label: value.name || value.label
              } : "");
            }}
            parse={(value, name) => {
              return (value ? (value.id || value.value) : "");
            }}
          />
          }
          {inspectionNotesVisible && (
            <FormField
              className="item-details-pane-right-notes"
              type="textarea"
              name="inspection_note"
              placeholder="Inspection Note..."
              isDisabled={false}
            />
          )}
          <SubmitButton className="item-details-form-button" loading={submitting}>
            Save
          </SubmitButton>
        </form>
      )}
    />
  );
};

VendorForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  vendorDetails: PropTypes.object,
  vendorTypes: PropTypes.array,
  selectedVendorType: PropTypes.object,
  newVendorType: PropTypes.object,
  editAccess: PropTypes.bool,
  handleVendorTypeSelect: PropTypes.func,
  handleShowCreateVendorType: PropTypes.func,
  handleShowEditVendorType: PropTypes.func,
  handleCreateVendorType: PropTypes.func,
  handleUpdateVendorType: PropTypes.func,
  handleDeleteVendorType: PropTypes.func,
  handleVendorTypeColorChange: PropTypes.func,
  handleVendorTypeNameChange: PropTypes.func,
  orderInspectionNoteVisible: PropTypes.bool,
  /**
   * If the Personnel module is enabled for this org
   */
  personnelEnabled: PropTypes.bool,
};

VendorForm.defaultProps = {
  values: {},
  editAccess: false,
  orderInspectionNoteVisible: true
};

export default VendorForm;
