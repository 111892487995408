import types from "./types";
import moment from "moment";
import uniqBy from "lodash-es/uniqBy";

const initialState = {
  loading: true,
  items: [],
  calendar: [],
  data: {},
  notes: {},
  calendar_dates: {from_date: moment().format("YYYY-MM-DD"), to_date: moment().add(1, "month").format("YYYY-MM-DD")},
  currentPage: 1,
  filter: "",
  filterList: [],
  pageList: [],
  errors: {},
  timeTracker: {
    active: null,
    recent: {},
  },
};


const scheduledShiftsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_CALENDAR_SUCCESS: {
    let combined = [...state.calendar, ...action.payload];
    const newList = uniqBy(combined, (e) => `${e.id}-${e.start}-${e.start_time}-${e.end_time}`);
    return {
      ...state,
      calendar: newList,
    };
  }
  /* Sets items to payload returned from getAllItems in operations. */
  case types.GET_ALL_SUCCESS: {
    let newItems = [];

    /* If success = 0, message is a string and not an array
        and must be handled differnetly. Check payload in redux-devtools for
        debugging issues like this. */
    console.log(action);
    newItems = action.payload;
    const data = {};
    newItems.forEach(item => (data[item.id] = item));

    return {
      ...state,
      loading: false,
      items: action.payload,
      data,
      pageList: newItems.slice(0, state.itemsPerPage),
    };
  }
  case types.GET_ALL_FAILURE:
    return {
      ...state,
      loading: false,
      errors: action,
    };

  case types.GET_ITEM_SUCCESS: {
    const newData = {
      ...state.data,
      [action.payload.id]: action.payload,
    };
    return {
      ...state,
      loading: false,
      data: newData,
    };
  }

  case types.GET_ITEM_NOTES_SUCCESS:
    const newNotes = {
      ...state.notes,
      [action.meta.id]: action.payload,
    };
    return {
      ...state,
      notes: newNotes,
    };

    /* Filters items in state by those that include the querry in item.name and item.tags.  */
  case types.FILTER_LIST: {
    const list = state.items;
    const newFilter = action.filter.split(" ");
    const newList = list.filter(item => {
      let includes = false;
      let includesTags = false;
      if (typeof item.name === "string") {
        includes = true;
        for (let i = 0; i < newFilter.length; i += 1) {
          if (!item.name.toLowerCase().includes(newFilter[i].toLowerCase())) {
            includes = false;
          }
          if (item.tags) {
            const {tags} = item;
            for (let j = 0; j < tags.length; j += 1) {
              if (tags[j].toLowerCase().includes(newFilter[i].toLowerCase())) {
                includesTags = true;
              }
            }
          }
        }
      }
      return includes || includesTags;
    });

    return {
      ...state,
      filter: newFilter,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage),
    };
  }

  case types.GET_ACTIVE_SHIFT_ACTIVITY_SUCCESS:
    return {
      ...state,
      timeTracker: {
        ...state.timeTracker,
        active: action.payload,
      },
    };

  case types.GET_RECENT_SHIFT_ACTIVITY_SUCCESS:
    return {
      ...state,
      timeTracker: {
        ...state.timeTracker,
        recent: action.payload,
      },
    };
  default:
    return state;
  }
};

export default scheduledShiftsReducer;
