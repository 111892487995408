import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FormDrawer} from "../../../Common/Drawers";
import {ReceivePalletForm} from "../../Forms";
import {formatInventoryForReceivePallet} from "../../utils/inventory";
import {validateReceivePallet} from "./utils";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY, STORE} from "../../utils/constants";
import {initializeReceiveInventory} from "../../Forms/ReceivePallet/utils";

const ReceivePalletDrawer = props => {
  const {
    onSubmit,
    values,
    formProps,
    palletDetails,
    locationPagination,
    getLocations,
    createLocation,
  } = props;
  const [receiveAction, setReceiveAction] = useState(STORE);

  const [selectedMaterials, setSelectedMaterials] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [valid, setIsValid] = useState(true);

  useEffect(() => {
    const {product_inventory, material_inventory, location} = palletDetails;

    if (product_inventory && material_inventory) {
      // palletDetails.product_inventory.forEach(item => productData[item.id] = item);
      // palletDetails.material_inventory.forEach(item => materialData[item.id] = item);
      const productData = initializeReceiveInventory({
        inventory: product_inventory,
        location: location,
      });
      const materialData = initializeReceiveInventory({
        inventory: material_inventory,
        location: location,
      });
      setSelectedMaterials(materialData);
      setSelectedProducts(productData);
    }
  }, [palletDetails]);


  const handleSubmit = async (values) => {
    const formattedInventory = formatInventoryForReceivePallet({materials: selectedMaterials, products: selectedProducts});

    const submitData = {
      action: receiveAction,
      values: values,
      pallet: palletDetails,
      ...formattedInventory,
    };

    const isValid = validateReceivePallet(submitData, palletDetails);
    if (isValid) {
      const res = await onSubmit(submitData);
      return res;
    } else {
      console.log("not valid");
      return ({error: true});
    }
  };

  const handleEditQuantity = (values, type) => {
    console.log("drawer edit quantity", values);
    let materials = selectedMaterials;
    let products = selectedProducts;
    console.log("edit values", values);
    if (type === PRODUCT_INVENTORY) {
      products = {
        ...selectedProducts,
        [values.id]: values,
      };
      console.log("product", values);
      setSelectedProducts(products);
    }
    if (type === MATERIAL_INVENTORY) {
      materials = {
        ...selectedMaterials,
        [values.id]: values,
      };
      setSelectedMaterials(materials);
    }
    const formattedInventory = formatInventoryForReceivePallet({materials, products});
    const isValid = validateReceivePallet(formattedInventory, palletDetails);

    console.log("valid", isValid);
    if (!isValid) {
      setIsValid(false);
    }
    if (isValid) {
      setIsValid(true);
    }
  };

  const formConfig = {
    palletDetails,
    onEdit: handleEditQuantity,
    receiveAction,
    setReceiveAction,
    locationPagination,
    getLocations,
    createLocation,
    ...formProps,
  };
  return (
    <FormDrawer
      onSubmit={handleSubmit}
      FormComponent={ReceivePalletForm}
      values={values}
      text={{
        title: "Receive Pallet",
        tooltip: "Receive pallet and update quantities as available.",
        openButton: "Receive Pallet",
      }}
      formID={"receive-pallet-form"}
      buttonConfig={{
        type: "primary",
        ariaLabel: "open receive pallet form",
      }}
      formConfig={formConfig}
      valid={valid}
    />
  );
};

ReceivePalletDrawer.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.func,
  formProps: PropTypes.object,
  pallet: PropTypes.object,
  locationPagination: PropTypes.object,
  getLocations: PropTypes.func,
  createLocation: PropTypes.func,
};

export default ReceivePalletDrawer;
