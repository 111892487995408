import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";


import {numericalSelectFieldHandler} from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";

import {InputWrapper, Label, Row, RowLabelWrapper, RowOptions} from "../../../styles/styled";

const RepeatMonthlyOn = ({
  id,
  mode,
  on,
  hasMoreModes,
  handleChange,
  translations
}) => {
  const isActive = mode === "on";

  return (
    <Row className={`form-group ${!isActive && "opacity-50"}`}>
      <RowLabelWrapper>
        {hasMoreModes && (
          <input
            id={id}
            type="radio"
            name="repeat.monthly.mode"
            aria-label="Repeat monthly on"
            value="on"
            checked={isActive}
            onChange={handleChange}
          />
        )}
        <Label>
          {translateLabel(translations, "repeat.monthly.on_day")}

        </Label>
      </RowLabelWrapper>


      <RowOptions>
        <InputWrapper width={"100px"}>
          <Select
            id={`${id}-day`}
            name="repeat.monthly.on.day"
            aria-label="Repeat monthly on a day"
            className="form-control"
            value={{value: on.day, label: on.day}}
            isDisabled={!isActive}
            onChange={numericalSelectFieldHandler(handleChange)}
            options={[...new Array(31)].map((day, i) => ({
              value: i + 1,
              label: i + 1,
              name: "repeat.monthly.on.day"
            }))}
          />
        </InputWrapper>

      </RowOptions>
    </Row>
  );
};
RepeatMonthlyOn.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  on: PropTypes.shape({
    day: PropTypes.number.isRequired
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default RepeatMonthlyOn;
