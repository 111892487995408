import React, {Fragment, useState} from "react";
import {Collapse, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader} from "@material-ui/core";
import PropTypes from "prop-types";
import LocationGroup from "./LocationGroup";
import {Flex} from "../../Base";
import {CaretDown, CaretUp} from "../../Base/Icons";
import {FaEdit} from "react-icons/fa";
import {ListButton} from "../../Lists/styled";
import {Input} from "@rebass/forms";
import {SecondaryButton} from "../styled";
import {Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {checkNestedKeys} from "../../../../utils/propUtils";

const LocationItem = props => {
  const {handleEdit, item, classes, levels, isEditable, selectedLocation, setSelectedLocation, updateLocation, accountInfo} = props;
  const {sub_location_name} = item;

  const [open, setOpen] = useState(false);
  const [isEditingSubheader, setEditingSubheader] = useState(false);
  const [subLocationName, setSubLocationName] = useState(sub_location_name || levels[1]);

  const thisLevel = levels[0];
  const nextLevel = levels[1];
  const newLevelList = levels.slice(1);
  const hasChildren = nextLevel && item[nextLevel].length !== 0;
  const uniqueID = `${thisLevel}:${item.id}:${item.name}`;

  const handleClick = () => {
    setOpen(!open);
    setSelectedLocation(selectedLocation["uniqueID"] !== uniqueID ? {uniqueID, item} : {uniqueID: "", item: {}});
  };

  const handleCancelEdit = () => {
    setEditingSubheader(false);
    setSubLocationName(sub_location_name || levels[1]);
  };

  const submit = async (e) => {
    e.preventDefault();
    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);
    if (orgId) {
      let response;
      if (subLocationName) {
        const submitData = {
          "level": thisLevel,
          "id": item.id,
          "sub_location_name": subLocationName,
          "location_instance": item.location_instance
        };
        response = await updateLocation(orgId, submitData);
      }
      if (response && !response.error) {
        const {payload} = response;
        setEditingSubheader(false);
        setSubLocationName(payload.sub_location_name);
      }
    }
  };

  return (
    <Fragment key={item.id}>
      <ListItem
        button={hasChildren || !isEditable}
        onClick={handleClick}
        key={item.id}
        selected={selectedLocation.uniqueID === uniqueID}
        className={`${classes.listItem} location-item`}>
        <ListItemText className={classes.listItemText}>{item.name}</ListItemText>
        {hasChildren &&
        <Flex width={"15px"} mr={"15px"}>
          {open ? <CaretUp/> : <CaretDown/>}
        </Flex>
        }
        {isEditable &&
        <ListItemSecondaryAction>
          <ListButton
            icon={FaEdit}
            aria-label={"edit"}
            onClick={() => handleEdit(item, thisLevel, nextLevel, item.location)}
          />
        </ListItemSecondaryAction>
        }
      </ListItem>

      {hasChildren &&
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nested}>
          {!isEditingSubheader ?
            <ListSubheader onClick={() => setEditingSubheader(true)} className={classes.subheader} style={{cursor: "pointer"}}>
              {subLocationName}
              <FontAwesomeIcon icon="edit" style={{marginLeft: "5px"}}/>
            </ListSubheader>
            :
            <Form
              onSubmit={submit}
              initialValues={null}
              render={({handleSubmit, form, submitting, pristine, values}) => (
                <form className="location-level-form" onSubmit={submit}>
                  <Input
                    backgroundColor="white"
                    placeholder={"Edit level name"}
                    value={subLocationName}
                    minWidth={100}
                    maxWidth={400}
                    mt={"20px"}
                    type="text"
                    name="fieldNewLevel"
                    disabled={false}
                    onChange={e => setSubLocationName(e.target.value)}
                    className="input-pane new-location-level sub-location-input"
                  />
                  <Flex>
                    <SecondaryButton type={"primary"} htmlType="submit" className="form-button" disabled={!subLocationName}>
                      Submit Edit
                    </SecondaryButton>
                    <SecondaryButton onClick={handleCancelEdit} className="form-button">
                      Cancel
                    </SecondaryButton>
                  </Flex>
                </form>)}/>
          }
          {React.createElement(LocationGroup, {
            items: item[nextLevel],
            levels: newLevelList,
            classes: classes,
            handleEdit: handleEdit,
            isEditable: isEditable,
            selectedLocation: selectedLocation,
            setSelectedLocation: setSelectedLocation,
            updateLocation: updateLocation,
            accountInfo: accountInfo
          })}
        </List>
      </Collapse>
      }
    </Fragment>
  );
};

LocationItem.propTypes = {
  item: PropTypes.object.isRequired,
  levels: PropTypes.array,
  classes: PropTypes.any,
  handleEdit: PropTypes.func,
  isEditable: PropTypes.bool,
  selectedLocation: PropTypes.object,
  setSelectedLocation: PropTypes.func,
};

LocationItem.defaultProps = {
  isEditable: true,
  setSelectedLocation: () => {
  }
};

export default LocationItem;
