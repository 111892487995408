import assetReducer from "./reducers";

/* These exports are the only thing that need to be edited for a new module */
export const name = "v1/assets/";

export {default as assetTypes} from "./types";
export {default as assetSelectors} from "./selectors";
export {default as assetOperations} from "./operations";
export {default as assetActions} from "./actions";

export default assetReducer;
