import React from "react";
import PropTypes from "prop-types";
import {Bottom, SeeMoreButton, SideList, Wrapper} from "../../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../../Common/Bars/BarComponents/constants";
import {renderNestedName} from "../../utils";
import {PurchaseOrderBarDetails} from "./Details";

/* An Purchase Order bar is a div containing information regarding an asset. A list of
asset bars are visible within the assets section. */

const PurchaseOrderBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const {total_cost, pallets, reference, materials, items, status_display} = data;
  const supplier = renderNestedName(data, "supplier");
  const baseClassName = listView ? listStyle : tileStyle;
  const leftList = [
    {title: "STATUS", value: status_display || "None"},
    {title: "PURCHASE ORDER #", value: reference || "None"},
    {title: "SUPPLIER", value: supplier}
  ];
  const rightList = [
    {title: "TOTAL COST", value: total_cost},
    {title: "PRODUCTS", value: items?.length ?? "None"},
    {title: "MATERIALS", value: materials?.length ?? 0},
    {title: "PALLETS", value: pallets?.length ?? 0}
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="warehouse/purchase_orders"
      itemData={data}
      detailsPane={<PurchaseOrderBarDetails baseClassName={baseClassName} />}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
      <SideList
        baseClassName={baseClassName}
        isRight={true}
        list={rightList}
      />
    </Wrapper>
  );
};

PurchaseOrderBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array
};

export {PurchaseOrderBar};
