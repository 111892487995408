import base from "../actions";
import {BULK_DELETE} from "../../../routes/constants";
import {constructRoute} from "../utils";


const createCrudActions = ({types, endpoint, withNotes = false, withCsv = false, paginator = null, withBulkDelete}) => {
  const {
    CREATE_ITEM_REQUEST,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_FAILURE,

    GET_ITEM_REQUEST,
    GET_ITEM_SUCCESS,
    GET_ITEM_FAILURE,

    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE,

    DELETE_ITEM_REQUEST,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE,

    BULK_DELETE_REQUEST,
    BULK_DELETE_SUCCESS,
    BULK_DELETE_FAILURE
  } = types;

  const getAllItems = (params = "") => base.getRsaa({
    endpoint: `${global.base_url}${endpoint}${params}`,
    types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
  });

  const createItem = (data) => base.postRsaa({
    endpoint: `${global.base_url}${endpoint}`,
    body: JSON.stringify(data),
    types: [CREATE_ITEM_REQUEST, CREATE_ITEM_SUCCESS, CREATE_ITEM_FAILURE],
  });

  /**
   * Create action that does not Stringify data.
   * For uploading FormData objects with files
   * @param {FormData} data - FormData object
   * @returns {object} - RSAA response
   */
  const createItemFile = (data) => base.postRsaa({
    endpoint: `${global.base_url}${endpoint}`,
    body: data,
    types: [CREATE_ITEM_REQUEST, CREATE_ITEM_SUCCESS, CREATE_ITEM_FAILURE],
  });

  const getItem = (id, params = "") => base.getRsaa({
    endpoint: `${global.base_url}${endpoint}${id}/${params}`,
    types: [GET_ITEM_REQUEST, GET_ITEM_SUCCESS, GET_ITEM_FAILURE],
  });

  const updateItem = (id, data) => base.patchRsaa({
    endpoint: `${global.base_url}${endpoint}${id}/`,
    body: JSON.stringify(data),
    types: [UPDATE_ITEM_REQUEST, UPDATE_ITEM_SUCCESS, UPDATE_ITEM_FAILURE],
  });

  /**
   * Update action that does not Stringify data.
   * For uploading FormData objects with files
   * @param {number} id - id of object to update
   * @param {FormData} data - FormData object
   * @returns {object} - RSAA response
   */
  const updateItemFile = (id, data) => base.patchRsaa({
    endpoint: `${global.base_url}${endpoint}${id}/`,
    body: data,
    headers: {},
    types: [UPDATE_ITEM_REQUEST, UPDATE_ITEM_SUCCESS, UPDATE_ITEM_FAILURE],
  });

  const deleteItem = id => base.deleteRsaa({
    endpoint: `${global.base_url}${endpoint}${id}/`,
    types: [DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS, DELETE_ITEM_FAILURE],
  });

  let bulkDelete = {};
  if (withBulkDelete) {
    const bulkDeleteAction = (data) => base.patchRsaa({
      endpoint: constructRoute(`${endpoint}${BULK_DELETE}`),
      body: JSON.stringify(data),
      types: [
        BULK_DELETE_REQUEST,
        BULK_DELETE_SUCCESS,
        BULK_DELETE_FAILURE
      ]
    });
    bulkDelete = bulkDeleteAction;
  }

  let noteActions = {};
  if (withNotes) {
    /* Fetch Item Notes */
    const getItemNotes = id => base.getRsaa({
      endpoint: `${global.base_url}${endpoint}${id}/notes/`,
      types: [
        types.GET_ITEM_NOTES_REQUEST,
        {
          type: types.GET_ITEM_NOTES_SUCCESS,
          meta: {id},
        },
        types.GET_ITEM_NOTES_FAILURE,
      ],
    });

    const submitNote = (id, data) => base.postRsaa({
      endpoint: `${global.base_url}${endpoint}${id}/notes/`,
      body: JSON.stringify(data),
      types: [
        types.SUBMIT_NOTE_REQUEST,
        {
          type: types.SUBMIT_NOTE_SUCCESS,
          meta: {id},
        },
        types.SUBMIT_NOTE_FAILURE,
      ],
    });

    const updateNote = (id, noteId, data) => base.patchRsaa({
      endpoint: `${global.base_url}${endpoint}${id}/notes/${noteId}`,
      body: JSON.stringify(data),
      types: [
        types.UPDATE_NOTE_REQUEST,
        {
          type: types.UPDATE_NOTE_SUCCESS,
          meta: {id, noteId},
        },
        types.UPDATE_NOTE_FAILURE,
      ],
    });
    noteActions = {getItemNotes, submitNote, updateNote};
  }
  let csvActions = {};
  if (withCsv) {
    const uploadCsv = (data, org) => base.postRsaa({
      endpoint: `${global.base_url}${endpoint}csv/${org}/`,
      headers: {},
      body: data,
      types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE],
    });

    const clearMessages = () => ({
      type: types.CLEAR_MESSAGES
    });
    csvActions = {uploadCsv, clearMessages};
  }

  let pageActions = {};
  if (paginator) {
    pageActions = {
      ...paginator.actions
    };
  }

  return {
    getAllItems,
    createItem,
    createItemFile,
    getItem,
    updateItem,
    updateItemFile,
    deleteItem,
    bulkDelete,
    ...noteActions, ...csvActions, ...pageActions
  };
};

const createNestedCrudActions = ({types, endpoint, nestedEndpoint}) => {
  const {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,

    CREATE_ITEM_REQUEST,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_FAILURE,

    GET_ITEM_REQUEST,
    GET_ITEM_SUCCESS,
    GET_ITEM_FAILURE,

    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE,

    DELETE_ITEM_REQUEST,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE,
  } = types;


  const getAllItems = (id, params = "") => base.getRsaa({
    endpoint: `${global.base_url}${endpoint}${id}/${nestedEndpoint}/${params}`,
    types: [GET_ALL_REQUEST, GET_ALL_SUCCESS, GET_ALL_FAILURE],
  });


  const createItem = (id, data) => base.postRsaa({
    endpoint: `${global.base_url}${endpoint}${id}/${nestedEndpoint}`,
    body: JSON.stringify(data),
    types: [CREATE_ITEM_REQUEST, CREATE_ITEM_SUCCESS, CREATE_ITEM_FAILURE],
  });

  const getItem = (parentID, id, params = "") => base.getRsaa({
    endpoint: `${global.base_url}${endpoint}${parentID}/${nestedEndpoint}${id}/${params}`,
    types: [GET_ITEM_REQUEST, GET_ITEM_SUCCESS, GET_ITEM_FAILURE],
  });

  const updateItem = (parentID, id, data) => base.patchRsaa({
    endpoint: `${global.base_url}${endpoint}${parentID}/${nestedEndpoint}${id}/`,
    body: JSON.stringify(data),
    types: [UPDATE_ITEM_REQUEST, UPDATE_ITEM_SUCCESS, UPDATE_ITEM_FAILURE],
  });

  const deleteItem = (parentID, id) => base.deleteRsaa({
    endpoint: `${global.base_url}${endpoint}${parentID}/${nestedEndpoint}${id}/`,
    types: [DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS, DELETE_ITEM_FAILURE],
  });

  return {getAllItems, createItem, getItem, updateItem, deleteItem};
};

export {createNestedCrudActions};

export default createCrudActions;
