const refreshPriceHistory = (state, id) => {
  if (state.price_history && state.price_history[id]) {
    return state.price_history[id];
  }
  return [];
};

const refreshStatusHistory = (state, id) => {
  if (state.status_history && state.status_history[id]) {
    return state.status_history[id];
  }
  return [];
};

export default {
  refreshPriceHistory,
  refreshStatusHistory
};
