import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Box, Column, Row} from "Base";
import {crudActions, paginationProps} from "../../../../../types/propTypes";
import {groupBy} from "../../../../../utils/sort";
import {dateFormats} from "../../../../../utils/dates";
import {DateRangeForm} from "../../../../Common/Forms/ScheduledShift";
import EntryList from "./EntryList";
import EntryFormDrawer from "./EntryFormDrawer";
import ActiveEntry from "./ActiveEntry";
import moment from "moment-timezone";
import {formatDateParams} from "./utils";

const groupByDate = (data) => {
  return data ? groupBy(data, "date") : [];
};

const TODAY = moment().startOf("day");

const DEFAULT_RANGE = {
  start: TODAY.format(dateFormats.date),
  end: TODAY.add(14, "days").format(dateFormats.date),
};

const EntrySection = props => {
  const {pagination, actions, productionLine, salesOrderActions, lineActions} = props;
  const {itemsCurrentPage} = pagination;
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);

  const handleFetchPage = (page = itemsCurrentPage, params = {}) => {
    actions.getPage(page, {...formatDateParams(dateRange), ...params});
  };

  useEffect(() => {
    actions.getPage(1, formatDateParams(dateRange));
  }, [actions, dateRange]);

  const data = pagination.itemsPaged;
  const byDate = groupByDate(data);

  const handleDateChange = (values) => {
    setDateRange(values);
    actions.getPage(1, formatDateParams(values));
  };

  const handleCreate = async (values) => {
    const res = await actions.create(values);
    lineActions.getItem(productionLine.id);
    actions.getPage(1, formatDateParams(dateRange));
    return res;
  };


  return (
    <Column py={2} overflow={"visible"} minHeight={"600px"}>


      <Row py={2} className={"entry-calendar"}>
        <DateRangeForm onSubmit={handleDateChange} values={dateRange}/>

        <EntryFormDrawer
          onSubmit={handleCreate}
          productionLine={productionLine}
          formConfig={{
            salesOrderActions
          }}
          values={{
            people_planned: 0,
            date: moment().format("YYYY-MM-DD"),
            start: moment().format(),
            end: null
          }}
        />
      </Row>
      <ActiveEntry
        data={productionLine?.active_entry ?? null}
        productionLine={productionLine}
        actions={actions}
        lineActions={lineActions}
        salesOrderActions={salesOrderActions}
      />
      <Box py={2}>
        <EntryList
          data={byDate}
          actions={{
            ...actions,
            getPage: handleFetchPage,
            getProductionLine: () => lineActions.getItem(productionLine.id)
          }}
        />
      </Box>
    </Column>
  );
};

EntrySection.propTypes = {
  actions: crudActions,
  salesOrderActions: crudActions,
  pagination: paginationProps,
  productionLine: PropTypes.object,
  data: PropTypes.object
};

export default EntrySection;
