import actions from "./actions";
import {baseOperations} from "../base";
import {dateUtils, snackUtils} from "../../utils";

/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getAllItems = () => async dispatch => {
  const res = await dispatch(actions.getAllItems());
  if (res && res.error && res.error.response && res.error.response.status === 404) {
    dispatch(actions.getAllNoneFound());
  }
  return res;
};

const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Vendor Order", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Vendor Order", res.payload.title, dispatch, () => {
  });
  return res.payload;
};

const bulkDelete = (data) => async dispatch => {
  const res = await dispatch(actions.bulkDelete(data));
  console.log("BULK OPERATIONS: ", res);
  dispatch(getAllItems());
};

/* This Function handles sending an Item update request to the API
for a specific field. Then dispatches either the updateSuccess or
updateFailure action. Also dispatches snackbar messages on start
and finish. */
const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Vender Order.", 3000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, fieldData));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Vendor Order", res.payload.title, dispatch, () => {
    dispatch(getAllItems());
  });
};

/* This Function handles adding a new item to the current parent item.
The first example of this is adding a note to a vendor order. */
const addNote = (id, data) => async dispatch => {
  const res = await dispatch(actions.submitNote(id, data));
  baseOperations.handleSubmitNoteResponse(res, dispatch);
  return res.payload;
};

export default {
  getAllItems,
  updateItem,
  updateItemField,
  addNote,
  bulkDelete
};
