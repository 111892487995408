import React, {useState} from "react";
import {Form, Link, Popconfirm, Table} from "../../../Common/Base";
import {EditableCell} from "Common/Tables";


const OrderProductTable = ({onSubmit, data}) => {
  const [form] = Form.useForm();
  // const [data, setData] = useState(items);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({...record});
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {...item, ...row});
        // setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        // setData(newData);
        setEditingKey("");
      }
      onSubmit(newData[index]);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      render: (_, record) => (
        <Link to={`/dashboard/warehouse/inventory/${record.product.id}`}>{record.product.name ? record.product.name : record.product.sku}</Link>
      ),
      key: "name",
      width: "25%",
      // fixed: 'left',
      editable: false,
    },
    {
      title: "SKU",
      dataIndex: "product",
      render: (text, record) => (
        record.product.sku
      ),
      key: "sku",
      width: "25%",
      editable: false,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
      editable: true,
    },
    {
      title: "Remaining",
      dataIndex: "available",
      key: "available",
      width: "10%",
      editable: false,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "10%",
      editable: true,
      render: (text, record) => (
        `$${text ? text : 0.00}`
      ),
    },
    {
      title: "Total",
      dataIndex: "total_price",
      key: "total",
      width: "20%",
      editable: false,
    },
    {
      title: "Actions",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <span
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </span>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <span>Cancel</span>
            </Popconfirm>
          </span>
        ) : (
          <span disabled={editingKey !== ""} onClick={() => edit(record)}>
            Edit
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        size={"small"}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        scroll={{x: 800}}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="product-row"
        rowKey={"id"}
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default OrderProductTable;
