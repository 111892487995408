import React, {Component} from "react";
import {connect} from "react-redux";
import {
  authSelectors,
  baseSelectors,
  vendorsActions,
  vendorsOperations,
  vendorsSelectors
} from "../../store/";
import {VendorOrdersDashboard} from "../../components/VendorOrders/Dashboard/VendorOrdersDashboard";

/* This will present a list of vendor order bars. */
class VendorsContainer extends Component {
  render() {
    return (
      <VendorOrdersDashboard {...this.props}/>
    );
  }
}

const mapStateToProps = state => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  allItems: baseSelectors.allItems(state.vendors),
  currentPage: baseSelectors.refreshCurrentPage(state.vendors),
  loading: baseSelectors.isLoading(state.vendors),
  listView: baseSelectors.isListView(state.vendors),
  tileView: baseSelectors.isTileView(state.vendors),
  tableView: baseSelectors.isTableView(state.vendors),
  calendarView: baseSelectors.isCalendarView(state.vendors),
  pageNum: baseSelectors.currPageNum(state.vendors),
  isLastPage: baseSelectors.isLastPage(state.vendors),
  calendarEvents: vendorsSelectors.refreshCalendarEvents(state.vendors),
  hasErrors: baseSelectors.hasErrors(state.vendors),
  isEmptyList: baseSelectors.isEmptyList(state.vendors),
  isReversed: baseSelectors.isListReversed(state.vendors),
  filteringBy: vendorsSelectors.filteringBy(state.vendors)
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  getServicesList: () => dispatch(vendorsOperations.getAllItems()),
  nextPage: () => dispatch(vendorsActions.nextPage()),
  prevPage: () => dispatch(vendorsActions.prevPage()),
  bulkDelete: (data) => dispatch(vendorsOperations.bulkDelete(data)),
  filterVendors: filter => dispatch(vendorsActions.filterList(filter)),
  filterListBy: by => dispatch(vendorsActions.filterListBy(by)),
  sortVendors: sortBy => dispatch(vendorsActions.sortList(sortBy)),
  reverseList: () => dispatch(vendorsActions.reverseList()),
  changeViewList: () => dispatch(vendorsActions.changeViewList()),
  changeViewTile: () => dispatch(vendorsActions.changeViewTile()),
  changeViewTable: () => dispatch(vendorsActions.changeViewTable()),
  changeViewCalendar: () => dispatch(vendorsActions.changeViewCalendar())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorsContainer);
