import React from "react";
import PropTypes from "prop-types";
import {AsyncPaginate} from "react-select-async-paginate";

const defaultFormatOptions = (data) => {
  return data ? data.map(item => ({label: item.name, value: item.id})) : [];
};

const PaginatedSelect = ({value, onChange, getPage, name, formatOptions}) => {
  const handleChange = (newValue) => {
    console.log("location onChange", newValue);
    onChange(newValue);
  };

  const handleLoad = async (search, loadedOptions, {page}) => {
    const res = await getPage(page, {search: search});
    console.log("handleLoad res", res);
    // return loadOptions(search, loadedOptions, {page, getPage});
    if (!res.error) {
      const {payload, meta} = res;
      const {page} = meta;

      const hasMore = Boolean(payload.next);
      return {
        options: formatOptions(payload?.results ?? []),
        hasMore,
        additional: {
          page: hasMore ? page + 1 : page,
        },
      };
    }
    return ({
      options: [],
      hasMore: false,
      additional: {
        page,
      },
    });
  };
  return (
    <AsyncPaginate
      value={value}
      loadOptions={handleLoad}
      onChange={handleChange}
      className={"item-details-form-dropdown"}
      name={name}
      debounceTimeout={300}
      additional={{
        page: 1,
      }}
    />
  );
};

PaginatedSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  pagination: PropTypes.object,
  getPage: PropTypes.func,
  formatOptions: PropTypes.func
};

PaginatedSelect.defaultProps = {
  formatOptions: defaultFormatOptions
};

export const AdaptedPaginatedSelect = ({input, ...rest}) => {
  return <PaginatedSelect {...input} {...rest}/>;
};

export default PaginatedSelect;
