import moment from "moment-timezone";

const combineDateFormat = "YYYY-MM-DD HH:mm:ss";
/**
 * Makes a moment object from the provided date and time
 * @param {string} date The date
 * @param {string} time The time
 * @returns {moment.Moment} The corresponding moment object
 */
const makeDateTime = (date, time) => {
  return moment(`${date} ${time}`, combineDateFormat);
};

/**
 * Given the state of the calendar, returns all the events
 * @param {object} calendar The state of the calendar
 * @returns {Array} The events associated with the state
 */
const parseCalendarQueryResult = (calendar) => {
  let result = [];
  try {
    calendar.forEach(shift => {
      let title = `${shift.schedule ? shift.schedule.name : "Scheduled Shift"}`;

      const start = makeDateTime(shift.start, shift.start_time);
      const end = makeDateTime(shift.end ? shift.end : shift.start, shift.end_time);
      const personnelNameList = [];
      shift.personnel.map(item => personnelNameList.push(item.name));

      title = title + " - " + personnelNameList.join(", ");
      result.push({
        ...shift,
        "start": start.toDate(),
        "end": end.toDate(),
        // "date": moment(shift.start),
        "title": title,
        "personnel": personnelNameList,
        "resource": "Personnel list"
      });
    });
  } catch (e) {
    result = [];
  }
  return result;
};

export default {
  parseCalendarQueryResult
};
