export const bondingTypes = [
  {value: 0, label: "Bid Bond"},
  {value: 1, label: "Performance & Payment"},
  {value: 2, label: "Material Supply Bond"},
  {value: 3, label: "Warranty Bond"}
];

export const statesOptions = [
  {value: "AK - Alaska", label: "AK - Alaska"},
  {value: "AL - Alabama", label: "AL - Alabama"},
  {value: "AR - Arkansas", label: "AR - Arkansas"},
  {value: "AS - American Samoa", label: "AS - American Samoa"},
  {value: "AZ - Arizona", label: "AZ - Arizona"},
  {value: "CA - California", label: "CA - California"},
  {value: "CO - Colorado", label: "CO - Colorado"},
  {value: "CT - Connecticut", label: "CT - Connecticut"},
  {value: "DC - District of Columbia", label: "DC - District of Columbia"},
  {value: "DE - Delaware", label: "DE - Delaware"},
  {value: "FL - Florida", label: "FL - Florida"},
  {value: "GA - Georgia", label: "GA - Georgia"},
  {value: "GU - Guam", label: "GU - Guam"},
  {value: "HI - Hawaii", label: "HI - Hawaii"},
  {value: "IA - Iowa", label: "IA - Iowa"},
  {value: "ID - Idaho", label: "ID - Idaho"},
  {value: "IL - Illinois", label: "IL - Illinois"},
  {value: "IN - Indiana", label: "IN - Indiana"},
  {value: "KS - Kansas", label: "KS - Kansas"},
  {value: "KY - Kentucky", label: "KY - Kentucky"},
  {value: "LA - Louisiana", label: "LA - Louisiana"},
  {value: "MA - Massachusetts", label: "MA - Massachusetts"},
  {value: "MD - Maryland", label: "MD - Maryland"},
  {value: "ME - Maine", label: "ME - Maine"},
  {value: "MI - Michigan", label: "MI - Michigan"},
  {value: "MN - Minnesota", label: "MN - Minnesota"},
  {value: "MO - Missouri", label: "MO - Missouri"},
  {value: "MS - Mississippi", label: "MS - Mississippi"},
  {value: "MT - Montana", label: "MT - Montana"},
  {value: "NC - North Carolina", label: "NC - North Carolina"},
  {value: "ND - North Dakota", label: "ND - North Dakota"},
  {value: "NE - Nebraska", label: "NE - Nebraska"},
  {value: "NH - New Hampshire", label: "NH - New Hampshire"},
  {value: "NJ - New Jersey", label: "NJ - New Jersey"},
  {value: "NM - New Mexico", label: "NM - New Mexico"},
  {value: "NV - Nevada", label: "NV - Nevada"},
  {value: "NY - New York", label: "NY - New York"},
  {value: "OH - Ohio", label: "OH - Ohio"},
  {value: "OK - Oklahoma", label: "OK - Oklahoma"},
  {value: "OR - Oregon", label: "OR - Oregon"},
  {value: "PA - Pennsylvania", label: "PA - Pennsylvania"},
  {value: "PR - Puerto Rico", label: "PR - Puerto Rico"},
  {value: "RI - Rhode Island", label: "RI - Rhode Island"},
  {value: "SC - South Carolina", label: "SC - South Carolina"},
  {value: "SD - South Dakota", label: "SD - South Dakota"},
  {value: "TN - Tennessee", label: "TN - Tennessee"},
  {value: "TX - Texas", label: "TX - Texas"},
  {value: "UT - Utah", label: "UT - Utah"},
  {value: "VA - Virginia", label: "VA - Virginia"},
  {value: "VI - Virgin Islands", label: "VI - Virgin Islands"},
  {value: "VT - Vermont", label: "VT - Vermont"},
  {value: "WA - Washington", label: "WA - Washington"},
  {value: "WI - Wisconsin", label: "WI - Wisconsin"},
  {value: "WV - West Virginia", label: "WV - West Virginia"},
  {value: "WY - Wyoming", label: "WY - Wyoming"}
];

export const countryList = [
  {value: "United States", label: "United States"},
  {value: "Afghanistan", label: "Afghanistan"},
  {value: "Albania", label: "Albania"},
  {value: "Algeria", label: "Algeria"},
  {value: "Andorra", label: "Andorra"},
  {value: "Angola", label: "Angola"},
  {value: "Anguilla", label: "Anguilla"},
  {value: "Antigua &amp; Barbuda", label: "Antigua &amp; Barbuda"},
  {value: "Argentina", label: "Argentina"},
  {value: "Armenia", label: "Armenia"},
  {value: "Aruba", label: "Aruba"},
  {value: "Australia", label: "Australia"},
  {value: "Austria", label: "Austria"},
  {value: "Azerbaijan", label: "Azerbaijan"},
  {value: "Bahamas", label: "Bahamas"},
  {value: "Bahrain", label: "Bahrain"},
  {value: "Bangladesh", label: "Bangladesh"},
  {value: "Barbados", label: "Barbados"},
  {value: "Belarus", label: "Belarus"},
  {value: "Belgium", label: "Belgium"},
  {value: "Belize", label: "Belize"},
  {value: "Benin", label: "Benin"},
  {value: "Bermuda", label: "Bermuda"},
  {value: "Bhutan", label: "Bhutan"},
  {value: "Bolivia", label: "Bolivia"},
  {value: "Bosnia &amp; Herzegovina", label: "Bosnia &amp; Herzegovina"},
  {value: "Botswana", label: "Botswana"},
  {value: "Brazil", label: "Brazil"},
  {value: "British Virgin Islands", label: "British Virgin Islands"},
  {value: "Brunei", label: "Brunei"},
  {value: "Bulgaria", label: "Bulgaria"},
  {value: "Burkina Faso", label: "Burkina Faso"},
  {value: "Burundi", label: "Burundi"},
  {value: "C ambodia", label: "C ambodia"},
  {value: "Cameroon", label: "Cameroon"},
  {value: "Cape Verde", label: "Cape Verde"},
  {value: "Cayman Islands", label: "Cayman Islands"},
  {value: "Chad", label: "Chad"},
  {value: "Chile", label: "Chile"},
  {value: "China", label: "China"},
  {value: "Colombia", label: "Colombia"},
  {value: "Congo", label: "Congo"},
  {value: "Cook Islands", label: "Cook Islands"},
  {value: "Costa Rica", label: "Costa Rica"},
  {value: "Cote D Ivoire", label: "Cote D Ivoire"},
  {value: "Croatia", label: "Croatia"},
  {value: "Cruise Ship", label: "Cruise Ship"},
  {value: "Cuba", label: "Cuba"},
  {value: "Cyprus", label: "Cyprus"},
  {value: "Czech Republic", label: "Czech Republic"},
  {value: "Denmark", label: "Denmark"},
  {value: "Djibouti", label: "Djibouti"},
  {value: "Dominica", label: "Dominica"},
  {value: "Dominican Republic", label: "Dominican Republic"},
  {value: "Ecuador", label: "Ecuador"},
  {value: "Egypt", label: "Egypt"},
  {value: "El Salvador", label: "El Salvador"},
  {value: "Equatorial Guinea", label: "Equatorial Guinea"},
  {value: "Estonia", label: "Estonia"},
  {value: "Ethiopia", label: "Ethiopia"},
  {value: "Falkland Islands", label: "Falkland Islands"},
  {value: "Faroe Islands", label: "Faroe Islands"},
  {value: "Fiji", label: "Fiji"},
  {value: "Finland", label: "Finland"},
  {value: "France", label: "France"},
  {value: "Fren ch Polynesia", label: "Fren ch Polynesia"},
  {value: "French West Indies", label: "French West Indies"},
  {value: "Gabon", label: "Gabon"},
  {value: "Gambia", label: "Gambia"},
  {value: "Georgia", label: "Georgia"},
  {value: "Germany", label: "Germany"},
  {value: "Ghana", label: "Ghana"},
  {value: "Gibraltar", label: "Gibraltar"},
  {value: "Greece", label: "Greece"},
  {value: "Greenland", label: "Greenland"},
  {value: "Grenada", label: "Grenada"},
  {value: "Guam", label: "Guam"},
  {value: "Guatemala", label: "Guatemala"},
  {value: "Guernsey", label: "Guernsey"},
  {value: "Guinea", label: "Guinea"},
  {value: "Guinea Bissau", label: "Guinea Bissau"},
  {value: "Guyana", label: "Guyana"},
  {value: "Haiti", label: "Haiti"},
  {value: "Honduras", label: "Honduras"},
  {value: "Hong Kong", label: "Hong Kong"},
  {value: "Hungary", label: "Hungary"},
  {value: "Iceland", label: "Iceland"},
  {value: "India", label: "India"},
  {value: "Indonesia", label: "Indonesia"},
  {value: "Iran", label: "Iran"},
  {value: "Iraq", label: "Iraq"},
  {value: "Ireland", label: "Ireland"},
  {value: "Isle of Man", label: "Isle of Man"},
  {value: "Israel", label: "Israel"},
  {value: "Italy", label: "Italy"},
  {value: "Jamaica", label: "Jamaica"},
  {value: "Japan", label: "Japan"},
  {value: "Jersey", label: "Jersey"},
  {value: "Jordan", label: "Jordan"},
  {value: "Kazakhstan", label: "Kazakhstan"},
  {value: "Kenya", label: "Kenya"},
  {value: "Kuwait", label: "Kuwait"},
  {value: "Kyrgyz Republic", label: "Kyrgyz Republic"},
  {value: "Laos", label: "Laos"},
  {value: "Latvia", label: "Latvia"},
  {value: "Lebanon", label: "Lebanon"},
  {value: "Lesotho", label: "Lesotho"},
  {value: "Liberia", label: "Liberia"},
  {value: "Libya", label: "Libya"},
  {value: "Liechtenstein", label: "Liechtenstein"},
  {value: "Lithuania", label: "Lithuania"},
  {value: "Luxembourg", label: "Luxembourg"},
  {value: "Macau", label: "Macau"},
  {value: "Macedonia", label: "Macedonia"},
  {value: "Madagascar", label: "Madagascar"},
  {value: "Malawi", label: "Malawi"},
  {value: "Malaysia", label: "Malaysia"},
  {value: "Maldives", label: "Maldives"},
  {value: "Mali", label: "Mali"},
  {value: "Malta", label: "Malta"},
  {value: "Mauritania", label: "Mauritania"},
  {value: "Mauritius", label: "Mauritius"},
  {value: "Mexico", label: "Mexico"},
  {value: "Moldova", label: "Moldova"},
  {value: "Monaco", label: "Monaco"},
  {value: "Mongolia", label: "Mongolia"},
  {value: "Montenegro", label: "Montenegro"},
  {value: "Montserrat", label: "Montserrat"},
  {value: "Morocco", label: "Morocco"},
  {value: "Mozambique", label: "Mozambique"},
  {value: "Namibia", label: "Namibia"},
  {value: "Nepal", label: "Nepal"},
  {value: "Netherlands", label: "Netherlands"},
  {value: "Netherlands Antilles", label: "Netherlands Antilles"},
  {value: "New Caledonia", label: "New Caledonia"},
  {value: "New Zealand", label: "New Zealand"},
  {value: "Nicaragua", label: "Nicaragua"},
  {value: "Niger", label: "Niger"},
  {value: "Nigeria", label: "Nigeria"},
  {value: "Norway", label: "Norway"},
  {value: "Oman", label: "Oman"},
  {value: "Pakistan", label: "Pakistan"},
  {value: "Palestine", label: "Palestine"},
  {value: "Panama", label: "Panama"},
  {value: "Papua New Guinea", label: "Papua New Guinea"},
  {value: "Paraguay", label: "Paraguay"},
  {value: "Peru", label: "Peru"},
  {value: "Philippines", label: "Philippines"},
  {value: "Poland", label: "Poland"},
  {value: "Portugal", label: "Portugal"},
  {value: "Puerto Rico", label: "Puerto Rico"},
  {value: "Qatar", label: "Qatar"},
  {value: "Reunion", label: "Reunion"},
  {value: "Romania", label: "Romania"},
  {value: "Russia", label: "Russia"},
  {value: "Rwanda", label: "Rwanda"},
  {value: "Saint Pierre &amp; Miquelon", label: "Saint Pierre &amp; Miquelon"},
  {value: "Samoa", label: "Samoa"},
  {value: "San Marino", label: "San Marino"},
  {value: "Satellite", label: "Satellite"},
  {value: "Saudi Arabia", label: "Saudi Arabia"},
  {value: "Senegal", label: "Senegal"},
  {value: "Serbia", label: "Serbia"},
  {value: "Seychelles", label: "Seychelles"},
  {value: "Sierra Leone", label: "Sierra Leone"},
  {value: "Singapore", label: "Singapore"},
  {value: "Slovakia", label: "Slovakia"},
  {value: "Slovenia", label: "Slovenia"},
  {value: "South Africa", label: "South Africa"},
  {value: "South Korea", label: "South Korea"},
  {value: "Spain", label: "Spain"},
  {value: "Sri Lanka", label: "Sri Lanka"},
  {value: "St Kitts &amp; Nevis", label: "St Kitts &amp; Nevis"},
  {value: "St Lucia", label: "St Lucia"},
  {value: "St Vincent", label: "St Vincent"},
  {value: "St. Lucia", label: "St. Lucia"},
  {value: "Sudan", label: "Sudan"},
  {value: "Suriname", label: "Suriname"},
  {value: "Swaziland", label: "Swaziland"},
  {value: "Sweden", label: "Sweden"},
  {value: "Switzerland", label: "Switzerland"},
  {value: "Syria", label: "Syria"},
  {value: "Taiwan", label: "Taiwan"},
  {value: "Tajikistan", label: "Tajikistan"},
  {value: "Tanzania", label: "Tanzania"},
  {value: "Thailand", label: "Thailand"},
  {value: "Timor L'Este", label: "Timor L'Este"},
  {value: "Togo", label: "Togo"},
  {value: "Tonga", label: "Tonga"},
  {value: "Trinidad &amp; Tobago", label: "Trinidad &amp; Tobago"},
  {value: "Tunisia", label: "Tunisia"},
  {value: "Turkey", label: "Turkey"},
  {value: "Turkmenistan", label: "Turkmenistan"},
  {value: "Turks &amp; Caicos", label: "Turks &amp; Caicos"},
  {value: "Uganda", label: "Uganda"},
  {value: "Ukraine", label: "Ukraine"},
  {value: "United Arab Emirates", label: "United Arab Emirates"},
  {value: "United Kingdom", label: "United Kingdom"},
  {value: "Uruguay", label: "Uruguay"},
  {value: "Uzbekistan", label: "Uzbekistan"},
  {value: "Venezuela", label: "Venezuela"},
  {value: "Vietnam", label: "Vietnam"},
  {value: "Virgin Islands (US)", label: "Virgin Islands (US)"},
  {value: "Yemen", label: "Yemen"},
  {value: "Zambia", label: "Zambia"},
  {value: "Zimbabwe", label: "Zimbabwe"}
];

export const DISPLAY_IMG = "display_image";
