import React from "react";
import {basePreSaveFormat} from "../Base/utils";


export const load = async (values) => {
  // Console.info('Loading...')
  // console.log(values)
  // let result = values;
  //
  // // add stuff here
  //
  // console.log('result', result)
  // return result
};

export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  let result = values;

  delete result.tags;
  delete result.org;
  delete result.stockroom_assets;

  if (result.type && result.type.id) {
    result.type = result.type.id;
  }
  if (result.template && result.template.id) {
    result.template = result.template.id;
  }
  return result;
};


export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  if (values.template_data) {
    result.template_data = values.template_data.map(e => ({id: e.id, value: e.value}));
  }

  return result;
};
