import React, {Component} from "react";
import ScheduledPMOrderDetails from "../../components/PMOrders/Details/ScheduledPMOrder/ScheduledPMOrderDetails";
import {connect} from "react-redux";
import {
  authSelectors,
  orgOperations,
  orgSelectors,
  personnelOperations,
  personnelSelectors,
  scheduledPMOrdersActions,
  scheduledPMOrdersOperations,
} from "../../store";


/* This presents the details for a particular order. The imported ID is acquired via the URL. */
class ScheduledPMOrderDetailsContainer extends Component {
  render() {
    return (
      <ScheduledPMOrderDetails {...this.props} />
    );
  }
}

const mapStateToProps = state => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  personnelList: personnelSelectors.refreshNameList(state.personnel),
  scheduleStatusList: orgSelectors.refreshScheduleStatuses(state.orgs)
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  fetchDetails: (id) => dispatch(scheduledPMOrdersActions.getItem(id, "?expand=location,pm_order")),
  updateScheduledOrder: (id, data) => dispatch(scheduledPMOrdersOperations.updateItem(id, data)),
  getPersonnelList: () => dispatch(personnelOperations.getAllItems()),
  getOrg: (id) => dispatch(orgOperations.getItem(id)),
  getScheduleStatuses: (id) => dispatch(orgOperations.getScheduleStatuses(id)),
  createScheduleStatus: (org_id, name, color) => dispatch(orgOperations.createScheduleStatus(org_id, name, color)),
  updateScheduleStatus: (org_id, order_status_id, name, color) => dispatch(orgOperations.updateScheduleStatus(org_id, order_status_id, name, color)),
  deleteScheduleStatus: (org_id, order_status_id) => dispatch(orgOperations.deleteScheduleStatus(org_id, order_status_id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledPMOrderDetailsContainer);
