import React, {Component} from "react";
import {LayersControl, Map, Marker, Popup, TileLayer} from "react-leaflet";
import {Box} from "../../Base";
import {djangoLocationToLeaflet, findLocationByCoordinates} from "../../../../utils/location";
import ReactLeafletSearch from "react-leaflet-search";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nearestLocation: "",
      initialCenter: djangoLocationToLeaflet(this.props.location)
    };
    const location = djangoLocationToLeaflet(this.props.location);
    findLocationByCoordinates(location.lat, location.lng)
      .then(res => this.setState({nearestLocation: res.display_name}));
  }

  setLocation = (e, override) => {
    let marker = e.latlng;
    findLocationByCoordinates(marker.lat, marker.lng)
      .then(res => this.setState({nearestLocation: res.display_name}));
    this.props.setMarker(e, override);
  };

  onLocationFound = e => {
    this.setLocation(e, true);
  };

  onLocationError = e => {
    console.error("Couldn't set location");
    console.error(e);
  };

  render() {
    const {nearestLocation, initialCenter} = this.state;
    const location = djangoLocationToLeaflet(this.props.location);

    return (
      <Box width="100%" height="400px" mt="20px">
        <Map
          center={initialCenter}
          onClick={this.setLocation}
          zoom={5}
          style={{height: "100%"}}
          onLocationFound={this.onLocationFound}
          onLocationError={this.onLocationError}
        >
          {/* <LocateControl/>*/}
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <ReactLeafletSearch position="topleft" closeResultsOnClick showMarker={false} zoom={14}/>
          {location &&
          <Marker position={location}>
            <Popup>
              <span><b>Latitude:</b> {location.lat}<br/>
                <b>Longitude:</b> {location.lng}<br/>
                <b>Nearest Location:</b> {nearestLocation ? nearestLocation : "Unknown"}</span>
            </Popup>
          </Marker>
          }
        </Map>
      </Box>
    );
  }
}

export {MapComponent};
