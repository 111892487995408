import React from "react";
import PropTypes from "prop-types";
import {ErrorHeading, Loading, Container} from "../Base";

const DetailsContainer = ({notFound, type, loading, children}) => {
  if (loading) {
    return (
      <Container>
        <Loading title={`Loading ${type}...`}/>
      </Container>
    );
  }
  if (notFound) {
    return (
      <Container>
        <ErrorHeading>{`${type} doesn't exist`}</ErrorHeading>
      </Container>
    );
  }
  return (
    <Container>
      {children}
    </Container>
  );
};

DetailsContainer.propTypes = {
  type: PropTypes.string,
  notFound: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

DetailsContainer.defaultProps = {
  notFound: false,
  type: "Item",
};

export default DetailsContainer;

