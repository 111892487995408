import styled from "@emotion/styled";
import {Box} from "../../Common/Base";

export const Wrapper = styled(Box)`
      flex: 1 0 auto;
      background-color: white;
      box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
      position: relative;
`;

Wrapper.defaultProps = {
  p: "10px",
  m: "10px"
};

export const ColumnWrapper = styled(Box)`
  flex: 1 0 auto;
`;

ColumnWrapper.defaultProps = {
  pr: "10px"
};


export const CloseWrapper = styled(Box)`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
`;

// Export const Link
