// products/reducers.js
import {combineReducers} from "redux";
import {productPaginator, inventoryPaginator} from "./pagination";
import types from "./types";
import {createCrud} from "../../base/crud";
import {productEndpoint} from "./constants";
import {createBarcodeStore} from "../../base/barcode/";
import {facilitiesReducer} from "../common/reducers";

export const productCrud = createCrud({
  endpoint: productEndpoint,
  name: "products",
  withNotes: true
});

export const productBarcodes = createBarcodeStore({
  endpoint: productEndpoint,
  name: "products",
});

export default combineReducers({
  items: productPaginator.reducer(),
  inventory: inventoryPaginator.reducer(),
  details: productCrud.reducer(),
  barcode: productBarcodes.reducer(),
  facilities: facilitiesReducer(types.GET_FACILITIES_SUCCESS),
});
