import React from "react";
import PropTypes from "prop-types";
import {Template, Details} from "./";
import {Bottom, TagList, SideList, Wrapper, SeeMoreButton, AddTagsButton} from "../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../Common/Bars/BarComponents/constants";

/* An asset bar is a div containing information regarding an asset. A list of
asset bars are visible within the assets section. */

const AssetBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const baseClassName = listView ? listStyle : tileStyle;
  const leftList = [
    {title: "ASSET NAME", value: data.name || "None"},
    {title: "MANUFACTURER", value: data.manufacturer_name || "None"},
    {title: "TYPE", value: data.type?.name ?? "None"},
  ];

  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="assets"
      itemData={data}
      detailsPane={<Details baseClassName={baseClassName} assetData={data}/>}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} rightButton={AddTagsButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
      {listView &&
            <Template
              data={data}
              listView={listView}
              baseClassName={baseClassName}
            />
      }
      {data.tags &&
            <TagList
              baseClassName={baseClassName}
              listName="TAGS"
              listArray={data.tags}
            />
      }
    </Wrapper>
  );
};

AssetBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array
};

export {AssetBar};
