import React from "react";
import {Link} from "Base";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY} from "./constants";
import {MATERIAL_DASHBOARD_ROUTE} from "../../../routes/constants";

/**
 * Returns base url string for materials or product
 * @param {string} type - material || product
 * @returns {string} - base url for inventory type
 */
const getInventoryUrl = (type = PRODUCT_INVENTORY) => {
  if (type === PRODUCT_INVENTORY) {
    return "/dashboard/warehouse/inventory/";
  }
  if (type === MATERIAL_INVENTORY) {
    return `${MATERIAL_DASHBOARD_ROUTE}/`;
  }
  return "";
};

/**
 * Returns the name or sku of a product or material
 * @param {object} item - material or product
 * @returns {string} - either sku or name or ""
 */
export const renderNameOrSku = item => {
  if (item) {
    if (item.name) {
      return item.name;
    }
    if (item.sku) {
      return item.sku;
    }
  }
  return "New";
};

const makeInventoryTitle = location => {
  let result = "All Locations";
  try {
    if (location) {
      if (location.level && location.level !== "None") {
        const level = location.level;
        const formattedLevel = level[0].toUpperCase() + level.slice(1);
        result = `${formattedLevel} - ${location[location.level].name}`;
      }
    }
  } catch (e) {
    console.log("error making title", e);
  }
  return result;
};

/**
 * Takes an object of id inventory pairs and returns them in an array
 * @param {object} selectedInventory - object of id-inventory pairs
 * @returns {Array} - array of inventory
 */
const getInventorySelectedList = selectedInventory => {
  /*
    Builds list from selectedInventory object
   */

  let result = [];
  let keys = Object.keys(selectedInventory);

  if (keys.length) {
    for (let i = 0; i < keys.length; i++) {
      result.push(selectedInventory[keys[i]]);
    }
  }
  return result;
};

/**
 * Takes an object of id inventory pairs and returns them in an array
 * formatted for Pallets
 * @param {object} data - object of id-inventory pairs
 * @returns {Array} - array of inventory
 */
const formatInventoryForSubmit = data => {
  const list = getInventorySelectedList(data);
  return list.map(item => ({inventory: item.id, quantity: item.add_quantity}));
};

/**
 * Takes an object of id inventory pairs and returns them in an array formatted
 * for submitting to a Sales Order
 * @param {object} data - object of id-inventory pairs
 * @param {string} type - 'material' or 'product'
 * @returns {Array} - array of formatted inventory
 */
const formatInventoryForSalesOrder = (data, type = "product") => {
  const list = getInventorySelectedList(data);
  return list.map(item => ({[type]: item[type].id, quantity: item.add_quantity, inventory: item.id}));
};

/**
 * Takes an object of id inventory pairs and returns them in an array formatted
 * for submitting to a Sales Order
 * @param {object} data - object of id-inventory pairs
 * @returns {Array} - array of formatted inventory
 */
const formatMaterialsForSalesOrder = data => {
  const list = getInventorySelectedList(data);
  console.log("list", list);
  return list.map(item => ({material: item.material.id, quantity: item.add_quantity, inventory: item.id}));
};

/**
 * Takes an object of id inventory pairs and returns them in an array
 * formatted for Pallets
 * @param {{materials: {}, products: {}}} data - object of id-inventory pairs
 * @returns {{materials: [], products: []}} - arrays of products and materials
 */
const formatInventoryForOrderPallet = data => {
  const {materials, products} = data;
  const materialList = getInventorySelectedList(materials);
  const productList = getInventorySelectedList(products);

  let materialResult = [];
  for (const material of materialList) {
    if (material.quantity > 0) {
      materialResult.push({material: material.id, quantity: material.quantity});
    }
  }
  let productResult = [];
  for (const product of productList) {
    if (product.quantity > 0) {
      productResult.push({product: product.id, quantity: product.quantity});
    }
  }
  return {materials: materialResult, products: productResult};
};

/**
 * Takes an object of id inventory pairs and returns them in an array
 * formatted for Pallets
 * @param {{materials: {}, products: {}}} data - object of id-inventory pairs
 * @returns {{materials: [], products: []}} - arrays of products and materials
 */
const formatInventoryForSalesOrderPallet = data => {
  const {materials, products} = data;
  console.log("formatting", data);
  const materialList = getInventorySelectedList(materials);
  const productList = getInventorySelectedList(products);

  let materialResult = [];
  for (const material of materialList) {
    if (material.quantity > 0) {
      const {id, ...rest} = material;
      materialResult.push({material: id, ...rest});
    }
  }
  let productResult = [];
  for (const product of productList) {
    if (product.quantity > 0) {
      const {id, ...rest} = product;

      productResult.push({
        product: id,
        ...rest,
      });
    }
  }
  return {materials: materialResult, products: productResult};
};

/**
 * Takes an object of id inventory pairs and returns them in an array
 * formatted for recieve
 * @param {{materials: {}, products: {}}} data - object of id-inventory pairs
 * @returns {{materials: [], products: []}} - arrays of products and materials
 */
const formatInventoryForReceivePallet = data => {
  const {materials, products} = data;

  console.log("data", data);
  const materialList = getInventorySelectedList(materials);

  const productList = getInventorySelectedList(products);
  let materialResult = [];
  for (let i in materialList) {
    if (materialList[i].quantity > 0) {
      const {id, ...rest} = materialList[i];
      materialResult.push({
        material: id,
        ...rest,
      });
    }
  }
  let productResult = [];
  for (let i in productList) {
    if (productList[i].quantity > 0) {
      const {id, ...rest} = productList[i];

      productResult.push({
        product: id,
        ...rest,
      });
    }
  }
  return {materials: materialResult, products: productResult};
};

/**
 * Renders a link to either a product or material
 * @param {{}} item - Product or Material instance
 * @param {string} type - PRODUCT_INVENTORY or MATERIAL_INVENTORY
 * @returns {*} - Link to item with name or sku
 */
const renderInventoryTitle = (item, type = PRODUCT_INVENTORY) => {
  const baseUrl = getInventoryUrl(type);

  const url = `${baseUrl}${item?.id ?? ""}`;
  const name = renderNameOrSku(item);
  return (
    <Link to={url}>{name}</Link>
  );
};

/**
 * Renders a link to either a product or material nested in item
 * @param {{}} item - Item with a Product or Material instance
 * @param {string} type - PRODUCT_INVENTORY or MATERIAL_INVENTORY
 * @returns {*} - Link to item with name or sku
 */
const renderLocationInventoryTitle = (item, type = PRODUCT_INVENTORY) => {
  const baseUrl = getInventoryUrl(type);
  // console.log('renderLocationInventoryTitle item', item)

  // console.log('renderLocationInventoryTitle type', type)
  const url = `${baseUrl}${item[type].id}`;
  const name = renderNameOrSku(item[type]);
  return (
    <Link to={url}>{name}</Link>
  );
};

export {
  formatInventoryForSubmit,
  getInventorySelectedList,
  makeInventoryTitle,
  formatInventoryForSalesOrder,
  formatMaterialsForSalesOrder,
  formatInventoryForOrderPallet,
  renderInventoryTitle,
  renderLocationInventoryTitle,
  formatInventoryForReceivePallet,
  formatInventoryForSalesOrderPallet,
};
