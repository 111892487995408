import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {Flex, Box} from "@chakra-ui/core";
import RepeatYearly from "./Yearly";
import RepeatMonthly from "./Monthly";
import RepeatWeekly from "./Weekly";
import RepeatDaily from "./Daily";
import RepeatHourly from "./Hourly";
import translateLabel from "../../utils/translateLabel";

import {
  RepeatWrapper,
  Label,
  Row,
  RowLabelWrapper,
  RowOptions,
  InputWrapper
} from "../../styles/styled";


const Repeat = ({
  id,
  repeat: {
    frequency,
    yearly,
    monthly,
    weekly,
    daily,
    hourly,
    options
  },
  handleChange,
  translations,
  hideHourly
}) => {
  const isOptionAvailable = option => !options.frequency || options.frequency.indexOf(option) !== -1;
  const isOptionSelected = option => frequency === option;

  const repeatFrequencyOptions = [
    {value: "Yearly", label: translateLabel(translations, "repeat.yearly.label")},
    {value: "Monthly", label: translateLabel(translations, "repeat.monthly.label")},
    {value: "Weekly", label: translateLabel(translations, "repeat.weekly.label")},
    {value: "Daily", label: translateLabel(translations, "repeat.daily.label")},

  ];

  if (!hideHourly) {
    repeatFrequencyOptions.push(
      {value: "Hourly", label: translateLabel(translations, "repeat.hourly.label")
      }
    );
  }

  const handleRepeatSelect = (value) => {
    const target = {
      name: "repeat.frequency",
      value: value.value
    };
    handleChange({target});
  };

  const defaultValue = repeatFrequencyOptions.find(item => item.value === frequency);

  return (
    <RepeatWrapper>
      <Row alignItems={"top"}>
        <Box pt={3} pr={1}>
          <Label
            htmlFor={`${id}-frequency`}
            className="col-form-label"
          >
            <strong>
              {translateLabel(translations, "repeat.label")}
            </strong>
          </Label>
        </Box>
        <RowOptions>
          <Flex flexDirection={"column"} flexWrap={"wrap"}>
            <InputWrapper width={"100%"}>
              <Select
                name="repeat.frequency"
                id={`${id}-frequency`}
                className="form-control repeat-frequency-select"
                defaultValue={defaultValue}
                onChange={handleRepeatSelect}
                options={repeatFrequencyOptions.filter(option => isOptionAvailable(option.value))}
              />
            </InputWrapper>

            <Row>
              {
                isOptionSelected("Yearly") &&
                <RepeatYearly
                  id={`${id}-yearly`}
                  yearly={yearly}
                  handleChange={handleChange}
                  translations={translations}
                />
              }
              {
                isOptionSelected("Monthly") &&
                <RepeatMonthly
                  id={`${id}-monthly`}
                  monthly={monthly}
                  handleChange={handleChange}
                  translations={translations}
                />
              }
              {
                isOptionSelected("Weekly") &&
                <RepeatWeekly
                  id={`${id}-weekly`}
                  weekly={weekly}
                  handleChange={handleChange}
                  translations={translations}
                />
              }
              {
                isOptionSelected("Daily") &&
                <RepeatDaily
                  id={`${id}-daily`}
                  daily={daily}
                  handleChange={handleChange}
                  translations={translations}
                />
              }
              {
                !hideHourly && isOptionSelected("Hourly") &&
                <RepeatHourly
                  id={`${id}-hourly`}
                  hourly={hourly}
                  handleChange={handleChange}
                  translations={translations}
                />
              }
            </Row>
          </Flex>

        </RowOptions>
      </Row>

      <Row width={"500px"}>
        <RowLabelWrapper/>

      </Row>
    </RepeatWrapper>
  );
};

Repeat.propTypes = {
  id: PropTypes.string.isRequired,
  repeat: PropTypes.shape({
    frequency: PropTypes.oneOf(["Yearly", "Monthly", "Weekly", "Daily", "Hourly"]).isRequired,
    yearly: PropTypes.object.isRequired,
    monthly: PropTypes.object.isRequired,
    weekly: PropTypes.object.isRequired,
    daily: PropTypes.object.isRequired,
    hourly: PropTypes.object.isRequired,
    options: PropTypes.shape({
      frequency: PropTypes.arrayOf(PropTypes.oneOf(["Yearly", "Monthly", "Weekly", "Daily", "Hourly"])),
      yearly: PropTypes.oneOf(["on", "on the"]),
      monthly: PropTypes.oneOf(["on", "on the"])
    }).isRequired
  }).isRequired,
  hideHourly: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};


Repeat.defaultProps = {
  hideHourly: false
};
export default Repeat;
