import React from "react";
import PropTypes from "prop-types";
// import CompanyLogo from "../../static/img/conmitto_logo_color.png";
import ServiceProviderInfo from "../ServiceProviderInfoContainer/ServiceProviderInfo";
import {AccountInfo, Notifications, Security} from "./Tabs";
import {Divider, Content, Tabs, TabPane} from "../../components/Common/Base";
// import {getTabList} from "./utils";
import {useMediaQuery} from "react-responsive";

const Settings = props => {
  const {account, accountSettings, changePassword, updateNotificationSettings} = props;
  const isTabletOrMobile = useMediaQuery({query: "(max-width: 600px)"});

  const showServProvInfo = () => {
    if (account && account.personnel.default_org.type === "Service Provider") {
      return (
        <TabPane
          tab={
            <span className="business-tab">
              Business
            </span>
          }
          key="4"
        >
          <ServiceProviderInfo {...props}/>
          <Divider borderColor="grey"/>
        </TabPane>
      );
    }
  };


  // const extraTabs = getTabList(account);

  const tabPosition = isTabletOrMobile ? "top" : "left";
  return (
    <section className="settings">
      <Content style={{background: "#fff"}}>
        <Tabs tabPosition={tabPosition}>
          <TabPane
            tab={
              <span className="account-tab">
                Account
              </span>
            }
            key="1"
          >
            <AccountInfo account={account}/>
          </TabPane>
          {showServProvInfo()}

          <TabPane
            tab={
              <span>
                Password
              </span>
            }
            key="2"
          >
            <Security changePassword={changePassword}/>
          </TabPane>
          <TabPane
            tab={
              <span className="notifications-tab">
                Notifications
              </span>
            }
            key="3"
          >
            <Notifications
              updateSettings={updateNotificationSettings}
              account={account}
              settings={accountSettings}
            />
          </TabPane>


          {/*    /!* {extraTabs.map(tab =>*!/*/}
          {/*    /!* <TabPanel key={tab}>*!/*/}
          {/*    /!*   {tab}*!/*/}
          {/*    /!* </TabPanel>,*!/*/}
          {/*    /!* )}*!/*/}

        </Tabs>
      </Content>
    </section>
  );
};

Settings.propTypes = {
  account: PropTypes.any,
};

export default Settings;
