import React from "react";
// Import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import {Button, Box} from "../index";
import {space, background, color} from "styled-system";


const BaseButtonComponent = (props) => <Button {...props}/>;

BaseButtonComponent.defaultProps = {
  // bg: "colorPrimary",
  // color: "white",
  // width: "240px",
  // p: "10px 30px",
  // mt: "10px",
  // fontSize: "14px",
  // fontWeight: "bold",
  type: "primary",
};


const BaseButton = styled(BaseButtonComponent)`
  background-image: ${props => props.theme.colors.gradients[props.gradient]};
  ${background}
  ${space}
  ${color}

`;

const SecondaryButton = (props) => <Button {...props}/>;

SecondaryButton.defaultProps = {
  // bg: "primaryGradient",
  // color: "white",
  // width: ["120px", "180px"],
  // p: "10px 15px 10px 20px",
  // fontSize: ["14px"],
  // m: "10px"
  type: "primary",
};


const InlineButton = ({props, children}) => (
  <Box display={"inline-block"}>
    <Button type={"primary"} {...props}>{children}</Button>
  </Box>
);


export {SecondaryButton, InlineButton};

export default BaseButton;
