import React, {Component, Fragment} from "react";
import moment from "moment";
import {getAzureBlobURL} from "../../../utils/azure";
import {deleteAxios, patchAxios} from "../../../utils/authWithAxios";
import {Box, DirectLink, Flex, Heading} from "../Base";
import {roleList} from "../Forms/PersonnelForm/constants";
import {Span} from "../Base/styled";
import {SubmitButton} from "../Forms/styled";

/* This component will display a single note for an object */
class Note extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteContent: props.note.content,
      noteChanged: false,
      editNoteErrorText: ""
    };
  }

  handleNoteFieldChanged = (e) => {
    if (!this.state.noteChanged) {
      this.setState({
        noteContent: e.target.value,
        noteChanged: true
      });
    } else {
      this.setState({noteContent: e.target.value});
    }
  };


  /* Perform submit for the edit note text. */
  handleSubmitEditNoteText = () => {
    const {objectEndpoint, objectID, note} = this.props;
    this.setState({
      editNoteErrorText: ""
    });
    patchAxios({
      url: `${global.base_url}v1/${objectEndpoint}/${objectID}/notes/${note.id}/`,
      data: {
        content: this.state.noteContent
      }
    })
      .then(res => {
        console.log(res);
        this.setState({noteChanged: false});
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          editNoteErrorText: "An error occurred when updating this note."
        });
      });
  };

  handleDeleteNote = () => {
    // Map props to var's
    const {objectEndpoint, objectID, note, refreshObject} = this.props;
    // Send delete request to server using note instance endpoint
    deleteAxios({
      url: `${global.base_url}v1/${objectEndpoint}/${objectID}/notes/${note.id}/`
    })
      // Wait for transaction then re-render component
      .then(res => refreshObject());
  };

  render() {
    const {
      note,
      displayStatus,
      files
    } = this.props;
    const {
      noteContent,
      noteChanged,
      editNoteErrorText
    } = this.state;
    const created_at = moment(note.created_at).format("MMMM Do YYYY, h:mm:ss a");
    const noteFiles = files.filter(file => file.noteID === note.id.toString());
    const noteHasBeenEdited = moment(note.updated_at).isSameOrAfter(moment(note.created_at).add("3", "seconds"));
    return (
      <div className="item-details-pane-right-note">
        <Flex wrap="wrap">
          <Span fontWeight={"bold"}>{note.created_by.name}</Span>
          <Span>&nbsp;- {roleList[note?.created_by?.user?.user_type ?? 1 - 1].label}</Span>
          <Span flexGrow={1} textAlign={"right"}>
            <Span>{created_at}</Span>
            {note.updated_at && noteHasBeenEdited &&
            <Span
              ml={"20px"}
              color="#bbb"
              title={moment(note.updated_at).format("MMMM Do YYYY, h:mm:ss a")}>
              (edited)
            </Span>
            }
            {displayStatus &&
            <Span margin={"0 0 0 20px"} className={"note-status"}>
              <strong>Status: </strong>{note?.status?.name ?? "Unknown"}
            </Span>
            }
          </Span>
        </Flex>
        <hr/>
        {note.permissions["write"] ?
          <textarea
            className="item-details-pane-right-notes editable-note"
            value={noteContent}
            onChange={this.handleNoteFieldChanged}
          /> :
          <Box>{noteContent}</Box>
        }
        {noteChanged &&
        <SubmitButton className="item-details-form-button" onClick={this.handleSubmitEditNoteText}>
          Save Note
        </SubmitButton>
        }
        <SubmitButton className="item-details-form-button" onClick={this.handleDeleteNote}>
          Delete Note
        </SubmitButton>

        {editNoteErrorText && <Heading as="h5" color={"red.500"} mt={"10px"} className={"note-error"}>{editNoteErrorText}</Heading>}
        {noteFiles.length !== 0 &&
        <Fragment>
          <h3>Attached Files</h3>
          {noteFiles.map((file, index) => {
            return (
              <DirectLink key={index} href={getAzureBlobURL(file.name)} color="#1e6cb6" display="block">
                {file.name.split("/")[2]}
              </DirectLink>
            );
          })}
        </Fragment>
        }
      </div>
    );
  }
}

export default Note;
