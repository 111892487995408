import base from "../actions";
import {buildPageString} from "./utils";

const createPaginationActions = (types, endpoint) => {
  const {
    SET_ITEMS_CURRENT_PAGE,
    GET_PAGE_REQUEST,
    GET_PAGE_SUCCESS,
    GET_PAGE_FAILURE,
    CHANGE_VIEW,
  } = types;

  const setItemsCurrentPage = (page, params = "default") => ({
    payload: {page, params},
    type: SET_ITEMS_CURRENT_PAGE,
  });

  const changeView = (view) => ({
    payload: view,
    type: CHANGE_VIEW,
  });

  // /**
  //  * Returns a page of items filtered by params
  //  * @param {number} page - page number to fetch
  //  * @param {object} params - key's and values to be converted to url params
  //  * @returns {object} - [RSAA] redux action response
  //  */
  const fetchPageAction = ({page, params}) => {
    const pageSuffix = buildPageString(page, params);
    return base.getRsaa({
      endpoint: `${global.base_url}${endpoint}${pageSuffix}`,
      types: [
        GET_PAGE_REQUEST,
        {
          type: GET_PAGE_SUCCESS,
          meta: {params, page},
        },
        GET_PAGE_FAILURE,
      ],
    });
  };

  const fetchPage = ({page = 1, params = {}}) => async dispatch => {
    // each param must have shape: {key: string}

    dispatch(setItemsCurrentPage(page, params));

    const res = await dispatch(fetchPageAction({page, params}));

    return res;
  };

  return {setItemsCurrentPage, fetchPage, changeView};
};


export default createPaginationActions;
