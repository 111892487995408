export default {
  locale: "de",
  invalid_rrule: "Sie haben eine ungültige RRule an die Komponente übergeben. '%{value}' ist keine gültige RRule.",
  months: {
    jan: "Jan",
    feb: "Feb",
    mar: "Mär",
    apr: "Apr",
    may: "Mai",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Okt",
    nov: "Nov",
    dec: "Dez",
  },
  days_short: {
    mon: "Mo",
    tue: "Di",
    wed: "Mi",
    thu: "Do",
    fri: "Fr",
    sat: "Sa",
    sun: "So",
  },
  days: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    day: "Tag",
    weekday: "Wochentag",
    "weekend day": "Wochenende",
  },
  numerals: {
    first: "Ersten",
    second: "Zweiten",
    third: "Dritten",
    fourth: "Vierten",
    last: "Letzten",
  },
  start: {
    label: "Start",
    tooltip: "Startdatum des Zeitplans",
  },
  repeat: {
    label: "Wiederholen",
    yearly: {
      label: "Jährlich",
      on: "am",
      on_the: "an dem",
      of: "von",
    },
    monthly: {
      label: "Monatlich",
      every: "Jeden",
      months: "Monat(e)",
      on_day: "am Tag",
      on_the: "am",
    },
    weekly: {
      label: "Wöchentlich",
      every: "Jede",
      weeks: "Woche(n)",
    },
    daily: {
      label: "Täglich",
      every: "Jeden",
      days: "Tag(e)",
    },
    hourly: {
      label: "Stündlich",
      every: "Jede",
      hours: "Stunde(n)",
    },
  },
  end: {
    label: "Ende",
    tooltip: "Ende des Zeitplans",
    never: "Niemals",
    after: "Nach",
    on_date: "Am",
    executions: "Ausführungen.",
  },
};
