import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import {Text} from "../../../Common/Base";
import SelectedInventoryListItem from "./SelectedInventoryListItem";
import {inventoryType} from "../../utils/propTypes";
/*
This component will render a Product inventory list
Used in PalletInventoryModal for inventory in the process of being selected/added to pallet
 */


const SelectedInventoryList = ({data, onEdit, onRemove, type, title}) => {
  const count = data.length;

  return (
    <List
      dataSource={data}
      header={<Text color={"black"}>{` ${count} ${title}s Selected`}</Text>}
      renderItem={item =>
        <SelectedInventoryListItem
          key={item.id}
          item={item}
          onEdit={onEdit}
          onRemove={onRemove}
          onSelect={(values) => console.log("selected", values)}
          type={type}

        />
      }
    />
  );
};

SelectedInventoryList.propTypes = {
  data: PropTypes.array,
  type: inventoryType,
  title: PropTypes.string
};

export default SelectedInventoryList;
