import React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";

import {DashboardContainer, LoginContainer, RequestPasswordResetContainer, ConfirmPasswordResetContainer, PasswordContainer} from "../containers";

/* This file will handle all routing for the single-page app.
By default, and when an error occurs, the Login page will be loaded.
To add a path, consider using the following syntax:
<Route exact(OPTIONAL) path={'/URLNAME'} component={COMPONENTNAME} /> */
const Routing = ({location}) => (
  <HashRouter>
    <Switch location={location}>
      <Route
        path="/dashboard"
        name="Dashboard"
        component={DashboardContainer}
      />
      <Route exact path="/reset" component={RequestPasswordResetContainer}/>
      <Route path="/reset/:uid/:token/" component={ConfirmPasswordResetContainer}/>

      <Route path="/password" name="Password" component={PasswordContainer}/>
      <Route path="/" name="Login" component={LoginContainer}/>
    </Switch>
  </HashRouter>
);

export default Routing;
