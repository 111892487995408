import React from "react";
import PropTypes from "prop-types";
import AssetList from "./AssetList";
import AssetForm from "./AssetForm";
import {LinkedFieldSection} from "../../../../Common/Details";

const AssetSection = props => {
  const {data, assetList, onSubmit} = props;


  return (
    <LinkedFieldSection
      Form={AssetForm}
      data={data}
      onSubmit={onSubmit}
      List={AssetList}
      name={"assets"}
      formField={"asset"}
      title={"Asset"}
      formProps={{assetList}}
    />
  );
};

AssetSection.propTypes = {
  data: PropTypes.object,
  assetList: PropTypes.array,
  onSubmit: PropTypes.func
};

export default AssetSection;
