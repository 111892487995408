import types from "./types";
import moment from "moment";

const initialState = {
  item: [],
  items: [],
  messages: null,
  errors: {},
  summary: {pm_orders: {}, assets: {}, vendor_orders: {}, personnel: {}},
  loading: true,
  loadingSummary: true,
  summaryTimescale: "year", // Default summaryTimescale Can be week, month, quarter, year
  summaryTimescaleValue: moment().year().toString(), // Default to this current year
  summaryYear: moment().year().toString(), // the current year
  logs: [],
};

/**
 * A reducer that sets the loading and errors keys
 * @param {object} state The current redux state
 * @param {object} action The action that was performed
 * @returns {object} The state with loading set to false and errors set to action.payload
 */
export const errorReducer = (state, action) => ({
  ...state,
  loading: false,
  errors: action.payload
});


/**
 * A reducer that sets the loading and errors keys
 * @param {object} state The current redux state
 * @param {object} action The action that was performed
 * @param {string} payloadKey The key to set to action.payload
 * @returns {object} The state with loading set to false and payloadKey set to action.payload
 */
const successReducerColorType = (state, action, payloadKey) => ({
  ...state,
  loading: false,
  [payloadKey]: action.payload
});

/* Main reducer for this duck. import into ../reducers.js and add to root reducer. */
// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
  /* Sets items to payload returned from getAllItems in operations. */
  case types.GET_ITEM_SUCCESS:
    return {
      ...state,
      loading: false,
      item: action.payload,
    };

  case types.GET_ALL_SUCCESS:
    return {
      ...state,
      loading: false,
      items: action.payload,
    };

  case types.GET_LOGS_SUCCESS: {
    return {
      ...state,
      logs: "results" in action.payload ? action.payload.results : action.payload
    };
  }

  case types.RECEIVED_LOG:
    return {
      ...state,
      logs: [action.payload, ...state.logs]
    };

  case types.GET_LOCATIONS_SUCCESS:
    return successReducerColorType(state, action, "locations");

  case types.GET_ASSET_TYPES_SUCCESS:
    return successReducerColorType(state, action, "asset_types");

  case types.GET_STOCK_STATUSES_SUCCESS:
    return successReducerColorType(state, action, "stock_asset_statuses");

  case types.GET_PERSONNEL_TITLES_SUCCESS:
    return successReducerColorType(state, action, "personnel_titles");

  case types.GET_VENDOR_TYPES_SUCCESS:
    return successReducerColorType(state, action, "vendor_types");

  case types.GET_SCHEDULE_STATUSES_SUCCESS:
    return successReducerColorType(state, action, "schedule_statuses");

  case types.GET_ORG_SUMMARY_REQUEST:
    return {
      ...state,
      loadingSummary: true,
      summary: {pm_orders: {}, assets: {}, vendor_orders: {}, personnel: {}, materials: {}, inventory: {}}
    };

  case types.GET_ORG_SUMMARY_SUCCESS:
    return {
      ...state,
      loadingSummary: false,
      summary: action.payload
    };

  case types.GET_ORG_SUMMARY_FAILURE: {
    let response;
    if (action.payload.response && action.payload.response.code === "token_not_valid") {
      response = {
        errBool: true,
        message: "You must be approved by the administrator to log in"
      };
    }
    const {payload} = action;
    return {
      ...state,
      loadingSummary: false,
      errors: payload.response ? response : {detail: "Something went wrong"}
    };
  }

  case types.CHANGE_SUMMARY_TIMESCALE:
    const {timescale, value} = action.payload;
    if (timescale === "day") {
      // For the day it might happen that the user selects a date with different year
      // so we update the state of the summary year
      const year = moment(value).year();
      return {
        ...state,
        summaryTimescale: timescale,
        summaryTimescaleValue: value,
        summaryYear: year.toString()
      };
    } else if (timescale === "year") {
      return {
        ...state,
        summaryTimescale: timescale,
        summaryYear: value,
        summaryTimescaleValue: value,
      };
    } else {
      return {
        ...state,
        summaryTimescale: timescale,
        summaryTimescaleValue: value,
      };
    }

  case types.CHANGE_SUMMARY_YEAR:
    return {
      ...state,
      summaryYear: action.payload,
    };

  case types.UPDATE_SERVICE_PROVIDER_FAILURE: {
    return {
      ...state,
      errors: {detail: "Something went wrong"}
    };
  }

  case types.GET_ALL_FAILURE:
  case types.GET_ITEM_FAILURE:
  case types.GET_LOCATIONS_FAILURE:
  case types.GET_ASSET_TYPES_FAILURE:
  case types.GET_STOCK_STATUSES_FAILURE:
  case types.GET_PERSONNEL_TITLES_FAILURE:
  case types.GET_VENDOR_TYPES_FAILURE:
  case types.GET_SCHEDULE_STATUSES_FAILURE:
    return errorReducer(state, action);

  default:
    return state;
  }
};
