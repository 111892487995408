import {Text, AccordionItem} from "../../Base";
import styled from "@emotion/styled";
import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    textAlign: "left"
  },
  selectedListItem: {
    textAlign: "left",
    backgroundColor: theme.palette.background.default
  },
  subheader: {
    // fontSize: "1.4rem",
    backgroundColor: "white"
  }
}));


const PrimaryText = styled(Text)`

`;

PrimaryText.defaultProps = {
  fontSize: "md",
  fontWeight: "bold"
};

const SecondaryText = styled(Text)`
    span {
      color: black;
    }
`;

SecondaryText.defaultProps = {
  fontSize: "sm",
  fontWeight: "semibold",
  color: "meta"
};


const ShiftItem = styled(AccordionItem)`
border-bottom: 1px solid ${props => props.theme.colors.colorBorder};
overflow: hidden;
`;


ShiftItem.defaultProps = {
  bg: "white"
};

export {PrimaryText, SecondaryText, ShiftItem};
