import reducer from "./reducers";
import {templateOperations, templateMaterialActions, templatePageActions} from "./templates/templates";

import {pagination as linePagination, operations as lineOperations, pageActions as linePageActions, lineAssetActions} from "./lines";

import {pagination as entryPagination, operations as entryOperations, pageActions as entryPageActions} from "./lines/entries";

export {
  templatePageActions,
  templateOperations,
  templateMaterialActions,
  linePagination,
  lineOperations,
  linePageActions,
  lineAssetActions,
  entryPagination,
  entryOperations,
  entryPageActions
};

export default reducer;
