import types from "./types";
import base from "../../base/actions";

import {productCrud, productBarcodes} from "./index";
import {inventoryEndpoint, productEndpoint} from "./constants";
import {default as orgActions} from "../../orgs/actions";


const createItemQuantity = (data) => base.postRsaa({
  endpoint: `${global.base_url}${productEndpoint}add_quantity/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE],
});

const bulkDelete = (data) => base.patchRsaa({
  endpoint: `${global.base_url}${productEndpoint}bulk_delete/`,
  body: JSON.stringify(data),
  types: [
    types.BULK_DELETE_REQUEST,
    types.BULK_DELETE_SUCCESS,
    types.BULK_DELETE_FAILURE
  ]
});

const uploadCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${productEndpoint}csv/${org}/`,
  headers: {},
  body: data,
  types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE],
});

const uploadInventoryCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${inventoryEndpoint}csv/${org}/`,
  headers: {},
  body: data,
  types: [types.UPLOAD_INVENTORY_CSV_REQUEST, types.UPLOAD_INVENTORY_CSV_SUCCESS, types.UPLOAD_INVENTORY_CSV_FAILURE],
});

const getFacilities = (org) => orgActions.getFacilities({
  org,
  typesList: [types.GET_FACILITIES_REQUEST,
    {
      type: types.GET_FACILITIES_SUCCESS,
    },
    types.GET_FACILITIES_FAILURE]
});


const clearMessages = () => ({
  type: types.CLEAR_MESSAGES,
});

export default {
  ...productCrud.actions,
  ...productBarcodes.actions,
  createItemQuantity,

  uploadCsv,
  uploadInventoryCsv,
  getFacilities,

  clearMessages,
  bulkDelete
};
