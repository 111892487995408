// Import React from 'react';
// import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import {Input as BaseInput} from "../../../Base";
import {display} from "styled-system";

const Input = styled(BaseInput)`
      // ${display}
      //padding: 10px 10px 10px 10px;
      //margin-bottom: 0 !important;
      font-family: 'Open Sans', sans-serif;
      //font-size: 1.6em;
      border: none;
`;

Input.defaultProps = {
  color: "colorGreyDark2",
  bg: "colorWhite",
  width: "95%",
  fontSize: "0.9rem",
  p: "4px 8px",
  mb: "30px",
  height: "unset"
};

export default Input;
