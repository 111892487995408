import React, {Component} from "react";
import {connect} from "react-redux";
import VendorDetails from "../../components/VendorOrders/Details/VendorDetails";
import {
  authSelectors,
  baseSelectors,
  materialOperations, materialPageActions,
  // materialSelectors,
  orgOperations,
  orgSelectors, paginationSelectors,
  personnelOperations,
  personnelSelectors,
  vendorsActions,
  vendorsOperations,
} from "../../store";


/* This presents the details for a particular vendor. The imported ID is acquired via the URL. */
class VendorsDetailsContainer extends Component {
  componentDidMount = () => {
    const {getPersonnelList} = this.props;
    getPersonnelList();
  };

  render() {
    return (
      <VendorDetails {...this.props} />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  personnelList: personnelSelectors.refreshNameList(state.personnel),
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  vendorDetails: baseSelectors.returnItemWithID(
    state.vendors,
    ownProps.match.params.vendorID
  ),
  vendorTypeList: orgSelectors.refreshVendorTypes(state.orgs),
  notes: baseSelectors.refreshNotes(state.vendors, ownProps.match.params.vendorID),
  // materialList: materialSelectors.refreshNameList(state.materials),
  materialPagination: paginationSelectors.pagination(state.materials.items)
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  getServicesList: () => dispatch(vendorsOperations.getAllItems()),
  getPersonnelList: () => dispatch(personnelOperations.getAllItems()),
  fetchDetails: (id) => dispatch(vendorsActions.getItem(id)),
  addNote: async (id, data) => dispatch(await vendorsOperations.addNote(id, data)),
  updateDetails: (id, data) => dispatch(vendorsOperations.updateItem(id, data)),
  updateVendorField: async (id, fieldData) => dispatch(vendorsOperations.updateItemField(id, fieldData)),
  getOrg: (id) => dispatch(orgOperations.getItem(id)),
  getVendorTypes: (org_id) => dispatch(orgOperations.getVendorTypes(org_id)),
  createVendorType: (org_id, name, color) => dispatch(orgOperations.createVendorType(org_id, name, color)),
  updateVendorType: (org_id, vendor_type_id, name, color) => dispatch(orgOperations.updateVendorType(org_id, vendor_type_id, name, color)),
  deleteVendorType: (org_id, vendor_type_id) => dispatch(orgOperations.deleteVendorType(org_id, vendor_type_id)),
  createOrderMaterial: data => dispatch(materialOperations.createItem(data)),
  updateOrderMaterial: (id, data) => dispatch(materialOperations.updateItem(id, data)),
  deleteOrderMaterial: id => dispatch(materialOperations.deleteItem(id)),
  getOrderMaterials: () => dispatch(materialOperations.getAllItems()),
  getMaterials: (page = 1, params = {}) => dispatch(materialPageActions.fetchPage({page, params})),
  fetchNotes: (id) => dispatch(vendorsActions.getItemNotes(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorsDetailsContainer);
