import React from "react";
import PropTypes from "prop-types";
import {DetailsPage} from "../../Common/Details";
import ProductionLineForm from "../Forms/ProductionLine/ProductionLineForm";
import {PRODUCTION_DASHBOARD_LINE_ROUTE} from "../../../routes/constants";
import {Descriptions} from "antd";
import {crudActions} from "../../../types/propTypes";

import {AssetSection, EntrySection, ProductSection} from "./Sections";
import authSelectors from "../../../store/auth/selectors";

const ProductionDetails = props => {
  const {
    accountInfo,
    details, lineActions,
    entryActions, entryPagination,
    locationList, assetList, productPagination, getProducts,
    salesOrderActions
  } = props;

  const fetchData = async () => {
    const {getLocations, getAssets, orgID} = props;

    getAssets();
    getLocations(orgID);
    // getProducts(1);
  };

  const renderDetails = () => {
    return (
      <Descriptions size="small" column={{md: 3, sm: 2, xs: 1}} className={"page-details"}>
        <Descriptions.Item label="Name">{details?.name ?? ""}</Descriptions.Item>
      </Descriptions>
    );
  };

  let tabs = [{
    key: "entries",
    title: "Entries",
    content: (
      <EntrySection
        data={details}
        actions={entryActions}
        productionLine={details}
        pagination={entryPagination}
        salesOrderActions={salesOrderActions}
        lineActions={lineActions}
      />
    )
  }];
  if (authSelectors.moduleEnabled(accountInfo, "warehouse")) {
    tabs.push({
      key: "products",
      title: "Products",
      content: (
        <ProductSection
          data={details}
          pagination={productPagination}
          getPage={getProducts}
          onSubmit={lineActions.update}
        />
      )
    });
  }
  if (authSelectors.moduleEnabled(accountInfo, "assets")) {
    tabs.push({
      key: "assets",
      title: "Assets",
      content: (
        <AssetSection
          data={details}
          assetList={assetList}
          onSubmit={lineActions.update}
        />
      )
    });
  }

  return (
    <DetailsPage
      accountInfo={accountInfo}
      details={details}
      apiActions={lineActions}
      fetchData={fetchData}
      hasLocation={true}
      locationList={locationList}
      type={"Production Line"}
      headerProps={{
        DetailsForm: ProductionLineForm,
        editConfig: {
          formID: "production-line-form",
        },
        deleteConfig: {
          confirmText: "Deleting this will remove all items assigned to it.",
          redirectUrl: PRODUCTION_DASHBOARD_LINE_ROUTE,
        },
        text: {
          title: "Production Line Details",
          name: "Production Line",
          shortName: "lines",
          subTitle: details?.status_display ?? "None",
        },
        renderDescription: renderDetails
      }}
      tabs={tabs}
    />
  );
};

ProductionDetails.propTypes = {
  details: PropTypes.object,
  lineActions: crudActions
};

export default ProductionDetails;
