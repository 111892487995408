import types from "./types";
import {name as endpoint} from "./index";
import base from "../base/actions";
import {constructRoute} from "../base/utils";
import {BULK_DELETE} from "../../routes/constants";

/* Update item actions. */

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE]
});

const bulkDelete = (data) => base.patchRsaa({
  endpoint: constructRoute(`${endpoint}${BULK_DELETE}`),
  body: JSON.stringify(data),
  types: [
    types.BULK_DELETE_REQUEST,
    types.BULK_DELETE_SUCCESS,
    types.BULK_DELETE_FAILURE
  ]
});

/* Fetch Item Notes */
const getItemNotes = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  types: [
    types.GET_ITEM_NOTES_REQUEST,
    {
      type: types.GET_ITEM_NOTES_SUCCESS,
      meta: {id}
    },
    types.GET_ITEM_NOTES_FAILURE
  ]
});

const submitNote = (id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/notes/`,
  body: JSON.stringify(data),
  types: [
    types.SUBMIT_NOTE_REQUEST,
    {
      type: types.SUBMIT_NOTE_SUCCESS,
      meta: {id}
    },
    types.SUBMIT_NOTE_FAILURE
  ]
});

const uploadCsv = (data, org) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}csv/${org}/`,
  headers: {},
  body: data,
  types: [types.UPLOAD_CSV_REQUEST, types.UPLOAD_CSV_SUCCESS, types.UPLOAD_CSV_FAILURE]
});

const createAssetTemplate = (asset_id, org_id, asset_type_id, template) => base.postRsaa({
  endpoint: `${global.base_url}v1/orgs/${org_id}/asset_types/${asset_type_id}/templates/`,
  body: JSON.stringify(template),
  types: [
    types.CREATE_ASSET_TEMPLATE_REQUEST,
    types.CREATE_ASSET_TEMPLATE_SUCCESS,
    types.CREATE_ASSET_TEMPLATE_FAILURE
  ]
});

const updateAssetTemplate = (asset_id, org_id, asset_type_id, template_id, template) => base.patchRsaa({
  endpoint: `${global.base_url}v1/orgs/${org_id}/asset_types/${asset_type_id}/templates/${template_id}/`,
  body: JSON.stringify(template),
  types: [
    types.UPDATE_ASSET_TEMPLATE_REQUEST,
    types.UPDATE_ASSET_TEMPLATE_SUCCESS,
    types.UPDATE_ASSET_TEMPLATE_FAILURE
  ]
});

const deleteAssetTemplate = (asset_id, org_id, asset_type_id, template_id) => base.deleteRsaa({
  endpoint: `${global.base_url}v1/orgs/${org_id}/asset_types/${asset_type_id}/templates/${template_id}/`,
  types: [
    types.DELETE_ASSET_TEMPLATE_REQUEST,
    types.DELETE_ASSET_TEMPLATE_SUCCESS,
    types.DELETE_ASSET_TEMPLATE_FAILURE
  ]
});

/* Fetch Personnel with skills that match Asset tags */
const getQualifiedPersonnel = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/personnel/`,
  types: [
    types.GET_QUALIFIED_PERSONNEL_REQUEST,
    {
      type: types.GET_QUALIFIED_PERSONNEL_SUCCESS,
      meta: {id}
    },
    types.GET_QUALIFIED_PERSONNEL_FAILURE
  ]
});

/* SimplePagination actions. */
const gotoPage = page => ({
  type: types.GOTO_PAGE,
  page
});

const nextPage = () => ({
  type: types.NEXT_PAGE
});

const prevPage = () => ({
  type: types.PREV_PAGE
});

const sortList = by => ({
  type: types.SORT_LIST,
  payload: by
});

const filterList = filter => ({
  type: types.FILTER_LIST,
  filter
});

const filterListBy = query => ({
  type: types.FILTER_LIST_BY,
  payload: query
});

const filterListByType = query => ({
  type: types.FILTER_LIST_BY_TYPE,
  payload: query
});

const reverseList = () => ({
  type: types.REVERSE_LIST
});

/* View related actions. */
const changeViewList = () => ({
  type: types.CHANGE_VIEW_LIST
});

const changeViewTile = () => ({
  type: types.CHANGE_VIEW_TILE
});

const changeViewTable = () => ({
  type: types.CHANGE_VIEW_TABLE
});

const clearMessages = () => ({
  type: types.CLEAR_MESSAGES
});

export default {
  updateItem,
  getItemNotes,
  submitNote,

  uploadCsv,

  createAssetTemplate,
  updateAssetTemplate,
  deleteAssetTemplate,

  getQualifiedPersonnel,

  gotoPage,
  nextPage,
  prevPage,
  sortList,
  filterList,
  filterListBy,
  filterListByType,
  reverseList,
  changeViewList,
  changeViewTile,
  changeViewTable,
  bulkDelete,
  clearMessages
};
