import styled from "@emotion/styled";
import {Box} from "../../Base";
import {right, space, top} from "styled-system";

export const SearchContainer = styled(Box)`
  flex: 1 0.5 275px;
  max-width: 100%;
  ${top};
  ${right};
  ${space};
`;
