import {
  faArrowAltCircleLeft,
  faBarcode,
  faBell,
  faBoxes,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faClock,
  faEdit,
  faExclamationTriangle,
  faFileAlt,
  faImage,
  faList,
  faMinus,
  faPallet,
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faSearch,
  faSearchMinus,
  faTable,
  faTh,
  faTrash,
  faUsersCog,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

import {library} from "@fortawesome/fontawesome-svg-core";

export {
  faCaretUp,
  faCaretDown,
  faPlusCircle,
  faPlus,
  faTrash,
  faCheck,
  faSearch,
  faWindowClose,
  faTh,
  faList,
  faTable,
  faEdit,
  faCalendarAlt,
  faArrowAltCircleLeft,
  faBell,
  faImage,
  faFileAlt,
  faBarcode,
  faUsersCog,
  faQuestionCircle,
  faMinus,
  faPaperPlane,
  faExclamationTriangle,
  faChartLine,
  faSearchMinus,
  faPallet,
  faBoxes,
  faQuestion,

};
export default () => {
  /* Font awesome imports. */
  library.add(faCaretUp);
  library.add(faCaretDown);
  library.add(faCheck);
  library.add(faPlusCircle);
  library.add(faPlus);
  library.add(faTrash);
  library.add(faSearch);
  library.add(faWindowClose);
  library.add(faTh);
  library.add(faList);
  library.add(faTable);
  library.add(faArrowAltCircleLeft);
  library.add(faEdit);
  library.add(faCalendarAlt);
  library.add(faBell);
  library.add(faImage);
  library.add(faFileAlt);
  library.add(faBarcode);
  library.add(faUsersCog);
  library.add(faQuestionCircle);
  library.add(faMinus);
  library.add(faClock);
  library.add(faPallet);
  library.add(faBoxes);
  library.add(faPaperPlane);
  library.add(faExclamationTriangle);
  library.add(faChartLine);
  library.add(faSearchMinus);
  library.add(faQuestion);
};
