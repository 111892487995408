export default {
  colorPrimary: "rgb(30, 108, 182)",
  colorPrimaryLight: "rgb(102, 168, 230)",
  colorPrimaryDark: "rgb(16,32,82)",
  colorSecondaryLight: "rgb(216, 145, 38)",
  colorSecondaryDark: "rgb(168, 108, 17)",
  colorError: "rgb(255, 0, 0)",
  colorBlack: "rgb(0, 0, 0)",
  colorBorder: "rgba(102, 102, 102, 0.1)",
  colorGreyDark1: "rgb(102, 102, 102)",
  colorGreyDark2: "rgb(68, 68, 68)",
  colorGreyDark3: "rgb(34, 34, 34)",
  colorGreyLight1: "rgb(247, 247, 247)",
  colorGreyLight2: "rgb(238, 238, 238)",
  colorGreyLight3: "rgb(204, 204, 204)",
  colorGreyLight4: "rgb(170, 170, 170)",
  colorGreyLight5: "rgb(153, 153, 153)",
  colorOpacity: "rgba(25, 25, 25, 0.1)",
  colorTertiaryDark: "rgb(44, 54, 85)",
  colorTertiaryLight: "rgb(66, 175, 218)",
  colorWhite: "rgb(255, 255, 255)",
  primaryBg: "#F7F7F7",
  primaryButton: "#42afda",
  primaryGradient: "linear-gradient(to right, #42afda, #2c3655)",
  secondaryGradient: "linear-gradient(to right, #58DD4E, #2dad24)",
  thirdGradient: "linear-gradient(to right, #F7F7F7, #2c3655)",
  greenGradient: "linear-gradient(to right, #58DD4E, rgb(45, 173, 36))",
  gradients: {
    green: "linear-gradient(to right, #58DD4E, rgb(45, 173, 36))",
    orange: "linear-gradient(to right, #FFD632, #EDBD00)",
    blue: "linear-gradient(to right, #42afda, #2c3655)",
  },
  meta: "rgb(0, 0, 0, 0.4)"
};
