import {
  updatedAtColumn,
  createdAtColumn,
  viewMoreColumn,
  numberOfNotesColumn,
  nameColumn
} from "../../../utils/tables";
import {dateSorter, numberSorter, stringSorter} from "../../../utils/sort";

// Configure the columns of the table
const columns = [
  nameColumn({width: 100}),
  {
    title: "Description",
    dataIndex: "description",
    elipsis: true,
    width: 150,
    editable: true,
    searchable: true,
    sorter: stringSorter("description"),
  },
  {
    title: "First Scheduled Date",
    dataIndex: "first_scheduled_date",
    width: 125,
    sorter: dateSorter
  },
  numberOfNotesColumn({width: 75}),
  {
    title: "Repeating",
    dataIndex: "repeating",
    width: 75,
    sorter: stringSorter("repeat_unit"),
  },
  {
    title: "Frequency",
    dataIndex: "frequency",
    width: 75,
    // TODO: why doesn't this one work?
    sorter: numberSorter("frequency"),
  },
  createdAtColumn({width: 100}),
  updatedAtColumn({width: 100}),
  viewMoreColumn("orders")
];

export default columns;
