import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormField} from "../Base";
import {AttachedFiles} from "../../index";
import {AZURE_BLOB_CONTAINER_NAME, AZURE_SERVPROVDIV_PREFIX, deleteBlobFromAzureContainer, listBlobsInAzureContainer} from "../../../../utils/azure";
import {SubmitButton} from "../styled";


const DiversityInfo = props => {
  const [proof, setProof] = useState([]);

  const {
    handleSubmit,
    validate,
    diverse_business_enterprise,
    userUUID
  } = props;

  const getProofFiles = () => {
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_SERVPROVDIV_PREFIX}/${userUUID}/`).then(file => {
      setProof(file);
    });
  };

  useEffect(() => {
    getProofFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveDocument = documentName => {
    deleteBlobFromAzureContainer(AZURE_BLOB_CONTAINER_NAME, documentName);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={{diverse_business_enterprise}}
      render={({
        handleSubmit,
        form,
        pristine,
        invalid,
        submitting,
        values
      }) => (
        <form className={"item-details-form serv-prov-info"} onSubmit={handleSubmit}>
          <FormField
            label="Diverse Business Enterprise (DBE)"
            type="checkbox"
            name="diverse_business_enterprise"
          />
          <AttachedFiles
            files={proof}
            azureDocumentPrefix={AZURE_SERVPROVDIV_PREFIX}
            UUID={userUUID}
            getDocuments={getProofFiles}
            handleRemoveDocument={handleRemoveDocument}
            refreshObject={() => null}/>
          <br/>
          <SubmitButton id="diverseSubmit" className="item-details-form-button" loading={submitting}>
            Submit
          </SubmitButton>
        </form>
      )
      }
    />
  );
};

DiversityInfo.propTypes = {
  handleSubmit: PropTypes.func,
  validate: PropTypes.func
};

DiversityInfo.defaultProps = {
  validate: (values) => null
};

export default DiversityInfo;
