import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";
import {allStatusOptions} from "../../Common/Forms/VendorForm/constants";
import {Details} from "./Details";
import {Bottom, SeeMoreButton, SideList, Wrapper} from "../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../Common/Bars/BarComponents/constants";

/* A vendor orders bar is a div containing information regarding a vendor order. A list of
vendor order bars are visible within the vendor orders section. */

const VendorBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const baseClassName = listView ? listStyle : tileStyle;
  const {status, is_on_site} = data;
  let styles = {};
  if (data.type !== null) {
    styles["backgroundColor"] = data.type.color;
    styles["color"] = chroma.contrast(data.type.color, "white") > 3 ? "white" : "black";
  } else {
    styles["color"] = "white";
  }
  const leftList = [
    {title: "VENDOR TITLE", value: data.title || data.id},
    {title: "ASSOCIATED ASSET", value: data.asset?.name ?? "None"},
    {title: "ORDER STATUS", value: status && is_on_site !== null ? allStatusOptions[status].label : "None"}
  ];


  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="vendors"
      itemData={data}
      detailsPane={<Details baseClassName={baseClassName} styles={styles} />}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} />}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
    </Wrapper>
  );
};

VendorBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array
};

export {VendorBar};
