import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Accordion, AccordionItem, Button, Flex, ListItem as BaseListItem} from "../index";
import {List as BaseList} from "@chakra-ui/core";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
/*
Renders a paginated list of items.
 */

const ITEMS_PER_PAGE = 10;

// /* Helper function to compute number of pages */
// const getNumberOfPages = (length, itemsPerPage) => (
//   Math.ceil(length / itemsPerPage)
// );

const Pagination = ({items, ListItem, handleItemClick, itemsPerPage, itemProps, isAccordion, accordionProps, listItemKey}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStart, setCurrentStart] = useState(0);
  const [currentEnd, setCurrentEnd] = useState(ITEMS_PER_PAGE);

  const List = (props) => {
    if (isAccordion === true) {
      return (<Accordion {...props} {...accordionProps}/>);
    } else {
      return <BaseList {...props}/>;
    }
  };

  const ListItemComponent = ListItem ? ListItem : isAccordion ? AccordionItem : BaseListItem;
  // Reset page number if items prop changes
  useEffect(() => {
    setCurrentPage(1);
    setCurrentStart(0);
    setCurrentEnd(ITEMS_PER_PAGE);
  }, [items]);

  const checkIsLastPage = () => {
    const nextPage = currentPage + 1;
    return (nextPage > Math.ceil(items.length / itemsPerPage));
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    const lastIndex = nextPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    setCurrentPage(nextPage);
    setCurrentStart(firstIndex);
    setCurrentEnd(lastIndex);
  };


  const handlePrevPage = () => {
    const nextPage = currentPage - 1;
    const lastIndex = nextPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    setCurrentPage(nextPage);
    setCurrentStart(firstIndex);
    setCurrentEnd(lastIndex);
  };

  const handleClick = (item) => {
    handleItemClick(item);
  };


  let currentPageList = [];
  let showPagination = false;
  let isLastPage = true;
  if (items && items.length) {
    currentPageList = items.slice(currentStart, currentEnd) ;
    showPagination = items.length > 10;
    isLastPage = checkIsLastPage();
  }

  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      {currentPageList.length !== 0 &&
      <List>
        {currentPageList.map(item => {
          const key = listItemKey(item);
          return (
            <ListItemComponent
              key={key}
              item={item}
              onClick={handleClick}
              {...itemProps}
            />
          );
        })}
      </List>
      }

      {showPagination &&
      <Flex flexDirection={"row"}>
        <Flex width={1 / 2} justifyContent={"center"}>
          {currentPage !== 1 &&
          <Button className={"prev-page"} onClick={handlePrevPage}>Prev</Button>
          }
        </Flex>
        <Flex width={1 / 2} justifyContent={"center"}>
          {!isLastPage &&
          <Button className={"next-page"} onClick={handleNextPage}>Next</Button>
          }
        </Flex>
      </Flex>
      }
    </ReactCSSTransitionGroup>
  );
};

Pagination.propTypes = {
  items: PropTypes.array,
  ListItem: PropTypes.any,
  handleItemClick: PropTypes.func,
  itemsPerPage: PropTypes.number,
  itemProps: PropTypes.any,
  renderItem: PropTypes.func,
  isAccordion: PropTypes.bool,
  accordionProps: PropTypes.any,
  listItemKey: PropTypes.func
};

Pagination.defaultProps = {
  ListItem: null,
  handleItemClick: (item) => console.log("pagination click", item),
  itemsPerPage: ITEMS_PER_PAGE,
  isAccordion: false,
  accordionProps: {},
  listItemKey: (item) => item.id
};

export default Pagination;
