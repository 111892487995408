import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import Home from "../Home";
import {WarehouseScorecard} from "../Scorecards/Warehouse/WarehouseScorecard";
import {BetaSwitch} from "Base";
import {MaintenanceScorecard} from "../Scorecards/Maintenance/MaintenanceScorecard";
import {ProductionScorecard} from "../Scorecards/Production/ProductionScorecard";


// Logic for deciding to show beta feature scorecards and which scorecards to show
const HomeDashboard = (props) => {
  const {accountInfo, showLoader} = props;
  const [beta, setBeta] = useState(false);
  const isBeta = accountInfo?.personnel?.beta_approved;

  // Set which scorecards display for certain sets of user types. Reference for the integers in
  // src/components/personnel/table/data.js => {USER_TYPES}
  const displayWarehouseFor = [0, 1, 2];
  const displayMaintenanceFor = [0, 1, 3, 4, 5];
  const displayProductionFor = [0, 1, 2, 3];

  return (
    <Fragment>
      {!showLoader && isBeta && <BetaSwitch onchange={() => setBeta(!beta)}/>}
      {isBeta && beta ?
        <Fragment>
          {displayWarehouseFor.includes(accountInfo?.user_type) && <WarehouseScorecard {...props}/>}
          {displayMaintenanceFor.includes(accountInfo?.user_type) && <MaintenanceScorecard {...props}/>}
          {displayProductionFor.includes(accountInfo?.user_type) && <ProductionScorecard {...props}/>}
        </Fragment>
        :
        <Home {...props}/>
      }
    </Fragment>
  );
};

HomeDashboard.propTypes = {
  /**
   *  The props for this component are mainly drilled to it's children and uses accountInfo to decide which children
   *  to display.
   */
  accountInfo: PropTypes.object,
  errors: PropTypes.object,
  fetchOrgSummary: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  showLoader: PropTypes.bool,
  staticContent: PropTypes.object,
  summary: PropTypes.object,
  summaryTimescale: PropTypes.string,
  summaryTimescaleValue: PropTypes.string,
  summaryYear: PropTypes.string
};

HomeDashboard.defaultProps = {
  accountInfo: {},
  errors: {},
  fetchOrgSummary: () => console.log("fetch org from default props"),
  history: {},
  location: {},
  match: {},
  summary: {},
  summaryTimescale: "",
  summaryTimescaleValue: "",
  summaryYear: ""
};

export {HomeDashboard};
