import React from "react";
import {Link} from "react-router-dom";
import {PERSONNEL_DASHBOARD_ROUTE} from "../../../routes/constants";
import {
  updatedAtColumn,
  createdAtColumn,
  viewMoreColumn
} from "../../../utils/tables";
import {allStatusOptions} from "../../Common/Forms/VendorForm/constants";
import {SearchOutlined} from "@ant-design/icons";
import {stringSorter} from "../../../utils/sort";

const statusFilterList = Object.values(allStatusOptions).map(option => ({text: option.label, value: option.label}));

// Configure the columns of the table
const columns = [
  {
    title: "Title",
    width: 150,
    dataIndex: "title",
    searchable: true,
    sorter: stringSorter("title")
  },
  {
    title: "Associated Asset Name",
    dataIndex: "associated_asset_name",
    width: 125,
    elipsis: true,
    searchable: true,
    sorter: stringSorter("associated_asset_name")
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    sorter: stringSorter("status"),
    filters: statusFilterList,
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? "#485fe0" : undefined}} />,
    onFilter: (value, record) => record.status.indexOf(value) === 0,
  },
  {
    title: "Email Sent",
    width: 100,
    dataIndex: "email_sent",
    sorter: stringSorter("email_sent")
  },
  {
    title: "Assigned Personnel",
    dataIndex: "assigned_personnel",
    width: 125,
    render: person => {
      if (person?.id && person?.name !== "None") {
        return <Link to={`${PERSONNEL_DASHBOARD_ROUTE}/${person.id}`}>{person.name}</Link>;
      }
    },
  },
  createdAtColumn({width: 120}),
  updatedAtColumn({width: 120}),
  viewMoreColumn("vendors")
];

export default columns;
