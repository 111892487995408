import React from "react";
import PropTypes from "prop-types";
import {Checkbox} from "antd";
import {Flex, OptionalLink} from "../../../Base";
import {ImageWithDefault} from "../../../Details/Layout";
import {listStyle, tileStyle} from "../constants";

const buildUrl = ({url, itemType}) => {
  return url ? url : `/dashboard/${itemType}`;
};

/**
 * This component is the main bar wrapper.  It handles selection, image, and starts the layout for the rest of a bar.
 * @component
 * @param {object} props The object properties
 * @returns {Wrapper} The wrapper
 */
const Wrapper = props => {
  const {
    baseClassName,
    showDelete,
    selectedRowsIds,
    onCheckChange,
    itemType,
    itemData,
    detailsPane,
    bottomBar,
    children,
    url
  } = props;

  const handleOnClick = () => {
    return onCheckChange(itemData.id);
  };

  const generateId = () => {
    if (itemData.name) {
      return `${itemData.name}Box`;
    } else if (itemData.title) {
      return `${itemData.title}Box`;
    } else {
      return "";
    }
  };

  const baseUrl = buildUrl({url, itemType});
  return (
    <Flex className={baseClassName}>
      <OptionalLink
        style={{textDecoration: "none"}}
        display={"flex"}
        width={"100%"}
        id={generateId()}
        to={!showDelete && `${baseUrl}/${itemData.id}`}
        onClick={showDelete ? handleOnClick : () => null}>
        <div className={`${baseClassName}-imgBox`}>
          {showDelete &&
          <Checkbox
            id={itemData.name || itemData.title || ""}
            className={`${baseClassName}-checkbox`}
            checked={selectedRowsIds.includes(itemData.id)}
          />
          }
          <ImageWithDefault className={`${baseClassName}-img`} src={itemData.display_image}/>
        </div>
        <Flex direction={"column"} flex={"1 1 auto"}>
          <div className={`${baseClassName}-main`}>
            {children}
          </div>
          {baseClassName === listStyle && bottomBar}
        </Flex>
        {baseClassName === tileStyle && bottomBar}
        {detailsPane || <div className={`${baseClassName}-detail`}/>}
      </OptionalLink>
    </Flex>
  );
};

Wrapper.propTypes = {
  baseClassName: PropTypes.string,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array,
  onCheckChange: PropTypes.func,
  itemType: PropTypes.string,
  itemData: PropTypes.object,
  detailsPane: PropTypes.node,
  bottomBar: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  // full base url for details page
  url: PropTypes.string
};

Wrapper.defaultProps = {
  url: null
};

export {Wrapper};
