import {Descriptions} from "antd";
import PropTypes from "prop-types";
import React from "react";
import voca from "voca";

import {DetailsPage} from "../../Common/Details";
import {LOCATION_DASHBOARD_ROUTE} from "../../../routes/constants";
import LocationForm from "../Forms/LocationForm";
import {PMOrderSection, CreatePMOrderButton} from "./Sections/PMOrders";
import {Link} from "../../Common/Base";
import StockroomAssetSection from "./Sections/StockroomAssets/StockroomAssetSection";


const Details = props => {
  const {
    accountInfo,
    details,
    orgID,
    locationActions,
  } = props;

  const fetchData = async () => {};

  const renderDetails = () => {
    return (
      <Descriptions size="small" column={{md: 1, sm: 2, xs: 1}} className={"page-details"}>
        <Descriptions.Item label="Name">{details[details.level].name || "None"}</Descriptions.Item>
        <Descriptions.Item label="Level">{voca.capitalize(details.level[0]) + details.level.slice(1) || "None"}</Descriptions.Item>
        <Descriptions.Item label="Parent Location">
          {details.parent_level ?
            <Link to={`${LOCATION_DASHBOARD_ROUTE}/${details[details.parent_level].location_instance}`}>
              {details[details.parent_level].name || "No Name"}
            </Link>
            :
            "None"
          }
        </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <DetailsPage
      accountInfo={accountInfo}
      details={details}
      apiActions={locationActions}
      fetchData={fetchData}
      hasLocation={false}
      type={"Location"}
      clickToEdit={false}
      editable={false}
      headerProps={{
        DetailsForm: LocationForm,
        formProps: {
          values: details,
        },
        editConfig: {
          formID: "location-form",
        },
        deleteConfig: {
          confirmText: "Deleting this will remove all items assigned to it.",
          redirectUrl: LOCATION_DASHBOARD_ROUTE,
        },
        text: {
          title: "Location Details",
          name: "Location",
          shortName: "location",
        },
        renderDescription: renderDetails
      }}
      tabs={[
        {
          key: "pm_orders",
          title: "PM Orders",
          content: (
            <PMOrderSection
              data={details}
            />
          )
        },
        {
          key: "stockroom_assets",
          title: "Stockroom Assets",
          content: (
            <StockroomAssetSection
              data={details}
            />
          )
        },
      ]}
      actions={[
        <CreatePMOrderButton
          key={"create_pm_order_button"}
          createPMOrder={locationActions.createPMOrder}
          location={details}
          orgID={orgID}
        />
      ]}
    />
  );
};


Details.propTypes = {
  /**
   * Location instance data
   */
  details: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.string,
    parent_level: PropTypes.string,
  }),
  /**
   * Account data of logged in user
   */
  accountInfo: PropTypes.object,
  /**
   * Organization of user
   */
  orgID: PropTypes.number,
  /**
   * Store actions
   */
  locationActions: PropTypes.shape({
    createPMOrder: PropTypes.func,
  }),
};

export default Details;
