import purchaseOrderReducer from "./reducers";

/* These exports are the only thing that need to be edited for a new module */
export const name = "v1/warehouse/purchase_orders/";

export {default as purchaseOrderTypes} from "./types";
export {default as purchaseOrderSelectors} from "./selectors";
export {default as purchaseOrderOperations} from "./operations";
export {default as purchaseOrderActions} from "./actions";

export default purchaseOrderReducer;
