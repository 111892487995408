/* eslint-disable indent */
import React, {Component} from "react";
import {Box, Button, ErrorBoundary, Flex, Heading, Tabs, TabPane} from "../../../Common/Base";
import {DetailsContainer} from "../../../Common/Details";
import DetailsHeader from "./DetailsHeader";
import {Card} from "antd";
import ProductQuantityModal from "../../Modals/ProductQuantityModal";
import ProductInventoryDetails from "./ProductInventoryDetails";
import {AttachedFiles, BarcodeSection, NoteSection} from "../../../Common";
import {
  AZURE_BLOB_CONTAINER_NAME,
  AZURE_PRODUCT_PREFIX,
  handleChangeDisplayImage,
  handleChangeWarrantyDocument,
  listBlobsInAzureContainer,
  removeDocument,
  uploadBlobsToAzureWithFileNameCheck,
} from "../../../../utils/azure";

import {CODE_128_BARCODE} from "../../../Common/Files/constants";
import {deleteAxios} from "utils/authWithAxios";
import {productEndpoint} from "../../../../store/warehouse/products/constants";


import {baseDetailsState, checkForResponseError, handleErrorResponse, scrollToTop} from "../../../../utils/containerUtils";
import ProductTemplateSection from "../../../Production/ProductTemplate/ProductTemplateSection";
import {ImageWithDefault} from "../../../Common/Details/Layout";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.productID = props.match.params.productID;
    this.state = {
      isEditing: false,
      isAdminControlsOpen: false,
      quantityLocationData: {},
      deleteProductModalShowing: false,
      productBarcode: "",
      newProductBarcode: "",
      itemNotFound: false,
      ...baseDetailsState("product"),
    };
  }

  /* Upon mounting, fetch data for the specific product by productID and store the data in state. */
  componentDidMount = () => {
    this.fetchProductDetails().then(() => {
      if (this.state.productOrgId) {
        this.props.getLocations(this.state.productOrgId);
      }
    });
    scrollToTop();
  };

  /* This function will handle the fetching of details for a product. */
  fetchProductDetails = () => {
    const {fetchDetails, templateActions, materialActions} = this.props;
    return fetchDetails(this.productID)
      .then(res => {
        console.log(res);
        checkForResponseError(res);
        let data = res.payload;
        this.setState({
          productData: data,
          productID: data.id,
          productOrgId: data.org.id,
          productUUID: data.UUID || data.uuid,
          productName: data.name,
          displayImage: data.display_image,
          warrantyDocument: data.warranty_document,
          editAccess: data?.permissions?.write ?? false,
        });
        // this.getProductDocuments();
        templateActions.getPage(1);
        materialActions.getPage(1);
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        if (error.message === "404 - Not Found") {
          this.setState({itemNotFound: true});
        }
        handleErrorResponse(this, error, "Product");
      }).then(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  /* This is passed to the redux-form which returns all values passed in from state */
  onFormSubmit = async (values) => {
    const {updateDetails} = this.props;
    console.log("values", values);
    const res = await updateDetails(this.productID, values);
    if (!res.error) {
      await this.fetchProductDetails();
    }
  };

  submitBarcode = async values => {
    const res = await this.props.updateDetails(this.productID, values);
    console.log("submit barcode res", res);
    this.fetchProductDetails();
    return res;
  };

  generateBarcode = () => {
    const {productDetails, generateBarcode} = this.props;
    return generateBarcode(this.productID, productDetails.org.id, CODE_128_BARCODE);
  };

  /* This function will handle deletion of a product. */
  handleDeleteProduct = () => {
    this.setState({
      adminControlsSubmitting: true,
      adminControlsMessage: "",
    });
    deleteAxios({
      url: `${global.base_url}${productEndpoint}${this.productID}/`,
    })
      .then(res => {
        console.log(res);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Product deletion successful.",
          deletedProduct: true,
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Error occurred during product deletion.",
          deleteProductModalShowing: false,
        });
      });
  };

  toggleDeleteProductModal = () => {
    this.setState({deleteProductModalShowing: !this.state.deleteProductModalShowing});
  };

  getProductDocuments = () => {
    const {productUUID} = this.state;
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_PRODUCT_PREFIX}/${productUUID}/`).then(files => {
      this.setState({
        productFiles: files,
      });
    });
  };

  handleChangeDisplayImageField = () => {
    const {productUUID} = this.state;
    uploadBlobsToAzureWithFileNameCheck(
      productUUID, AZURE_PRODUCT_PREFIX, [this.refs.fileUploader.files[0]],
    ).then(async res => {
      // Res contains filenames from uploaded files, use res[0] because only one file was uploaded
      await this.handleChangeDisplayImageNoUpload(res[0]);
      this.fetchProductDetails();
    });
  };

  handleChangeDisplayImageNoUpload = async imageName => {
    await handleChangeDisplayImage(this, imageName, this.productID, this.props.updateProductField);
  };

  handleChangeWarrantyDocumentField = async documentName => {
    await handleChangeWarrantyDocument(this, documentName, this.productID, this.props.updateProductField);
  };

  handleRemoveDocument = documentName => {
    removeDocument(this, documentName, this.productID, this.props.updateProductField, this.getProductDocuments);
  };

  /* If field is read only, filter main page results by field contents (manufacturer name) and route to page */
  handleManufacturerClick = (by) => {
    const {name} = by.target;
    const productEditAccess = this.props.accountInfo.user_type <= 2;
    const {manufacturer} = this.state.productData;
    const {filterListBy} = this.props;
    if (!productEditAccess) {
      filterListBy({by: name, filter: manufacturer});
    }
  };

  toggleEdit = () => this.setState({isEditing: !this.state.isEditing});

  render() {
    const {
      loading,
      itemNotFound,
      productData,
      productID,
      productUUID,
      // quantityLocationData,
      productFiles,
      productBarcode,
      displayImage,
      warrantyDocument,
      editAccess,
    } = this.state;
    const {
      accountInfo,
      locationList,
      getRecentLogs,
      notes,
      fetchNotes,
      addNote,
      productDetails,
      deleteProduct,
      templatePagination,
      materialPagination,
      materialActions,
      templateActions,
      templateMaterialActions
    } = this.props;

    let barcodeFiles = {};
    if (productBarcode) {
      barcodeFiles["files"] = [{source: productBarcode}];
    }

    const productEditAccess = this.props.accountInfo.user_type <= 2;


    return (
      <DetailsContainer loading={loading} notFound={itemNotFound} type={"Product"}>
        <ErrorBoundary>


          <DetailsHeader
            account={accountInfo}
            onDelete={deleteProduct}
            onUpdate={this.onFormSubmit}
            data={productDetails || {}}
            formProps={{
              handleManufacturerClick: this.handleManufacturerClick,
              editAccess: productEditAccess,
            }}
            actions={[]}
            Image={
              <Box alignItems="center" alignContent="center" textAlign="center">
                <Box width={["100%", "250px"]} p={[0, 2]}>
                  <ImageWithDefault
                    mr="auto"
                    ml="auto"
                    objectFit="cover"
                    src={displayImage}
                  />
                </Box>
                <Box mt="10px">
                  <input type="file" accept="image/*" id="file" ref="fileUploader"
                         onChange={this.handleChangeDisplayImageField}
                         style={{display: "none"}}/>
                  <Button
                    className="item-details-form-button"
                    onClick={() => this.refs.fileUploader.click()}>
                    Change Display Image
                  </Button>
                </Box>
                <Box m={3} p={4}>
                  {productDetails &&
                  <BarcodeSection
                    data={productDetails?.barcode?.data ?? ""}
                    format={productDetails?.barcode?.format === false ? -1 : productDetails?.barcode?.format}
                    updateBarcode={this.submitBarcode}
                    generateBarcode={this.generateBarcode}/>
                  }
                </Box>
              </Box>
            }
            SecondaryContent={
              <Flex flexDirection={["column", "column", "column", "row"]}>
                <Box m={3} p={4} w={["100%", "100%", "100%", "50%"]}>
                  <AttachedFiles
                    files={productFiles}
                    displayImage={displayImage}
                    warrantyDocument={warrantyDocument}
                    azureDocumentPrefix={AZURE_PRODUCT_PREFIX}
                    UUID={productUUID}
                    getDocuments={this.getProductDocuments}
                    handleChangeDisplayImage={this.handleChangeDisplayImageNoUpload}
                    handleChangeWarrantyDocument={this.handleChangeWarrantyDocumentField}
                    handleRemoveDocument={this.handleRemoveDocument}
                    refreshObject={this.fetchProductDetails}/>
                </Box>

              </Flex>
            }
          />

        </ErrorBoundary>
        <Tabs type={"card"}>
          <TabPane tab={"Inventory"} key={"inventory"}>
            <Flex flexDirection={"row"}>
              <Heading py={2} mt={"10px"}>
                Inventory

                <ProductQuantityModal
                  {...this.props}
                  key={"add-item"}
                  loading={loading}
                  productDetails={productDetails || {}}
                  onSubmit={this.submit}
                  values={productData}
                  productID={productID}
                  editAccess={editAccess}
                  locationList={locationList}
                  setLocationData={data => {
                    this.setState({quantityLocationData: data.location.id > 0 ? data : {}});
                  }}
                  isAdmin={accountInfo.user_type <= 1}
                />
              </Heading>


            </Flex>
            <ProductInventoryDetails
              productDetails={productDetails || {}}
            />
          </TabPane>
          <TabPane tab={"Templates"} key={"templates"}>
            <ProductTemplateSection
              product={productDetails}
              pagination={templatePagination}
              templateActions={templateActions}
              templateMaterialActions={templateMaterialActions}
              materialActions={materialActions}
              materialPagination={materialPagination}
              />

          </TabPane>
          <TabPane tab={"Notes"} key={"notes"}>
            <Card>
              <NoteSection
                UUID={productUUID}
                accountInfo={accountInfo}
                documentID={this.productID}
                handleSubmit={addNote}
                refreshObject={() => fetchNotes(this.productID)}
                azureDocumentPrefix={AZURE_PRODUCT_PREFIX}
                getDocuments={this.getProductDocuments}
                notes={notes}
                files={productFiles}
                getRecentLogs={getRecentLogs}
                title={"PRODUCT NOTES"}
                endpoint={"warehouse/products"}
              />
            </Card>
          </TabPane>
        </Tabs>


      </DetailsContainer>
    );
  }
}


export default ProductDetails;
