

const getShiftListFromAccount = (account) => {
  let result = [];
  if (account && account.personnel) {
    const {personnel} = account;
    if (personnel && personnel.schedules) {
      for (let i in personnel.schedules) {
        for (let j in personnel.schedules[i].shifts) {
          result.push({
            ...personnel.schedules[i].shifts[j],
            schedule: {id: personnel.schedules[i].id, name: personnel.schedules[i].name}
          });
        }
      }
    }
  }

  return result;
};


export {getShiftListFromAccount};
