import React, {Component} from "react";
import {connect} from "react-redux";
import {authSelectors, baseSelectors, materialActions, materialOperations, paginationSelectors, materialPageActions} from "../../store/";
import Materials from "../../components/Materials/Dashboard/Materials";

/* This will present a list of material bars. */
class MaterialsContainer extends Component {
  render() {
    return (
      <Materials {...this.props} />
    );
  }
}

/* This is where Redux state is mapped to this component. */
const mapStateToProps = state => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  allItems: baseSelectors.allItems(state.materials.base),
  materialList: baseSelectors.refreshCurrentPage(state.materials.base),
  loading: baseSelectors.isLoading(state.materials.base),
  listView: baseSelectors.isListView(state.materials.base),
  tileView: baseSelectors.isTileView(state.materials.base),
  tableView: baseSelectors.isTableView(state.materials.base),
  hasErrors: baseSelectors.hasErrors(state.materials.base),
  errors: baseSelectors.refreshErrors(state.materials.base),
  pageNum: baseSelectors.currPageNum(state.materials.base),
  isEmptyList: baseSelectors.isEmptyList(state.materials.base),
  isLastPage: baseSelectors.isLastPage(state.materials.base),
  isReversed: baseSelectors.isListReversed(state.materials.base),
  csvMessages: baseSelectors.refreshMessages(state.materials.base),
  pagination: paginationSelectors.pagination(state.materials.items)
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  getPage: (page = 1, params = {}) => dispatch(materialPageActions.fetchPage({page, params})),
  updateDetails: (id, data) => dispatch(materialOperations.updateItem(id, data)),
  bulkDelete: (data) => dispatch(materialOperations.bulkDelete(data)),
  changeView: (view) => dispatch(materialPageActions.changeView(view)),
  getMaterialList: () => dispatch(materialOperations.getAllItems()),
  uploadCsv: (data, org) => dispatch(materialOperations.uploadCsv(data, org)),
  nextPage: () => dispatch(materialActions.nextPage()),
  prevPage: () => dispatch(materialActions.prevPage()),
  filterMaterial: filter => dispatch(materialActions.filterList(filter)),
  sortMaterial: sortBy => dispatch(materialActions.sortList(sortBy)),
  reverseList: () => dispatch(materialActions.reverseList()),
  changeViewList: () => dispatch(materialActions.changeViewList()),
  changeViewTile: () => dispatch(materialActions.changeViewTile()),
  changeViewTable: () => dispatch(materialActions.changeViewTable()),
  clearMessages: () => dispatch(materialActions.clearMessages()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaterialsContainer);
