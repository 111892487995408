import {setTokenAction} from "@tshio/redux-api-auth-middleware";
import Cookies from "js-cookie";
import actions from "./actions";
import {showSnack} from "react-redux-snackbar";
import {postAxios} from "../../utils/authWithAxios";
import jwtDecode from "jwt-decode";
// import {withAuth} from "./selectors";


export const checkLogin = () => async dispatch => {
  let profileRes = await dispatch(actions.getProfile());
  if (!profileRes.error) {
    dispatch(actions.getSettings());
  }
  return profileRes;
};


const login = (username, password) => async dispatch => {
  const res = await dispatch(actions.login(username, password));

  if (!res.error) {
    const refreshDecode = jwtDecode(res.payload.refresh_token);
    const setTokenRes = await dispatch(setTokenAction({
      access_token: res.payload.access_token,
      refresh_token: res.payload.refresh_token,
      expires_in: refreshDecode.exp
    }));
    const accountInfo = await dispatch(checkLogin());
    console.log("setToken res", setTokenRes);
    if (!accountInfo.error) {
      return accountInfo.payload;
    } else {
      return null;
    }
  }
};


const changePassword = (oldP, newP) => async dispatch => {
  try {
    const res = await postAxios({
      url: `${global.base_url}v1/personnel/change_password`,
      data: {
        old_password: oldP,
        new_password: newP
      }
    });
    console.log(res);
    dispatch(showSnack("password", {
      label: "Successfully updated password!",
      timeout: 2000,
      button: {label: "X"}
    }));
  } catch (error) {
    dispatch(showSnack("password", {
      label: "Failed to update password!",
      timeout: 2000,
      button: {label: "X"}
    }));
  }
};

const confirmInvitation = (oldP, newP) => async dispatch => {
  try {
    const res = await postAxios({
      url: `${global.base_url}v1/personnel/change_password`,
      data: {
        old_password: oldP,
        new_password: newP,
        confirm_invitation: true
      }
    });
    console.log(res);
    dispatch(actions.confirmInvitationSuccess());
  } catch (error) {
    dispatch(actions.confirmInvitationFailure(error.response.data));
  }
};


const logout = () => async dispatch => {
  try {
    Cookies.remove("access");

    Cookies.remove("refresh");
    dispatch({
      type: "LOG_OUT"
    });
  } catch (error) {
    console.log(error);
    dispatch(actions.fetchAccountInfoFailure(error.response));
    dispatch({
      type: "LOG_OUT"
    });
  }
  localStorage.clear();
};

/* This will send an email to the user with a password reset link if they exist */
const requestPasswordReset = (email) => async dispatch => {
  try {
    const res = await postAxios({
      url: `${global.base_url}auth/password/reset/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        email: email
      })
    });
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    // Dispatch(actions.tokenFailure(error));
    return error;
  }
};

const updateSettings = (data) => async dispatch => {
  const res = await dispatch(actions.updateSettings(data));

  if (!res.error) {
    dispatch(actions.getSettings());
  }
};


export default {
  login,
  logout,
  checkLogin,
  changePassword,
  confirmInvitation,
  requestPasswordReset,
  updateSettings
};
