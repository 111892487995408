import types from "./types";
import base from "../base/actions";
import {name as endpoint} from "../scheduledShifts";

/* Standard fetch actions */
const getItem = id => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  types: [types.GET_ITEM_REQUEST, types.GET_ITEM_SUCCESS, types.GET_ITEM_FAILURE],
});

const getAllItems = () => base.getRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE],
});

const updateItem = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_ITEM_REQUEST, types.UPDATE_ITEM_SUCCESS, types.UPDATE_ITEM_FAILURE],
});

const createScheduledShift = data => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}`,
  body: JSON.stringify(data),
  types: [
    types.CREATE_SCHEDULED_SHIFT_REQUEST,
    types.CREATE_SCHEDULED_SHIFT_SUCCESS,
    types.CREATE_SCHEDULED_SHIFT_FAILURE,
  ],
});

const updateScheduledShift = (id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [
    types.UPDATE_SCHEDULED_SHIFT_REQUEST,
    types.UPDATE_SCHEDULED_SHIFT_SUCCESS,
    types.UPDATE_SCHEDULED_SHIFT_FAILURE,
  ],
});

const deleteScheduledShift = (id, data) => base.deleteRsaa({
  endpoint: `${global.base_url}${endpoint}${id}/`,
  body: JSON.stringify(data),
  types: [
    types.DELETE_SCHEDULED_SHIFT_REQUEST,
    types.DELETE_SCHEDULED_SHIFT_SUCCESS,
    types.DELETE_SCHEDULED_SHIFT_FAILURE,
  ],
});

const getScheduleShiftCalendar = (id, data) => base.postRsaa({
  /**
   * This well fetch all Shifts for the chosen schedule between the two dates passed in data
   * @param {object} data - from_date: date, to_date: date
   * @param {string} id - ID of Schedule
   */
  endpoint: `${global.base_url}${endpoint}${id}/calendar`,
  body: JSON.stringify(data),
  types: [types.GET_SCHEDULE_CALENDAR_REQUEST, types.GET_SCHEDULE_CALENDAR_SUCCESS, types.GET_SCHEDULE_CALENDAR_FAILURE],
});


const getCalendar = (data) => base.postRsaa({
  /**
   * This well fetch all Shifts between the two dates passed in data
   * @param {object} data - from_date: date, to_date: date
   */
  endpoint: `${global.base_url}${endpoint}calendar/`,
  body: JSON.stringify(data),
  types: [types.GET_CALENDAR_REQUEST, types.GET_CALENDAR_SUCCESS, types.GET_CALENDAR_FAILURE],
});

const createShift = (schedule_id, data) => base.postRsaa({
  endpoint: `${global.base_url}${endpoint}${schedule_id}/shifts/`,
  body: JSON.stringify(data),
  types: [types.CREATE_SHIFT_REQUEST, types.CREATE_SHIFT_SUCCESS, types.CREATE_SHIFT_FAILURE],
});

const updateShift = (schedule_id, id, data) => base.patchRsaa({
  endpoint: `${global.base_url}${endpoint}${schedule_id}/shifts/${id}/`,
  body: JSON.stringify(data),
  types: [types.UPDATE_SHIFT_REQUEST, types.UPDATE_SHIFT_SUCCESS, types.UPDATE_SHIFT_FAILURE],
});

const deleteShift = (schedule_id, id) => base.deleteRsaa({
  endpoint: `${global.base_url}${endpoint}${schedule_id}/shifts/${id}`,
  types: [types.DELETE_SHIFT_REQUEST, types.DELETE_SHIFT_SUCCESS, types.DELETE_SHIFT_FAILURE],
});


const assignPersonnel = (schedule_id, data) => base.putRsaa({
  /*
  Data : {
    action: Number - 1 for Add, 2 for Remove,
    personnel: Number - PersonnelID
   */
  endpoint: `${global.base_url}${endpoint}${schedule_id}/personnel/`,
  body: JSON.stringify(data),
  types: [types.ASSIGN_PERSONNEL_REQUEST, types.ASSIGN_PERSONNEL_SUCCESS, types.ASSIGN_PERSONNEL_FAILURE],
});


const getActiveShiftActivity = () => base.getRsaa({
  endpoint: `${global.base_url}v1/timetracker/toggle_tracking/`,
  types: [types.GET_ACTIVE_SHIFT_ACTIVITY_REQUEST, types.GET_ACTIVE_SHIFT_ACTIVITY_SUCCESS, types.GET_ACTIVE_SHIFT_ACTIVITY_FAILURE],
});

const getRecentShiftActivity = (page) => base.getRsaa({
  endpoint: `${global.base_url}v1/timetracker/?page=${page}`,
  types: [types.GET_RECENT_SHIFT_ACTIVITY_REQUEST, types.GET_RECENT_SHIFT_ACTIVITY_SUCCESS, types.GET_RECENT_SHIFT_ACTIVITY_FAILURE],
});

const toggleShiftActivity = (data) => base.postRsaa({
  /*
    data: {
      shift: shift_id
     }
     */
  endpoint: `${global.base_url}v1/timetracker/toggle_tracking/`,
  body: JSON.stringify(data),
  types: [types.TOGGLE_SHIFT_ACTIVITY_REQUEST, types.TOGGLE_SHIFT_ACTIVITY_SUCCESS, types.TOGGLE_SHIFT_ACTIVITY_FAILURE],
}
);

export default {
  updateItem,
  getItem,
  getAllItems,
  createScheduledShift,
  updateScheduledShift,
  deleteScheduledShift,
  getScheduleShiftCalendar,
  getCalendar,
  createShift,
  updateShift,
  deleteShift,
  assignPersonnel,
  getActiveShiftActivity,
  getRecentShiftActivity,
  toggleShiftActivity
};
