import React from "react";
import {ErrorHeading} from "./Heading";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // console.log("error", errorInfo);
  }

  render() {
    const {fallbackComponent, errorText} = this.props;
    if (this.state.hasError) {
      if (fallbackComponent !== undefined) {
        return fallbackComponent;
      }
      return errorText ? <ErrorHeading>{errorText}</ErrorHeading> : null;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  errorText: PropTypes.string,
  fallbackComponent: PropTypes.element
};

ErrorBoundary.defaultProps = {
  errorText: null
};

export default ErrorBoundary;
