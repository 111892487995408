import {sortBy} from "lodash-es";
import moment from "moment";

/**
 * Sorting function which takes into account
 *    A) The state of isReversed
 *    B) if the key is 'created_at' or 'updated_at'
 * This matters because the lodash sortBy function returns oldest items first when searching by date
 * @param {Array} list The list of objects to sort
 * @param {string} key The key to sort by
 * @param {boolean} [isReversed=false] If the list should be reversed
 * @returns {Array} The list of sorted items
 */
const sortByKey = (list, key, isReversed = false) => {
  let sortedList = sortBy(list, key);
  return (((key === "created_at" || key === "updated_at") && !isReversed) || (isReversed && key !== "created_at" && key !== "updated_at")) ?
    sortedList.reverse() :
    sortedList;
};


export default {
  sortByKey
};

export const dateSorter = {compare: (a, b) => moment(a.updated_at).diff(moment(b.updated_at))};

/**
 * Utility function to create a number comparator using an items key
 * @param {string} item is the items key
 * @returns {Function} return a comparator to alphabetize using the item key
 */
export const numberSorter = item => {
  return {compare: (a, b) => a[item] - b[item]};
};

/**
 * Utility function to create a string comparator using an items key
 * @param {string} item is the items key
 * @returns {Function} return a comparator to alphabetize using the item key
 */
export const stringSorter = item => {
  /**
   * Utility function to create a string comparator using an items key
   * @param {string} a first string to compare
   * @param {string} b second string to compare
   * @returns {Function} return a comparator to alphabetize using the item key
   */
  const compare = (a, b) => {
    const aValue = a[item];
    const bValue = b[item];
    const aNonNullValue = aValue?.toUpperCase();
    const bNonNullValue = bValue?.toUpperCase();
    return (aValue === null) - (bValue === null) || -(aNonNullValue > bNonNullValue) || Number(aNonNullValue < bNonNullValue);
  };
    // eslint-disable-next-line max-len
  return {compare: (a, b) => compare(a, b)};
};


/**
 * Groups an array of objects by key and returns as an array of arrays
 * @param {[]} arr - array of objects
 * @param {string} key - field to group array by
 * @returns {[{key: string, data: Array}]} - array of of key data pairs
 */
export const groupBy = (arr, key) => {
  let newArr = [],
    types = {};
  for (const cur of arr) {
    if (!(cur[key] in types)) {
      types[cur[key]] = {key: cur[key], data: []};
      newArr.push(types[cur[key]]);
    }
    types[cur[key]].data.push(cur);
  }
  return newArr;
};
