import React, {Component} from "react";
import {connect} from "react-redux";

import {authSelectors, baseSelectors, materialActions, materialOperations, orgOperations, orgSelectors, paginationSelectors} from "../../store/";

import MaterialDetails from "../../components/Materials/Details/MaterialDetails";

class MaterialDetailsContainer extends Component {
  render() {
    return <MaterialDetails {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  materialDetails: paginationSelectors.getItem(
    state.materials.items,
    ownProps.match.params.materialID,
  ),
  locationList: orgSelectors.refreshLocations(state.orgs),
  notes: baseSelectors.refreshNotes(state.materials.base, ownProps.match.params.materialID),
});

const mapDispatchToProps = dispatch => ({
  fetchDetails: id => dispatch(materialActions.getItem(id)),
  getLocations: id => dispatch(orgOperations.getLocations(id)),
  addNote: async (id, data) => dispatch(await materialOperations.addNote(id, data)),
  updateDetails: (id, data) => dispatch(materialOperations.updateItem(id, data)),
  updateMaterialField: async (id, fieldData) => dispatch(materialOperations.updateItemField(id, fieldData)),
  fetchNotes: id => dispatch(materialActions.getItemNotes(id)),
  generateBarcode: (objId, orgId, barcodeType) =>
    dispatch(materialActions.generateBarcode({objId, orgId, barcodeType})),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialDetailsContainer);
