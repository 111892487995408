import React from "react";
import PropTypes from "prop-types";
import {Descriptions, Flex, Link, List, Skeleton, Button} from "../../../Common/Base";

import {MATERIAL_DASHBOARD_ROUTE} from "../../../../routes/constants";

const MaterialListItem = ({item, loading, onSelect, onDeselect, isSelected}) => {
  const handleSelect = () => (isSelected ? onDeselect() : onSelect({...item, quantity: 0}));

  return (
    <List.Item
      className={"material-list-item"}
      actions={[
        <Flex justifyContent={"flex-start"} key={1}>
          <Button variant={"outline"} onClick={handleSelect} disabled={isSelected}>
            {isSelected ? "Deselect" : "Select"}
          </Button>
        </Flex>,
      ]}
      extra={
        <Flex flexDirection={"column"} width={"230px"}>
          <Descriptions column={1}>
            <Descriptions.Item label={"SKU"}>{item.sku}</Descriptions.Item>
            <Descriptions.Item label={"Cost"}>{item.price}</Descriptions.Item>
          </Descriptions>
        </Flex>
      }
    >
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          // avatar={
          //   <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>
          // }
          title={<Link to={`${MATERIAL_DASHBOARD_ROUTE}/${item.id}`}>NAME: {item.name}</Link>}
          // description={
          //   <Text>{item.product.manufacturer}</Text>}
        />
      </Skeleton>
    </List.Item>
  );
};

MaterialListItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  selectedProductID: PropTypes.number,
};

export default MaterialListItem;
