import React from "react";
import {Field} from "react-final-form";

const Condition = ({when, is, dropdown, isGreaterThan, children, propValue}) => (
  <Field name={when} subscription={{value: true}}>
    {({input: {value}}) => {
      if (dropdown) {
        value = value.value || value.id;
      }
      let showChildren = false;
      if (is) {
        showChildren = value === is;
      }
      if (!value && propValue) {
        value = propValue;
      }
      if (isGreaterThan) {
        showChildren = value > isGreaterThan;
      }
      return showChildren ? children : null;
    }}
  </Field>
);

export default Condition;
