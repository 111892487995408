import React, {Component, Fragment} from "react";
import ReactTooltip from "react-tooltip";
import {Button, DetailsCloseIcon, ErrorHeading, Flex, Heading, Link, Loading} from "../../components/Common/Base";
import {Redirect} from "react-router-dom";
import {deleteAxios, postAxios} from "../../utils/authWithAxios";
import {AutocompleteTagInput, StockroomAssetForm} from "../../components/Common/Forms";
import {PMOrderHistory, VendorOrderHistory} from "../index";
import {
  AddItemModal,
  AdminControlsDetails,
  AttachedFiles, BackButton,
  BarcodeSection,
  Container,
  LocationSection,
  Modal,
  NoteSection,
  PriceHistoryModal,
  StatusHistoryModal,
} from "../../components/Common";

import {
  AZURE_BLOB_CONTAINER_NAME,
  AZURE_STOCKROOM_PREFIX,
  handleChangeDisplayImage,
  handleChangeWarrantyDocument,
  listBlobsInAzureContainer,
  removeDocument,
  uploadBlobsToAzureWithFileNameCheck,
} from "../../utils/azure";
import {baseDetailsState, checkForResponseError, handleErrorResponse, scrollToTop} from "../../utils/containerUtils";
import {DetailsHeader, DetailsPaneLeft, DetailsPaneRight, ImageWithDefault} from "../../components/Common/Details/Layout";
import {csvColumns, sampleData} from "./constants";
import {checkNestedKeys} from "../../utils/propUtils";
import {DescriptionText} from "../../components/Common/Modals/styled";
import {CODE_128_BARCODE} from "../../components/Common/Files/constants";
import {ASSET_DASHBOARD_ROUTE, PM_ORDERS_DASHBOARD_ROUTE, VENDORS_DASHBOARD_ROUTE} from "../../routes/constants";

/* This presents the details for a particular asset. The imported item ID is acquired via the URL. */
class StockroomAssetDetails extends Component {
  constructor(props) {
    super(props);
    this.stockID = props.match.params.stockID;
    this.state = {
      assetID: "",
      stockAssetTags: [],
      stockAssetBarcode: "",
      newStockAssetBarcode: "",
      linkedStockAssets: [],
      stockroomVendorHistory: [],
      stockroomPMOrderHistory: [],
      addedPMOrderID: "",
      addedVendorOrderID: "",
      showPriceHistoryModal: false,
      showStatusHistoryModal: false,
      showTogglePriceHistoryButton: false,
      showToggleStatusHistoryButton: false,
      showAddPMOrderModal: false,
      showAddVendorOrderModal: false,
      addItemModalSubmitting: false,
      addItemModalError: false,
      deletedStockAsset: false,
      deleteModalShowing: false,
      selectedStockStatus: "",
      deleteModalClose: () => null,
      newStockStatus: {
        name: "",
        color: "#2c3655",
        id: ""
      },
      ...baseDetailsState("stock")
    };
  }

  /* Upon mounting, fetch data for the specific asset by assetID and store the data in state. */
  componentDidMount() {
    const {stockAssetDetails, getStockroomAssets, getOrg, getLocations, getStockAssetStatuses} = this.props;
    if (stockAssetDetails) {
      this.setState({
        stockID: stockAssetDetails._id,
        assetID: stockAssetDetails.asset.id,
        stockName: stockAssetDetails.name,
        stock: stockAssetDetails.org.id,
        stockAssetTags: stockAssetDetails.tags,
        linkedStockAssets: stockAssetDetails.linked_stockroom_assets,
        showLoader: false,
      });
    }

    this.fetchStockroomAssetDetails(this.stockID).then(() => {
      if (this.state.stockOrgId) {
        getOrg(this.state.stockOrgId);
        getLocations(this.state.stockOrgId);
        getStockAssetStatuses(this.state.stockOrgId);
      }
    });
    getStockroomAssets();
    scrollToTop();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.match.params.stockID !== this.stockID) {
      this.setState({showLoader: true});
      this.fetchStockroomAssetDetails(nextProps.match.params.stockID);
      this.stockID = nextProps.match.params.stockID;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    window.onpopstate = () => {
      this.setState({showLoader: true});
      this.fetchStockroomAssetDetails(this.props.match.params.stockID);
    };
  }

  /* This function will handle the fetching of asset details. */
  fetchStockroomAssetDetails = (id) => {
    const {fetchDetails, fetchPriceHistory, fetchStatusHistory} = this.props;
    return fetchDetails(id)
      .then(async res => {
        console.log("fetchDetails:", res);
        checkForResponseError(res);
        let data = res.payload;
        let stockroom = data.stockroom_assets;
        if (!stockroom) {
          stockroom = [];
        }
        this.setState({
          stockData: data,
          stockID: data.id,
          stockUUID: data.UUID || data.uuid,
          assetID: data.asset ? data.asset.id : "",
          stockName: data.name,
          selectedStockStatus: data.status || {},
          stockOrgId: data.org.id,
          stockAssetTags: data.tags,
          linkedStockAssets: data.linked_stockroom_assets || [],
          asset: data.asset || {},
          stockroomVendorHistory: data.vendor_order_history,
          stockroomPMOrderHistory: data.pm_order_history,
          displayImage: data.display_image,
          warrantyDocument: data.warranty_document,
          editAccess: data?.permissions?.write ?? false
        });
        this.getStockroomDocuments();
        fetchPriceHistory(id).then(async res => {
          if (res) {
            this.setState({
              showTogglePriceHistoryButton: res.payload.length > 1,
            });
          }
        });
        fetchStatusHistory(id).then(async res => {
          if (res) {
            this.setState({
              showToggleStatusHistoryButton: res.payload.length > 1,
            });
          }
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        handleErrorResponse(this, error, "Stockroom Asset");
      }).then(() => {
        this.setState({
          showLoader: false
        });
      });
  };

  /* This is passed to the redux-form which returns all values passed in from state */
  submit = async values => {
    const {updateStockAssetDetails} = this.props;
    let {submitting, stockID, stockData} = this.state;
    console.log("submitting", values);
    if (!submitting) {
      this.setState({submitting: true});
      let res = await updateStockAssetDetails(stockID, values);
      console.log("submit res", res.location);
      stockData.location = res.location;
      if (stockData.status) {
        stockData.status.id = res.status?.id ?? res?.status;
      }
      this.setState({
        stockData: stockData,
        submitting: false
      });
    }
  };

  submitBarcode = async values => {
    await this.props.updateStockAssetDetails(this.state.stockID, values);
  };

  generateBarcode = () => {
    return this.props.generateBarcode(this.state.stockID, this.state.stockOrgId, CODE_128_BARCODE);
  };

  toggleDeleteStockAssetModal = () => {
    const {stockName} = this.state;
    this.setState({
      deleteModalShowing: !this.state.deleteModalShowing,
      deleteModalTitle: `Delete ${stockName || "Stockroom Asset"}?`,
      deleteModalDescription: "Deleting this stockroom asset will remove it from all PM and Vendor Orders.",
      deleteModalClose: this.toggleDeleteStockAssetModal,
      deleteModalConfirm: this.handleDeleteStockAsset,
    });
  };

  /* This function will handle deletion of a stockroom asset. */
  handleDeleteStockAsset = () => {
    this.setState({
      adminControlsSubmitting: true,
      adminControlsMessage: "",
    });
    deleteAxios({
      url: `${global.base_url}v1/stockroom_assets/${this.stockID}`
    })
      .then(res => {
        console.log(res);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Stockroom asset deletion successful.",
          deletedStockAsset: true
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Error occurred during stockroom asset delete.",
          deleteModalShowing: false
        });
      });
  };

  togglePriceHistoryModal = () => {
    this.setState(prevState => ({
      showPriceHistoryModal: !prevState.showPriceHistoryModal,
    }));
  };

  toggleStatusHistoryModal = () => {
    this.setState(prevState => ({
      showStatusHistoryModal: !prevState.showStatusHistoryModal,
    }));
  };

  toggleDeletePriceHistoryModal = (id, title) => {
    this.setState({
      deleteModalShowing: !this.state.deleteModalShowing,
      deleteModalTitle: "Delete this entry?",
      deleteModalDescription: `${title}\nDeleting this price history entry will remove it forever.`,
      deleteModalClose: () => this.toggleDeletePriceHistoryModal(-1),
      deleteModalConfirm: () => this.handleDeletePriceHistoryEntry(id),
    });
  };

  handleDeletePriceHistoryEntry = (id) => {
    const {fetchPriceHistory} = this.props;
    deleteAxios({
      url: `${global.base_url}v1/stockroom_assets/${this.stockID}/history/${id}/`
    })
      .then(res => {
        console.log(res);
        this.setState({
          showPriceHistoryModal: false,
          deleteModalTitle: "Success",
          deleteModalDescription: "",
          deleteModalClose: null,
          deleteModalConfirm: () => this.toggleDeletePriceHistoryModal(-1),
        });
        fetchPriceHistory(id);
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          deleteModalTitle: "Deletion Failed",
          deleteModalDescription: "Please try again.",
          deleteModalClose: () => this.toggleDeletePriceHistoryModal(-1),
          deleteModalConfirm: null,
        });
      });
  };

  toggleDeleteStatusHistoryModal = (id, title) => {
    this.setState({
      deleteModalShowing: !this.state.deleteModalShowing,
      deleteModalTitle: "Delete this entry?",
      deleteModalDescription: `${title}\nDeleting this status history entry will remove it forever.`,
      deleteModalClose: () => this.toggleDeleteStatusHistoryModal(-1),
      deleteModalConfirm: () => this.handleDeleteStatusHistoryEntry(id),
    });
  };

  handleDeleteStatusHistoryEntry = (id) => {
    const {fetchStatusHistory} = this.props;
    deleteAxios({
      url: `${global.base_url}v1/stockroom_assets/${this.stockID}/status_history/${id}/`
    })
      .then(res => {
        console.log(res);
        fetchStatusHistory(id);
        this.setState({
          showStatusHistoryModal: false,
          deleteModalTitle: "Success",
          deleteModalDescription: "",
          deleteModalClose: null,
          deleteModalConfirm: () => this.toggleDeleteStatusHistoryModal(-1),
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          deleteModalTitle: "Deletion Failed",
          deleteModalDescription: "Please try again.",
          deleteModalClose: () => this.toggleDeleteStatusHistoryModal(-1),
          deleteModalConfirm: null,
        });
      });
  };


  /* This function will open the add PM order modal upon clicking 'Add PM Order' button. */
  handleToggleAddPMOrderModal = () => {
    this.setState(prevState => ({
      showAddPMOrderModal: !prevState.showAddPMOrderModal,
      addItemModalError: false
    }));
  };

  handleToggleAddVendorOrderModal = () => {
    this.setState(prevState => ({
      showAddVendorOrderModal: !prevState.showAddVendorOrderModal,
      addItemModalError: false
    }));
  };

  /* Creates a new PM order with current AssetID in state */
  handleSubmitAddPMOrder = () => {
    const {stockID, assetID, stockOrgId} = this.state;

    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false
    });
    postAxios({
      url: `${global.base_url}v1/pm_orders/`,
      data: {
        org: stockOrgId,
        asset: assetID,
        stockroom_asset: stockID
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          addedPMOrderID: res.data.id,
          addItemModalSubmitting: false
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          addItemModalSubmitting: false,
          addItemModalError: true
        });
      });
  };

  handleSubmitAddVendorOrder = () => {
    const {assetID, stockOrgId, stockID} = this.state;

    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false
    });
    postAxios({
      url: `${global.base_url}v1/vendor_orders/`,
      data: {
        org: stockOrgId,
        asset: assetID,
        stockroom_asset: stockID
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          addedVendorOrderID: res.data.id,
          addItemModalSubmitting: false
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          addItemModalSubmitting: false,
          addItemModalError: true
        });
      });
  };

  getStockroomDocuments = () => {
    const {stockUUID} = this.state;
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_STOCKROOM_PREFIX}/${stockUUID}/`).then(files => {
      this.setState({
        stockFiles: files
      });
    });
  };

  handleChangeDisplayImageField = () => {
    const {stockUUID} = this.state;
    uploadBlobsToAzureWithFileNameCheck(stockUUID, AZURE_STOCKROOM_PREFIX, [this.refs.fileUploader.files[0]])
      .then(async res => {
        // Res contains filenames from uploaded files, use res[0] because only one file was uploaded
        await this.handleChangeDisplayImageNoUpload(res[0]);
        this.fetchStockroomAssetDetails(this.stockID);
      });
  };

  handleChangeDisplayImageNoUpload = async imageName => {
    await handleChangeDisplayImage(this, imageName, this.stockID, this.props.updateStockAssetField);
  };

  handleChangeWarrantyDocumentField = async documentName => {
    await handleChangeWarrantyDocument(this, documentName, this.stockID, this.props.updateStockAssetField);
  };

  handleRemoveDocument = documentName => {
    removeDocument(this, documentName, this.stockID, this.props.updateStockAssetField, this.getStockroomDocuments);
  };


  /* This funciton will handle the updating of tags within the container. */
  updateTags = tags => {
    const asset = {
      ...this.state,
      stockAssetTags: tags
    };

    this.setState({
      stockAssetTags: tags
    }, () => this.submit(asset));
    this.submit({tags});
  };

  updateLinkedStockAssets = tags => {
    console.log(tags);

    this.setState({
      linkedStockAssets: tags
    });

    let linkStocks = tags;
    if (linkStocks.length && typeof linkStocks[0] !== "number") {
      linkStocks = linkStocks.map(x => x.id);
    }
    this.submit({linked_stockroom_assets: linkStocks});
  };

  handleStockStatusSelect = data => {
    const obj = {
      name: data["value"],
      color: data["color"],
      id: data["id"]
    };
    this.setState({
      selectedStockStatus: obj
    });
    this.submit({status: obj.id || null});
  };

  handleShowCreateStockStatus = () => {
    this.setState({
      newStockStatus: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };

  handleShowEditStockStatus = () => {
    this.setState({newStockStatus: this.state.selectedStockStatus});
  };

  handleStockStatusColorChange = (color) => {
    this.setState({
      newStockStatus: {
        name: this.state.newStockStatus.name,
        color: color.hex,
        id: this.state.newStockStatus.id
      }
    });
  };
  handleStockStatusNameChange = (event) => {
    this.setState({
      newStockStatus: {
        name: event.target.value,
        color: this.state.newStockStatus.color,
        id: this.state.newStockStatus.id
      }
    });
  };
  handleCreateStockStatus = async (name, color) => {
    const res = await this.props.createStockStatus(this.state.stockOrgId, name, color);
    // if the request is successful, automatically set the status to the newly created status
    if (res && res.payload) {
      const {id, name, color} = res.payload;
      this.handleStockStatusSelect({id, color, value: name});
    }
  };
  handleUpdateStockStatus = (asset_type_id, name, color) => {
    this.props.updateStockStatus(this.state.stockOrgId, asset_type_id, name, color);
    this.setState({selectedStockStatus: this.state.newStockStatus});
  };
  handleDeleteStockStatus = () => {
    const {name, color, id} = this.state.selectedStockStatus;
    this.props.deleteStockStatus(this.state.stockOrgId, id, name, color);
    this.setState({
      selectedStockStatus: {
        name: "",
        color: "#2c3655",
        id: ""
      }
    });
  };

  render() {
    const {
      stockData,
      asset,
      assetID,
      stockID,
      stockUUID,
      showLoader,
      stockAssetBarcode,
      addedPMOrderID,
      addedVendorOrderID,
      showAddPMOrderModal,
      showAddVendorOrderModal,
      showPriceHistoryModal,
      showTogglePriceHistoryButton,

      showStatusHistoryModal,
      showToggleStatusHistoryButton,

      addItemModalSubmitting,
      addItemModalError,
      linkedStockAssets,
      adminControlsSubmitting,
      adminControlsMessage,
      stockroomVendorHistory,
      stockroomPMOrderHistory,
      errorLoadingMessage,
      deletedStockAsset,

      deleteModalShowing,
      deleteModalTitle,
      deleteModalDescription,
      deleteModalClose,
      deleteModalConfirm,

      selectedStockStatus,
      newStockStatus,
      stockFiles,
      displayImage,
      warrantyDocument,
      editAccess
    } = this.state;
    let {
      stockroomAssetList,
      stockStatusList,
      accountInfo,
      getRecentLogs,
      notes,
      fetchNotes,
      addNote,
      priceHistory,
      statusHistory,
      fetchPriceHistory,
      fetchStatusHistory,
      locationList,
      csvMessages,
      clearMessages
    } = this.props;

    if (addedPMOrderID) return <Redirect to={`${PM_ORDERS_DASHBOARD_ROUTE}/${addedPMOrderID}`}/>;
    else if (addedVendorOrderID) return <Redirect to={`${VENDORS_DASHBOARD_ROUTE}/${addedVendorOrderID}`}/>;
    else if (deletedStockAsset) return <Redirect to={ASSET_DASHBOARD_ROUTE}/>;

    let mappedStockAssets = linkedStockAssets.map(stock => {
      stockroomAssetList = stockroomAssetList.filter(item => item.id !== stock.id);
      let data = {};
      // Happens on first load
      if (stock.asset) {
        data = {
          "id": stock.id,
          "name": stock.asset.name + " - " + stock.name
        };
      } else {
        data = stock;
      }
      return data;
    });
    let stockAssetsList = stockroomAssetList.reduce((filtered, stock) => {
      if (stock.id !== stockID && stock.asset) {
        filtered.push({
          "id": stock.id,
          "value": stock.id,
          "label": stock.asset.name + " - " + stock.name
        });
      }
      return filtered;
    }, []);

    let barcodeFiles = {};
    if (stockAssetBarcode) {
      barcodeFiles["files"] = [{source: stockAssetBarcode}];
    }

    let barcodeFormat = stockData?.barcode?.format ?? false;

    return (
      <div>
        <Container>
          {stockID && !showLoader && (
            <Flex className={"item-details"} flexDirection={["column", "row"]}>
              <DetailsCloseIcon href={`#${ASSET_DASHBOARD_ROUTE}/${asset.id}`} dataTip={"Close and return to the corresponding asset."}/>
              <DetailsPaneLeft
                className={"item-details-pane-left"}
              >
                <ImageWithDefault src={displayImage} />
                <input
                  type={"file"} accept={"image/*"} id={"file"} ref={"fileUploader"}
                  onChange={this.handleChangeDisplayImageField}
                  style={{display: "none"}}/>
                <Button
                  className={"item-details-form-button"}
                  onClick={() => this.refs.fileUploader.click()}>
                  Change Display Image
                </Button>
                <div className="item-details-pane-left-text">
                  <ReactTooltip/>
                  <Heading color={"colorPrimary"}>LOCATION</Heading>
                  <LocationSection
                    blockButton
                    locationData={stockData.location || {}}
                    locationList={locationList}
                    submit={this.submit}
                    isAdmin={accountInfo.user_type <= 1}
                  />
                  <br/>
                  <Heading color={"colorPrimary"}>LINKED STOCK ASSETS</Heading>
                  <AutocompleteTagInput
                    handleUpdate={this.updateLinkedStockAssets}
                    tagList={mappedStockAssets}
                    autocompleteList={stockAssetsList}
                  />
                  <BarcodeSection
                    data={checkNestedKeys(["barcode", "data"], stockData) || ""}
                    format={barcodeFormat === false ? -1 : barcodeFormat}
                    updateBarcode={this.submitBarcode}
                    generateBarcode={this.generateBarcode}/>

                  <Heading color={"colorPrimary"}>CONTROLS</Heading>
                  {showToggleStatusHistoryButton &&
                  <Button
                    className="item-details-pane-left-control"
                    onClick={this.toggleStatusHistoryModal}>
                    See Status History
                  </Button>
                  }
                  {showTogglePriceHistoryButton &&
                  <Button
                    className="item-details-pane-left-control"
                    onClick={this.togglePriceHistoryModal}>
                    See Price History
                  </Button>
                  }
                  <Button
                    block
                    type={"primary"}
                    className="item-details-pane-left-control"
                    onClick={this.handleToggleAddPMOrderModal}>
                    Add PM Order
                  </Button>
                  <Button
                    block
                    type={"primary"}
                    className="item-details-pane-left-control"
                    onClick={this.handleToggleAddVendorOrderModal}>
                    Add Vendor Order
                  </Button>
                  <br/>

                  <AdminControlsDetails
                    deleteItem={this.toggleDeleteStockAssetModal}
                    controlsSubmitting={adminControlsSubmitting}
                    controlsMessage={adminControlsMessage}
                  />
                  <br/>
                </div>
              </DetailsPaneLeft>
              <DetailsPaneRight className="item-details-pane-right">
                <div className="item-details-pane-right-text">
                  <DetailsHeader>STOCKROOM ASSET DETAILS</DetailsHeader>
                  <h3><b>Asset Name: </b>
                    <Link color="#1e6cb6" to={`${ASSET_DASHBOARD_ROUTE}/${asset.id}`}>{asset.name || "Unnamed"}</Link>
                  </h3>
                  <h3><b>Asset Manufacturer:</b> {asset.manufacturer_name || "None"}</h3>
                  <br/>
                  <StockroomAssetForm
                    onSubmit={this.submit}
                    values={stockData}
                    editAccess={editAccess}
                    stockStatuses={stockStatusList}
                    selectedStockStatus={selectedStockStatus}
                    newStockStatus={newStockStatus}
                    handleStockStatusSelect={this.handleStockStatusSelect}
                    handleColorPickerClick={this.handleColorPickerClick}
                    handleShowCreateStockStatus={this.handleShowCreateStockStatus}
                    handleShowEditStockStatus={this.handleShowEditStockStatus}
                    handleCreateStockStatus={this.handleCreateStockStatus}
                    handleUpdateStockStatus={this.handleUpdateStockStatus}
                    handleDeleteStockStatus={this.handleDeleteStockStatus}
                    handleStockStatusColorChange={this.handleStockStatusColorChange}
                    handleStockStatusNameChange={this.handleStockStatusNameChange}
                  />
                  <br/>
                  <AttachedFiles
                    files={stockFiles}
                    displayImage={displayImage}
                    warrantyDocument={warrantyDocument}
                    azureDocumentPrefix={AZURE_STOCKROOM_PREFIX}
                    getDocuments={this.getStockroomDocuments}
                    UUID={stockUUID}
                    documentID={stockID}
                    handleChangeDisplayImage={this.handleChangeDisplayImageNoUpload}
                    handleChangeWarrantyDocument={this.handleChangeWarrantyDocumentField}
                    handleRemoveDocument={this.handleRemoveDocument}
                    refreshObject={() => this.fetchStockroomAssetDetails(stockID)}/>

                  <NoteSection
                    UUID={stockUUID}
                    accountInfo={accountInfo}
                    documentID={stockID}
                    handleSubmit={addNote}
                    refreshObject={() => fetchNotes(stockID)}
                    azureDocumentPrefix={AZURE_STOCKROOM_PREFIX}
                    getDocuments={this.getStockroomDocuments}
                    notes={notes}
                    files={stockFiles}
                    getRecentLogs={getRecentLogs}
                    title={"STOCKROOM ASSET NOTES"}
                    endpoint={"stockroom_assets"}
                    displayStatus={true}
                    itemStatus={stockData?.status?.id ?? undefined}
                  />

                  {stockroomPMOrderHistory && <PMOrderHistory history={stockroomPMOrderHistory}/>}
                  {stockroomVendorHistory && <VendorOrderHistory history={stockroomVendorHistory}/>}
                </div>
              </DetailsPaneRight>
            </Flex>
          )}
        </Container>

        {showLoader ?
          <Loading title={"Loading Stockroom Asset..."}/> :
          assetID && <BackButton prevPage={`${ASSET_DASHBOARD_ROUTE}/${assetID}`}/>
        }
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}

        {stockID &&
          <Fragment>
            <AddItemModal
              visible={showAddPMOrderModal}
              itemName="PM Order"
              closeAddItemModal={this.handleToggleAddPMOrderModal}
              submitAddItem={this.handleSubmitAddPMOrder}
              addItemSubmitting={addItemModalSubmitting}
              addItemError={addItemModalError}
              showCsv
              columns={csvColumns}
              csvMessages={csvMessages}
              clearCsvMessages={clearMessages}
              sampleData={sampleData}
            />
            <AddItemModal
              visible={showAddVendorOrderModal}
              itemName="Vendor Order"
              closeAddItemModal={this.handleToggleAddVendorOrderModal}
              submitAddItem={this.handleSubmitAddVendorOrder}
              addItemSubmitting={addItemModalSubmitting}
              addItemError={addItemModalError}
            />
            <PriceHistoryModal
              visible={showPriceHistoryModal}
              priceHistory={priceHistory}
              refresh={() => fetchPriceHistory(stockID)}
              closePriceHistoryModal={this.togglePriceHistoryModal}
              toggleDeleteModal={this.toggleDeletePriceHistoryModal}
              editAccess={accountInfo.user_type <= 2}
            />
            <StatusHistoryModal
              visible={showStatusHistoryModal}
              statusHistory={statusHistory}
              refresh={() => fetchStatusHistory(stockID)}
              closeStatusHistoryModal={this.toggleStatusHistoryModal}
              toggleDeleteModal={this.toggleDeleteStatusHistoryModal}
              editAccess={accountInfo.user_type <= 2}
            />
            <Modal
              visible={deleteModalShowing}
              title={deleteModalTitle}
              onCancel={deleteModalClose}
              onOk={deleteModalConfirm}
              isDeleteModal
            >
              <DescriptionText>{deleteModalDescription}</DescriptionText>
            </Modal>
          </Fragment>
        }
      </div>
    );
  }
}

export default StockroomAssetDetails;
