import React from "react";
import moment from "moment";
import {basePreSaveFormat} from "../Base/utils";
// Import {roleList} from './constants';


export const load = async (values) => {
  console.info("Loading...");
  let result = values;

  console.log("result", result);
  return result;
};


export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  const {name, purchase_price, purchase_price_currency, purchased_date, implemented_date} = values;
  const result = {name, purchase_price, purchase_price_currency, purchased_date, implemented_date};

  //

  return result;
};


export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  if (values.hired_date) {
    result.hired_date = moment(values.hired_date).format("YYYY-MM-DD");
  }

  return result;
};
