import styled from "@emotion/styled";
import {Box, Heading} from "../../../components/Common/Base";

export const CategoryContainer = styled(Box)`
  text-align: center;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(247,247,247,1) 72%, rgba(236,236,236,1) 100%);
  border-radius: 0 0 10px 10px;
  box-shadow: 3px 3px 5px #555;
  text-transform: uppercase;
  padding: 20px;
`;

export const CategoryHeader = styled(Heading)`
  //font-size: 1.5rem;
  font-weight: unset;
  margin: 25px;
  padding: 7px 0;
  background-color: #fafafa;
  box-shadow: 0 2px 3px grey;
`;

CategoryHeader.defaultProps = {
  fontSize: "lg"
};

export const MRUBox = styled(Box)`
  flex: 1 1 0;
  background-color: #fff;
  box-sizing: border-box;
  margin-right: 10px;
  min-width: 30%;
  color: black;
  text-transform: none;
  box-shadow: 2px 2px 3px #666;
`;

export const SectionHead = styled(Box)`
  border-radius: 10px 10px 0 0;
  height: 15px;
  background: ${props => props.gradient};
`;

