import {combineReducers} from "redux";
import {createCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {productionEntriesEndpoint} from "./constants";


export const crud = createCrud({
  endpoint: productionEntriesEndpoint,
  name: "productionEntries"
});

export const pagination = createPaginator({
  endpoint: productionEntriesEndpoint,
  name: "productionEntries",
});


export const pageActions = pagination.actions;

export const operations = crud.operations;


export default combineReducers({
  items: pagination.reducer(),
  details: crud.reducer()
});
