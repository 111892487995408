// import {combineReducers} from "redux";
// import {createCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {materialInventoryEndpoint} from "./constants";

const materialInventoryPaginator = createPaginator({
  endpoint: materialInventoryEndpoint,
  name: "materialInventory"
});


const materialInventoryActions = materialInventoryPaginator.actions;
const materialInventoryOperations = materialInventoryPaginator.operations;

export {
  materialInventoryActions,
  materialInventoryOperations
};

export default materialInventoryPaginator.reducer;
