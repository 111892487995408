import React from "react";
import PropTypes from "prop-types";
import {AddInventoryQuantityForm} from "../../Forms/PalletForm";
import {List} from "antd";
import {CloseIcon} from "../../../Common/Base/Icons";
import {inventoryType} from "../../utils/propTypes";

const SelectedInventoryListItem = ({item, onEdit, onRemove}) => {
  const handleEditQuantity = (values) => {
    onEdit({id: item.id, quantity: values.quantity});
  };

  return (
    <List.Item
      actions={[
        <AddInventoryQuantityForm
          key="add-quantity"
          // max={item.available_quantity}
          onSubmit={handleEditQuantity}/>,
        <CloseIcon key={"remove"} onClick={() => onRemove(item.id)}/>,
      ]}
    >
      <List.Item.Meta
        title={item.name}
        description={item.sku}
      />
    </List.Item>
  );
};

SelectedInventoryListItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  type: inventoryType
};

export default SelectedInventoryListItem;
