import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {Button, ButtonGroup, Popover as BasePopover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger} from "@chakra-ui/core";

const DefaultTrigger = (props) => <Button {...props}>Trigger</Button>;

const Popover = ({body, header, onConfirm, Trigger}) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const handleConfirm = () => {
    onConfirm();
    close();
  };
  return (
    <BasePopover
      isOpen={isOpen}
      initialFocusRef={cancelButtonRef}
      onOpen={open}
      onClose={close}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Trigger/>
      </PopoverTrigger>
      <PopoverContent zIndex={4}>

        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
        <ButtonGroup d="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={close} ref={cancelButtonRef}>
            Cancel
          </Button>
          <Button variantColor="teal" onClick={handleConfirm}>
            Save
          </Button>
        </ButtonGroup>
      </PopoverContent>
    </BasePopover>
  );
};

Popover.propTypes = {
  header: PropTypes.any,
  body: PropTypes.any,
  onConfirm: PropTypes.func,
  Trigger: PropTypes.element,
};

Popover.defaultProps = {
  header: "Confirmation!",
  body: "Are you sure you want to remove this?",
  onConfirm: () => console.log("confirmed"),
  Trigger: DefaultTrigger,
};

export default Popover;
