import React from "react";

import {WarehouseRoutes} from "../../../routes";

const Warehouse = props => {
  return (
    <div>
      <WarehouseRoutes/>

    </div>
  );
};


export default Warehouse;
