import React from "react";
import PropTypes from "prop-types";
import {Table} from "../../Common/Base";

const ProductInventoryTable = ({items}) => {
  const expandedRowRender = (record) => {
    console.log("expanded record", record);
    const columns = [
      {
        title: "Location",
        dataIndex: "stored_at.code",
        key: "stored_at",
        render: (text, record) => record?.stored_at?.code ?? "No Location"
      },
      {
        title: "Date",
        dataIndex: "received_at",
        key: "date",
        render: (text) => text
      },
      {
        title: "Available",
        dataIndex: "available",
        key: "available",
        width: "16%",
      },
      {
        title: "Total",
        dataIndex: "quantity",
        key: "quantity",
        width: "12%",
      },
      {
        title: "In Use",
        dataIndex: "in_use",
        key: "in_use",
        width: "12%",
      },
    ];

    const data = record.quantities.map((quantityItem, i) => {
      const childKey = `${record.id}-${i}`;
      return {
        key: childKey,
        ...quantityItem,
      };
    });
    return <Table columns={columns} dataSource={data} pagination={false}/>;
  };

  const columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Available",
      dataIndex: "available",
      key: "available",
      width: "16%",
    },
    {
      title: "Total",
      dataIndex: "quantity",
      key: "quantity",
      width: "12%",
    },
    {
      title: "In Use",
      dataIndex: "in_use",
      key: "in_use",
      width: "12%",
    },
  ];

  const data = items;
  // for(let i in items) {
  //     data.push({
  //       ...items[i],
  //
  //       key: items[i].id
  //     });
  //   }
  // }
  // for (let i = 0; i < 3; ++i) {
  //   data.push({
  //     key: i,
  //     name: 'Screem',
  //     location: 'iOS',
  //     version: '10.3.4.5654',
  //     in_use: 500,
  //     quantity: 600,
  //     available: 100,
  //     createdAt: '2014-12-24 23:12:00',
  //   });
  // }

  return (
    <Table
      className="components-table-demo-nested"
      columns={columns}
      expandable={{expandedRowRender}}
      dataSource={data}
    />
  );
};

ProductInventoryTable.propTypes = {
  items: PropTypes.array,
};

export default ProductInventoryTable;
