import actions from "./actions";
import {baseOperations} from "../../base";
import {orgOperations} from "../../orgs";
import {dateUtils, snackUtils} from "../../../utils";

const getItem = (id) => async dispatch => {
  const res = await dispatch(actions.getItem(id));

  return res.payload;
};

const getAllItems = (filter = "?expand=location,purchase_order,sales_order,destination,origin") => async dispatch => {
  const res = await dispatch(actions.getAllItems(filter));

  return res.payload;
};

const getInventory = (filter = "") => async dispatch => {
  const res = await dispatch(actions.getInventory(filter));

  return res.payload;
};

const addInventory = (id, data, multiple = false) => async dispatch => {
  console.log("inventory data", data);
  let result = [];
  if (multiple === true) {
    console.log("submitting multiple", data.length);
    for (const inventory of data) {
      console.log("data", inventory);
      const res = await dispatch(actions.addInventory(id, inventory));
      console.log("res", res);
      result.push(res.payload);
    }
  } else {
    const res = await dispatch(actions.addInventory(id, data));
    result = res.payload;
  }

  dispatch(actions.getItem(id));

  return result;
};

const addMaterial = (id, data, multiple = false) => async dispatch => {
  console.log("inventory data", data);
  let result = [];
  if (multiple === true) {
    console.log("submitting multiple", data.length);
    for (const material of data) {
      console.log("data", material);
      const res = await dispatch(actions.addMaterial(id, material));
      console.log("res", res);
      result.push(res.payload);
    }
  } else {
    const res = await dispatch(actions.addMaterial(id, data));
    result = res.payload;
  }

  dispatch(actions.getItem(id));

  return result;
};

const createItem = data => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Creating Pallet", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.createItem(data));
  baseOperations.handleCreateResponse(res, timestamp + 1, "pallet", res.payload.UUID, dispatch, () => {
    dispatch(orgOperations.getRecentLogs());
  });
  return res.payload;
};

const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Pallet", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Pallet", res.payload.name, dispatch, () => {
    dispatch(orgOperations.getRecentLogs());
  });
  return res.payload;
};

const deleteItem = id => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Deleting Pallet", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.deleteItem(id));
  console.log(res);
  dispatch(orgOperations.getRecentLogs());
  return res;
};

const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateItem(id, fieldData));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Pallet", res.payload.name, dispatch, () => {
    dispatch(getAllItems());
    dispatch(orgOperations.getRecentLogs());
  });
};

const addNote = (id, data) => async dispatch => {
  const res = await dispatch(actions.submitNote(id, data));
  baseOperations.handleSubmitNoteResponse(res, dispatch);
  return res.payload;
};

const uploadCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch, getAllItems);
};


const addInventoryFromOrder = (id, data) => async dispatch => {
  const {materials, products} = data;

  let productResult = [];
  for (const product of products) {
    console.log("product data", {pallet: id, ...product});
    const res = await dispatch(actions.addInventory(id, product));
    console.log("res", res);
    productResult.push(res.payload);
  }
  let materialResult = [];
  for (const material of materials) {
    console.log("material data", {pallet: id, ...material});
    const res = await dispatch(actions.addMaterial(id, material));
    console.log("res", res);
    materialResult.push(res.payload);
  }

  const result = {materials: materialResult, products: productResult};
  console.log("addInventoryFromOrder result", result);
  return result;
};

/**
 * Creates a pallet for an order then adds materials and products
 * @param {{pallet, materials, products}} data - Pallet and inventory data
 * @returns {object} - RSAA action
 */
const createPalletForOrder = (data) => async dispatch => {
  console.log("create pallet data", data);
  const {pallet} = data;

  let palletData = {
    ...pallet,
  };
  console.log("palletdata", palletData);

  const palletRes = await dispatch(createItem(pallet));
  console.log("palletRes", palletRes);
  if (!palletRes.id) {
    return palletRes;
  } else {
    let inventoryRes = await dispatch(addInventoryFromOrder(palletRes.id, data));
    console.log("inventory", inventoryRes);
  }
  return pallet;
};


const receivePallet = (receiveData) => async dispatch => {
  console.log("receive pallet operation data", receiveData);
  console.log("receive pallet operation dispatch", dispatch);

  const {products, materials, pallet} = receiveData;

  // product.product/material.material is the PalletInventory ID to receive
  for (const item of products) {
    const {product, ...data} = item;
    const res = await dispatch(actions.receiveInventory(pallet.id, product, data));
    console.log("receive res", res);
  }

  for (const item of materials) {
    const {material, ...data} = item;
    const res = await dispatch(actions.receiveMaterial(pallet.id, material, data));
    console.log("receive res", res);
  }
  const palletRes = await dispatch(actions.getItem(pallet.id));
  console.log("receivePalletRes", palletRes);
  return palletRes;
};

const pickupPallet = (id) => async dispatch => {
  await dispatch(actions.pickupPallet(id));
  return dispatch(getItem(id));
};

export default {
  getItem,
  getAllItems,
  createItem,
  updateItem,
  deleteItem,
  updateItemField,
  addNote,
  uploadCsv,
  getInventory,
  addInventory,
  addMaterial,
  createPalletForOrder,
  receivePallet,
  pickupPallet
};
