import React, {useState} from "react";
import PropTypes from "prop-types";
import {Form, Popconfirm, Table, Box, Flex} from "../../Base";
import EditableCell from "./EditableCell";
import {DeleteOutlined} from "@ant-design/icons";

const EditableTable = ({onSubmit, onDelete, data, columns, rowClassName, rowKey, ...rest}) => {
  const [form] = Form.useForm();
  // const [data, setData] = useState(items);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({...record});
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const deleteRecord = (record) => {
    onDelete(record.key);
  };
  const save = async key => {
    try {
      console.log("key", key);
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {...item, ...row});
        // setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        // setData(newData);
        setEditingKey("");
      }
      onSubmit(newData[index]);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const finalColumns = [
    ...columns,
    {
      title: "Actions",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Box>
            <span
              className={"save-row"}
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </span>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Box as={"span"}>Cancel</Box>
            </Popconfirm>
          </Box>
        ) : (
          <Flex flexDirection={"row"} justifyContent={"space-evenly"}>
            <Box className={"edit-row"} as={"span"} disabled={editingKey !== ""} onClick={() => edit(record)}>
              Edit
            </Box>
            <Popconfirm
              title={"Sure to delete?"}
              onConfirm={() => deleteRecord(record)}
              okButtonProps={{
                className: "confirm-delete-btn"
              }}
            >
              <Box ml={2} disabled={editingKey !== ""} className={"delete-row"}>
                <DeleteOutlined/>
              </Box>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = finalColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        size={"small"}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        scroll={{x: 300}}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName={rowClassName}
        rowKey={rowKey}
        pagination={{
          onChange: cancel,
        }}
        {...rest}
      />
    </Form>
  );
};

EditableTable.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array,
  rowClassName: PropTypes.string,
  rowKey: PropTypes.string || PropTypes.func,
};

EditableTable.defaultProps = {
  columns: [],
  rowClassName: "editable-table-row",
  rowKey: "key",
};
export default EditableTable;
