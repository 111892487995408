import React, {Fragment, useState} from "react";
import {SummaryContainer} from "../../containers";
import PropTypes from "prop-types";
import {HelpButton, TutorialModal} from "../Common/index";
import BannerOne from "images/BannerOne.png";
import {Flex, Heading, Loading, ErrorBoundary} from "../Common/Base";
import TimescaleContainer from "../../containers/HomeContainer/TimescaleContainer";

const dashboardDescription = [
  "This is your Dashboard, it reflects Maintenance, Repair and Operations information from across your Enterprise. ",
  "Add data to this dashboard in the above sections of Conmitto Manager.",
];

const Home = ({summary, errors, showLoader, summaryYear, summaryTimescale, summaryTimescaleValue, fetchOrgSummary}) => {
  const {
    assets,
    materials,
    inventory,
    pm_orders,
    vendor_orders,
    personnel,
  } = summary;

  // Make some state to control the tutorial modal
  const [showTutorialModal, toggleTutorialModal] = useState(true);
  const [showTutFromHelp, toggleTutFromHelp] = useState(false);
  const closeTutorialModal = () => {
    toggleTutorialModal(false);
    toggleTutFromHelp(false);
  };

  let anyObjs = true;

  // TODO: This is flaky
  // if (!showLoader) {
  //   if (assets.most_recent_updated &&
  //     pm_orders.most_recent_updated &&
  //     vendor_orders.most_recent_updated &&
  //     personnel.most_recent_updated) {
  //     anyObjs = (assets.most_recent_updated.length !== 0 ||
  //       pm_orders.most_recent_updated.length !== 0 ||
  //       vendor_orders.most_recent_updated.length !== 0 ||
  //       personnel.most_recent_updated.length !== 1
  //     );
  //   }
  // }

  return (
    <Fragment>
      {summary && !showLoader ?
        <Fragment>
          <div data-tip="Open a help popup" data-delay-show="1000">
            <HelpButton handleClick={() => toggleTutFromHelp(true)}/>
          </div>
          <TutorialModal
            visible={(!anyObjs && showTutorialModal && !showLoader) || (showTutFromHelp)}
            closeModal={closeTutorialModal}
            banner={BannerOne}
            headerText={"Dashboard Information"}
            descriptions={dashboardDescription}
            handleOpenAddItemModal={[closeTutorialModal, "Close"]}/>
          {errors && errors.detail &&
          <Heading
            as={"h1"}
            fontSize={"md"}
            color={"red"}
            textAlign={"center"}>
            {errors.detail === "This Action requires a premium account" ?
              "This requires a Premium Account" : errors.detail}
          </Heading>
          }
          <TimescaleContainer
            summaryYear={summaryYear}
            summaryTimescale={summaryTimescale}
            summaryTimescaleValue={summaryTimescaleValue}
            fetchOrgSummary={fetchOrgSummary}
          />

          <ErrorBoundary>
            <SummaryContainer
              title={"Assets"}
              titleLink={"assets"}
              summaryName1={"Unique"}
              summaryNumber1={assets.unique}
              summaryName2={"Individual"}
              summaryNumber2={assets.individual}
              summaryName3={"Inspect Today"}
              summaryNumber3={assets.inspect_today}
              summaryColor3={"red.500"}
              weekToDateName1={"New Unique"}
              weekToDateNumber1={assets.new_unique}
              weekToDateName2={"New Individual"}
              weekToDateNumber2={assets.new_individual}
              weekToDateName3={"Purchased"}
              weekToDateNumber3={assets.purchased}
              mostRecentUpdates={assets.most_recent_updated}
              topGradientColor={"linear-gradient(to right, rgb(0, 255, 255), rgb(0, 75, 255)"}
              hrGradientColor={"linear-gradient(to right, rgba(0, 255, 255, .5), rgba(0, 0, 255, 0.75), rgba(0, 255, 255, .5)"}
            />

            <SummaryContainer
              title={"Materials"}
              titleLink={"materials"}
              summaryName1={"Individual"}
              summaryNumber1={materials.individual}
              summaryName2={"New Individual"}
              summaryNumber2={materials.new_individual}
              summaryName3={"Inspect Today"}
              summaryNumber3={materials.inspect_today}
              summaryColor3={"red.500"}
              mostRecentUpdates={materials.most_recent_updated}
              topGradientColor={"linear-gradient(to right, rgba(253,252,45,1), rgba(255,188,6,1)"}
              hrGradientColor={"linear-gradient(to right, rgba(253,252,45,1), rgba(255,188,6,1), rgba(253,252,45,1)"}
            />

            <SummaryContainer
              title={"Inventory"}
              titleLink={"inventory"}
              summaryName1={"Individual"}
              summaryNumber1={inventory.individual}
              summaryName2={"New Individual"}
              summaryNumber2={inventory.new_individual}
              summaryName3={"Inspect Today"}
              summaryNumber3={inventory.inspect_today}
              summaryColor3={"red.500"}
              mostRecentUpdates={inventory.most_recent_updated}
              topGradientColor={"linear-gradient(to right, rgba(77,0,214,1), rgba(233,164,255,1)"}
              hrGradientColor={"linear-gradient(to right, rgba(233,164,255,1), rgba(77,0,214,1), rgba(233,164,255,1)"}
            />

            <SummaryContainer
              title={"PM Orders"}
              titleLink={"orders"}
              summaryName1={"Overdue"}
              summaryNumber1={pm_orders.overdue}
              summaryLink1={"/dashboard/orders?filterby=overdue"}
              summaryColor1={"red.500"}
              summaryName2={"Individual"}
              summaryNumber2={pm_orders.individual}
              summaryName3={"Inspect Today"}
              summaryNumber3={pm_orders.inspect_today}
              summaryLink3={"/dashboard/orders?filterby=inspect_today"}
              weekToDateName1={"New Schedules"}
              weekToDateNumber1={pm_orders.new_schedules}
              weekToDateName2={"New Individual"}
              weekToDateNumber2={pm_orders.new_individual}
              weekToDateName3={"Inspected"}
              weekToDateNumber3={pm_orders.inspected}
              mostRecentUpdates={pm_orders.most_recent_updated}
              topGradientColor={"linear-gradient(to right, rgb(255, 0, 0), rgb(75, 0, 0)"}
              hrGradientColor={"linear-gradient(to right, rgba(255, 0, 0, .5), rgba(100, 0, 0, 0.75), rgba(255, 0, 0, .5)"}
            />

            <SummaryContainer
              title={"Vendor Orders"}
              titleLink={"vendors"}
              summaryName1={"Needs Approval"}
              summaryNumber1={vendor_orders.needs_approval}
              summaryLink1={"/dashboard/vendors?filterby=needs_approval"}
              summaryColor1={"red.500"}
              summaryName2={"Individual"}
              summaryNumber2={vendor_orders.individual}
              summaryName3={"Inspect Today"}
              summaryNumber3={vendor_orders.inspect_today}
              summaryLink3={"/dashboard/vendors?filterby=occurring_today"}
              weekToDateName1={"In Progress"}
              weekToDateNumber1={vendor_orders.in_progress}
              weekToDateName2={"New Individual"}
              weekToDateNumber2={vendor_orders.new_individual}
              weekToDateName3={"Completed"}
              weekToDateNumber3={vendor_orders.completed}
              mostRecentUpdates={vendor_orders.most_recent_updated}
              topGradientColor={"linear-gradient(to right, rgb(0, 252, 0), rgb(0, 75, 0)"}
              hrGradientColor={"linear-gradient(to right, rgba(0, 252, 0, .5), rgba(0, 75, 0, 0.75), rgba(0, 252, 0, .5)"}
            />

            <SummaryContainer
              title={"Personnel"}
              titleLink={"personnel"}
              summaryName1={"Total"}
              summaryNumber1={personnel.total_personnel}
              summaryName2={"Biggest Shift"}
              summaryNumber2={personnel.biggest_shift}
              summaryName3={"New Hires"}
              summaryNumber3={personnel.new_hires}
              summaryColor3={"green"}
              weekToDateName1={"Hours Worked"}
              weekToDateNumber1={personnel.hours_worked}
              weekToDateName2={"New Individual"}
              weekToDateNumber2={personnel.new_individual}
              weekToDateName3={"Inspected"}
              weekToDateNumber3={personnel.inspected}
              mostRecentUpdates={personnel.most_recent_updated}
              topGradientColor={"linear-gradient(to right, rgb(255, 0, 255), rgb(128, 0, 128)"}
              hrGradientColor={"linear-gradient(to right, rgba(255, 0, 255, .6), rgba(100, 0, 100, 0.75), rgba(255, 0, 255, .6)"}
            />
          </ErrorBoundary>
        </Fragment>
        :
        <Flex flexWrap={"wrap"} flexDirection={"column"} align={"center"} justifyContent={"center"} className="items-loader">
          <Loading title={"Loading Summary..."}/>
        </Flex>
      }
    </Fragment>
  );
};

Home.propTypes = {
  errors: PropTypes.object,
  showLoader: PropTypes.bool,
  summary: PropTypes.shape({
    assets: PropTypes.object,
    materials: PropTypes.object,
    inventory: PropTypes.object,
    pm_orders: PropTypes.object,
    vendor_orders: PropTypes.object,
    personnel: PropTypes.object,
  }).isRequired,
};

Home.defaultProps = {
  errors: {},
  summary: {
    assets: {},
    materials: {},
    inventory: {},
    pm_orders: {},
    vendor_orders: {},
    personnel: {},
  },
};

export default Home;
