/**
 * Reducer for facilities
 * @param {string} facilitySuccessType The success type that should trigger the reduction
 * @returns {Function} The state reducer
 */
const facilitiesReducer = facilitySuccessType => (state = [], action) => {
  switch (action.type) {
  case facilitySuccessType:
    return action.payload;
  default:
    return state;
  }
};

export {facilitiesReducer};
