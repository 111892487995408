import React, {useState} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {FlexCenter, Heading, Link, Text, Column} from "../../Common/Base";
import CompanyLogo from "../../../static/img/conmitto_logo_color.png";
import ConfirmPasswordResetForm from "../Forms/ConfirmPasswordResetForm";

const ConfirmPasswordReset = props => {
  const {match, onSubmit} = props;
  const [confirmed, setConfirmed] = useState(false);

  const handleSubmit = async (values) => {
    const res = await onSubmit(values);
    if (!res.error) {
      setConfirmed(true);
    }
  };

  return (
    <FlexCenter className="login" flexDirection="column">

      <div className={"login-card"}>
        <img
          src={CompanyLogo}
          className="login-card-logo"
          alt="Conmitto logo"
        />
        <Heading className="login-card-header-text">
          CONFIRM PASSWORD RESET
        </Heading>
        {confirmed ?
          <Column alignItems={"center"}>
            <Text color={"white"}>
            Password has been reset.
            </Text>
            <Text>
              <Link to={"/"}>Login</Link>
            </Text>
          </Column>
          :
          <ConfirmPasswordResetForm
            onSubmit={handleSubmit}
            values={match.params}
          />
        }

      </div>
    </FlexCenter>
  );
};

ConfirmPasswordReset.propTypes = {
  onSubmit: PropTypes.func
};

export default withRouter(ConfirmPasswordReset);
