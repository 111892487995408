import React from "react";
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";
import {postLoadFormat} from "./utils";
import {FormDropdown, FormField} from "../../../Common/Forms/Base";
import {AddressForm} from "../index";
import {Box, Flex} from "../../../Common/Base";
import {AdaptedDatePicker, AdaptedInput, AdaptedTextarea} from "../../../Common/Base/Inputs/AdaptedInputs";
import {parseDate, parseText} from "../../../Common/Forms/Base/utils";
import {CUSTOMER, PURCHASE_ORDER, SALES_ORDER, SUPPLIER} from "../../utils/constants";
import {CreateSelectField} from "../../../Common/Fields";

const handleAddressRes = (res) => {
  console.log("handle res", res);

  let newOption = null;
  if (!res.error) {
    if (res.payload) {
      const {address} = res.payload;
      newOption = {id: address.id, value: address.id, label: address.name};
    }
  }
  console.log("new address option", newOption);
  return newOption;
};


const getContactSource = (type) => {
  return type === SALES_ORDER ? CUSTOMER : SUPPLIER;
};
const getAddressData = (order, type) => {
  const source = getContactSource(type);
  return order[source]?.addresses ?? [];
};

const buildAddressOptions = (order, type = SALES_ORDER) => {
  let addressList = getAddressData(order, type);
  return addressList.map(({address}) => ({
    id: address.id,
    value: address.id,
    label: address.name,
  }));
};

const getContactID = (order, type = SALES_ORDER) => {
  const source = getContactSource(type);
  return order[source]?.id ?? null;
};


const PalletForm = (props) => {
  const {
    onSubmit,
    validate,
    values,
    facilities,
    orderDetails,
    id,
    orderType,
    createAddress,
  } = props;

  let initialValues = postLoadFormat(values, orderDetails, orderType);

  const handleAddressSubmit = (data) => {
    const id = getContactID(orderDetails, orderType);
    return createAddress(id, data);
  };

  const addressOptions = buildAddressOptions(orderDetails, orderType);
  console.log("address options", addressOptions);

  const renderLocationFields = () => {
    if (orderType === SALES_ORDER) {
      return (
        <Flex flexDirection={"row"}>
          <FormDropdown
            label={"Destination"}
            name="destination"
            selectClassName={"address-dropdown"}
            component={CreateSelectField}
            form={AddressForm}
            options={addressOptions}
            onSubmit={handleAddressSubmit}
            text={{
              title: "New Customer Address",
              tooltip: "Create a new Destination Address",
            }}
            wrapperConfig={{pr: 2, pb: 2}}
            handleResponse={handleAddressRes}
          />

          <Field name={"location"} component={"input"} type={"hidden"}/>

        </Flex>
      );
    }
    if (orderType === PURCHASE_ORDER) {
      return (
        <Flex flexDirection={"column"}>
          <FormDropdown
            label={"Origin"}
            name="origin"
            selectClassName={"origin-address-dropdown"}
            component={CreateSelectField}
            form={AddressForm}
            options={addressOptions}
            onSubmit={handleAddressSubmit}
            text={{
              title: "New Supplier Address",
              tooltip: "Create a new Origin Address",
            }}
            wrapperConfig={{pr: 2, pb: 2}}
            handleResponse={handleAddressRes}
          />

          <FormDropdown
            label={"Destination Facility"}
            name="location"
            options={facilities}
          />

        </Flex>

      );
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form
          id={id}
          className={"pallet-details-form"}
          onSubmit={handleSubmit}>
          {/* <AutoSave*/}
          {/*  debounce={1000}*/}
          {/*  save={onSubmit}*/}
          {/*  isSubmitting={submitting}*/}
          {/*  debounced={["sku"]}*/}
          {/*  preSaveFormat={preSaveFormat}*/}
          {/*  postLoadFormat={postLoadFormat}*/}
          {/*  formInvalid={invalid}*/}
          {/* />*/}
          <Flex flexDirection={"column"}>
            {renderLocationFields()}
            <FormField
              component={AdaptedInput}
              label={"SKU"}
              type={"text"}
              name={"sku"}
            />

            <Field name={"reference"} type={"hidden"} component={"input"}/>
            <Field name={"purchase_order"} type={"hidden"} component={"input"}/>
            <Field name={"org"} type={"hidden"} component={"input"}/>
            <Field name={"status"} type={"hidden"} component={"input"}/>

            <FormField
              label={"Expected Date"}
              placeholder={"Expected Date"}
              type="text"
              name="expected_date"
              component={AdaptedDatePicker}
              parse={parseDate}
            />

            <Box pt={3}/>
            <FormField
              label={"Special Instructions"}
              placeholder={"Instructions..."}
              type="text"
              name="instructions"
              component={AdaptedTextarea}
              size="small"
              parse={parseText}
              format={parseText}
            />

          </Flex>
        </form>
      )}
    />
  );
};


PalletForm.propTypes = {
  onSubmit: (values) => null,
  validate: PropTypes.func,
  values: PropTypes.object,
  handleManufacturerClick: PropTypes.func,
  createAddress: PropTypes.func,
  locationList: PropTypes.array,
  isAdmin: PropTypes.bool,
  locationData: PropTypes.any,
  setLocationData: PropTypes.func,
  forOrder: PropTypes.bool,
  id: PropTypes.string,
  facilities: PropTypes.array,
  orderDetails: PropTypes.object,
  orderType: PropTypes.oneOf([SALES_ORDER, PURCHASE_ORDER]).isRequired,
};

PalletForm.defaultProps = {
  values: {},
  editAccess: false,
  forOrder: false,
  id: "pallet-details-form",
  orderType: PURCHASE_ORDER,
};

export default PalletForm;
