import React, {Component} from "react";
import {connect} from "react-redux";
import {
  authSelectors,
  baseSelectors,
  orgOperations,
  orgSelectors,
  stockroomAssetActions,
  stockroomAssetOperations,
  stockroomAssetSelectors,
} from "../../store";
import StockroomAssetDetails from "./StockroomAssetDetails";

/* This presents the details for a particular asset. The imported item ID is acquired via the URL. */
class StockroomAssetDetailsContainer extends Component {
  render() {
    return (
      <StockroomAssetDetails {...this.props} />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  stockroomAssetList: baseSelectors.refreshAllItems(state.stockroomAssets),
  stockStatusList: orgSelectors.refreshStockStatuses(state.orgs),
  notes: baseSelectors.refreshNotes(state.stockroomAssets, ownProps.match.params.stockID),
  locationList: orgSelectors.refreshLocations(state.orgs),
  priceHistory: stockroomAssetSelectors.refreshPriceHistory(state.stockroomAssets, ownProps.match.params.stockID),
  statusHistory: stockroomAssetSelectors.refreshStatusHistory(state.stockroomAssets, ownProps.match.params.stockID),
  csvMessages: baseSelectors.refreshMessages(state.stockroomAssets)
});

const mapDispatchToProps = dispatch => ({
  fetchDetails: (id) => dispatch(stockroomAssetActions.getItem(id)),
  getLocations: (id) => dispatch(orgOperations.getLocations(id)),
  updateStockAssetDetails: async (id, data) => dispatch(stockroomAssetOperations.updateItem(id, data)),
  updateStockAssetField: async (id, fieldData) => dispatch(stockroomAssetOperations.updateItemField(id, fieldData)),
  addNote: async (id, data) => dispatch(await stockroomAssetOperations.addNote(id, data)),
  getStockroomAssets: () => dispatch(stockroomAssetOperations.getAllItems()),
  getOrg: (id) => dispatch(orgOperations.getItem(id)),
  getStockAssetStatuses: id => dispatch(orgOperations.getStockAssetStatuses(id)),
  createStockStatus: (org_id, name, color) => dispatch(orgOperations.createStockStatus(org_id, name, color)),
  updateStockStatus: (org_id, asset_type_id, name, color) => dispatch(orgOperations.updateStockStatus(org_id, asset_type_id, name, color)),
  deleteStockStatus: (org_id, asset_type_id) => dispatch(orgOperations.deleteStockStatus(org_id, asset_type_id)),
  fetchNotes: (id) => dispatch(stockroomAssetActions.getItemNotes(id)),
  fetchPriceHistory: (id) => dispatch(stockroomAssetActions.getItemPriceHistory(id)),
  fetchStatusHistory: (id) => dispatch(stockroomAssetActions.getItemStatusHistory(id)),
  clearMessages: () => dispatch(stockroomAssetActions.clearMessages()),
  generateBarcode: (objId, orgId, barcodeType) =>
    dispatch(stockroomAssetActions.generateBarcode({objId, orgId, barcodeType})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockroomAssetDetailsContainer);
