import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {Link} from "Base";
import SignupContainer from "./Signup/SignupContainer";
import CompanyLogo from "../../static/img/conmitto_logo_color.png";
import {Button, FlexCenter, Heading} from "../../components/Common/Base";

/* This is the Login container where users initially arrive. Other pages
aren't accessible until a user has registered and logged in successfully. */
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginEmail: "",
      loginPassword: "",
      loginErrorMessage: "",
      loggedIn: false,
      showSignUp: false,
      submittingLogin: false,
      approved: false
    };
  }

  componentDidMount() {
    this.props.getAllOrgs();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {accountInfo, authenticated} = this.props;
    if (authenticated && accountInfo && accountInfo.personnel) {
      const {approved, loginErrorMessage} = this.state;
      if (!approved && loginErrorMessage === "") {
        if (accountInfo.personnel.needs_approval) {
          this.setState({loginErrorMessage: "You must be approved by the administrator to log in."});
        } else {
          this.setState({approved: true});
        }
      }
    }
  }

  handleLoginFormSubmit = async e => {
    e.preventDefault();

    // Login form validation
    if (this.state.loginEmail.length === 0 || this.state.loginPassword.length === 0) {
      this.setState({
        loginErrorMessage: "Both fields are required."
      });
      return;
    }
    this.setState({
      loginErrorMessage: "",
      submittingLogin: true
    });
    const {loginEmail, loginPassword} = this.state;
    this.handleLoginSubmit(loginEmail, loginPassword);
  };

  /* This will handle login submission and authentication. If a user is successful
  in loggin in, they will be ushered to the dashboard. Otherwise, an error message
  will be shown. */
  handleLoginSubmit = async (loginEmail, loginPassword) => {
    const {onSubmit} = this.props;
    const accountInfo = await onSubmit(loginEmail, loginPassword);
    if (accountInfo) {
      if (accountInfo.personnel.needs_approval) {
        this.setState({loginErrorMessage: "You must be approved by the administrator to log in."});
      } else {
        this.setState({approved: true});
      }
    }
    this.setState({showSignUp: false});
  };

  /* This will display the sign up page when the user clicks sign up under the log in form. */
  handleOpenSignUpModal = () => {
    this.setState({
      showSignUp: true
    });
  };

  /* This will close the sign up modal upon clicking the X on the top right. */
  handleCloseSignupModal = () => {
    this.setState({
      showSignUp: false
    });
  };

  /* This will render the company logo, email and password inputs, as well as a submit button for
  login. Upon login, the program will redirect to the dashboard. */
  render() {
    const {showSignUp, loginErrorMessage, approved} = this.state;
    const {authenticated, authErrorMessage, submittingLogin, allOrgs, errors} = this.props;

    if (authenticated && approved) {
      const tempEndpoint = localStorage.getItem("oldHistory");
      const endpoint = tempEndpoint ? tempEndpoint : "/dashboard";
      return <Redirect to={endpoint}/>;
    }


    return (
      <FlexCenter className="login" flexDirection="column">
        {showSignUp ?
          <SignupContainer directLogin={this.handleLoginSubmit} closeSignupModal={this.handleCloseSignupModal} orgList={allOrgs}/>
          :
          <div className="login-card">
            <img
              src={CompanyLogo}
              className="login-card-logo"
              alt="Conmitto logo"
            />
            <Heading className="login-card-header-text">LOG IN</Heading>
            {authErrorMessage || loginErrorMessage ? (
              <div className="login-card-msg-error"> {loginErrorMessage ? loginErrorMessage : authErrorMessage}</div>
            ) : (
              <div/>
            )}
            { (errors.errBool && !authErrorMessage) && (
              <div className="login-card-msg-error">{ errors.message }</div>
            ) }

            <div className="login-card-form">
              <form onSubmit={this.handleLoginFormSubmit}>
                <input
                  className="login-card-input"
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={e => this.setState({loginEmail: e.target.value})}
                />
                <input
                  className="login-card-input"
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={e => this.setState({loginPassword: e.target.value})}
                />
                <Button
                  className="login-card-button"
                  htmlType="submit"
                  // type={'pr'}
                  value="Submit"
                  size={"large"}
                  loading={submittingLogin}>
                  Submit
                </Button>
              </form>
              <p className="login-card-link" onClick={this.handleOpenSignUpModal}>
                Sign Up
              </p>

            </div>
            <FlexCenter>
              <Link to={"/reset"}>
              Forgot your password?
              </Link>
            </FlexCenter>
          </div>
        }
      </FlexCenter>

    );
  }
}

export default Login;
