import React from "react";
import PropTypes from "prop-types";
import {Flex} from "Base";
import PalletForm from "./PalletForm";
import {SelectingInventoryList} from "../../Lists";
import {MATERIAL_INVENTORY, PRODUCT_INVENTORY, PURCHASE_ORDER, SALES_ORDER} from "../../utils/constants";
// This component combines a PalletForm with Inventory selection for Products and materials.
// The Inventory is provided by the products/materials in orderDetails.
const OrderPalletForm = ({onSubmit, orderDetails, onEdit, id, personnel, facilities, orderType, formatEditValues, createAddress}) => {
  const {items, materials} = orderDetails;

  return (
    <Flex flexDirection={"column"}>
      <PalletForm
        id={id}
        onSubmit={onSubmit}
        personnel={personnel}
        facilities={facilities}
        orderDetails={orderDetails}
        orderType={orderType}
        createAddress={createAddress}
      />

      <SelectingInventoryList
        title={"Select Product Quantities on Pallet"}
        data={items}
        onEdit={(values) => onEdit(values, PRODUCT_INVENTORY)}
        maxField={"available"}
        fromOrder
        formatEditValues={formatEditValues}
      />

      <SelectingInventoryList
        title={"Select Material Quantities on Pallet"}
        data={materials}
        onEdit={(values) => onEdit(values, MATERIAL_INVENTORY)}
        maxField={"available"}
        fromOrder
        type={MATERIAL_INVENTORY}
        formatEditValues={formatEditValues}
      />
    </Flex>
  );
};

OrderPalletForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  orderDetails: PropTypes.object,
  errors: PropTypes.object,
  id: PropTypes.string,
  personnel: PropTypes.array,
  facilities: PropTypes.array,
  orderType: PropTypes.oneOf([SALES_ORDER, PURCHASE_ORDER]).isRequired,
  formatEditValues: PropTypes.func,
  createAddress: PropTypes.func.isRequired
};

OrderPalletForm.defaultProps = {
  id: "order-pallet-form",
  formatEditValues: (item, values) => ({id: item.id, quantity: values.quantity})
};

export default OrderPalletForm;
