// Purchase Order Details Header
import React from "react";
import PropTypes from "prop-types";
import {Descriptions, DirectLink} from "Base";
import PurchaseOrderForm from "../../Forms/PurchaseOrderForm/PurchaseOrderForm";
import {PageHeader} from "../../../Common/Details";

const DetailsHeader = (props) => {
  const {
    data,
    onUpdate,
    onDelete,
    updateBol,
    actions,
    createSupplier,
    suppliers,
    personnel,
  } = props;

  const renderDescriptions = () => (
    <Descriptions
      size="small"
      column={{sm: 2, xs: 1}}
      className={"page-details"}>
      <Descriptions.Item label={"Total"} span={2}>
        {data.total_cost}
      </Descriptions.Item>
      <Descriptions.Item label="Reference #" span={2}>{data.reference}</Descriptions.Item>
      <Descriptions.Item label={"Supplier"}>
        {data?.supplier?.name ?? "None"}
      </Descriptions.Item>
      <Descriptions.Item label={"Purchaser"}>
        {data?.purchaser?.name ?? "None"}
      </Descriptions.Item>
      <Descriptions.Item label={"Order"}>
        {data?.order ? <DirectLink href={data.order} onClick={e => e.stopPropagation()}>View Order Document</DirectLink> : "None"}
      </Descriptions.Item>
      <Descriptions.Item label={"Invoice"}>
        {data?.invoice ? <DirectLink href={data.invoice} onClick={e => e.stopPropagation()}>View Invoice</DirectLink> : "None"}
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <PageHeader
      data={data}
      DetailsForm={PurchaseOrderForm}
      formProps={{
        suppliers: suppliers,
        personnel: personnel,
        createSupplier: createSupplier,
        updateBol: updateBol
      }}
      onUpdate={onUpdate}
      onDelete={onDelete}
      renderDescription={renderDescriptions}
      actions={actions}
      formID={"purchase-order-form"}
      text={{
        title: "Purchase Order Details",
        name: "Purchase Order",
        shortName: "order",
        subTitle: data.status_display
      }}
      editConfig={{
        formID: "purchase-order-form",
        hideMeta: true,
        metaFields: [{field: "instructions", span: 2, label: "Special Instructions"}]
      }}
      deleteConfig={{
        confirmText: "Deleting this will remove all items assigned to it.",
        redirectUrl: "/dashboard/warehouse/purchase_orders",
      }}
    >

    </PageHeader>
  );
};


DetailsHeader.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  updateBol: PropTypes.func,
  actions: PropTypes.array,
  createSupplier: PropTypes.func,
  suppliers: PropTypes.array,
  personnel: PropTypes.array
};

DetailsHeader.defaultProps = {
  updateBol: () => null
};

export default DetailsHeader;
