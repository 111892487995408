import React from "react";
import PropTypes from "prop-types";
import {EditableTable} from "../../Common/Tables";
import {templateColumns} from "./constants";

const ProductTemplateTable = ({data, onSubmit, onDelete}) => {
  const dataSource = data?.map(item => ({key: item.id, ...item})) ?? [];

  return (
    <EditableTable
      className={"template-material-table"}
      rowClassName={"template-material-row"}
      rowKey={"id"}
      data={dataSource}
      onSubmit={onSubmit}
      onDelete={onDelete}
      columns={templateColumns}
    />
  );
};

ProductTemplateTable.propTypes = {
  data: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ProductTemplateTable;
