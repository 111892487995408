import React from "react";
import PropTypes from "prop-types";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index";
import {Edit, ImportOutlined, Plus} from "../../Base/Icons";
import {Action, Fab} from "react-tiny-fab";
/* This displays an icon on the bottom right of the UI for adding an asset. */

// actions is an array of objects
// {text: String, onClick: func, color: string, icon: string/element}

// const renderIcon = (Icon) => <Icon/>

const renderIcon = (icon) => {
  if (icon === "import") {
    return <ImportOutlined/>;
  }
  return <Edit/>;
};

const ButtonBar = ({handleClick, actions, mainText}) => {
  const event = actions.length > 0 ? "hover" : "click";
  return (

    <span className="button-bar">
      <Fab
        mainButtonStyles={{backgroundColor: "#1e6cb6"}}
        // actionButtonStyles={actionButtonStyles}
        event={event}
        icon={<Plus className="button-bar-plus"/>}
        alwaysShowTitle={mainText === ""}
        onClick={handleClick}
        text={mainText}
      >
        {actions.map(action => (
          <Action
            key={action.key}
            text={action.text}
            onClick={action.onClick}
            className={"button-bar-action"}
          >
            {renderIcon(action.icon)}
          </Action>
        ))}
      </Fab>
    </span>
  );
};

ButtonBar.propTypes = {
  handleClick: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    renderIcon: PropTypes.func,
  })),
  mainText: PropTypes.string,
};

ButtonBar.defaultProps = {
  actions: [],
  mainText: "",
};
export default ButtonBar;
