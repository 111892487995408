import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {Field} from "react-final-form";
import {Label, LabelText} from "./styled";

export const Dropdown = props => {
  const {onChange, value, options, id, selectClassname, ...rest} = props;
  const handleChange = (values) => {
    return values.value;
  };
  return (
    <div>
      <Select
        className={selectClassname}
        onChange={handleChange}
        defaultValue={""}
        {...rest}
      />
    </div>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  selectClassName: PropTypes.string,

};

Dropdown.defaultProps = {
  selectClassName: "item-details-form-dropdown",
};

// Const adapt /* ⬅️ this is a HOC */ = Component => ({input, meta: {valid}, ...rest}) =>
//   <Component {...input} {...rest} valid={valid}/>;

// const AdaptedDropdown = adapt(Dropdown);

export const ReactSelectAdapter = ({input, defaultValue, ...rest}) => {
  return <Select className="item-details-form-dropdown" {...input} {...rest} searchable/>;
};


const FormDropdown = props => {
  const {
    label,
    name,
    options,
    component
  } = props;

  const format = (value, name) => {
    if (value && typeof value === "number") {
      return options.find(el => el.id === value);
    }
    // console.log('options', options)
    // console.log('format', value)
    let found = options.find(item => item.value === value || item.value === item.id || item.value === value);
    return (found ? {
      value: found.id || found.value,
      label: found.name || found.label,
    } : "");
  };

  const parse = (value, name) => {
    return (value ? (value.value || value.id || value) : "");
  };
  return (
    <Fragment>
      {
        label ?
          <Label htmlFor={name} mb={"30px"}>
            <LabelText>{label}: </LabelText>
            <Field
              name={name}
              component={component}
              options={options}
              parse={parse}
              format={format}
              {...props}
            />
          </Label>
          :
          <Label>
            <Field
              name={name}
              component={component}
              options={options}
              parse={parse}
              format={format}
              {...props}
            />
          </Label>
      }
    </Fragment>
  );
};


FormDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  inputType: PropTypes.string,
  display: PropTypes.string,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  selectClassName: PropTypes.string,
  component: PropTypes.any
};

FormDropdown.defaultProps = {
  inputType: "input",
  display: "block",
  component: ReactSelectAdapter
};

export default FormDropdown;
