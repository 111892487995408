import {createCrud} from "../base/crud";
import {combineReducers} from "redux";

const endpoint = "v1/pm_orders/";
const scheduleEndpoint = "v1/scheduled_pm_orders/";

const pmOrdersModule = createCrud({
  endpoint,
  name: "pmOrders",
  withPagination: true,
  withCsv: true,
  withNotes: true,
  withBulkDelete: true
});

export const scheduledPMOrderModule = createCrud({
  endpoint: scheduleEndpoint,
  nestedEndpoint: "schedules/",
  name: "schedulePMOrders",
  withPagination: true
});

const scheduleActions = scheduledPMOrderModule.actions;
const scheduleOperations = scheduledPMOrderModule.operations;

const {
  actions,
  operations
} = pmOrdersModule;

export {actions, operations, scheduleActions, scheduleOperations};

export default combineReducers({
  pmOrders: pmOrdersModule.reducer(),
  scheduledPm: scheduledPMOrderModule.reducer()
});
