import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
// import {FormField} from "Common/Forms/Base";


const LocationForm = props => {
  const {
    onSubmit,
    values,
  } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      render={({
        handleSubmit,
        pristine,
        invalid,
        form,
        submitting,
        values,
      }) => (
        <form
          onSubmit={() => {}}>
        </form>
      )}
    />
  );
};


LocationForm.propTypes = {
  /**
   * Form submission callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Form data
   */
  values: PropTypes.object
};

export default LocationForm;
