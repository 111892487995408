import React from "react";
import columns from "./columns";
import {Table} from "../../Common/Tables/Table";
import {vendorsTableData} from "./data";


const VendorsTable = (props) => {
  return (
    <Table
      handleFieldChange={props.handleFieldChange}
      columns={columns}
      tableData={vendorsTableData}
      {...props}
    />
  );
};

export {VendorsTable};
