import React, {Fragment} from "react";
import Popover, {ArrowContainer} from "react-tiny-popover";
import PropTypes from "prop-types";

export default function ProgressPoint({accomplished, pointName, icon, isActiveIssue}) {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <Fragment>
      <Popover
        isOpen={isOpen}
        position={["bottom", "right"]}
        padding={10}
        content={({position, targetRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={"black"}
            arrowSize={10}
            arrowStyle={{opacity: 1}}>
            <div style={{backgroundColor: "black", opacity: 1, color: "white", padding: "4px"}}>
              {pointName}
            </div>
          </ArrowContainer>
        )}
      >
        <span
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className={`progress-point-${isActiveIssue && accomplished ? "issue-active" : (accomplished ? "accomplished" : "pending")}`}>
          {accomplished && icon}
        </span>
      </Popover>
    </Fragment>
  );
}

ProgressPoint.propTypes = {
  isActiveIssue: PropTypes.bool
};
