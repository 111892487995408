import actionTyper from "redux-actiontyper";

const createPaginationActionTypes = (name) => {
  const {
    CHANGE_VIEW,
    SET_ITEMS_CURRENT_PAGE,
    GET_PAGE_REQUEST,
    GET_PAGE_SUCCESS,
    GET_PAGE_FAILURE,
    GET_ITEM_SUCCESS
  } = actionTyper(`@@${name}/`);
  return {
    CHANGE_VIEW,
    SET_ITEMS_CURRENT_PAGE,
    GET_PAGE_REQUEST,
    GET_PAGE_SUCCESS,
    GET_PAGE_FAILURE,
    GET_ITEM_SUCCESS
  };
};

export default createPaginationActionTypes;
