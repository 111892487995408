import {
  updatedAtColumn,
  createdAtColumn,
  viewMoreColumn,
  numberOfNotesColumn,
  manufacturerColumn,
  nameColumn
} from "../../../../utils/tables";
import {numberSorter, stringSorter} from "../../../../utils/sort";

// Configure the columns of the table
const columns = [
  nameColumn({width: 100}),
  manufacturerColumn({width: 150}),
  {
    title: "SKU",
    dataIndex: "sku",
    width: 100,
    editable: true,
    searchable: true,
    sorter: stringSorter("sku")
  },
  {
    title: "Price",
    dataIndex: "price",
    width: 100,
    editable: true,
    sorter: numberSorter("price")
  },
  numberOfNotesColumn({width: 100}),
  {
    title: "Quantity",
    dataIndex: "quantity",
    width: 100,
    sorter: stringSorter("quantity")
  },
  createdAtColumn({width: 100}),
  updatedAtColumn({width: 100}),
  viewMoreColumn("warehouse/inventory"),
];

export default columns;
