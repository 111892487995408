import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Select from "react-select";
import {range} from "lodash-es";
import {InputWrapper, Label, Row, RowLabelWrapper, RowOptions} from "../../../styles/styled";

import {numericalSelectFieldHandler} from "../../../utils/numericalFieldHandler";
import selectFieldHandler from "../../../utils/selectFieldHandler";

import {MONTHS} from "../../../constants";
import translateLabel from "../../../utils/translateLabel";

const RepeatYearlyOn = ({
  id,
  mode,
  on,
  hasMoreModes,
  handleChange,
  translations
}) => {
  const daysInMonth = moment(on.month, "MMM").daysInMonth();
  const isActive = mode === "on";


  const monthOptions = MONTHS.map(month => ({
    value: month,
    label: translateLabel(translations, `months.${month.toLowerCase()}`),
    name: "repeat.yearly.on.month"
  }));

  const dayOptions = range(0, daysInMonth).map((i) => ({
    value: i + 1,
    label: i + 1,
    name: "repeat.yearly.on.day"
  }
  ));
  return (
    <Row className={`form-group ${!isActive && "opacity-50"}`}>
      <RowLabelWrapper>

        {hasMoreModes && (
          <input
            id={id}
            type="radio"
            name="repeat.yearly.mode"
            aria-label="Repeat yearly on"
            value="on"
            checked={isActive}
            onChange={handleChange}
          />
        )}

        <Label>
          {translateLabel(translations, "repeat.yearly.on")}
        </Label>
      </RowLabelWrapper>

      <RowOptions>
        <InputWrapper width={5 / 16}>
          <Select
            id={`${id}-month`}
            name="repeat.yearly.on.month"
            aria-label="Repeat yearly on month"
            className="form-control"
            value={{value: on.month, label: translateLabel(translations, `months.${on.month.toLowerCase()}`)}}
            isDisabled={!isActive}
            onChange={selectFieldHandler(handleChange)}
            options={monthOptions}
          />
        </InputWrapper>

        <InputWrapper width={1 / 4}>
          <Select
            id={`${id}-day`}
            name="repeat.yearly.on.day"
            aria-label="Repeat yearly on a day"
            className="form-control"
            value={{value: on.day, label: on.day}}
            options={dayOptions}
            isDisabled={!isActive}
            onChange={numericalSelectFieldHandler(handleChange)}
          />

        </InputWrapper>
      </RowOptions>
    </Row>
  );
};
RepeatYearlyOn.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  on: PropTypes.shape({
    month: PropTypes.oneOf(MONTHS).isRequired,
    day: PropTypes.number.isRequired
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default RepeatYearlyOn;
