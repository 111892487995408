import React from "react";
import PropTypes from "prop-types";
import {Flex} from "Base";
import {Radio} from "antd";

import PalletForm from "./PalletForm";


import {MATERIAL, MATERIAL_INVENTORY, PRODUCT, PRODUCT_INVENTORY, STORE, UNPACK} from "../../utils/constants";
import {SelectingInventoryList} from "../../Lists/ReceiveSelectLists";


// This component combines a PalletForm with Inventory selection for Products and materials
// The Inventory is provided by the products/materials in palletDetails.
const ReceivePalletForm = ({
  receiveAction, setReceiveAction, onSubmit, palletDetails,
  onEdit, id, personnel,
  facilities, locationPagination, getLocations, createLocation,
}) => {
  const {product_inventory, material_inventory, location} = palletDetails;

  const onActionChange = (e) => {
    setReceiveAction(e.target.value);
  };

  return (
    <Flex flexDirection={"column"}>
      <PalletForm
        id={id}
        onSubmit={onSubmit}
        personnel={personnel}
        facilities={facilities}
        palletDetails={palletDetails}
      />

      <Radio.Group value={receiveAction} onChange={onActionChange}>
        <Radio value={UNPACK} id={"unpack-option"}>
          Unpack
        </Radio>
        <Radio value={STORE} id={"store-option"}>
          Store
        </Radio>
      </Radio.Group>

      <SelectingInventoryList
        title={"Select Product Quantities on Pallet"}
        data={product_inventory}
        onEdit={(values) => onEdit(values, PRODUCT_INVENTORY)}
        maxField={"available"}
        action={receiveAction}
        location={location}
        type={PRODUCT}
        locationPagination={locationPagination}
        getLocations={getLocations}
        createLocation={createLocation}
      />

      <SelectingInventoryList
        title={"Select Material Quantities on Pallet"}
        data={material_inventory}
        onEdit={(values) => onEdit(values, MATERIAL_INVENTORY)}
        maxField={"available"}
        action={receiveAction}
        location={location}
        type={MATERIAL}
        locationPagination={locationPagination}
        getLocations={getLocations}
        createLocation={createLocation}
      />
    </Flex>
  );
};

ReceivePalletForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  palletDetails: PropTypes.object,
  errors: PropTypes.object,
  locationPagination: PropTypes.object,
  getLocations: PropTypes.func,
  receiveAction: PropTypes.string,
  setReceiveAction: PropTypes.func,
  createLocation: PropTypes.func,
};

ReceivePalletForm.defaultProps = {
  id: "order-pallet-form",
};

export default ReceivePalletForm;
