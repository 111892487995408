import moment from "moment";

export const USER_TYPES = [
  "Admin",
  "Manager",
  "Lead",
  "Technician",
  "Contractor",
  "Service Provider"
];

/**
 * Utility function to format the PERSONNEL table data
 * @param {Array} data The list of data to parse
 * @returns {Array} The list of parsed items
 */
export const personnelTableData = (data) => {
  return data.map(item => ({
    key: item.id,
    name: item.name || "\xa0",
    title: item.title?.name ?? "\xa0",
    skills: item.skills.map(item => ({name: item, color: "green"}))
      .concat(item.desired_skills.map(item => ({name: item, color: "red"}))),
    number_notes: item?.notes?.length ?? 0,
    user_type: USER_TYPES[item.user.user_type - 1],
    created_at: moment(item.created_at).format("LLL"),
    updated_at: moment(item.updated_at).format("LLL")
  }));
};
