import actionTyper from "redux-actiontyper";

const {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,
  FILTER_LIST
} = actionTyper("@@scheduled_pm_orders/");


export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,

  GET_ITEM_NOTES_REQUEST,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAILURE,

  SUBMIT_NOTE_REQUEST,
  SUBMIT_NOTE_FAILURE,
  SUBMIT_NOTE_SUCCESS,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,
  FILTER_LIST
};
