import React from "react";

import {Menu, SubMenu, Link} from "../Base";

export const renderSubMenuItem = (item, onTitleClick) => {
  const {key, title, items} = item;
  if (item.submenu) {
    return (
      <SubMenu
        className={`navigation-submenu-item ${key}-navigation`}
        key={key}
        onTitleClick={() => onTitleClick(key)}
        title={
          <span>
            {/* <UserOutlined/>*/}
            {title}
          </span>
        }
      >
        {items.map(({key, title, to}) => (
          <Menu.Item
            className={`navigation-submenu-item-dropdown ${key}-navigation`}
            key={key}
          >
            <Link to={to}>{title}</Link>
          </Menu.Item>
        ))}
      </SubMenu>
    );
  }
  return (
    <Menu.Item
      className={`navigation-submenu-item ${key}-navigation`}
      key={key}
    >
      <Link to={item.to}>{title}</Link>
    </Menu.Item>
  );
};
