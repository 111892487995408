import React, {useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Flex, IconButton} from "../Base";
import Modal from "./";
import {DateText, HistoryListWrapper, HistoryText} from "./styled";

const StatusHistoryModal = (props) => {
  const [initStatus, updateInitStatus] = useState(false);
  const {
    statusHistory,
    refresh,
    closeStatusHistoryModal,
    toggleDeleteModal,
    editAccess,
    visible
  } = props;

  let statusList = [];

  const handleInit = () => {
    updateInitStatus(true);
    refresh();
  };

  if (initStatus === false) {
    handleInit();
  }

  statusList = statusHistory && statusHistory.length ? statusHistory.map(function (entry, i, arr) {
    const date = `${moment(entry.date).format("MMM Do YYYY")}: `;
    const long_date = moment(entry.date).format("MM/DD/YYYY, h:mma");

    const status = `${entry.status ? entry.status.name : "None"}`;
    const text = date + status;
    return (
      <HistoryListWrapper key={entry.id}>
        <DateText flexGrow={0.5} className={"history-entry-date"} title={long_date}>
          {date}
        </DateText>
        <HistoryText flexGrow={1}>{status}</HistoryText>
        {editAccess &&
        <IconButton
          variant="link"
          aria-label="Delete entry"
          icon="delete"
          disabled={i === 0}
          onClick={() => toggleDeleteModal(entry.id, text)}/>
        }
      </HistoryListWrapper>
    );
  }) : [];

  return (
    <Modal
      visible={visible}
      onCancel={closeStatusHistoryModal}
      showCancelButton={false}
      title={"Status History"}>
      <Flex flexDirection={"column"}>
        {statusList}
      </Flex>
    </Modal>
  );
};

StatusHistoryModal.propTypes = {
  statusHistory: PropTypes.array,
  refresh: PropTypes.func,
  closeStatusHistoryModal: PropTypes.func,
  toggleDeleteModal: PropTypes.func,
  editAccess: PropTypes.bool,
  visible: PropTypes.bool.isRequired
};

StatusHistoryModal.defaultProps = {
  statusHistory: [],
  editAccess: false
};

export default StatusHistoryModal;
