/**
 * Validates the quantities of materials and products being received on a pallet.
 * @param {{materials: [], products: []}} values - material and inventory quantities
 * @param {{material_inventory: [], product_inventory: []}} pallet - pallet details with
 * @returns {boolean} - True if quantities are within inventory quantity
 */
export const validateReceivePallet = (values, pallet) => {
  console.log("validate values", values);
  console.log("validate pallet", pallet);
  const {materials, products} = values;

  let valid = true;
  if (materials) {
    for (let i in materials) {
      const material = materials[i];
      const palletMaterial = pallet.material_inventory.find(item => item.id === material.material);
      if (material.quantity > palletMaterial?.quantity ?? 0) {
        valid = false;
      }
    }
  }

  if (products) {
    for (let i in products) {
      const product = products[i];
      const palletProduct = pallet.product_inventory.find(item => item.id === product.product);
      if (product.quantity > palletProduct?.quantity ?? 0) {
        valid = false;
      }
    }
  }

  return valid;
};

