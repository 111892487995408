import React from "react";
import PropTypes from "prop-types";
import {List} from "Base";
import TemplateListItem from "./TemplateListItem";
import {crudActions, paginationProps} from "../../../types/propTypes";

const ProductTemplateList = (props) => {
  const {
    product,
    pagination,
    templateActions,
    materialActions,
    templateMaterialActions,
    materialPagination,
  } = props;

  const {
    itemsPaged,
    loading, // can be used as loading boolean
    itemsCount,
    itemsCurrentPage
  } = pagination;

  return (
    <List
      itemLayout={"vertical"}
      className={"template-list"}
      pagination={{
        onChange: page => {
          templateActions.getPage(page, {page_size: 3, product: product.id, expand: "materials"});
        },
        total: itemsCount,
        showTotal: (total) => `Total ${total} items`,
        pageSize: 3,
        position: "top",
      }}
      loading={loading}
      dataSource={itemsPaged}
      renderItem={item => (
        <TemplateListItem
          key={item.id}
          data={item}
          currentPage={itemsCurrentPage}
          templateActions={templateActions}
          onEdit={templateActions.update}
          onDelete={templateActions.delete}
          materialActions={materialActions}
          templateMaterialActions={templateMaterialActions}
          materialPagination={materialPagination}
        />
      )}
    />
  );
};

ProductTemplateList.propTypes = {
  pagination: paginationProps.isRequired,
  templateActions: crudActions.isRequired,
  templateMaterialActions: crudActions.isRequired,
  materialActions: crudActions.isRequired,
  materialPagination: PropTypes.object.isRequired,
};

export default ProductTemplateList;
