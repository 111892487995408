import React from "react";
import PropTypes from "prop-types";
import {Table} from "Base";


const DashboardTable = props => {
  const {data, columns} = props;
  return (
    <Table
      dataSource={data}
      columns={columns}
    />
  );
};

DashboardTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default DashboardTable;
