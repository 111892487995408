import React from "react";
import {basePreSaveFormat, deleteUnwantedProps} from "../../../Common/Forms/Base/utils";


export const load = async (values) => {
};


export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  const {barcode, ...rest} = values;
  let result = rest;

  return result;
};


export const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  if (values.quantity && values.quantityLocationData) {
    // Adding a new quantity with a location assigned
    if (Object.keys(values.quantityLocationData).length !== 0) {
      const {id, level} = values.quantityLocationData.location;
      result.quantities = [{
        "quantity": values.quantity,
        "id": id,
        "level": level
      }];
    } else {
      result.quantities = [{"quantity": values.quantity}];
    }
    delete result.quantity;
    delete result.quantityLocationData;
  }

  if (values.quantities) {
    result.quantities = result.quantities.map(result => {
      result = deleteUnwantedProps(result);
      if (result.location && result.location.id) {
        result.location = result.location.id;
      }
      return result;
    });
  }

  return result;
};
