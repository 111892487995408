import React from "react";
import PropTypes from "prop-types";
import {Button, Tooltip} from "antd";


const getTooltipTitle = ({disabled, showOnDisabled, tooltip}) => {
  if (showOnDisabled) {
    if (disabled) {
      return tooltip;
    } else {
      return null;
    }
  }
  return tooltip;
};

const TooltipButton = ({disabled, tooltip, onClick, showOnDisabled, placement, children, ...rest}) => {
  const title = getTooltipTitle({disabled, showOnDisabled, tooltip});
  return (
    <Tooltip placement={placement} title={title}>
      <Button
        onClick={onClick}
        type={"primary"}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

TooltipButton.propTypes = {
  disabled: PropTypes.bool,
  showOnDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placement: PropTypes.string,
  tooltip: PropTypes.string
};

TooltipButton.defaultProps = {
  showOnDisabled: true,
  placement: "topLeft",
};

export default TooltipButton;
