import React from "react";
import PropTypes from "prop-types";
import {AddInventoryQuantityForm} from "../../Forms/PalletForm";
import {List} from "antd";
import {CloseIcon} from "../../../Common/Base/Icons";

const SelectingMaterialListItem = ({item, onEdit, onRemove, fromOrder, maxField}) => {
  const handleEditQuantity = (values) => {
    onEdit({id: item.id, quantity: values.quantity});
  };
  let initialValues = item;
  let material = item;

  if (fromOrder) {
    material = item.material;
    initialValues.id = material.id;
  }
  return (
    <List.Item
      actions={[
        <AddInventoryQuantityForm
          key="add-quantity"
          fromOrder={fromOrder}
          max={item[maxField]}
          onSubmit={handleEditQuantity}
          initialValues={initialValues}
        />,
        !fromOrder && <CloseIcon key={"remove"} onClick={() => onRemove(item.id)}/>,
      ]}
    >
      <List.Item.Meta
        title={material && material.name ? material.name : "Material"}
        description={material && material.sku ? material.sku : null}
      />
    </List.Item>
  );
};

SelectingMaterialListItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  fromOrder: PropTypes.bool,
  maxField: PropTypes.string,
  onRemove: PropTypes.func
};
SelectingMaterialListItem.defaultProps = {
  fromOrder: false,
  maxField: "available_quantity"
};
export default SelectingMaterialListItem;
