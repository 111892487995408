import types from "./types";

import {filters, sort} from "../../utils";
import {baseReducer, getNumberOfPages, INITIAL_STATE, withCsv} from "../base/reducers";

const initialState = {...INITIAL_STATE, shifts: {}};

const personnelReducer = (state = initialState, action) => {
  switch (action.type) {
  /* Sets items to payload returned from getAllItems in operations. */
  case types.GET_ALL_SUCCESS: {
    let newItems = [];

    /* If success = 0, action.payload is a string and not an array
      and must be handled differnetly. Check payload in redux-devtools for
      debugging issues like this. */
    console.log(action);
    newItems = action.payload;
    const data = {};
    newItems.forEach(item => data[item.id] = item);

    const filteredList = sort.sortByKey(filters.filterListByName(newItems, state.filter), state.sortBy);
    const pages = getNumberOfPages(newItems.length, state.itemsPerPage);
    return {
      ...state,
      loading: false,
      pages,
      items: action.payload,
      data,
      filterList: filteredList,
      currentPage: 1,
      pageList: newItems.slice(0, state.itemsPerPage)
    };
  }
  case types.GET_ALL_FAILURE:
    let errors = {};
    console.log(action.payload);
    const {payload} = action;
    if (payload.status === 403) {
      errors = {
        detail: "Personnel Features require a Premium Account"
      };
    }
    return {
      ...state,
      loading: false,
      errors: errors
    };

  case types.GET_ITEM_SUCCESS: {
    const newData = {
      ...state.data,
      [action.payload.id]: action.payload
    };
    return {
      ...state,
      loading: false,
      data: newData,
    };
  }

  case types.GET_ITEM_SHIFTS_SUCCESS: {
    const newShifts = {
      ...state.shifts,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      shifts: newShifts
    };
  }

  case types.GET_ITEM_NOTES_SUCCESS: {
    const newNotes = {
      ...state.notes,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      notes: newNotes
    };
  }

  case types.FILTER_LIST: {
    // Const newFilter = action.filter.split(' ');
    let newList = filters.filterListByAll(state.items, action.filter);
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filter: action.filter,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  default:
    return state;
  }
};


export default withCsv(types, baseReducer(types, personnelReducer));
