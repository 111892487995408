import React from "react";
import PropTypes from "prop-types";
import {Details} from "./Details";
import {Bottom, SeeMoreButton, SideList, TagList, ViewLocationButton, Wrapper} from "../../Common/Bars/BarComponents";
import {listStyle, tileStyle} from "../../Common/Bars/BarComponents/constants";
import moment from "moment-timezone";
import voca from "voca";
import {getDeepestLocation} from "../../../utils/location";
/* A PM order bar is a div containing information regarding a PM order. A list of
PM order bars are visible within the assets section. */

const handleStartFormat = (data) => {
  const value = data?.start ?? null;
  if (value) {
    const startTz = moment.tz(value, "UTC");
    return startTz.format("YYYY-MM-DD");
  } else {
    return "Not Scheduled";
  }
};

const PMOrderBar = props => {
  const {data, listView, onCheckChange, showDelete, selectedRowsIds} = props;
  const baseClassName = listView ? listStyle : tileStyle;
  // const {start} = data;
  let leftList = [{title: "PM ORDER", value: data.name || "None"}];
  if (data?.location) {
    const [rung, locationObj] = getDeepestLocation(data.location);
    leftList.push({
      title: "LOCATION",
      value: `${voca.capitalize(rung)}: ${locationObj.name || "Unnamed Location"}`
    });
  }
  else {
    leftList.push({title: "ASSET NAME", value: data.asset?.name ?? "None"});
  }
  leftList.push({title: "DATE", value: handleStartFormat(data)});
  return (
    <Wrapper
      baseClassName={baseClassName}
      showDelete={showDelete}
      selectedRowsIds={selectedRowsIds}
      onCheckChange={onCheckChange}
      itemType="orders"
      itemData={data}
      detailsPane={<Details baseClassName={baseClassName} orderData={data}/>}
      bottomBar={<Bottom baseClassName={baseClassName} leftButton={SeeMoreButton} rightButton={ViewLocationButton}/>}
    >
      <SideList
        baseClassName={baseClassName}
        list={leftList}
      />
      {data.asset && data.asset.tags &&
      <TagList
        baseClassName={baseClassName}
        listName="TAGS"
        listArray={data.asset.tags}
      />
      }
    </Wrapper>
  );
};

PMOrderBar.propTypes = {
  objectData: PropTypes.object,
  listView: PropTypes.bool,
  onCheckChange: PropTypes.func,
  showDelete: PropTypes.bool,
  selectedRowsIds: PropTypes.array
};

export {PMOrderBar};
