import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {Flex} from "Base";
import {crudActions} from "../../../types/propTypes";
import EditableMaterialDropdown from "../../Common/Forms/Base/EditableMaterialDropdown";
import {formatMaterialOptions} from "../../../store/materials/selectors";

const TemplateMaterialForm = props => {
  const {
    onSubmit,
    validate,
    values,
    materialActions,
    materialPagination,
    org,
  } = props;

  const handleAddMaterial = (values) => {
    onSubmit({material: values.value, quantity: values?.quantity ?? 1});
  };

  const materialOptions = formatMaterialOptions(materialPagination.itemsPaged);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={values}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"template-material-form"} onSubmit={handleSubmit}>
          <Flex flexDirection={"column"}>
            <EditableMaterialDropdown
              label={"Material"}
              name={"material"}
              handleDeleteField={materialActions.delete}
              org={org}
              handleCreateField={materialActions.create}
              handleUpdateField={materialActions.update}
              handleAddFieldToObject={handleAddMaterial}
              options={materialOptions}
              canCreateSelections={true}
              canChangeSelection={true}
              submitText={"Add to Template"}
            />

          </Flex>
        </form>
      )}
    />
  );
};

TemplateMaterialForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  values: PropTypes.object.isRequired,
  materialActions: crudActions.isRequired,
  materialPagination: PropTypes.object,
  org: PropTypes.number,
};

export default TemplateMaterialForm;
