import {createBarcodeReducer} from "./reducers";
import {createBarcodeActionTypes} from "./types";
import {createBarcodeActions} from "./actions";

/**
 * @typedef {object} BarcodeModule
 * @property {object} types Barcode types - Generate barcode
 * @property {object} actions generateBarcode
 * @property {object} reducer The barcode reducer
 */

/**
 * Creates the barcode store
 * @param {object} options create options
 * @param {string} options.endpoint base endpoint eg: "v1/products/"
 * @param {string} options.name name of duck eg: "products"
 * @returns {BarcodeModule} Redux Barcode utilities
 */
const createBarcodeStore = ({endpoint, name}) => {
  const types = createBarcodeActionTypes(name);

  const actions = createBarcodeActions({types, endpoint});

  const reducer = () => createBarcodeReducer(types);

  return {
    types,
    actions,
    reducer,
  };
};

export {createBarcodeStore};
