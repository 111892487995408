import React, {Component} from "react";
import {connect} from "react-redux";

import {
  authSelectors,
  baseSelectors,
  orgOperations,
  orgSelectors,
  personnelActions,
  personnelOperations,
  personnelSelectors,
  scheduledShiftOperations,
  scheduledShiftSelectors,
} from "../../store/";

import PersonnelDetails from "../../components/Personnel/Details/PersonnelDetails";

/* This presents the details for a particular personnel. The imported personnel ID is acquired via the URL. */
class PersonnelDetailsContainer extends Component {
  render() {
    return (
      <PersonnelDetails {...this.props} />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  personnelData: baseSelectors.returnItemWithID(
    state.personnel,
    ownProps.match.params.personnelID
  ),
  personnelList: personnelSelectors.refreshNameList(state.personnel),
  scheduleList: scheduledShiftSelectors.refreshScheduleNameList(state.schedules),
  locationList: orgSelectors.refreshLocations(state.orgs),
  personnelTitleList: orgSelectors.refreshPersonnelTitles(state.orgs),
  notes: baseSelectors.refreshNotes(state.personnel, ownProps.match.params.personnelID),
  shifts: personnelSelectors.refreshShiftList(state.personnel, ownProps.match.params.personnelID)
});

const mapDispatchToProps = dispatch => ({
  fetchDetails: (id) => dispatch(personnelActions.getItem(id)),
  getLocations: (id) => dispatch(orgOperations.getLocations(id)),
  getSchedules: () => dispatch(scheduledShiftOperations.getAllItems()),
  addNote: async (id, data) => dispatch(await personnelOperations.addNote(id, data)),
  updateDetails: (id, data) => dispatch(personnelOperations.updateItem(id, data)),
  updatePersonnelField: async (id, fieldData) => dispatch(personnelOperations.updateItemField(id, fieldData)),
  getPersonnelList: () => dispatch(personnelOperations.getAllItems()),
  getOrg: (id) => dispatch(orgOperations.getItem(id)),
  sendInvitationEmail: async (id, email) => dispatch(await personnelOperations.sendInvitationEmail(id, email)),
  cancelInvitationEmail: async id => dispatch(await personnelOperations.cancelInvitationEmail(id)),
  getPersonnelTitles: org_id => dispatch(orgOperations.getPersonnelTitles(org_id)),
  createPersonnelTitle: (org_id, name) => dispatch(orgOperations.createPersonnelTitle(org_id, name)),
  updatePersonnelTitle: (org_id, personnel_title_id, name) => dispatch(orgOperations.updatePersonnelTitle(org_id, personnel_title_id, name)),
  deletePersonnelTitle: (org_id, personnel_title_id) => dispatch(orgOperations.deletePersonnelTitle(org_id, personnel_title_id)),
  fetchNotes: (id) => dispatch(personnelActions.getItemNotes(id)),
  assignPersonnelToSchedule: (id, data) => dispatch(scheduledShiftOperations.assignPersonnel(id, data)),
  getShifts: (id, range = "") => dispatch(personnelActions.getItemShifts(id, range))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonnelDetailsContainer);
