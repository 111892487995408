import React from "react";
import PropTypes from "prop-types";
import {Form, Field} from "react-final-form";
import {Input, Descriptions} from "antd";
import {Box, Flex} from "Base";
import {antAdapt} from "../../../Common/Forms/Base/utils";
import {ReactSelectAdapter} from "../../../Common/Fields/SelectField/SelectField";
import {CreateSelectField, FileField} from "../../../Common/Fields";
import {FormDropdown, FormField} from "../../../Common/Forms/Base";
import {formatCustomerList} from "./utils";
import {SupplierForm} from "../Agents";


const AdaptedInput = antAdapt(Input);
const SalesOrderForm = ({onSubmit, updateFiles, initialValues, editAccess, purchaseOrders, createCustomer, customers}) => {
  const {reference, customer, order, invoice} = initialValues;

  const formValues = {reference, customer, order, invoice};

  const customerOptions = formatCustomerList(customers);

  const submitForm = (values) => {
    console.log("submit values ", values);
    let data = values;
    let formData = new FormData();
    let hasFiles = false;
    if (data?.order) {
      hasFiles = true;
      formData.append("order", data.order.file);
    }
    if (data?.invoice) {
      hasFiles = true;
      formData.append("invoice", data.invoice.file);
    }
    delete data.order;
    delete data.invoice;

    if (hasFiles) {
      console.log("has files");
      updateFiles(initialValues.id, formData);
    }
    onSubmit(data);
  };
  return (
    <Form
      onSubmit={submitForm}
      initialValues={formValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form id={"sales-order-form"} onSubmit={handleSubmit}>
          <Descriptions size="small" column={3} className={"page-details"}>
            <Descriptions.Item label={"Reference #"}>
              <Field
                type="text"
                name="reference"
                component={AdaptedInput}
                size={"small"}
                parse={(value, name) => value === undefined ? "" : value}
                format={(value, name) => value === undefined ? "" : value}
              />
            </Descriptions.Item>
            <Descriptions.Item label={"Total Cost"}>{initialValues.total_cost}</Descriptions.Item>
            <Descriptions.Item label={"Currency"}>{initialValues.currency}</Descriptions.Item>
          </Descriptions>
          <Flex
            flexWrap={"wrap"}
            flexDirection={["column", "column", "row"]}
            alignItems={["", "", "center"]}>
            <Box pr={"10px"}>Order: </Box>
            <Box flex={1}>
              <FileField
                name={"order"}
              />
            </Box>
          </Flex>
          <Flex
            flexWrap={"wrap"}
            flexDirection={["column", "column", "row"]}
            alignItems={["", "", "center"]}>
            <Box pr={"10px"}>Invoice: </Box>
            <Box flex={1}>
              <FileField
                name={"invoice"}
              />
            </Box>
          </Flex>
          <FormField
            label={"Customer"}
            component={CreateSelectField}
            selectClassName={"details-dropdown"}
            InputComponent={ReactSelectAdapter}
            name="customer"
            form={SupplierForm}
            text={{
              title: "New Customer",
              tooltip: "Create a new customer",
            }}
            options={customerOptions}
            onSubmit={createCustomer}
            wrapperConfig={{pr: 2, pb: 2}}
            isSelect
          />

          <Box pr={2} pb={2}>
            <FormDropdown
              label={"Purchase Order"}
              name="purchase_order"
              options={purchaseOrders}
              selectClassName={"linked-po-select"}
            />
          </Box>
        </form>
      )}
    />
  );
};

SalesOrderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  updateFiles: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export default SalesOrderForm;
