import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {Box, Button, Collapse} from "../../Base";
import theme from "../../../../theme";
import IssueForm from "./IssueForm";
import ProgressStage from "./ProgressStage";
import {checkNestedKeys} from "../../../../utils/propUtils";

import {roleList, CLIENT_ORG_TYPE, SERVICE_PROV_ORG_TYPE} from "../PersonnelForm/constants";
import {
  ORDER_CREATED,
  CLIENT_ADMIN_APPROVED,
  SERVICE_PROVIDER_ACCEPTED,
  CLIENT_ADMIN_ACCEPTED,
  ORDER_SCHEDULED,
  ORDER_STARTED,
  ASSETS_IN_TRANSIT,
  AT_VENDOR_SITE,
  ORDER_COMPLETE,
} from "./constants";

export default function OrderAdvancementContainer({personnel, user_type, submit, status, is_on_site, isActiveIssue, date, windowWidth}) {
  const [isIssueOpen, setIssue] = useState(false);

  const orgType = checkNestedKeys(["default_org", "type"], personnel);
  const isFlexVertical = windowWidth < 750;
  const orderStatus = status;
  const userType = roleList[user_type - 1].label;
  const ADMIN_USER_TYPE = roleList[0].label;

  return (
    <Fragment>
      <ProgressStage
        isRendered={orderStatus === ORDER_CREATED}
        onClick={() => submit({"status": CLIENT_ADMIN_APPROVED})}
        disabled={orgType !== CLIENT_ORG_TYPE || userType !== ADMIN_USER_TYPE}
        gradient={"green"}
        btnText={"Approve Order"}
        subTextConditions={orgType !== CLIENT_ORG_TYPE || userType !== ADMIN_USER_TYPE}
        subText={"To advance the order, the Client Admin must approve the order."}
        secondaryButton={<Fragment/>}/>
      <ProgressStage
        isRendered={orderStatus === CLIENT_ADMIN_APPROVED}
        onClick={() => submit({"status": SERVICE_PROVIDER_ACCEPTED})}
        disabled={orgType !== SERVICE_PROV_ORG_TYPE || userType !== ADMIN_USER_TYPE}
        gradient={"green"}
        btnText={"Approve Order"}
        subTextConditions={true}
        subText={"To advance the order, the service provider must approve the order."}
        secondaryButton={<Fragment/>}/>
      <ProgressStage
        isRendered={orderStatus === SERVICE_PROVIDER_ACCEPTED}
        onClick={() => {
          const nextStatus = is_on_site ? CLIENT_ADMIN_ACCEPTED : ORDER_STARTED;
          submit({"status": nextStatus});
        }}
        disabled={orgType !== CLIENT_ORG_TYPE || userType !== ADMIN_USER_TYPE || !date}
        gradient={"green"}
        btnText={"Accept Order"}
        subTextConditions={true}
        subText={"To advance the order, the Client Admin must propose a date and accept the offer."}
        secondaryButton={<Fragment/>}/>
      {/* On Site Section */}
      {is_on_site && status > 3 &&
        <Fragment>
          <ProgressStage
            isRendered={orderStatus === CLIENT_ADMIN_ACCEPTED}
            onClick={() => submit({"status": ORDER_SCHEDULED})}
            disabled={(orgType !== SERVICE_PROV_ORG_TYPE || userType !== ADMIN_USER_TYPE) || !date}
            gradient={"green"}
            btnText={"Accept Proposed Date"}
            subTextConditions={true}
            subText={"To advance the order, the Service Provider must approve the proposed date or propose a new date by submitting a note."}
            secondaryButton={<Fragment/>}/>
          <ProgressStage
            isRendered={orderStatus === ORDER_SCHEDULED}
            onClick={() => submit({"status": ORDER_STARTED})}
            disabled={userType !== ADMIN_USER_TYPE}
            gradient={"green"}
            btnText={"Mark Order As Started"}
            subTextConditions={true}
            subText={"Once the Service Provider arrives on site, mark the order as started."}
            secondaryButton={<Fragment/>}/>
          <ProgressStage
            isRendered={orderStatus === ORDER_STARTED && !isIssueOpen}
            onClick={() => submit({"status": ORDER_COMPLETE})}
            disabled={orgType !== SERVICE_PROV_ORG_TYPE || userType !== ADMIN_USER_TYPE || isActiveIssue}
            gradient={"green"}
            btnText={"Mark Order Complete"}
            subTextConditions={false}
            isFlexVertical={isFlexVertical}
            secondaryButton={
              <Button
                className="progress-btn"
                onClick={() => setIssue(true)}
                m={1}
                disabled={orgType !== SERVICE_PROV_ORG_TYPE || userType !== ADMIN_USER_TYPE}
                theme={theme}
                gradient="orange">
                  Report a Problem
              </Button>}/>
        </Fragment>
      }
      {/* Off Site Section */}
      {!is_on_site && status > 3 &&
        <Fragment>
          <ProgressStage
            isRendered={orderStatus === ORDER_STARTED && orgType === CLIENT_ORG_TYPE}
            onClick={() => submit({"status": ASSETS_IN_TRANSIT})}
            disabled={orgType !== CLIENT_ORG_TYPE || userType !== ADMIN_USER_TYPE}
            gradient={"green"}
            btnText={"Yes"}
            subTextConditions={false}
            isFlexVertical={isFlexVertical}
            preText={"Do you have transportation covered?"}
            secondaryButton={
              <Button
                className="progress-btn"
                onClick={() => submit({"status": ASSETS_IN_TRANSIT})}
                m={1}
                disabled={orgType !== CLIENT_ORG_TYPE || userType !== ADMIN_USER_TYPE}
                theme={theme}
                gradient="green">
                  No
              </Button>}/>
          {orderStatus === ORDER_STARTED && orgType === SERVICE_PROV_ORG_TYPE && <Box fontSize="1.5em">
            Please wait while transportation is arranged.
          </Box>}
          <ProgressStage
            isRendered={status === ASSETS_IN_TRANSIT && orgType === SERVICE_PROV_ORG_TYPE}
            onClick={() => submit({"status": AT_VENDOR_SITE})}
            disabled={orgType !== SERVICE_PROV_ORG_TYPE || userType !== ADMIN_USER_TYPE}
            gradient={"green"}
            btnText={"Mark Asset Received"}
            subTextConditions={false}
            secondaryButton={<Fragment/>}/>
          <ProgressStage
            isRendered={status === AT_VENDOR_SITE && !isIssueOpen}
            onClick={() => submit({"status": ORDER_COMPLETE})}
            disabled={orgType !== SERVICE_PROV_ORG_TYPE || userType !== ADMIN_USER_TYPE || isActiveIssue}
            gradient={"green"}
            btnText={"Mark Order Complete"}
            subTextConditions={false}
            isFlexVertical={isFlexVertical}
            secondaryButton={
              <Button
                className="progress-btn"
                onClick={() => setIssue(true)}
                m={1}
                disabled={orgType !== SERVICE_PROV_ORG_TYPE || userType !== ADMIN_USER_TYPE}
                color={"white"}

                gradient="orange">
                  Report a Problem
              </Button>}/>
        </Fragment>}
      <br />
      <Collapse mt={4} isOpen={isIssueOpen}>
        <IssueForm submit={submit} closeIssue={() => setIssue(false)} />
      </Collapse>
    </Fragment>
  );
}

OrderAdvancementContainer.propTypes = {
  is_on_site: PropTypes.bool,
  user_type: PropTypes.number,
  status: PropTypes.number,
  isActiveIssue: PropTypes.bool,
  personnel: PropTypes.object,
  date: PropTypes.string,
  windowWidth: PropTypes.number
};

OrderAdvancementContainer.defaultProps = {
  is_on_site: null,
};
