import base from "../actions";

/**
 * Create all the needed barcode actions
 * @param {object} params The parameters
 * @param {object} params.types The redux types to use
 * @param {string} params.endpoint The base endpoint for the requests
 * @returns {object} The barcode actions
 */
const createBarcodeActions = ({types, endpoint}) => {
  const {
    GENERATE_BARCODE_REQUEST,
    GENERATE_BARCODE_SUCCESS,
    GENERATE_BARCODE_FAILURE
  } = types;

  /**
   * Generates a barcode
   * @param {object} params The parameters
   * @param {number} params.objId The ID of the object to generate the barcode for
   * @param {number} params.orgId The org to create the barcode under
   * @param {string} params.barcodeType The type of barcode to generate
   * @returns {object} The RSAA object
   */
  const generateBarcode = ({objId, orgId, barcodeType}) => {
    return base.postRsaa({
      endpoint: `${global.base_url}${endpoint}${objId}/generate_barcode/`,
      body: JSON.stringify({
        org_id: orgId,
        barcode_type: barcodeType
      }),
      types: [GENERATE_BARCODE_REQUEST, GENERATE_BARCODE_SUCCESS, GENERATE_BARCODE_FAILURE]
    });
  };

  return {generateBarcode};
};

export {createBarcodeActions};
