import React from "react";
import PropTypes from "prop-types";
import {Form, Field} from "react-final-form";
// import {adapt} from "../Base/utils";
import {Search} from "../../Base";
import {AutoSave} from "../Base";

export const adapt = Component => ({input, meta: {valid}, ...rest}) =>
  <Component {...input} {...rest}/>;

const AdaptedSearch = adapt(Search);

const SearchInput = props => {
  const {
    onSubmit,
    // validate,
    // values
    filter,
    reset,
  } = props;

  const submitSearch = (values) => {
    if (values.search !== "") {
      onSubmit(values.search);
    } else {
      reset();
    }
  };


  const values = {
    search: filter,
  };
  return (
    <Form
      onSubmit={submitSearch}
      // validate={validate}
      initialValues={values}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <div className={""}>
          <AutoSave
            debounce={1000}
            save={handleSubmit}
            isSubmitting={submitting}
            debounced={["search"]}
          />

          <Field
            className={"search-input"}
            component={AdaptedSearch}
            name={"search"}
            placeholder={"Search..."}
            enterButton
            onSearch={handleSubmit}
          />
        </div>
      )}
    />
  );
};

SearchInput.propTypes = {
  onSubmit: (values) => null,
  validate: PropTypes.func,
  filter: PropTypes.string,
  reset: PropTypes.func,
};
SearchInput.defaultProps = {
  validate: (values) => {
    console.log("search validate", values);
    return true;
  },
};

export default SearchInput;
