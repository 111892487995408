import React from "react";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {List} from "@material-ui/core";
import PersonnelListItem from "./PersonnelListItem";

const PersonnelList = props => {
  const {handleClick, classes, items} = props;
  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      {items.length !== 0 &&
      <List className={classes.list}>
        {items.map(item => {
          return (
            <PersonnelListItem
              key={item.id}
              item={item}
              onClick={handleClick}
              classes={classes}
              isEditable={true}
            />
          );
        })}
      </List>
      }
    </ReactCSSTransitionGroup>
  );
};

PersonnelList.propTypes = {
  items: PropTypes.array,
  handleClick: PropTypes.func,
};

export default PersonnelList;
