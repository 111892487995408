import React from "react";
import PropTypes from "prop-types";
import {ListItem as BaseListItem} from "@chakra-ui/core";

const ListItem = props => {
  return (
    <BaseListItem {...props}/>
  );
};

ListItem.propTypes = {
  item: PropTypes.any
};

export default ListItem;
