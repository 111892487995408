
const MOBILE = {query: "(max-width: 600px)"};
const TABLET = {query: "(max-width: 990px)"};

const MEDIA_QUERIES = {
  mobile: MOBILE,
  tablet: TABLET
};


const ADMIN = "ADMIN";
const MANAGER = "MANAGER";
const LEAD = "LEAD";
const TECHNICIAN = "TECHNICIAN";
const CONTRACTOR = "CONTRACTOR";
const SERVICE_PROVIDER = "SERVICE_PROVIDER";

const USER_TYPE = {
  1: ADMIN,
  2: MANAGER,
  3: LEAD,
  4: TECHNICIAN,
  5: CONTRACTOR,
  6: SERVICE_PROVIDER
};

/**
 * Gets the user type of the account object as a string
 * @param {object} account The account object
 * @param {number} account.user_type The user type as a number
 * @returns {string} A short description of the user type
 */
const getUserType = (account = {user_type: 1}) => {
  return USER_TYPE[account.user_type];
};

export {MEDIA_QUERIES, MOBILE, TABLET, ADMIN, MANAGER, LEAD, TECHNICIAN, CONTRACTOR, SERVICE_PROVIDER, getUserType};

export default USER_TYPE;


export const DEFAULT_CONTAINER_DIMENSIONS = {max_weight: 2000, max_containers: 10, width: 48, depth: 40, height: 72};
