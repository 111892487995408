import {Heading as BaseHeading} from "@chakra-ui/core";
import styled from "@emotion/styled";

const Heading = styled(BaseHeading)``;

Heading.defaultProps = {
  as: "h3",
  fontSize: "lg",
  mb: "15px"
  // size: "lg",
  // py: 2,
  // fontWeight: "medium"
};

const ErrorHeading = styled(Heading)``;

ErrorHeading.defaultProps = {
  fontSize: "16px",
  mt: "15px",
  color: "colorError",
  textAlign: "center"
};

export {Heading, ErrorHeading};
