import React from "react";
import PropTypes from "prop-types";
import {Descriptions, Flex, List, Skeleton, Button} from "../../../Common/Base";

import {inventoryType} from "../../utils/propTypes";
import {renderInventoryTitle} from "../../utils/inventory";

const InventoryListItem = ({item, loading, onSelect, onDeselect, isSelected, type}) => {
  const handleSelect = () => isSelected ? onDeselect() : onSelect({...item, quantity: 0});

  return (
    <List.Item
      className={`${type}-list-item`}
      actions={[
        <Flex justifyContent={"flex-start"} key={1}>
          <Button
            variant={"outline"}
            onClick={handleSelect}
            disabled={isSelected}>
            {isSelected ? "Deselect" : "Select"}
          </Button>
        </Flex>
      ]}
      extra={
        <Flex flexDirection={"column"} width={"230px"}>
          <Descriptions column={1}>
            <Descriptions.Item label={"SKU"}>{item.sku}</Descriptions.Item>
            <Descriptions.Item label={"Cost"}>{item.price}</Descriptions.Item>

          </Descriptions>
        </Flex>
      }
    >
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          // avatar={
          //   <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>
          // }
          title={renderInventoryTitle(item, type)}

          // description={
          //   <Text>{item.product.manufacturer}</Text>}
        />
      </Skeleton>

    </List.Item>
  );
};

InventoryListItem.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  selectedProductID: PropTypes.number,
  type: inventoryType
};

export default InventoryListItem;
