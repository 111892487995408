import {CUSTOMER, SALES_ORDER, SUPPLIER} from "./constants";


export const getContactSource = (type) => {
  return type === SALES_ORDER ? CUSTOMER : SUPPLIER;
};

export const hasContact = (order, type) => {
  const contactSource = getContactSource(type);
  return Boolean(order[contactSource]);
};

