import React, {useEffect, useState} from "react";
import {Table as BaseTable} from "Base";
import PropTypes from "prop-types";
import {getTableConfigWithProps, getColumnSearchProps} from "../../../utils/tables";
import {ResizeableTitle} from "./Editable/ResizableTitle";
import {EditableCell, EditableRow} from "./Editable/GeneralEditableCell";

const Table = props => {
  const {tableData, handleFieldChange} = props;
  const [columns, setColumns] = useState([]);
  const tableConfig = getTableConfigWithProps(tableData, props);

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  const handleResize = index => (e, {size}) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setColumns(nextColumns);
  };

  const handleSave = row => {
    const newData = [tableConfig.tableData];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {...item, ...row});
    handleFieldChange(newData);
  };

  const components = {
    header: {
      cell: ResizeableTitle,
    },
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const finalColumns = columns.map(col => {
    let column = col;
    if (col.editable) {
      column = {
        ...column,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
        }),
      };
    }
    if (col.searchable) {
      column = {
        ...column,
        ...getColumnSearchProps(col.dataIndex),
      };
    }
    return column;
  });

  return (
    <BaseTable
      size="small"
      components={components}
      style={{width: "100%", height: "100%"}}
      // This will be implemented when selection is brought in from bulk_delete
      rowSelection={tableConfig.rowSelection}
      columns={finalColumns.map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      }))}
      bordered
      dataSource={tableConfig.tableData}
      pagination={false}
      scroll={{x: 800}}
    />
  );
};

Table.propTypes = {
  tableData: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  currentPage: PropTypes.array,
  rowSelection: PropTypes.array
};

export {Table};
