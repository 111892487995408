import {allStatusOptions} from "../../Common/Forms/VendorForm/constants";
import moment from "moment";

/**
 * Utility function to format the Vendor orders table data
 * @param {Array} data The list of data to parse
 * @returns {Array} The list of parsed items
 */
export const vendorsTableData = (data) => {
  return data.map(item => ({
    key: item.id,
    associated_asset_name: item.asset.name,
    title: item.title,
    status: allStatusOptions[item.status].label,
    email_sent: item.email_sent ? "Yes" : "No",
    assigned_personnel: item.personnel,
    created_at: moment(item.created_at).format("LLL"),
    updated_at: moment(item.updated_at).format("LLL")
  }));
};
