import React, {Component} from "react";
import {connect} from "react-redux";
import {checkNestedKeys} from "../../utils/propUtils";
import {
  authSelectors,
  customerOperations,
  customerPageActions,
  lineOperations,
  linePageActions,
  orgOperations,
  orgSelectors,
  paginationSelectors,
  personnelOperations,
  personnelSelectors,
  scheduledShiftOperations,
  scheduledShiftSelectors,
  supplierOperations,
  supplierPageActions,
} from "../../store/";
import {AntdCollapse, Panel, Heading} from "Base";
import {AssetTypesForm, LocationForm, ProductionLineForm, ScheduleShiftForm} from "../../components/Common/Forms";
import {AdminAddressForm} from "../../components/Warehouse/Forms";
import styled from "@emotion/styled";


const AccordionHeader = styled(Heading)`
  margin-bottom: 0;
`;

class AdminContainer extends Component {
  state = {
    isLoading: false,
    error: ""
  };


  componentDidMount() {
    // Use cdm to get all of the required items we need for each accordionItem
    const {
      accountInfo,
      customerActions,
      getAssetTypes,
      getLocations,
      getPersonnelList,
      getSchedules,
      productionLineActions,
      supplierActions,
    } = this.props;
    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);
    if (orgId) {
      getAssetTypes(orgId);
      getLocations(orgId);
    }
    getSchedules();
    getPersonnelList();
    productionLineActions.getPage(1);
    supplierActions.getPage(1);
    customerActions.getPage(1);
  }

  handleCreateSchedule = (values) => {
    const {accountInfo, createSchedule} = this.props;
    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);

    return createSchedule({...values, org: orgId});
  };

  handleCreateShift = (scheduleId, values) => {
    const {createShift} = this.props;

    const data = {...values, schedule: scheduleId};

    console.log("shift data", data);

    return createShift(scheduleId, {...values, schedule_id: scheduleId});
  };

  handleUpdateShift = async (scheduleId, id, data) => {
    const {updateShift, getSchedules} = this.props;

    let res = await updateShift(scheduleId, id, data);
    getSchedules();
    return res;
  };

  render() {
    const {allErrors, scheduleList, updateSchedule, personnelList, assignPersonnelToSchedule, locationList} = this.props;
    const commonFormProps = {
      accountInfo: this.props.accountInfo,
    };

    return (
      <AntdCollapse accordion>
        <div className={`error-pane ${allErrors.message ? "show" : ""}`}>{allErrors.message}</div>

        <Panel header={<AccordionHeader>Asset Types</AccordionHeader>} key={"asset-types"} className={"settings-view-center"}>
          <AssetTypesForm
            assetTypeList={this.props.assetTypeList}
            createAssetType={this.props.createAssetType}
            updateAssetType={this.props.updateAssetType}
            deleteAssetType={this.props.deleteAssetType}
            {...commonFormProps}
          />
        </Panel>

        <Panel header={<AccordionHeader>Locations</AccordionHeader>} key={"locations"} className={"settings-view-center"}>
          <LocationForm
            locationList={this.props.locationList}
            createLocation={this.props.createLocation}
            updateLocation={this.props.updateLocation}
            deleteLocation={this.props.deleteLocation}
            {...commonFormProps}
          />
        </Panel>

        <Panel header={<AccordionHeader>Scheduled Shifts</AccordionHeader>} key={"scheduled-shifts"} className={"settings-view-center"}>
          <ScheduleShiftForm
            createSchedule={this.handleCreateSchedule}
            createShift={this.handleCreateShift}
            updateSchedule={updateSchedule}
            updateShift={this.handleUpdateShift}
            schedules={scheduleList}
            personnelList={personnelList}
            assignPersonnel={assignPersonnelToSchedule}
            locationList={locationList}
            {...commonFormProps}
          />
        </Panel>

        <Panel header={<AccordionHeader>Production Lines</AccordionHeader>} key={"production-lines"} className={"settings-view-center"}>
          <ProductionLineForm
            pagination={this.props.productionPagination}
            productionLineActions={this.props.productionLineActions}
            {...commonFormProps}
          />
        </Panel>

        <Panel header={<AccordionHeader>Suppliers</AccordionHeader>} key={"suppliers"} className={"settings-view-center"}>
          <AdminAddressForm
            pagination={this.props.supplierPagination}
            actions={this.props.supplierActions}
            {...commonFormProps}
          />
        </Panel>

        <Panel header={<AccordionHeader>Customers</AccordionHeader>} key={"customers"} className={"settings-view-center"}>
          <AdminAddressForm
            pagination={this.props.customerPagination}
            actions={this.props.customerActions}
            {...commonFormProps}
          />
        </Panel>
      </AntdCollapse>
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  assetTypeList: orgSelectors.refreshAssetTypes(state.orgs),
  locationList: orgSelectors.refreshLocations(state.orgs),
  allErrors: orgSelectors.refreshErrors(state.orgs),
  scheduleList: scheduledShiftSelectors.refreshAllItems(state.schedules),
  personnelList: personnelSelectors.refreshNameList(state.personnel),
  productionPagination: paginationSelectors.pagination(state.production.lines.items),
  supplierPagination: paginationSelectors.pagination(state.warehouse.contacts.supplier.items),
  customerPagination: paginationSelectors.pagination(state.warehouse.contacts.customer.items),
});

const mapDispatchToProps = dispatch => ({
  productionLineActions: {
    create: (data) => dispatch(lineOperations.createItem(data)),
    update: (id, data) => dispatch(lineOperations.updateItem(id, data)),
    delete: (id) => dispatch(lineOperations.deleteItem(id)),
    getPage: (page, params = {}) => {
      const finalParams = {
        ...params,
        expand: "assets,products"
      };
      return dispatch(linePageActions.fetchPage({page, params: finalParams}));
    }
  },
  supplierActions: {
    create: (data) => dispatch(supplierOperations.createItem(data)),
    update: (id, data) => dispatch(supplierOperations.updateItem(id, data)),
    delete: (id) => dispatch(supplierOperations.deleteItem(id)),
    getPage: (page, params = {}) => {
      const finalParams = {
        ...params,
        expand: "addresses"
      };
      return dispatch(supplierPageActions.fetchPage({page, params: finalParams}));
    }
  },
  customerActions: {
    create: (data) => dispatch(customerOperations.createItem(data)),
    update: (id, data) => dispatch(customerOperations.updateItem(id, data)),
    delete: (id) => dispatch(customerOperations.deleteItem(id)),
    getPage: (page, params = {}) => {
      const finalParams = {
        ...params,
        expand: "addresses"
      };
      return dispatch(customerPageActions.fetchPage({page, params: finalParams}));
    }
  },
  getOrg: (id) => dispatch(orgOperations.getItem(id)),
  getAssetTypes: id => dispatch(orgOperations.getAssetTypes(id)),
  getLocations: (id) => dispatch(orgOperations.getLocations(id)),
  getSchedules: () => dispatch(scheduledShiftOperations.getAllItems()),
  createAssetType: (org_id, name, color) => dispatch(orgOperations.createAssetType(org_id, name, color)),
  updateAssetType: (org_id, asset_type_id, name, color) => dispatch(orgOperations.updateAssetType(org_id, asset_type_id, name, color)),
  deleteAssetType: (org_id, asset_type_id) => dispatch(orgOperations.deleteAssetType(org_id, asset_type_id)),
  createLocation: (org_id, data) => dispatch(orgOperations.createLocation(org_id, data)),
  updateLocation: (org_id, data) => dispatch(orgOperations.updateLocation(org_id, data)),
  deleteLocation: (org_id, location_id) => dispatch(orgOperations.deleteLocation(org_id, location_id)),
  createSchedule: (data) => dispatch(scheduledShiftOperations.createScheduledShift(data)),
  createShift: (scheduleId, data) => dispatch(scheduledShiftOperations.createShift(scheduleId, data)),
  updateSchedule: (id, data) => dispatch(scheduledShiftOperations.updateScheduledShift(id, data)),
  updateShift: (schedule_id, id, data) => dispatch(scheduledShiftOperations.updateShift(schedule_id, id, data)),
  getPersonnelList: () => dispatch(personnelOperations.getAllItems()),
  assignPersonnelToSchedule: (id, data) => dispatch(scheduledShiftOperations.assignPersonnel(id, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContainer);
