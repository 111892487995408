import React from "react";
import columns from "./columns.js";
import {Table} from "../../Common/Tables/Table";
import {personnelTableData} from "./data";

const PersonnelTable = (props) => {
  return (
    <Table
      handleFieldChange={props.handleFieldChange}
      columns={columns}
      tableData={personnelTableData}
      {...props}
    />
  );
};

export {PersonnelTable};
