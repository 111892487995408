import React from "react";
import PropTypes from "prop-types";
import {Heading} from "../../../components/Common/Base";
import PasswordForm from "../../../components/Common/Forms/PasswordForm";
import {Box} from "@chakra-ui/core";

const Security = props => {
  const submitNewPassword = values => {
    const {changePassword} = props;
    const {oldPassword, newPassword} = values;
    changePassword(oldPassword, newPassword);
  };

  return (
    <Box className="edit-password" mb={"20px"}>
      <Heading as={"h2"} size="xl" m={"20px 0 15px 0"}>Password Settings</Heading>
      <PasswordForm onSubmit={submitNewPassword}/>
    </Box>
  );
};

Security.propTypes = {
  changePassword: PropTypes.func
};

export default Security;
