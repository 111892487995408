import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, List} from "../../components/Common/Base";
import Log from "../../components/Common/Header/Logs/Log";
import {orgActions, orgOperations, orgSelectors} from "../../store/orgs/";

class LogContainer extends Component {
  componentDidMount() {
    this.props.getRecentLogs(0);
  }

  render() {
    const {logList} = this.props;
    return (
      <Fragment>
        <Box padding={30} maxWidth={1400} margin="0 auto">
          <List
            itemLayout={"vertical"}
            dataSource={logList}
            renderItem={(item) => <Log key={item.id} logInfo={item}/>}
          />
        </Box>
      </Fragment>
    );
  }
}


/* Here we will map Redux's store state to props to be accessed and manipulated within the
context of this container */
const mapStateToProps = state => ({
  logList: orgSelectors.refreshLogs(state.orgs),
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  receiveLog: (data) => dispatch(orgActions.receivedLog(data)),
  getRecentLogs: () => dispatch(orgOperations.getRecentLogs(0)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogContainer);
