import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {pickBy} from "lodash-es";
import {Box, Button, Flex} from "../../Base";
import {Input} from "./Inputs";
import {OnChange} from "react-final-form-listeners";
import Select from "react-select";
import {Plus} from "../../Base/Icons";
import {currencyList} from "./constants";
import {Modal} from "../../index";
import {DescriptionText} from "../../Modals/styled";
import {Label, LabelText} from "./styled";

const price = (price = "", currency = "", isSelected) => {
  if (price) {
    price = `- ${price} ${currency}`;
  }
  return {
    alignItems: "center",
    display: "flex",

    ":after": {
      content: `"${price}"`,
      color: isSelected ? "#fff" : "#777",
      display: "block",
      marginLeft: 4,
    }
  };
};
const priceStyling = {
  option: (styles, {data, isSelected}) => ({...styles, ...price(data.price, data.price_currency, isSelected)}),
  placeholder: styles => ({...styles}),
  singleValue: (styles, {data, isSelected}) => ({...styles, ...price(data.price, data.price_currency, isSelected)})
};

/**
 * Self contained form component for creating, updating and deleting materials
 */

class EditableMaterialDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createFieldShowing: false,
      editFieldShowing: false,
      deleteFieldModalShowing: false,
      selectedOption: "",
      newField: {
        name: "",
        manufacturer: "",
        sku: "",
        price: "",
        price_currency: ""
      }
    };
  }

  handleFieldSelect = data => {
    this.setState({
      selectedOption: {
        ...data,
        name: data["label"]
      }
    });
  };

  handleCreateFieldSubmit = () => {
    const {handleCreateField, org} = this.props;
    let data = pickBy(this.state.newField);
    data["org"] = org;
    handleCreateField(data);
    this.setState({
      createFieldShowing: false
    });
  };

  handleEditFieldSubmit = () => {
    const {handleUpdateField} = this.props;
    const {newField} = this.state;
    handleUpdateField(newField.id, pickBy(newField));
    this.setState({
      editFieldShowing: false
    });
  };

  handleDeleteFieldSubmit = () => {
    const {handleDeleteField} = this.props;
    handleDeleteField(this.state.selectedOption.id);
    this.setState({
      selectedOption: "",
      editFieldShowing: false
    });
    this.toggleDeleteFieldModal();
  };

  toggleDeleteFieldModal = () => {
    this.setState({deleteFieldModalShowing: !this.state.deleteFieldModalShowing});
  };

  handleFieldValueChange = (prop, value, obj) => {
    let newFieldUpdate = this.state[obj];
    newFieldUpdate[prop] = value;
    if (prop === "name") {
      newFieldUpdate.label = value;
    }
    this.setState({[obj]: newFieldUpdate});
  };

  handleShowCreateForm = () => {
    this.setState({
      createFieldShowing: !this.state.createFieldShowing,
      editFieldShowing: false
    });
  };
  handleShowEditForm = () => {
    this.setState({
      createFieldShowing: false,
      editFieldShowing: !this.state.editFieldShowing,
      newField: this.state.selectedOption
    });
  };

  shouldShowEditButton = () => {
    const {
      selectedOption,
      currentOption
    } = this.state;
    let showEdit = false;

    if (selectedOption.name) {
      showEdit = true;
    }
    if (currentOption !== undefined) {
      showEdit = currentOption.value !== null;
    }
    return showEdit;
  };

  render() {
    const {
      name,
      label,
      dropdownName,
      canChangeSelection,
      canCreateSelections,
      options,
      includeNone,
      handleAddFieldToObject,
      submitText
    } = this.props;
    const {
      createFieldShowing,
      editFieldShowing,
      deleteFieldModalShowing,
      selectedOption,
      newField
    } = this.state;

    let selectOptions = includeNone ? [{value: null, label: "None"}, ...options] : options;

    const showEdit = this.shouldShowEditButton();

    return (
      <Fragment>
        <Label htmlFor={name}>
          <LabelText>{label}: </LabelText>
        </Label>

        <Box className="item-details-form-flex" mb={"30px"}>
          <Select
            className="item-details-form-dropdown"
            id="order-materials-dropdown"
            onChange={this.handleFieldSelect}
            value={selectedOption}
            options={selectOptions}
            disabled={!canChangeSelection}
            styles={priceStyling}
          />

          {canCreateSelections &&
          <Box>
            <OnChange name={name}>
              {(value, previous) => {
                // This will unhide the edit and trash buttons
                // and update the container's state
                if (previous && value && value.value !== previous.value) {
                  this.setState({currentOption: value});
                  this.handleFieldSelect(value);
                }
              }}
            </OnChange>
            <Plus
              onClick={this.handleShowCreateForm}
              data-tip={`Create new ${dropdownName}.`}
              className="create-material"
            />
            {showEdit && (
              <FontAwesomeIcon
                icon="edit"
                size="lg"
                onClick={this.handleShowEditForm}
                data-tip={`Edit ${dropdownName}.`}
                className="edit-material"
              />
            )}
            {showEdit && (
              <FontAwesomeIcon
                icon="trash"
                size="lg"
                onClick={this.toggleDeleteFieldModal}
                data-tip={`Delete ${dropdownName}.`}
                className="delete-material"
              />
            )}
          </Box>
          }
        </Box>

        {(createFieldShowing || editFieldShowing) && (
          <Flex flexWrap="wrap">
            <Input
              readOnly={true}
              display="none"
              name="newFieldID"
            />
            <Input
              placeholder={`${dropdownName} Name`}
              margin="0 20px 10px 0"
              flex="1 0 auto"
              width="inherit"
              type="text"
              name={`${name}Name`}
              readOnly={!canCreateSelections}
              value={newField.name}
              onChange={e => this.handleFieldValueChange("name", e.target.value, "newField")}
              className="material-name"
            />
            <Input
              placeholder={"Manufacturer"}
              margin="0 20px 10px 0"
              flex="1 0 auto"
              width="inherit"
              type="text"
              name={`${name}Manufacturer`}
              readOnly={!canCreateSelections}
              value={newField.manufacturer}
              onChange={e => this.handleFieldValueChange("manufacturer", e.target.value, "newField")}
              className="material-manufacturer"
            />
            <Input
              placeholder={"SKU"}
              margin="0 20px 10px 0"
              flex="1 0 auto"
              width="inherit"
              type="text"
              min={0}
              step={0.1}
              name={`${name}SKU`}
              readOnly={!canCreateSelections}
              value={newField.sku}
              onChange={e => this.handleFieldValueChange("sku", e.target.value, "newField")}
              className="material-sku"
            />
            <Input
              placeholder={"Price"}
              margin="0 20px 10px 0"
              flex="1 0 auto"
              width="inherit"
              type="number"
              min={0}
              step={0.01}
              name={`${name}Price`}
              readOnly={!canCreateSelections}
              value={newField.price}
              onChange={e => this.handleFieldValueChange("price", e.target.value, "newField")}
              className="material-price"
            />
            <Select
              options={currencyList}
              placeholder="Currency"
              className="item-details-form-dropdown material-currency"
              styles={{
                container: (base) => ({
                  ...base,
                  maxWidth: "95%",
                  margin: "0 20px 10px 0",
                  flex: "1 0 auto"
                })
              }}
              isDisabled={!canCreateSelections}
              value={(currencyList.find(el => el.value === newField.price_currency))}
              onChange={e => this.handleFieldValueChange("price_currency", e.value, "newField")}
            />
            <Button
              type="button"
              className="item-details-form-button"
              id="material-create-update"
              width="initial"
              mt={0}
              mb={10}
              padding="5px 15px"
              disabled={!(newField.name)}
              onClick={() => {
                editFieldShowing ? this.handleEditFieldSubmit() : this.handleCreateFieldSubmit();
              }}>
              {editFieldShowing ? "Edit" : "Create"}
            </Button>
          </Flex>
        )}

        {showEdit && !(createFieldShowing || editFieldShowing) &&
        <Flex flexDirection={"row"}>
          <Input
            placeholder="Quantity"
            margin="0 20px 10px 0"
            width={100}
            display="inline-block"
            type="number"
            min={1}
            defaultValue={1}
            name={`${name}Quantity`}
            readOnly={!canChangeSelection}
            onChange={e => this.handleFieldValueChange("quantity", e.target.value, "selectedOption")}/>
          <Button
            type="primary"
            className="item-details-form-button"
            width="initial"
            onClick={() => handleAddFieldToObject(this.state.selectedOption)}>
            {submitText}
          </Button>
        </Flex>
        }

        <Modal
          visible={deleteFieldModalShowing}
          title={`Delete ${selectedOption.name || dropdownName}?`}
          onCancel={this.toggleDeleteFieldModal}
          onOk={this.handleDeleteFieldSubmit}
          isDeleteModal
        >
          <DescriptionText>Deleting this {dropdownName} will remove it from any object.</DescriptionText>
        </Modal>
      </Fragment>
    );
  }
}

EditableMaterialDropdown.propTypes = {
  dropdownName: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  org: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  canChangeSelection: PropTypes.bool,
  canCreateSelections: PropTypes.bool,
  includeNone: PropTypes.bool,
  handleCreateField: PropTypes.func.isRequired,
  handleUpdateField: PropTypes.func.isRequired,
  handleDeleteField: PropTypes.func.isRequired,
  handleAddFieldToObject: PropTypes.func.isRequired,
  submitText: PropTypes.string
};
EditableMaterialDropdown.defaultProps = {
  name: "dropdown-field",
  label: "Dropdown",
  options: [],
  canChangeSelection: false,
  canCreateSelections: false,
  includeNone: true,
  submitText: "Add to Order"
};


export default EditableMaterialDropdown;
