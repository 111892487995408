import React from "react";
import PropTypes from "prop-types";
import {FlexCenter, Heading} from "../index";
import Loader from "react-loader-spinner";

/**
 * Shows a loading icon
 * @param {string=} title The text to be accompanied with the loading icon
 * @class
 */
const Loading = ({title}) => {
  return (
    <FlexCenter className="items-loader" width={"100%"} flexDirection={"column"} flexWrap={"wrap"}>
      <Loader
        type="Oval"
        color="#2c3655"
        height="200"
        width="200"
        value="100"
      />
      {title && <Heading as={"h1"} size={"xl"} mt={"20px"}>{title}</Heading>}
    </FlexCenter>
  );
};

Loading.propTypes = {
  title: PropTypes.string
};

Loading.defaultProps = {
  title: ""
};

export default Loading;
