import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormDropdown} from "../Base";
import {bondingTypes} from "./constants.js";
import PositiveNumberField from "../Base/PositiveNumberField";

import {SubmitButton} from "../styled";


const InsuranceBondingInfo = props => {
  const {
    handleSubmit,
    validate,
    insurance_type,
    other_insurance_type,
    min_per_occ,
    min_gen_agg,
    min_combined_single,
    umbrella_liab_limit,
    workers_comp_limit,
    bonding_type,
    bonding_rate,
    bonding_capacity
  } = props;

  const initialValues = {
    insurance_type,
    other_insurance_type,
    min_per_occ,
    min_gen_agg,
    min_combined_single,
    umbrella_liab_limit,
    workers_comp_limit,
    bonding_type,
    bonding_rate,
    bonding_capacity
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        pristine,
        invalid,
        submitting,
        values
      }) => (
        <form className={"item-details-form serv-prov-info"} onSubmit={handleSubmit}>
          <h2><label> General Liability:</label></h2>
          <PositiveNumberField
            label="Minimum Per Occurance *"
            placeholder="required"
            name="min_per_occ"
            required
          />
          <PositiveNumberField
            label="Minimum General Aggregate Limit *"
            placeholder="required"
            name="min_gen_agg"
            required
          />
          <h2><label> Automotive Liability Insurance:</label></h2>
          <PositiveNumberField
            label="Minimum Combined Single Limit"
            placeholder="enter #"
            name="min_combined_single"
          />
          <h2><label> Umbrella Liability Insurance:</label></h2>
          <PositiveNumberField
            label="Minimum Limit"
            placeholder="enter #"
            name="umbrella_liab_limit"
          />
          <h2><label> Workers Compensation:</label></h2>
          <PositiveNumberField
            label="Workers Comp Limit"
            placeholder="enter #"
            name="workers_comp_limit"
          />

          <h2><label> Bonding Types:</label></h2>
          <FormDropdown
            options={bondingTypes}
            name={"bonding_type"}
            label={"BONDING TYPES"}
            format={(value, name) => bondingTypes[value]}
            parse={(value, name) => value.value}
          />
          <PositiveNumberField
            label="Bonding Rate Per $1000"
            placeholder="enter #"
            name="bonding_rate"
          />
          <PositiveNumberField
            label="Bonding Capacity per Project"
            placeholder="enter #"
            name="bonding_capacity"
          />
          <br/>
          <SubmitButton className="item-details-form-button" loading={submitting}>Submit</SubmitButton>
        </form>
      )
      }
    />
  );
};

InsuranceBondingInfo.propTypes = {
  handleSubmit: PropTypes.func,
  validate: PropTypes.func
};

InsuranceBondingInfo.defaultProps = {
  validate: (values) => null
};

export default InsuranceBondingInfo;
