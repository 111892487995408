import React from "react";
import PropTypes from "prop-types";
import {List, Text, IconButton, PopConfirmButton} from "../../../../Common/Base";
import {DeleteOutlined} from "@ant-design/icons";
import {renderInventoryTitle} from "../../../../Warehouse/utils/inventory";

const ProductListItem = props => {
  const {
    item,
    onDelete
  } = props;

  const handleDelete = () => onDelete(item.id);

  return (
    <List.Item
      className={"product-list-item"}
      actions={[
        <PopConfirmButton
          key="delete-item"
          className={"delete-product-button"}
          onConfirm={handleDelete}
          Button={IconButton}
          icon={DeleteOutlined}
        />
      ]}
    >
      <Text>{renderInventoryTitle(item)}</Text>
    </List.Item>
  );
};

ProductListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func

};

export default ProductListItem;
