import React, {useState} from "react";
import PropTypes from "prop-types";
import {NoteSection} from "../index";

const DetailsNotes = props => {
  const {
    azurePrefix,
    accountInfo,
    data,
    actions,
    notes,
    files,
    title, endpoint,
    getDocuments
  } = props;

  const {uuid, id} = data;


  const {fetchNotes, addNote} = actions;
  const [inspectionNoteVisible, setInspectionNoteVisible] = useState(false);


  const toggleInspectionNotes = () => {
    setInspectionNoteVisible(!inspectionNoteVisible);
  };

  return (
    <NoteSection
      UUID={uuid}
      accountInfo={accountInfo}
      documentID={id}
      handleSubmit={addNote}
      refreshObject={() => fetchNotes(id)}
      azureDocumentPrefix={azurePrefix}
      getDocuments={getDocuments}
      notes={notes}
      files={files}
      title={title}
      endpoint={endpoint}
      toggleInspectionNotes={toggleInspectionNotes}
    />
  );
};

DetailsNotes.propTypes = {
  title: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  accountInfo: PropTypes.object,
  // object with id and uuid
  actions: PropTypes.shape({
    addNote: PropTypes.func,
    fetchNotes: PropTypes.func
  }),
  data: PropTypes.object,
  notes: PropTypes.array,
  files: PropTypes.array,
  getDocuments: PropTypes.func,
  azurePrefix: PropTypes.string.isRequired,
};

DetailsNotes.defaultProps = {
  getRecentLogs: () => console.log("get logs"),
};

export default DetailsNotes;
