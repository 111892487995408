import {DatePicker, Input, Select, TimePicker} from "antd";
import {antAdapt, antDateAdapter, antSelectAdapt} from "../../Forms/Base/utils";

const {RangePicker} = TimePicker;

const {TextArea} = Input;
export const AdaptedInput = antAdapt(Input);
export const AdaptedTextarea = antAdapt(TextArea);

export const AdaptedSelect = antSelectAdapt(Select);
// const AdaptedDatePicker = dateAdapter(SingleDatePicker);
export const AdaptedDatePicker = antDateAdapter(DatePicker);

export const AdaptedRangePicker = antAdapt(RangePicker);
