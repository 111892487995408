import React from "react";
import PropTypes from "prop-types";
import {PaginatedList} from "../../Common/Lists";
import ScheduledPMOrder from "../Details/ScheduledPMOrder/ScheduledPMOrder";
import {paginationProps} from "../../../types/propTypes";

const ScheduleSection = props => {
  const {pagination, fetchPage} = props;

  return (
    <PaginatedList
      className={"schedule-list"}
      pagination={pagination}
      fetchPage={fetchPage}
      renderItem={item => (
        <ScheduledPMOrder key={item.id} item={item}/>
      )}
    />
  );
};

ScheduleSection.propTypes = {
  data: PropTypes.array,
  pagination: paginationProps.isRequired,
  fetchPage: PropTypes.func.isRequired
};

export default ScheduleSection;
