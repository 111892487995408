import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {LocationSection, AdminControlsDetails, AttachedFiles, BarcodeSection, Container, Modal, NoteSection} from "../../Common";
import MaterialForm from "./MaterialForm";

import {Button, DetailsCloseIcon, ErrorHeading, Flex, Loading, Box} from "../../Common/Base";
import {
  AZURE_BLOB_CONTAINER_NAME,
  AZURE_MATERIAL_PREFIX,
  handleChangeDisplayImage,
  handleChangeWarrantyDocument,
  listBlobsInAzureContainer,
  removeDocument,
  uploadBlobsToAzureWithFileNameCheck,
} from "../../../utils/azure";
import {deleteAxios} from "../../../utils/authWithAxios";
import {preSaveFormat} from "./MaterialForm/utils";
import {baseDetailsState, checkForResponseError, handleErrorResponse, scrollToTop} from "../../../utils/containerUtils";

import {DetailsHeader, DetailsPaneLeft, DetailsPaneRight, ImageWithDefault} from "../../Common/Details/Layout";
import {checkNestedKeys} from "../../../utils/propUtils";
import {DescriptionText} from "../../Common/Modals/styled";
import {CODE_128_BARCODE} from "../../Common/Files/constants";
import {MATERIAL_DASHBOARD_ROUTE} from "../../../routes/constants";

/* This presents the details for a particular material. The imported material ID is acquired via the URL. */
class MaterialDetails extends Component {
  constructor(props) {
    super(props);
    this.materialID = props.match.params.materialID;
    this.state = {
      quantityLocationData: {},
      deletedMaterial: false,
      deleteMaterialModalShowing: false,
      ...baseDetailsState("material"),
    };
  }

  /* Upon mounting, fetch data for the specific material by materialID and store the data in state. */
  componentDidMount = () => {
    this.fetchMaterialDetails().then(() => {
      if (this.state.materialOrgId) {
        this.props.getLocations(this.state.materialOrgId);
      }
    });
    scrollToTop();
  };

  /* This function will handle the fetching of details for a material. */
  fetchMaterialDetails = () => {
    const {fetchDetails} = this.props;
    return fetchDetails(this.materialID)
      .then(res => {
        console.log(res);
        checkForResponseError(res);
        let data = res.payload;
        this.setState({
          materialData: data,
          materialID: data.id,
          materialOrgId: data.org.id,
          materialUUID: data.UUID || data.uuid,
          materialName: data.name,
          displayImage: data.display_image,
          warrantyDocument: data.warranty_document,
          editAccess: data?.permissions?.write ?? false,
        });
        // this.getMaterialDocuments();
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        handleErrorResponse(this, error, "Material");
      })
      .then(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  /* This is passed to the redux-form which returns all values passed in from state */
  submit = async values => {
    const {updateDetails} = this.props;
    let {materialID, materialData, submitting, quantityLocationData} = this.state;

    if (!submitting) {
      this.setState({submitting: true});

      values.quantityLocationData = quantityLocationData;

      const submitValues = preSaveFormat(values);
      console.log("submit values", submitValues);
      const res = await updateDetails(materialID, submitValues);

      materialData.quantities = res.quantities;
      this.setState({
        materialData: res,
        quantityLocationData: {},
      });
      setTimeout(() => this.setState({submitting: false}), 1000);
    }
  };

  submitBarcode = async values => {
    await this.props.updateDetails(this.state.materialID, values);
  };

  generateBarcode = () => {
    return this.props.generateBarcode(this.state.materialID, this.state.materialOrgId, CODE_128_BARCODE);
  };

  /* This function will handle deletion of a material. */
  handleDeleteMaterial = () => {
    this.setState({
      adminControlsSubmitting: true,
      adminControlsMessage: "",
    });
    deleteAxios({
      url: `${global.base_url}v1/materials/${this.materialID}/`,
    })
      .then(res => {
        console.log(res);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Material deletion successful.",
          deletedMaterial: true,
        });
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error);
        this.setState({
          adminControlsSubmitting: false,
          adminControlsMessage: "Error occurred during material deletion.",
          deleteMaterialModalShowing: false,
        });
      });
  };

  toggleDeleteMaterialModal = () => {
    this.setState({deleteMaterialModalShowing: !this.state.deleteMaterialModalShowing});
  };

  getMaterialDocuments = () => {
    const {materialUUID} = this.state;
    listBlobsInAzureContainer(AZURE_BLOB_CONTAINER_NAME, `${AZURE_MATERIAL_PREFIX}/${materialUUID}/`).then(files => {
      this.setState({
        materialFiles: files,
      });
    });
  };

  handleChangeDisplayImageField = () => {
    const {materialUUID} = this.state;
    uploadBlobsToAzureWithFileNameCheck(materialUUID, AZURE_MATERIAL_PREFIX, [this.refs.fileUploader.files[0]]).then(async res => {
      // Res contains filenames from uploaded files, use res[0] because only one file was uploaded
      await this.handleChangeDisplayImageNoUpload(res[0]);
      this.fetchMaterialDetails();
    });
  };

  handleChangeDisplayImageNoUpload = async imageName => {
    await handleChangeDisplayImage(this, imageName, this.materialID, this.props.updateMaterialField);
  };

  handleChangeWarrantyDocumentField = async documentName => {
    await handleChangeWarrantyDocument(this, documentName, this.materialID, this.props.updateMaterialField);
  };

  handleRemoveDocument = documentName => {
    removeDocument(this, documentName, this.materialID, this.props.updateMaterialField, this.getMaterialDocuments);
  };

  /* If field is read only, filter main page results by field contents (manufacturer name) and route to page */
  handleManufacturerClick = by => {
    const {name} = by.target;
    const materialEditAccess = this.props.accountInfo.user_type <= 2;
    const {manufacturer} = this.state.materialData;
    const {filterListBy} = this.props;
    if (!materialEditAccess) {
      filterListBy({by: name, filter: manufacturer});
    }
  };

  render() {
    const {
      materialData,
      materialID,
      materialUUID,
      materialName,
      showLoader,
      quantityLocationData,

      adminControlsSubmitting,
      adminControlsMessage,
      errorLoadingMessage,
      deletedMaterial,
      deleteMaterialModalShowing,
      materialFiles,
      displayImage,
      warrantyDocument,
      editAccess,
    } = this.state;
    const {
      accountInfo,
      getRecentLogs,
      notes,
      fetchNotes,
      addNote,
      locationList,
      match
    } = this.props;

    if (deletedMaterial) return <Redirect to={`${MATERIAL_DASHBOARD_ROUTE}`} />;

    let barcodeFormat = checkNestedKeys(["barcode", "format"], materialData);

    const dashboardUrl = match.url.slice(0, -2);
    return (
      <div>
        <Container>
          {materialID && (
            <Flex className="item-details" flexDirection={["column", "row"]}>
              <DetailsCloseIcon href={`/#${dashboardUrl}`} dataTip={"Close and return to materials."} />
              <DetailsPaneLeft className="item-details-pane-left">
                <ImageWithDefault src={displayImage} />
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  ref="fileUploader"
                  onChange={this.handleChangeDisplayImageField}
                  style={{display: "none"}}
                />
                <Button className="item-details-form-button" onClick={() => this.refs.fileUploader.click()}>
                  Change Display Image
                </Button>
                <div className="item-details-pane-left-text">
                  <BarcodeSection
                    data={checkNestedKeys(["barcode", "data"], materialData) || ""}
                    format={barcodeFormat === false ? -1 : barcodeFormat}
                    updateBarcode={this.submitBarcode}
                    generateBarcode={this.generateBarcode}
                  />

                  {accountInfo.user_type === 1 ? (
                    <AdminControlsDetails
                      deleteItem={this.toggleDeleteMaterialModal}
                      controlsSubmitting={adminControlsSubmitting}
                      controlsMessage={adminControlsMessage}
                    />
                  ) : null}
                  <br />
                </div>
              </DetailsPaneLeft>
              <DetailsPaneRight className="item-details-pane-right">
                <div className="item-details-pane-right-text">
                  <DetailsHeader
                    locationData={quantityLocationData}
                    locationSection={
                      <Box display={"inline-block"} key={"location-section"}>
                        <LocationSection
                          locationData={quantityLocationData}
                          locationList={locationList}
                          submit={this.submit}
                          isAdmin={accountInfo.user_type <= 1}
                          hideCard
                          buttonConfig={{
                            size: "small",
                            type: Object.keys(quantityLocationData).length !== 0 ? "link" : "default",
                          }}
                          wrapperStyle={{mt: 0}}
                        />
                      </Box>
                    }
                  >
                    MATERIAL DETAILS
                  </DetailsHeader>
                  <MaterialForm
                    onSubmit={this.submit}
                    values={materialData}
                    editAccess={editAccess}
                    handleManufacturerClick={this.handleManufacturerClick}
                    locationList={locationList}
                    locationData={quantityLocationData}
                    setLocationData={data => {
                      this.setState({quantityLocationData: data.location.id > 0 ? data : {}});
                    }}
                    isAdmin={accountInfo.user_type <= 1}
                  />
                  <br />
                  <br />
                  <AttachedFiles
                    files={materialFiles}
                    displayImage={displayImage}
                    warrantyDocument={warrantyDocument}
                    azureDocumentPrefix={AZURE_MATERIAL_PREFIX}
                    UUID={materialUUID}
                    getDocuments={this.getMaterialDocuments}
                    handleChangeDisplayImage={this.handleChangeDisplayImageNoUpload}
                    handleChangeWarrantyDocument={this.handleChangeWarrantyDocumentField}
                    handleRemoveDocument={this.handleRemoveDocument}
                    refreshObject={this.fetchMaterialDetails}
                  />

                  <NoteSection
                    UUID={materialUUID}
                    accountInfo={accountInfo}
                    documentID={materialID}
                    handleSubmit={addNote}
                    refreshObject={() => fetchNotes(materialID)}
                    azureDocumentPrefix={AZURE_MATERIAL_PREFIX}
                    getDocuments={this.getMaterialDocuments}
                    notes={notes}
                    files={materialFiles}
                    getRecentLogs={getRecentLogs}
                    title={"MATERIAL NOTES"}
                    endpoint={"materials"}
                  />
                </div>
              </DetailsPaneRight>
            </Flex>
          )}
        </Container>
        {errorLoadingMessage && <ErrorHeading>{errorLoadingMessage}</ErrorHeading>}

        {showLoader && <Loading title={"Loading Material..."} />}
        <Modal
          visible={deleteMaterialModalShowing}
          title={`Delete ${materialName || "Material"}?`}
          onCancel={this.toggleDeleteMaterialModal}
          onOk={this.handleDeleteMaterial}
          isDeleteModal
        >
          <DescriptionText>Deleting this material will remove it from all PM and Vendor Orders.</DescriptionText>
        </Modal>
      </div>
    );
  }
}

export default MaterialDetails;
