import React, {Component} from "react";
import {connect} from "react-redux";
import {assetActions, assetOperations, assetSelectors, authSelectors, baseSelectors} from "../../store/";
import {AssetsDashboard} from "../../components/Assets/Dashboard/AssetDashboard";

/* This will present a list of asset bars. */
class AssetsContainer extends Component {
  render() {
    return (
      <AssetsDashboard {...this.props}/>
    );
  }
}

const mapStateToProps = state => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  allItems: baseSelectors.allItems(state.assets),
  currentPage: baseSelectors.refreshCurrentPage(state.assets),
  loading: baseSelectors.isLoading(state.assets),
  listView: baseSelectors.isListView(state.assets),
  tileView: baseSelectors.isTileView(state.assets),
  tableView: baseSelectors.isTableView(state.assets),
  pageNum: baseSelectors.currPageNum(state.assets),
  isLastPage: baseSelectors.isLastPage(state.assets),
  hasErrors: baseSelectors.hasErrors(state.assets),
  isEmptyList: baseSelectors.isEmptyList(state.assets),
  csvMessages: baseSelectors.refreshMessages(state.assets),
  filter: baseSelectors.filter(state.assets),
  isReversed: baseSelectors.isListReversed(state.assets),
  filteringBy: assetSelectors.filteringBy(state.assets),
  filteringByType: assetSelectors.filteringByType(state.assets),
  filterTypeOptions: assetSelectors.types(state.assets)
});

const mapDispatchToProps = dispatch => ({
  getAssetList: () => dispatch(assetOperations.getAllItems()),
  updateAssetDetails: (id, data) => dispatch(assetOperations.updateItem(id, data)),
  bulkDelete: (data) => dispatch(assetOperations.bulkDelete(data)),
  uploadCsv: (data, org) => dispatch(assetOperations.uploadCsv(data, org)),
  nextPage: () => dispatch(assetActions.nextPage()),
  prevPage: () => dispatch(assetActions.prevPage()),
  filterAssets: filter => dispatch(assetActions.filterList(filter)),
  filterListBy: by => dispatch(assetActions.filterListBy(by)),
  filterListByType: by => dispatch(assetActions.filterListByType(by)),
  sortAssets: sortBy => dispatch(assetActions.sortList(sortBy)),
  reverseList: () => dispatch(assetActions.reverseList()),
  changeViewList: () => dispatch(assetActions.changeViewList()),
  changeViewTile: () => dispatch(assetActions.changeViewTile()),
  changeViewTable: () => dispatch(assetActions.changeViewTable()),
  clearMessages: () => dispatch(assetActions.clearMessages()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetsContainer);
