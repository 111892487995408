import React from "react";
import moment from "moment-timezone";
import {repeatUnitOptions} from "./constants.js";
import {basePreSaveFormat} from "../../Common/Forms/Base/utils";
import {checkIfToday} from "../../../utils/rrules";
import {isInclusivelyAfterDay} from "react-dates";

export const formatStartTime = (value) => {
  return value ? moment().tz(moment(value).format("YYYY-MM-DD")) : null;
};
export const load = async (values) => {
  console.info("Loading...");
  let result = values;
  console.log("result", result);
  return result;
};


export const loading = <div className="loading">Loading...</div>;

export const postLoadFormat = (values) => {
  let result = values ?? {};

  if (values) {
    if (values.repeat_unit) {
      for (let el in repeatUnitOptions) {
        if (repeatUnitOptions[el].label === values.repeat_unit) {
          result.repeat_unit = repeatUnitOptions[el].value;
        }
      }
    }

    if (values.expected_time) {
      result.expected_time = moment.utc(values.expected_time, "YYYY-MM-DDTHH:mm:ss").toDate();
    }

    if (values.start) {
      result.start = moment().tz(values.start).format("YYYY-MM-DD");
    }
  }

  return result;
};


const preSaveFormat = (values) => {
  let result = basePreSaveFormat(values);

  delete result.scheduled_pm_orders;

  if (values.start === "") {
    result.start = null;
  }
  else if (values.start) {
    console.log("pre save start", values.start);
    result.start = moment(values.start).tz(moment.tz.guess()).format();
  }

  if (values.asset && values.asset.id) {
    result.asset = values.asset.id;
  }

  if (values.stockroom_asset && values.stockroom_asset.id) {
    result.stockroom_asset = values.stockroom_asset.id;
  }

  if (values.location && values.location.id) {
    result.location = values.location.location_instance;
  }

  if (values.materials) {
    // Don't send anything if the save button was clicked
    if (!Array.isArray(values.materials)) {
      result.materials = [{
        id: values.materials["id"],
        amount: values.materials["quantity"],
      }];
    } else {
      delete result.materials;
    }
  }

  return result;
};


const preSaveFormatSchedule = (values) => {
  let result = basePreSaveFormat(values);

  result.start = moment(result.start);

  return result;
};

/**
 * This function compares the date to the personnel shifts
 * @param {Array} personnel The personnel to search through
 * @param {moment.Moment} date Date to compare to
 * @returns {boolean} True if date is included in a shift
 */
const isDateHighlighted = (personnel, date) => {
  let result = false;
  let shifts = [];
  let scheduleList = [];
  if (personnel) {
    personnel.forEach(personnel => scheduleList = [...scheduleList, ...personnel.schedules]);
    scheduleList.forEach(schedule => schedule.shifts.forEach(shift => shifts.push(shift)));
    for (let x in shifts) {
      if (checkIfToday(shifts[x].repeat, date)) {
        result = true;
      }
    }
    return result;
  } else {
    return false;
  }
};


export {preSaveFormat, preSaveFormatSchedule, isInclusivelyAfterDay, isDateHighlighted};
