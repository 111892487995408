import React from "react";
import PropTypes from "prop-types";
import {ListItemSecondaryAction, ListItemText, ListItem} from "@material-ui/core";
import {SecondaryText, ListButton} from "../styled";
import {ruleStringToText} from "../../../../utils/rrules";
import {FaEdit} from "react-icons/fa";

const ShiftListItem = props => {
  const {item, classes, onClick, current} = props;

  const handleEdit = () => {
    onClick(item);
  };

  const ruleText = ruleStringToText(item.repeat);
  const isSelected = Boolean(current) && current.id === item.id;

  return (
    <ListItem className={`${isSelected ? classes.selectedListItem : classes.listItem} shift-list-item`}>
      <ListItemText
        className={classes.listItemText}
        primary={ruleText}
        secondary={
          <SecondaryText className={"shift-start-end"}>{item.start_time} - {item.end_time}</SecondaryText>
        }
      />

      <ListItemSecondaryAction>
        <ListButton
          icon={FaEdit}
          className={"edit-shift"}
          aria-label="edit"
          onClick={handleEdit}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ShiftListItem.propTypes = {
  item: PropTypes.object,
  isEditable: PropTypes.bool,
  classes: PropTypes.any,
  onClick: PropTypes.func,
};

export default ShiftListItem;
