import React from "react";
import {Checkbox, FormControl, FormErrorMessage, FormLabel, Input, Progress, RadioGroup, Textarea} from "@chakra-ui/core";

import {Field, useField, useForm} from "react-final-form";

/*
  This file contains compound chakra-ui Form components
  These have been adapted for use in react-final-form
 */

const AdaptedTextarea = ({input, meta, ...rest}) => (
  <Textarea {...input} {...rest} isInvalid={meta.error && meta.touched}/>
);

const CheckboxControl = ({name, value, children}) => {
  const {
    input: {checked, ...input},
    meta: {error, touched, invalid},
  } = useField(name, {
    type: "checkbox", // important for RFF to manage the checked prop
  });
  return (
    <FormControl isInvalid={touched && invalid} my={2}>
      <Checkbox {...input} isInvalid={touched && invalid} my={2} isChecked={checked}>
        {children}
      </Checkbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

const CheckboxArrayControl = ({name, value, children}) => {
  const {
    input: {checked, ...input},
    meta: {error, touched},
  } = useField(name, {
    type: "checkbox", // important for RFF to manage the checked prop
    value, // important for RFF to manage list of strings
  });
  return (
    <Checkbox {...input} isChecked={checked} isInvalid={error && touched}>
      {children}
    </Checkbox>
  );
};

const AdaptedRadioGroup = ({input, meta, label, children}) => (
  <FormControl isInvalid={meta.touched && meta.invalid} my={4}>
    <FormLabel htmlFor={input.name}>{label}</FormLabel>
    <RadioGroup {...input}>{children}</RadioGroup>
    <FormErrorMessage>{meta.error}</FormErrorMessage>
  </FormControl>
);

const Control = ({name, ...rest}) => {
  const {
    meta: {error, touched},
  } = useField(name, {subscription: {touched: true, error: true}});
  return <FormControl {...rest} isInvalid={error && touched}/>;
};

const Error = ({name}) => {
  const {
    meta: {error},
  } = useField(name, {subscription: {error: true}});
  return <FormErrorMessage>{error}</FormErrorMessage>;
};

const InputControl = ({name, label, controlStyle, inputStyle, placeholder = label}) => {
  const {input, meta} = useField(name);
  return (
    <Control name={name} {...controlStyle}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={placeholder}
        {...inputStyle}
      />
      <Error name={name}/>
    </Control>
  );
};

InputControl.defaultProps = {
  controlStyle: {my: 4},
  inputStyle: {size: "lg"}
};

const TextareaControl = ({name, label}) => (
  <Control name={name} my={4}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <Field
      name={name}
      component={AdaptedTextarea}
      placeholder={label}
      id={name}
    />
    <Error name={name}/>
  </Control>
);

const PercentComplete = props => {
  const form = useForm();
  const numFields = form.getRegisteredFields().length;
  const numErrors = Object.keys(form.getState().errors).length;
  return (
    <Progress
      value={numFields === 0 ? 0 : ((numFields - numErrors) / numFields) * 100}
      {...props}
    />
  );
};

export {TextareaControl, CheckboxControl, CheckboxArrayControl, InputControl, AdaptedRadioGroup, AdaptedTextarea, PercentComplete};
