import React from "react";
import PropTypes from "prop-types";

import {Table} from "Common/Base";

import {
  orderPalletColumns,
  orderPalletItemColumns,
  salesOrderPalletColumns,
  combineMaterialProductList
} from "./constants";
import {PURCHASE_ORDER, SALES_ORDER} from "../../utils/constants";

const getColumns = (type) => {
  return type === PURCHASE_ORDER ? orderPalletColumns : salesOrderPalletColumns;
};
const OrderPalletTable = ({data, type}) => {
  const expandedRowRender = (record) => {
    const data = combineMaterialProductList(record);
    return <Table columns={orderPalletItemColumns} dataSource={data} pagination={false}/>;
  };


  // TODO: make row's expandable on it's own.
  const formattedData = data?.map(item => ({key: item.id, ...item}));

  const columns = getColumns(type);
  return (
    <Table
      size={"small"}
      className={"pallet-table"}
      scroll={{x: 800}}
      bordered
      dataSource={formattedData}
      columns={columns}
      expandable={{expandedRowRender}}
      rowClassName="pallet-row"
      // pagination={{
      //   onChange: cancel,
      // }}
    />
  );
};

OrderPalletTable.propTypes = {
  type: PropTypes.oneOf([SALES_ORDER, PURCHASE_ORDER]),
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.array
};

OrderPalletTable.defaultProps = {
  type: PURCHASE_ORDER,
  data: []
};

export default OrderPalletTable;
