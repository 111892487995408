import React, {Component} from "react";
import {connect} from "react-redux";
import {
  authSelectors,
  baseSelectors,
  customerOperations,
  customerPageActions,
  inventoryPageActions,
  materialInventoryActions,
  orgOperations,
  orgSelectors,
  paginationSelectors,
  palletOperations,
  personnelSelectors,
  salesOrderActions,
  salesOrderItemActions,
  salesOrderMaterialActions,
  salesOrderOperations,
  salesOrderSelectors,
} from "store";
import {customerAddressActions} from "store/warehouse";

import {isEmpty} from "lodash-es";

import {SalesOrderDetails} from "../../../components/Warehouse";
import {DEFAULT_PAGE_SIZE} from "../../../components/Warehouse/PurchaseOrders/Details/constants";
import {purchaseOrderOperations, purchaseOrderSelectors} from "../../../store";

function mapStateToProps(state, ownProps) {
  return {
    accountInfo: authSelectors.refreshAccountInfo(state.account),
    orderDetails: paginationSelectors.getItem(
      state.warehouse.sales.items,
      ownProps.match.params.orderID,
    ),
    locationList: orgSelectors.refreshLocations(state.orgs),
    notes: baseSelectors.refreshNotes(state.warehouse.products.details, ownProps.match.params.productID),
    inventoryPagination: paginationSelectors.pagination(state.warehouse.products.inventory),
    materialPagination: paginationSelectors.pagination(state.warehouse.materials.inventory),
    customerPagination: paginationSelectors.pagination(state.warehouse.contacts.customer.items),
    orgID: authSelectors.defaultOrgID(state.account),
    facilities: salesOrderSelectors.refreshFacilityList(state.warehouse.sales),
    personnelList: personnelSelectors.refreshNameList(state.personnel),
    purchaseOrders: purchaseOrderSelectors.refreshNameList(state.purchaseOrders),
  };
}

const expandSales = "?expand=location,customer.addresses,items.product,materials.material,pallets,org,purchase_order";

function mapDispatchToProps(dispatch, ownProps) {
  const orderID = ownProps.match.params.orderID;
  return {
    // Sales Order Actions
    fetchDetails: () => dispatch(
      salesOrderOperations.getItem(
        orderID,
        expandSales
      )),
    updateDetails: (id, data) => dispatch(salesOrderOperations.updateItem(id, data)),
    updateFiles: (id, data) => dispatch(salesOrderOperations.updateItemFile(id, data)),
    deleteOrder: () => dispatch(salesOrderOperations.deleteItem(orderID)),
    changeView: (view) => dispatch(salesOrderActions.changeView(view)),
    createItem: (data) => dispatch(salesOrderOperations.createItem(data)),

    // Inventory Actions
    getInventory: (page = 1, params = {}) => dispatch(inventoryPageActions.fetchPage({page, params})),
    getMaterials: (page = 1, params = {}) => {
      const finalParams = {
        expand: "material",
        ...params,
      };
      dispatch(materialInventoryActions.fetchPage({page, params: finalParams}));
    },
    createOrderItem: (id, data) => dispatch(salesOrderItemActions.createItem(id, data)),
    updateOrderItem: (orderID, id, data) => dispatch(salesOrderItemActions.updateItem(orderID, id, data)),
    createOrderMaterial: (id, data) => dispatch(salesOrderMaterialActions.createItem(id, data)),
    updateOrderMaterial: (orderID, id, data) => dispatch(salesOrderMaterialActions.updateItem(orderID, id, data)),

    // Customer Actions
    getCustomers: (page = 1, params = {page_size: DEFAULT_PAGE_SIZE}) =>
      dispatch(customerPageActions.fetchPage({page, params})),
    createCustomer: (data) => dispatch(customerOperations.createItem(data)),

    // Pallet
    createPallet: (data) => dispatch(palletOperations.createPalletForOrder(data)),
    updatePallet: (id, data) => dispatch(palletOperations.updateItem(id, data)),
    packItems: (id, data) => dispatch(salesOrderActions.packItems(id, data)),
    // Locations
    getFacilities: (org) => dispatch(salesOrderActions.getFacilities(org)),
    getLocations: (id) => dispatch(orgOperations.getLocations(id)),
    createCustomerAddress: (id, data) => dispatch(customerAddressActions.createItem(id, data)),

    getPurchaseOrders: () => dispatch(purchaseOrderOperations.getAllItems()),

  };
}

class SalesOrderDetailsContainer extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const {customerList, orgID, getCustomers, getPurchaseOrders} = this.props;
    if (isEmpty(customerList) && orgID) getCustomers(1, {page_size: DEFAULT_PAGE_SIZE});
    getPurchaseOrders();
  };

  handleCreateAddress = async (id, data) => {
    const {createCustomerAddress, fetchDetails} = this.props;

    const res = await createCustomerAddress(id, data);
    await fetchDetails();
    return res;
  };

  render() {
    return (
      <SalesOrderDetails
        {...this.props}
        createCustomerAddress={this.handleCreateAddress}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesOrderDetailsContainer);
