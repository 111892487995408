import React from "react";
import PropTypes from "prop-types";
import {Drawer} from "antd";
import AdminContainer from "./AdminContainer";
import {useMediaQuery} from "react-responsive";
import {MOBILE} from "utils/constants";

const AdminSlidingPane = props => {
  const isTabletOrMobile = useMediaQuery(MOBILE);

  const {isPaneOpen, setPanel} = props;
  const drawerWidth = isTabletOrMobile ? "100%" : 600;

  return (
    <Drawer
      visible={isPaneOpen}
      destroyOnClose={true}
      title="Controls"
      placement="left"
      width={drawerWidth}
      className="admin-panel-drawer"
      onClose={() => setPanel(false)}>
      <AdminContainer/>
    </Drawer>
  );
};

AdminSlidingPane.propTypes = {
  /**
   * If the admin panel is open
   */
  isPaneOpen: PropTypes.bool,
  /**
   * Controls the panel being open or closed
   */
  setPanel: PropTypes.func,
};

AdminSlidingPane.defaultProps = {
  isPaneOpen: false,
};

export default AdminSlidingPane;
