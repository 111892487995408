import {combineReducers} from "redux";
import {createCrud, createNestedCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {salesOrderEndpoint} from "./constants";
import {facilitiesReducer} from "../common/reducers";
import types from "./types";

const salesOrderPaginator = createPaginator({
  name: "salesOrders",
  endpoint: salesOrderEndpoint
});

export const salesOrderCrud = createCrud({
  endpoint: salesOrderEndpoint,
  name: "salesOrders",
  withNotes: true
});

export const salesOrderItems = createNestedCrud({
  endpoint: salesOrderEndpoint,
  nestedEndpoint: "products/",
  name: "sales_order_items"
});

export const salesOrderMaterials = createNestedCrud({
  endpoint: salesOrderEndpoint,
  nestedEndpoint: "materials/",
  name: "sales_order_items"
});

const salesOrderItemActions = salesOrderItems.actions;

const salesOrderMaterialActions = salesOrderMaterials.actions;

const paginationActions = salesOrderPaginator.actions;


export {
  salesOrderPaginator,
  paginationActions,
  salesOrderItemActions,
  salesOrderMaterialActions
};


export default combineReducers({
  items: salesOrderPaginator.reducer(),
  details: salesOrderCrud.reducer(),
  facilities: facilitiesReducer(types.GET_FACILITIES_SUCCESS),
});
