import React from "react";
import PropTypes from "prop-types";
import {Card, Box} from "../../Base";
import {DashStat} from "./DashStat";
const {Meta} = Card;

const DashTile = ({title, stats, meta, actions, visualComponent}) => {
  return (
    <Box m={2} w={["100%", "50%", "31%"]}>
      <Card
        type={"inner"}
        title={title}
        hoverable
        actions={actions}
        size="small"
      >
        <Box pt="5%" display="flex" flexWrap="wrap" flex="4 4 auto" justifyContent="space-around">
          {stats.map((stat, key) => (
            <DashStat moreThanOne={stats.length > 1} {...stat} key={key}/>
          ))}
        </Box>
        {meta && <Meta style={{bottom: "0px", marginTop: "2em"}} title={meta.title} description={visualComponent || meta.description}/>}
      </Card>
    </Box>
  );
};

DashTile.propTypes = {
  /**
   * title - title displayed at top of the <DashTile/>
   */
  title: PropTypes.string.isRequired,
  /**
   * stats - an array of objects displayed by <DashStat/>
   */
  stats: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * meta - object with title and a visual component TODO: make visual components
   */
  meta: PropTypes.object,
  /**
   * actions - array of nodes with user interactions, if desired
   */
  actions: PropTypes.arrayOf(PropTypes.node)
};

DashTile.defaultProps = {
  title: "title",
  stats: [],
  meta: {},
  actions: []
};

export {DashTile};
