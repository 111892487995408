import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {DetailsPage, TagDisplay} from "Common/Details";
import {Descriptions, Box} from "Common/Base";
import {crudActions, paginationProps} from "../../../types/propTypes";
import {PMOrderForm} from "../Forms";
import {PM_ORDERS_DASHBOARD_ROUTE} from "../../../routes/constants";
import {
  AZURE_PM_ORDER_PREFIX,
} from "../../../utils/azure";
import authSelectors from "../../../store/auth/selectors";
import {preSaveFormat} from "../Forms/utils";
import {MaterialSection, ScheduleSection} from "../Sections";
import moment from "moment";
import {getStockAssetList} from "./utils";
import {setSnackMessage} from "../../../utils/snackUtils";
import {SnackBar} from "../../Common";

/*
 PM Order details page.
 Has Scheduled PM Order and Material's sections.
 Container component:
 src/containers/PMOrders/DetailsContainer.js
 */
const PMOrderDetails = props => {
  const {
    actions, details, accountInfo, defaultOrg, extra,
    materialActions, materialPagination,
    scheduleActions, schedulePagination,
    availablePersonnel, assetList, locationList,
    noteActions, notes
  } = props;
  const {fetchAvailablePersonnel, getLocations, getAssetList} = extra;

  const [showInspectionNote, setShowInspectionNote] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const assetsEnabled = authSelectors.moduleEnabled(accountInfo, "assets");

  let stockAssetList = getStockAssetList(details, assetList);

  const orderType = details?.order_type ?? "None";

  const refreshSchedule = () => {
    scheduleActions.getPage(1);
  };

  const toggleInspectionNote = () => setShowInspectionNote(!showInspectionNote);

  const preSave = (values) => {
    let preSaveFormValues = preSaveFormat(values);
    console.log("pre save values", preSaveFormValues);

    let asset = preSaveFormValues.asset;
    if (!asset) {
      asset = details?.asset?.id ?? null;
    }
    if (preSaveFormValues.start) {
      if (!(asset || details.location)) {
        setSnackMessage((msg) => setSnackMsg(msg),
          "Please specify asset or location before creating a schedule");
        return false;
      }
      if (asset) {
        console.log("asset", asset);
        let stockAssetList = assetList.find(item => item.id === asset);
        console.log("assetList", assetList);
        console.log("stock list", stockAssetList);
        if ((!stockAssetList || !stockAssetList.stocks.length)) {
          console.log("no stock asset");

          setSnackMessage((msg) => setSnackMsg(msg),
            "You must create a stockroom asset for this asset before creating a schedule");
          return false;
        }
      }
    }
    return preSaveFormValues;
  };

  const postSave = () => {
    refreshSchedule();
    // if (details && details.asset && details.asset.id) {
    //   fetchAvailablePersonnel(details.asset.id);
    // }
  };
  const fetchData = () => {
    console.log("fetch extra data");
    getAssetList();
    getLocations(defaultOrg);
    // if (details && details.asset && details.asset.id) {
    //   fetchAvailablePersonnel(details.asset.id);
    // }
    refreshSchedule();
    materialActions.getPage(1);
  };

  const renderDetails = () => {
    return details ? (
      <Descriptions
        size="small"
        column={{md: 2, sm: 1, xs: 1}}
        className={"page-details"}
      >
        <Descriptions.Item label="Name">
          {details?.name ?? "No Name"}
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {details?.description ?? "No Description"}
        </Descriptions.Item>
        {assetsEnabled && orderType !== "location" &&
          <Fragment>
            <Descriptions.Item label="Asset">{details?.asset?.name ?? "None"}</Descriptions.Item>
            <Descriptions.Item label="Stockroom Asset">{details?.stockroom_asset?.name ?? "None"}</Descriptions.Item>
          </Fragment>
        }
        <Descriptions.Item label="First Scheduled Date" span={2}>
          {details?.start && moment(details.start).format("L")}
        </Descriptions.Item>
        <Descriptions.Item label="Repeating">
          {details.repeating ? "True" : "False"}
        </Descriptions.Item>
        <Descriptions.Item label="Photo Required">
          {details.photo_required ? "True" : "False"}
        </Descriptions.Item>
        <Descriptions.Item label="Inspection Note Required">
          {details.inspection_note_required ? "True" : "False"}
        </Descriptions.Item>
      </Descriptions>
    ) : null;
  };

  const renderLeftDetails = () => {
    if (assetsEnabled && orderType !== "location") {
      return (
        <Box pt={2}>
          <TagDisplay
            tags={details?.asset?.tags ?? []}
            headerText={"ASSET TAGS"}
          />
          <TagDisplay
            tags={details?.stockroom_asset?.tags ?? []}
            headerText={"STOCKROOM TAGS"}
          />
        </Box>
      );
    }
    return null;
  };


  const formProps = {
    assetList,
    availablePersonnel,
    stockAssetList,
    assetsEnabled,
    orderDetails: details,
    editAccess: details?.permissions?.write ?? false,
    orderInspectionNoteVisible: showInspectionNote,
    accountUserType: accountInfo?.user_type ?? null,
    accountPersonnelId: accountInfo?.personnel?.id ?? null,
    fetchPersonnel: fetchAvailablePersonnel
  };

  const tabs = [
    {
      key: "schedule",
      title: "Scheduled Orders",
      content: (
        <ScheduleSection
          pagination={schedulePagination}
          fetchPage={scheduleActions.getPage}
        />
      )
    },
    {
      key: "materials",
      title: "Materials",
      content: (
        <MaterialSection
          onSubmit={actions.update}
          actions={materialActions}
          pagination={materialPagination}
          accountInfo={accountInfo}
          details={details}
        />
      )
    }
  ];
  const showLocation = Boolean(!details?.asset ?? true);

  return (
    <DetailsPage
      accountInfo={accountInfo}
      details={details}
      apiActions={actions}
      preSave={preSave}
      postSave={postSave}
      fetchData={fetchData}
      type={"PM Order"}
      hasLocation={showLocation}
      locationList={locationList}
      headerProps={{
        DetailsForm: PMOrderForm,
        editConfig: {
          formID: "pm-order-form"
        },
        formProps: formProps,
        deleteConfig: {
          confirmText: "Deleting this also delete all scheduled orders.",
          redirectUrl: PM_ORDERS_DASHBOARD_ROUTE,
        },
        text: {
          title: "PM Order Details",
          name: "PM Order",
          shortName: "orders",
          // subTitle: details?.status_display ?? "None",
        },
        renderDescription: renderDetails,
        leftDetails: renderLeftDetails
      }}
      tabs={tabs}
      hasImage
      hasAttachments
      hasNotes
      notes={{
        actions: noteActions,
        data: notes,
        toggleInspectionNote: toggleInspectionNote,
        endpoint: "pm_orders"
      }}
      fileConfig={{
        azurePrefix: AZURE_PM_ORDER_PREFIX,
      }}
    >
      {snackMsg !== "" && <SnackBar title={snackMsg} status={"error"}/>}

    </DetailsPage>
  );
};


PMOrderDetails.propTypes = {
  accountInfo: PropTypes.object,
  /**
   * ID of accountInfo's default org
   */
  defaultOrg: PropTypes.number,
  details: PropTypes.object,
  /**
   * Main redux actions for PM Orders
   */
  actions: crudActions,
  noteActions: PropTypes.object,
  materialActions: crudActions,
  scheduleActions: crudActions,
  materialPagination: paginationProps,
  schedulePagination: paginationProps,
  /**
   * List of available personnel with skills matching Asset tags
   */
  availablePersonnel: PropTypes.array,
  /**
   * List of assets with Stockroom assets expanded
   */
  assetList: PropTypes.array,
  locationList: PropTypes.array,
  /**
   * notes associated with order in details
   */
  notes: PropTypes.array
};

export default PMOrderDetails;
