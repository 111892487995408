import React, {useState} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index";
import {Fab} from "react-tiny-fab";
import {Redirect} from "react-router";

/**
 * Displays a help icon on the bottom left of the UI for going back to the specified page.
 * @param {string} prevPage The page that should be navigated to when the button is clicked
 * @class
 */
const BackButton = ({prevPage}) => {
  const [goBack, setGoBack] = useState(false);

  if (goBack) {
    return <Redirect to={prevPage}/>;
  }
  return (
    <span className="button-bar">
      <Fab
        mainButtonStyles={{backgroundColor: "#1e6cb6"}}
        position={{bottom: 24, left: 24}}
        event={"click"}
        icon={<FontAwesomeIcon
          id="backButton"
          icon={"arrow-alt-circle-left"}
          className="button-bar-left"
        />}
        alwaysShowTitle={true}
        onClick={() => setGoBack(true)} />
    </span>
  );
};

BackButton.propTypes = {
  prevPage: PropTypes.string.isRequired
};

export default BackButton;
