import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {AutoSave, FormDropdown, FormField} from "../../../Common/Forms/Base";
import {defaultStatusOptions} from "./constants.js";
import {postLoadFormat, preSaveFormat} from "./utils";
import EditableColorFormDropdown from "../../../Common/Forms/Base/EditableColorFormDropdown";

import {SubmitButton} from "../../../Common/Forms/styled";
import {Box, Row} from "../../../Common/Base";

const ScheduledPMOrderForm = (props) => {
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    personnelList,
    scheduleStatuses,
    selectedScheduleStatus,
    newScheduleStatus,
    handleScheduleStatusSelect,
    handleShowCreateScheduleStatus,
    handleShowEditScheduleStatus,
    handleCreateScheduleStatus,
    handleUpdateScheduleStatus,
    handleDeleteScheduleStatus,
    handleScheduleStatusColorChange,
    handleScheduleStatusNameChange,
    personnelEnabled,
  } = props;

  const initialValues = postLoadFormat(values);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className="item-details-form" onSubmit={handleSubmit}>
          <AutoSave
            debounce={2000}
            save={onSubmit}
            debounced={["personnel", "date", "inspection_note"]}
            preSaveFormat={preSaveFormat}
            postLoadFormat={postLoadFormat}
            formInvalid={invalid}
          />

          {personnelEnabled &&
          <FormDropdown
            options={personnelList}
            isDisabled={!editAccess}
            name="personnel"
            label="ASSIGNED TO"
            format={(value, name) => {
              if (value && typeof value === "number") {
                return personnelList.find(el => el.id === value);
              }
              return (value ? {
                value: value.id || value.value,
                label: value.name || value.label
              } : "");
            }}
            parse={(value, name) => {
              return (value ? (value.id || value.value) : "");
            }}
          />
          }

          <EditableColorFormDropdown
            dropdownName="Schedule Status"
            label={"SCHEDULE STATUS"}
            canChangeSelection={true}
            canCreateSelections={editAccess}
            defaultOptions={defaultStatusOptions}
            dropdownOptions={scheduleStatuses}
            selectedOption={selectedScheduleStatus}
            newField={newScheduleStatus}
            handleFieldSelect={handleScheduleStatusSelect}
            handleShowCreateFieldSuper={handleShowCreateScheduleStatus}
            handleShowEditFieldSuper={handleShowEditScheduleStatus}
            handleCreateField={handleCreateScheduleStatus}
            handleEditField={handleUpdateScheduleStatus}
            handleDeleteField={handleDeleteScheduleStatus}
            handleColorChange={handleScheduleStatusColorChange}
            handleNewFieldNameChange={handleScheduleStatusNameChange}/>

          <FormField
            label={"Scheduled Date"}
            type={"date"}
            name={"date"}
            isDisabled={!editAccess}
          />
          <Row>
            <Box width={1 / 2}>
              <FormField
                label="Start Time"
                type="time"
                name="start_time"
                readOnlyMode={!editAccess}
              />
            </Box>
            <Box width={1 / 2}>
              <FormField
                label="End Time"
                type="time"
                name="end_time"
                readOnlyMode={!editAccess}
              />
            </Box>
          </Row>

          <FormField
            label={"Inspection Note"}
            type={"textarea"}
            name={"inspection_note"}
            placeholder={"Inspection Note..."}
            isDisabled={!editAccess}
          />
          <br/>
          <br/>

          <SubmitButton className="item-details-form-button" loading={submitting}>
            Save
          </SubmitButton>
        </form>
      )}
    />
  );
};

ScheduledPMOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  initialValues: PropTypes.object,
  scheduleStatuses: PropTypes.array,
  selectedScheduleStatus: PropTypes.object,
  newScheduleStatus: PropTypes.object,
  handleScheduleStatusSelect: PropTypes.func,
  handleShowCreateScheduleStatus: PropTypes.func,
  handleShowEditScheduleStatus: PropTypes.func,
  handleCreateScheduleStatus: PropTypes.func,
  handleUpdateScheduleStatus: PropTypes.func,
  handleDeleteScheduleStatus: PropTypes.func,
  handleScheduleStatusColorChange: PropTypes.func,
  handleScheduleStatusNameChange: PropTypes.func,
  /**
   * If the Personnel module is enabled for this org
   */
  personnelEnabled: PropTypes.bool,
};

ScheduledPMOrderForm.defaultProps = {
  values: {},
};

export default ScheduledPMOrderForm;
