import React, {Component} from "react";
import PropTypes from "prop-types";
import {Box, Flex} from "Common/Base";
import {
  AZURE_BLOB_CONTAINER_NAME,
  handleChangeWarrantyDocument,
  removeDocument,
} from "../../../utils/azure";
import {AttachedFiles} from "../index";


class Attachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savingVisible: false,
      snackMsg: "",
      displayImage: "",
      warrantyDocument: "",
    };
  }

  handleChangeWarrantyDocumentField = async documentName => {
    const {id, updateDetails} = this.props;

    await handleChangeWarrantyDocument(this, documentName, id, updateDetails);
  };

  handleRemoveDocument = documentName => {
    const {id, getDocuments, updateDetails} = this.props;
    return removeDocument(this, documentName, id, updateDetails, getDocuments);
  };


  render() {
    const {
      files,
      uuid,
      fetchDetails,
      azurePrefix,
      warrantyDocument,
      displayImage,
    } = this.props;

    return (
      <Flex flexDirection={["column", "column", "column", "row"]}>
        <Box m={3} p={4} w={["100%", "100%", "100%", "50%"]}>
          <AttachedFiles
            files={files}
            displayImage={displayImage}
            warrantyDocument={warrantyDocument}
            azureDocumentPrefix={azurePrefix}
            UUID={uuid}
            getDocuments={this.getDocuments}
            handleChangeWarrantyDocument={this.handleChangeWarrantyDocumentField}
            handleRemoveDocument={this.handleRemoveDocument}
            refreshObject={fetchDetails}
          />
        </Box>
      </Flex>
    );
  }
}

Attachments.propTypes = {
  /**
   * id of item with attachments
   */
  id: PropTypes.string,
  /**
   * DetailsPage fetchDetails function
   */
  fetchDetails: PropTypes.func.isRequired,
  /**
   * UUID of item with attachments
   */
  uuid: PropTypes.string.isRequired,
  /**
   * document specific prefix for azure
   */
  azurePrefix: PropTypes.string.isRequired,
  /**
   * project specific prefix for azure
   */
  azureBlob: PropTypes.string,
  /**
   * Warranty document url
   */
  warrantyDocument: PropTypes.string,
  /**
   * Display image url
   */
  displayImage: PropTypes.string,
  /**
   * getDocuments callback function from DetailsPage
   */
  getDocuments: PropTypes.func.isRequired
};

Attachments.defaultProps = {
  azureBlob: AZURE_BLOB_CONTAINER_NAME
};

export default Attachments;
