import {RequestError} from "redux-api-middleware";
import queryString from "query-string";

/**
 * Checks a response for an error, and throws the error if it is one
 * @param {object} res The response object from the backend
 * @throws The Error object from the RSAA payload, if it exists
 */
const checkForResponseError = res => {
  if (res === undefined || res instanceof Error) {
    throw res;
  } else if (res.payload instanceof Error) {
    throw res.payload;
  }
};

/**
 * Handles the error response, setting `errorLoadingMessage` to a descriptive error message
 * @param {object} obj The state of the container (this)
 * @param {object} error The error response message
 * @param {string} item The name of the container
 */
const handleErrorResponse = (obj, error, item) => {
  if (error === undefined) {
    obj.setState({errorLoadingMessage: "Your session has expired, please refresh the page or log in again"});
    return;
  }
  const errorStatus = error.status;
  if (errorStatus === 403) {
    obj.setState({errorLoadingMessage: `You don't have access to this ${item}`});
  } else if (errorStatus === 404) {
    obj.setState({errorLoadingMessage: `${item} doesn't exist`});
  } else if (error instanceof RequestError) {
    obj.setState({errorLoadingMessage: "API Connection Error"});
  } else {
    obj.setState({errorLoadingMessage: `Couldn't load ${item}`});
  }
};

/**
 * Function to call during ComponentDidMount to check and apply filterby if present in the url
 * @param {string} search The query string after the url like ?filterby=issue_reported
 * @param {Function} filterListBy Function that performs the filtering by a specific filter
 */
const checkFiltersDidMount = (search, filterListBy) => {
  const query = queryString.parse(search);
  if (Object.prototype.hasOwnProperty.call(query, "filterby")) {
    filterListBy(query.filterby);
  } else {
    filterListBy("");
  }
};


/**
 * Function to be called during ComponentDidUpdate to check and apply filterby if present in the url
 * @param {object} props Current props after the update
 * @param {object} prevProps props received before the update
 */
const checkFiltersDidUpdate = (props, prevProps) => {
  const {location, filterListBy} = props;
  const prevQuery = queryString.parse(prevProps.location.search);
  const query = queryString.parse(location.search);
  if (!Object.prototype.hasOwnProperty.call(query, "filterby") &&
    Object.prototype.hasOwnProperty.call(prevQuery, "filterby")) {
    filterListBy("");
  } else if (Object.prototype.hasOwnProperty.call(query, "filterby") &&
    prevQuery.filterby !== query.filterby) {
    filterListBy(query.filterby);
  }
};

/**
 * This function handles the onChange event of dropdown select filters, so it can apply the correct filter and update the url
 * @param {Event} e Event object from the dropdown component
 * @param {string} queryField Field to check among the query strings, for instance filterby or type
 * @param {string} urlObjectName The string representing the object in the url, for instance assets
 * @param {Function} filterBy Function to be called to apply the filter
 * @param {object} location Object use to get the query strings from the url
 * @param {object} history Object used to push the new filter values in the url
 */
const handleFilterDropdownChange = (e, queryField, urlObjectName, filterBy, location, history) => {
  const query = queryString.parse(location.search);
  if (e.value !== "") {
    query[queryField] = e.value;
  } else {
    delete query[queryField];
  }
  history.push(`/dashboard/${urlObjectName}?${queryString.stringify(query)}`);
  filterBy(e.value);
};

/**
 * Scrolls to the top of the page
 */
const scrollToTop = () => {
  window.scrollTo(0, 0);
};

/**
 * A base state that all of the details containers has
 * @param {string} detailsName What the name of the details container is
 * @returns {object} The base state
 */
const baseDetailsState = detailsName => {
  return {
    [detailsName + "ID"]: "",
    [detailsName + "Data"]: {},
    [detailsName + "UUID"]: "",
    [detailsName + "Name"]: "",
    [detailsName + "Files"]: [],
    [detailsName + "OrgId"]: "",
    adminControlsSubmitting: false,
    showLoader: true,
    showFormLoader: false,
    adminControlsMessage: "",
    errorLoadingMessage: "",
    submitting: false,
    editAccess: false,
    displayImage: "",
    warrantyDocument: "",
  };
};

export {
  checkForResponseError,
  handleErrorResponse,
  checkFiltersDidMount,
  checkFiltersDidUpdate,
  handleFilterDropdownChange,
  scrollToTop,
  baseDetailsState
};

