import {createPaginator} from "../base/paginator";
import {materialEndpoint} from "./constants";

const materialPaginator = createPaginator({
  name: "materials",
  endpoint: materialEndpoint
});

const paginationActions = materialPaginator.actions;


export {
  materialPaginator,
  paginationActions
};
