// customers duck
import {combineReducers} from "redux";
import {createCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {locationsEndpoint} from "./constants";

const warehouseLocationsPaginator = createPaginator({
  name: "warehouseLocations",
  endpoint: locationsEndpoint
});

export const warehouseLocationCrud = createCrud({
  endpoint: locationsEndpoint,
  name: "warehouseLocations",
  withNotes: false
});


const warehouseLocationActions = warehouseLocationCrud.actions;
const warehouseLocationOperations = warehouseLocationCrud.operations;

const warehouseLocationPageActions = warehouseLocationsPaginator.actions;


export {
  warehouseLocationsPaginator,
  warehouseLocationPageActions,
  warehouseLocationActions,
  warehouseLocationOperations
};


export default combineReducers({
  items: warehouseLocationsPaginator.reducer(),
  details: warehouseLocationCrud.reducer(),
});
