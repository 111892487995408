import React, {Component} from "react";
import {connect} from "react-redux";
import {authOperations, authSelectors, orgOperations, orgSelectors} from "../../store";

import Login from "../../components/Login/Login";
/* This is the Login container where users initially arrive. Other pages
aren't accessible until a user has registered and logged in successfully. */
class LoginContainer extends Component {
  render() {
    return (
      <Login {...this.props}/>
    );
  }
}

const mapStateToProps = state => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  authenticated: authSelectors.isAuthenticated(state.account),
  authErrorMessage: authSelectors.loginErrorMessage(state.account),
  submittingLogin: authSelectors.attemptingLogin(state.account),
  allOrgs: orgSelectors.allItems(state.orgs),
  errors: orgSelectors.refreshErrors(state.orgs)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (email, password) => dispatch(authOperations.login(email, password)),
  getAllOrgs: () => dispatch(orgOperations.getAllItems())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
