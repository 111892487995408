import React, {Component} from "react";
import {LocationSection, PalletForm} from "../../../Common";
import {Box, ErrorBoundary, Heading} from "../../../Common/Base";
import {SelectInventoryDrawer} from "../../Drawers";
import {generateBreadcrumb} from "../../../../utils/location";
import {ManifestTable, PalletMaterialTable, PalletProductTable} from "./Tables";
import {palletInventory, palletMaterials, palletStatus} from "../../utils";
import {DetailsContainer} from "../../../Common/Details";
import DetailsHeader from "./DetailsHeader";
import {ReceivePalletDrawer} from "../../Drawers/ReceivePallet";
import {PRODUCT_INVENTORY} from "../../utils/constants";
import {CODE_128_BARCODE} from "../../../Common/Files/constants";
import PickupButton from "./PickupButton";

class PalletDetails extends Component {
  constructor(props) {
    super(props);
    this.palletID = props.match.params.palletID;
    this.state = {
      isEditing: false,
    };
  }

  toggleEdit = () => {
    const {isEditing} = this.state;
    this.setState({isEditing: !isEditing});
  };

  // TODO: Make sure submit location is attaching the proper location to a pallet
  handleSubmitLocation = async (value) => {
    console.log("location value", value);
    const {
      palletDetails,
      updatePallet,
      fetchData,
    } = this.props;
    const updateValue = {location: value.location.location_instance};
    const res = await updatePallet(palletDetails.id, updateValue);
    if (!res.error) {
      fetchData();
    }
  };

  // TODO: Make sure the SKU is being assigned
  onFormSubmit = async (values) => {
    const {isEditing} = this.state;
    const {palletDetails, updatePallet} = this.props;
    await updatePallet(palletDetails.id, values);
    if (isEditing) {
      this.toggleEdit();
    }
  };

  handleSubmitBarcode = async values => {
    const {palletDetails, updatePallet} = this.props;
    const res = await updatePallet(palletDetails.id, values);
    if (!res.error) {
      this.fetchData();
    }
  };

  generateBarcode = () => {
    const {generateBarcode, palletDetails} = this.props;
    return generateBarcode(this.palletID, palletDetails.org.id, CODE_128_BARCODE);
  };

  renderLocationSection = () => {
    const {
      accountInfo,
      locationList,
      palletDetails,
    } = this.props;
    const locationData = palletDetails.location ? palletDetails.location : {level: "None"};
    const isAdmin = accountInfo.user_type <= 1;

    return (
      <Box display={"inline-block"} key={"location-section"}>
        <LocationSection
          locationData={locationData}
          locationList={locationList}
          submit={this.handleSubmitLocation}
          isAdmin={isAdmin}
          hideCard
          buttonConfig={{
            size: "small",
            type: Object.keys(locationData).length !== 0 ? "link" : "default",
          }}
          wrapperStyle={{mt: 0}}

        />
      </Box>
    );
  };

  renderReceivePallet = () => {
    const {
      accountInfo,
      palletDetails,
      updatePallet,
      receivePallet,
      warehouseLocationPagination,
      getWarehouseLocations,
      createWarehouseLocation,
      pickupPallet,
    } = this.props;

    if (palletDetails.status === palletStatus.OUTGOING) {
      return (
        <PickupButton
          onSubmit={pickupPallet}
        />
      );
    }

    if (palletDetails.status === palletStatus.INCOMING) {
      return (
        <ReceivePalletDrawer
          accountInfo={accountInfo}
          palletDetails={palletDetails}
          onSubmit={receivePallet}
          updatePallet={updatePallet}
          locationPagination={warehouseLocationPagination}
          getLocations={getWarehouseLocations}
          createLocation={createWarehouseLocation}
        />
      );
    }
    return null;
  };

  renderInventoryButton = () => {
    const {
      inventoryList,
      palletDetails,
      addInventory,
      inventoryPagination,
      getInventory,
    } = this.props;

    const locationData = palletDetails.location ? palletDetails.location : {level: "None"};

    return (
      <SelectInventoryDrawer
        key={"add-inventory"}
        loading={inventoryPagination.pageLoading}
        inventoryList={inventoryList ? inventoryList : []}
        locationData={locationData}
        onSubmit={addInventory}
        pagination={inventoryPagination}
        getInventory={getInventory}
        type={PRODUCT_INVENTORY}
        buttonText={"Add Inventory"}
        buttonConfig={{
          size: "small",
          style: {marginLeft: "10px"},
        }}
      />
    );
  };

  render() {
    const {isEditing} = this.state;
    const {
      loading,
      itemNotFound,
      accountInfo,
      locationList,
      palletDetails,
      updatePallet,
      deletePallet,
      productPagination,
    } = this.props;


    const productList = productPagination.itemsPaged;
    const locationLabel = palletDetails.location ? generateBreadcrumb(palletDetails.location) : "Select a location";

    const locationData = palletDetails.location ? palletDetails.location : {level: "None"};

    const currentInventory = palletInventory(palletDetails);

    const currentMaterials = palletMaterials(palletDetails);

    return (
      <div>
        <DetailsContainer
          type={"Pallet"}
          notFound={itemNotFound}
          loading={loading}
        >
          <ErrorBoundary>
            <DetailsHeader
              data={palletDetails}
              onDelete={deletePallet}
              account={accountInfo}
              locationData={locationData}
              isEditing={isEditing}
              toggleEdit={this.toggleEdit}
              handleSubmitBarcode={this.handleSubmitBarcode}
              generateBarcode={this.generateBarcode}
              FormComponent={
                <Box px={3}>
                  <PalletForm
                    locationData={{location: palletDetails ? palletDetails.location : null}}
                    updatePallet={updatePallet}
                    palletDetails={palletDetails}
                    onSubmit={this.onFormSubmit}
                    locationList={locationList}
                    productOptions={productList}
                    setLocationData={this.handleSubmitLocation}
                    locationLabel={locationLabel}
                    loading={loading}
                  />
                </Box>
              }
              locationSection={this.renderLocationSection()}
              actions={[
                this.renderReceivePallet(),
              ]}
            />
          </ErrorBoundary>

          {palletDetails?.manifest && palletDetails?.manifest.length > 0 &&
             <Box py={2}>
               <Heading py={2}>
              Manifest
               </Heading>

               <ManifestTable
                 items={palletDetails.manifest}
                 loading={loading}
               />

             </Box>
          }

          <Box py={2}>
            <Heading py={2}>Inventory
              {this.renderInventoryButton()}
            </Heading>

            <PalletProductTable
              items={currentInventory}
              loading={loading}
            />

          </Box>

          <Box py={2}>
            <Heading py={2}>Materials</Heading>

            <PalletMaterialTable
              items={currentMaterials}
              loading={loading}
            />
          </Box>

        </DetailsContainer>
      </div>
    );
  }
}


export default PalletDetails;
