import React from "react";
import {ScorecardDashboard} from "../../../Common/Dashboard/ScorecardDashboard";
import {summaries} from "./data";

const WarehouseScorecard = props => {
  const {summary} = props;

  return (
    <ScorecardDashboard
      title="Warehouse Scorecard"
      summaries={summaries(summary)}
    />
  );
};

export {WarehouseScorecard};

