import React from "react";
import styled from "@emotion/styled";
import {IconButton, Flex, Text} from "../Base";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    textAlign: "left",
  },
  selectedListItem: {
    textAlign: "left",
    backgroundColor: theme.palette.background.default,
  },
  subheader: {
    backgroundColor: "white",
  },
}));

const SecondaryText = styled("span")`
  font-size: 1.25rem;
`;


const ListButton = (props) => <IconButton {...props}/>;

ListButton.defaultProps = {
  isRound: true,
  size: "md",
  variant: "ghost",
  fontSize: "20px"
};

const CaretWrapper = styled(Flex)`

`;

CaretWrapper.defaultProps = {
  w: "15px",
  mr: "10px",
};

const ListSubheader = styled(Text)``;

ListSubheader.defaultProps = {
  as: "li",
  fontWeight: "semibold",
  color: "meta",
  fontSize: "sm",
  ml: "1em"
};

export {SecondaryText, ListButton, CaretWrapper, ListSubheader, useStyles};
