import React from "react";
import PropTypes from "prop-types";
import {List} from "@material-ui/core";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {useStyles} from "../styled";
import ScheduleListItem from "./ScheduleListItem";

/*
This will render a list of Schedule's with a sub list of Shifts
 */
const ScheduleList = props => {
  const {items, handleItemClick, editShift, setSchedule, current, addPersonnel, selectPersonnel} = props;
  const classes = useStyles();

  const handleClick = (item) => {
    console.log("edit schedule item", item);
    handleItemClick(item);
  };

  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      {items.length !== 0 &&
      <List className={classes.list}>
        {items.map(item => {
          return (
            <ScheduleListItem
              key={item.id}
              current={current}
              item={item}
              onClick={handleClick}
              onClickShift={editShift}
              classes={classes}
              setSchedule={setSchedule} // So edit Shift has an ID to use
              addPersonnel={addPersonnel}
              selectPersonnel={selectPersonnel}
            />
          );
        })}
      </List>
      }
    </ReactCSSTransitionGroup>
  );
};

ScheduleList.propTypes = {
  items: PropTypes.array,
  handleItemClick: PropTypes.func,
  editShift: PropTypes.func,
  setSchedule: PropTypes.func,
  addPersonnel: PropTypes.func,
  selectPersonnel: PropTypes.func,
  isEditable: PropTypes.bool
};

ScheduleList.defaultProps = {

};

export default ScheduleList;
