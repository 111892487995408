import moment from "moment";
import types from "./types";

import {filters, sort} from "../../utils";
import {baseReducer, getNumberOfPages, INITIAL_STATE} from "../base/reducers";

const initialState = {...INITIAL_STATE};

/**
 * Function used to filter PM Orders by overdue or inspect_today
 * @param {string} filteringBy which filter is applied, '', 'overdue', 'inspect_today'
 * @param {Array} items an array of PM Orders
 * @returns {Array} All PM Orders filtered by the argument
 */
const filteringByList = (filteringBy, items) => {
  if (filteringBy === "created_today") {
    return filters.filterListByDate(items, "created_at", moment(), (a, b) => a.isSame(b, "day"));
  } else if (filteringBy === "needs_approval") {
    return filters.filterListByKey(items, "status", a => a === 1);
  } else if (filteringBy === "occurring_today") {
    return filters.filterListByDate(items, "date", moment(), (a, b) => a.isSame(b, "day"));
  }
  return items;
};

const vendorsReducer = (state = initialState, action) => {
  switch (action.type) {
  /* Sets items to payload returned from getAllItems in operations. */
  case types.GET_ALL_SUCCESS: {
    let newItems = [];

    /* If success = 0, action.vendors.message is a string and not an array
      and must be handled differnetly. Check payload in redux-devtools for
      debugging issues like this. */

    newItems = action.payload;
    const data = {};
    newItems.forEach(item => data[item.id] = item);
    const newList = filteringByList(state.filteringBy, newItems);
    const filteredList = sort.sortByKey(filters.filterListByTitle(newList, state.filter), state.sortBy);
    const pages = getNumberOfPages(newItems.length, state.itemsPerPage);
    let noneFound = false;
    if (action.payload.length === 0) {
      noneFound = true;
    }
    return {
      ...state,
      loading: false,
      items: action.payload,
      pages,
      data,
      filterList: filteredList,
      currentPage: 1,
      pageList: filteredList.slice(0, state.itemsPerPage),
      noneFound
    };
  }
  case types.GET_ALL_FAILURE:
    return {
      ...state,
      loading: false,
      errors: action
    };
  case types.GET_ALL_NONE_FOUND:
    return {
      ...state,
      loading: false,
      noneFound: true
    };
  case types.GET_ITEM_SUCCESS: {
    const newData = {
      ...state.data,
      [action.payload.id]: action.payload
    };
    return {
      ...state,
      loading: false,
      data: newData,
    };
  }
  case types.GET_ITEM_FAILURE: {
    return {
      ...state,
      loading: false,
      errors: action
    };
  }

  case types.UPDATE_ITEM_FAILURE: {
    return {
      ...state,
      errors: action.payload
    };
  }
  case types.UPDATE_ITEM_SUCCESS: {
    // The update serializer returns limited data (asset etc), so only select the data that could be updated on the vendor form
    const selectedData = (({title, date, materials, status, type, personnel, inspection_note, display_image, is_on_site, issues}) => {
      return ({title, date, materials, status, type, personnel, inspection_note, display_image, is_on_site, issues});
    })(action.payload);
    const {id} = action.payload;
    const {items} = state;
    const index = items.findIndex(vendorItem => vendorItem.id === id);
    const item = items[index];
    let newItems = items;
    let updatedItem = null;
    // Combine the selected updated fields with the fields from the original item
    if (item && selectedData) {
      updatedItem = Object.assign(item, selectedData);
      newItems[index] = updatedItem;
    }
    // Console.log('update item success', state.items, action.payload, selectedData, updatedItem);
    const newData = {
      ...state.data,
      [action.payload.id]: updatedItem || item
    };
    return {
      ...state,
      items: newItems,
      newData
    };
  }
  case types.GET_ITEM_NOTES_SUCCESS: {
    const newNotes = {
      ...state.notes,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      notes: newNotes
    };
  }

  case types.FILTER_LIST: {
    let newList = filters.filterListByAll(state.items, action.filter);
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filter: action.filter,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  /* Filters items in state by a field specified in the action payload, overdue or inspect_today. */
  case types.FILTER_LIST_BY: {
    const filteringBy = action.payload;
    // If filtering by is overdue or inspect_today filter otherwise do not filter the items.
    let newList = filteringByList(filteringBy, state.items);

    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filteringBy,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  default:
    return state;
  }
};

export default baseReducer(types, vendorsReducer);
