import {getAxios} from "./authWithAxios";

/**
 * The base URL for the mapping library we are using
 * @type {string}
 */
const MAPS_BASE_URL = "https://nominatim.openstreetmap.org/";

/**
 * How the data should returned from the mapping library
 * @type {string}
 */
const OUTPUT_FORMAT = "jsonv2";

/**
 * How many results should be returned from a search
 * @type {number}
 */
const RESULTS_TO_RETURN = 1;

/**
 * Given a location object, returns the most nested location rung set on the location object.
 * The rungs are:
 *  * Region <- Most shallow
 *  * District
 *  * Facility
 *  * Wing
 *  * Zone
 *  * Subzone <- Deepest
 * @param {object} location - The location object
 * @returns {Array} - Tuple: What location rung is being returned, and the deepest location rung
 */
const getDeepestLocation = (location) => {
  if (!location) {
    return ["", {}];
  } else if (location.subzone) {
    return ["subzone", location.subzone];
  } else if (location.zone) {
    return ["zone", location.zone];
  } else if (location.wing) {
    return ["wing", location.wing];
  } else if (location.facility) {
    return ["facility", location.facility];
  } else if (location.district) {
    return ["district", location.district];
  } else if (location.region) {
    return ["region", location.region];
  } else {
    return ["", {}];
  }
};

/**
 * Given a locationData object, returns a string breadcrumb of the selected location hierarchy.
 * ex.) "regionName > districtName > facilityName etc..."
 * The rungs are:
 *  * Region <- Most shallow
 *  * District
 *  * Facility
 *  * Wing
 *  * Zone
 *  * Subzone <- Deepest
 * @param {object} locationData - The location object
 * @returns {string} - string: The selected location hierarchy ie "region_name > distric_name > ..." etc
 */
const generateBreadcrumb = (locationData) => {
  let breadcrumb = [];
  if (locationData && locationData.region) {
    const locationNames = (({region, district, facility, wing, zone, subzone}) => {
      return [region, district, facility, wing, zone, subzone];
    })(locationData);
    locationNames.forEach((location) => {
      if (location) breadcrumb.push(location.name);
    });
    return breadcrumb.join(" > ");
  } else return "";
};

/**
 * Converts the location from the backend to a location that leaflet understands
 * @param {Array.number} coordinates 0th index is longitude, 1st index is latitude
 * @returns {object} The coordinates as an object
 */
function djangoLocationToLeaflet(coordinates) {
  return {
    lat: coordinates[1],
    lng: coordinates[0]
  };
}

/**
 * Converts an array of coordinates to a location that Django understands
 * @param {Array.number} coordinates 0th index is longitude, 1st index is latitude
 * @returns {object} The coordinates as an object that Django will understand
 */
function coordinatesToDjangoLocation(coordinates) {
  return {
    "type": "Point",
    "coordinates": coordinates
  };
}

/**
 * Get the nearest address based on the provided coordinates
 * @param {number} lat The latitude of the location
 * @param {number} lon The longitude of the location
 * @returns {Promise} A promise that resolves when the network request has completed
 */
function findLocationByCoordinates(lat, lon) {
  return getAxios({
    url: `${MAPS_BASE_URL}reverse?lat=${lat}&lon=${lon}&format=${OUTPUT_FORMAT}`
  }).then(res => res.data).catch(e => {
    console.error("Unable to fetch location by coordinates: " + e);
    throw e;
  });
}

/**
 * Get the nearest address based on the provided coordinates
 * @param {string} location The approximate address of the location
 * @returns {Promise} A promise that resolves when the network request has completed
 */
function findLocationByString(location) {
  return getAxios({
    url: `${MAPS_BASE_URL}search?addressdetails=1&q=${encodeURI(location)}&limit=${RESULTS_TO_RETURN}&format=${OUTPUT_FORMAT}`
  }).catch(e => {
    console.error("Unable to fetch location by string: " + e);
    throw e;
  });
}

export {
  findLocationByCoordinates,
  findLocationByString,
  djangoLocationToLeaflet,
  coordinatesToDjangoLocation,
  getDeepestLocation,
  generateBreadcrumb
};
