import actions from "./actions";
import {paginationActions} from "./pagination";
import {baseOperations} from "../../base";
import {orgOperations} from "../../orgs";
import {dateUtils, snackUtils} from "../../../utils";
import {productCrud} from "./index";


const createItemQuantity = (data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Product", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.createItemQuantity(data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Product", res.payload.name, dispatch, () => {
    dispatch(orgOperations.getRecentLogs());
  });
  // TODO - Refactor the NewProductInventoryQuantitySerializer to return the full serialized data so
  // so that we don't have to send another get request.
  if (!res.error) {
    dispatch(actions.getItem(data.product));
  }
  return res.payload;
};

const bulkDelete = (data) => async dispatch => {
  const res = await dispatch(actions.bulkDelete(data));
  console.log("BULK OPERATIONS: ", res);
  dispatch(paginationActions.fetchPage({page: 1}));
};

/* This Function handles sending an Item update request to the API
for a specific field. Then dispatches either the updateSuccess or
updateFailure action. Also dispatches snackbar messages on start
and finish, and updates asset data upon success. */
const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateItem(id, fieldData));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Product", res.payload.name, dispatch, () => {
    // dispatch(getAllItems());
    dispatch(actions.getItem(id));
  });
};

/* This Function handles adding a new item to the current parent item. */
const uploadCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch);
  dispatch(paginationActions.fetchPage({page: 1}));
  return res;
};

/* This Function handles adding a new item to the current parent item. */
const uploadInventoryCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadInventoryCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch);
  dispatch(paginationActions.fetchPage({page: 1}));
  return res;
};


export default {
  ...productCrud.operations,
  createItemQuantity,
  updateItemField,
  uploadCsv,
  uploadInventoryCsv,
  bulkDelete
};
