/**
 * Gets stock asset list from pm order details asset
 * @param {{}} details - PM Order details
 * @param {[]} assetList - Asset list
 * @returns {[]} - StockRoom Asset list
 */
export const getStockAssetList = (details, assetList) => {
  let stockAssetList = null;
  if (assetList.length) {
    stockAssetList = assetList.find(item => item.id === details?.asset?.id ?? "");
    stockAssetList = stockAssetList?.stocks ?? null;
  }
  return stockAssetList;
};
