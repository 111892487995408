import React from "react";
import PropTypes from "prop-types";
import {List, Text, IconButton, Link, PopConfirmButton} from "../../../../Common/Base";
import {DeleteOutlined} from "@ant-design/icons";
import {STOCKROOM_DASHBOARD_ROUTE} from "../../../../../routes/constants";

const AssetListItem = props => {
  const {
    item,
    onDelete
  } = props;

  const handleDelete = () => onDelete(item.id);

  return (
    <List.Item
      className={"asset-list-item"}
      actions={[
        <PopConfirmButton
          key="delete-item"
          className={"delete-asset-button"}
          onConfirm={handleDelete}
          Button={IconButton}
          icon={DeleteOutlined}
        />
      ]}
    >
      <Text><Link to={`${STOCKROOM_DASHBOARD_ROUTE}/${item.id}`}>{item.name === "" ? "No Name" : item.name}</Link></Text>
    </List.Item>
  );
};

AssetListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func

};

export default AssetListItem;
