import {salesOrderCrud} from "./index";
import {paginationActions} from "./reducers";
import types from "./types";
import {createAction} from "redux-api-middleware";
import {default as orgActions} from "../../orgs/actions";

const getFacilities = (org) => orgActions.getFacilities({
  org,
  typesList: [types.GET_FACILITIES_REQUEST,
    {
      type: types.GET_FACILITIES_SUCCESS,
    },
    types.GET_FACILITIES_FAILURE]
});

const packItems = (id, data) => createAction({
  endpoint: `${global.base_url}v1/warehouse/sales_orders/${id}/pack_items/`,
  method: "POST",
  body: JSON.stringify(data),
  headers: {"Content-Type": "application/json"},
  types: [types.PACK_SALES_ORDER_REQUEST, types.PACK_SALES_ORDER_SUCCESS, types.PACK_SALES_ORDER_FAILURE]
});

export default {
  ...salesOrderCrud.actions,
  ...paginationActions,
  getFacilities,
  packItems
};
