import React from "react";
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import ScheduleRule from "./ScheduleRule";

import {Label, LabelText} from "../../Forms/Base/styled";
import {adapt} from "../../Forms/Base/utils";

const AdaptedSchedule = adapt(ScheduleRule);

const ScheduleField = props => {
  const {
    label,
    name,
    generatorConfig
  } = props;
  return (
    <Label htmlFor={name} width="100%">
      <LabelText>{label}:</LabelText>
      <Field
        name={name}
        component={AdaptedSchedule}
        placeholder={label}
        generatorConfig={generatorConfig}
      />
    </Label>
  );
};


ScheduleField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  generatorConfig: PropTypes.object
};

ScheduleField.defaultProps = {
  label: "Schedule"
};

export default ScheduleField;
