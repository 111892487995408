import React from "react";
import PropTypes from "prop-types";
import {Flex} from "Base";
import {Form} from "react-final-form";
import {FormField} from "Common/Forms/Base";
import {AdaptedInput, AdaptedTextarea} from "Common/Base/Inputs/AdaptedInputs";
import {Alert} from "antd";

const SupplierForm = ({onSubmit, initialValues, editAccess, id, errors}) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form id={id} onSubmit={handleSubmit}>

          <Flex flexDirection={"column"}>
            {errors && errors.name &&
              <Alert message={errors.name[0]} type={"error"}/>
            }
            <FormField
              placeholder={"Name.."}
              label="Name"
              type="text"
              name="name"
              readOnlyMode={!editAccess}
              component={AdaptedInput}
            />

            <FormField
              placeholder={"Notes"}
              label="Notes"
              type="text"
              name="notes"
              readOnlyMode={!editAccess}
              component={AdaptedTextarea}
            />
          </Flex>
        </form>
      )}
    />
  );
};

SupplierForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  id: PropTypes.string,
  editAccess: PropTypes.bool,
  errors: PropTypes.object
};

SupplierForm.defaultProps = {
  id: "supplier-form",
  editAccess: false,
};
export default SupplierForm;
