
export default {
  primary: {
    display: "block",
    margin: " 20px auto 20px",
    padding: "5px 10px 5px 10px",
    width: "140px",
    textDecoration: "none",
    fontSize: "1.6em",
    fontWeight: "bold",
    color: "white",
    // BackgroundImage: 'linear-gradient(to right, #42afda, #2c3655)',
    textAlign: "center",
  },
  secondary: {
    width: ["120px", "180px"],
    p: "10px 15px 10px 20px",
    fontSize: ["14px"],
    m: "10px"
  }
};
