function refreshNameList(state) {
  if (state.items) {
    return state.items.map(purchaseOrder => ({
      id: purchaseOrder.id,
      location: purchaseOrder.location
    }));
  }
  return [];
}

function refreshInventory(state) {
  if (state.inventory) {
    return state.inventory;
  }
  return [];
}


export default {
  refreshNameList,
  refreshInventory
};
