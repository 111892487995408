import React, {Fragment, useReducer, useState} from "react";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import {Button} from "antd";
import {Box, Drawer} from "Common/Base";
// import {Button} from "../../Common/Base/Button/Button";
import {MaterialList, SelectingMaterialList} from "../../Lists/Materials";
import {DEFAULT_PAGE_SIZE} from "../../PurchaseOrders/Details/constants";
import {actions, getItems, initialState, selectMaterialReducer} from "../../utils/cartReducer";
import {SearchInput} from "Common/Forms";

const SelectMaterialDrawer = (props) => {
  const [selectedState, dispatch] = useReducer(selectMaterialReducer, initialState);
  const [modelOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const isTabletOrMobile = useMediaQuery({query: "(max-width: 600px)"});

  const {
    selectItem,
    deselectItem,
    editItem,
  } = actions;

  const {pagination, materialList, fetchProducts, loading, addQuantity} = props;

  const handleSelectItem = (item) => {
    console.log("item", item);
    /* For selecting a Product/Inventory that will have a quanitty added */
    dispatch(selectItem(item));
  };

  const handleEditQuantity = (values) => {
    dispatch(editItem(values));
  };

  const handleDeselectItem = (id) => {
    dispatch(deselectItem(id));
  };

  const toggleModal = () => {
    setModalOpen(!modelOpen);
    if (modelOpen === false) {
      dispatch(actions.reset());
    }
  };


  const handleSubmit = async () => {
    const idList = selectedState.ids;
    const submitValues = selectedState.forSubmit;
    if (idList.length > 0) {
      setIsSubmitting(true);
      for (let i in idList) {
        await addQuantity(submitValues[idList[i]]);
      }
      setIsSubmitting(false);
    }
    toggleModal();
  };

  const handleSearch = (value) => {
    fetchProducts(1, {search: value, page_size: DEFAULT_PAGE_SIZE});
    setSearchTerm(value);
  };

  const handleFetchPage = (page) => {
    fetchProducts(page, {search: searchTerm, page_size: DEFAULT_PAGE_SIZE});
  };

  const selectedProductList = getItems(selectedState);

  // console.log("selected Products", selectedProduct);

  // const title = makeTitle(locationData);

  const drawerWidth = isTabletOrMobile ? "100%" : 600;

  const disableSave = selectedState.ids.length === 0;
  return (
    <Fragment>

      {!loading &&
      <Button
        className={"add-material-button"}
        onClick={toggleModal}
        my={3}
        type={"primary"}>
        Add Materials
      </Button>
      }
      <Drawer
        className={"order-materials-drawer"}
        visible={modelOpen}
        onClose={toggleModal}
        width={drawerWidth}
        title={"Select Materials For Order"}
        zIndex={isTabletOrMobile ? 10000 : 1000}
        footer={
          <Box textAlign={"right"}>
            <Button
              key={"cancel"}
              className={"modal-close-button"}
              onClick={toggleModal}
              style={{marginRight: 8}}
              // variantColor={"teal"}
            >Close
            </Button>
            <Button
              className={"save-btn"}
              onClick={handleSubmit}
              type="primary"
              disabled={disableSave}
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        }
      >
        <SearchInput
          onSubmit={handleSearch}
        />

        {modelOpen && // this forces InventoryList to rerender and clears hooks when modal opens
        <Box minHeight={"455px"}>
          <MaterialList
            pagination={pagination}
            fetchPage={handleFetchPage}
            data={materialList}
            selected={selectedState.ids}
            onSelect={handleSelectItem}
            onDeselect={handleDeselectItem}
            loading={loading}
          />
        </Box>
        }

        <SelectingMaterialList
          data={selectedProductList}
          onEdit={handleEditQuantity}
          onRemove={handleDeselectItem}
        />
      </Drawer>
    </Fragment>
  );
};

SelectMaterialDrawer.propTypes = {
  productList: PropTypes.array,
  location: PropTypes.any,
  onSubmit: PropTypes.func,
  addQuantity: PropTypes.func,
  purchaseOrderItemLookup: PropTypes.object,
  max: PropTypes.number,
  pagination: PropTypes.object,
};

SelectMaterialDrawer.defaultProps = {
  purchaseOrderItemLookup: {},
};

export default SelectMaterialDrawer;
