import salesOrderReducer, {salesOrderCrud} from "./reducers";
/* These exports are the only thing that need to be edited for a new module */
export const name = "v1/warehouse/sales_orders";

export {default as salesOrderTypes} from "./types";
export {default as salesOrderSelectors} from "./selectors";
export {default as salesOrderOperations} from "./operations";
export {default as salesOrderActions} from "./actions";

export {salesOrderCrud};

export default salesOrderReducer;
