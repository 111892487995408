import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {ItemText} from "../../Common/Bars/styled";
import {ruleStringToText} from "../../../utils/rrules";

const Details = ({orderData, baseClassName}) => {
  return (
    <div className={`${baseClassName}-detail`}>
      {orderData.repeating ? (
        <Fragment>
          <p>Repeating</p>
          <p>{ruleStringToText(orderData.repeat)}</p>
          {orderData.hourly &&
          <p>Every {orderData.hourly} hours {orderData.start_time} - {orderData.end_time}</p>
          }
        </Fragment>
      ) : (<ItemText color={"white"}>Not Repeating</ItemText>)}
    </div>
  );
};

Details.propTypes = {
  baseClassName: PropTypes.string
};

export {Details};
