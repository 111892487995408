import React, {Component} from "react";
import {connect} from "react-redux";
import {authActions} from "../../store";
import RequestPasswordReset from "../../components/Login/PasswordReset/RequestPasswordReset";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(authActions.requestPasswordReset(data))
  };
};

class RequestPasswordResetContainer extends Component {
  render() {
    return (
      <RequestPasswordReset {...this.props}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPasswordResetContainer);
