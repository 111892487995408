import React from "react";
import columns from "./columns.js";
import {Table} from "../../../Common/Tables/Table";
import {productsTableData} from "./data";

const ProductsTable = (props) => {
  return (
    <Table
      handleFieldChange={props.handleFieldChange}
      columns={columns}
      tableData={productsTableData}
      {...props}
    />
  );
};

export {ProductsTable};
