import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {AutoSave, EditableColorFormDropdown, FormField, FormMoneyField} from "../Base";
import {defaultStatusOptions} from "./constants.js";
import {postLoadFormat, preSaveFormat} from "./utils";

import {SubmitButton} from "../styled";


const StockroomAssetForm = (props) => {
  const {
    onSubmit,
    validate,
    values,
    editAccess,
    stockStatuses,
    selectedStockStatus,
    newStockStatus,
    handleStockStatusSelect,
    handleShowCreateStockStatus,
    handleShowEditStockStatus,
    handleCreateStockStatus,
    handleUpdateStockStatus,
    handleDeleteStockStatus,
    handleStockStatusColorChange,
    handleStockStatusNameChange,
  } = props;

  const initialValues = postLoadFormat(values);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"item-details-form"} onSubmit={handleSubmit}>
          <AutoSave
            save={onSubmit}
            ignored={["purchase_price", "purchase_price_currency"]}
            debounced={["name", "purchased_date", "implemented_date"]}
            preSaveFormat={preSaveFormat}
            postLoadFormat={postLoadFormat}
            formInvalid={invalid}
          />

          <FormField
            type={"text"}
            name={"name"}
            label={"UNIQUE NAME"}
            isDisabled={!editAccess}
          />

          <FormMoneyField
            label={"PURCHASE PRICE"}
            name={"purchase_price"}
            currencyName={"purchase_price_currency"}
            isDisabled={!editAccess}
          />

          <FormField
            type={"date"}
            name={"purchased_date"}
            label={"PURCHASE DATE"}
            isDisabled={!editAccess}
          />

          <FormField
            type={"date"}
            name={"implemented_date"}
            label={"IMPLEMENTED DATE"}
            isDisabled={!editAccess}
          />

          <EditableColorFormDropdown
            dropdownName={"Stockroom Asset Status"}
            label={"STOCKROOM ASSET STATUS"}
            canChangeSelection={true}
            canCreateSelections={editAccess}
            defaultOptions={defaultStatusOptions}
            dropdownOptions={stockStatuses}
            selectedOption={selectedStockStatus}
            newField={newStockStatus}
            handleFieldSelect={handleStockStatusSelect}
            handleShowCreateFieldSuper={handleShowCreateStockStatus}
            handleShowEditFieldSuper={handleShowEditStockStatus}
            handleCreateField={handleCreateStockStatus}
            handleEditField={handleUpdateStockStatus}
            handleDeleteField={handleDeleteStockStatus}
            handleColorChange={handleStockStatusColorChange}
            handleNewFieldNameChange={handleStockStatusNameChange}/>
          <br/>

          <SubmitButton className="item-details-form-button" loading={submitting}>
            Save
          </SubmitButton>
        </form>
      )}
    />
  );
};

StockroomAssetForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.object,
  stockStatuses: PropTypes.array,
  selectedStockStatus: PropTypes.object,
  newStockStatus: PropTypes.object,
  handleStockStatusSelect: PropTypes.func,
  handleShowCreateStockStatus: PropTypes.func,
  handleShowEditStockStatus: PropTypes.func,
  handleCreateStockStatus: PropTypes.func,
  handleUpdateStockStatus: PropTypes.func,
  handleDeleteStockStatus: PropTypes.func,
  handleStockStatusColorChange: PropTypes.func,
  handleStockStatusNameChange: PropTypes.func,
};

StockroomAssetForm.defaultProps = {
  values: {}
};

export default StockroomAssetForm;
