import React from "react";
import PropTypes from "prop-types";
import {ItemText, ItemName} from "../../styled";
import {List} from "Base";

// This component displays a list of tags with one title and will display on the inner right side of the bar
const TagList = ({baseClassName, listName, listArray}) => {
  return (
    <div className={`${baseClassName}-main-right`}>
      <ItemName className={`${baseClassName}-main-right-text`}>{listName}:</ItemName>
      <List
        locale={{emptyText: " "}}
        size="small"
        dataSource={listArray.slice(0, 3)}
        renderItem={item => <ItemText className={`${baseClassName}-main-right-tag`}>{item}</ItemText>}
      />
    </div>
  );
};

TagList.propTypes = {
  baseClassName: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  listArray: PropTypes.array,
};

export {TagList};
