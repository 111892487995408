import React, {Component} from "react";
import {connect} from "react-redux";
import {
  authSelectors,
  baseSelectors,
  // materialOperations,
  orgOperations,
  orgSelectors,
  paginationSelectors,
  palletOperations,
  personnelOperations,
  personnelSelectors,
  productActions,
  productPaginationActions,
  materialPageActions,
  productSelectors,
  purchaseOrderOperations,
} from "../../../store";

import {supplierAddressActions, supplierOperations, supplierPageActions} from "store/warehouse";

import PurchaseOrderDetails from "../../../components/Warehouse/PurchaseOrders/Details/PurchaseOrderDetails";
import {checkNestedKeys} from "../../../utils/propUtils";
import {isEmpty} from "lodash-es";
// import {DEFAULT_PAGE_SIZE} from "../../../components/Warehouse/PurchaseOrderDetails/constants";

const DEFAULT_PAGE_SIZE = 3;
/* This is where Redux state is mapped to this component. */
const mapStateToProps = (state, ownProps) => ({
  purchaseOrderDetails: baseSelectors.returnItemWithID(
    state.purchaseOrders,
    ownProps.match.params.orderID,
  ),
  productPagination: paginationSelectors.pagination(state.warehouse.products.items),
  materialPagination: paginationSelectors.pagination(state.materials.items),
  supplierPagination: paginationSelectors.pagination(state.warehouse.contacts.supplier.items),
  locationList: orgSelectors.refreshLocations(state.orgs),
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  isAdmin: authSelectors.isAdmin(state.account),
  orgID: authSelectors.defaultOrgID(state.account),
  facilities: productSelectors.refreshFacilityList(state.warehouse.products),
  personnelList: personnelSelectors.refreshNameList(state.personnel),

});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
  the reducer and affecting state. This affected state will be immediately available via the mapped
  props above. */
const mapDispatchToProps = (dispatch, ownProps) => ({
  getProductList: (page = 1, params = {page_size: DEFAULT_PAGE_SIZE}) => dispatch(productPaginationActions.fetchPage({page, params})),
  getMaterialList: (page = 1, params = {page_size: DEFAULT_PAGE_SIZE}) => dispatch(materialPageActions.fetchPage({page, params})),
  getPurchaseOrderDetails: (id) => dispatch(purchaseOrderOperations.getItem(id)),
  getPersonnelList: () => dispatch(personnelOperations.getAllItems()),
  getAllPurchaseOrderDetails: () => dispatch(purchaseOrderOperations.getAllItems()),
  updatePurchaseOrder: (id, data) => dispatch(purchaseOrderOperations.updateItem(id, data)),
  updateFiles: (id, data) => dispatch(purchaseOrderOperations.updateItemFile(id, data)),
  updatePurchaseOrderBol: (id, data) => dispatch(purchaseOrderOperations.updateBol(id, data)),
  addQuantity: (id, data) => dispatch(purchaseOrderOperations.addQuantity(id, data)),
  addMaterial: (id, data) => dispatch(purchaseOrderOperations.addMaterial(id, data)),
  updateQuantity: (id, itemID, data) => dispatch(purchaseOrderOperations.updateQuantity(id, itemID, data)),
  updateMaterial: (id, itemID, data) => dispatch(purchaseOrderOperations.updateMaterial(id, itemID, data)),
  deletePurchaseOrder: (id) => dispatch(purchaseOrderOperations.deleteItem(id)),
  updateItemField: (id, fieldData) => dispatch(purchaseOrderOperations.updateItemField(id, fieldData)),
  addNote: (id, data) => dispatch(purchaseOrderOperations.addNote),
  uploadCsv: (data, org) => dispatch(purchaseOrderOperations.uploadCsv(data, org)),
  getLocations: (id) => dispatch(orgOperations.getLocations(id)),
  getInventory: (filter) => dispatch(purchaseOrderOperations.getInventory(filter)),
  addInventory: (data) =>
    dispatch(purchaseOrderOperations.addInventory(ownProps.match.params.palletID, data, true)),

  // Supplier's
  getSuppliers: (page = 1, params = {page_size: 100}) => dispatch(supplierPageActions.fetchPage({page, params})),
  createSupplier: (data) => dispatch(supplierOperations.createItem(data)),
  createSupplierAddress: (id, data) => dispatch(supplierAddressActions.createItem(id, data)),
  createPallet: (data) => dispatch(palletOperations.createPalletForOrder(data)),
  updatePallet: (id, data) => dispatch(palletOperations.updateItem(id, data)),
  getFacilities: (org) => dispatch(productActions.getFacilities(org)),
});


class PurchaseOrderDetailsContainer extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const {
      getProductList,
      materialPagination,
      productPagination,
      getMaterialList,
      getPurchaseOrderDetails,
      getLocations,
      accountInfo,
      match,
      purchaseOrderDetails,
      locationList,
      getSuppliers,
      getPersonnelList,
      getFacilities,
    } = this.props;

    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);
    const purchaseOrderID = match.params.orderID;

    if (isEmpty(locationList)) getLocations(orgId);
    if (isEmpty(productPagination.itemsPaged)) getProductList(1);
    if (isEmpty(materialPagination.itemsPaged)) getMaterialList(1);

    if (purchaseOrderDetails.id !== purchaseOrderID) getPurchaseOrderDetails(purchaseOrderID);

    getSuppliers(1);
    getFacilities(orgId);
    getPersonnelList();
  };

  handleCreateAddress = async (id, data) => {
    const {createSupplierAddress, getPurchaseOrderDetails, match} = this.props;
    const purchaseOrderID = match.params.orderID;

    const res = await createSupplierAddress(id, data);
    await getPurchaseOrderDetails(purchaseOrderID);
    return res;
  };

  render() {
    return (
      <PurchaseOrderDetails
        {...this.props}
        createSupplierAddress={this.handleCreateAddress}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseOrderDetailsContainer);
