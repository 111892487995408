import React, {useState, useEffect, Fragment} from "react";
import PropTypes from "prop-types";
import {Box, Flex, Button, CloseIcon} from "../../Base";
import {InputControl} from "../../Base/Inputs/InputControls";
import {Span} from "../../Base/styled";
import Popover, {ArrowContainer} from "react-tiny-popover";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LineChart, Line, XAxis, YAxis, Tooltip, ReferenceArea} from "../../Base/Charts";
import {EMAIL_POPUP_NAME, CHART_POPUP_NAME} from "../../../Materials/Details/MaterialForm/constants";

const ARROW_COLOR = "grey";
const ARROW_SIZE = 10;
const ARROW_STYLE = {opacity: 1};

// component to return what's displayed when user hovers over a data point
const CustomTooltip = ({active, payload}) => {
  if (active) {
    const {name, value} = payload[0];
    const {date} = payload[0].payload;
    return (
      <Box color={"black"} className="custom-tooltip">
        <p className="label">{`Date : ${date}`}</p>
        <p className="label">{`${name} : ${value}`}</p>
      </Box>
    );
  }
  return null;
};

const initialZoomConfigState = {
  left: "dataMin",
  right: "dataMax",
  refAreaLeft: "",
  refAreaRight: "",
  top: "dataMax+100",
  bottom: 0,
  animation: true,
};

const MaterialQuantityField = (props) => {
  const [currentOpenPopup, setOpen] = useState({"openPopup": ""});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [zoomConfig, setZoomConfig] = useState(initialZoomConfigState);

  const {
    id,
    label,
    editAccess,
    available,
    name,
    location,
    quantity,
    quantity_history,
    in_use,
    email_sent,
    material,
    submit,
  } = props;

  const quantityHistoryData = quantity_history.map((point, i) => {
    const {created_at, quantity, in_use} = point;
    const date = moment(created_at).format("YYYY-MM-DD");
    const availableQuantity = quantity - in_use;
    /*
    The rechart zooming feature doesn't like using data strings as values for the x axis,
    thus instead we can use the index of the data point for plotting and zooming,
    but display the data in the x axis and tooltip
     */
    return {name: i, date, availableQuantity};
  });

  // Since time will pass without any updates to quantity values, we can extrapolate the last data point if the date doesn't match
  // today's date. This gives us the advantage of not needing to store extra data points in the backend when there is no change to record.
  const lastIndex = quantityHistoryData.length - 1;
  if (quantityHistoryData.length && quantityHistoryData[lastIndex].date !== moment().format("YYYY-MM-DD")) {
    const newLastPoint = {...quantityHistoryData[lastIndex]};
    newLastPoint.date = moment().format("YYYY-MM-DD");
    newLastPoint.name += 1;
    quantityHistoryData.push(newLastPoint);
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const setOpenPopup = (popup) => {
    const {openPopup} = currentOpenPopup;
    const newPopupState = openPopup && popup === openPopup ? "" : popup;
    setOpen({"openPopup": newPopupState});
  };

  const handleClick = () => {
    const emailData = {quantities: [{id, location, location_breadcrumb: label, quantity, in_use, material, email: true}]};
    submit(emailData);
    setOpenPopup("");
  };

  const formatXAxis = (tickItem) => {
    /*
    because we are using index values for the x axis, we need to use this method to return the string date
    for each data point. Otherwise, the x axis would simply display indicies.
    Becuase the chart component implicitly passes the value
    of x for the data point of interest (index),
    we can derive the date by using array notation on the history data
    */
    return quantityHistoryData[tickItem] ? quantityHistoryData[tickItem].date : "";
  };

  // Get the min and max values from the subset of zoomed in data with built in offset
  const getAxisYDomain = (from, to, ref) => {
    // slice's second arguement is exclusive so use to + 1 unless 'to' is the last index
    const refData = quantityHistoryData.slice(from, Math.min(to + 1, quantityHistoryData.length - 1));
    // naively set bottom and top values
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    // verify top and bottom are indeed min and max, if
    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });
    /* offset is meant to add extra range to the bottom and top of the y axis during zooming.
    Without knowing what the exact range of
    data could be, this is calculated using the difference between top and bottom and dividing by 3.
    */
    const offset = Math.floor((top - bottom) / 3);
    return [(bottom | 0) - offset, (top | 0) + offset];
  };

  // handler for a zoom event
  const zoom = () => {
    let {refAreaLeft, refAreaRight} = zoomConfig;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setZoomConfig({
        ...zoomConfig,
        refAreaLeft: "",
        refAreaRight: "",
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, "availableQuantity");

    setZoomConfig({
      refAreaLeft: "",
      refAreaRight: "",
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
    });
  };

  const zoomOut = () => {
    setZoomConfig({
      refAreaLeft: "",
      refAreaRight: "",
      left: "dataMin",
      right: "dataMax",
      top: "dataMax + 100",
      bottom: 0,
    });
  };

  const getEmailPopoverContent = () => {
    if (email_sent) {
      return (
        <div>
          An email has already been sent to
          the organization admin indicating
          there is a material shortage.
        </div>
      );
    } else {
      return (
        <Fragment>
          <Span ml={"95%"} cursor={"pointer"} onClick={() => setOpenPopup("")}>x</Span>
          <div>
            Send a notification to admin
            indicating there is a material shortage.
          </div>
          <Button id={`send-notification-${id}`} className="item-details-form-button assign-location" onClick={handleClick}>
            Send
          </Button>
        </Fragment>
      );
    }
  };

  const chartWidth = Math.min(500, Math.floor(Number(windowWidth) / 2));
  const chartHeight = Math.floor(chartWidth * 2 / 3);

  const {
    left, right, refAreaLeft, refAreaRight, top, bottom,
  } = zoomConfig;

  return (
    <Box mb={"40px"}>
      <Box className="item-details-flex-box" display="flex" alignItems="left" justifyContent="left">
        <Box className="item-details-flex-width-left" alignItems="left">
          <span id={id + "label"} style={{fontWeight: "bold"}}>{label}:</span>
        </Box>
      </Box>
      <Flex className="item-details-flex-box">
        <Span
          className="item-details-flex-width"
          alignItems="left"
          justifyContent="left"
          marginTop="0%"
        >
          <span>{`Available: ${available}  `}</span>
          <Popover
            isOpen={currentOpenPopup.openPopup === EMAIL_POPUP_NAME}
            onClickOutside={() => setOpenPopup("")}
            position={["bottom", "right"]}
            padding={10}
            content={({position, targetRect, popoverRect}) => (
              <ArrowContainer
                position={position}
                targetRect={targetRect}
                popoverRect={popoverRect}
                arrowColor={ARROW_COLOR}
                arrowSize={ARROW_SIZE}
                arrowStyle={ARROW_STYLE}>
                <Box className="item-details-flex-width-popover">
                  {getEmailPopoverContent()}
                </Box>
              </ArrowContainer>
            )}
          >
            {email_sent ?
              <FontAwesomeIcon
                style={{cursor: "pointer", marginLeft: "5px"}}
                icon="exclamation-triangle"
                size="1x"
                onMouseEnter={() => setOpenPopup(EMAIL_POPUP_NAME)}
                onMouseLeave={() => setOpenPopup("")}
                id={`email-sent-icon-${id}`}
              />
              :
              <Box
                onClick={() => setOpenPopup(EMAIL_POPUP_NAME)}
                display={"inline"}

              >
                <FontAwesomeIcon
                  style={{cursor: "pointer", marginLeft: "5px"}}
                  icon="paper-plane"
                  size="1x"
                  onClick={() => setOpenPopup(EMAIL_POPUP_NAME)}
                  id={`open-send-notification-${id}`}
                />
              </Box>
            }
          </Popover>
          <Popover
            isOpen={currentOpenPopup.openPopup === CHART_POPUP_NAME}
            onClickOutside={() => setOpenPopup("")}
            position={["bottom", "right"]}
            padding={10}
            content={({position, targetRect, popoverRect}) => (
              <ArrowContainer
                position={position}
                targetRect={targetRect}
                popoverRect={popoverRect}
                arrowColor={ARROW_COLOR}
                arrowSize={ARROW_SIZE}
                arrowStyle={ARROW_STYLE}>
                <Box className="item-details-flex-width-popover">
                  <Span float={"left"}>
                    <FontAwesomeIcon
                      style={{cursor: "pointer"}}
                      icon="search-minus"
                      size="1x"
                      onClick={zoomOut}
                    />
                  </Span>
                  <Span
                    cursor={"pointer"}
                    float={"right"}
                    onClick={() => {
                      setOpenPopup("");
                      zoomOut();
                    }}>
                    <CloseIcon/>
                  </Span>
                  <Box display={"inline-block"} w={"100%"} textAlign={"center"}>Quantity History</Box>
                  <LineChart
                    className="quantity-history-chart"
                    width={chartWidth}
                    height={chartHeight}
                    data={quantityHistoryData}
                    onMouseDown={e => e && setZoomConfig({...zoomConfig, refAreaLeft: e.activeLabel || 0})}
                    onMouseMove={e => e && refAreaLeft && setZoomConfig({...zoomConfig, refAreaRight: e.activeLabel || 0})}
                    onMouseUp={() => zoom()}
                    margin={{
                      top: 5, right: 20, left: 10, bottom: 10,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis
                      dataKey="name"
                      tickFormatter={formatXAxis}
                      allowDataOverflow
                      type="number"
                      domain={[left, right]}
                      style={{userSelect: "none"}}/>
                    <YAxis
                      allowDataOverflow
                      domain={[bottom, top]}
                      type="number"
                      yAxisId="1"
                      style={{userSelect: "none"}}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Line
                      yAxisId="1"
                      type="natural"
                      dataKey="availableQuantity"
                      name="Available Quantity"
                      stroke="#8884d8"
                      animationDuration={300}
                      activeDot={{r: 8}}/>
                    {
                      (refAreaLeft && refAreaRight) &&
                        <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3}/>
                    }
                  </LineChart>
                </Box>
              </ArrowContainer>
            )}
          >
            {quantityHistoryData.length !== 0 &&
            <Box
              display={"inline"}
              onClick={() => setOpenPopup(CHART_POPUP_NAME)}

            >
              <FontAwesomeIcon
                style={{cursor: "pointer", marginLeft: "10px"}}
                icon="chart-line"
                size="1x"
                className="quantity-history-chart-icon"
                id={`open-chart-history-${id}`}
              />
            </Box>
            }
          </Popover>
        </Span>
        <InputControl
          controlStyle={{width: "33%", px: 2}}
          inputStyle={{size: "lg", label: "Total" /* <-- label for testing */}}
          width={"33%"}
          label={"Total"}
          name={`${name}.quantity`}
          type="number"
          placeholder="Quantity"
          min={0}
          isDisabled={!editAccess}
          parse={(value, name) => Number(value)}
          format={(value, name) => Number(value)}
        />
        <InputControl
          controlStyle={{width: "33%", px: 2}}
          inputStyle={{size: "lg", label: "In Use" /* <-- label for testing */}}
          width={"33%"}
          label={"In Use"}
          name={`${name}.in_use`}
          type="number"
          placeholder="In Use"
          min={0}
          isDisabled={!editAccess}
          parse={(value, name) => Number(value)}
          format={(value, name) => Number(value)}
        />
      </Flex>
    </Box>
  );
};

MaterialQuantityField.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  editAccess: PropTypes.bool,
  available: PropTypes.number,
  name: PropTypes.string,
  submit: PropTypes.func,
  location: PropTypes.object,
  in_use: PropTypes.number,
  email_sent: PropTypes.bool,
  quantity: PropTypes.number,
  quantity_history: PropTypes.array,
  material: PropTypes.number,
};

MaterialQuantityField.defaultProps = {
  quantity_history: [],
};

export default MaterialQuantityField;
