import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {AddItemModal, ButtonBar, HelpButton, ToolBar, TutorialModal} from "../../../Common";
import {PurchaseOrderBar} from "../Bar/PurchaseOrderBar";
import {Loading} from "../../../Common/Base";
import {selectionOptions} from "../../../../containers/Warehouse/WarehouseContainer/constants";
import {checkNestedKeys} from "../../../../utils/propUtils";
import BannerOne from "../../../../static/img/BannerOne.png";
import {purchaseOrderHelp} from "../../../Common/HelpItems/constants";
import {scrollToTop} from "../../../../utils/containerUtils";

class PurchaseOrders extends Component {
  constructor() {
    super();
    this.state = {
      showAddItemModal: false,
      showTutFromHelp: false,
      showTutorialModal: true,
      addItemModalSubmitting: false,
      addItemModalError: false,
      addedOrderID: ""
    };
  }

  componentDidMount = () => {
    const {
      accountInfo,
      getOrg,
      getAllPurchaseOrderDetails,
      getLocations,
    } = this.props;

    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);

    if (orgId) {
      this.setState({
        orgID: orgId
      });
      getOrg(orgId);
      getLocations(orgId);
      getAllPurchaseOrderDetails();
    }
    scrollToTop();
  }

  handleSearch = e => {
    e.preventDefault();
    const {filterPurchaseOrders} = this.props;
    filterPurchaseOrders(e.target.value);
  };

  updateSortBy = e => {
    const {sortPurchaseOrders} = this.props;
    sortPurchaseOrders(e.value);
  };

  handleSubmitAddItem = async () => {
    const {accountInfo} = this.props;
    this.setState({
      addItemModalSubmitting: true,
      addItemModalError: false
    });
    const dataToSubmit = {
      org: accountInfo.personnel ? accountInfo.personnel.orgs[0].id : null,
    };
    let res = await this.props.createPurchaseOrder(dataToSubmit);
    this.setState({addedOrderID: res.id, addItemModalSubmitting: false});
  }

  handleCloseAddItemModal = () => {
    this.setState({
      showAddItemModal: false
    });
  };

  handleTutFromButton = () => {
    this.setState({
      showTutFromHelp: !this.state.showTutFromHelp
    });
  };

  handleCloseTutorialModal = () => {
    this.setState({
      showTutorialModal: false,
      showTutFromHelp: false
    });
  };

  handleOpenAddItemModal = () => {
    this.setState({
      showAddItemModal: true
    });
  };


  render() {
    const {
      addItemModalError,
      addItemModalSubmitting,
      showAddItemModal,
      showTutFromHelp,
      showTutorialModal,
      addedOrderID,
    } = this.state;
    const {
      loading,
      nextPage,
      prevPage,
      currentPage,
      listView,
      tileView,
      tableView,
      pageNum,
      isLastPage,
      changeViewList,
      changeViewTile,
      changeViewTable,
      filter,
      reverseList,
      isReversed,
      locationList
    } = this.props;
    if (addedOrderID) {
      return <Redirect to={`/dashboard/warehouse/purchase_orders/${addedOrderID}`}/>;
    }
    return (
      <div>
        {!loading && <ToolBar
          handleChange={this.handleSearch}
          updateSortBy={this.updateSortBy}
          nextPage={nextPage}
          prevPage={prevPage}
          pageNum={pageNum}
          changeViewList={changeViewList}
          changeViewTile={changeViewTile}
          changeViewTable={changeViewTable}
          listView={listView}
          tileView={tileView}
          tableView={tableView}
          isLastPage={isLastPage}
          selectionOptions={selectionOptions}
          filter={filter}
          reverseList={reverseList}
          isReversed={isReversed}
          handleAddItemButtonClick={this.handleOpenAddItemModal}
          objectType="Order"
          hasTableView={false}
        />
        }
        <div className="row">
          <div className={(listView) ? "items-flex-row" : "items-flex-column"}>
            {loading &&
              <Loading title={"Loading orders..."}/>}
            {!loading &&
              <div>
                <div data-tip="Open a help popup" data-delay-show="1000">
                  <HelpButton handleClick={this.handleTutFromButton}/>
                </div>
                <div data-tip="Build an order." data-delay-show="1000">
                  <ButtonBar handleClick={this.handleOpenAddItemModal} />
                </div>
              </div>
            }
            { (listView || tileView) && currentPage.length > 0
              && currentPage.map((purchaseOrder, index) => (
                <PurchaseOrderBar
                  key={index}
                  data={purchaseOrder}
                  openAddItemModal={this.handleOpenAddItemModal}
                  listView={listView}
                  locationList={locationList}
                />
              ))
            }
            {/* tableView && allPalletsInfo
              ? <PalletTable
                palletData={allPalletsInfo}
                createNewPallet={this.handleSubmitAddItem}
              />
              : null
            */}
          </div>
        </div>

        <TutorialModal
          visible={(currentPage.length === 0 && showTutorialModal && !loading) || showTutFromHelp}
          closeModal={this.handleCloseTutorialModal}
          banner={BannerOne}
          type="Warehouse Order"
          descriptions={purchaseOrderHelp}
          handleOpenAddItemModal={[this.handleOpenAddItemModal, "Create One!"]}/>

        <AddItemModal
          visible={showAddItemModal}
          itemName="Order"
          closeAddItemModal={this.handleCloseAddItemModal}
          submitAddItem={this.handleSubmitAddItem}
          addItemSubmitting={addItemModalSubmitting}
          addItemError={addItemModalError}
        />
      </div>
    );
  }
}

export default PurchaseOrders;
