import {ruleStringToText} from "../../../../utils/rrules";


const formatShiftOptions = (shifts) => {
  const result = shifts.map(item => {
    const rruleStr = ruleStringToText(item.repeat);
    return (
      {
        value: `${item.id}-${item.start}`,
        label: `${item.schedule.name} - ${rruleStr}`
      }
    );
  });
  return result;
};

const checkHasActive = (active) => {
  if (Boolean(active) && typeof active === "string") {
    return false;
  }
  if (!active) {
    return false;
  }
  return true;
};

const formatActive = (active, shiftList) => {
  if (Boolean(active) && typeof active !== "string") {
    const item = shiftList.find(el => el.id === active.shift);


    if (item) {
      const rruleStr = ruleStringToText(item.repeat);
      return ({
        shift: {
          value: `${item.id}-${item.start}`,
          label: `${item.schedule.name} - ${rruleStr}`
        }
      });
    }
  }
  return null;
};


const formatSubmit = (values) => {
  let data = values;
  if (values && values.shift && values.shift.value) {
    let splitValue = values.shift.value.split("-");
    data.shift = splitValue[0];
  }
  if (values && values.shift) {
    let splitValue = values.shift.split("-");
    data.shift = splitValue[0];
  }
  return data;
};


export {formatShiftOptions, formatActive, checkHasActive, formatSubmit};
