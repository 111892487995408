import React from "react";
import PropTypes from "prop-types";
import {List} from "Base";
import ProductListItem from "./ProductListItem";

const ProductList = props => {
  const {data, onDelete} = props;
  return (
    <List
      bordered
      style={{backgroundColor: "white"}}
      dataSource={data}
      renderItem={item => <ProductListItem item={item} onDelete={onDelete} />}
    />
  );
};

ProductList.propTypes = {
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
};

export default ProductList;
