import React from "react";
import PropTypes from "prop-types";
import {Descriptions} from "../Base";

const AddressDetails = props => {
  const {
    title,
    address,
    className,
  } = props;
  const {
    state,
    name,
    address_line1,
    address_line2,
    zip_code,
    city,
  } = address;
  return (
    <Descriptions title={title} column={{xs: 2, lg: 3}} className={className}>
      <Descriptions.Item label={"Name"} span={3}>{name}</Descriptions.Item>
      <Descriptions.Item label={"Address 1"} span={3}>
        {address_line1}
      </Descriptions.Item>
      {address_line2 &&
      <Descriptions.Item label={"Address 2"} span={3}>
        {address_line2}
      </Descriptions.Item>
      }
      <Descriptions.Item label={"City"} span={{xs: 2, lg: 1}}>
        {city}
      </Descriptions.Item>
      <Descriptions.Item label={"State"}>
        {state}
      </Descriptions.Item>
      <Descriptions.Item label={"Zipcode"}>
        {zip_code}
      </Descriptions.Item>
    </Descriptions>
  );
};

AddressDetails.propTypes = {
  title: PropTypes.string,
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
};

AddressDetails.defaultProps = {
  title: "Address",
  className: "address-details",
};

export default AddressDetails;
