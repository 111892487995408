import types from "./types";

import {filters, sort} from "../../utils";
import {baseReducer, getNumberOfPages, INITIAL_STATE, withCsv} from "../base/reducers";
import moment from "moment";

const initialState = {
  ...INITIAL_STATE,
  savingItem: false,
  calendarView: false,
};

/**
 * Function used to filter PM Orders by overdue or inspect_today
 * @param {string} filteringBy which filter is applied, '', 'overdue', 'inspect_today'
 * @param {Array} items An array of PM Orders
 * @returns {Array} The list of filtered PM Orders
 */
const filteringByList = (filteringBy, items) => {
  if (filteringBy === "overdue") {
    return filters.filterListByDate(items, "first_scheduled_date", moment(), (a, b) => a.isBefore(b, "day"));
  } else if (filteringBy === "inspect_today") {
    return filters.filterListByDate(items, "first_scheduled_date", moment(), (a, b) => a.isSame(b, "day"));
  }
  return items;
};

const pmOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
  /* Sets items to payload returned from getAllItems in operations. */
  case types.GET_ALL_SUCCESS: {
    let newItems = [];
    /* If success = 0, action.assets.message is a string and not an array
    and must be handled differnetly. Check payload in redux-devtools for
    debugging issues like this. */
    newItems = action.payload;
    // Check any filterby
    const newList = filteringByList(state.filteringBy, newItems);
    const filteredList = sort.sortByKey(filters.filterListByName(newList, state.filter), state.sortBy);
    const pages = getNumberOfPages(filteredList.length, state.itemsPerPage);

    const data = {};
    newItems.forEach(item => data[item.id] = item);

    let noneFound = false;
    if (action.payload.length === 0) {
      noneFound = true;
    }
    return {
      ...state,
      pages,
      loading: false,
      items: action.payload,
      data,
      filterList: filteredList,
      currentPage: 1,
      pageList: filteredList.slice(0, state.itemsPerPage),
      noneFound
    };
  }
  case types.GET_ALL_FAILURE:
    return {
      ...state,
      loading: false,
      errors: action
    };

  case types.GET_ITEM_SCHEDULES_SUCCESS: {
    const newSchedules = {
      ...state.schedules,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      schedules: newSchedules
    };
  }

  case types.GET_ALL_NONE_FOUND:
    return {
      ...state,
      loading: false,
      noneFound: true
    };

  case types.GET_ITEM_SUCCESS: {
    const newData = {
      ...state.data,
      [action.payload.id]: action.payload
    };
    return {
      ...state,
      loading: false,
      data: newData,
    };
  }

  case types.GET_ITEM_NOTES_SUCCESS: {
    const newNotes = {
      ...state.notes,
      [action.meta.id]: action.payload
    };
    return {
      ...state,
      notes: newNotes
    };
  }
  case types.UPDATE_ITEM_REQUEST:
    return {
      ...state,
      savingItem: true
    };
  case types.UPDATE_ITEM_SUCCESS:
    // The update serializer returns limited data (asset etc), so only select the data that could be updated on the vendor form
    const selectedData = (({
      name,
      description,
      first_scheduled_date,
      materials,
      expected_time,
      repeating_num,
      repeating_unit,
      photo_required,
      inpspection_note_required,
      inspection_note
    }) => {
      return ({
        name,
        description,
        first_scheduled_date,
        materials,
        expected_time,
        repeating_num,
        repeating_unit,
        photo_required,
        inpspection_note_required,
        inspection_note
      });
    })(action.payload);
    const {id} = action.payload;
    const {items} = state;
    const index = items.findIndex(PMOrderItem => PMOrderItem.id === id);
    const item = items[index];
    let newItems = items;
    let updatedItem = null;
    // Combine the selected updated fields with the fields from the original item
    if (item && selectedData) {
      updatedItem = Object.assign(item, selectedData);
      newItems[index] = updatedItem;
    }
    // Console.log('update item success', state.items, action.payload, selectedData, updatedItem);
    const newData = {
      ...state.data,
      [action.payload.id]: updatedItem || item
    };
    return {
      ...state,
      savingItem: false,
      items: newItems,
      newData
    };
  case types.UPDATE_ITEM_FAILURE:
    return {
      ...state,
      savingItem: false,
      errors: action.payload
    };

    /* Filters items in state by those that include the querry in item.name and item.tags. */
  case types.FILTER_LIST: {
    // Const newFilter = action.filter.split(' ');
    let newList = filters.filterListByAll(state.items, action.filter);
    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filter: action.filter,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  /* Filters items in state by a field specified in the action payload, overdue or inspect_today. */
  case types.FILTER_LIST_BY: {
    const filteringBy = action.payload;
    // If filtering by is overdue or inspect_today filter otherwise do not filter the items.
    let newList = filteringByList(filteringBy, state.items);

    newList = sort.sortByKey(newList, state.sortBy, state.isReversed);
    const pages = getNumberOfPages(newList.length, state.itemsPerPage);
    return {
      ...state,
      filteringBy,
      pages,
      currentPage: 1,
      filterList: newList,
      pageList: newList.slice(0, state.itemsPerPage)
    };
  }

  default:
    return state;
  }
};

export default withCsv(types, baseReducer(types, pmOrdersReducer));
