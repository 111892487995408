import React from "react";
import {Text as BaseText} from "@chakra-ui/core";
import styled from "@emotion/styled";

const StyledText = styled(BaseText)``;

const Text = (props) => <StyledText {...props}/>;

Text.defaultProps = {
  fontSize: "xl"
};

const ErrorText = (props) => <StyledText {...props}/>;

ErrorText.defaultProps = {
  color: "red.500",
  fontSize: "xl"
};

export {ErrorText, Text};
