import {Form} from "react-final-form";
import moment from "moment";
import {Condition, FormDropdown, FormField} from "../../Common/Forms/Base";
import {booleanOptions} from "./constants.js";
import {isDateHighlighted} from "./utils";
import {Box, SingleDatePicker} from "../../Common/Base";
import {dateAdapter} from "../../Common/Forms/Base/utils";
import CalendarInfo from "./CalendarInfo";
import {ScheduleField} from "../../Common/Fields/Schedules";
import React from "react";
import PropTypes from "prop-types";
import {SubmitButton} from "../../Common/Forms/styled";

const AdaptedDatePicker = dateAdapter(SingleDatePicker);

const PMOrderScheduleForm = props => {
  const {
    onSubmit,
    validate,
    editAccess,
    availablePersonnel
  } = props;

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{}}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form className={"item-details-form"} onSubmit={handleSubmit}>
          <FormField
            withValidation={false}
            required
            name="start"
            label="SCHEDULE START DATE"
            isDisabled={!editAccess}
            component={AdaptedDatePicker}
            // format={(value, name) => value ? moment(value).format("MM/DD/YYYY") : null}
            parse={(value, name) => value ? moment(value).format("YYYY-MM-DD") : null}
            calendarOptions={{
              renderCalendarInfo: () => (
                <CalendarInfo personnel={availablePersonnel}/>
              ),
              isDayHighlighted: (date) => {
                return isDateHighlighted(availablePersonnel, date);
              },
              calendarInfoPosition: "after"
            }}
          />
          <br/>

          <FormDropdown
            options={booleanOptions}
            name="repeating"
            label="REPEATING"
            isDisabled={!editAccess}
            defaultValue={false}
            format={(value, name) => ({value: value, label: value ? "True" : "False"})}
            parse={(value, name) => value.value}
          />

          <Condition when="repeating" is={true}>
            <Box pb={"15px"}>
              <ScheduleField
                label={"REPEAT FREQUENCY"}
                name={"repeat"}
                generatorConfig={{hideEnd: false}}/>
            </Box>
          </Condition>

          {editAccess && (
            <SubmitButton className="item-details-form-button" loading={submitting}>
              Add Schedule
            </SubmitButton>
          )}
        </form>
      )}
    />
  );
};

PMOrderScheduleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  editAccess: PropTypes.bool,
  availablePersonnel: PropTypes.array,
};

PMOrderScheduleForm.defaultProps = {
  editAccess: false,
  availablePersonnel: []
};

export default PMOrderScheduleForm;
