import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {List, Tag, Heading} from "Base";

const defaultListProps = {"grid": {gutter: 16, column: 4}};
const TagDisplay = ({tags, headerText, listProps}) => {
  const fullListProps = {...defaultListProps, ...listProps};

  return (
    <Fragment>
      {headerText && <Heading color={"colorPrimary"}>{headerText}</Heading>}
      <div className="tags">
        {tags.length !== 0 && <List {...fullListProps} dataSource={tags} renderItem={tag =>
          <Tag color={"#42afda"}>{tag}</Tag>
        }/>}
      </div>
    </Fragment>
  );
};

TagDisplay.propTypes = {
  /**
   * The tag list to display
   */
  tags: PropTypes.array.isRequired,
  /**
   * The text to display over the tag section
   */
  headerText: PropTypes.string,
  /**
   * Properties to add to the List ReactElement
   */
  listProps: PropTypes.object,
  /**
   * If more tags are allowed to be added
   */
  editable: PropTypes.bool
};

TagDisplay.defaultProps = {
  headerText: "",
  listProps: defaultListProps,
  editable: false
};

export default TagDisplay;
