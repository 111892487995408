import React, {Component} from "react";
import styled from "@emotion/styled";
import CompanyLogo from "../../../static/img/conmitto_logo_color.png";
import GeneralSignUpForm from "../Forms/GeneralSignUpForm";
import {postAxios} from "../../../utils/authWithAxios";
import {Flex, Heading} from "../../../components/Common/Base";

const Wrapper = styled(Flex)`
flex-grow: 1;
`;

/* The Signup container will handle registration. */
class SignupContainer extends Component {
  state = {
    submittingForm: false,
    signupSuccess: false,
    signupErrorMessage: "",
    formValues: {}
  };

  // A function that takes the field value, all the values of the form and the meta data
  // about the field and returns an error if the value is invalid, or undefined if the value is valid.
  validate = (formValues) => {
    if (formValues.user) {
      if (formValues.user.password !== formValues.confirm_password) {
        return {confirm_password: "both password fields must match"};
      }
    }
  };

  handleDirectLoginClick = () => {
    const email = this.state.formValues.user.email;
    const password = this.state.formValues.user.password;
    this.props.directLogin(email, password);
  };

  // Docs here https://github.com/final-form/final-form#onsubmit-values-object-form-formapi-callback-errors-object--void--object--promiseobject--void
  // Asynchronously with a callback: returns undefined,
  // calls callback() with no arguments on success, or with an Object of submission errors on failure.
  // Submission errors must be in the same shape as the values of the form. You may return a generic error for the whole form (e.g. 'Login Failed') using the special FORM_ERROR string key.
  submitForm = (formValues, form, callback) => {
    this.setState({
      formValues: formValues
    });
    const toSubmit = Object.assign({}, formValues);
    delete toSubmit.confirm_password;
    delete toSubmit.terms;
    delete toSubmit.company_name;
    delete toSubmit.org_type;

    let data = {
      ...toSubmit,
      "default_org": {
        "name": formValues.company_name.label,
        "id": formValues.company_name.value
      },
      "user": {
        ...formValues.user,
        "username": formValues.user.email,
      },
    };
    if (formValues.org_type) {
      data["default_org"]["type"] = formValues.org_type.value;
    }

    this.setState({
      signupSuccess: false,
      signupErrorMessage: ""
    });
    postAxios({
      url: `${global.base_url}v1/signup`,
      data: data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        this.setState({
          signupSuccess: true
        });
        callback();
      })
      .catch(error => {
        console.log("Error fetching and parsing data", error.response);
        let responseData = error.response.data;
        let errorMessage = "";
        // For username error
        if (responseData.user) {
          errorMessage = responseData.user.username;
        }
        // For other errors
        if (Array.isArray(responseData)) {
          errorMessage = responseData[0];
        }
        this.setState({
          signupSuccess: false,
          signupErrorMessage: errorMessage,
        });
        callback();
      });
  };

  render() {
    const {orgList} = this.props;
    return (
      <Wrapper className="login-card-signup"
        justifyContent="center"
        alignItems="center">
        <div className="login-card-signup-close" onClick={this.props.closeSignupModal}>
            X
        </div>
        <div className="login-card">
          <img
            src={CompanyLogo}
            className="login-card-logo"
            alt="Conmitto logo"
          />
          <div className="login-card-signup-form">
            <Heading className="login-card-signup-header-text">SIGN UP</Heading>
            {this.state.signupErrorMessage ? (
              <div className="login-card-msg-error">
                {this.state.signupErrorMessage}
              </div>
            ) : (
              <div/>
            )}
            {this.state.signupSuccess ? (
              <div className="login-card-msg-success" onClick={this.props.closeSignupModal}>
                <p>Account created successfully. </p>
                <p>The Conmitto team will be in touch shortly. </p>
              </div>
            ) : (
              <div/>
            )}
            <GeneralSignUpForm
              onSubmit={this.submitForm}
              validate={this.validate}
              orgList={orgList}
              signupSuccess={this.state.signupSuccess}
              handleDirectLoginClick={this.handleDirectLoginClick}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default SignupContainer;
