import {palletStatus, palletQuantityStatus} from "./constants";

/**
 * Takes an object and looks for a name property
 * @param {object} item - anything that might have a name property
 * @returns {string} - Name or "None"
 */
export const renderName = (item) => {
  return item && item.name ? item.name : "None";
};

/**
 * Renders the nested name
 * @param {object} item - item with a related object that has a name property
 * @param {string} key - property of object with name on item
 * @returns {string} - "None" or name of object
 */
export const renderNestedName = (item, key) => {
  let result = "None";
  if (item && item[key]) {
    result = renderName(item[key]);
  }
  return result;
};


const descriptionString = (item) => {
  const locationData = item.location;
  let location = "Add Location";
  let level = "None";

  if (locationData) {
    location = locationData.name;
    level = locationData.level;
  }
  let result = `Location: ${location} - ${level} `;
  return result;
};

const itemName = (item) => {
  let result = "Product";
  if (Object.prototype.hasOwnProperty.call(item, "product")) {
    if (Object.prototype.hasOwnProperty.call(item.product, "name")) {
      result = item.product.name;
    }
  }
  return result;
};


const palletItemSku = (item) => {
  let result = "";
  if (Object.prototype.hasOwnProperty.call(item, "product")) {
    if (Object.prototype.hasOwnProperty.call(item.product, "sku")) {
      result = item.product.sku;
    }
  }
  return result;
};

/**
 * Returns the product_inventory list
 * @param {object} item - pallet details
 * @returns {[]} - array of products
 */
const palletInventory = (item) => {
  let result = [];
  if (item && item.product_inventory) {
    result = item.product_inventory;
  }
  return result;
};

/**
 * Returns the material_inventory list
 * @param {object} item - pallet details
 * @returns {[]} - array of materials
 */
const palletMaterials = (item) => {
  let result = [];
  if (item && item.material_inventory) {
    result = item.material_inventory;
  }
  return result;
};
export {palletStatus, palletQuantityStatus, descriptionString, itemName, palletInventory, palletMaterials, palletItemSku};
