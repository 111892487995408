import React from "react";
import PropTypes from "prop-types";
import {Column, Label, Row, Typography} from "Base";
import {Field, Form} from "react-final-form";
import {Error, FormDropdown, FormField} from "../../../../Common/Forms/Base";
import {NumberInputControl} from "../../../../Common/Base/Inputs/NumberInput";
import RangePickerField from "../../../../Common/Fields/Dates/RangePickerField";
import {AdaptedDatePicker} from "../../../../Common/Base/Inputs/AdaptedInputs";
import {LabelText} from "../../../../Common/Forms/Base/styled";
import {parseDate} from "../../../../Common/Forms/Base/utils";
import moment from "moment-timezone";
import {formatSubmitValues} from "./utils";
import {formatSelectOptions} from "../../../../../utils/forms";
import {AdaptedPaginatedSelect} from "../../../../Common/Fields/PaginatedSelect/PaginatedSelect";
import {crudActions} from "../../../../../types/propTypes";

const getTemplateOptions = (values, products) => {
  // console.log("get template values", values);

  const product = products.find(item => item.id === values?.product ?? null);
  // const product =  values?.product ?? [];

  const templates = product?.templates ?? [];
  return formatSelectOptions(templates);
};


const formatOrderOptions = (data) => {
  console.log("order data", data);
  return data.map(item => ({label: item?.reference ?? item.id, value: item.id}));
};

const EntryForm = props => {
  const {
    onSubmit,
    initialValues,
    productionLine,
    id,
    errors,
    salesOrderActions
  } = props;

  const products = productionLine?.products ?? [];
  // const productOptions = products.map(item => ({label: item.name, ...item}));
  const productOptions = formatSelectOptions(products);

  const submitForm = (values) => {
    return onSubmit(formatSubmitValues(values));
  };

  return (
    <Form
      onSubmit={submitForm}
      initialValues={{
        ...initialValues,
        line: productionLine.id
      }}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form
          id={id}
          onSubmit={handleSubmit}>

          <Field name={"line"} component={"input"} type={"hidden"}/>
          <Column>
            <FormField
              label={"Date"}
              placeholder={"Date"}
              type="text"
              name="date"
              component={AdaptedDatePicker}
              parse={parseDate}
              format={(value) => value ? moment(value).format("YYYY-MM-DD") : null}
            />

            <Label><LabelText>Start and End</LabelText> </Label>

            <Error
              name={"start"}
              errors={errors}
              color={"red"}
            />
            <Error
              name={"end"}
              errors={errors}
              color={"red"}
            />

            <RangePickerField
              startName={"start"}
              endName={"end"}
              use12Hours
              format="h:mm a"
              // defaultValue={[moment(), null]}
            />
          </Column>
          <Row>
            <NumberInputControl
              label={"People Planned"}
              backgroundColor="white"
              placeholder={"Planned..."}
              name={"people_planned"}
              defaultValue={0}
              isDisabled={false}
              clampValueOnBlur={false}
              mr={2}
            />

            <NumberInputControl
              label={"People Actual"}
              backgroundColor="white"
              placeholder={"Actual..."}
              name={"people_actual"}
              defaultValue={null}
              isDisabled={false}
              clampValueOnBlur={false}
            />
          </Row>


          <Column>
            <Typography.Title level={3}>Job</Typography.Title>

            <FormDropdown
              label={"Sales Order"}
              name={"sales_order"}
              component={AdaptedPaginatedSelect}
              formatOptions={formatOrderOptions}
              getPage={salesOrderActions.getPage}
              format={(value) => {
                // if (value && typeof value === "number") {
                //   return assetList.find(el => el.id === value);
                // }
                return value ? {
                  value: value.id || value.value,
                  label: value.name || value.label,
                } : "";
              }}
              parse={(value) => {
                // console.log("parse value", value);
                // return value ? value.id || value.value : "";
                return value;
              }}
            />

            <Typography.Title level={3}>Product</Typography.Title>

            <FormDropdown
              label={"Product"}
              options={productOptions}
              name={"product"}
              placeholder={"Product.."}
              defaultValue={""}
            />
            <FormDropdown
              label={"Template"}
              options={getTemplateOptions(values, products)}
              name={"product_template"}
              placeholder={"Product Template.."}
            />
            <Row>
              <NumberInputControl
                label={"Capacity"}
                backgroundColor="white"
                placeholder={"Capacity..."}
                name={"capacity"}
                defaultValue={0}
                isDisabled={false}
                clampValueOnBlur={false}
                mr={2}
              />

              <NumberInputControl
                label={"Produced"}
                backgroundColor="white"
                placeholder={"Produced..."}
                name={"produced"}
                defaultValue={null}
                isDisabled={false}
                clampValueOnBlur={false}
              />
            </Row>
          </Column>
        </form>
      )}
    />
  );
};

EntryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  productionLine: PropTypes.object,
  salesOrderActions: crudActions,
  errors: PropTypes.any
};

EntryForm.defaultProps = {
  id: "entry-form",
  errors: {},
};

export default EntryForm;
