import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {Box} from "../../components/Common/Base";
import SnackBarDispatch from "../../components/Common/SnackBar/SnackBar";
import {authOperations, authSelectors, orgOperations, orgSelectors} from "../../store/";
import styled from "@emotion/styled";
import {Footer} from "../../components/Common";
import {
  AdminSlidingPane,
  HeaderContainer
} from "../index";
import {DashboardRoutes} from "../../routes";
import {Layout} from "../../components/Common/Layout";

const NUM_LOGS_ENTRIES_TO_GET = 5;

/* The dashboard container is a top level component that provides a wrapper for the view
area of the internal Conmitto experience. Users must first be logged into the system to access
the dashboard. */

const FlexBox = styled(Box)`
  flex: 1
`;

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doneLoading: false,
      isPaneOpen: false,
    };
  }

  /* Upon mounting, verify that the user is logged in. */
  componentDidMount = () => {
    const {checkLogin, getRecentLogs} = this.props;
    checkLogin().then(loggedIn => {
      if (loggedIn) {
        getRecentLogs(NUM_LOGS_ENTRIES_TO_GET);
        // this.handleWebsocket()
      }
      this.setState({doneLoading: true});
    });
    if (!localStorage.getItem("oldHistory")) {
      localStorage.setItem("oldHistory", this.props.location.pathname);
    } else {
      localStorage.removeItem("oldHistory");
    }
  };


  setPanel = (isPaneOpen) => {
    this.setState({isPaneOpen});
  };

  render() {
    const {doneLoading, isPaneOpen} = this.state;
    const {accountInfo, resetStore, isAuthenticated, logList} = this.props;


    if (doneLoading) {
      if (!isAuthenticated) {
        return <Redirect to="/"/>;
      }
      if (accountInfo && accountInfo.personnel) {
        if (accountInfo.email && accountInfo.personnel.orgs.length === 0) {
          return <Redirect to={"/onboarding"}/>;
        }

        if (accountInfo.personnel.invitation_pending) {
          return <Redirect to={"/password"}/>;
        }

        if (accountInfo.personnel.needs_approval) {
          resetStore();
        }

        if (!accountInfo.id) {
          // CheckLogin()
        }
      }
    }


    return (
      <Layout
        account={accountInfo}
        resetStore={resetStore}
        doneLoading={doneLoading}
        id="wrapper"
        column={true}
        flexDirection={"column"}
        footer={<Footer/>}
        mainHeader={
          <HeaderContainer
            account={accountInfo}
            resetStore={resetStore}
            logs={logList}
            maxNumLogEntries={NUM_LOGS_ENTRIES_TO_GET}
            setPanel={this.setPanel}/>
        }
      >

        <AdminSlidingPane
          setPanel={this.setPanel}
          isPaneOpen={isPaneOpen}
        />
        <SnackBarDispatch/>
        <FlexBox>
          <DashboardRoutes accountInfo={accountInfo}/>
        </FlexBox>
      </Layout>
    );
  }
}

/* Here we will map Redux's store state to props to be accessed and manipulated within the
context of this container */
const mapStateToProps = state => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  isAuthenticated: authSelectors.isAuthenticated(state.account),
  logList: orgSelectors.refreshLogs(state.orgs),
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  checkLogin: async () => dispatch(await authOperations.checkLogin()),
  resetStore: () => dispatch(authOperations.logout()),
  getRecentLogs: (num_to_get) => dispatch(orgOperations.getRecentLogs(num_to_get)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardContainer);
