// products/reducers.js
import {combineReducers} from "redux";

const createBarcodeReducer = (types) => {
  /* Currently doesn't do anything, but putting here for future development */
  const generate = (state = null, action) => {
    switch (action.type) {
    case types.GENERATE_BARCODE_SUCCESS:
      return state;
    case types.GENERATE_BARCODE_FAILURE:
      return state;
    default:
      return state;
    }
  };

  return combineReducers({
    generate
  });
};

export {createBarcodeReducer};
