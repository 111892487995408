import PropTypes from "prop-types";
import {LIST, TABLE, TILE, CALENDAR} from "store/base/crud/utils";

const dropdownMenuPropType = PropTypes.shape({
  key: PropTypes.string,
  text: PropTypes.string
});

const crudActions = PropTypes.shape({
  update: PropTypes.func,
  create: PropTypes.func,
  delete: PropTypes.func,
  getPage: PropTypes.func,
  getItem: PropTypes.func
});


const paginationProps = PropTypes.shape({
  /**
   * Last page number
   */
  itemsLastPage: PropTypes.number,
  /**
   * Array of items for current page
   */
  itemsPaged: PropTypes.array,
  itemsErrored: PropTypes.bool,
  /**
   * True if get page api request in progress
   */
  itemsRequested: PropTypes.bool,
  isEmpty: PropTypes.bool,
  pageSize: PropTypes.number,
  /**
   * Current page number
   */
  itemsCurrentPage: PropTypes.number,
  /**
   * Total number of items on all pages
   */
  itemsCount: PropTypes.number,
  /**
   * Returns string constant for List, Table, or Tile view
   */
  pageView: PropTypes.oneOf([LIST, TABLE, TILE, CALENDAR]),
  pageLoading: PropTypes.bool,
  loading: PropTypes.bool
});

/**
 * value/label object for react-select
 * */
const selectOptionType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string
});

/**
 * value/label/field for react-select
 * */
const filterOption = PropTypes.shape({
  /**
   * value of field to filter with
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * name of field to sort bye
   */
  field: PropTypes.string,
  label: PropTypes.string
});

const selectOptionsType = PropTypes.arrayOf(selectOptionType);

const filterOptionsType = PropTypes.arrayOf(filterOption);

export {
  dropdownMenuPropType,
  crudActions,
  paginationProps,
  selectOptionType,
  selectOptionsType,
  filterOption,
  filterOptionsType
};
