import React from "react";
import PropTypes from "prop-types";
import {Row, Card, Descriptions, Link, Heading} from "Base";
import {crudActions} from "../../../../../types/propTypes";
import {renderInventoryTitle} from "../../../../Warehouse/utils/inventory";
import {formatTime} from "./utils";
import {SALES_ORDERS_DASHBOARD_ROUTE} from "../../../../../routes/constants";
import EntryFormDrawer from "./EntryFormDrawer";


const ActiveEntry = props => {
  const {data, productionLine, actions, lineActions, salesOrderActions} = props;

  const handleSubmit = async (values) => {
    const res = await actions.update(data.id, values);
    lineActions.getItem();
    return res;
  };

  return data && (
    <Card>
      <Descriptions
        size={"small"}
        title={
          <Row alignItems={"center"}>
            <Heading>
            Active
            </Heading>
            <EntryFormDrawer
              onSubmit={handleSubmit}
              productionLine={productionLine}
              formConfig={{
                salesOrderActions
              }}
              values={data}
              text={{
                title: "Edit Production Entry",
                tooltip: "Edit active production entry",
                openButton: "Edit Entry",
              }}
              buttonConfig={{
                size: "small",
                type: "primary",
                ariaLabel: "Open Entry Form",
                className: "open-edit-entry-btn"
              }}
            />
          </Row>
        }
        layout={"vertical"}
        className={"active-entry"}
        bordered
        column={{xxl: 5, xl: 4, lg: 4, md: 4, sm: 3, xs: 2}}
      >

        <Descriptions.Item label={"Start"}>{formatTime(data.start)}</Descriptions.Item>
        <Descriptions.Item label={"End"}>{formatTime(data.end)}</Descriptions.Item>
        <Descriptions.Item label={"People Planned"}>{data.people_planned}</Descriptions.Item>
        <Descriptions.Item label={"Actual"}>{data.people_actual}</Descriptions.Item>
        <Descriptions.Item label={"Product"} span={2}>
          {renderInventoryTitle(data.product)}
        </Descriptions.Item>
        <Descriptions.Item label={"Capacity"}>{data.capacity}</Descriptions.Item>
        <Descriptions.Item label={"Produced"}>{data.produced}</Descriptions.Item>
        <Descriptions.Item label={"Job"} span={2}>
          <Link to={`${SALES_ORDERS_DASHBOARD_ROUTE}/${data?.sales_order?.id ?? ""}`}>
            {data?.sales_order?.reference ?? "None"}
          </Link>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

ActiveEntry.propTypes = {
  data: PropTypes.object.isRequired,
  productionLine: PropTypes.object.isRequired,
  actions: crudActions.isRequired,
  lineActions: crudActions.isRequired,
  salesOrderActions: crudActions.isRequired
};

export default ActiveEntry;
