import React, {Fragment} from "react";
import {Flex, Box, Heading, Link, OptionalLink, Stat, StatGroup, StatLabel, StatNumber} from "../../../components/Common/Base";
import {CategoryContainer, CategoryHeader, MRUBox} from "./styled";

const SummaryContainer = (props) => {
  const {
    title,
    titleLink,
    summaryName1,
    summaryNumber1,
    summaryColor1,
    summaryLink1,
    summaryName2,
    summaryNumber2,
    summaryColor2,
    summaryLink2,
    summaryName3,
    summaryNumber3,
    summaryColor3,
    summaryLink3,
    mostRecentUpdates,
    topGradientColor,
    hrGradientColor,
  } = props;

  return (
    <Box
      p={8}
      margin={"10px auto"}
      maxWidth={1200}>
      <Box
        height={15}
        borderRadius={"10px 10px 0 0"}
        // backgroundImage={topGradientColor}
        style={{
          backgroundImage: topGradientColor
        }}
      />
      <CategoryContainer>
        <Link to={`/dashboard/${titleLink}`}>
          <Heading as="h1" fontSize="xl" color={"black"}>{title}</Heading>
        </Link>
        <hr style={{
          border: 0,
          height: "3px",
          backgroundImage: hrGradientColor,
        }}/>
        <CategoryHeader as="h2">SUMMARY</CategoryHeader>
        <StatGroup pt={3}>
          <Stat>
            <OptionalLink to={summaryLink1}>
              <StatNumber color={summaryColor1}>{summaryNumber1 || 0}</StatNumber>
              <StatLabel color={summaryColor1}>{summaryName1}</StatLabel>
            </OptionalLink>
          </Stat>
          <Stat>
            <OptionalLink to={summaryLink2}>
              <StatNumber color={summaryColor2}>{summaryNumber2 || 0}</StatNumber>
              <StatLabel color={summaryColor2}>{summaryName2}</StatLabel>
            </OptionalLink>
          </Stat>
          <Stat>
            <OptionalLink to={summaryLink3}>
              <StatNumber color={summaryColor3}>{summaryNumber3 || 0}</StatNumber>
              <StatLabel color={summaryColor3}>{summaryName3}</StatLabel>
            </OptionalLink>
          </Stat>
        </StatGroup>

        {/* <Heading as="h3" backgroundColor={"#f8f8f8"} boxShadow="0 2px 3px grey">WEEK TO DATE</Heading>*/}
        {/* <Flex fontSize={"1.4em"} flexWrap={'wrap'}>*/}
        {/*  <NumberContainer name={weekToDateName1} number={weekToDateNumber1} color={weekToDateColor1}/>*/}
        {/*  <NumberContainer name={weekToDateName2} number={weekToDateNumber2} color={weekToDateColor2}/>*/}
        {/*  <NumberContainer name={weekToDateName3} number={weekToDateNumber3} color={weekToDateColor3}/>*/}
        {/* </Flex>*/}
        {mostRecentUpdates && mostRecentUpdates.length !== 0 &&
        <Fragment>
          <CategoryHeader as="h3">MOST RECENTLY UPDATED</CategoryHeader>
          <Flex overflowX={"auto"} padding={"10px 0"}>
            {mostRecentUpdates.map((object, index) => {
              return (
                <MRUBox key={index}>
                  <Link
                    to={`/dashboard/${titleLink}/${object.id}`}
                    p={"10px"}
                    color={"black"}
                    display={"block"}
                    width="100%">
                    {object.name || object.title || `${title} ${object.id}`}
                  </Link>
                </MRUBox>
              );
            })}
          </Flex>
        </Fragment>
        }
      </CategoryContainer>
    </Box>
  );
};

export default SummaryContainer;
