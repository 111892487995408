import React, {Fragment} from "react";
import PropTypes from "prop-types";
import LocationItem from "./LocationItem";

const LocationGroup = props => {
  const {
    handleEdit, items, classes, levels, isEditable,
    selectedLocation,
    setSelectedLocation,
    updateLocation,
    accountInfo
  } = props;
  if (Array.isArray(items)) {
    return (
      items.map((item, i) => {
        return <LocationItem
          handleEdit={handleEdit}
          key={i}
          item={item}
          levels={levels}
          classes={classes}
          isEditable={isEditable}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          updateLocation={updateLocation}
          accountInfo={accountInfo}/>;
      })
    );
  } else {
    return (<Fragment/>);
  }
};

LocationGroup.propTypes = {
  items: PropTypes.array.isRequired,
  levels: PropTypes.array,
  classes: PropTypes.any,
  handleEdit: PropTypes.func,
  isEditable: PropTypes.bool,
  selectedLocation: PropTypes.object,
  setSelectedLocation: PropTypes.func
};

LocationGroup.defaultProps = {
  selectedLocation: {uniqueId: "0-0-0", item: null}
};

export default LocationGroup;
