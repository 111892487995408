import React from "react";
import PropTypes from "prop-types";
import EntryCardItem from "./EntryCard";
import {List} from "Common/Base";
import {crudActions} from "../../../../../types/propTypes";

const EntryList = props => {
  const {
    data,
    actions
  } = props;

  return (
    <List
      bordered
      itemLayout={"vertical"}
      style={{backgroundColor: "white"}}
      dataSource={data}
      renderItem={item => <EntryCardItem item={item} actions={actions} />}
    />
  );
};

EntryList.propTypes = {
  data: PropTypes.array.isRequired,
  actions: crudActions.isRequired
};

export default EntryList;
