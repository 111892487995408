import actions from "./actions";
import {materialEndpoint} from "./constants";
import {baseOperations} from "../base";
import {paginationActions} from "./pagination";
import types from "./types";
import {dateUtils, snackUtils} from "../../utils";
import base from "../base/actions";

/* This Function handles sending the GET request to the api with the endpoint defined in ./index.js.
On Success or Failure, this will dispatch the apporiate action as defined in './actions.js'. */
const getAllItems = () => base.getRsaa({
  endpoint: `${global.base_url}${materialEndpoint}`,
  types: [types.GET_ALL_REQUEST, types.GET_ALL_SUCCESS, types.GET_ALL_FAILURE]
});

const createItem = data => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Creating Material", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.createItem(data));
  baseOperations.handleCreateResponse(res, timestamp + 1, "material type", res.payload.name, dispatch, () => {
    dispatch(paginationActions.fetchPage({page: 1}));
  });
  return res.payload;
};

/* This Function handles sending an Item update request to the API.
Then dispatches either the updateSuccess or updateFailure action.
Also dispatches snackbar messages on start and finish. */
const updateItem = (id, data) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Updating Material", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.updateItem(id, data));
  console.log(res);
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Material", res.payload.name, dispatch, () => {
    dispatch(paginationActions.fetchPage({page: 1}));
  });
  return res.payload;
};

const bulkDelete = (data) => async dispatch => {
  const res = await dispatch(actions.bulkDelete(data));
  console.log("BULK OPERATIONS: ", res);
  dispatch(paginationActions.fetchPage({page: 1}));
};

const deleteItem = id => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Deleting Material", 1000, timestamp, false, dispatch);
  const res = await dispatch(actions.deleteItem(id));
  dispatch(paginationActions.fetchPage({page: 1}));

  console.log(res);
  return res;
};

/* This Function handles sending an Item update request to the API
for a specific field. Then dispatches either the updateSuccess or
updateFailure action. Also dispatches snackbar messages on start
and finish, and updates asset data upon success. */
const updateItemField = (id, fieldData) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  const res = await dispatch(actions.updateItem(id, fieldData));
  baseOperations.handleUpdateResponse(res, timestamp + 1, "Material", res.payload.name, dispatch, () => {
    dispatch(getAllItems());
  });
};

const uploadCsv = (data, org) => async dispatch => {
  const timestamp = dateUtils.createTimestamp();
  snackUtils.createSnack("Submitting CSV Upload", 2000, timestamp, false, dispatch);
  const res = await dispatch(actions.uploadCsv(data, org));
  baseOperations.handleCSVUploadResponse(res, dispatch, null);
};

/* This Function handles adding a new item to the current parent item.
The first example of this is adding a note to an asset. */
const addNote = (id, data) => async dispatch => {
  const res = await dispatch(actions.submitNote(id, data));
  baseOperations.handleSubmitNoteResponse(res, dispatch);
  return res.payload;
};

export default {
  getAllItems,
  createItem,
  updateItem,
  deleteItem,
  updateItemField,
  uploadCsv,
  addNote,
  bulkDelete
};
