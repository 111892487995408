import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, List, Typography} from "../../../components/Common/Base";

const PackingResults = ({results, reset}) => {
  const [hasUnfitted, setHasUnfitted] = useState(false);

  const renderPallet = (item) => {
    if (item.unfitted.length > 0 && !hasUnfitted) setHasUnfitted(true);

    return (
      <>
        <h4>{item.pallet}</h4>
        {item.manifest.length > 0 &&


        <List
          dataSource={item.manifest}
          header={<div>Fitted</div>}
          renderItem={item => (
            <List.Item>
              <Typography.Text>[{item.name}]</Typography.Text>
              <Typography.Text>Position: {item.position[0]}x{item.position[1]}x{item.position[2]}</Typography.Text>

            </List.Item>
          )}
        />
        }
        {item.unfitted.length > 0 &&

        <List
          dataSource={item.unfitted}

          header={<div>Unfitted</div>}
          renderItem={item => (
            <List.Item>
              <Typography.Text>[{item.name}]</Typography.Text>
              <Typography.Text>Dimensions: {item.weight}x{item.height}x{item.depth}</Typography.Text>
            </List.Item>
          )}
        />
        }
      </>
    );
  };
  return (
    <div>
      {results.map(pallet => (
        renderPallet(pallet)
      ))}

      {hasUnfitted ?
        <Button onClick={reset}>Try Again</Button>
        :
        <Typography.Text>Unfitted: 0</Typography.Text>
      }
    </div>
  );
};

PackingResults.propTypes = {
  results: PropTypes.array
};

export default PackingResults;
