import React, {useState, Fragment} from "react";
import PropTypes from "prop-types";
import {AddItemModal, ButtonBar, HelpButton, ToolBar, TutorialModal} from "../../index";
import {Box, Checkbox, Button} from "Base";
import BannerOne from "../../../../static/img/BannerOne.png";
import {Loading} from "../../Base";
import {filterOptionsType} from "../../../../types/propTypes";

/* This will encase all common dashboard modal logic */
const OldStyleDashboard = ({loading, allItems, csvMessages, clearMessages, hasEditAccess, csvColumns,
  csvData, handleSubmitAddItem, helpDialog, type, addItemModalSubmitting, addItemModalError, buttonBarActions,
  listView, tileView, tableView, changeViewList, changeViewTile, changeViewTable, changeViewCalendar, filter, reverseList,
  isReversed, filteringBy, filteringByType, filterTypeOptions, pageNum, nextPage, prevPage, isLastPage,
  handleFilterBy, handleFilterByType, calendarView, currentPage, bulkDelete, barComponent, tableComponent,
  filterItems, sortItems, getItemList, uploadCsv, csvHelpText, accountInfo, selectionOptions, filterOptions
}) => {
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(true);
  const [showTutFromHelp, setShowTutFromHelp] = useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  // Close AddItemModal and clear messages
  const handleCloseAddItemModal = () => {
    setShowAddItemModal(false);
    clearMessages();
  };

  // Close the TutorialModal
  const handleCloseTutModal = () => {
    setShowTutorialModal(false);
    setShowTutFromHelp(false);
  };

  // Search for target value using passed filter function
  const handleSearch = e => {
    e.preventDefault();
    filterItems(e.target.value);
  };

  // Sort for value using passed sorting function
  const updateSortBy = e => {
    sortItems(e.value);
  };

  // Upload Csv and using passed redux operations
  const handleUploadCsv = async (data) => {
    await uploadCsv(data, accountInfo.personnel.orgs[0].id);
    getItemList && getItemList();
  };

  // keep track of selections on the table component
  const onTableSelectChange = (selectedRowsIds) => {
    setSelectedRowsIds(selectedRowsIds);
  };

  // Delete a selection of items (selectedRowsIds)
  const onDeleteSelected = () => {
    bulkDelete(selectedRowsIds);
    setSelectedRowsIds([]);
    setShowDelete(false);
  };

  // Keep track of selections over all views, also set deleteAllIsChecked based on selection set
  const onCheckChange = (id) => {
    if (!selectedRowsIds.includes(id)) {
      setSelectedRowsIds([id, ...selectedRowsIds]);
    } else {
      setSelectedRowsIds(selectedRowsIds.filter(x => x !== id));
    }
  };

  /* Add all items to the delete list when checked and revert back to the
     previously checked assets when unchecked. */
  const handleDeleteAllIsChecked = (e) => {
    if (e.target.checked) {
      setSelectedRowsIds(currentPage.map(x => x.id));
    } else {
      setSelectedRowsIds([]);
    }
  };

  const deleteAllCheckedFromProps = () => {
    const idLen = selectedRowsIds.length;
    const allIds = currentPage.map(item => item.id);
    return allIds.length === 0 ? false : idLen === allIds.length;
  };

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
    setSelectedRowsIds([]);
  };

  return (
    <Fragment>
      {!loading &&
      <ToolBar
        showDelete={showDelete}
        handleShowDelete={handleShowDelete}
        handleChange={handleSearch}
        updateSortBy={updateSortBy}
        nextPage={nextPage}
        prevPage={prevPage}
        pageNum={pageNum}
        changeViewList={changeViewList}
        changeViewTile={changeViewTile}
        changeViewTable={changeViewTable}
        listView={listView}
        tileView={tileView}
        tableView={tableView}
        isLastPage={isLastPage}
        selectionOptions={selectionOptions}
        filter={filter}
        reverseList={reverseList}
        isReversed={isReversed}
        handleAddItemButtonClick={type === "Vendor Order" ? () => setShowTutFromHelp(true) : () => setShowAddItemModal(true)}
        objectType={type}
        filterOptions={filterOptions}
        filterListBy={handleFilterBy}
        filteringBy={filteringBy}
        filterListByType={handleFilterByType}
        filteringByType={filteringByType}
        filterTypeOptions={filterTypeOptions}
        changeViewCalendar={changeViewCalendar}
        calendarView={calendarView}
      />
      }
      {showDelete && !calendarView &&
        <Box style={{marginBottom: "10px"}} display="flex" justifyContent={!tableView ? "space-between" : "flex-end"}>
          {!tableView &&
          <Checkbox id="deleteSelectAll" checked={deleteAllCheckedFromProps()} onClick={handleDeleteAllIsChecked}>Select all</Checkbox>}
          <div>
            <Button
              id="confirmDeleteButton"
              onClick={onDeleteSelected}
              danger={selectedRowsIds.length !== 0}
              disabled={selectedRowsIds.length === 0}
            >Confirm Delete {selectedRowsIds.length + "/" + currentPage.length}</Button>
          </div>
        </Box>
      }
      {!loading && hasEditAccess &&
          <div>
            <div data-tip="Open a help popup" data-delay-show="1000">
              <HelpButton handleClick={() => setShowTutFromHelp(!showTutFromHelp)}/>
            </div>
            {type !== "Vendor Order" &&
            <div data-tip={`Add an ${type}.`} data-delay-show="1000">
              <ButtonBar
                handleClick={() => setShowAddItemModal(true)}
                actions={buttonBarActions}
              />
            </div>}
          </div>
      }
      <div className="row">
        <div className={(listView) ? "items-flex-row" : "items-flex-column"}>
          {loading && <Loading title={`Loading ${type}...`}/>}
          {(listView || tileView) && currentPage.length > 0
                && currentPage.map((data, key) => (
                  React.cloneElement(barComponent, {data, listView, onCheckChange, showDelete, selectedRowsIds, key})
                ))}
          {tableView && allItems && React.cloneElement(tableComponent, {currentPage, allItems, selectedRowsIds, onTableSelectChange})}
        </div>
      </div>
      <TutorialModal
        visible={(allItems.length === 0 && showTutorialModal && !loading) || (showTutFromHelp)}
        closeModal={handleCloseTutModal}
        banner={BannerOne}
        type={type}
        descriptions={helpDialog}
        handleOpenAddItemModal={type !== "Vendor Order" ?
          [() => setShowAddItemModal(true), "Create One!"]
          :
          [false, "Go to Assets"]}
      />
      <AddItemModal
        visible={showAddItemModal}
        itemName={type}
        closeAddItemModal={handleCloseAddItemModal}
        submitAddItem={handleSubmitAddItem}
        addItemSubmitting={addItemModalSubmitting}
        addItemError={addItemModalError}
        handleUploadCsv={handleUploadCsv}
        columns={csvColumns}
        showCsv
        csvMessages={csvMessages}
        clearCsvMessages={clearMessages}
        sampleData={csvData}
        csvHelpText={csvHelpText}
      />
    </Fragment>
  );
};

export {OldStyleDashboard};

OldStyleDashboard.propTypes = {
  /**
   *  The following are DISPLAY BOOLEAN attributes that dictate how the age is displayed
   */
  loading: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
  addItemModalSubmitting: PropTypes.bool,
  addItemModalError: PropTypes.bool,
  listView: PropTypes.bool,
  tileView: PropTypes.bool,
  tableView: PropTypes.bool,
  isReversed: PropTypes.bool,
  isLastPage: PropTypes.bool,
  calendarView: PropTypes.bool,
  /**
   *  The following represents all the DATA that the OldStyleDashboard uses for CONTENT
   */
  accountInfo: PropTypes.PropTypes.object.isRequired,
  allItems: PropTypes.array,
  selectionOptions: PropTypes.array,
  filterOptions: PropTypes.array,
  currentPage: PropTypes.arrayOf(PropTypes.object),
  pageNum: PropTypes.object,
  type: PropTypes.string.isRequired,
  helpDialog: PropTypes.arrayOf(PropTypes.string).isRequired,
  csvMessages: PropTypes.any,
  csvColumns: PropTypes.array,
  csvData: PropTypes.any,
  filter: PropTypes.string,
  filteringBy: PropTypes.string,
  csvHelpText: PropTypes.PropTypes.arrayOf(PropTypes.string),
  /**
   * The following are functions used to manipulate the DISPLAY BOOLEAN and DATA sets
   */
  clearMessages: PropTypes.func,
  handleSubmitAddItem: PropTypes.func,
  buttonBarActions: PropTypes.array,
  changeViewList: PropTypes.func,
  changeViewTile: PropTypes.func,
  changeViewTable: PropTypes.func,
  changeViewCalendar: PropTypes.func,
  reverseList: PropTypes.func,
  filteringByType: PropTypes.func,
  filterTypeOptions: filterOptionsType,
  nextPage: PropTypes.func,
  prevPage: PropTypes.func,
  handleFilterBy: PropTypes.func,
  handleFilterByType: PropTypes.func,
  bulkDelete: PropTypes.func,
  barComponent: PropTypes.object.isRequired,
  tableComponent: PropTypes.object.isRequired,
  filterItems: PropTypes.func,
  sortItems: PropTypes.func,
  getItemList: PropTypes.func,
  uploadCsv: PropTypes.func,
};

OldStyleDashboard.defaultProps = {
  buttonBarActions: [],
  listView: true,
  csvMessages: [],
  clearMessages: () => null,
  csvColumns: [],
  csvData: [],
  csvHelpText: [],
  filterTypeOptions: [],
};
