import React, {useState} from "react";
import PropTypes from "prop-types";
import {ShiftList, ScheduleList} from "../../Lists/PersonnelScheduleList";
import {useStyles} from "../../Lists/PersonnelScheduleList/styled";
import ReactTooltip from "react-tooltip";
import AssignScheduleForm from "./AssignScheduleForm";
import {FormWrapper, ListWrapper} from "./styled";
import {Box, H2} from "../../Base";
import {Plus} from "../../Base/Icons";
import DateRangeForm from "./DateRangeForm";
import moment from "moment";
import {REMOVE} from "./utils";
// Import {Label} from "../Base/styled";
/*
This component is for Personnel Details pages.
Allows viewing upcoming shifts and assigning schedules
 */

const DEFAULT_RANGE = {
  start: moment().format("YYYY-MM-DD"),
  end: moment().add(7, "days").format("YYYY-MM-DD"),
};

const formatDateValuesForQuerry = (values) => {
  let string = `?start=${values.start}&end=${values.end}`;
  return string;
};

const PersonnelScheduleForm = props => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const classes = useStyles();
  const {personnel, shifts, scheduleList, submitScheduleForm, editAccess, submitDateRange} = props;

  const handleRemoveSchedule = async (item) => {
    const data = {
      personnel: personnel.id,
      action: REMOVE,
    };
    await submitScheduleForm(item.id, data);
    submitDateRange(formatDateValuesForQuerry(dateRange));
  };

  const handleSelectSchedule = (item) => {
    console.log("schedule", item);
  };

  const handleSelectShift = (item) => {
    // TODO: do something
    console.log("shift", item);
  };
  const handleSubmitScheduleForm = async (values) => {
    const data = {
      personnel: personnel.id,
    };

    await submitScheduleForm(values.schedule, data);
    submitDateRange(formatDateValuesForQuerry(dateRange));
  };

  const handleSubmitDateRange = (values) => {
    const newRange = { // Must destructure both so single field updates autosave
      ...dateRange,
      ...values,
    };
    submitDateRange(formatDateValuesForQuerry(newRange));
    setDateRange(newRange);
  };

  return (
    <Box mb="40px">
      <ReactTooltip/>
      <Box p={3}>
        <H2 my={5} pr={"0.75em"} display={"inline"}>ASSIGNED SCHEDULES</H2>
        <Plus
          style={{cursor: "pointer"}}
          className={"add-schedule-icon"}
          onClick={() => setShowScheduleForm(!showScheduleForm)}
        />
        {showScheduleForm &&
        <FormWrapper>
          <AssignScheduleForm
            onSubmit={handleSubmitScheduleForm}
            scheduleList={scheduleList}
            editAccess={editAccess}
          />
        </FormWrapper>
        }


        <ListWrapper>
          <ScheduleList
            items={personnel.schedules}
            isEditable={editAccess}
            handleItemClick={handleSelectSchedule}
            onRemove={handleRemoveSchedule}
          />

        </ListWrapper>
      </Box>
      <Box p={3}>
        <H2 display={"inline"}>UPCOMING SHIFTS</H2>


        <FormWrapper>
          <DateRangeForm
            onSubmit={handleSubmitDateRange}
            values={dateRange}
          />
        </FormWrapper>
        <ShiftList
          classes={classes}
          items={shifts}
          onItemClick={handleSelectShift}
        />
      </Box>
    </Box>
  );
};

PersonnelScheduleForm.propTypes = {
  personnel: PropTypes.object,
  schedule: PropTypes.array,
  onSubmit: PropTypes.func,
  shifts: PropTypes.array,
  scheduleList: PropTypes.array, // List of schedules for the assign schedule form
  submitScheduleForm: PropTypes.func,
  editAccess: PropTypes.bool,
  submitDateRange: PropTypes.func
};

export default PersonnelScheduleForm;
