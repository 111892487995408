import React, {Component} from "react";
import PropTypes from "prop-types";
import {Affix} from "antd";

class NavAffix extends Component {
  render() {
    const {children, offsetTop} = this.props;
    return (
      <Affix offsetTop={offsetTop}>
        {children}
      </Affix>
    );
  }
}

NavAffix.propTypes = {
  offsetTop: PropTypes.number
};

NavAffix.defaultProps = {
  offsetTop: 0
};


export default NavAffix;
