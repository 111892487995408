import React, {Component} from "react";
import PropTypes from "prop-types";
import Popover from "react-tiny-popover";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box} from "@chakra-ui/core";
import {CloseIcon, ErrorBoundary, ErrorHeading, Heading} from "../../Base";
import {CloseWrapper, dropdownContainerStyles, DropdownWrapper, HeaderWrapper} from "./styled";
import {Redirect} from "react-router";
import BarcodeScanner from "../../Files/BarcodeScanner";
import {getAxios} from "../../../../utils/authWithAxios";

class BarcodeDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      barcodeResult: null,
      errorText: ""
    };
  }


  toggleDropdown = () => {
    this.setState({isOpen: !this.state.isOpen});
    this.setState({errorText: ""});
  };

  handleBarcodeScan = ({codeResult: {code}}) => {
    getAxios({url: `${global.base_url}v1/barcode/search?data=${code}`})
      .then(res => {
        let data = res.data;
        if (data.length > 0) {
          let obj = data[0].object;
          if (obj !== undefined) {
            this.setState({barcodeResult: obj});
          }
        } else {
          this.setState({errorText: "Couldn't find matching object"});
        }
      });
  };

  handleBarcodeFailure = (error) => {
    if (typeof (error) === "string") {
      this.setState({errorText: error});
    }
  };

  render() {
    const {isOpen, barcodeResult, errorText} = this.state;

    if (barcodeResult) {
      // Replace product with inventory because that's how the routes are setup
      let barcode = this.state.barcodeResult.replace("products", "inventory")
        .replace("stockroom_assets", "stockroom_asset");
      return <Redirect to={`/dashboard/${barcode}`}/>;
    }
    return (
      <Popover
        isOpen={isOpen}
        onClickOutside={() => this.setState({isOpen: false})}
        position={"bottom"}
        align={"center"}
        containerStyle={dropdownContainerStyles}
        content={(
          <DropdownWrapper w={"350px"} className={"barcode-scanner-dropdown"}>
            <HeaderWrapper>
              <Heading textAlign="center">Scan a Code-128 Barcode</Heading>
              <ErrorBoundary errorText="Unable to render scanner">
                <BarcodeScanner
                  onDetected={this.handleBarcodeScan}
                  onFailed={this.handleBarcodeFailure}
                  initializationTimeout={10}/>
              </ErrorBoundary>
              <ErrorHeading>{errorText}</ErrorHeading>
              <CloseWrapper>
                <CloseIcon onClick={this.toggleDropdown}/>
              </CloseWrapper>
            </HeaderWrapper>
          </DropdownWrapper>
        )}
      >
        <Box className="barcode-dropdown-icon" mr={"15px"} cursor={"pointer"} onClick={this.toggleDropdown}>
          <FontAwesomeIcon
            icon="barcode"
            size="lg"/>
        </Box>
      </Popover>
    );
  }
}

BarcodeDropdown.propTypes = {
  setSnackMsg: PropTypes.func
};

export default BarcodeDropdown;
