import React, {useState, Fragment} from "react";
import PropTypes from "prop-types";
import {Row} from "../styled";
import {Box, Flex, Text, Button} from "../../Base";
import {ScheduleForm, ScheduleList, ShiftForm} from "./index";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import AssignPersonnelForm from "./AssignPersonnelForm";


const ScheduleShiftForm = props => {
  const {
    createSchedule,
    updateSchedule,
    // TODO deleteSchedule,
    assignPersonnel,
    createShift,
    updateShift,
    schedules,
    personnelList,
    locationList,
    editAccess
  } = props;

  const [isEditing, setEditing] = useState(false);
  // Const [showScheduleControls, setShowScheduleControls] = useState(false);

  const [showShiftForm, setShowShiftForm] = useState(false);
  const [showPersonnelForm, setShowPersonnelForm] = useState(false);

  const [currentShift, setCurrentShift] = useState(null);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [currentPersonnel, setCurrentPersonnel] = useState(null);

  const handleSubmitScheduleForm = async (data) => {
    if (isEditing && currentSchedule) {
      const res = await updateSchedule(currentSchedule.id, data);
      console.log("schedule res", res);
    } else {
      const res = await createSchedule(data);
      console.log("schedule res", res);
    }
  };

  const handleSubmitShiftForm = async (data) => {
    if (isEditing && data.id && currentSchedule) {
      const {id, ...rest} = data;
      const res = await updateShift(currentSchedule.id, id, rest);
      console.log("shift update res", res);
      if (!res.error) {
        setCurrentShift(res.payload);
      }
    } else {
      const res = await createShift(currentSchedule.id, data);
      console.log("shift create res", res);
      if (!res.payload.error) {
        setShowShiftForm(false);
      }
    }
  };


  const handleSubmitAssignPersonnel = (values) => {
    console.log("assign personnel", values);
    assignPersonnel(currentSchedule.id, values);
    setShowPersonnelForm(false);
  };

  const handleSubmitRemovePersonnel = () => {
    const values = {
      personnel: currentPersonnel.id,
      action: 2
    };
    assignPersonnel(currentSchedule.id, values);
    setCurrentPersonnel(null);
  };

  const handleNewShift = () => {
    setCurrentShift(null);
    setShowShiftForm(true);
  };


  const handleEditSchedule = (data) => {
    setCurrentSchedule(data);

    if (currentShift) {
      setCurrentShift(null);
    }
    setEditing(true);
    setShowShiftForm(false);
    setShowPersonnelForm(false);
  };

  const handleEditShift = (item) => {
    if (isEditing && currentShift && !showPersonnelForm) {
      if (item.id === currentShift.id) {
        setEditing(false);
        setShowShiftForm(false);
        setCurrentShift(null);
      } else {
        setCurrentShift(item);
      }
    } else {
      setEditing(true);
      setShowPersonnelForm(false);
      setCurrentShift(item);
      setShowShiftForm(true);
    }
  };

  const handleShowAddPersonnel = (values) => {
    setCurrentPersonnel(null);

    setCurrentSchedule(values);
    setShowPersonnelForm(true);
  };

  const handleSelectPersonnel = (values) => {
    if (Boolean(currentPersonnel) && values.id === currentPersonnel.id) {
      setCurrentPersonnel(null);
    } else {
      setCurrentPersonnel(values);
    }
  };

  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>

      <ScheduleList
        items={schedules}
        handleItemClick={handleEditSchedule}
        editShift={handleEditShift}
        setSchedule={setCurrentSchedule}
        current={{
          schedule: currentSchedule,
          shift: currentShift
        }}
        addPersonnel={handleShowAddPersonnel}
        selectPersonnel={handleSelectPersonnel}
      />
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        py={3}
        className={"admin-panel-schedule-form"}>


        {/* {showCreateSchedule &&*/}
        {!showShiftForm && !showPersonnelForm && !currentPersonnel &&
        <ScheduleForm
          onSubmit={handleSubmitScheduleForm}
          values={isEditing ? currentSchedule : null}
          createShift={handleNewShift}
          editAccess={editAccess}
        />
        }
        {/* }*/}

        {currentPersonnel && currentSchedule &&
        <Fragment>
          <Row>
            <Text>Remove {currentPersonnel.name} from {currentSchedule.name}?</Text>

          </Row>
          <Row>
            <Button danger className={"confirm-remove-personnel"} onClick={handleSubmitRemovePersonnel}>Confirm</Button>

            <Button onClick={() => setCurrentPersonnel(null)}>Cancel</Button>

          </Row>
        </Fragment>
        }

        {showPersonnelForm && !currentPersonnel &&
        <AssignPersonnelForm
          onSubmit={handleSubmitAssignPersonnel}
          personnelList={personnelList}
          handleCancel={() => setShowPersonnelForm(false)}
          editAccess={editAccess}
        />
        }
      </Flex>

      {showShiftForm && !showPersonnelForm &&
      <Box py={2} className={"admin-panel-shift-form"}>

        <ShiftForm
          onSubmit={handleSubmitShiftForm}
          values={currentShift}
          editAccess={editAccess}
          locationList={locationList}
        />
      </Box>
      }
    </ReactCSSTransitionGroup>
  );
};

ScheduleShiftForm.propTypes = {
  data: PropTypes.object,
  schedules: PropTypes.array,
  createSchedule: PropTypes.func,
  createShift: PropTypes.func,
  updateSchedule: PropTypes.func,
  updateShift: PropTypes.func,
  deleteShift: PropTypes.func,
  deleteSchedule: PropTypes.func,
  assignPersonnel: PropTypes.func,
  personnelList: PropTypes.array,
  locationList: PropTypes.array,
  editAccess: PropTypes.bool
};

ScheduleShiftForm.defaultProps = {
  editAccess: true
};

export default ScheduleShiftForm;
