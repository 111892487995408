import React, {Component} from "react";
import {connect} from "react-redux";

import {
  assetActions,
  assetOperations,
  authSelectors,
  baseSelectors,
  orgOperations,
  orgSelectors,
  pmOrderOperations,
  stockroomAssetActions,
  stockroomAssetOperations,
  vendorsOperations,
} from "store";

import AssetDetails from "../../components/Assets/Details";

/* This presents the details for a particular asset. The imported item ID is acquired via the URL. */
class AssetDetailsContainer extends Component {
  render() {
    return (
      <AssetDetails {...this.props}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  accountInfo: authSelectors.refreshAccountInfo(state.account),
  assetDetails: baseSelectors.returnItemWithID(
    state.assets,
    ownProps.match.params.assetID
  ),
  assetTypeList: orgSelectors.refreshAssetTypes(state.orgs),
  csvMessages: baseSelectors.refreshMessages(state.stockroomAssets),
  notes: baseSelectors.refreshNotes(state.assets, ownProps.match.params.assetID)
});

/* Here we will map Redux's actions to props. This will signal for actions to occur, triggering
the reducer and affecting state. This affected state will be immediately available via the mapped
props above. */
const mapDispatchToProps = dispatch => ({
  fetchDetails: (id) => dispatch(assetOperations.getItem(id)),
  updateAssetDetails: (id, data) => dispatch(assetOperations.updateItem(id, data)),
  updateAssetField: async (id, fieldData) => dispatch(assetOperations.updateItemField(id, fieldData)),
  uploadCsv: (data, org) => dispatch(stockroomAssetOperations.uploadCsv(data, org)),
  addNote: async (id, data) => dispatch(await assetOperations.addNote(id, data)),
  getOrg: (id) => dispatch(orgOperations.getItem(id)),
  getAssetTypes: id => dispatch(orgOperations.getAssetTypes(id)),
  createAssetType: (org_id, name, color) => dispatch(orgOperations.createAssetType(org_id, name, color)),
  updateAssetType: (org_id, asset_type_id, name, color) => dispatch(orgOperations.updateAssetType(org_id, asset_type_id, name, color)),
  deleteAssetType: (org_id, asset_type_id) => dispatch(orgOperations.deleteAssetType(org_id, asset_type_id)),
  createAssetTemplate: (assetID, org_id, asset_type_id, template) =>
    dispatch(assetOperations.createAssetTemplate(assetID, org_id, asset_type_id, template)),
  updateAssetTemplate: async (assetID, org_id, asset_type_id, template_id, template) =>
    dispatch(assetOperations.updateAssetTemplate(assetID, org_id, asset_type_id, template_id, template)),
  deleteAssetTemplate: async (assetID, org_id, asset_type_id, template_id) =>
    dispatch(assetOperations.deleteAssetTemplate(assetID, org_id, asset_type_id, template_id)),
  getRecentLogs: () => dispatch(orgOperations.getRecentLogs()),
  clearCsvMessages: () => dispatch(stockroomAssetActions.clearMessages()),
  fetchNotes: (id) => dispatch(assetActions.getItemNotes(id)),
  filterListBy: (query) => dispatch(assetActions.filterListBy(query)),
  getAllVendors: () => dispatch(vendorsOperations.getAllItems()),
  getAllPMOrders: () => dispatch(pmOrderOperations.getAllItems()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDetailsContainer);
