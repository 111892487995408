/* Returns the complete list of items returned from getAllItems operation. */
import moment from "moment";

function refreshCalendarEvents(state) {
  if (state.items) {
    let items = [];
    state.items.forEach(it => {
      if (it.date) {
        let name = it.asset ? it.asset.name : "";
        if (name === "") {
          name = it.stockroom_asset ? it.stockroom_asset.name : "";
        }
        items.push({
          // Add 6 hours to the date to account for timezone
          // TODO: Come up with more robust solution
          "date": moment(it.date).add(6, "hours"),
          "title": it.title || name || "Undefined",
          "id": it.id
        });
      }
    });

    return items;
  }
  return [];
}

/* Returns boolean value of filteringBy state. */
const filteringBy = state => state.filteringBy;


export default {
  refreshCalendarEvents,
  filteringBy
};
