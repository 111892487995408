import PropTypes from "prop-types";

import {HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION, VERTICAL_SCROLLABLE} from "./constants";

const ScrollableOrientationShape = PropTypes.oneOf([
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
  VERTICAL_SCROLLABLE,
]);


export {ScrollableOrientationShape};
