import React from "react";
import PropTypes from "prop-types";
import EndAfter from "./After";
import EndOnDate from "./OnDate";
import {Box, Flex} from "@chakra-ui/core";

import translateLabel from "../../utils/translateLabel";
import {InputWrapper, Label} from "../../styles/styled";
import Select from "react-select";

const End = ({
  id,
  end: {
    mode,
    after,
    onDate,
    options,
  },
  handleChange,
  translations
}) => {
  const isOptionAvailable = option => !options.modes || options.modes.indexOf(option) !== -1;
  const isOptionSelected = option => mode === option;

  const endOptions = [
    {value: "Never", label: translateLabel(translations, "end.never")},
    {value: "After", label: translateLabel(translations, "end.after")},
    {value: "On date", label: translateLabel(translations, "end.on_date")},
  ];

  const handleRepeatSelect = (value) => {
    const target = {
      name: "end.mode",
      value: value.value
    };
    handleChange({target});
  };

  return (
    <Flex alignItems={"center"}>
      <Box pt={3} pr={1}>
        <Label
          htmlFor={id}
          className="col-form-label"
        >
          <strong>
            {translateLabel(translations, "end.label")}
          </strong>
        </Label>
      </Box>
      <Box>
        <InputWrapper width={"200px"}>
          <Select
            name="end.mode"
            id={id}
            className="form-control"
            onChange={handleRepeatSelect}
            defaultValue={endOptions[0]}
            options={endOptions.filter(option => isOptionAvailable(option.value))}
          />
        </InputWrapper>
      </Box>

      {
        isOptionSelected("After") &&
        <EndAfter
          id={`${id}-after`}
          after={after}
          handleChange={handleChange}
          translations={translations}
        />
      }
      {
        isOptionSelected("On date") &&
        <EndOnDate
          id={`${id}-onDate`}
          onDate={onDate}
          handleChange={handleChange}
          translations={translations}
        />
      }
    </Flex>
  );
};

End.propTypes = {
  id: PropTypes.string.isRequired,
  end: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    after: PropTypes.number.isRequired,
    onDate: PropTypes.object.isRequired,
    options: PropTypes.shape({
      modes: PropTypes.arrayOf(PropTypes.oneOf(["Never", "After", "On date"])),
      weekStartsOnSunday: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default End;
