import {combineReducers} from "redux";
import {createCrud, createNestedCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {productionLineEndpoint} from "./constants";
import {ASSETS_ROUTE} from "../../../routes/constants";


export const crud = createCrud({
  endpoint: productionLineEndpoint,
  name: "productionLines",
});

export const pagination = createPaginator({
  endpoint: productionLineEndpoint,
  name: "productionLines",
});

export const lineAssetCrud = createNestedCrud({
  endpoint: productionLineEndpoint,
  nestedEndpoint: `${ASSETS_ROUTE}/`,
  name: "productionLineAssets",
});

export const pageActions = pagination.actions;

export const operations = crud.operations;

export const lineAssetActions = lineAssetCrud.actions;


export default combineReducers({
  items: pagination.reducer(),
  details: crud.reducer()
});
