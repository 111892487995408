import store from "./store";

import {paginationActions as materialPageActions} from "./materials/pagination";

import {inventoryPageActions, paginationActions as productPaginationActions} from "./warehouse/products/pagination";

import {
  customerActions,
  customerOperations,
  customerPageActions,
  supplierActions,
  supplierOperations,
  supplierPageActions
} from "./warehouse/contacts";

import {lineAssetActions, lineOperations, linePageActions, linePagination} from "./production";

import {
  actions as pmOrderActions,
  operations as pmOrderOperations,
  scheduleActions as scheduledPMOrdersActions,
  scheduleOperations as scheduledPMOrdersOperations
} from "./orders/pmOrders";

import {materialInventoryActions} from "./warehouse/materials/inventory";

import {salesOrderItemActions, salesOrderMaterialActions} from "./warehouse/sales/reducers";

import {warehouseLocationOperations, warehouseLocationPageActions} from "./warehouse/locations/locations";

import {
  receiveNotification,
  actions as notificationActions,
  operations as notificationOperations
} from "./personnel/notifications";

export {default as crudSelectors} from "./base/crud/selectors";

export {default as assetTypes} from "./assets/types";
export {default as assetSelectors} from "./assets/selectors";
export {default as assetOperations} from "./assets/operations";
export {default as assetActions} from "./assets/actions";

export {default as authOperations} from "./auth/operations";
export {default as authActions} from "./auth/actions";
export {default as authSelectors} from "./auth/selectors";
export {default as authTypes} from "./auth/types";

export {default as baseTypes} from "./base/types";
export {default as baseSelectors} from "./base/selectors";
export {default as baseOperations} from "./base/operations";
export {default as baseActions} from "./base/actions";
export {default as paginationSelectors} from "./base/paginator/selectors";

export {default as materialTypes} from "./materials/types";
export {default as materialSelectors} from "./materials/selectors";
export {default as materialOperations} from "./materials/operations";
export {default as materialActions} from "./materials/actions";

export {default as productSelectors} from "./warehouse/products/selectors";
export {default as productOperations} from "./warehouse/products/operations";
export {default as productActions} from "./warehouse/products/actions";

export {default as orgTypes} from "./orgs/types";
export {default as orgSelectors} from "./orgs/selectors";
export {default as orgOperations} from "./orgs/operations";
export {default as orgActions} from "./orgs/actions";

export {default as personnelTypes} from "./personnel/types";
export {default as personnelSelectors} from "./personnel/selectors";
export {default as personnelOperations} from "./personnel/operations";
export {default as personnelActions} from "./personnel/actions";

export {default as pmOrderTypes} from "./pmOrders/types";
export {default as pmOrderSelectors} from "./orders/selectors";
// export {default as pmOrderOperations} from "./pmOrders/operations";
// export {default as pmOrderActions} from "./pmOrders/actions";

export {default as scheduledPMOrdersTypes} from "./scheduledPMOrders/types";
export {default as scheduledPMOrdersSelectors} from "./scheduledPMOrders/selectors";
// export {default as scheduledPMOrdersOperations} from "./scheduledPMOrders/operations";
// export {default as scheduledPMOrdersActions} from "./scheduledPMOrders/actions";

export {default as stockroomAssetTypes} from "./stockroomAssets/types";
export {default as stockroomAssetSelectors} from "./stockroomAssets/selectors";
export {default as stockroomAssetOperations} from "./stockroomAssets/operations";
export {default as stockroomAssetActions} from "./stockroomAssets/actions";

export {default as vendorsTypes} from "./vendors/types";
export {default as vendorsSelectors} from "./vendors/selectors";
export {default as vendorsOperations} from "./vendors/operations";
export {default as vendorsActions} from "./vendors/actions";

export {default as scheduledShiftTypes} from "./scheduledShifts/types";
export {default as scheduledShiftSelectors} from "./scheduledShifts/selectors";
export {default as scheduledShiftOperations} from "./scheduledShifts/operations";
export {default as scheduledShiftActions} from "./scheduledShifts/actions";

export {default as palletTypes} from "./warehouse/pallets/types";
export {default as palletSelectors} from "./warehouse/pallets/selectors";
export {default as palletOperations} from "./warehouse/pallets/operations";
export {default as palletActions} from "./warehouse/pallets/actions";

export {default as purchaseOrderTypes} from "./warehouse/purchaseOrders/types";
export {default as purchaseOrderSelectors} from "./warehouse/purchaseOrders/selectors";
export {default as purchaseOrderOperations} from "./warehouse/purchaseOrders/operations";
export {default as purchaseOrderActions} from "./warehouse/purchaseOrders/actions";

export {default as salesOrderSelectors} from "./warehouse/sales/selectors";
export {default as salesOrderOperations} from "./warehouse/sales/operations";
export {default as salesOrderActions} from "./warehouse/sales/actions";

export {
  pmOrderOperations,
  pmOrderActions,
  supplierOperations,
  supplierPageActions,
  supplierActions,
  lineOperations,
  linePageActions,
  lineAssetActions,
  linePagination,
  productPaginationActions,
  inventoryPageActions,
  salesOrderItemActions,
  customerOperations,
  customerPageActions,
  customerActions,
  materialInventoryActions,
  salesOrderMaterialActions,
  warehouseLocationPageActions,
  warehouseLocationOperations,
  materialPageActions,
  scheduledPMOrdersActions,
  scheduledPMOrdersOperations,
  receiveNotification,
  notificationActions,
  notificationOperations
};

export default store;
