import React from "react";
import PropTypes from "prop-types";
import {Box, Statistic, OptionalLink, Card} from "Base";

const DashStat = ({name, value, style, suffix, route}) => {
  return (
    <Box p={1}>
      <Card>
        <OptionalLink to={route}>
          <Statistic
            title={name}
            value={value}
            valueStyle={{fontSize: "2em", ...style}}
            suffix={suffix}
          />
        </OptionalLink>
      </Card>
    </Box>
  );
};

DashStat.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  suffix: PropTypes.string,
  moreThanOne: PropTypes.bool
};

DashStat.defaultProps = {
  name: "",
  value: "N/A",
  style: {},
  suffix: ""
};

export {DashStat};

