import React from "react";
import PropTypes from "prop-types";
import {LinkedFieldSection} from "../../../../Common/Details";
import ProductForm from "./ProductForm";
import ProductList from "./ProductList";

const ProductSection = props => {
  const {data, onSubmit, pagination, getPage} = props;

  return (
    <LinkedFieldSection
      data={data}
      onSubmit={onSubmit}
      Form={ProductForm}
      List={ProductList}
      name={"products"}
      formField={"product"}
      title={"Product"}
      formProps={{pagination, getPage}}
    />
  );
};

ProductSection.propTypes = {
  data: PropTypes.object,
  pagination: PropTypes.object.isRequired,
  getPage: PropTypes.func.isRequired
};

export default ProductSection;
