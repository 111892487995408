import React, {Fragment, useEffect, useState} from "react";
import {Flex, Input, Box} from "../../Base";
import {ChromePicker} from "react-color";
import {Form} from "react-final-form";
import {isEqual} from "lodash-es";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Divider, List, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {SecondaryButton, Row} from "../styled";
import {checkNestedKeys} from "../../../../utils/propUtils";
import {Modal} from "../../index";
import {DescriptionText} from "../../Modals/styled";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    textAlign: "center"
  }
}));

const AssetTypesForm = (props) => {
  const [isEditing, setEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteAssetModalShowing, setDeleteAssetModalShowing] = useState(false);
  const [isColorPickerShowing, setColorPickerShowing] = useState(false);
  const [assetName, setAssetName] = useState("");
  const [assetType, setAssetType] = useState({
    label: "",
    color: "#2c3655",
    id: ""
  });

  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    if (assetName !== assetType.label) {
      let asset = assetType;
      asset.label = assetName;
      setAssetType(asset);
    }
  }, [assetName, assetType]);

  const handleChange = (ev) => {
    const {value} = ev.target;
    setAssetName(value);
  };

  const handleEditItem = (assetToEdit) => {
    const asset = (({id, label, color}) => ({id, label, color}))(assetToEdit);
    setAssetName(asset.label);
    setAssetType(asset);
    setEditing(true);
  };

  const toggleDeleteAssetModal = () => {
    setDeleteAssetModalShowing(!deleteAssetModalShowing);
  };

  const handleAssetTypeColorChange = (color) => {
    let asset = assetType;
    asset.color = color.hex;
    setAssetType(asset);
  };

  const clearFields = () => {
    setAssetName("");
    setAssetType({
      label: "",
      color: "#2c3655",
      id: ""
    });
    if (isEditing) {
      setEditing(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    clearFields();
    setEditing(false);
  };

  const handleDelete = () => {
    const {deleteAssetType, accountInfo} = props;
    const orgId = checkNestedKeys(["personnel", "default_org", "id"], accountInfo);
    const response = deleteAssetType(orgId, assetType.id);
    if (response && !response.error) {
      clearFields();
      toggleDeleteAssetModal();
    }
  };


  const itemWasEdited = () => {
    let itemToCheck = props.assetTypeList.find(item => item.id === assetType.id);
    itemToCheck = (({id, label, color}) => ({id, label, color}))(itemToCheck);
    const isItemDifferent = !isEqual(assetType, itemToCheck);
    return isItemDifferent;
  };

  const onSubmit = async () => {
    const orgId = checkNestedKeys(["accountInfo", "personnel", "default_org", "id"], props);
    if (orgId) {
      const {label, color} = assetType;
      let response;
      if (isEditing && itemWasEdited()) {
        response = await props.updateAssetType(orgId, assetType.id, label, color);
      } else if (!isEditing) {
        response = await props.createAssetType(orgId, label, color);
      }
      if (response && !response.error) {
        clearFields();
      }
    }
  };

  const {assetTypeList} = props;
  const classes = useStyles();
  return (
    <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      {visible &&
      <div>
        <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
          {assetTypeList.length !== 0 &&
          <List className={classes.list}>
            {assetTypeList.map(item => {
              return (
                <Fragment key={item.id}>
                  <ListItem button onClick={() => handleEditItem(item)} key={item.id}
                    className={`${classes.listItem} asset-type-item`}>
                    <ListItemText primary={item.label} className={classes.listItemText}/>
                  </ListItem>
                  <Divider/>
                </Fragment>
              );
            })}
          </List>
          }
        </ReactCSSTransitionGroup>
        <Form
          onSubmit={onSubmit}
          initialValues={null}
          render={({handleSubmit, form, submitting, pristine, values}) => (
            <form className="asset-types-form" onSubmit={handleSubmit}>
              <div className="center-contents-vertical">
                <Flex justifyContent="center">
                  <Input
                    backgroundColor="white"
                    placeholder={"Asset Type Name"}
                    value={assetName}
                    minWidth={100}
                    maxWidth={400}
                    type="text"
                    name="fieldNameNew"
                    disabled={false}
                    onChange={handleChange}
                    className="input-pane"
                  />
                  <Box
                    onClick={() => setColorPickerShowing(true)}
                    margin={"0 25px"}
                    background={"#f7f7f7"}
                    borderRadius={1}
                    display={"inline-block"}
                    // ClassName='color-box-align'
                    cursor={"pointer"}>
                    <Box
                      width={36}
                      height={"100%"}
                      border={"1px solid black"}
                      borderRadius={2}
                      background={assetType.color}/>
                  </Box>
                  {isColorPickerShowing &&
                  <Box>
                    <Box position={"absolute"} width={1}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px"
                        }}
                        onClick={() => setColorPickerShowing(false)}/>
                      <Box position={"relative"} left={-300}>
                        <ChromePicker
                          disableAlpha={true}
                          color={assetType.color}
                          onChangeComplete={handleAssetTypeColorChange}/>
                      </Box>
                    </Box>
                  </Box>
                  }
                </Flex>
              </div>
              <Row justifyContent={"center"}>
                <ReactCSSTransitionGroup transitionName="mapping" transitionEnterTimeout={700}
                  transitionLeaveTimeout={700}>
                  {isEditing ?

                    <Row justifyContent={"center"}>
                      <SecondaryButton onClick={handleCancel} className="form-button">
                        Cancel Edit
                      </SecondaryButton>

                      <SecondaryButton htmlType="submit" type="primary" className="form-button">
                        Edit Asset Type
                      </SecondaryButton>

                      <SecondaryButton onClick={toggleDeleteAssetModal} className="form-button">
                        Delete Item
                      </SecondaryButton>
                    </Row> :

                    <SecondaryButton type="primary" htmlType="submit" className="form-button" width={"auto"}>
                      Create Asset Type
                    </SecondaryButton>
                  }

                </ReactCSSTransitionGroup>
              </Row>
            </form>)}/>
        <Modal
          visible={deleteAssetModalShowing}
          title={`Delete ${assetType?.label ?? "Asset Type"}?`}
          onCancel={toggleDeleteAssetModal}
          onOk={handleDelete}
          isDeleteModal
        >
          <DescriptionText>Deleting this asset type will remove it from all Assets</DescriptionText>
        </Modal>
      </div>
      }
    </ReactCSSTransitionGroup>
  );
};

AssetTypesForm.propTypes = {
  onSubmit: PropTypes.func,
  createAssetType: PropTypes.func,
  deleteAssetType: PropTypes.func,
  updateAssetType: PropTypes.func,
  accountInfo: PropTypes.object,
  assetTypeList: PropTypes.array
};

AssetTypesForm.defaultProps = {
  accountInfo: {},
  allOrgs: [],
  assetTypeList: []
};

export default AssetTypesForm;

