import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Box, Flex} from "../Base";
import {onSiteProgressLabels, offSiteProgressLabels} from "./constants";
import {allStatusOptions} from "../Forms/VendorForm/constants";

export default function ProgressLabels({isWindowLarge, is_on_site, status}) {
  const progressLabels = is_on_site ? onSiteProgressLabels : offSiteProgressLabels;

  return (
    <Fragment>
      {isWindowLarge ? <Flex className="progress-label-container">
        {progressLabels.map((progressLabel, index) => {
          const {label} = progressLabel;
          return (
            <Box key={index} {...progressLabel}>
              {label}
            </Box>
          );
        })}
      </Flex>
        :
        <div style={{textAlign: "center", fontSize: "1.2em"}}>
          Order Status: {" " + (status ? allStatusOptions[status].label : "")}
        </div>}
    </Fragment>
  );
}

ProgressLabels.propTypes = {
  is_on_site: PropTypes.bool,
  isWindowLarge: PropTypes.bool,
  status: PropTypes.number
};

ProgressLabels.defaultProps = {
  is_on_site: null,
};
