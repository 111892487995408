import React from "react";
import styled from "@emotion/styled";
import {Heading, Text} from "../Base";


const ItemName = (props) => <Heading {...props}/>;


ItemName.defaultProps = {
  size: "md",
  as: "h3",
  fontSize: "sm",
  fontWeight: "semibold",
  pb: 1,
  mb: 0
};

const ItemText = styled(Text)``;

ItemText.defaultProps = {
  fontSize: "sm"
};

export {ItemName, ItemText};
