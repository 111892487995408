import {combineReducers} from "redux";
import {createCrud, createNestedCrud} from "../../base/crud";
import {createPaginator} from "../../base/paginator";
import {templateEndpoint} from "./constants";


export const templateCrud = createCrud({
  endpoint: templateEndpoint,
  name: "productionTemplate",
});

export const templatePagination = createPaginator({
  endpoint: templateEndpoint,
  name: "productionTemplate",
});

export const templateMaterialCrud = createNestedCrud({
  endpoint: templateEndpoint,
  nestedEndpoint: "materials/",
  name: "productionMaterials",
});

export const templatePageActions = templatePagination.actions;

export const templateOperations = templateCrud.operations;

export const templateMaterialActions = templateMaterialCrud.actions;


export default combineReducers({
  items: templatePagination.reducer(),
  details: templateCrud.reducer()
});
