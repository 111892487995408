import React, {useState} from "react";
import PropTypes from "prop-types";
import {DescriptionText, PageNumberText} from "./styled";
import {Box, Button, Container, FlexCenter, Image} from "../Base";
import {Link} from "react-router-dom";
import {ASSET_DASHBOARD_ROUTE} from "../../../routes/constants";
import Modal from "./";
import {LeftOutlined, RightOutlined} from "@ant-design/icons/lib/icons";

/**
 * This component displays tutorial and/or 'about' information in a popup modal.
 * @returns {TutorialModal} The tutorial modal
 */
const TutorialModal = ({
  banner,
  descriptions,
  closeModal,
  handleOpenAddItemModal,
  visible,
  type
}) => {
  // Hooks for counting (for the step in descriptions array)
  const [count, setCount] = useState(0);

  // Function to step through the descriptions array on 'next' click
  const handleClicks = (e) => {
    if (e === "next" && count !== descriptions.length - 1) {
      setCount(count => count + 1);
    }
    if (e === "back" && count !== 0) {
      setCount(count => count - 1);
    }
  };

  const handleAddItem = () => {
    handleOpenAddItemModal[0]();
    closeModal();
  };

  /**
   * Function to generate the modal header text based on item type. Add cases if
   * a type needs a customized tutorial modal header.
   * @returns {string} Generated text for the header.
   */
  const headerText = () => {
    switch (type) {
    case "Asset":
      return `Creating an ${type}`;
    case "Personnel":
      return `Add ${type}`;
    case "Pallet":
      return `Build a ${type}`;
    default:
      return `Creating a ${type}`;
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      showCancelButton={false}
      title={headerText()}
      footer={null}
      modalConfig={{"wrapClassName": "tutorialModal ant-modal-custom modal"}}
    >
      <Image src={banner} alt={""} mb="10px"/>
      <Container width="100%">
        <DescriptionText>{descriptions[count]}</DescriptionText>
      </Container>
      <br/>
      <FlexCenter flexWrap={"wrap"}>
        <Box flexBasis={"25%"} maxWidth={"100%"}>
          {count !== 0 &&
          <Button
            width=""
            icon={<LeftOutlined/>}
            id="back"
            className="modal-card-button"
            onClick={() => handleClicks("back")}>Back</Button>}
        </Box>
        <PageNumberText flexBasis={"50%"} id="pageCount" flexShrink={"0.5"}>
          Page: {count + 1}/{descriptions.length}
        </PageNumberText>
        <Box flexBasis={"25%"} maxWidth={"100%"}>
          {(count !== descriptions.length - 1) &&
          <Button
            id="next"
            className="modal-card-button"
            onClick={() => handleClicks("next")}>
            Next {<RightOutlined/>}
          </Button>}
        </Box>
      </FlexCenter>
      {handleOpenAddItemModal[0] ? (
        <Button
          type={"primary"}
          className="modal-card-button create-object"
          onClick={handleAddItem}>{handleOpenAddItemModal[1]}</Button>)
        : (
          <Link to={ASSET_DASHBOARD_ROUTE}>
            <Button className="modal-card-button" type={"primary"}>Go to Assets</Button>
          </Link>
        )}
    </Modal>
  );
};

TutorialModal.propTypes = {
  /**
   * An image file to display in the modal
   */
  banner: PropTypes.node,
  /**
   * String value for the header
   */
  headerText: PropTypes.string,
  /**
   * The description content that will be cycled through on 'next' clicking
   */
  descriptions: PropTypes.array.isRequired,
  /**
   * Function for the closing behavior
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Function passed from the parent container that opens object creation modal
   */
  handleOpenAddItemModal: PropTypes.array,
  /**
   * Whether the tutorial modal is visible or not
   */
  visible: PropTypes.bool.isRequired
};

TutorialModal.defaultProps = {
  headerText: "Empty Modal",
  descriptions: ["1", "2", "3 ", "4"]
};

export default TutorialModal;
