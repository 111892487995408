import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-final-form";
import {FormField, FormLinkField, FormMoneyField, PositiveNumberField} from "../../../Common/Forms/Base";
import {SubmitButton} from "../../../Common/Forms/styled";
import arrayMutators from "final-form-arrays";
import {clearMutator} from "../../../Common/Forms/Base/mutators";
import {Input} from "antd";
import {antAdapt} from "../../../Common/Forms/Base/utils";

import {postLoadFormat} from "./utils";
import InventoryShippingDimensions from "../../../Common/Forms/InventoryShippingDimensions";

const AdaptedInput = antAdapt(Input);

const ProductForm = ({onSubmit, initialValues, editAccess, validate, handleManufacturerClick}) => {
  const formValues = postLoadFormat(initialValues);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={formValues}
      mutators={{
        ...arrayMutators,
        clearMutator,
      }}
      render={({handleSubmit, pristine, invalid, form, submitting, values}) => (
        <form id={"product-form"} className={"item-details-form"} onSubmit={async e => {
          await handleSubmit(e);
          // toggleEdit();
        }}>

          <FormField
            component={AdaptedInput}
            label={"Product Name"}
            type={"text"}
            name={"name"}
            isDisabled={!editAccess}
            size={"large"}
          />

          {editAccess ?
            <FormField
              component={AdaptedInput}
              label="Product Manufacturer"
              type="text"
              name="manufacturer"
              isDisabled={!editAccess}
              size={"large"}

            />
            :
            <FormLinkField
              component={AdaptedInput}
              label="Product Manufacturer"
              type="text"
              name="manufacturer"
              isDisabled={!editAccess}
              handleClick={handleManufacturerClick}
              size={"large"}

              url="/#/dashboard/warehouse/inventory"
            />
          }

          <PositiveNumberField
            component={AdaptedInput}
            label={"SKU"}
            type={"text"}
            name={"sku"}
            isDisabled={!editAccess}
            size={"large"}

          />

          <FormMoneyField
            numberProps={{
              component: AdaptedInput,
              size: "large",
            }}
            label={"PRICE"}
            name={"price"}
            currencyName={"price_currency"}
            isDisabled={!editAccess}
          />

          <InventoryShippingDimensions editAccess={editAccess}/>

          {editAccess &&
          <SubmitButton className="item-details-form-button" htmlType="submit" loading={submitting}>
            Save
          </SubmitButton>
          }
        </form>
      )}
    />
  );
};


ProductForm.propTypes = {
  handleManufacturerClick: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  validate: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
  initialValues: PropTypes.object,
};

ProductForm.defaultProps = {
  values: {},
  editAccess: false,
};

export default ProductForm;
