import React, {useEffect, useState, useCallback} from "react";
import PropTypes from "prop-types";
import {RangePicker} from "Base";
import {useField} from "react-final-form";
import moment from "moment-timezone";

const formatValue = (value) => {
  return moment(value).format();
};


// const formatInitialValues = (start, end) =>
const returnMomentOrNull = (value) => {
  if (value && value !== "") {
    return moment(value);
  }
  return null;
};

const returnFormatOrNull = (value) => {
  if (value && value !== "") {
    return formatValue(value);
  }
  return null;
};

const RangePickerField = ({startName, endName, ...rest}) => {
  const [value, setValue] = useState(null);
  const startField = useField(startName);

  const endField = useField(endName);

  const getAndSetValue = useCallback(
    () => {
      const values = [
        returnMomentOrNull(startField.input.value),
        returnMomentOrNull(endField.input.value)
      ];
      setValue(values);
    },
    [startField, endField],
  );

  useEffect(() => {
    if (!value) {
      getAndSetValue();
    }
  }, [value, getAndSetValue]);

  const handleOnChange = (values) => {
    console.log("range on change", values);
    if (values) {
      startField.input.onChange(returnFormatOrNull(values[0]));
      endField.input.onChange(returnFormatOrNull(values[1]));
    } else {
      startField.input.onChange(null);
      endField.input.onChange(null);
    }
    setValue(values);
  };

  return (
    <div>
      <RangePicker
        onCalendarChange={handleOnChange}
        value={value}
        {...rest}
      />
    </div>
  );
};

RangePickerField.propTypes = {
  startName: PropTypes.string,
  endName: PropTypes.string
};

RangePickerField.defaultProps = {
  startName: "start",
  endName: "end"
};

export default RangePickerField;
